import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';

export interface ILogyxOrderLineTableDTO {
  name: string;
  status?: string;
  line?: IPurchaseOrderLine;
}

export interface ILogyxOrderLine {
  id: number;
  logyx_order_id: number;
  purchase_order_line: IPurchaseOrderLine;
  purchase_order_line_id: number;
  status: ILogyxOrderLineStatus;
  status_id: number;
}

export interface ILogyxOrderLineStatus {
  id: number;
  name: LogyxOrderStatus;
}

export enum LogyxOrderStatus {
  VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS',
  INVALID_LOGYX_CONFIG = 'INVALID_LOGYX_CONFIG',
  VALID = 'VALID',
}

export enum LogyxOrderStatusId {
  VALIDATION_IN_PROGRESS = 1,
  INVALID_LOGYX_CONFIG = 2,
  VALID = 3,
}
