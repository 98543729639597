import { CloseStyled } from './Close.styled';
import { Size } from './type';

export interface ICloseProps {
  onClick?: () => void;
  size?: Size;
  id?: string;
}

export const Close = ({ onClick, size = Size.DEFAULT, id }: ICloseProps) => (
  <CloseStyled onClick={onClick} size={size} id={id} className="close-button" />
);
