import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { BlueprintLogoBigImg, Container } from './LoadingScreen.styled';
import BlueprintLogoGif from 'assets/images/BlueprintLogoGif.gif';
import { useState } from 'react';

const LoadingScreen = () => {
  const { darkMode, isMobileApp } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  // Generate a unique key each time the component renders
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uniqueGifKey, _] = useState<number>(Date.now());

  return (
    <Container isDarkMode={darkMode}>
      <BlueprintLogoBigImg
        src={`${BlueprintLogoGif}?${uniqueGifKey}`}
        alt=""
        isMobileApp={isMobileApp}
      />
    </Container>
  );
};

export default LoadingScreen;
