import * as Yup from 'yup';

export const editOrderDetailsSchema = () => {
  return Yup.object().shape({
    reference: Yup.string()
      .optional()
      .max(80, 'Reference should be less than 80 characters'),
    downpayment: Yup.number()
      .typeError('Down payment must be a number')
      .test(
        'twoDecimal',
        'Down payment can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .optional(),
  });
};
