import { EmailTemplateColors } from './types';

export const colors = [
  EmailTemplateColors.color_1,
  EmailTemplateColors.color_2,
  EmailTemplateColors.color_3,
  EmailTemplateColors.color_4,
  EmailTemplateColors.color_5,
];

export const defaultTemplate = {
  DRAFT_QUOTATION_HEADLINE: 'Quotation {{QUOTATION_NUMBER}}',
  DRAFT_QUOTATION_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your indicative quotation is ready and the PDF version of it can be found in the attachment of this email.. \n \n Thank you for your inquiry! \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  DEFINITIVE_QUOTATION_HEADLINE: 'Quotation {{QUOTATION_NUMBER}}',
  DEFINITIVE_QUOTATION_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your quotation is ready, and you can find the PDF version in the attachment. \n \n Thank you for your interest and please let us know your response to the offer at your earliest convenience. \n \n {{SIGNATURE_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_ORDER_HEADLINE: 'Order Confirmation {{SALES_ORDER_NUMBER}}',
  SALES_ORDER_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Thank you for your order. We are pleased to confirm your purchase. You can find a PDF version of the order in the attachment. \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_INVOICE_HEADLINE: 'Order Invoice {{SALES_INVOICE_NUMBER}}',
  SALES_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SALES_CREDIT_INVOICE_HEADLINE: 'Credit Invoice {{SALES_INVOICE_NUMBER}}',
  SALES_CREDIT_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  DOWN_PAYMENT_INVOICE_HEADLINE:
    'Down payment Invoice {{SALES_INVOICE_NUMBER}}',
  DOWN_PAYMENT_INVOICE_BODY:
    'Dear {{CUSTOMER_NAME}},\n \n Your down payment invoice is ready and you can find the PDF version of the invoice in the attachment. \n \n {{PAYMENT_LINK}} \n \n If you have any questions or concerns, please let us know. \n \n Best regards, \n {{COMPANY_NAME}} \n \n',
  SIGNED_QUOTATION_HEADLINE: 'Signed quotation {{QUOTATION_NUMBER}}',
  SIGNED_QUOTATION_BODY:
    'Dear,\n \n Quotation {{QUOTATION_NUMBER}} is signed. \n \n You can find the signed PDF version in the attachment.',
};

export const DEFAULT_HEADLINE_SIZE = '24px';
export const DEFAULT_BODY_SIZE = '16px';
