import { useInfiniteQuery } from 'react-query';
import { getFwAppointmentComments } from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetFwAppointmentComments = (
  perPage: number,
  appointmentId: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_FW_APPOINTMENT_COMMENTS, appointmentId],
    queryFn: ({ pageParam = 1 }) =>
      getFwAppointmentComments(appointmentId, pageParam, perPage),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });
