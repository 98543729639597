import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 154rem;
  padding: 6rem;
  max-height: 91rem;
  border: 1rem solid ${COLORS.GREY_200};
  border-radius: ${borderRadiusSm};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90rem;
  min-height: 90rem;
`;

export const Image = styled.img`
  border-radius: 15rem;
  width: fit-content;
  max-width: 142rem;
  max-height: 79rem;
  cursor: pointer;
`;

export const TrashIcon = styled.div`
  position: absolute;
  cursor: pointer;
  width: 20rem;
  height: 20rem;
  right: -10rem;
  top: -10rem;
  background-color: ${COLORS.WHITE};
  border-radius: 5rem;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
