import moment from 'moment';
import {
  ICreateCreditInvoiceDTO,
  ICreateCreditInvoiceFormDTO,
} from './constants';

export const getCreateCreditInvoicePayload = (
  creditInvoiceFormData: ICreateCreditInvoiceFormDTO,
  prepopulateCreditInvoiceData: any,
  isForSalesInvoice: boolean,
  isRepay?: boolean
) => {
  const daysToAdd = Number(creditInvoiceFormData.daysValid) || 0;
  const validToMoment = moment().endOf('day').add(daysToAdd, 'day');

  const validTo = validToMoment.isValid()
    ? validToMoment.toISOString()
    : moment().add(7, 'days').toISOString(); // Fallback: current date + 7 days

  if (isForSalesInvoice) {
    // Create payload for sales credit invoice
    const createCreditInvoiceDTO: ICreateCreditInvoiceDTO = {
      credit_reason: creditInvoiceFormData.reason,
      credit_detailed_reason: creditInvoiceFormData.detailedReason,
      reference: creditInvoiceFormData.reference,
      valid_to: validTo,
      sales_order_id: prepopulateCreditInvoiceData.sales_invoice.sales_order_id,
      is_credit: true,
      parent_invoice_id: prepopulateCreditInvoiceData.sales_invoice.id,
      isForSalesInvoice: true,
      credit_total_amount: Number(creditInvoiceFormData.correctiveAmount),
      is_repay: isRepay,
    };
    return createCreditInvoiceDTO;
  } else {
    // Create payload for purchase credit invoice
    const createCreditInvoiceDTO: ICreateCreditInvoiceDTO = {
      credit_reason: creditInvoiceFormData.reason,
      credit_detailed_reason: creditInvoiceFormData.detailedReason,
      reference: creditInvoiceFormData.reference,
      valid_to: validTo,
      purchase_order_id:
        prepopulateCreditInvoiceData?.purchase_invoice?.purchase_order?.id,
      is_credit: true,
      parent_invoice_id: prepopulateCreditInvoiceData?.purchase_invoice?.id,
      isForSalesInvoice: false,
      credit_total_amount: Number(creditInvoiceFormData.correctiveAmount),
    };
    return createCreditInvoiceDTO;
  }
};
