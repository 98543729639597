import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
} from './ConfirmModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction } from 'react';
import { Level } from '../type';

interface IConfirmProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel?: () => void;
  description?: string;
  title: string;
  level?: Level;
  disabled?: boolean;
}

export const ConfirmModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  description,
  title,
  level = 'FIRST',
  disabled = false,
}: IConfirmProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level={level}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>{description}</Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            disabled={disabled}
            onClick={() => {
              onCancel && onCancel();
            }}
            secondary
            width="200rem"
            label={t('Cancel')}
          />
        </Margin>
        <Button
          disabled={disabled}
          primary
          width="200rem"
          label={t('Confirm')}
          onClick={() => {
            onConfirm && onConfirm();
          }}
        />
      </ButtonContainer>
    </Modal>
  );
};
