// Single Sales Order Invoice
export const SingleSalesInvoiceRoutes = {
  SingleSalesInvoiceGeneral: {
    path: '',
    fullPath: '/sale-invoices',
  },
  SingleSalesInvoiceSalesOrders: {
    path: 'sales',
    fullPath: '/sale-invoices/:id/sales',
  },
  SingleSalesInvoicePayments: {
    path: 'payments',
    fullPath: '/sale-invoices/:id/payments',
  },
  SingleSalesInvoiceFiles: {
    path: 'files',
    fullPath: '/sale-invoices/:id/files',
  },
  SingleSalesInvoiceNewCreditInvoice: {
    path: 'credit/create',
    fullPath: '/sale-invoices/:id/credit/create',
  },
  SingleSalesInvoiceCreditInvoice: {
    path: 'credit',
    fullPath: '/sale-invoices/:id/credit',
  },
};
