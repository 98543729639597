import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1075rem;
  align-self: center;
  height: 100%;
  gap: 20rem;
`;

export const RightAlign = styled.div`
  width: 850rem;
  display: flex;
  justify-content: flex-end;
`;
