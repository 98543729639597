import { Route, Routes } from 'react-router-dom';
import { SalesPurchaseOrderInvoiceRoutes } from './SinglePurchaseInvoice.routes';
import SinglePurchaseInvoiceGeneralPage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoiceGeneralPage/SinglePurchaseInvoiceGeneralPage';
import SinglePurchaseInvoicePurchaseOrderPage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoicePurchaseOrderPage/SinglePurchaseInvoicePurchaseOrderPage';
import SinglePurchaseInvoiceFilesPage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoiceFilesPage/SinglePurchaseInvoiceFilesPage';
import SinglePurchaseInvoicePaymentsPage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoicePaymentsPage/SinglePurchaseInvoicePaymentsPage';
import NewCreditInvoicePage from 'pages/Manager/NewCreditInvoice/NewCreditInvoicePage';
import SinglePurchaseInvoiceCreditInvoicesPage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoiceCreditInvoicesPage/SinglePurchaseInvoiceCreditInvoicesPage';

const SinglePurchaseInvoiceRouter = () => {
  return (
    <Routes>
      <Route
        path={SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceGeneral.path}
        element={<SinglePurchaseInvoiceGeneralPage />}
      />
      <Route
        path={
          SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoicePurchaseOrders
            .path
        }
        element={<SinglePurchaseInvoicePurchaseOrderPage />}
      />
      <Route
        path={
          SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoicePayments.path
        }
        element={<SinglePurchaseInvoicePaymentsPage />}
      />
      <Route
        path={SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceFiles.path}
        element={<SinglePurchaseInvoiceFilesPage />}
      />
      <Route
        path={
          SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceNewCreditInvoice
            .path
        }
        element={<NewCreditInvoicePage />}
      />
      <Route
        path={
          SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceCreditInvoices
            .path
        }
        element={<SinglePurchaseInvoiceCreditInvoicesPage />}
      />
    </Routes>
  );
};

export default SinglePurchaseInvoiceRouter;
