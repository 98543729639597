import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginMd } from 'assets/styled';

interface StyledProps {
  isSelected: boolean;
  disabled: boolean;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const AppointmentFilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30rem;
`;

export const AppointmentFilterTypeLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      color: ${COLORS.GREY_1000};
      cursor: not-allowed;
    `}
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      cursor: default;
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
    user-select: none;
`;

export const AppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
