import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'utils/toast';
import {
  deleteAppointmentFile,
  editAppointmentFile,
  getFwAppointmentFiles,
  uploadFwAppointmentFile,
} from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';

export const useGetFwAppointmentFiles = (
  perPage: number,
  appointmentId: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_FW_APPOINTMENT_FILES, appointmentId],
    queryFn: ({ pageParam = 1 }) =>
      getFwAppointmentFiles(appointmentId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
    enabled: !!appointmentId,
  });

interface IUseUploadFwAppointmentFileParams {
  files: any;
  appointmentId: string;
}
export const useUploadFwAppointmentFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadFwAppointmentFileParams) => {
      const fileFormData = new FormData();
      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      return uploadFwAppointmentFile(params.appointmentId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT_INFO
        );
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_FW_APPOINTMENT_FILES,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_FW_APPOINTMENT_FILE,
        });
      },
      onSettled: () => {
        // onSettled
      },
      mutationKey: ReactMutationKeys.UPLOAD_FW_APPOINTMENT_FILE,
    }
  );
};

export const useEditAppointmentFileName = (
  appointmentId: string,
  fileId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (newFileName: string) =>
      editAppointmentFile(appointmentId, fileId, newFileName),
    {
      onSuccess: () => {
        toast.success(t('File name updated'), {
          toastId: ReactMutationKeys.EDIT_FW_APPOINTMENT_FILE_NAME,
        });
        const shouldInvalidateInfoQueries = false;
        invalidateFileQueries(shouldInvalidateInfoQueries);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_FW_APPOINTMENT_FILE_NAME,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_FW_APPOINTMENT_FILE_NAME,
    }
  );
};

interface IUseDeleteAppointmentFile {
  appointmentId: string;
  fileId: string;
}
export const useDeleteAppointmentFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeleteAppointmentFile) =>
      deleteAppointmentFile(params.appointmentId, params.fileId),
    {
      onSuccess: (data) => {
        toast.success(t('File successfully deleted'));
        invalidateFileQueries();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_FW_APPOINTMENT_FILE,
        });
      },
      mutationKey: ReactMutationKeys.DELETE_FW_APPOINTMENT_FILE,
    }
  );
};
