import { IQuotation, QuotationTypeIdsEnum } from 'types/Quotations.types';
import { IsDraftLabel, SwitchWrapper } from './DraftQuotationSwitch.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import { Switch } from '@mui/material';
import { useChangeQuotationType } from './hooks';
import { useTranslation } from 'react-i18next';

interface IDraftQuotationSwitchProps {
  quotation: IQuotation;
  editable?: boolean;
}

const DraftQuotationSwitch = ({
  quotation,
  editable,
}: IDraftQuotationSwitchProps) => {
  const { mutate: changeQuotationType, isLoading } = useChangeQuotationType();
  const { t } = useTranslation();

  if (!quotation) {
    return <div />;
  }
  let isDraft: boolean | null = null;
  if (quotation.type?.id === QuotationTypeIdsEnum.DRAFT) {
    isDraft = true;
  }
  if (quotation.type?.id === QuotationTypeIdsEnum.DEFINITIVE) {
    isDraft = false;
  }

  const getIsDraftLabel = () => {
    if (isDraft === null) {
      return t('Undetermined quotation type');
    }
    return isDraft ? t('Draft quotation') : t('Definitive quotation');
  };

  const getTooltipText = () => {
    if (isDraft === null) {
      return t(
        `This quotation does not have a type 'draft' or 'definitive' because it was made before the update. You can handle this as you normally would, or indicate wether it's a 'draft' or 'definitive' quotation and continue in the new method`
      );
    }
    return isDraft
      ? t('This quotation is a draft')
      : t('This quotation is a definitive quotation');
  };

  const getIsChecked = () => {
    if (isDraft === null) {
      return false;
    }
    return !isDraft;
  };

  const handleClick = () => {
    if (isLoading) {
      return;
    }

    if (isDraft) {
      changeQuotationType({
        quotationId: quotation.id,
        quotationTypeId: QuotationTypeIdsEnum.DEFINITIVE,
      });
    } else {
      changeQuotationType({
        quotationId: quotation.id,
        quotationTypeId: QuotationTypeIdsEnum.DRAFT,
      });
    }
  };

  return (
    <SwitchWrapper>
      <IsDraftLabel isDraft={isDraft}>{getIsDraftLabel()}</IsDraftLabel>
      {editable && (
        <Tooltip content={getTooltipText()}>
          <Switch
            data-testid={'draft-switch'}
            checked={getIsChecked()} // Switched for presentation purpose
            onChange={handleClick}
          />
        </Tooltip>
      )}
    </SwitchWrapper>
  );
};

export default DraftQuotationSwitch;
