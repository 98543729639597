import { IAppointment } from 'types/Appointment.types';
import { ISalesInvoice } from 'types/Invoice.types';
import { SalesOrderPaymentStatusIdEnum } from 'types/SalesOrders.types';

export const getReportSignatureFullNameFromAppointment = (
  appointment: IAppointment
): string => {
  if (appointment) {
    if (appointment?.quotation?.different_shipping_address)
      return (
        appointment.quotation.shipping_data.first_name +
        ' ' +
        appointment.quotation.shipping_data.last_name
      );
    else if (appointment?.sales_order?.customer_snapshot)
      return (
        appointment?.sales_order?.customer_snapshot?.shipping_data.first_name +
        ' ' +
        appointment?.sales_order?.customer_snapshot?.shipping_data.last_name
      );
    else if (appointment?.customer)
      return (
        appointment?.customer?.name + ' ' + appointment?.customer?.last_name
      );
    else return '';
  }
  return '';
};

export const removeInactiveInvoices = (salesInvoices: ISalesInvoice[]) => {
  return salesInvoices.filter((salesInvoice: ISalesInvoice) =>
    [
      SalesOrderPaymentStatusIdEnum.NEW,
      SalesOrderPaymentStatusIdEnum.SENT,
      SalesOrderPaymentStatusIdEnum.PARTLY_PAID,
    ].includes(Number(salesInvoice.payment_status.id))
  );
};
