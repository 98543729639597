import moment from 'moment';
import { IAppointmentInterval } from 'types/Appointment.types';

export const formatIntervalTotalTime = (totalTime: number) => {
  // Create a moment duration from the number of seconds
  const duration = moment.duration(totalTime, 'seconds');

  // Extract
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the output as HH:MM
  const formattedTime = moment
    .utc()
    .hours(hours)
    .minutes(minutes)
    .seconds(seconds)
    .format('HH:mm:ss');
  return formattedTime + 'h';
};

export const groupIntervalsByEntity = (
  intervals: IAppointmentInterval[]
): IAppointmentInterval[] => {
  const groupedIntervals = new Map<string, IAppointmentInterval[]>();

  // Group intervals by worker or resource name
  intervals.forEach((interval) => {
    const key = interval.worker?.name || interval.resource?.name || '';
    if (!groupedIntervals.has(key)) {
      groupedIntervals.set(key, []);
    }
    groupedIntervals.get(key)?.push(interval);
  });

  // Sum and merge intervals for each group
  const newIntervals: IAppointmentInterval[] = [];
  groupedIntervals.forEach((group) => {
    if (group.length > 0) {
      const summedInterval: IAppointmentInterval = group.reduce(
        (acc, curr) => {
          acc.start_date =
            acc.start_date < curr.start_date ? acc.start_date : curr.start_date;
          acc.end_date =
            acc.end_date > curr.end_date || !acc.end_date
              ? acc.end_date
              : curr.end_date;
          acc.total += curr.total;
          return acc;
        },
        { ...group[0], total: 0 }
      );
      newIntervals.push(summedInterval);
    }
  });

  return newIntervals;
};

export const sumIntervalsTotalTime = (
  intervals: IAppointmentInterval[]
): string => {
  let totalCombinedTime = 0;

  // Summing up the total field of each interval
  intervals.forEach((interval) => {
    totalCombinedTime += interval.total;
  });

  return formatIntervalTotalTime(Number(totalCombinedTime));
};
