import { useTranslation } from 'react-i18next';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useMutation, useQuery } from 'react-query';
import {
  changePurchaseInvoiceExternalId,
  changePurchaseInvoiceStatus,
  exportPurchaseInvoice,
  getSinglePurchaseInvoice,
} from 'services/Invoice/PurchaseInvoiceService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { queryClient } from 'index';

export const useGetSinglePurchaseCreditInvoice = (invoiceId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_PURCHASE_CREDIT_INVOICE, invoiceId],
    queryFn: () => {
      return getSinglePurchaseInvoice(invoiceId);
    },
    onSuccess: () => {
      // On success
    },
  });

export const useGetSinglePurchaseInvoice = (invoiceId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE, invoiceId],
    queryFn: () => {
      return getSinglePurchaseInvoice(invoiceId);
    },
  });

export const useExportPurchaseInvoice = () =>
  useMutation(
    (purchaseInvoiceId: string) => exportPurchaseInvoice(purchaseInvoiceId),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EXPORT_PURCHASE_INVOICE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EXPORT_PURCHASE_INVOICE,
    }
  );

export const useChangePurchaseInvoiceStatus = () =>
  useMutation(
    (params: { id: string; status_id: string }) =>
      changePurchaseInvoiceStatus(params.id, params.status_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_PURCHASE_INVOICE_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_INVOICES);
      },
      mutationKey: ReactMutationKeys.CHANGE_PURCHASE_INVOICE_STATUS,
    }
  );

export const useChangeCreditPurchaseInvoiceExternalId = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: string; external_id: string }) =>
      changePurchaseInvoiceExternalId(params.id, params.external_id),
    {
      onSuccess: (data) => {
        toast.success(t('External reference edited'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_CREDIT_INVOICE
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_INVOICES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_CREDIT_PURCHASE_INVOICE_REFERENCE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.CHANGE_CREDIT_PURCHASE_INVOICE_REFERENCE,
    }
  );
};
