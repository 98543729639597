import React from 'react';
import * as Sentry from '@sentry/react';
import { EnvironmentType, config } from 'config';

interface ISentryProviderProps {
  children: React.ReactNode;
}

if (config.sentry.dsnUrl) {
  Sentry.init({
    dsn: config.sentry.dsnUrl,
    environment:
      config.environment === EnvironmentType.PROD
        ? EnvironmentType.PROD
        : EnvironmentType.DEV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/erp.codeflex.dev\/api/,
          /^https:\/\/app.blue-print\.io\/api/,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const SentryProvider = ({ children }: ISentryProviderProps) => {
  return <>{children}</>;
};

export default SentryProvider;
