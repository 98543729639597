import { Option } from 'components/Select/type';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
} from 'types/Product.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export const getDropdownProductAttributeOptions = (options: string[]) => {
  const optionsResult: Option[] = [];
  for (const option of options) {
    optionsResult.push({
      value: option,
      label: option,
      key: option,
    });
  }
  return optionsResult;
};

export const useGetDropdownDefaultValue = (attribute: IProductAttribute) => {
  if (attribute.defaultOptionIndex === null || !attribute.options) {
    return undefined;
  }
  const option = attribute?.options[attribute.defaultOptionIndex];
  return {
    value: option,
    label: option,
    key: option,
  };
};

export const useInitializeOptionsFromDocVisibilityOptions = (
  docVisibilityOptions: IDocVisibilityOptionEnum[]
): [Option[], Option[]] => {
  let initiallySelectedDocVisibilityOptions = [];
  let allDocVisibilityOptions = [];

  // For dropdown options
  // Initializing all possible dropdown options for document visibility
  allDocVisibilityOptions = Object.values(IDocVisibilityOptionEnum).map(
    (docSelectEnumValue: string) => {
      const option: Option = {
        value: docSelectEnumValue,
        label: `${capitalizeFirstLetter(
          docSelectEnumValue.toLowerCase().replace('_', ' ')
        )}`,
      };
      return option;
    }
  );

  // For dropdown default value prop
  // Initializing currently selected options for document visibility
  initiallySelectedDocVisibilityOptions = docVisibilityOptions.map(
    (docSelectEnumValue: string) => {
      const option: Option = {
        value: docSelectEnumValue,
        label: `${capitalizeFirstLetter(
          docSelectEnumValue.toLowerCase().replace('_', ' ')
        )}`,
      };
      return option;
    }
  );

  return [initiallySelectedDocVisibilityOptions, allDocVisibilityOptions];
};

export const formatOptionsIntoDocVisibilityOptionEnumsArray = (
  options: Option[]
): IDocVisibilityOptionEnum[] => {
  const docVisibilityOptions: IDocVisibilityOptionEnum[] = [];
  options.forEach((option: Option) => {
    docVisibilityOptions.push(option.value as IDocVisibilityOptionEnum);
  });
  return docVisibilityOptions;
};
