import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const StyledDropdown = styled.div`
  position: relative;
  padding-left: 30rem;
  font-size: 18rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  margin-top: 10rem;
`;

export const ChevronDown = styled.div`
  position: absolute;
  right: -30rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const PlusIcon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const Menu = styled.div`
  width: 180rem;
  position: absolute;
  top: 30rem;
  left: 30rem;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.GREY_1000};
  border-radius: 10rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
`;

export const Item = styled.div`
  display: flex;
  color: ${COLORS.GREY_1000};
  justify-content: flex-start;
  align-items: center;
  padding: 5rem 15rem;
  font-size: 16rem;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${COLORS.GREY_300};
  }
`;
