import { IResourceWorkingHours } from 'pages/FieldWorker/FwReportPage/ReportForm/SituationDescriptionResourceSection/SituationDescriptionResourceSection';
import { IReportResource } from 'types/Appointment.types';

export const formatReportResourcesIntoResourceWorkingHours = (
  reportResources: IReportResource[]
): IResourceWorkingHours[] => {
  if (!reportResources || !Array.isArray(reportResources)) {
    return [];
  }
  const reportWorkingHours: IResourceWorkingHours[] = [];
  for (const reportResource of reportResources) {
    const reportWorkingHour: IResourceWorkingHours = {
      resource: reportResource.resource,
      workingHours: reportResource.expected_working_hours,
    };
    reportWorkingHours.push(reportWorkingHour);
  }

  return reportWorkingHours;
};
