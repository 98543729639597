import { useGetFwAppointmentIntervals } from 'pages/FieldWorker/FwSingleAppointmentPage/FwTimer/hooks';
import { useGetSingleSalesOrderInvoices } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderInvoicesPage/hooks';

export const useGetPreviewReportPageData = (
  appointmentId: number,
  salesOrderId: string
) => {
  const { data: intervalsData, isLoading: isLoadingIntervals } =
    useGetFwAppointmentIntervals(appointmentId?.toString());
  const { data: invoicesData, isLoading: isLoadingInvoices } =
    useGetSingleSalesOrderInvoices(salesOrderId?.toString(), 1, 50);

  const data = {
    intervalsData,
    invoicesData,
  };

  return {
    data,
    isLoadingIntervals,
    isLoadingInvoices,
  };
};
