import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editStockItemDetails } from 'services/StockItems/StockItemsService';

export interface IEditStockDTO {
  name?: string;
  price?: string;
  purchase_price?: string;
  description?: string;
  number_supplier?: string;
}

export const useEditStock = (stockItemId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editStockDTO: IEditStockDTO) =>
      editStockItemDetails(editStockDTO, stockItemId),
    {
      onSuccess: () => {
        toast.success(t('Stock item updated'), {
          className: ReactMutationKeys.EDIT_STOCK_ITEM,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_STOCK_ITEM,
          stockItemId,
        ]);
        queryClient.invalidateQueries(ReactQueryKeys.GET_STOCK_ITEMS);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_STOCK_ITEM,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_STOCK_ITEM,
    }
  );
};
