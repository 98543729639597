import { Option } from 'components/Select/type';
import { IFilterGroup, IFilter } from './types';

const transformFilter = (filter: IFilter): any => {
  return {
    field: filter.type,
    op: filter.condition,
    value: filter.value?.id
      ? filter.value?.id
      : filter.value?.target
      ? filter.value?.target.value
      : filter.value?.value
      ? filter.value?.value
      : filter.value,
  };
};

const nestFilters = (filters: IFilter[]): any[] => {
  if (filters.length === 0) return [];

  const result: any[] = [];
  let currentGroup: any[] = [];
  let currentPrecondition: any = null;

  filters.forEach((filter, index) => {
    if (filter.value === '') return;

    const transformedFilter = transformFilter(filter);
    const precondition = filter.precondition;

    if (index === 0) {
      result.push(transformedFilter);
    } else {
      if (currentGroup.length > 0) {
        result.push({ [currentPrecondition!]: currentGroup });
      }
      currentPrecondition = precondition;
      currentGroup = [transformedFilter];
    }
  });

  if (currentGroup.length > 0) {
    result.push({ [currentPrecondition!]: currentGroup });
  }

  return result;
};

export const prepareData = (filterGroups: IFilterGroup[]) => {
  if (filterGroups.length === 0) return [];

  const result: any[] = [];

  filterGroups.forEach((group, groupIndex) => {
    const filters = nestFilters(group.filters);
    const subFilters = nestFilters(group.subFilters);

    if (groupIndex === 0 && filters.length > 0) {
      result.push(...filters);
    } else {
      if (filters.length > 0) {
        const precondition: any = group.filters[0]?.precondition;
        result.push({ [precondition]: filters });
      }
    }

    if (subFilters.length > 0) {
      const subPrecondition: any = group.subFilters[0]?.precondition;
      result.push({ [subPrecondition]: subFilters });
    }
  });

  return result;
};

export const checkEmptyValues = (data: IFilterGroup[]): boolean => {
  for (const group of data) {
    for (const filter of group.filters) {
      if (filter.value === '') {
        return true;
      }
    }
    for (const subFilter of group.subFilters) {
      if (subFilter.value === '') {
        return true;
      }
    }
  }
  return false;
};

export const generateUniqueId = () => Math.random().toString(36).substr(2, 9);

export const unpackData = (data: any[]): IFilterGroup[] => {
  const result: IFilterGroup[] = [];

  data.forEach((item) => {
    if (item.field) {
      result.push({
        id: generateUniqueId(),
        filters: [
          {
            id: generateUniqueId(),
            type: item.field,
            condition: item.op,
            value: item.value,
          },
        ],
        subFilters: [],
      });
    } else if (typeof item === 'object' && item !== null) {
      const [precondition, subFilterGroup] = Object.entries(item)[0];

      if (Array.isArray(subFilterGroup)) {
        const subFilters = subFilterGroup.flatMap((subItem: any) => {
          if (subItem.field) {
            return [
              {
                id: generateUniqueId(),
                type: subItem.field,
                condition: subItem.op,
                value: subItem.value,
                precondition,
              },
            ];
          } else if (typeof subItem === 'object' && subItem !== null) {
            const nestedPrecondition = Object.keys(subItem)[0];
            const nestedFilters = unpackData([subItem]);
            return nestedFilters.flatMap((group) =>
              group.subFilters.map((subFilter) => ({
                ...subFilter,
                precondition: nestedPrecondition,
              }))
            );
          }
          return [];
        });

        result.push({
          id: generateUniqueId(),
          filters: [],
          subFilters,
        });
      }
    }
  });

  return result;
};

export const getDefaultValue = (
  value: any,
  options: Option[]
): Option | undefined => {
  if (options.length > 0) {
    if (
      typeof value === 'object' &&
      value !== null &&
      'label' in value &&
      'value' in value
    ) {
      return value;
    } else if (typeof value === 'string' || typeof value === 'number') {
      return options.find(
        (option) => option.id === value || option.value === value
      );
    }
  }
  return undefined;
};
