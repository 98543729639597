import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { connectToThirdParty } from 'services/Invoice/SalesInvoiceService';
import { ICompanySettings } from 'types/Company.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { ITwinfieldCustomer } from 'types/Customer.types';

export const useConnectToThirdParty = (
  salesInvoiceId: number,
  isCredit: boolean
) =>
  useMutation(() => connectToThirdParty(salesInvoiceId), {
    onSuccess: () => {
      if (isCredit)
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_CREDIT_INVOICE
        );
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
    },
    onError: (error: ERPError) => {
      if (error.response.data.code != 409) {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CONNECT_SALES_INVOICE_TO_THIRD_PARTY,
        });
      }
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.CONNECT_SALES_INVOICE_TO_THIRD_PARTY,
  });

// Action is available if twinfield is activated for company and there is no twinfield customer connected to the current customer
export const useIsTwinfieldCustomerActionAvailable = (
  twinfieldCustomer: ITwinfieldCustomer | undefined
) => {
  const companySettings: ICompanySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  return (
    companySettings?.accounting_connector_type_id === 1 && !twinfieldCustomer
  );
};
