export const capitalizeFirstLetter = (word: string) => {
  if (!word) return word;
  const lowerCaseString = word.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
};

export const convertToTitleCase = (input: string): string => {
  // Replace uppercase letters preceded by a lowercase letter with a space and the uppercase letter
  const titleCase = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Capitalize the first letter
  const capitalized = titleCase.charAt(0).toUpperCase() + titleCase.slice(1);
  return capitalized;
};

export const cutLongWords = (value: string, size: number) => {
  return value.length > size ? `${value.slice(0, size)}...` : value;
};
