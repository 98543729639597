import { CSSProperties } from '@mui/styled-engine';
import styled, { css } from 'styled-components';

interface StyledProps {
  containerStyle: CSSProperties;
  isDragging: boolean;
}

export const Container = styled.div`
  ${({ containerStyle }: StyledProps) => containerStyle}
  transition: opacity 200ms ease-out;
  ${({ isDragging }: StyledProps) =>
    isDragging &&
    css`
      opacity: 0;
    `}
`;
