import { Option } from 'components/Select/type';

export const STOCK_ITEM_SERVICE_TYPE_ID = '2';
export const useGetTypeOptions = () => {
  const typeOptions: Option[] = [
    { value: '1', label: 'Normal', key: 'normal' },
    { value: '2', label: 'Service', key: 'service' },
  ];
  return typeOptions;
};
