import { BASE_64_PREFIX } from 'pages/Manager/SingleQuotation/constants';
import { AppointmentType, AppointmentTypeIds } from 'types/Appointment.types';

export const mapAppointmentTypeValueToId = (appointmentTypeString: string) => {
  switch (appointmentTypeString.toUpperCase()) {
    case AppointmentType.MEASURING:
      return AppointmentTypeIds.MEASURING;
    case AppointmentType.INSTALLATION:
      return AppointmentTypeIds.INSTALLATION;
    case AppointmentType.GENERAL:
      return AppointmentTypeIds.GENERAL;
    case AppointmentType.SERVICE:
      return AppointmentTypeIds.SERVICE;
    default:
      return '';
  }
};

export const removeBase64Prefix = (base64url: string) => {
  return base64url.replace(BASE_64_PREFIX, '');
};
