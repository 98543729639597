import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/common';

export const getCityAndAddress = async (
  zipCode: string,
  houseNumber: string
): Promise<any> => {
  const queryParamsObj = {
    zip_code: zipCode,
    house_number: houseNumber,
  };
  const fullUrl = getUrlWithQueryParams(`${BASE_URL}/address`, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const fetchCommonData = async (): Promise<any> => {
  const url = `${BASE_URL}/data`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};
