import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import {
  createQuotationLine,
  editQuotationLine,
} from 'services/Quotation/QuotationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  ICreateQuotationLineDTO,
  IEditQuotationLineData,
} from 'types/Quotations.types';

export const useAddQuotationLine = (quotationId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (createQuotationLineData: ICreateQuotationLineDTO) =>
      createQuotationLine(quotationId, createQuotationLineData),
    {
      onSuccess: () => {
        toast.success(t('Successfully added Line to Quotation'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_QUOTATION_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.ADD_QUOTATION_LINE,
    }
  );
};

export interface IUseEditQuotationLine {
  quotationLineId: string | undefined;
  editQuotationLineData: IEditQuotationLineData;
}
export const useEditQuotationLine = (quotationId: string) => {
  const { t } = useTranslation();

  return useMutation(
    (params: IUseEditQuotationLine) =>
      editQuotationLine(
        quotationId,
        params.quotationLineId!,
        params.editQuotationLineData
      ),
    {
      onSuccess: (data) => {
        toast.success(t('Quotation line edited'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION,
          quotationId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_LINE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.EDIT_QUOTATION_LINE,
    }
  );
};

export const useGetPrepopulateLineData = () => {
  const { state } = useLocation();
  const line = state?.line || null;

  return line;
};
