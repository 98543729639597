import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  createSalesInvoice,
  getSalesInvoiceCreationInfo,
} from 'services/Invoice/SalesInvoiceService';
import {
  ICreateDownpaymentInvoiceDTO,
  ICreateSalesInvoiceDTO,
} from 'types/Invoice.types';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useEffect, useState } from 'react';
import {
  getCreateDownpaymentInvoicePayload,
  getCreateInvoicePayload,
} from './helpers';
import { ISalesOrder } from 'types/SalesOrders.types';
import { ICreateInvoiceDetails } from './InvoiceDetailsTab/constants';
import { useTranslation } from 'react-i18next';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useCreateSalesInvoice = () => {
  const { t } = useTranslation();
  return useMutation(
    (
      createSalesInvoiceData:
        | ICreateSalesInvoiceDTO
        | ICreateDownpaymentInvoiceDTO
    ) => createSalesInvoice(createSalesInvoiceData),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        if (
          error?.response?.data?.errors?.[0].includes('No lines for invoice')
        ) {
          toast.error(t('No lines for invoice'), {
            toastId: ReactMutationKeys.CREATE_SALES_INVOICE,
          });
        } else {
          toast.error(getToastErrorMessage(error), {
            toastId: ReactMutationKeys.CREATE_SALES_INVOICE,
          });
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
      },
      mutationKey: ReactMutationKeys.CREATE_SALES_INVOICE,
    }
  );
};

export const useGetPrepopulateInvoiceData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulateInvoiceData;
  } else {
    return null;
  }
};

export const useGetSalesInvoiceCreationInfo = () =>
  useMutation(
    (
      createSalesInvoiceData:
        | ICreateSalesInvoiceDTO
        | ICreateDownpaymentInvoiceDTO
    ) => getSalesInvoiceCreationInfo(createSalesInvoiceData),
    {
      onSuccess: () => {
        // onSuccess
      },
      onError: () => {
        // onError
      },
      mutationKey: ReactMutationKeys.GET_INVOICE_CREATION_INFO,
    }
  );

export const useSalesInvoiceCreationInfo = (
  isSuccessGetSalesOrderInvoices: boolean,
  prepopulateInvoiceData: ISalesOrder,
  invoiceDetails: ICreateInvoiceDetails,
  selectedPartialIds: number[],
  isDownPaymentInvoiceCreated: boolean,
  salesOrderInvoices: any
) => {
  const [
    selectedPartialIdsForWhichInfoWasFetched,
    setSelectedPartialIdsForWhichInfoWasFetched,
  ] = useState<number[]>([]);

  const {
    data: invoiceCreationInfoData,
    mutate: getInvoiceCreationInfo,
    isLoading: isLoadingInvoiceCreationInfo,
  } = useGetSalesInvoiceCreationInfo();

  useEffect(() => {
    // Dont fetch if already fetching or fetched for provided ids, and dont fetch if sales order invoices havent been fetched yet
    if (
      isLoadingInvoiceCreationInfo ||
      selectedPartialIdsForWhichInfoWasFetched === selectedPartialIds ||
      !isSuccessGetSalesOrderInvoices
    ) {
      return;
    }
    if (isDownPaymentInvoiceCreated) {
      // Fetching of invoice creation info for normal invoices
      const createInvoiceData: ICreateSalesInvoiceDTO = getCreateInvoicePayload(
        prepopulateInvoiceData,
        invoiceDetails,
        selectedPartialIds,
        isDownPaymentInvoiceCreated,
        salesOrderInvoices?.sales_invoices || []
      );
      getInvoiceCreationInfo(createInvoiceData, {
        onSuccess: () =>
          setSelectedPartialIdsForWhichInfoWasFetched(selectedPartialIds),
      });
    } else {
      // Fetching of invoice creation info for downpayment invoices
      const createDownpaymentInvoiceData: ICreateDownpaymentInvoiceDTO =
        getCreateDownpaymentInvoicePayload(
          prepopulateInvoiceData,
          invoiceDetails
        );
      getInvoiceCreationInfo(createDownpaymentInvoiceData, {
        onSuccess: () =>
          setSelectedPartialIdsForWhichInfoWasFetched(selectedPartialIds),
      });
    }
  }, [isSuccessGetSalesOrderInvoices, selectedPartialIds]);

  return { invoiceCreationInfoData, isLoadingInvoiceCreationInfo };
};
