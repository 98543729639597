import { EnvironmentType, config } from 'config';
import {
  Container,
  EnvironmentLabel,
  EnvironmentLabelWrapper,
} from './EnvironmentBanner.styled';
import Tooltip from 'components/Tooltip/Tooltip';

const EnvironmentBanner = () => {
  if (config.environment === EnvironmentType.PROD) {
    return <></>;
  }

  const getEnvironmentLabel = () => {
    if (config.api.baseUrl?.startsWith('http://')) {
      return 'LOCAL';
    }
    if (config.api.baseUrl?.includes('staging')) {
      return 'STAGING';
    }
    if (config.api.baseUrl?.endsWith('.dev')) {
      return 'DEV';
    }
    if (config.api.baseUrl?.endsWith('.io')) {
      return 'PRODUCTION';
    }
    return 'UNKNOWN';
  };

  return (
    <Container>
      <Tooltip content={`API URL: ${config.api.baseUrl}`} placement="bottom">
        <EnvironmentLabelWrapper>
          <EnvironmentLabel>{getEnvironmentLabel()}</EnvironmentLabel>
        </EnvironmentLabelWrapper>
      </Tooltip>
    </Container>
  );
};

export default EnvironmentBanner;
