import {
  CloseContainer,
  LoaderWrapper,
  NoContentLabel,
  Scrollable,
} from './PreviewDayAppointmentsModal.styled';
import { Close } from 'components/Close/Close';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction } from 'react';
import { Level } from '../type';
import { IAppointment } from 'types/Appointment.types';
import { PreviewDayAppointmentCard } from './PreviewDayAppointmentCard/PreviewDayAppointmentCard';
import { useTranslation } from 'react-i18next';
import { useGetDaySummaryAppointments } from './hooks';
import { Moment } from 'moment';
import { IQuotationLine } from 'types/Quotations.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import Spinner from 'components/Spinner/Spinner';

interface IPreviewDayAppointmentsModalProps {
  dateForDaySummary: Moment | null;
  setDateForDaySummary: Dispatch<SetStateAction<Moment | null>>;
  level?: Level;
}

export interface IAppointmentSummaryData {
  appointment: IAppointment;
  quotation_lines: IQuotationLine[];
  sales_order_lines: ISalesOrderLine[];
}

export const PreviewDayAppointmentsModal = ({
  dateForDaySummary,
  setDateForDaySummary,
  level = 'FIRST',
}: IPreviewDayAppointmentsModalProps) => {
  const { t } = useTranslation();

  const { data: appointmentsData, isLoading: isLoadingDaySummaryAppointments } =
    useGetDaySummaryAppointments(dateForDaySummary);

  const isOpen: boolean = dateForDaySummary !== null;
  if (!isOpen) {
    return <></>;
  }

  const appointmentSummariesForSelectedDayId: IAppointmentSummaryData[] =
    appointmentsData?.appointments || [];

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setDateForDaySummary(null);
        }
      }}
      level={level}
      modalStyle={{ position: 'fixed', margin: 'auto', width: '500rem' }}
    >
      <CloseContainer>
        <Close onClick={() => setDateForDaySummary(null)} />
      </CloseContainer>
      {!appointmentSummariesForSelectedDayId.length ? (
        isLoadingDaySummaryAppointments ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <NoContentLabel>
            {t('There are currently no appointments for the selected day') +
              '.'}
          </NoContentLabel>
        )
      ) : (
        <Scrollable>
          {appointmentSummariesForSelectedDayId.map(
            (appointmentSummary: IAppointmentSummaryData, index: number) => (
              <PreviewDayAppointmentCard
                key={index}
                appointmentSummary={appointmentSummary}
                isLast={
                  appointmentSummariesForSelectedDayId.length - 1 <= index
                }
              />
            )
          )}
        </Scrollable>
      )}
    </Modal>
  );
};
