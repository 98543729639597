// Single Quotation
export const SingleQuotationRoutes = {
  SingleQuotationGeneral: {
    path: '',
    fullPath: '/quotations',
  },
  SingleQuotationProducts: {
    path: 'products',
    fullPath: '/quotations/:id/products',
  },
  SingleQuotationComments: {
    path: 'comments',
    fullPath: '/quotations/:id/comments',
  },
  SingleQuotationQuotations: {
    path: 'quotations',
    fullPath: '/quotations/:id/quotations',
  },
  SingleQuotationSales: {
    path: 'sales',
    fullPath: '/quotations/:id/sales',
  },
  SingleQuotationAppointments: {
    path: 'appointments',
    fullPath: '/quotations/:id/appointments',
  },
  SingleQuotationFiles: {
    path: 'files',
    fullPath: '/quotations/:id/files',
  },
  SingleQuotationReports: {
    path: 'reports',
    fullPath: '/quotations/:id/reports',
  },
  SingleQuotationNewComment: {
    path: 'comment',
    fullPath: '/quotations/:id/comment',
  },
  SingleQuotationSignature: {
    path: 'signature',
    fullPath: '/quotations/:id/signature',
  },
};
