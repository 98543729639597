import { useQuery } from 'react-query';
import { getRunningFwAppointments } from 'services/Appointment/AppointmentService';
import { getBlockedFwAppointments } from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetRunningAppointments = (isEnabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_RUNNING_APPOINTMENTS],
    queryFn: () => {
      return getRunningFwAppointments();
    },
    enabled: isEnabled,
  });

export const useGetBlockedFwAppointments = (isEnabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_BLOCKED_FW_APPOINTMENTS],
    queryFn: () => {
      return getBlockedFwAppointments();
    },
    enabled: isEnabled,
  });
