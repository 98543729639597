import moment from 'moment';
import { IAppointmentInterval } from 'types/Appointment.types';

export const getIntervalLabel = (
  interval: IAppointmentInterval,
  userLabel: string
) => {
  if (interval.resource) {
    return interval.resource.name;
  } else if (interval.worker) {
    return interval.worker.name;
  } else return userLabel;
};

export const calculateTotalTime = (intervals: IAppointmentInterval[]) => {
  if (!intervals || !intervals.length) {
    return '/';
  }
  let totalTime = 0;
  for (const interval of intervals) {
    if (interval.worker) {
      totalTime = totalTime + interval.total;
    }
  }
  if (!totalTime) {
    return '/';
  }

  // Create a moment duration from the number of seconds
  const duration = moment.duration(totalTime, 'seconds');

  // Extract
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  // Format the output as HH:MM
  const formattedTime = moment
    .utc()
    .hours(hours)
    .minutes(minutes)
    .seconds(seconds)
    .format('HH:mm:ss');
  return formattedTime + 'h';
};

export const splitIntervalByPauses = (interval: IAppointmentInterval) => {
  const subIntervals = [];

  let currentStart = interval.start_date;
  interval.pauses.forEach((pause) => {
    subIntervals.push({
      start: currentStart,
      end: pause.start_date,
    });
    currentStart = pause.end_date;
  });

  if (currentStart) {
    // Add the last sub-interval after the last pause (or the whole interval if no pauses)
    subIntervals.push({
      start: currentStart,
      end: interval.end_date || '',
    });
  }

  return subIntervals;
};

export const removeUserIntervals = (
  intervals: IAppointmentInterval[]
): IAppointmentInterval[] => {
  if (!intervals || !intervals.length) {
    return [];
  }
  return intervals.filter(
    (interval: IAppointmentInterval) => interval.worker || interval.resource
  );
};
