import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  activateCustomer,
  addCommentForCustomer,
  deleteCommentForCustomer,
  deleteCustomer,
  getCustomerComments,
  getCustomerInfoById,
  getCustomerQuotations,
  getSingleCustomer,
} from 'services/Customer/CustomerService';
import { IComment } from './types';

export const useGetSingleCustomer = (customerId: string, enabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_CUSTOMER, customerId],
    queryFn: () => {
      return getSingleCustomer(customerId);
    },
    enabled: enabled && !!customerId,
  });

export const useGetSingleCustomerQuotations = (customerId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_CUSTOMER_QUOTATIONS, customerId],
    queryFn: () => {
      return getCustomerQuotations(customerId);
    },
  });

export const useDeleteCustomer = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteCustomer(id), {
    onSuccess: (data) => {
      toast.success(t('Customer deactivated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_CUSTOMER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_CUSTOMER);
      queryClient.invalidateQueries(ReactQueryKeys.GET_CUSTOMERS);
    },
    mutationKey: ReactMutationKeys.DELETE_CUSTOMER,
  });
};

export const useActivateCustomer = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => activateCustomer(id), {
    onSuccess: (data) => {
      toast.success(t('Customer activated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ACTIVATE_CUSTOMER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_CUSTOMER);
      queryClient.invalidateQueries(ReactQueryKeys.GET_CUSTOMERS);
    },
    mutationKey: ReactMutationKeys.ACTIVATE_CUSTOMER,
  });
};

export const useGetSingleCustomerComments = (
  customerId: string,
  isModalOpen: boolean,
  page?: number,
  perPage?: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_CUSTOMER_COMMENTS,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerComments(customerId, page, perPage);
    },
    enabled: !!isModalOpen,
  });

export const useAddCustomerComment = (customerId: string) =>
  useMutation(
    (commentDTO: IComment) => addCommentForCustomer(customerId, commentDTO),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_CUSTOMER_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_CUSTOMER_COMMENTS
        );
      },
      mutationKey: ReactMutationKeys.ADD_CUSTOMER_COMMENT,
    }
  );

export const useDeleteCustomerComment = (customerId: string) =>
  useMutation(
    (commentId: string) => deleteCommentForCustomer(customerId, commentId),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_CUSTOMER_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_CUSTOMER_COMMENTS
        );
      },
      mutationKey: ReactMutationKeys.DELETE_CUSTOMER_COMMENT,
    }
  );

export const useGetSingleCustomerInfo = (customerId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_CUSTOMER_INFO, customerId],
    queryFn: () => {
      return getCustomerInfoById(customerId);
    },
  });
