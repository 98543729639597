import React, { createContext, useContext, useEffect, useRef } from 'react';
import {
  onLogyxConfigurationFinishedEvent,
  onLogyxEditConfigurationFinishedEvent,
  onLogyxPurchaseOrderLineReconfigurationFinishedEvent,
} from 'pages/Manager/NewQuotationPage/ProductTab/AddNewProduct/ConfigureLogyxModal/events';
import { initializeSocket } from './socketManager';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import { io } from 'socket.io-client';
import { config } from 'config';
import { ISocket } from './types';
import { IRootReducerState, store } from 'store/store';
import { useSelector } from 'react-redux';
import { setIsSocketConnected } from 'store/Common/actions/common';

const URL = config.api.baseUrl ? config.api.baseUrl : 'MY_WS_URL';
let socket: ISocket = initializeSocket();

const handleOnLogyxConfigurationFinishedEvent = (event: any) => {
  onLogyxConfigurationFinishedEvent(event, socket);
};
const handleOnLogyxEditConfigurationFinishedEvent = (event: any) => {
  onLogyxEditConfigurationFinishedEvent(event, socket);
};
const handleOnLogyxPurchaseOrderLineReconfigurationFinishedEvent = (
  event: any
) => {
  onLogyxPurchaseOrderLineReconfigurationFinishedEvent(event, socket);
};

export function updateSocketToken(token: string) {
  let wasSocketConnected = false;
  if (!socket) {
    throw new Error(
      'Socket has not been initialized. Call initializeSocket() first.'
    );
  } else {
    if (store.getState().commonInfo.isSocketConnected) {
      wasSocketConnected = true;
      disconnectSocket();
    }
  }

  // Prevent infinite updateSocketToken loop
  if (socket.io.opts.auth?.token === `Bearer ${token}`) {
    return;
  }

  socket = io(URL, {
    autoConnect: false,
    transports: ['websocket'],
    auth: {
      token: `Bearer ${token}`,
    },
  });

  if (wasSocketConnected) {
    connectSocket();
  }
}

function onConnect() {
  store.dispatch(setIsSocketConnected(true));
}

function onConnectError(data: any) {
  console.log(
    'SocketIO onConnectError connect_error_no_token: ',
    data?.message
  );
  // Socket has not fully connected because token was invalid
  const token = LocalStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
  if (token) {
    updateSocketToken(token);
  }
}

const connectSocket = () => {
  socket.connect();
  socket.on('connect', onConnect);
  socket.on('connect_error_no_token', onConnectError);
  socket.on(
    'logyx_configuration_finished_event',
    handleOnLogyxConfigurationFinishedEvent
  );
  socket.on(
    'logyx_edit_configuration_finished_event',
    handleOnLogyxEditConfigurationFinishedEvent
  );
  socket.on(
    'purchase_order_line_logyx_reconfig_finished',
    handleOnLogyxPurchaseOrderLineReconfigurationFinishedEvent
  );
};

const disconnectSocket = () => {
  socket.off('connect', onConnect);
  socket.off('connect_error_no_token', onConnectError);
  socket.off(
    'logyx_configuration_finished_event',
    handleOnLogyxConfigurationFinishedEvent
  );
  socket.off(
    'logyx_edit_configuration_finished_event',
    handleOnLogyxEditConfigurationFinishedEvent
  );
  socket.off(
    'purchase_order_line_logyx_reconfig_finished',
    handleOnLogyxPurchaseOrderLineReconfigurationFinishedEvent
  );
  socket.disconnect();
  store.dispatch(setIsSocketConnected(false));
};

const SocketIOContext = createContext({
  connect: () => {
    // connect
  },
  disconnect: () => {
    // disconnect
  },
});

interface ISocketIOProviderProps {
  children: React.ReactNode;
}

const SocketIOProvider = ({ children }: ISocketIOProviderProps) => {
  return (
    <SocketIOContext.Provider
      value={{
        connect: connectSocket,
        disconnect: disconnectSocket,
      }}
    >
      {children}
    </SocketIOContext.Provider>
  );
};

export default SocketIOProvider;

const useSocketContext = () => {
  const isSocketConnected = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSocketConnected
  );
  const contextData = useContext(SocketIOContext);
  return { ...contextData, connected: isSocketConnected };
};

export const useSocketConnection = () => {
  const { connected, connect, disconnect } = useSocketContext();
  const connectedRef = useRef(connected);

  // Update the ref whenever 'connected' changes
  useEffect(() => {
    connectedRef.current = connected;
  }, [connected]);

  useEffect(() => {
    if (!connected) {
      connect();
    }
  }, [connected]);

  useEffect(() => {
    // Cleanup function gets created when the component is mounted, using the "connected" value from that point in time
    // That is why we need to keep the connected in a ref in order to have the newest value at the moment of cleanup execution
    return () => {
      // Use the current value from the ref
      if (connectedRef.current) {
        disconnect();
      }
    };
  }, []);

  return connected;
};
