import { useMutation } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useEffect, useState } from 'react';
import { getCityAndAddress } from 'services/Common/CommonService';
import {
  ICreateSalesOrderProductFormDTO,
  IGetCityAndAddressDTO,
} from './constants';
import { useCallbackPrompt } from 'utils/hooks/useCallbackPrompt';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { ICreateSalesOrderData } from 'types/SalesOrders.types';
import { createSalesOrder } from 'services/SalesOrder/SalesOrderService';

export const useCreateSalesOrder = () =>
  useMutation(
    (createSalesOrderData: ICreateSalesOrderData) =>
      createSalesOrder(createSalesOrderData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_SALES_INFO]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_SALES_ORDER,
        });
      },
      mutationKey: ReactMutationKeys.CREATE_SALES_ORDER,
    }
  );

export const useGetCityAndAddress = () =>
  useMutation(
    (data: IGetCityAndAddressDTO) =>
      getCityAndAddress(data.zipCode, data.houseNumber),
    {
      mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
    }
  );

export const useAddNavigationCheckAfterProductIsAdded = (
  productFormDtos: ICreateSalesOrderProductFormDTO[],
  status: string
) => {
  const [checkNavigation, setCheckNavigation] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(checkNavigation);

  useEffect(() => {
    if (productFormDtos?.length) {
      setCheckNavigation(
        status === 'loading' || status === 'success' ? false : true
      );
    }
  }, [productFormDtos, status, checkNavigation]);

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  };
};
