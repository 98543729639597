export enum MobileMessageTypes {
  LOGOUT = 'LOGOUT',
  OPEN_LOGYX = 'OPEN_LOGYX',
  CLOSE_LOGYX = 'CLOSE_LOGYX',
  OPEN_DEALER_PANEL = 'OPEN_DEALER_PANEL',
  OPEN_GOOGLE_MAPS = 'OPEN_GOOGLE_MAPS',
  OPEN_FILE = 'OPEN_FILE',
  DOWNLOAD_BASE64 = 'DOWNLOAD_BASE64',
  TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
}

export interface IMobileMessage {
  type: MobileMessageTypes;
  payload?: string | object;
}

export interface IFileViewerPayload {
  fileViewerUrl: string;
  isPDF: boolean;
}

export const postMobileMessage = (message: IMobileMessage) => {
  (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
};
