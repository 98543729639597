import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

export const useHandleScrollingEffects = (
  shouldScrollToLastProduct: boolean,
  setShouldScrollToLastProduct: Dispatch<SetStateAction<boolean>>
) => {
  const productFormDtoRefs = useRef<HTMLDivElement[]>([]);
  useEffect(() => {
    if (shouldScrollToLastProduct) {
      handleScrollToLastProduct();
      setShouldScrollToLastProduct(false);
    }
  }, [shouldScrollToLastProduct]);

  const handleScrollToLastProduct = (msDelay = 150) => {
    setTimeout(() => {
      const lastRef =
        productFormDtoRefs.current[productFormDtoRefs.current.length - 1];
      lastRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }, msDelay);
  };

  return { productFormDtoRefs, handleScrollToLastProduct };
};
