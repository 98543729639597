import { config } from 'config';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import { io } from 'socket.io-client';
import { ISocket } from './types';

const URL = config.api.baseUrl ? config.api.baseUrl : 'MY_WS_URL';
let socket: ISocket | null = null;

export const initializeSocket = () => {
  if (socket) {
    // If the socket already exists, disconnect before re-initializing
    socket.disconnect();
  }

  const token = LocalStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

  socket = io(URL, {
    autoConnect: false,
    transports: ['websocket'],
    auth: {
      token: `Bearer ${token}`,
    },
  });

  return socket;
};
