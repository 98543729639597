import { IDraggableLine } from './DraggableLines';
import { IReorderLineDTO } from './hooks';

export const sortLinesByIndex = (lines: IDraggableLine[]) => {
  return lines.sort(
    (a: IDraggableLine, b: IDraggableLine) => a.index - b.index
  );
};

export const isOrderDifferentFromInitial = (
  initialLines: IDraggableLine[],
  reorderLinesDTOs: IReorderLineDTO[]
): boolean => {
  if (initialLines.length !== reorderLinesDTOs.length) {
    return true; // Length difference implies a change in order or content
  }

  // Create a map of initial line indexes by line id for quick lookup
  const initialIndexMap = new Map<number, number>();
  initialLines.forEach((line) =>
    initialIndexMap.set(Number(line.id), line.index)
  );

  // Check each line in the reordered array to see if its index has changed
  for (const line of reorderLinesDTOs) {
    const initialIndex = initialIndexMap.get(Number(line.lineId));

    if (initialIndex === undefined || initialIndex !== Number(line.lineIndex)) {
      return true; // Found a line whose index has changed
    }
  }

  return false; // No changes in the order
};

export const swapDragAndHoverLineIndexes = (
  localLines: IDraggableLine[],
  dragIndex: number,
  hoverIndex: number
) => {
  // Create a copy of the array to avoid mutating the original data
  const linesCopy = localLines.map((line) => ({ ...line }));

  // Swap the index properties of the specified elements
  const tempIndex = linesCopy[dragIndex].index;
  linesCopy[dragIndex].index = linesCopy[hoverIndex].index;
  linesCopy[hoverIndex].index = tempIndex;

  return linesCopy;
};

export const getBooleanAttributeLabel = (value: string) => {
  if (value) return 'Yes';
  else return 'No';
};
