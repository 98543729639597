import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/twinfield-customers/';

export const getTwinfieldCustomersByPostalCode = async (
  zipCode: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    zip_code: zipCode,
    page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(BASE_URL, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getTwinfieldCustomerDetails = async (
  code: number
): Promise<any> => {
  const queryParamsObj = {
    code,
  };
  const fullUrl = getUrlWithQueryParams(`${BASE_URL}details`, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const connectCustomerToTwinfieldCustomer = async (
  code: number,
  customerId: number
): Promise<any> => {
  const { data } = await apiClient.post<any>(BASE_URL, {
    code,
    customer_id: customerId,
  });
  return data.data;
};

export const createTwinfieldCustomer = async (
  customerId: number
): Promise<any> => {
  const { data } = await apiClient.post<any>(`${BASE_URL}create`, {
    customer_id: customerId,
  });
  return data.data;
};
