import {
  SET_LOGYX_CONFIGURATION_INTENT,
  SET_IS_AWAITING_CONFIGURATION,
  SET_IS_AWAITING_EDIT,
  SET_IS_AWAITING_PURCHASE_ORDER_LINE_EDIT,
  SET_PENDING_INTENT_UUID,
} from '../constants';
import { ILogyxConfigurationIntent } from '../types';

export const setPendingIntentUUID = (intentUUID: string | null) => ({
  type: SET_PENDING_INTENT_UUID,
  payload: intentUUID,
});

export const setIsAwaitingConfiguration = (boolean: boolean) => ({
  type: SET_IS_AWAITING_CONFIGURATION,
  payload: boolean,
});

export const setLogyxConfigurationIntent = (
  logyxConfigurationIntent: ILogyxConfigurationIntent | null
) => ({
  type: SET_LOGYX_CONFIGURATION_INTENT,
  payload: logyxConfigurationIntent,
});

export const setIsAwaitingEdit = (boolean: boolean) => ({
  type: SET_IS_AWAITING_EDIT,
  payload: boolean,
});

export const setIsAwaitingPurchaseOrderLineEdit = (boolean: boolean) => ({
  type: SET_IS_AWAITING_PURCHASE_ORDER_LINE_EDIT,
  payload: boolean,
});
