import { useEffect } from 'react';

const useModalObserver = () => {
  useEffect(() => {
    let hasModalOpen = document.querySelector('.modal-open') !== null;

    const observer = new MutationObserver((mutations) => {
      let modalClassChanged = false;

      mutations.forEach((mutation) => {
        const addedNodes = Array.from(mutation.addedNodes);
        const removedNodes = Array.from(mutation.removedNodes);

        // Check both added and removed nodes for 'modal-open' class
        [...addedNodes, ...removedNodes].forEach((node) => {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            (node as Element).classList.contains('modal-open')
          ) {
            modalClassChanged = true;
          }
        });

        // Check for attribute changes in existing nodes
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const target = mutation.target as Element;
          if (target.classList.contains('modal-open')) {
            modalClassChanged = true;
          }
        }
      });

      // Update the document overflow style if needed
      if (modalClassChanged) {
        const currentlyHasModalOpen =
          document.querySelector('.modal-open') !== null;
        if (currentlyHasModalOpen !== hasModalOpen) {
          document.body.style.overflow = currentlyHasModalOpen
            ? 'hidden'
            : 'auto';
          hasModalOpen = currentlyHasModalOpen;
        }
      }
    });

    const config = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    };

    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default useModalObserver;
