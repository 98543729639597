export enum AddEmailNoteModalType {
  QUOTATION = 'Quotations',
  SALES_ORDER = 'Sales order',
  SALES_INVOICE = 'Sales invoice',
  APPOINTMENT = 'APPOINTMENT',
  MEASUREMENT_CREATION = 'MEASUREMENT_CREATION',
  INSTALLATION_CREATION = 'INSTALLATION_CREATION',
  GENERAL_CREATION = 'GENERAL_CREATION',
  SERVICE_CREATION = 'SERVICE_CREATION',
  APPOINTMENT_CONFIRM = 'APPOINTMENT_CONFIRM',
  FW_APPOINTMENT = 'FW_APPOINTMENT',
}
