import { H4 } from 'assets/styled';
import { IProductAttribute } from 'types/Product.types';
import {
  CloseWrapper,
  Container,
  HorizontalLine,
} from '../ViewProductModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute } from 'store/Logyx/types';
import FakeInput from 'components/FakeInput/FakeInput';
import {
  GreenLine,
  LogyxAttributesLabel,
  Section,
  Text,
} from './AttributesTab.styled';
import { IViewProductProps } from '../type';

interface IAttributesTabProps {
  productLineData: IViewProductProps;
  onCancel: () => void;
}

export const AttributesTab = ({
  productLineData,
  onCancel,
}: IAttributesTabProps) => {
  const { t } = useTranslation();

  let attributes: IProductAttribute[] = [];
  if (productLineData.product?.attributes) {
    attributes = Object.values(productLineData.product.attributes);
  }

  let logyxAttributes: ILogyxAttribute[] = [];
  if (productLineData.product.logyx_configuration_intent?.data) {
    logyxAttributes = formatLogyxAttributesObjectIntoArray(
      productLineData.product.logyx_configuration_intent.data
    );
  }

  const hasAttributes = !!productLineData.product?.attributes;
  const haslogyxAttributes = !!logyxAttributes.length;

  return (
    <Container>
      {attributes.map((attr: IProductAttribute, index: number) => {
        const withoutLine = attributes.length - 1 >= index;
        return (
          <Section key={index}>
            <H4>{attr.fieldName.toUpperCase()}</H4>
            <FakeInput
              width="300rem"
              label={attr.value || ''}
              placeholder={''}
              marginBottom="20rem"
              marginTop="20rem"
            />
            {withoutLine ? null : <HorizontalLine />}
          </Section>
        );
      })}
      {haslogyxAttributes ? (
        <>
          <GreenLine />
          <LogyxAttributesLabel>
            {t('Logyx Attributes') + ':'}
          </LogyxAttributesLabel>
          {logyxAttributes.map((attr: ILogyxAttribute, index: number) => {
            const withoutLine = logyxAttributes.length - 1 >= index;
            return (
              <Section key={index}>
                <H4>{attr.fieldName.toUpperCase()}</H4>
                <FakeInput
                  width="300rem"
                  label={attr.value || ''}
                  placeholder={''}
                  marginBottom="20rem"
                  marginTop="20rem"
                />
                {withoutLine ? null : <GreenLine />}
              </Section>
            );
          })}
        </>
      ) : null}
      {!hasAttributes && !haslogyxAttributes ? (
        <Text>{t('No attributes')}.</Text>
      ) : null}
      <CloseWrapper>
        <Button onClick={onCancel} primary width="200rem" label={t('Close')} />
      </CloseWrapper>
    </Container>
  );
};
