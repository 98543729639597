import { Option } from 'components/Select/type';
import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

export const PURCHASE_INVOICE_FILES_PER_PAGE = 10;

export enum FilesPageViewType {
  BOX_VIEW = 'box_view',
  LIST_VIEW = 'list_view',
}

export const fileFilterTypes: Option[] = [
  { value: 'ALL', label: 'All', key: 'all' },
  { value: 'IMAGE', label: 'Image', key: 'image' },
  { value: 'E-mail', label: 'E-mail', key: 'email' },
  { value: 'TEXT', label: 'Text', key: 'text' },
];

const rowConfigs: ITableRowConfig[] = [
  { header: 'Name', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1, isBlue: false },
  { header: 'Date', flexValue: 1, isBlue: false },
  { header: 'Added by', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};
