import { Route, Routes } from 'react-router-dom';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import SingleSalesOrderProductsPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderProductsPage/SingleSalesOrderProductsPage';
import SingleSalesOrderCommentsPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderCommentsPage/SingleSalesOrderCommentsPage';
import SingleSalesOrderAppointmentsPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderAppointmentsPage/SingleSalesOrderAppointmentsPage';
import SingleSalesOrderFilesPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderFilesPage/SingleSalesOrderFilesPage';
import SingleSalesOrderInvoicesPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderInvoicesPage/SingleSalesOrderInvoicesPage';
import SingleSalesOrderPurchaseOrdersPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderPurchaseOrdersPage/SingleSalesOrderPurchaseOrdersPage';
import SingleSalesOrderGeneralPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderGeneralPage/SingleSalesOrderGeneralPage';
import SingleSalesOrderQuotationsPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderQuotationsPage/SingleSalesOrderQuotationsPage';
import SingleSalesOrderNewComment from 'pages/Manager/SingleSalesOrder/NewComment/SingleSalesOrderNewComment';
import NewPurchaseOrderFromSalesOrderPage from 'pages/Manager/NewPurchaseOrderFromSalesOrderPage/NewPurchaseOrderFromSalesOrderPage';
import NewSalesInvoicePage from 'pages/Manager/NewSalesInvoicePage/NewSalesInvoicePage';
import SingleSalesOrderNewSignature from '../../pages/Manager/SingleSalesOrder/NewSignature/SingleSalesOrderNewSignature';
import SingleSalesOrderReportsPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderReportsPage/SingleSalesOrderReportsPage';

const SingleSalesOrderRouter = () => {
  return (
    <Routes>
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderGeneral.path}
        element={<SingleSalesOrderGeneralPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderProducts.path}
        element={<SingleSalesOrderProductsPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderComments.path}
        element={<SingleSalesOrderCommentsPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderQuotations.path}
        element={<SingleSalesOrderQuotationsPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderPurchaseOrders.path}
        element={<SingleSalesOrderPurchaseOrdersPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderInvoices.path}
        element={<SingleSalesOrderInvoicesPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderAppointments.path}
        element={<SingleSalesOrderAppointmentsPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderReports.path}
        element={<SingleSalesOrderReportsPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderFiles.path}
        element={<SingleSalesOrderFilesPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderNewComment.path}
        element={<SingleSalesOrderNewComment />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderNewPurchaseOrder.path}
        element={<NewPurchaseOrderFromSalesOrderPage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderNewInvoice.path}
        element={<NewSalesInvoicePage />}
      />
      <Route
        path={SingleSalesOrderRoutes.SingleSalesOrderNewSignature.path}
        element={<SingleSalesOrderNewSignature />}
      />
    </Routes>
  );
};

export default SingleSalesOrderRouter;
