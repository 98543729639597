import {
  COLORS,
  marginLg,
  marginMd,
  marginSm,
  marginXs,
  paddingXs,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const FormContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropDownOptionsLabel = styled.label`
  font-size: 16rem;
  line-height: 24rem;
  font-weight: 300;
  margin-bottom: ${marginXs};
`;

export const AddMoreOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 160rem;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export const RelativeInputWrapper = styled.div`
  position: relative;
  padding-top: ${paddingXs};
  width: 300rem;
  margin-right: ${marginLg}; // 300 + 50
`;

export const DeleteProductFieldIcon = styled.div`
  position: absolute;
  cursor: pointer;
  margin-left: ${marginSm};
  width: 20rem;
  height: 20rem;
  right: -7rem;
  top: 0rem;
  background-color: ${COLORS.WHITE};
  border-radius: 5rem;
  z-index: 2;
`;

export const ProductAttributeTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${marginMd};
`;

export const ProductTypeLabel = styled.label`
  user-select: none;
  cursor: pointer;
  margin-top: ${marginSm};
  margin-bottom: -10rem;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const DefaultLabel = styled.label`
  font-size: 16rem;
  line-height: 24rem;
  font-weight: 300;
  margin-bottom: ${marginXs};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: ${marginXs};
`;
