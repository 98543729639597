export const getStatusValue = (selectedStatusOption: any) => {
  if (selectedStatusOption === '') {
    return null;
  } else {
    if (selectedStatusOption === 'ACTIVE') {
      return true;
    } else {
      return false;
    }
  }
};
