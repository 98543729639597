import { useMutation, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  changeSalesInvoiceExternalId,
  changeSalesInvoiceStatus,
  exportSalesInvoice,
  getSalesInvoiceAttachmentsPreview,
  getSalesInvoiceInfoById,
  getSalesInvoiceLatestEmailStatus,
  getSingleSalesInvoice,
  sendSalesInvoiceEmail,
} from 'services/Invoice/SalesInvoiceService';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { EmailStatuses } from 'types/Email.types';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';

export const useGetSingleSalesInvoice = (invoiceId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_INVOICE, invoiceId],
    queryFn: () => {
      return getSingleSalesInvoice(invoiceId);
    },
    enabled: !!invoiceId,
    refetchInterval: 7000,
  });

export const useExportSalesInvoice = () =>
  useMutation((salesInvoiceId: string) => exportSalesInvoice(salesInvoiceId), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EXPORT_SALES_INVOICE,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.EXPORT_SALES_INVOICE,
  });

export const useChangeSalesInvoiceStatus = () =>
  useMutation(
    (params: { id: string; status_id: string }) =>
      changeSalesInvoiceStatus(params.id, params.status_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_SALES_INVOICE_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
      },
      mutationKey: ReactMutationKeys.CHANGE_SALES_INVOICE_STATUS,
    }
  );

interface ISendSalesInvoiceEmailDTO {
  isPaymentReminder: boolean;
  note: string;
  files: any;
}

export const useSendSalesInvoiceEmail = (salesInvoiceId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: ISendSalesInvoiceEmailDTO) =>
      sendSalesInvoiceEmail(
        salesInvoiceId,
        params.isPaymentReminder,
        params.note,
        params.files
      ),
    {
      onSuccess: () => {
        toast.success(t('E-mail is being sent'), { autoClose: 7000 });

        const salesInvoiceData: any = queryClient.getQueryData([
          ReactQueryKeys.GET_SINGLE_SALES_INVOICE,
          salesInvoiceId.toString(),
        ]);

        if (salesInvoiceData?.sales_invoice?.sent_to_customer)
          handleEmailStatusFetching(salesInvoiceId);
        else handleEmailStatusFirstTimeFetching(salesInvoiceId);
      },
      onError: (error: ERPError) => {
        if (error.response.data.code != 409) {
          toast.error(getToastErrorMessage(error), {
            toastId: ReactMutationKeys.SEND_SALES_INVOICE_EMAIL,
          });
        }
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SEND_SALES_INVOICE_EMAIL,
    }
  );
};

const handleEmailStatusFetching = (salesInvoiceId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE_EMAIL_STATUS,
      salesInvoiceId.toString(),
    ]);
    const salesInvoiceData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE,
      salesInvoiceId.toString(),
    ]);

    //Check if status has changed and stop further intervals
    if (
      statusData?.id !== salesInvoiceData?.sales_invoice?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_INVOICE]);
      clearInterval(intervalId);
    } else
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_SALES_INVOICE_EMAIL_STATUS,
      ]);

    intervalCount++;
  }, 7000);
};

const handleEmailStatusFirstTimeFetching = (salesInvoiceId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE_EMAIL_STATUS,
      salesInvoiceId.toString(),
    ]);
    const salesInvoiceData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE,
      salesInvoiceId.toString(),
    ]);

    //Check if status exists and stop further intervals
    if (
      salesInvoiceData?.sales_invoice?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    )
      clearInterval(intervalId); // Stop further intervals
    else {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_SALES_INVOICE_EMAIL_STATUS,
      ]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_INVOICE]);
    }

    intervalCount++;
  }, 7000);
};

export const useChangeSalesInvoiceExternalId = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: string; external_id: string }) =>
      changeSalesInvoiceExternalId(params.id, params.external_id),
    {
      onSuccess: (data) => {
        toast.success(t('External reference edited'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_SALES_INVOICE_REFERENCE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.CHANGE_SALES_INVOICE_REFERENCE,
    }
  );
};

export const useGetSingleSalesInvoiceInfo = (salesInvoiceId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_INVOICE_INFO],
    queryFn: () => {
      return getSalesInvoiceInfoById(salesInvoiceId);
    },
  });

export const useGetSalesInvoiceLatestEmailStatus = (salesOrderId: string) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE_EMAIL_STATUS,
      salesOrderId,
    ],
    queryFn: () => {
      return getSalesInvoiceLatestEmailStatus(salesOrderId);
    },
  });

export const useGetSalesInvoiceAttachmentsPreview = (
  salesOrderId: string,
  type: AddEmailNoteModalType
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SALES_INVOICE_ATTACHMENTS_PREVIEW,
      salesOrderId,
    ],
    queryFn: () => {
      return getSalesInvoiceAttachmentsPreview(salesOrderId);
    },
    enabled: !!salesOrderId && type === AddEmailNoteModalType.SALES_INVOICE,
  });
