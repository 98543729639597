import { Option } from 'components/Select/type';
import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Type',
    value: 'type',
    type: 'dropdown',
  },
  {
    label: 'Name',
    value: 'name',
    type: 'string',
  },
  {
    label: 'Quantity bought',
    value: 'quantityBought',
    type: 'string',
  },
  {
    label: 'Status',
    value: 'status',
    type: 'dropdown',
  },
  {
    label: 'Supplier',
    value: 'company',
    type: 'dropdown',
  },
  {
    label: 'In Stock',
    value: 'inStock',
    type: 'string',
  },
  {
    label: 'Units sold',
    value: 'unitsSold',
    type: 'string',
  },
];

const typeOptions: Option[] = [
  { value: '1', label: 'Normal' },
  { value: '2', label: 'Service' },
];

export const useGetItemsAdvancedFilters = (statusOptions: Option[]) => {
  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'status') {
      return { ...filter, options: statusOptions };
    } else if (filter.value === 'type') {
      return { ...filter, options: typeOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};
