import {
  CANCELLED_STATUS,
  downpaymentSubMenuItems,
  SENT_STATUS,
  subMenuItems,
} from './constants';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import {
  TitleContainer,
  ButtonContainer,
  Wrapper,
  IconContainer,
  Container,
  LeftSide,
  H3,
  StatusContainer,
  StatusOptionsContainer,
  PenImg,
  Option,
  P,
  HeaderWrapper,
  RouterWrapper,
  LoaderWrapper,
  ButtonWrapper,
} from './SingleSalesInvoicePage.styled';
import Button from 'components/Button/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import { RoutesConfig } from 'navigation/routes';
import SingleSalesInvoiceRouter from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoiceRouter';
import {
  useChangeSalesInvoiceStatus,
  useExportSalesInvoice,
  useGetSalesInvoiceLatestEmailStatus,
  useGetSingleSalesInvoice,
  useGetSingleSalesInvoiceInfo,
} from './hooks';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { useGetSalesInvoiceStatuses } from 'utils/hooks/useGetSalesInvoiceStatuses';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import { EmailStatuses } from 'types/Email.types';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Spinner from 'components/Spinner/Spinner';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { ExternalTwinfieldCustomersModal } from '../AccountingFlow/ExternalTwinfieldCustomersModal/ExternalTwinfieldCustomersModal';
import { useIsTwinfieldCustomerActionAvailable } from '../AccountingFlow/hooks';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import {
  CaretLeft,
  DownloadSimple,
  EnvelopeSimple,
  PencilSimple,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SingleSalesInvoicePage = () => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [isPaymentReminder, setIsPaymentReminder] = useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [
    isExternalTwinfieldCustomerModalOpen,
    setIsExternalTwinfieldCustomerModalOpen,
  ] = useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isError: isErrorSalesInvoice } = useGetSingleSalesInvoice(id!);

  // Sales invoice email is sent to general address
  const emailDetails = {
    email: data?.sales_invoice?.customer_snapshot?.email,
    type: 'General',
  };
  const { data: emailStatus } = useGetSalesInvoiceLatestEmailStatus(id!);
  const [isSentStatusModalOpen, setIsSentStatusModalOpen] =
    useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isSendSalesInvoiceModalOpen, setIsSendSalesInvoiceModalOpen] =
    useState<boolean>(state?.openModal ? true : false);
  const [isCancelInvoiceModalOpen, setIsCancelInvoiceModalOpen] =
    useState<boolean>(false);

  const canEditSalesInvoice = useCan(Actions.EDIT_SALES_INVOICE);

  const { mutate: exportSalesInvoice, isLoading: isLoadingExport } =
    useExportSalesInvoice();
  const { mutate: changeSalesInvoiceStatus } = useChangeSalesInvoiceStatus();
  const statusOptions = useGetSalesInvoiceStatuses();

  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditSalesInvoice && setIsStatusModalOpen(false)
  );

  const { data: infoData } = useGetSingleSalesInvoiceInfo(id!);
  const { isTablet } = useBreakpointFlag();
  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );

  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();
  const isTwinfieldCustomerActionAvailable =
    useIsTwinfieldCustomerActionAvailable(
      data?.sales_invoice?.customer?.twinfield_customer
    );

  const { steps, showIntroductionGuide } = useRunTour(
    GuidePages.SINGLE_SALES_INVOICE,
    id,
    true,
    true
  );

  return !isErrorSalesInvoice ? (
    <Container className="single-sales-invoice-step-1">
      <SubSideMenu
        subMenuItems={
          data?.sales_invoice?.is_downpayment
            ? downpaymentSubMenuItems
            : subMenuItems
        }
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.sales_invoice_info)}
      />
      <Wrapper isTablet={isTablet}>
        <HeaderWrapper
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.Invoices.fullPath)}
              />
              <H3>
                {t('Invoice')}&nbsp;
                {data?.sales_invoice?.invoice_number}
              </H3>
              <StatusContainer
                ref={statusContainerWrapperRef}
                className="single-sales-invoice-step-2"
              >
                <P
                  onClick={() =>
                    canEditSalesInvoice &&
                    setIsStatusModalOpen(!isStatusModalOpen)
                  }
                >
                  {capitalizeFirstLetter(
                    t(data?.sales_invoice?.payment_status?.name)
                  )}
                </P>
                {canEditSalesInvoice && (
                  <PenImg>
                    <Icon
                      onClick={() => setIsStatusModalOpen(!isStatusModalOpen)}
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                    />
                  </PenImg>
                )}
                {isStatusModalOpen && (
                  <StatusOptionsContainer>
                    {statusOptions.map((option) => {
                      return (
                        <Option
                          isSelected={
                            option.value ===
                            data?.sales_invoice.payment_status?.name
                          }
                          key={option.value}
                          onClick={() => {
                            if (option.value === CANCELLED_STATUS) {
                              setIsCancelInvoiceModalOpen(true);
                              return;
                            }
                            changeSalesInvoiceStatus({
                              id: data?.sales_invoice?.id || '',
                              status_id: option.id,
                            });
                            if (option.value === SENT_STATUS) {
                              setIsSentStatusModalOpen(true);
                            }
                          }}
                        >
                          {capitalizeFirstLetter(t(option.label))}
                        </Option>
                      );
                    })}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>
            </LeftSide>
            <IconContainer className="single-sales-invoice-step-3">
              <Tooltip content={t('Open PDF')} key={'exportKey'}>
                {isLoadingExport ? (
                  <LoaderWrapper>
                    <Spinner size={20} />
                  </LoaderWrapper>
                ) : (
                  <Icon
                    data-testid="download-pdf"
                    svg={DownloadSimple}
                    color={COLORS.PRIMARY}
                    size={25}
                    marginRight={marginXs}
                    marginLeft={marginXs}
                    onClick={() => exportSalesInvoice(data?.sales_invoice?.id)}
                  />
                )}
              </Tooltip>
              <Tooltip content={t('Send mail')} key={'emailKey'}>
                <Icon
                  data-testid="send-email"
                  svg={
                    data?.sales_invoice?.sent_to_customer
                      ? emailStatus?.status === EmailStatuses.SENT
                        ? MailSent
                        : emailStatus?.status === EmailStatuses.PENDING
                        ? MailPending
                        : MailFailed
                      : EnvelopeSimple
                  }
                  size={25}
                  onClick={() => setIsSendEmailModalOpen(true)}
                  color={COLORS.PRIMARY}
                  marginLeft={marginXs}
                  marginRight={marginXs}
                />
              </Tooltip>
            </IconContainer>
          </TitleContainer>
          <ButtonContainer>
            <ButtonWrapper className="single-sales-invoice-step-4">
              <Button
                label={t('Add Payment')}
                secondary
                disabled={
                  !canEditSalesInvoice || !data?.sales_invoice.open_amount
                }
                width="200rem"
                onClick={() => setIsPaymentModalOpen(true)}
              />
              <Button
                onClick={() =>
                  navigate(
                    SingleSalesInvoiceRoutes.SingleSalesInvoiceNewCreditInvoice
                      .path,
                    {
                      state: { prepopulateCreditInvoiceData: data },
                    }
                  )
                }
                label={t('New credit invoice')}
                width="200rem"
                secondary
                disabled={!canEditSalesInvoice}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper routerWithoutPaddingBottom={routerWithoutPaddingBottom}>
          <SingleSalesInvoiceRouter />
        </RouterWrapper>
      </Wrapper>

      <ThreeButtonsModal
        isOpen={isSentStatusModalOpen}
        setIsOpen={setIsSentStatusModalOpen}
        onCancel={() => setIsSentStatusModalOpen(false)}
        firstButtonLabel={t('No')}
        onFirstButton={() => setIsSentStatusModalOpen(false)}
        secondButtonLabel={t('Download pdf')}
        onSecondButton={() => {
          exportSalesInvoice(data?.sales_invoice?.id);
          setIsSentStatusModalOpen(false);
        }}
        thirdButtonlabel={t('Send email with pdf')}
        onThirdButton={() => {
          setIsPaymentReminder(false);
          setIsAddEmailNoteModalOpen(true);
          setIsSentStatusModalOpen(false);
        }}
        title={''}
        description={
          data?.sales_invoice?.is_credit
            ? t('Do you want to send the credit invoice') + '?'
            : data?.sales_invoice?.is_downpayment
            ? t('Do you want to send the down payment invoice') + '?'
            : t('Do you want to send the invoice') + '?'
        }
      />
      <ThreeButtonsModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onCancel={() => setIsSendEmailModalOpen(false)}
        firstButtonLabel={t('Cancel')}
        onFirstButton={() => setIsSendEmailModalOpen(false)}
        secondButtonLabel={t('Payment reminder')}
        onSecondButton={() => {
          setIsPaymentReminder(true);
          if (isTwinfieldCustomerActionAvailable) {
            setIsExternalTwinfieldCustomerModalOpen(true);
          } else {
            setIsAddEmailNoteModalOpen(true);
          }
          setIsSendEmailModalOpen(false);
        }}
        secondButtonDisabled={
          data?.sales_invoice?.payment_status?.name === 'FULLY_PAID'
        }
        thirdButtonlabel={t('Invoice')}
        onThirdButton={() => {
          setIsPaymentReminder(false);
          if (isTwinfieldCustomerActionAvailable) {
            setIsExternalTwinfieldCustomerModalOpen(true);
          } else {
            setIsAddEmailNoteModalOpen(true);
          }
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          t('Do you want to send a payment reminder or the invoice') + '?'
        }
      />
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.sales_invoice?.id}
        invoiceType={InvoiceType.SALES_INVOICE}
        initialPaymentAmount={data?.sales_invoice?.open_amount}
      />
      <YesOrNoModal
        pwId="yes-or-no-send-sales-invoice-modal"
        isOpen={isSendSalesInvoiceModalOpen}
        setIsOpen={setIsSendSalesInvoiceModalOpen}
        onYes={() => {
          setIsPaymentReminder(false);
          if (isTwinfieldCustomerActionAvailable) {
            setIsExternalTwinfieldCustomerModalOpen(true);
          } else {
            setIsAddEmailNoteModalOpen(true);
          }
          setIsSendSalesInvoiceModalOpen(false);
        }}
        onNo={() => {
          setIsSendSalesInvoiceModalOpen(false);
        }}
        title={''}
        description={t('Do you want to send the sales invoice') + '?'}
      />
      <ExternalTwinfieldCustomersModal
        isOpen={isExternalTwinfieldCustomerModalOpen}
        setIsOpen={setIsExternalTwinfieldCustomerModalOpen}
        onActionComplete={() => setIsAddEmailNoteModalOpen(true)}
        zipCode={data?.sales_invoice?.customer?.address?.zip_code}
        customerId={data?.sales_invoice?.customer?.id}
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.sales_invoice?.id}
        type={AddEmailNoteModalType.SALES_INVOICE}
        isPaymentReminder={isPaymentReminder}
        emailDetails={emailDetails}
      />
      <ThreeButtonsModal
        isOpen={isCancelInvoiceModalOpen}
        setIsOpen={setIsCancelInvoiceModalOpen}
        onCancel={() => setIsCancelInvoiceModalOpen(false)}
        firstButtonLabel={t('No')}
        onFirstButton={() => setIsCancelInvoiceModalOpen(false)}
        secondButtonLabel={t('Yes')}
        onSecondButton={() => {
          changeSalesInvoiceStatus({
            id: data?.sales_invoice?.id || '',
            status_id:
              statusOptions.find(
                (option: any) => option.value === CANCELLED_STATUS
              )!.id || '',
          });
          setIsCancelInvoiceModalOpen(false);
        }}
        thirdButtonlabel={t('Create credit')}
        onThirdButton={() => {
          navigate(
            SingleSalesInvoiceRoutes.SingleSalesInvoiceNewCreditInvoice.path,
            {
              state: { prepopulateCreditInvoiceData: data },
            }
          );
          setIsCancelInvoiceModalOpen(false);
        }}
        title={''}
        description={
          t(
            'Are you sure you want to cancel this invoice? We recommend to create a credit invoice instead of cancelling an invoice. If you decide to cancel this invoice, please contact your financial department'
          ) + '.'
        }
      />
      {showIntroductionGuide && <IntroductionGuide steps={steps} />}
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleSalesInvoicePage;
