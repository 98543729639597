import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { IPurchaseOrderFormDTO, IPurchaseOrderLineFormDTO } from '../constants';
import {
  PurchaseOrderLineContainer,
  PurchaseOrderLinesWrapper,
  SupplierCompanyNameLabel,
  Container,
  FormHeaderContainer,
  HeaderLabel,
  FormCell,
  FormLabel,
  StockItemAbsoluteInfoTooltipWrapper,
  IsLogyxProductAbsoluteWrapper,
  Line,
} from './PurchaseOrderForm.styled';
import {
  createPurchaseOrderSchema,
  getFormikErrorMesssage,
} from './validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { ReactComponent as LogyxLogo } from 'assets/icons/logyxLogoSmall.svg';
import { FlexColumn } from '../Overview/Overview.styled';
import { getLineColorBasedOnSelectedLines } from './helper';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IPurchaseOrderFormProps {
  purchaseOrderForm: IPurchaseOrderFormDTO;
  handlePurchasePriceOnChange: any;
  handleSelect: (supplierId: number, productId: number) => void;
  handleSelectAll: (supplierId: number) => void;
  areAllFieldsTouched: boolean;
  openViewModal: (isOpen: boolean) => void;
  setSelectedLine: (line: IPurchaseOrderLineFormDTO) => void;
}

const PurchaseOrderForm = ({
  purchaseOrderForm,
  handlePurchasePriceOnChange,
  handleSelect,
  handleSelectAll,
  areAllFieldsTouched,
  openViewModal,
  setSelectedLine,
}: IPurchaseOrderFormProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={purchaseOrderForm}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={createPurchaseOrderSchema}
        onSubmit={() => {
          // On submit
        }}
        validateOnMount={true}
      >
        {({ handleBlur, setFieldValue, values, errors }) => {
          return (
            <Form>
              <FieldArray
                name="purchaseOrderLines"
                render={() => (
                  <>
                    <SupplierCompanyNameLabel>
                      {purchaseOrderForm.supplier.company_name}
                    </SupplierCompanyNameLabel>
                    <FormHeaderContainer>
                      <HeaderLabel flex={2}>{t('Product')}</HeaderLabel>
                      <HeaderLabel flex={1}>{t('Type')}</HeaderLabel>
                      <HeaderLabel flex={0.8}>{t('Quantity')}</HeaderLabel>
                      <HeaderLabel flex={0.8}>{t('In stock')}</HeaderLabel>
                      <HeaderLabel flex={1.5}>
                        {t('Purchase price')}
                      </HeaderLabel>
                      <HeaderLabel flex={0.5} />
                      <HeaderLabel
                        flex={1}
                        isSelectAll
                        onClick={() =>
                          handleSelectAll(Number(purchaseOrderForm.supplier.id))
                        }
                      >
                        {t('Select all')}
                      </HeaderLabel>
                    </FormHeaderContainer>
                    <PurchaseOrderLinesWrapper>
                      {purchaseOrderForm.purchaseOrderLines.map(
                        (purchaseOrderLine, purchaseOrderLineIndex) => {
                          return (
                            <FlexColumn
                              key={`${purchaseOrderForm.supplier.company_name}-line-${purchaseOrderLineIndex}`}
                            >
                              <PurchaseOrderLineContainer
                                isSelected={purchaseOrderLine.isSelected}
                                isFirst={purchaseOrderLineIndex === 0}
                                isLast={
                                  purchaseOrderForm.purchaseOrderLines.length -
                                    1 ===
                                  purchaseOrderLineIndex
                                }
                              >
                                {purchaseOrderLine.product
                                  .logyx_configuration_intent ? (
                                  <IsLogyxProductAbsoluteWrapper>
                                    <Icon
                                      svg={LogyxLogo}
                                      size={21}
                                      wrapperStyle={{ cursor: 'default' }}
                                    />
                                  </IsLogyxProductAbsoluteWrapper>
                                ) : null}
                                {purchaseOrderLine.isStockItem ? (
                                  <StockItemAbsoluteInfoTooltipWrapper>
                                    <Tooltip
                                      content={
                                        purchaseOrderLine.isSelected
                                          ? t(
                                              'This item will be added to the purchase order'
                                            )
                                          : t(
                                              'This item will be taken from stock'
                                            )
                                      }
                                    >
                                      <Icon
                                        svg={Info}
                                        size={21}
                                        color={COLORS.PRIMARY}
                                      />
                                    </Tooltip>
                                  </StockItemAbsoluteInfoTooltipWrapper>
                                ) : null}
                                <FormCell flex={2}>
                                  <FormLabel>
                                    {purchaseOrderLine.product.name}
                                  </FormLabel>
                                </FormCell>
                                <FormCell flex={1}>
                                  <FormLabel>
                                    {purchaseOrderLine.isStockItem
                                      ? t('Stock item')
                                      : t('Product')}
                                  </FormLabel>
                                </FormCell>
                                <FormCell flex={0.8}>
                                  <FormLabel
                                    onClick={() => {
                                      setSelectedLine(purchaseOrderLine);
                                      openViewModal(true);
                                    }}
                                  >
                                    {getIn(
                                      values.purchaseOrderLines[
                                        purchaseOrderLineIndex
                                      ],
                                      'quantity'
                                    )}
                                  </FormLabel>
                                </FormCell>
                                <FormCell flex={0.8}>
                                  <FormLabel
                                    onClick={() => {
                                      setSelectedLine(purchaseOrderLine);
                                      openViewModal(true);
                                    }}
                                  >
                                    {getIn(
                                      values.purchaseOrderLines[
                                        purchaseOrderLineIndex
                                      ],
                                      'in_stock'
                                    ) || '/'}
                                  </FormLabel>
                                </FormCell>
                                <FormCell flex={1.5} isInputCell>
                                  <Input
                                    pwId={`purchase-cost-per-unit-${purchaseOrderLineIndex}`}
                                    disabled={!purchaseOrderLine.isSelected}
                                    errorMessage={
                                      areAllFieldsTouched &&
                                      purchaseOrderLine.isSelected &&
                                      getFormikErrorMesssage(
                                        purchaseOrderLineIndex,
                                        values,
                                        errors,
                                        `purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`
                                      )
                                    }
                                    height={'41rem'}
                                    name={`purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`}
                                    type="number"
                                    handleOnChange={(value: any) => {
                                      handlePurchasePriceOnChange(
                                        value,
                                        Number(purchaseOrderForm.supplier.id),
                                        Number(purchaseOrderLine.product.id)
                                      );
                                    }}
                                    onBlur={(e) => {
                                      const value = e.target.value.trim();
                                      setFieldValue(e.target.name, value);
                                      handleBlur(e);
                                      handlePurchasePriceOnChange(
                                        e.target.value,
                                        Number(purchaseOrderForm.supplier.id),
                                        Number(purchaseOrderLine.product.id)
                                      );
                                    }}
                                    symbol={currencySymbol}
                                  />
                                </FormCell>
                                <FormCell flex={0.5}>
                                  <FormLabel
                                    isMoreLabel
                                    onClick={() => {
                                      setSelectedLine(purchaseOrderLine);
                                      openViewModal(true);
                                    }}
                                  >
                                    {t('More')}
                                  </FormLabel>
                                </FormCell>
                                <FormCell flex={1}>
                                  <Button
                                    data-testid={`${purchaseOrderLine.product.name}-select-button-${purchaseOrderLineIndex}`}
                                    width={'100rem'}
                                    height={'31rem'}
                                    onClick={() =>
                                      handleSelect(
                                        Number(purchaseOrderForm.supplier.id),
                                        Number(purchaseOrderLine.product.id)
                                      )
                                    }
                                    label={
                                      purchaseOrderLine.isSelected
                                        ? t('Unselect')
                                        : t('Select')
                                    }
                                    secondary
                                  />
                                </FormCell>
                              </PurchaseOrderLineContainer>
                              {purchaseOrderForm.purchaseOrderLines.length -
                                1 ===
                              purchaseOrderLineIndex ? null : (
                                <Line
                                  color={getLineColorBasedOnSelectedLines(
                                    purchaseOrderForm.purchaseOrderLines,
                                    purchaseOrderLineIndex
                                  )}
                                />
                              )}
                            </FlexColumn>
                          );
                        }
                      )}
                    </PurchaseOrderLinesWrapper>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default PurchaseOrderForm;
