import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { addWorker } from 'services/Workers/WorkersService';
import { IWorkerRequest } from './type';
import { queryClient } from 'index';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useAddWorker = () =>
  useMutation(
    (params: { workers: IWorkerRequest[]; companyId: string }) => {
      return addWorker(params.workers, params.companyId);
    },
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_WORKER,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_WORKERS);
      },
      mutationKey: ReactMutationKeys.ADD_WORKER,
    }
  );
