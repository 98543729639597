import { ISalesOrderLine } from 'types/SalesOrders.types';

export const getProductCardTitle = (
  salesOrderLine: ISalesOrderLine,
  t: any
) => {
  const prefixString = `${salesOrderLine.quantity}x - `;
  if (salesOrderLine.working_hours_line) {
    return t('Working hours');
  } else if (salesOrderLine.service) {
    return prefixString + salesOrderLine.service.purpose;
  } else if (salesOrderLine.stock_item_line) {
    return prefixString + salesOrderLine.product?.name;
  } else if (salesOrderLine.product) {
    return prefixString + salesOrderLine.product.name;
  }
};

export const getProductCardType = (salesOrderLine: ISalesOrderLine, t: any) => {
  if (salesOrderLine.service) {
    return t('Service fee');
  } else if (salesOrderLine.stock_item_line) {
    return t('Stock item');
  } else if (salesOrderLine.product) {
    return t('Product');
  }
};
