import { useNavigate, useParams } from 'react-router-dom';
import {
  AccountingFlowWrapper,
  ClickableLabel,
  Container,
  CreatedAtValidToDiv,
  CreatedAtValidToLabel,
  CreatedAtValidToLabelValue,
  InvoiceDescriptionLabel,
  LoaderWrapper,
  OriginalInvoiceHoverLabel,
  OriginalInvoiceLabel,
  PenImg,
  ReferenceSpan,
  ReferenceWrapper,
  RightDiv,
  RowSpaceBetween,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  SpinnerWrapper,
  StatusLabel,
} from './SingleSalesCreditInvoiceGeneralPage.styled';
import { useGetSingleSalesCreditInvoice } from '../hooks';
import {
  ISalesCreditInvoiceLineTableDTO,
  ISalesInvoiceLine,
} from 'types/Invoice.types';
import { formatSalesCreditInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { EditExternalReferenceModal } from 'pages/Manager/SingleInvoice/EditExternalReferenceModal/EditExternalReferenceModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import { RoutesConfig } from 'navigation/routes';
import { tableConfig } from './constants';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { AccountingFlowSwitch } from '../../AccountingFlow/AccountingFlowSwitch';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { PencilSimple } from '@phosphor-icons/react';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import Tooltip from 'components/Tooltip/Tooltip';

const EMPTY_VALUE = 0;

const SingleSalesCreditInvoiceGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const {
    data,
    isLoading,
    isFetched: isFetchedSingleSalesCreditInvoice,
  } = useGetSingleSalesCreditInvoice(typeof id === 'string' ? id : '');
  const [isEditReferenceModalOpen, setIsEditReferenceModalOpen] =
    useState<boolean>(false);

  const canEditSalesInvoice = useCan(Actions.EDIT_SALES_INVOICE);

  const {
    tax_rate: taxRate,
    has_accounting_integration: hasAccountingIntegration,
  } = useSelector((state: IRootReducerState) => state?.companyInfo?.settings);

  let tableData: ISalesCreditInvoiceLineTableDTO[] = [];
  if (data?.sales_invoice?.sales_invoice_lines) {
    tableData = formatSalesCreditInvoiceLineTableData(
      data.sales_invoice?.sales_invoice_lines
        .map((salesInvoiceLine: ISalesInvoiceLine) => {
          return salesInvoiceLine;
        })
        .flat(),
      taxRate,
      currencySymbol
    );
  }

  return (
    <>
      <AccountingFlowWrapper>
        {isFetchedSingleSalesCreditInvoice ? (
          hasAccountingIntegration && (
            <AccountingFlowSwitch invoice={data?.sales_invoice} />
          )
        ) : (
          <SpinnerWrapper>
            <Spinner size={20} />
          </SpinnerWrapper>
        )}
      </AccountingFlowWrapper>
      <Container>
        {isLoading ? (
          <LoaderWrapper>
            <Spinner size={50} />
          </LoaderWrapper>
        ) : (
          <>
            <RowSpaceBetween>
              <StatusLabel>
                {t(data?.sales_invoice?.payment_status?.name)}
              </StatusLabel>

              <RightDiv>
                <ReferenceWrapper>
                  {data?.sales_invoice?.external_id?.length > 10 ? (
                    <Tooltip content={data?.sales_invoice?.external_id}>
                      <ReferenceSpan
                        $editable={!canEditSalesInvoice}
                        onClick={() => {
                          if (canEditSalesInvoice)
                            return setIsEditReferenceModalOpen(true);
                        }}
                      >
                        {`${t('External reference')}${
                          data?.sales_invoice?.external_id
                            ? ` - ${data?.sales_invoice?.external_id.substring(
                                0,
                                10
                              )}...`
                            : ''
                        }`}
                      </ReferenceSpan>
                    </Tooltip>
                  ) : (
                    <ReferenceSpan
                      $editable={!canEditSalesInvoice}
                      onClick={() => {
                        if (canEditSalesInvoice)
                          return setIsEditReferenceModalOpen(true);
                      }}
                    >
                      {`${t('External reference')}${
                        data?.sales_invoice?.external_id
                          ? ' - ' + data?.sales_invoice?.external_id
                          : ''
                      }`}
                    </ReferenceSpan>
                  )}
                  {canEditSalesInvoice && (
                    <PenImg data-testid="edit-external-reference">
                      <Icon
                        onClick={() => setIsEditReferenceModalOpen(true)}
                        svg={PencilSimple}
                        color={COLORS.PRIMARY}
                        size={15}
                      />
                    </PenImg>
                  )}
                </ReferenceWrapper>
              </RightDiv>
            </RowSpaceBetween>
            <RowSpaceBetween>
              <OriginalInvoiceLabel>
                {`${t('Original invoice')}`}&nbsp;
                <OriginalInvoiceHoverLabel
                  onClick={() =>
                    navigate(
                      RoutesConfig.SingleSalesInvoice.fullPath.replace(
                        ':id/*',
                        data?.sales_invoice?.parent_invoice?.id
                      )
                    )
                  }
                >{`${data?.sales_invoice?.parent_invoice?.invoice_number}`}</OriginalInvoiceHoverLabel>
              </OriginalInvoiceLabel>
            </RowSpaceBetween>
            <RowSpaceBetween>
              <CreatedAtValidToDiv>
                <CreatedAtValidToLabel>
                  {t('Invoice date')}
                </CreatedAtValidToLabel>
                <CreatedAtValidToLabelValue>
                  {moment(data?.sales_invoice?.created_at).format('DD-MM-YYYY')}
                </CreatedAtValidToLabelValue>
              </CreatedAtValidToDiv>
            </RowSpaceBetween>

            <InvoiceDescriptionLabel>
              <ClickableLabel
                onClick={() =>
                  navigate(
                    SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                      ':id',
                      data?.sales_invoice?.customer?.id
                    )
                  )
                }
              >
                {data?.sales_invoice?.customer?.name +
                  ' ' +
                  data?.sales_invoice?.customer?.last_name}
              </ClickableLabel>
              {data?.sales_invoice?.description &&
                ` | ${data?.sales_invoice?.description}`}
            </InvoiceDescriptionLabel>
            <RowSpaceBetweenMarginTop>
              <SmallColumnDiv>
                <SmallColumnDivLabel>
                  {data?.sales_invoice?.is_credit
                    ? t('Corrective amount')
                    : t('Total')}
                </SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.total_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Invoiced')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {data?.sales_invoice?.sent_to_customer
                    ? formatNumber(data?.sales_invoice?.total_amount)
                    : EMPTY_VALUE}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>

              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Open amount')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(
                    data?.sales_invoice?.total_amount -
                      data?.sales_invoice?.total_amount_payed
                  )}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>
                  {t('Received amount')}
                </SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.total_amount_payed)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Offset amount')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.offset_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
            </RowSpaceBetweenMarginTop>
            <Table
              page={-1}
              perPage={-1}
              total={data?.total}
              tableData={tableData}
              tableConfig={tableConfig}
              hasExtraAction={false}
              loadPage={() => null}
            />
            <EditExternalReferenceModal
              isOpen={isEditReferenceModalOpen}
              setIsOpen={setIsEditReferenceModalOpen}
              onCancel={() => setIsEditReferenceModalOpen(false)}
              externalId={data?.sales_invoice?.external_id}
              type={InvoiceType.CREDIT_SALES_INVOICE}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SingleSalesCreditInvoiceGeneralPage;
