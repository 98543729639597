import { isNumeric } from 'utils/validation';

export const validateLastItem = async (values: any, setFieldError: any) => {
  const lastItem = values[values.length - 1];
  let isValid = true;
  if (!lastItem.item) {
    isValid = false;

    await setFieldError(`${values.length - 1}.item`, 'Item is invalid');
  }
  if (!lastItem.quantity) {
    isValid = false;
    await setFieldError(`${values.length - 1}.quantity`, 'Quantity is invalid');
  }
  if (!lastItem.price) {
    isValid = false;
    await setFieldError(`${values.length - 1}.price`, 'Price is invalid');
  }
  return isValid;
};

export const calculateTotalPriceFromQuantityAndCustomPrice = (
  quantity: any,
  customPrice: any
) => {
  if (isNumeric(quantity) && isNumeric(customPrice)) {
    const priceSum = customPrice * quantity;
    return priceSum;
  } else {
    return '';
  }
};
