import { useParams } from 'react-router-dom';
import { useGetFwAppointmentIntervals } from '../FwTimer/hooks';
import {
  BlueHeader,
  TopRow,
  BottomRow,
  Container,
  Content,
  HeaderLabel,
  TimelineRow,
  TimelineRowLabel,
  HeaderLabelWrapper,
  TimelineRowLabelWrapper,
  NoContentLabel,
  LoaderWrapper,
} from './FwSingleAppointmentTimesheetPage.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { IAppointmentInterval } from 'types/Appointment.types';
import {
  calculateTotalTime,
  getIntervalLabel,
  removeUserIntervals,
  splitIntervalByPauses,
} from './helpers';
import moment from 'moment';
import Spinner from 'components/Spinner/Spinner';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const FwSingleAppointmentTimesheetPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: intervalsData, isLoading: isLoadingIntervals } =
    useGetFwAppointmentIntervals(id!);

  const { name: firstName, last_name: lastName } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const intervals = removeUserIntervals(intervalsData?.appointment_intervals);

  // Flatten and sort all subintervals
  const allSubIntervals = intervals
    .flatMap((interval: IAppointmentInterval) =>
      splitIntervalByPauses(interval).map((subInterval) => ({
        ...subInterval,
        parentInterval: interval,
      }))
    )
    .sort(
      (a: { start: string; end: string }, b: { start: string; end: string }) =>
        moment(a.start).valueOf() - moment(b.start).valueOf()
    );

  const { steps } = useRunTour(GuidePages.FIELD_WORKER_TIMESHEET);

  return (
    <Container className="fw-timesheet-step-1">
      <BlueHeader>
        <TopRow>
          <HeaderLabel>{firstName + ' ' + lastName}</HeaderLabel>
          <HeaderLabel>
            {t('Total time for workers')}: {calculateTotalTime(intervals)}
          </HeaderLabel>
        </TopRow>
        <BottomRow>
          <HeaderLabelWrapper>
            <HeaderLabel>
              {t('Worker')}/{t('Resource')}
            </HeaderLabel>
          </HeaderLabelWrapper>
          <HeaderLabelWrapper>
            <HeaderLabel>{t('Start time')}</HeaderLabel>
          </HeaderLabelWrapper>
          <HeaderLabelWrapper>
            <HeaderLabel>{t('Stop time')}</HeaderLabel>
          </HeaderLabelWrapper>
        </BottomRow>
      </BlueHeader>
      <Content>
        {isLoadingIntervals ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : allSubIntervals.length ? (
          allSubIntervals.map(
            (
              subInterval: {
                start: string;
                end: string;
                parentInterval: IAppointmentInterval;
              },
              index: number
            ) => (
              <TimelineRow
                withoutBorderBottom={allSubIntervals.length - 1 <= index}
                key={index} // Unique key for each sub-interval
              >
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {getIntervalLabel(
                      subInterval.parentInterval,
                      firstName + ' ' + lastName
                    )}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {moment
                      .utc(subInterval.start)
                      .tz(moment.tz.guess())
                      .format('HH:mm:ss')}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {subInterval.end
                      ? moment
                          .utc(subInterval.end)
                          .tz(moment.tz.guess())
                          .format('HH:mm:ss')
                      : '/'}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
              </TimelineRow>
            )
          )
        ) : (
          <NoContentLabel>
            {t('There are currently no intervals for this appointment')}
          </NoContentLabel>
        )}
      </Content>
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default FwSingleAppointmentTimesheetPage;
