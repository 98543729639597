import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginXs, paddingXs, paddingSm, marginMd } from 'assets/styled';
import { hoverLineOut } from 'assets/styled/commonStyles';

interface StyledProps {
  selected: boolean;
}

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20rem;
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClearButton = styled.span`
  display: flex;
  color: ${COLORS.RED_100};
  font-size: 16rem;
  cursor: pointer;
  ${hoverLineOut}
  align-self: center;
`;

export const UserContainer = styled.div`
  background-color: ${(props: StyledProps) =>
    props.selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};

  border-bottom: 1rem solid ${COLORS.GREY_300};
  border-radius: ${(props: StyledProps) => (props.selected ? `10PX` : '0')};

  padding: ${paddingXs} ${paddingSm};
  margin-top: ${marginXs};
`;

export const Worker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserName = styled.div`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const AddWorker = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserDescription = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;
export const Margin = styled.div`
  margin-left: ${marginXs};
`;

export const MarginTop = styled.div`
  margin-top: ${marginMd};
`;

export const AddMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
