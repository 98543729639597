import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isActive?: boolean;
}

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  width: 100%;
  max-width: 100%;
  padding-right: 30rem;
`;

export const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: scale(0) translate(-50%, -120%);
  transition: 0.3s ease;
  background-color: red;
  padding: 5rem;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
`;

export const CalendarTab = styled.div`
  border: 1px solid ${COLORS.GREY_500};
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  border-bottom: none;
  padding: 5rem 10rem;
  background-color: ${COLORS.WHITE};
  min-width: 90rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: -10rem;
    left: 0;
    width: 100%;
    height: 10rem;
    background-color: transparent;
  }

  &:hover {
    ${(props: StyledProps) =>
      props.isActive
        ? css`
            background-color: ${COLORS.PRIMARY};
          `
        : css`
            background-color: ${COLORS.BACKGROUND_COLOR};
          `}

    ${Actions} {
      transform: scale(1) translate(-50%, -120%);
    }
  }

  ${(props: StyledProps) =>
    props.isActive &&
    css`
      background-color: ${COLORS.PRIMARY};

      ${TabText} {
        color: ${COLORS.STRICT_WHITE};
      }

      .close-button::after,
      .close-button::before {
        background-color: ${COLORS.STRICT_WHITE};
      }
    `}
`;

export const TabText = styled.span`
  color: ${COLORS.GREY_700};
  font-size: 14rem;
  display: inline-block;
  white-space: nowrap;
`;

export const CloseWrapper = styled.div`
  padding-left: 10rem;
`;
