import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginMd, paddingSm, paddingXs } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  padding-bottom: ${paddingXs};
  justify-content: center;
  align-items: center;
`;

export const UpdateAvailableLabel = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;

export const PleaseRefreshLabel = styled.label`
  font-size: 20rem;
  font-weight: 300;
  line-height: 26rem;
  text-align: center;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;
