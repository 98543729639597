import { H3 } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  StyledCustomSelect,
} from './SupplierTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { createPurchaseOrderSupplierTabSchema } from './validation';
import { ICreatePurchaseOrderSupplierDetails } from './constants';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';

interface ISupplierProps {
  isSupplierPrepopulated: boolean;
  onForward: () => void;
  supplierDetails: ICreatePurchaseOrderSupplierDetails;
  setSupplierDetails: Dispatch<
    SetStateAction<ICreatePurchaseOrderSupplierDetails>
  >;
}

const SupplierTab = ({
  isSupplierPrepopulated,
  onForward,
  supplierDetails,
  setSupplierDetails,
}: ISupplierProps) => {
  const countryOptions = useGetCountryOptions();
  const { t } = useTranslation();

  return (
    <Container>
      <FirstRow>
        <H3>{t('Supplier details')}</H3>
      </FirstRow>
      <Formik
        enableReinitialize
        initialValues={supplierDetails}
        validationSchema={createPurchaseOrderSupplierTabSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onForward}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId="contact-person-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={
                    touched['contact_person'] ? errors['contact_person'] : ''
                  }
                  height={'41rem'}
                  name="contact_person"
                  placeholder={t('Contact person')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('contact_person', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="phone-number-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['phone'] ? errors['phone'] : ''}
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="company-name-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={
                    touched['company_name'] ? errors['company_name'] : ''
                  }
                  height={'41rem'}
                  name="company_name"
                  placeholder={t('Company Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('company_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="vat-number-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={
                    touched['vat_number'] ? errors['vat_number'] : ''
                  }
                  height={'41rem'}
                  name="vat_number"
                  placeholder={t('Company VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('vat_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="email-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['email'] ? errors['email'] : ''}
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Field
                  pwId="country-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['country'] ? errors['country'] : ''}
                  height={'41rem'}
                  name="country"
                  width="50%"
                  options={countryOptions}
                  component={StyledCustomSelect}
                  placeholder={t('Country code')}
                  isMulti={false}
                  onSelect={(value: string) => {
                    setFieldValue('country', value);
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="city-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['city'] ? errors['city'] : ''}
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="zip-code-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['code'] ? errors['code'] : ''}
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="street-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={touched['street'] ? errors['street'] : ''}
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
                <Input
                  pwId="house-number-field"
                  disabled={isSupplierPrepopulated}
                  errorMessage={
                    touched['house_number'] ? errors['house_number'] : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                />
              </Row>
              <AlignRow>
                <Button
                  width={'200rem'}
                  onClick={() => {
                    submitForm();
                    if (isValid) {
                      setSupplierDetails(values);
                      onForward();
                    }
                  }}
                  label={t('Next')}
                  primary
                />
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default SupplierTab;
