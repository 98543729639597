import {
  ButtonContainer,
  CloseContainer,
  TopContainer,
  Margin,
  UploadFileButtonWrapper,
  FileNameLabel,
  TitleContainer,
  Title,
  Container,
  Row,
  DatePickerWrap,
  Error,
} from './PurchaseOrderConfirmationModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Level } from '../type';
import { Formik } from 'formik';
import { COLORS, H4 } from 'assets/styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { FileUploadModal } from '../FileUploadModal/FileUploadModal';
import { IUseConfirmPurchaseOrderDTO, useConfirmPurchaseOrder } from './hooks';
import { validateField } from 'utils/validation';
import { getFormikErrorMesssage } from './validation';
import { IPurchaseOrder } from 'types/PurchaseOrders.types';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import Icon from 'components/Icon/Icon';
import { cutLongWords } from 'utils/stringUtils';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

interface IConfirmProps {
  purchaseOrder: IPurchaseOrder;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel?: () => void;
  level?: Level;
}

export const PurchaseOrderConfirmationModal = ({
  purchaseOrder,
  isOpen,
  setIsOpen,
  onCancel,
  level = 'FIRST',
}: IConfirmProps) => {
  const { t } = useTranslation();
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [files, setFiles] = useState<any>(null);

  const {
    mutate: confirmPurchaseOrder,
    isLoading,
    isSuccess,
    reset,
  } = useConfirmPurchaseOrder(Number(purchaseOrder.id));

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
      setFiles(null);
      reset();
    }
  }, [isSuccess]);

  const handleConfirmPurchaseOrder = (values: any) => {
    if (!files) {
      return;
    }

    const confirmPurchaseOrderDTO: IUseConfirmPurchaseOrderDTO = {
      files,
      reference: values.reference,
      expected_delivery_date: values.expected_delivery_date,
      confirmed_delivery_date: values.confirmed_delivery_date,
    };
    confirmPurchaseOrder(confirmPurchaseOrderDTO);
  };

  const removeFile = (fileToRemove: any) => {
    setFiles((currentFiles: any[]) =>
      currentFiles.filter((file) => file !== fileToRemove)
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setFiles(null);
          setIsUploadFileModalOpen(false);
        }
        setIsOpen(boolean);
      }}
      level={level}
      modalStyle={{
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel && onCancel();
              setFiles(null);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Confirm purchase order')}</Title>
        </TitleContainer>
        <Formik
          enableReinitialize
          initialValues={{
            reference: purchaseOrder.reference || '',
            confirmed_delivery_date: purchaseOrder.expected_delivery_date
              ? moment(purchaseOrder.expected_delivery_date).format(
                  'YYYY-MM-DD'
                )
              : '',
            expected_delivery_date: purchaseOrder.promised_delivery_date
              ? moment(purchaseOrder.promised_delivery_date).format(
                  'YYYY-MM-DD'
                )
              : '',
          }}
          onSubmit={() => {
            // On submit
          }}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            isValid,
            touched,
            errors,
          }) => {
            return (
              <Container>
                <div>
                  <H4>{t('Reference')}</H4>
                  <FormikInput
                    pwId="reference-field"
                    type="text"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    name={`reference`}
                    height={'41rem'}
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '10rem',
                    }}
                    validate={(value: string) =>
                      validateField(value, 'Reference')
                    }
                    errorMessage={getFormikErrorMesssage(
                      touched,
                      errors,
                      'reference'
                    )}
                  />
                  <H4>{t('Confirmed delivery date')}</H4>
                  <DatePickerWrap>
                    <DatePicker
                      pwId="confirmed-delivery-date-field"
                      date={moment(values?.confirmed_delivery_date)}
                      setDate={(newValue: any) =>
                        setFieldValue(
                          'confirmed_delivery_date',
                          newValue?.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </DatePickerWrap>
                  <H4>{t('Expected delivery date')}</H4>
                  <DatePickerWrap>
                    <DatePicker
                      pwId="expected-delivery-date-field"
                      date={moment(values?.expected_delivery_date)}
                      setDate={(newValue: any) =>
                        setFieldValue(
                          'expected_delivery_date',
                          newValue?.format('YYYY-MM-DD')
                        )
                      }
                    />
                  </DatePickerWrap>
                  <UploadFileButtonWrapper>
                    <Button
                      disabled={isLoading}
                      label={t('Upload file')}
                      secondary
                      width={'200rem'}
                      onClick={() => setIsUploadFileModalOpen(true)}
                    />
                    {files &&
                      files.map((file: any, index: number) => (
                        <Row key={index}>
                          <FileNameLabel>
                            {cutLongWords(file.name, 30)}
                          </FileNameLabel>
                          <Icon
                            svg={Trash}
                            color={COLORS.RED_200}
                            size={20}
                            weight="regular"
                            onClick={() => removeFile(file)}
                          />
                        </Row>
                      ))}
                  </UploadFileButtonWrapper>
                  {!files && (
                    <Error>
                      {t('You need to upload at least one file to continue')}
                    </Error>
                  )}
                </div>
                <ButtonContainer>
                  <Margin>
                    <Button
                      onClick={() => {
                        onCancel && onCancel();
                      }}
                      secondary
                      width="200rem"
                      label={t('Cancel')}
                    />
                  </Margin>
                  <Button
                    primary
                    disabled={isLoading || !isValid || !files}
                    width="200rem"
                    label={t('Confirm')}
                    onClick={() => setIsConfirmModalOpen(true)}
                  />
                </ButtonContainer>
                <ConfirmModal
                  level="SECOND"
                  title={t('Purchase Order Confirmation')}
                  description={`${t(
                    'Are you sure you want to confirm a Purchase Order'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => handleConfirmPurchaseOrder(values)}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
              </Container>
            );
          }}
        </Formik>
      </TopContainer>
      <FileUploadModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        onSubmit={(files: any) => {
          setFiles(files);
          setIsUploadFileModalOpen(false);
        }}
      />
    </Modal>
  );
};
