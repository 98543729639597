import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { ICloseProps } from './Close';
import { Size } from './type';

export const CloseStyled = styled('div')`
  position: relative;
  display: inline-block;
  width: 25rem;
  height: 25rem;
  cursor: pointer;

  ${({ size }: ICloseProps) => {
    if (size === Size.SMALL) {
      return css`
        width: 15rem;
        height: 15rem;
      `;
    }
  }}

  ${({ size }: ICloseProps) => {
    if (size === Size.EXTRA_SMALL) {
      return css`
        width: 12rem;
        height: 12rem;
      `;
    }
  }}

  &:hover {
    &::after,
    &::before {
      transform: translate(-50%, -50%) rotateZ(0);
    }
  }

  &::after,
  ::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1rem;
    background-color: ${COLORS.BLACK};
    content: '';
    display: inline-block;
    transform: translate(-50%, -50%);
    transition: all 0.3s;
  }

  &::after {
    transform: translate(-50%, -50%) rotateZ(-45deg);
  }

  &::before {
    transform: translate(-50%, -50%) rotateZ(45deg);
  }
`;
