import { ButtonContainer, TopContainer, Margin } from './AddWorkerForm.styled';
import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { CloseContainer, TitleContainer, Title } from '../WorkersModal.styled';
import { useAddWorker } from './hooks';
import { Formik } from 'formik';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { addWorkerSchema } from './validation';

export interface IAddWorkerFormProps {
  onCancel: () => void;
}

const AddWorkerForm = ({ onCancel }: IAddWorkerFormProps) => {
  const { mutate: addWorker } = useAddWorker();
  const { t } = useTranslation();
  const handleAddWorker = async (
    values: { name: string; description: string },
    isValid: any
  ) => {
    if (!isValid) {
      return;
    }

    addWorker({ workers: [values], companyId: '2' });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ description: '', name: '' }}
        validationSchema={addWorkerSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleAddWorker}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <div>
              <TopContainer>
                <CloseContainer>
                  <Close onClick={onCancel} />
                </CloseContainer>
                <TitleContainer>
                  <Title>{t('Add Worker')}</Title>
                </TitleContainer>
                <FormikInput
                  pwId="name-field"
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'41rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                    marginBottom: '10rem',
                    marginTop: '10rem',
                  }}
                />
                <FormikInput
                  pwId="description-field"
                  isTextArea
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  name="description"
                  placeholder={t('Description')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                />
              </TopContainer>
              <ButtonContainer>
                <Margin>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="150rem"
                    label={t('Cancel')}
                  />
                </Margin>
                <Button
                  data-testid="add-button"
                  disabled={!values.description}
                  primary
                  width="150rem"
                  label={t('Add')}
                  onClick={() => {
                    submitForm();
                    onCancel();
                  }}
                />
              </ButtonContainer>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddWorkerForm;
