import { COLORS, borderRadiusSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  withoutBottomBorder: boolean;
  isTotalTime: boolean;
}

export const NoIntervalsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${COLORS.BLACK};
`;
export const WorkersSection = styled.div``;

export const ResourcesSection = styled.div``;

export const WorkerResourceTimeSpentHeader = styled.div`
  width: 340rem;
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.PRIMARY};
  border-top-left-radius: ${borderRadiusSm};
  border-top-right-radius: ${borderRadiusSm};
  align-items: center;
  justify-content: space-evenly;
  height: 60rem;
`;

export const TimesheetHeaderLabel = styled.div`
  font-size: 14rem;
  font-weight: 300;
  color: ${COLORS.WHITE};
`;

export const WorkerResourceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 60rem;
  border-bottom: 1rem solid ${COLORS.GREY_200};
  ${(props: StyledProps) =>
    props.withoutBottomBorder &&
    css`
      border-bottom: none;
    `}
`;

export const WorkerResourceRowLabel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isTotalTime &&
    css`
      font-weight: 600;
    `}
`;
