import { Moment } from 'moment';
import { useQuery } from 'react-query';
import { getFwAppointments } from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetFwAppointments = (
  dateFrom: Moment,
  dateTo: Moment,
  searchBy: string,
  sortBy?: string,
  sortDirection?: string,
  selectedType?: string,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_APPOINTMENTS,
      dateFrom,
      dateTo,
      searchBy,
      sortBy,
      sortDirection,
      selectedType,
    ],
    queryFn: () => {
      return getFwAppointments(
        dateFrom,
        dateTo,
        searchBy,
        sortBy,
        sortDirection,
        selectedType
      );
    },
    enabled: isEnabled,
  });
