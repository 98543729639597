import { store } from 'store/store';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from './mobile/postMobileMessage';

export const downloadBlob = (data: Blob, fileName: string) => {
  if (!store.getState().commonInfo.isMobileApp) {
    const filename = `${fileName}.xlsx`;
    const blobUrl = URL.createObjectURL(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = filename;
    downloadLink.click();
  } else {
    const blob: any = data;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {
      const base64data = reader.result as string;
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.DOWNLOAD_BASE64,
        payload: { data: base64data, fileName, mimeType: 'xlsx' },
      };
      postMobileMessage(mobileMessage);
    };
  }
};
