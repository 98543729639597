import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getAllCompanyUsers } from 'services/Company/CompanyService';

export const useGetCompanyUsers = (companyId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_USERS, companyId],
    queryFn: () => {
      return getAllCompanyUsers(companyId);
    },
    enabled: !!companyId,
  });
