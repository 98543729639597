import { ArrowRight } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { Text, Wrapper } from './MoreButton.styled';

const MoreButton = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Text>{t('More')}</Text>
      <Icon
        svg={ArrowRight}
        color={COLORS.PRIMARY}
        size={13}
        weight="regular"
      />
    </Wrapper>
  );
};

export default MoreButton;
