import ReactDOM from 'react-dom';
import { Level } from './type';
import { BackDrop, StyledModal } from './Modal.styled';
import { CSSProperties, Dispatch, SetStateAction, useEffect } from 'react';

interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onBackDropClick?: () => void;
  level?: Level;
  modalStyle?: CSSProperties;
}

export const Modal = ({
  children,
  isOpen,
  setIsOpen,
  onBackDropClick,
  level = 'FIRST',
  modalStyle,
}: IModalProps) => {
  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  if (!isOpen) return <></>;

  return ReactDOM.createPortal(
    <div className="modal-open">
      <BackDrop
        level={level}
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(false);
          onBackDropClick?.();
        }}
      />
      <StyledModal style={modalStyle} level={level}>
        {children}
      </StyledModal>
    </div>,
    document.body
  );
};
