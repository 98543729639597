import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { queryClient } from 'index';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { addProduct, editProduct } from 'services/Product/ProductService';
import { useLocation } from 'react-router-dom';
import { IProductAttribute } from 'types/Product.types';

export interface IAddEditProductDTO {
  name: string;
  description: string;
  logyx_model_id: string;
  supplier_id?: string;
  product_id?: string;
  files?: any;
  attributes: IProductAttribute[];
  deleted_attachment_ids?: any;
  digital_order_enabled: boolean;
}

export const useAddProduct = () =>
  useMutation(
    (editProductData: IAddEditProductDTO) => {
      return addProduct(editProductData);
    },
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_PRODUCT,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS_INFINITE);
      },
      mutationKey: ReactMutationKeys.ADD_PRODUCT,
    }
  );

export const useEditProduct = () =>
  useMutation(
    (editProductData: IAddEditProductDTO) => {
      return editProduct(editProductData);
    },
    {
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PRODUCT,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PRODUCTS_INFINITE);
      },
      mutationKey: ReactMutationKeys.EDIT_PRODUCT,
    }
  );

export const useGetSupplierId = () => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  return pathParts[3];
};

export const useIsLogyxModelIdFieldVisible = () => {
  // const companyName = useSelector(
  //   (state: IRootReducerState) => state.companyInfo.name
  // );
  return true;
};
