import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { gapSm, marginSm } from 'assets/styled';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const TopContainer = styled.div``;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${gapSm};
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;
