import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { reorderPurchaseOrderLines } from 'services/PurchaseOrder/PurchaseOrderService';
import { reorderQuotationLines } from 'services/Quotation/QuotationService';
import { reorderSalesOrderLines } from 'services/SalesOrder/SalesOrderService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { DraggableLineType } from './DraggableLines';
import { reorderFwAppointmentLines } from 'services/Appointment/FwAppointmentService';

export const useReorderLines = (id: number, lineType: DraggableLineType) => {
  const { mutate: reorderQuotationLines } = useReorderQuotationLines(id);
  const { mutate: reorderSalesOrderLines } = useReorderSalesOrderLines(id);
  const { mutate: reorderPurchaseOrderLines } =
    useReorderPurchaseOrderLines(id);
  const { mutate: reorderFwAppointmentLines } =
    useReorderFwAppointmentLines(id);

  switch (lineType) {
    case DraggableLineType.QUOTATION:
      return reorderQuotationLines;
    case DraggableLineType.SALES_ORDER:
      return reorderSalesOrderLines;
    case DraggableLineType.PURCHASE_ORDER:
      return reorderPurchaseOrderLines;
    case DraggableLineType.APPOINTMENT:
      return reorderFwAppointmentLines;
  }
};

export interface IReorderLinesRequestDTO {
  lines: IReorderLineDTO[];
}

export interface IReorderLineDTO {
  lineId: number;
  lineIndex: number;
}

const useReorderQuotationLines = (id: number) => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IReorderLinesRequestDTO) => reorderQuotationLines(id, dto),
    {
      onSuccess: () => {
        toast.success(t('Quotation lines reordered'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REORDER_QUOTATION_LINES,
        });
      },
      mutationKey: ReactMutationKeys.REORDER_QUOTATION_LINES,
    }
  );
};

const useReorderSalesOrderLines = (id: number) => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IReorderLinesRequestDTO) => reorderSalesOrderLines(id, dto),
    {
      onSuccess: () => {
        toast.success(t('Sales order lines reordered'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REORDER_SALES_ORDER_LINES,
        });
      },
      mutationKey: ReactMutationKeys.REORDER_SALES_ORDER_LINES,
    }
  );
};

const useReorderPurchaseOrderLines = (id: number) => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IReorderLinesRequestDTO) => reorderPurchaseOrderLines(id, dto),
    {
      onSuccess: () => {
        toast.success(t('Purchase order lines reordered'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REORDER_PURCHASE_ORDER_LINES,
        });
      },
      mutationKey: ReactMutationKeys.REORDER_PURCHASE_ORDER_LINES,
    }
  );
};

const useReorderFwAppointmentLines = (id: number) => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IReorderLinesRequestDTO) => reorderFwAppointmentLines(id, dto),
    {
      onSuccess: () => {
        toast.success(t('Appointment lines reordered'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REORDER_FW_APPOINTMENT_LINES,
        });
      },
      mutationKey: ReactMutationKeys.REORDER_FW_APPOINTMENT_LINES,
    }
  );
};
