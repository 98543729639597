import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { Level } from './type';
import { CSSProperties } from 'react';

interface IBackDrop {
  level: Level;
}

interface IModal {
  level: Level;
  style: CSSProperties;
}

const levelStyle = {
  FIRST: {
    backDropStyle: css`
      z-index: 8000;
      background-color: ${COLORS.BLACK_200_OPACITY};
    `,
    modalStyle: css`
      z-index: 8100;
    `,
  },
  SECOND: {
    backDropStyle: css`
      z-index: 8200;
      background-color: ${COLORS.BLACK_300_OPACITY};
    `,
    modalStyle: css`
      z-index: 8300;
    `,
  },
  THIRD: {
    backDropStyle: css`
      z-index: 8300;
      background-color: ${COLORS.BLACK_400_OPACITY};
    `,
    modalStyle: css`
      z-index: 8400;
    `,
  },
};

export const BackDrop = styled('div')`
  text-transform: uppercase;
  position: fixed;
  left: 0;
  top: 0;
  ${({ level }: IBackDrop) => levelStyle[level].backDropStyle}
  width: 100%;
  height: 100%;
  overflow: auto;
  backdrop-filter: blur(0.8rem);
`;

export const StyledModal = styled('div')`
  position: absolute;
  height: fit-content;
  max-height: 90vh;
  width: 98%;
  ${({ level }: IModal) => levelStyle[level].modalStyle}
  ${({ level }: IModal) =>
    level === 'FIRST'
      ? css`
          max-width: 600rem;
        `
      : level === 'SECOND'
      ? css`
          max-width: 550rem;
        `
      : css`
          max-width: 500rem;
        `}
  inset: 0;
  margin: auto;
  padding: 32rem;
  background-color: ${COLORS.WHITE};
  border-radius: 8rem;
  box-shadow: 0rem 8rem 48rem rgba(0, 0, 0, 0.12);
  border: 1px solid ${COLORS.GREY_200};
  overflow: auto;
  ${({ style }: IModal) => style}
`;
