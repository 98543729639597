import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${COLORS.WHITE};
  width: 750rem;
  padding: 30rem;
  border-radius: ${borderRadiusSm};
`;

export const Headline = styled.h3`
  margin-bottom: 30rem;
  color: ${COLORS.BLACK};
  margin-right: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30rem;
`;

export const TableWrapper = styled.div`
  margin-bottom: 30rem;
  color: ${COLORS.BLACK};
  max-width: 100%;
  width: 100%;
`;

export const BigLoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
`;
