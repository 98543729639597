import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
} from 'types/Product.types';
import { IAddEditProduct } from './types';

export const getInitialAttributes = (product?: IAddEditProduct) => {
  if (!product) {
    return [];
  }
  const attributesArray: IProductAttribute[] = [];
  for (const attribute of product.attributes) {
    if (!attribute.docVisibilityOptions) {
      // Injecting docVisibilityOptions into legacy attributes
      attributesArray.push({
        ...attribute,
        docVisibilityOptions: Object.values(IDocVisibilityOptionEnum),
      });
    } else {
      attributesArray.push(attribute);
    }
  }
  return attributesArray;
};
