import { formatNumber } from 'utils/numberFormatter';

export const formatProductFormDTONumberFields = (
  key: string,
  value: string
) => {
  const productFormDTONumberFields = [
    'salesPrice',
    'purchasePrice',
    'quantity',
    'discount',
  ];
  if (productFormDTONumberFields.includes(key)) {
    return formatNumber(value);
  } else {
    return value;
  }
};
