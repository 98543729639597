import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  customerExport,
  getSignatureInfo,
  uploadCustomerSignature,
} from 'services/Quotation/QuotationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';

export const useGetSignatureInfo = (token: string, companyId: string) =>
  useQuery({
    queryKey: ReactQueryKeys.GET_SIGNATURE_INFO,
    queryFn: () => {
      return getSignatureInfo(token, companyId);
    },
  });

export const useUploadCustomerSignature = (
  token: string,
  companyId: string,
  onUploadSuccess: () => void
) => {
  const { t } = useTranslation();
  return useMutation(
    (file: any) => {
      return uploadCustomerSignature(file, token, companyId);
    },
    {
      onSuccess: () => {
        toast.success(t('Signature added'), {
          className: ReactMutationKeys.UPLOAD_CUSTOMER_SIGNATURE,
        });
        onUploadSuccess();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_CUSTOMER_SIGNATURE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_CUSTOMER_SIGNATURE,
    }
  );
};

export const useCustomerExport = (token: string, companyId: string) =>
  useMutation(() => customerExport(token, companyId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.CUSTOMER_EXPORT,
      });
    },
    mutationKey: ReactMutationKeys.CUSTOMER_EXPORT,
  });
