import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  authorizeGoogleAccount,
  authorizeOutlook,
  getGoogleCalendarPreferences,
  getOutlookCalendarPreferences,
  signOutFromGoogleAccount,
  signOutFromMicrosoft,
} from 'services/Calendar/CalendarService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';
import { CalendarType } from './CalendarIntegration';
import { Dispatch, SetStateAction, useEffect } from 'react';

export const useAuthorizeGoogleAccount = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_AUTHORIZE_GOOGLE_ACCOUNT],
    queryFn: () => {
      return authorizeGoogleAccount();
    },
  });

export const useLogoutGoogleAccount = () => {
  const { t } = useTranslation();
  return useMutation(
    () => {
      return signOutFromGoogleAccount();
    },
    {
      onSuccess: () => {
        toast.success(t('Signed out from Google'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_AUTHORIZE_GOOGLE_ACCOUNT,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.LOGOUT_GOOGLE_ACCOUNT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.LOGOUT_GOOGLE_ACCOUNT,
    }
  );
};

export const useAuthorizeOutlook = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_AUTHORIZE_OUTLOOK],
    queryFn: () => {
      return authorizeOutlook();
    },
  });

export const useLogoutOutlook = () => {
  const { t } = useTranslation();
  return useMutation(
    () => {
      return signOutFromMicrosoft();
    },
    {
      onSuccess: () => {
        toast.success(t('Signed out from Microsoft'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_AUTHORIZE_OUTLOOK]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.LOGOUT_MICROSOFT_ACCOUNT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.LOGOUT_MICROSOFT_ACCOUNT,
    }
  );
};

export const useGetGoogleCalendarPreferences = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_GOOGLE_CALENDAR_PREFERENCES],
    queryFn: () => {
      return getGoogleCalendarPreferences();
    },
  });

export const useGetOutlookCalendarPreferences = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_OUTLOOK_CALENDAR_PREFERENCES],
    queryFn: () => {
      return getOutlookCalendarPreferences();
    },
  });

// is_custom_calendar_preferred is null if there is no preference yet
export const useGetCalendarPreferences = () => {
  const {
    data: googleCalendarPreferencesData,
    isLoading: isLoadingGoogleCalendarPreferences,
  } = useGetGoogleCalendarPreferences();
  const {
    data: outlookCalendarPreferencesData,
    isLoading: isLoadingOutlookCalendarPreferences,
  } = useGetOutlookCalendarPreferences();

  return {
    isGoogleCustomCalendarPrefered:
      googleCalendarPreferencesData?.is_custom_calendar_preferred,
    isOutlookCustomCalendarPrefered:
      outlookCalendarPreferencesData?.is_custom_calendar_preferred,
    isLoadingGoogleCalendarPreferences,
    isLoadingOutlookCalendarPreferences,
  };
};

export const useShowCalendarPreferenceModal = (
  loggedIntoGoogle: boolean,
  loggedIntoOutlook: boolean,
  isGoogleCustomCalendarPrefered: boolean | null,
  isOutlookCustomCalendarPrefered: boolean | null,
  setManagePreferenceCalendarType: Dispatch<SetStateAction<CalendarType | null>>
) => {
  useEffect(() => {
    if (loggedIntoGoogle && isGoogleCustomCalendarPrefered === null) {
      setManagePreferenceCalendarType(CalendarType.GOOGLE);
    }
  }, [loggedIntoGoogle, isGoogleCustomCalendarPrefered]);
  useEffect(() => {
    if (loggedIntoOutlook && isOutlookCustomCalendarPrefered === null) {
      setManagePreferenceCalendarType(CalendarType.OUTLOOK);
    }
  }, [loggedIntoOutlook, isOutlookCustomCalendarPrefered]);
};
