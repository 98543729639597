import { useTranslation } from 'react-i18next';
import {
  Body,
  BodyCell,
  BodyCellName,
  BodyLabel,
  More,
  NoContentLabel,
  Pending,
  PendingText,
  Reconfigure,
  ReconfigureLogo,
  ReconfigureText,
  Row,
  Table,
} from './ProductValidationTable.styled';
import Button from 'components/Button/Button';
import { ILogyxOrderLineTableDTO, LogyxOrderStatus } from './types';
import LogyxLogo from 'assets/icons/logyxLogoSmall.svg';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';

interface IProps {
  logyxOrderLineTableDTOs: ILogyxOrderLineTableDTO[];
  onReconfigureClick: (params: any) => void;
  onOrderManuallyClick: (params: any) => void;
  onMoreClick: (params: any) => void;
}

const ProductValidationTable = ({
  logyxOrderLineTableDTOs,
  onReconfigureClick,
  onOrderManuallyClick,
  onMoreClick,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Table>
      {logyxOrderLineTableDTOs.length ? (
        <Body>
          {logyxOrderLineTableDTOs?.map(
            (row: ILogyxOrderLineTableDTO, index: number) => {
              return (
                <Row key={index} $status={row.status}>
                  <BodyCellName>
                    <BodyLabel>{row.name}</BodyLabel>
                  </BodyCellName>
                  {row.line && (
                    <BodyCell>
                      <More onClick={() => onMoreClick(row.line)}>
                        {t('More')}
                      </More>
                    </BodyCell>
                  )}
                  <BodyCell>
                    {(row.status === LogyxOrderStatus.INVALID_LOGYX_CONFIG ||
                      row.status === LogyxOrderStatus.VALID) && (
                      <Button
                        redOutline
                        label={t('Order manually')}
                        onClick={() => onOrderManuallyClick(row.line)}
                      />
                    )}
                    {row.status === LogyxOrderStatus.VALIDATION_IN_PROGRESS && (
                      <Pending>
                        <Spinner size={17} color={COLORS.PRIMARY} />
                        <PendingText>{t('Pending')}</PendingText>
                      </Pending>
                    )}
                  </BodyCell>
                  <BodyCell>
                    {row.status === LogyxOrderStatus.INVALID_LOGYX_CONFIG && (
                      <Reconfigure onClick={() => onReconfigureClick(row.line)}>
                        <ReconfigureLogo alt="Logyx" src={LogyxLogo} />
                        <ReconfigureText>{t('Reconfigure')}</ReconfigureText>
                      </Reconfigure>
                    )}
                  </BodyCell>
                </Row>
              );
            }
          )}
        </Body>
      ) : (
        <NoContentLabel>{t('No lines available')}</NoContentLabel>
      )}
    </Table>
  );
};

export default ProductValidationTable;
