import Icon from 'components/Icon/Icon';
import { CurrentItemsLabel, RowCenter } from './BasicPagination.styled';
import { COLORS, marginXs } from 'assets/styled';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

interface IBasicPaginationProps {
  page: number;
  perPage: number;
  total: number;
  hasNextPage: boolean;
  loadPage: (page: number) => void;
}

// Similar to Pagination, but the total is not displayed and has additional prop 'hasNextPage'
export const BasicPagination = ({
  page,
  perPage,
  total,
  hasNextPage,
  loadPage,
}: IBasicPaginationProps) => {
  const handleLoadPage = (newPage: number) => {
    if (newPage === 0) {
      return;
    }
    loadPage(newPage);
  };

  return (
    <RowCenter>
      <Icon
        svg={CaretLeft}
        color={page === 1 ? COLORS.GREY_1000 : COLORS.PRIMARY}
        size={18}
        weight="bold"
        onClick={() => handleLoadPage(page - 1)}
        marginRight={marginXs}
      />
      <CurrentItemsLabel>
        {(page - 1) * perPage + 1} - {total}
      </CurrentItemsLabel>
      <Icon
        svg={CaretRight}
        color={hasNextPage ? COLORS.PRIMARY : COLORS.GREY_1000}
        size={18}
        weight="bold"
        marginLeft={marginXs}
        onClick={() => hasNextPage && handleLoadPage(page + 1)}
      />
    </RowCenter>
  );
};

export default BasicPagination;
