import { Fragment, useEffect, useState } from 'react';
import {
  AddSignatureWrapper,
  Card,
  Container,
  Heading,
  HorizontalLine,
  Label,
  LabelKey,
  LabelWrapper,
  LoaderWrapper,
  NoContentLabel,
  RowWrapper,
} from './SingleSalesOrderNewSignature.styled';
import {
  useGetSingleSalesOrder,
  useGetSingleSalesOrderSignatures,
} from '../hooks';
import { useParams } from 'react-router-dom';
import {
  ISignatureFormDTO,
  SingleSalesOrderNewSignatureForm,
} from './SingleSalesOrderNewSignatureForm/SingleSalesOrderNewSignatureForm';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import {
  formatGetSignaturesResponseIntoInitialData,
  initializeSignatureFormDto,
} from './helpers';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const SingleSalesOrderNewSignature = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { data: salesOrder } = useGetSingleSalesOrder(id!);
  const {
    data: salesOrderSignatures,
    isSuccess: isSuccessGetSignatures,
    isLoading: isLoadingGetSignatures,
  } = useGetSingleSalesOrderSignatures(id!);

  const [signatureFormDTOs, setSignatureFormDTOs] = useState<
    ISignatureFormDTO[]
  >([]);
  useEffect(() => {
    if (isSuccessGetSignatures) {
      setSignatureFormDTOs(
        formatGetSignaturesResponseIntoInitialData(
          salesOrderSignatures,
          Number(id!)
        )
      );
    }
  }, [isSuccessGetSignatures]);

  return (
    <Container>
      <Card>
        <Heading>{t('Customer Signatures')}</Heading>
        <RowWrapper>
          <LabelWrapper>
            <LabelKey>{t('Sales order')}</LabelKey>
            <Label>{salesOrder?.number}</Label>
          </LabelWrapper>
          <LabelWrapper>
            <LabelKey>{t('Total amount')}</LabelKey>
            <Label>
              {currencySymbol}
              {salesOrder?.total_amount?.toFixed(2)}
            </Label>
          </LabelWrapper>
        </RowWrapper>
        <HorizontalLine withoutMarginTop />
        {isLoadingGetSignatures && !signatureFormDTOs.length && (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        )}
        {!isLoadingGetSignatures && !signatureFormDTOs.length && (
          <NoContentLabel>
            {t('There are currently no signatures for this sales order')}
          </NoContentLabel>
        )}
        {signatureFormDTOs.map(
          (signatureFormDTO: ISignatureFormDTO, index: number) => (
            <Fragment key={index}>
              <SingleSalesOrderNewSignatureForm
                handleUpdateSignatureForm={(
                  newSignatureFormDTO: ISignatureFormDTO
                ) => {
                  const newSignatureFormDTOs = [...signatureFormDTOs];
                  newSignatureFormDTOs[index] = newSignatureFormDTO;
                  setSignatureFormDTOs(newSignatureFormDTOs);
                }}
                handleRemoveSignatureForm={() => {
                  const newSignatureFormDTOs = [...signatureFormDTOs];
                  newSignatureFormDTOs.splice(index, 1);
                  setSignatureFormDTOs(newSignatureFormDTOs);
                }}
                signatureFormDTO={signatureFormDTO}
              />
              {signatureFormDTOs.length !== index && <HorizontalLine />}
            </Fragment>
          )
        )}
        <AddSignatureWrapper>
          <Button
            label={t('Add another signature')}
            link
            icon={Plus}
            sizeIcon={15}
            colorIcon={COLORS.PRIMARY}
            weightIcon="bold"
            onClick={() =>
              setSignatureFormDTOs([
                ...signatureFormDTOs,
                initializeSignatureFormDto(salesOrder),
              ])
            }
            fontSize="14rem"
          />
        </AddSignatureWrapper>
      </Card>
    </Container>
  );
};

export default SingleSalesOrderNewSignature;
