import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/mandates/';

export const deleteMandate = async (mandateId: string): Promise<any> => {
  const url = `${BASE_URL}delete/${mandateId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const editMandate = async (
  mandateId: string,
  enabled: boolean
): Promise<any> => {
  const queryParamsObj = {
    enabled: enabled ? '1' : '0',
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}enable/${mandateId}`,
    queryParamsObj
  );
  const { data } = await apiClient.patch<any>(fullUrl);

  return data.data;
};
