import {
  COLORS,
  borderRadiusSm,
  marginSm,
  marginMd,
  paddingLg,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
`;

export const NoResultsContainer = styled.div`
  width: 100%;
  max-width: 850rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 790rem;
  margin: 0 auto;
`;

export const Headline = styled.span`
  font-size: 24rem;
  line-height: 36rem;
  margin-top: ${marginMd};
  color: ${COLORS.BLACK};
  display: block;
`;

export const Message = styled.span`
  margin-top: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
`;

export const ReportFilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${marginMd};
`;

export const ReportFilterTypeLabel = styled.label`
  cursor: pointer;
  margin-top: ${marginSm};
  margin-bottom: -10rem;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const NoReportsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${paddingLg};
`;
