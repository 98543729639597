import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonContainer,
  CloseContainer,
  Margin,
  Title,
  TitleContainer,
} from '../WorkersModal/WorkersModal.styled';
import { TopContainer } from './EditWorkerOrResourceModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { EditingType, IWorkerAndResource } from '../WorkersModal/type';
import { addWorkerSchema } from '../WorkersModal/AddWorkerForm/validation';
import { useEditWorker } from '../WorkersModal/hooks';
import { useEditResource } from '../ResourcesModal/hooks';

interface IEditWorkerOrResourceProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  entity: IWorkerAndResource;
  type: EditingType;
}
export const EditWorkerOrResourceModal = ({
  isOpen,
  onCancel,
  setIsOpen,
  entity,
  type,
}: IEditWorkerOrResourceProps) => {
  const { t } = useTranslation();

  const { mutate: editWorker } = useEditWorker(entity?.id);
  const { mutate: editResource } = useEditResource(entity?.id);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="SECOND"
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            name: entity?.name,
            description: entity?.description,
          }}
          validationSchema={addWorkerSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
          validateOnMount={true}
        >
          {({
            handleBlur,
            setFieldValue,
            isValid,
            values,
            errors,
            touched,
          }) => {
            return (
              <div>
                <TopContainer>
                  <CloseContainer>
                    <Close onClick={onCancel} />
                  </CloseContainer>
                  <TitleContainer>
                    <Title>
                      {type === EditingType.WORKER
                        ? t('Edit Worker')
                        : type === EditingType.RESOURCE
                        ? t('Edit Resource')
                        : null}
                    </Title>
                  </TitleContainer>
                  <FormikInput
                    pwId="name-field"
                    errorMessage={touched['name'] ? errors['name'] : ''}
                    height={'41rem'}
                    name="name"
                    placeholder={t('Name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '60%',
                      marginBottom: '30rem',
                      marginTop: '30rem',
                    }}
                  />
                  <FormikInput
                    pwId="description-field"
                    isTextArea
                    errorMessage={
                      touched['description'] ? errors['description'] : ''
                    }
                    name="description"
                    placeholder={t('Description')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('description', value);
                      handleBlur(e);
                    }}
                  />
                </TopContainer>
                <ButtonContainer>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Margin />
                  <Button
                    data-testid="apply-button"
                    disabled={!values.description || !values.name}
                    primary
                    width="200rem"
                    label={t('Apply')}
                    onClick={() => {
                      if (isValid) {
                        if (type === EditingType.WORKER) editWorker(values);
                        else if (type === EditingType.RESOURCE)
                          editResource(values);
                        else return;
                      }
                      onCancel();
                    }}
                  />
                </ButtonContainer>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
