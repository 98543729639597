import {
  ILogyxOrderLine,
  LogyxOrderStatusId,
} from './ProductValidationTable/types';

export const areAnyLogyxLinesInStatusValidationInProgress = (
  logyxOrderLines?: ILogyxOrderLine[]
) => {
  if (!logyxOrderLines) {
    return false;
  }
  const result = logyxOrderLines.some(
    (line: ILogyxOrderLine) =>
      line.status_id === LogyxOrderStatusId.VALIDATION_IN_PROGRESS
  );
  return result;
};
