import apiClient from '../api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import i18n from 'providers/i18n/i18n';
import { Moment } from 'moment';
import {
  IAddSalesOrderLineData,
  ICreateSalesOrderData,
  IEditSalesOrderLine,
} from 'types/SalesOrders.types';
import { SortDirection } from 'components/Table/constants';
import { IEditSalesOrderServiceLine } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderProductsPage/EditSalesOrderServiceLineModal/constants';
import { IEditSalesOrderDetailsDTO } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderGeneralPage/constants';
import { IEditSalesOrderWorkingHoursLine } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderProductsPage/EditSalesOrderWorkingHoursLineModal/types';
import { IEditSalesOrderProductsDefinitiveDTO } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderProductsPage/constants';
import { IEditCustomerDetailsDTO } from 'components/Modal/EditCustomerDetailsModal/constants';
import { AppointmentTypeIds } from 'types/Appointment.types';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';

const BASE_URL = '/v1/sales-orders/';
const DEFAULT_ATTACHMENTS = '/v1/default-attachments';

export const createSalesOrder = async (
  createSalesOrderData: ICreateSalesOrderData
): Promise<any> => {
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, createSalesOrderData.queryParams);
  const { data } = await apiClient.post<any>(
    fullUrl,
    createSalesOrderData.payload
  );

  return data.data;
};

export const getSalesOrders = async (
  page?: number,
  perPage?: number,
  salesOrderStatuses?: string,
  salesOrderPaymentStatuses?: string,
  salesOrderSubStatuses?: string,
  searchBy?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    sales_order_statuses: salesOrderStatuses,
    sales_order_sub_statuses: salesOrderSubStatuses,
    sales_order_payment_statuses: salesOrderPaymentStatuses,
    search: searchBy,
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSalesInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSalesOrderBySalesOrderId = async (
  salesOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesOrderId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data.sales_order;
};

export const getSalesOrderQuotationsBySalesOrderId = async (
  salesOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesOrderId}/quotations`;
  const { data } = await apiClient.get<any>(url);

  return data.data.quotations;
};

export const newComment = async (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/comments`;
  const { data } = await apiClient.post<any>(url, {
    headline,
    description,
    is_internal: isInternal,
  });

  return data.data;
};

export const changeStatus = async (
  id: string,
  status_id: string,
  substatus_id?: string
): Promise<any> => {
  const url = BASE_URL + `${id}/status`;
  const changeStatusData = substatus_id
    ? {
        new_status_id: +status_id,
        new_sub_status_id: +substatus_id,
      }
    : {
        new_status_id: +status_id,
      };
  const { data } = await apiClient.patch<any>(url, changeStatusData);

  return data.data;
};

export const getSalesOrderComments = async (
  id: string,
  page: number,
  perPage: number,
  dateFrom: string,
  dateTo: string
) => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(dateFrom && { date_from: dateFrom }),
    ...(dateTo && { date_to: dateTo }),
  };

  const url = BASE_URL + `${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const getSalesOrderFiles = async (
  salesOrderId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${salesOrderId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadSalesOrderFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deleteSalesOrderFile = async (
  salesOrderId: string,
  salesOrderFileId: string
) => {
  const url = BASE_URL + `${salesOrderId}/files/${salesOrderFileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const exportSalesOrder = async (salesOrderId: string): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${salesOrderId}/export`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl, salesOrderId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const getSingleSalesOrderInvoices = async (
  salesOrderId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const url = BASE_URL + `${salesOrderId}/invoices`;

  if (page && perPage) {
    const queryParamsObj = {
      per_page: perPage,
      page: page,
    };
    const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
    const { data } = await apiClient.get<any>(fullUrl);
    return data.data;
  } else {
    const { data } = await apiClient.get<any>(url);
    return data.data;
  }
};

export const getSingleSalesOrderPurchaseOrders = async (
  salesOrderId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${salesOrderId}/purchase-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getSalesOrderAppointmentsForDateInterval = async (
  id: string,
  dateFrom: Moment,
  dateTo: Moment
) => {
  const queryParamsObj = {
    date_from: `${dateFrom.format('YYYY-MM-DD')} 00:00:00`,
    date_to: `${dateTo.format('YYYY-MM-DD')} 00:00:00`,
  };
  const url = BASE_URL + `${id}/appointments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get(fullUrl);
  return data.data;
};

export const getSalesOrderAppointments = async (
  id: string,
  page?: number,
  perPage?: number,
  selectedAppointmentTypeId?: AppointmentTypeIds
) => {
  const queryParamsObj = {
    filter_type: selectedAppointmentTypeId,
    ...(page && { page: page }),
    ...(perPage && { per_page: perPage }),
  };
  const url = BASE_URL + `${id}/appointments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get(fullUrl);
  return data.data;
};

export const sendSalesOrderEmail = async (
  salesOrderId: string,
  note: string,
  files: any
) => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${salesOrderId}/email-customer`,
    queryParamsObj
  );
  const formData = new FormData();
  const defaultAttachmentIds: string[] = [];

  if (note) formData.append('note', note);

  if (files && files.length) {
    files.forEach((file: any) => {
      if (file.id) {
        defaultAttachmentIds.push(file.id);
      } else {
        formData.append('files', file);
      }
    });
  }

  if (defaultAttachmentIds.length > 0) {
    formData.append(
      'default_attachment_ids',
      JSON.stringify(defaultAttachmentIds)
    );
  }

  const { data } = await apiClient.post<any>(fullUrl, formData);
  return data.data;
};

export const getSalesLinesAvailability = async (
  salesOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesOrderId}/lines-availability`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editSalesOrderFile = async (
  salesOrderId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${salesOrderId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const getSalesOrderHistory = async (
  salesOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesOrderId}/status-history`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const editSalesOrderLineCustomerDetails = async (
  salesOrderId: string,
  customerDetailsDTO: IEditCustomerDetailsDTO
) => {
  const url = BASE_URL + `${salesOrderId}/customer-snapshot`;
  const { data } = await apiClient.put(url, customerDetailsDTO);
  return data.data;
};

export const createSalesOrderLine = async (
  salesOrderId: string,
  addSalesOrderLineData: IAddSalesOrderLineData
) => {
  const url = BASE_URL + `${salesOrderId}/lines`;
  const { data } = await apiClient.post<any>(url, addSalesOrderLineData);
  return data.data;
};

export const editSalesOrderLine = async (
  salesOrderId: string,
  salesOrderLineId: string,
  editSalesOrderLineData: IEditSalesOrderLine
) => {
  const url = BASE_URL + `${salesOrderId}/lines/${salesOrderLineId}`;
  const { data } = await apiClient.patch<any>(url, editSalesOrderLineData);
  return data.data;
};

export const deleteSalesOrderLine = async (
  salesOrderId: string,
  salesOrderLineId: string
) => {
  const url = BASE_URL + `${salesOrderId}/lines/${salesOrderLineId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const editSalesOrderServiceLine = async (
  salesOrderId: string,
  salesOrderLineId: string,
  serviceLineDTO: IEditSalesOrderServiceLine
) => {
  const url = BASE_URL + `${salesOrderId}/lines/${salesOrderLineId}/service`;
  const { data } = await apiClient.patch(url, serviceLineDTO);
  return data.data;
};

export const editSalesOrderDetails = async (
  salesOrderId: string,
  editDetailsDTO: IEditSalesOrderDetailsDTO
) => {
  const url = BASE_URL + `${salesOrderId}/details`;
  const { data } = await apiClient.patch(url, editDetailsDTO);

  return data.data;
};

export const editSalesOrderWorkingHoursLine = async (
  salesOrderId: string,
  salesOrderLineId: string,
  workingHoursLineDTO: IEditSalesOrderWorkingHoursLine
) => {
  const url =
    BASE_URL + `${salesOrderId}/lines/${salesOrderLineId}/working_hours`;
  const { data } = await apiClient.patch(url, workingHoursLineDTO);
  return data.data;
};

export const editSalesOrderProductsDefinitive = async (
  salesOrderId: string,
  editProductsDefinitiveDTO: IEditSalesOrderProductsDefinitiveDTO
) => {
  const fullUrl =
    BASE_URL +
    `${salesOrderId}/products-definitive?all_products_definitive=${editProductsDefinitiveDTO.all_products_definitive}`;
  const { data } = await apiClient.patch(fullUrl);
  return data.data;
};

export const getSalesOrderInfoById = async (
  salesOrderId: string
): Promise<any> => {
  const url = `${BASE_URL}${salesOrderId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editDownPaymentAmount = async (
  id: string,
  downpaymentAmount: string
): Promise<any> => {
  const url = BASE_URL + `${id}/update-downpayment`;
  const { data } = await apiClient.patch<any>(url, {
    downpayment_amount: downpaymentAmount,
  });

  return data.data;
};

export const uploadSalesOrderSignature = async (
  salesOrderId: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${salesOrderId}/signatures`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const getSalesOrderSignatures = async (id: string) => {
  const url = BASE_URL + `${id}/signatures`;
  const { data } = await apiClient.get(url);

  return data.data;
};

export const updateSalesOrderSignature = async (
  salesOrderId: string,
  salesOrderSignatureId: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${salesOrderId}/signatures/${salesOrderSignatureId}`;
  const { data } = await apiClient.patch(url, fileFormData, { headers });

  return data.data;
};

export const getSalesOrderLatestEmailStatus = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/emails/latest-status`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSalesOrderAttachmentsPreview = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/attachments-preview`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getPendingInvoiceLines = async (id: number): Promise<any> => {
  const url = BASE_URL + `${id}/pending-invoice-lines`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getPendingAppointments = async (id: number): Promise<any> => {
  const url = BASE_URL + `${id}/pending-appointments`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getSalesOrderReportsCurrent = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getSalesOrderReportsHistorical = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/historical-reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getDefaultAttachmentsForProducts = async (
  productIds: string[]
): Promise<any> => {
  const queryParamsObj = {
    product_ids: productIds,
  };
  const fullUrl = getUrlWithQueryParams(
    `${DEFAULT_ATTACHMENTS}/products/attachments`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const reorderSalesOrderLines = async (
  salesOrderId: number,
  dto: IReorderLinesRequestDTO
) => {
  const url = BASE_URL + `${salesOrderId}/reorder-lines`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};
