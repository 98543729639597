import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ICountry } from 'store/Common/types';
import { IRootReducerState } from 'store/store';

export const useGetInitialCountry = (): ICountry => {
  const countries = useSelector(
    (state: IRootReducerState) => state.commonInfo.countries
  );
  const initialCountryMemo: ICountry = useMemo(() => {
    const initialCountry = countries.find(
      (country: ICountry) => country.cca2 === 'NL'
    );
    return initialCountry;
  }, [countries]);
  return initialCountryMemo;
};
