import { H3 } from 'assets/styled';
import Input from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { formatStockItemsTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IStockItem, IStockItemTableDTO } from 'types/StockItem.types';
import { STOCK_ITEMS_PER_PAGE, tableConfig } from './constants';
import { getStatusValue } from './helpers';
import {
  useGetStockItems,
  useGetStockItemsPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import {
  AdvancedFilterWrapper,
  Container,
  FlexAlignCenter,
  Row,
  RowRightPart,
  SelectInputWrapper,
  TitleContainer,
} from './StockItemsPage.styled';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import { useDebounce } from 'utils/hooks/useDebounce';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { MagnifyingGlass } from '@phosphor-icons/react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useState } from 'react';
import { useGetItemsAdvancedFilters } from './advancedFilters';

const StockItemsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState<string>('');

  const canCreateStock = useCan(Actions.CREATE_STOCK_ITEM);

  const { stockItemsPageFilters, statusOptions } =
    useGetStockItemsPageFilters();

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useManageAndSaveFilters(stockItemsPageFilters);

  const debouncedSearchBy = useDebounce(searchBy);

  const { isLoading, data } = useGetStockItems(
    page,
    STOCK_ITEMS_PER_PAGE,
    debouncedSearchBy,
    getStatusValue(selectedStatusOption.value),
    sortBy,
    sortDirection,
    undefined,
    advancedFilters
  );

  let tableData: IStockItemTableDTO[] = [];
  if (data) {
    tableData = formatStockItemsTableData(
      data.stock_items
        .map((stockItem: IStockItem[]) => {
          return stockItem;
        })
        .flat()
    );
  }

  const handleViewButtonClick = (rowData: IStockItemTableDTO) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetItemsAdvancedFilters(statusOptions);

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <Button
          width={'200rem'}
          onClick={() => navigate(RoutesConfig.NewStockItem.fullPath)}
          label={t('Create stock item')}
          primary
          disabled={!canCreateStock}
        />
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <RowRightPart>
          <SelectInputWrapper>
            <Select
              defaultValue={
                stockItemsPageFilters.selectedStatusOption
                  ? stockItemsPageFilters.selectedStatusOption
                  : statusOptions[0]
              }
              name="status"
              placeholder={t('Status')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedStatusOption(e)}
              options={statusOptions}
            />
          </SelectInputWrapper>
        </RowRightPart>
      </Row>
      <AdvancedFilterWrapper>
        <Button
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </AdvancedFilterWrapper>

      <Table
        isLoading={isLoading}
        page={page}
        perPage={STOCK_ITEMS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />
      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />
    </Container>
  );
};

export default StockItemsPage;
