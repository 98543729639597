import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonWrapper,
  CloseContainer,
  Container,
  Description,
  Title,
  TitleContainer,
} from './AddNewMollieCustomerModal.styled';
import { useConnectCustomerToMollieCustomer } from './hooks';

export interface IAddNewMollieCustomerModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: string;
}

export const AddNewMollieCustomerModal = ({
  setIsOpen,
  isOpen,
  onCancel,
  customerId,
}: IAddNewMollieCustomerModalProps) => {
  const { t } = useTranslation();
  const { mutate: connectToMollieCustomer, isError } =
    useConnectCustomerToMollieCustomer();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('New Mollie customer')}</Title>
      </TitleContainer>
      <TitleContainer>
        <Description>{`${t(
          'Provide mollie customer ID to connect customer to mollie customer or just submit to add new one'
        )}.`}</Description>
      </TitleContainer>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        initialValues={{
          mollie_customer_id: '',
        }}
      >
        {({ errors, touched, setFieldValue, handleBlur, values }) => {
          return (
            <Container>
              <Input
                errorMessage={
                  touched['mollie_customer_id']
                    ? errors['mollie_customer_id']
                    : ''
                }
                height={'41rem'}
                name="mollie_customer_id"
                placeholder={t('Mollie customer ID')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('mollie_customer_id', value);
                  handleBlur(e);
                }}
                wrapperStyles={{
                  width: '300rem',
                }}
              />
              <ButtonWrapper>
                <Button
                  onClick={onCancel}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  onClick={() => {
                    connectToMollieCustomer({
                      ...(values.mollie_customer_id && {
                        mollie_customer_id: values.mollie_customer_id,
                      }),
                      customer_id: customerId,
                    });
                    if (!isError) setIsOpen(false);
                  }}
                  primary
                  width="200rem"
                  label={t('Add')}
                />
              </ButtonWrapper>
            </Container>
          );
        }}
      </Formik>
    </Modal>
  );
};
