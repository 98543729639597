import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isDarkMode?: boolean;
  isMobileApp?: boolean;
}

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: radial-gradient(${COLORS.BACKGROUND_COLOR}, ${COLORS.PRIMARY});
  ${(props: StyledProps) =>
    props.isDarkMode
      ? css`
          background: radial-gradient(
            ${COLORS.BACKGROUND_COLOR},
            ${COLORS.PRIMARY}
          );
        `
      : css`
          background: radial-gradient(#a2bfd0, ${COLORS.PRIMARY});
        `}
`;

export const BlueprintLogoBigImg = styled.img`
  width: 100%;
  transform: scale(0.5);
  max-width: 100%;
  ${({ isMobileApp }: StyledProps) =>
    isMobileApp &&
    css`
      width: calc(100% - 40rem);
      max-width: 750rem;
    `}
`;
