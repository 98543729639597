import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Subtitle,
  Title,
} from './ManageCalendarPreferencesModal.styled';
import { Level } from 'components/Modal/type';
import { Modal } from 'components/Modal/Modal';
import { CalendarType } from '../CalendarIntegration';
import GoogleCalendarPreferencesForm from './GoogleCalendarPreferencesForm/GoogleCalendarPreferencesForm';
import OutlookCalendarPreferencesForm from './OutlookCalendarPreferencesForm/OutlookCalendarPreferencesForm';
import { capitalizeFirstLetter } from 'utils/stringUtils';

export interface IManageCalendarPreferencesModalProps {
  isOpen: CalendarType | null;
  setIsOpen: Dispatch<SetStateAction<CalendarType | null>>;
  calendarType: CalendarType | null;
  level?: Level;
}

const ManageCalendarPreferencesModal = ({
  isOpen,
  setIsOpen,
  calendarType,
  level = 'FIRST',
}: IManageCalendarPreferencesModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      level={level}
      isOpen={!!isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setIsOpen(null);
        }
      }}
      modalStyle={{ margin: 'auto', position: 'fixed' }}
      onBackDropClick={() => setIsOpen(null)}
    >
      <Container>
        <Title>
          {t(`Manage {CALENDAR_TYPE} calendar preferences`).replace(
            '{CALENDAR_TYPE}',
            capitalizeFirstLetter(calendarType!)
          )}
        </Title>
        <Subtitle>{t(`Create custom calendar`)}</Subtitle>
        {calendarType === CalendarType.GOOGLE ? (
          <GoogleCalendarPreferencesForm onSuccess={() => setIsOpen(null)} />
        ) : calendarType === CalendarType.OUTLOOK ? (
          <OutlookCalendarPreferencesForm onSuccess={() => setIsOpen(null)} />
        ) : null}
      </Container>
    </Modal>
  );
};

export default ManageCalendarPreferencesModal;
