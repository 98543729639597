import { COLORS, Label, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';
import { LogyxOrderStatus } from './types';

interface StyledProps {
  $status: string;
}

export const Table = styled.div``;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border: 1rem solid ${COLORS.GREY_500};
  border-radius: ${borderRadiusSm};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1rem solid ${COLORS.GREY_500};
  padding: 15rem;
  position: relative;

  &::before {
    content: '';
    width: 10rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${({ $status }: StyledProps) => {
      switch ($status) {
        case LogyxOrderStatus.VALID:
          return COLORS.GREEN;
        case LogyxOrderStatus.INVALID_LOGYX_CONFIG:
          return COLORS.RED_200;
        default:
          return 'transparent';
      }
    }};
  }

  &:last-of-type {
    border: none;

    &::before {
      border-bottom-left-radius: 9rem;
    }
  }

  &:first-of-type {
    &::before {
      border-top-left-radius: 9rem;
    }
  }
`;

export const BodyCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  padding: 0 5rem;
`;

export const BodyCellName = styled(BodyCell)<StyledProps>`
  justify-content: flex-start;
`;

export const BodyLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const More = styled(Label)`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1rem;
    text-underline-position: under;
  }
`;

export const Reconfigure = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  cursor: pointer;
`;

export const ReconfigureLogo = styled.img`
  width: 20rem;
  height: 20rem;
`;

export const ReconfigureText = styled.span`
  display: inline-block;
  color: ${COLORS.GREEN};
`;

export const Pending = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
`;

export const PendingText = styled.span`
  display: inline-block;
  color: ${COLORS.PRIMARY};
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  width: 100%;
`;
