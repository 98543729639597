import moment, { Moment } from 'moment';
import {
  Container,
  DateIntervalLabel,
  WeekNumber,
} from './CalendarDateIntervalHeader.styled';
import { useEffect, useState } from 'react';
import i18n from 'providers/i18n/i18n';
import { COLORS } from 'assets/styled';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';

interface ICalendarDateIntervalHeader {
  startDate: Moment;
  endDate: Moment;
  next: () => void;
  prev: () => void;
  selectedAppointmentViewOption?: string;
}

const CalendarDateIntervalHeader = ({
  startDate,
  endDate,
  next,
  prev,
  selectedAppointmentViewOption,
}: ICalendarDateIntervalHeader) => {
  const [dateIntervalString, setDateIntervalString] = useState<string>('');
  const weekNumber = moment(startDate).week();

  useEffect(() => {
    const currentLocale = i18n.resolvedLanguage || 'en';

    let dateString: string;
    if (selectedAppointmentViewOption === 'timeGridDay') {
      dateString = moment(startDate)
        .locale(currentLocale)
        .format('DD MMMM YYYY');
    } else {
      dateString =
        moment(startDate).locale(currentLocale).format('DD MMMM YYYY') +
        ' - ' +
        moment(endDate)
          .locale(currentLocale)
          .subtract(1, 'days')
          .format('DD MMMM YYYY');
    }
    setDateIntervalString(dateString);
  }, [
    i18n.resolvedLanguage,
    startDate,
    endDate,
    selectedAppointmentViewOption,
  ]);

  return (
    <Container>
      <Icon svg={CaretLeft} size={20} color={COLORS.BLACK} onClick={prev} />
      <DateIntervalLabel>{dateIntervalString}</DateIntervalLabel>
      {selectedAppointmentViewOption === 'timeGridWeek' && (
        <WeekNumber>({weekNumber})</WeekNumber>
      )}
      <Icon svg={CaretRight} size={20} color={COLORS.BLACK} onClick={next} />
    </Container>
  );
};

export default CalendarDateIntervalHeader;
