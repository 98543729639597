import { COLORS } from 'assets/styled';
import styled from 'styled-components';
export const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #de7316;
  width: 100%;
  height: 1rem;
`;

export const EnvironmentLabel = styled.label`
  font-size: 12rem;
  color: ${COLORS.WHITE};
  user-select: none;
`;

export const EnvironmentLabelWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0rem;
  width: min-content;
  height: min-content;
  background-color: #de7316;
  padding: 1rem 6rem;
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
`;
