import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  Row,
  Title,
  TitleContainer,
} from './EditSalesOrderServiceLine.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { useEditSalesOrderServiceLine } from './hooks';
import { useParams } from 'react-router-dom';
import { validateNumericField } from 'utils/validation';

interface IEditOrderDetailsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  salesOrderLine: any;
}
export const EditSalesOrderServiceLineModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  salesOrderLine,
}: IEditOrderDetailsModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    mutate: editServiceLine,
    isLoading,
    isSuccess,
  } = useEditSalesOrderServiceLine(id!, salesOrderLine.id);

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit service fee')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            purpose: salesOrderLine.service.purpose,
            quantity: salesOrderLine.quantity,
            amount: salesOrderLine.service.amount,
            description: salesOrderLine.service.description,
          }}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Purpose').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="purpose-field"
                      errorMessage={
                        errors['purpose'] ? (errors['purpose'] as string) : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'purpose');
                      }}
                      height={'43rem'}
                      name="purpose"
                      wrapperStyles={{
                        width: '300rem',
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>

                <HorizontalLine />

                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Quantity').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="quantity-field"
                      validate={(value: string) =>
                        validateNumericField(value, 'quantity')
                      }
                      errorMessage={
                        errors['quantity'] ? (errors['quantity'] as string) : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'quantity');
                      }}
                      height={'43rem'}
                      name="quantity"
                      wrapperStyles={{
                        width: '300rem',
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>

                <HorizontalLine />

                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Amount').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="amount-field"
                      validate={(value: string) =>
                        validateNumericField(value, 'amount')
                      }
                      errorMessage={
                        errors['amount'] ? (errors['amount'] as string) : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'amount');
                      }}
                      height={'43rem'}
                      name="amount"
                      wrapperStyles={{
                        width: '300rem',
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>

                <HorizontalLine />

                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Description').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="description-field"
                      isTextArea
                      height={'120rem'}
                      errorMessage={
                        errors['description']
                          ? (errors['description'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'description');
                      }}
                      name="description"
                      wrapperStyles={{
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={
                      !values.amount ||
                      !values.description ||
                      !values.purpose ||
                      !values.quantity ||
                      !isValid ||
                      isLoading
                    }
                    onClick={() => {
                      if (isValid) {
                        editServiceLine(values);
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
