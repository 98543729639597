import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { H5, marginMd, marginSm, paddingXs } from 'assets/styled';

interface StyledProps {
  isSelected: boolean;
  isAlternativeShippingData: boolean;
}

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AppointmentFilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AppointmentFilterTypeLabel = styled.label`
  cursor: pointer;
  margin-top: ${marginSm};
  margin-bottom: -10rem;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const AppointmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.PRIMARY};
  margin-right: 10rem;
`;

export const TimeWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 17rem;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const GroupText = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateLabel = styled.label`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  display: block;
  font-weight: 300;
`;

export const LinkText = styled.a`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.PRIMARY};
  cursor: default;
  ${(props: StyledProps) =>
    !props.isAlternativeShippingData &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `};
`;

export const UnderlineText = styled(Text)`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-decoration: underline;
  margin-left: ${marginSm};
  margin-top: 0;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${paddingXs} 0;
  border-bottom: 1px solid ${COLORS.GREY_200};
  gap: 5rem;
`;

export const DoubleColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20;
`;

export const InsideColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: calc(50% - 10rem);
`;

export const ColumnTitle = styled.span`
  display: inline-block;
  color: ${COLORS.BLACK};
  font-size: 14rem;
`;

export const ArrivalTime = styled(H5)`
  text-transform: uppercase;
`;

export const RowColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-wrap: wrap;
  color: ${COLORS.BLACK};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;

export const BottomWrapper = styled.div`
  padding-top: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ExportImg = styled.img`
  cursor: pointer;
  width: 25rem;
  height: 25rem;
  margin-left: ${marginSm};
  margin-top: 5rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProductsButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 700rem;
`;

export const TimerLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Scrollable = styled.div`
  max-height: calc(
    80vh - 200rem
  ); // 200rem === ApplyWrapper (Footer) + TitleContainer (Header)
  overflow-y: scroll;
`;

export const LeftAlign = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20rem;
`;
