import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  paddingLg,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${paddingLg};

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-between;
  margin-bottom: ${marginLg};
  gap: 20rem;
`;

export const H3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24rem;
  padding-right: 30rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20rem;
`;

export const CardsWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1075rem;
  gap: 50rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Separator50 = styled.div`
  height: ${marginLg};
`;

export const DescriptionCardWrapper = styled.div`
  width: 625rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  align-self: start;
`;

export const DescriptionTitle = styled.label`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginMd};
`;

export const DescriptionValueLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  word-break: break-word;
`;

export const StockItemIsActiveLabel = styled.label`
  padding-left: ${marginMd};
  color: ${COLORS.PRIMARY};
  font-weight: 300;
  font-size: 18rem;
  margin-right: 3rem;
`;
