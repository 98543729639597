import moment from 'moment';

export const validateDateTimeInterval = (
  dateFromString: string,
  timeFromString: string,
  dateToString: string,
  timeToString: string,
  setFieldError: any,
  t: (string: string) => string,
  fieldName?: string
) => {
  const dateTimeFrom = moment(
    `${dateFromString} ${timeFromString}`,
    'YYYY-MM-DD HH:mm:ss'
  );
  const dateTimeTo = moment(
    `${dateToString} ${timeToString}`,
    'YYYY-MM-DD HH:mm:ss'
  );

  if (dateTimeFrom.isSameOrBefore(dateTimeTo)) {
    setFieldError(fieldName, undefined);
  } else {
    setFieldError(fieldName, t('Invalid time span'));
  }
};

export const validateTimeOnlyInterval = (
  timeFromString: string,
  timeToString: string,
  setFieldError: any,
  t: (string: string) => string,
  fieldName?: string
) => {
  const timeFrom = moment(timeFromString, 'HH:mm:ss');
  const timeTo = moment(timeToString, 'HH:mm:ss');

  if (timeFrom.isSameOrBefore(timeTo)) {
    setFieldError(fieldName, undefined);
  } else {
    setFieldError(fieldName, t('Invalid time span'));
  }
};

export const validateTimeFields = (values: any, setFieldError: any, t: any) => {
  validateDateTimeInterval(
    moment(values?.startDate).format('YYYY-MM-DD'),
    values?.time_from,
    moment(values?.endDate).format('YYYY-MM-DD'),
    values?.time_to,
    setFieldError,
    t,
    'time_to'
  );
};
