import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

export const validateDateTimeInterval = (
  dateFromString: string,
  timeFromString: string,
  dateToString: string,
  timeToString: string,
  setTimeIntervalErrorMessage: Dispatch<SetStateAction<string>>,
  t: (string: string) => string
) => {
  const dateTimeFrom = moment(
    `${dateFromString} ${timeFromString}`,
    'YYYY-MM-DD HH:mm:ss'
  );
  const dateTimeTo = moment(
    `${dateToString} ${timeToString}`,
    'YYYY-MM-DD HH:mm:ss'
  );

  if (dateTimeFrom.isSameOrBefore(dateTimeTo)) {
    setTimeIntervalErrorMessage('');
  } else {
    setTimeIntervalErrorMessage(t('Invalid time span'));
  }
};

export const validateTimeOnlyInterval = (
  timeFromString: string,
  timeToString: string,
  setTimeIntervalErrorMessage: Dispatch<SetStateAction<string>>,
  t: (string: string) => string
) => {
  const timeFrom = moment(timeFromString, 'HH:mm:ss');
  const timeTo = moment(timeToString, 'HH:mm:ss');

  if (timeFrom.isSameOrBefore(timeTo)) {
    setTimeIntervalErrorMessage('');
  } else {
    setTimeIntervalErrorMessage(t('Invalid time span'));
  }
};

export const validateTimeFields = (
  values: any,
  setTimeIntervalErrorMessage: Dispatch<SetStateAction<string>>,
  t: any
) => {
  validateDateTimeInterval(
    moment(values?.date_from).format('YYYY-MM-DD'),
    values?.time_from,
    moment(values?.date_to).format('YYYY-MM-DD'),
    values?.time_to,
    setTimeIntervalErrorMessage,
    t
  );
};
