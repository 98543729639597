import Button from 'components/Button/Button';
import {
  ActionCell,
  ActionHeaderCell,
  Body,
  BodyCell,
  BodyLabel,
  ButtonContainer,
  Footer,
  Header,
  HeaderCell,
  HeaderLabel,
  Margin,
  Row,
  Table,
} from './TableAlt.styled';
import { useTranslation } from 'react-i18next';
import Pagination from 'components/Table/Pagination/Pagination';
import { excludedTableDataKeys } from 'components/Table/constants';
import { translatableColumnFields } from './constants';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import { Info } from '@phosphor-icons/react';

interface ITableAlt {
  page: number;
  perPage: number;
  total: number;
  tableData: any[];
  tableConfig: ITableAltConfig;
  hasExtraAction: boolean;
  loadPage: (page: number) => void;
  onButtonClick?: (params: any) => void;
  selectable?: boolean;
  selectedRows?: any[];
  setSelectedRows?: (ids: string[]) => void;
  onApply?: () => void;
  onCancel?: () => void;
  animatedRows?: boolean;
  removedRows?: string[];
}

export interface ITableAltConfig {
  rowConfigs: ITableAltRowConfig[];
}

export interface ITableAltRowConfig {
  header: string;
  flexValue: number;
  isBlue?: boolean;
}

const TableAlt = ({
  page,
  perPage,
  total,
  tableConfig,
  tableData,
  hasExtraAction,
  loadPage,
  onButtonClick,
  selectable,
  selectedRows,
  setSelectedRows,
  onApply,
  onCancel,
  animatedRows,
  removedRows,
}: ITableAlt) => {
  const { t } = useTranslation();
  return (
    <Table>
      <Header>
        {tableConfig?.rowConfigs.map((rowData, index) => (
          <HeaderCell key={index} $flexValue={rowData.flexValue}>
            <HeaderLabel>{t(rowData.header)}</HeaderLabel>
          </HeaderCell>
        ))}
        {hasExtraAction && (
          <ActionHeaderCell
            $isRed={selectable && selectedRows?.length === total}
            onClick={() => {
              if (selectable) {
                selectedRows?.length === total
                  ? setSelectedRows?.([])
                  : setSelectedRows?.(
                      tableData?.map((rowData: any) => rowData?.entityId)
                    );
              }
            }}
          >
            {selectable &&
              tableData?.length !== 0 &&
              (selectedRows?.length === total ? t('Clear') : t('Select all'))}
          </ActionHeaderCell>
        )}
      </Header>
      <Body>
        {tableData?.map((rowData, rowIndex) => {
          const isSelected = selectedRows?.includes(rowData?.entityId);
          return (
            <Row
              $selected={isSelected}
              key={rowIndex}
              className={
                animatedRows && removedRows?.includes(rowData.entityId)
                  ? 'exit-row'
                  : ''
              }
            >
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell key={cellIndex}>
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex].isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                    {cellData === 'customer' && rowData[cellData] && (
                      <Tooltip content={rowData['customerNumber']}>
                        <Icon
                          svg={Info}
                          size={21}
                          color={COLORS.PRIMARY}
                          marginLeft={marginXs}
                        />
                      </Tooltip>
                    )}
                  </BodyCell>
                ))}
              {hasExtraAction && (
                <ActionCell>
                  <Button
                    onClick={() => {
                      if (selectable && selectedRows) {
                        isSelected
                          ? setSelectedRows?.(
                              selectedRows.filter(
                                (id: any) => id !== rowData.entityId
                              )
                            )
                          : setSelectedRows?.([
                              ...selectedRows,
                              rowData?.entityId,
                            ]);
                      } else onButtonClick && onButtonClick(rowData);
                    }}
                    tableAlt
                    width="100rem"
                    label={
                      selectable
                        ? isSelected
                          ? t('Unselect')
                          : t('Select')
                        : t('Open')
                    }
                  />
                </ActionCell>
              )}
            </Row>
          );
        })}
      </Body>
      <Footer>
        {total > perPage && (
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            loadPage={loadPage}
          />
        )}
      </Footer>
      {onApply && onCancel && (
        <ButtonContainer>
          <Button
            width={'200rem'}
            secondary
            onClick={() => {
              if (selectable) setSelectedRows?.([]);
              onCancel();
            }}
            label={t('Cancel')}
          />
          <Margin>
            <Button
              disabled={selectable ? selectedRows?.length === 0 : undefined}
              onClick={onApply}
              width={'200rem'}
              primary
              label={t('Apply')}
            />
          </Margin>
        </ButtonContainer>
      )}
    </Table>
  );
};

export default TableAlt;
