import React, { useEffect } from 'react';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import jwt_decode from 'jwt-decode';
import { IToken } from 'types/Token.types';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useFetchUserInfo } from './hooks';
import { setUserInfo } from 'store/User/actions/user';

interface ITokenChangedInDifferentTabListenerProps {
  children: React.ReactNode;
}

const TokenChangedInDifferentTabListener = ({
  children,
}: ITokenChangedInDifferentTabListenerProps) => {
  const dispatch = useDispatch();
  const userId = useSelector((state: IRootReducerState) => state.userInfo.id);
  const { data, mutate: fetchUserInfo } = useFetchUserInfo();

  useEffect(() => {
    if (data) {
      dispatch(setUserInfo(data.user));
    }
  }, [data, dispatch]);

  // This approach only detects changes made to the local storage from other tabs or windows in the same browser. (Except if the token was manually changed in this tab)
  // It won't detect changes made by the same tab or window that the React app is running in.
  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === LOCAL_STORAGE_ACCESS_TOKEN) {
        const oldToken = event.oldValue;
        const newToken = event.newValue;

        if (
          newToken &&
          oldToken &&
          isJwtToken(newToken) &&
          isJwtToken(oldToken)
        ) {
          try {
            // User was previously logged in
            const newDecodedToken: IToken = jwt_decode(newToken);
            const oldDecodedToken: IToken = jwt_decode(oldToken);

            // Check if token is of the same person
            if (
              typeof newDecodedToken?.id === 'number' &&
              typeof oldDecodedToken?.id === 'number' &&
              newDecodedToken.id === oldDecodedToken.id
            ) {
              // Token of same user
              if (newDecodedToken.id !== userId) {
                fetchUserInfo();
              }
            } else {
              // Token of different user detected, refetch user info
              fetchUserInfo();
            }
          } catch (e) {
            console.log('jwt_decode exception: ', e);
          }
        } else {
          if (newToken) {
            // User just logged in
            // If token is present, but isAuthenticated is false, fetch user info using that token
            !userId && fetchUserInfo();
          } else {
            // User just logged out
            // Page reload will set userId value to null, because new requests after reload will get 401 responses
            window.location.reload();
          }
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return <>{children}</>;
};

// Basic validation
export const isJwtToken = (token: string) => {
  const tokenParts = token.split('.');
  return tokenParts.length === 3;
};

export default TokenChangedInDifferentTabListener;
