import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
  FormContainer,
  SelectWrapper,
  InputWrapper,
} from './MarkAsConnectedModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { IMarkAsConnectedProps, useMarkAsConnected } from './hooks';
import Input from 'components/Input/Input';
import { useGetTwinfieldTransactionStatuses } from 'utils/hooks/useGetTwinfieldTransactionStatuses';
import { Select } from 'components/Select/Select';
import { useDispatch } from 'react-redux';
import { setMarkAsConnectedInvoiceId } from 'store/Common/actions/common';

interface IMarkAsConnectedModalProps {
  invoiceId: number | string | null;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const MarkAsConnectedModal = ({
  invoiceId,
  isOpen,
  setIsOpen,
}: IMarkAsConnectedModalProps) => {
  const level = 'FIRST';
  const PWID = 'mark-sales-invoice-connected-modal';
  const { t } = useTranslation();

  const DESCRIPTION_LABEL = t(
    'Sales Invoice already exists on Twinfield. Mark it as connected?'
  );
  const TITLE_LABEL = t('Twinfield sales transaction already exists');
  const TRANSACTION_NUMBER_PLACEHOLDER = t('Sales transaction number');
  const TRANSACTION_STATUS_PLACEHOLDER = t('Sales transaction status');
  const MARK_AS_CONNECTED_LABEL = t('Mark as connected');
  const CANCEL_LABEL = t('Cancel');

  const [transactionNumber, setTransactionNumber] = useState<string>('');
  const [transactionNumberError, setTransactionNumberError] =
    useState<string>('');

  const transactionStatuses = useGetTwinfieldTransactionStatuses();
  const [transactionStatus, setTransactionStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: markAsConnected } = useMarkAsConnected(invoiceId);

  const handleValidateTransactionNumber = (value: string) => {
    setTransactionNumberError(value ? '' : t('Transaction number is required'));
  };

  const handleDisableButton = () => {
    return (
      !!transactionNumberError ||
      !transactionNumber ||
      !transactionStatus ||
      isLoading
    );
  };

  const dispatch = useDispatch();
  const handleButtonClick = () => {
    const dto: IMarkAsConnectedProps = {
      twinfieldTransactionNumber: transactionNumber,
      twinfieldTransactionStatus: transactionStatus,
    };
    setIsLoading(true);
    markAsConnected(dto, {
      onSuccess: () => {
        setTransactionNumber('');
        setTransactionNumberError('');
        setTransactionStatus(0);
        setIsOpen(false);
        setIsLoading(false);
        dispatch(setMarkAsConnectedInvoiceId(null));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed' }}
      level={level}
    >
      <TopContainer data-testid={PWID}>
        <CloseContainer>
          <Close onClick={() => setIsOpen(false)} />
        </CloseContainer>
        <TitleContainer>
          <Title>{TITLE_LABEL}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>{DESCRIPTION_LABEL}</Description>
        </TitleContainer>
      </TopContainer>
      <FormContainer>
        <InputWrapper>
          <Input
            placeholder={TRANSACTION_NUMBER_PLACEHOLDER}
            error={!!transactionNumberError}
            errorMessage={transactionNumberError}
            height="41rem"
            type="string"
            value={transactionNumber}
            onChange={(e) => {
              handleValidateTransactionNumber(e.target.value);
              setTransactionNumber(e.target.value);
            }}
          />
        </InputWrapper>
        <SelectWrapper>
          <Select
            name="status"
            placeholder={TRANSACTION_STATUS_PLACEHOLDER}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: any) => {
              setTransactionStatus(e.value);
            }}
            options={transactionStatuses}
          />
        </SelectWrapper>
      </FormContainer>
      <ButtonContainer>
        <Margin>
          <Button
            data-testid={`${PWID}-cancel-button`}
            onClick={() => {
              setIsOpen(false);
            }}
            secondary
            width="200rem"
            label={CANCEL_LABEL}
          />
        </Margin>
        <Button
          data-testid={`${PWID}-mark-as-connected-button`}
          disabled={handleDisableButton()}
          primary
          width="200rem"
          label={MARK_AS_CONNECTED_LABEL}
          onClick={handleButtonClick}
        />
      </ButtonContainer>
    </Modal>
  );
};
