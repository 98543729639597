import { H4 } from 'assets/styled';
import { Field, Formik } from 'formik';
import {
  ColumnContainer,
  ColumnContainerSelect,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
} from '../EditCustomerDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useTranslation } from 'react-i18next';
import { IEditCustomerShipToDetails } from '../constants';
import { editCustomerDetailsShipToTabSchema } from '../validation';

interface IShipToTabProps {
  shipToDetails: IEditCustomerShipToDetails;
  setShipToDetails: (shipToDetails: IEditCustomerShipToDetails) => void;
  setIsValid: (isValid: boolean) => void;
}
export const ShipToTab = ({
  shipToDetails,
  setShipToDetails,
  setIsValid,
}: IShipToTabProps) => {
  const { t } = useTranslation();
  const countryOptions: Option[] = useGetCountryOptions();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          //
        }}
        initialValues={shipToDetails}
        validationSchema={editCustomerDetailsShipToTabSchema}
      >
        {({ values, setFieldValue, handleBlur, isValid, errors }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setShipToDetails(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('First name').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="name-field"
                    errorMessage={errors['name'] ? errors['name'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'name');
                    }}
                    height={'43rem'}
                    name="name"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Last name').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="last-name-field"
                    errorMessage={
                      errors['last_name'] ? errors['last_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'last_name');
                    }}
                    height={'43rem'}
                    name="last_name"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('E-MAIL')}</H4>
                  </Heading>
                  <FormikInput
                    pwId="email-field"
                    errorMessage={errors['email'] ? errors['email'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'email');
                    }}
                    height={'43rem'}
                    name="email"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>

                <ColumnContainerSelect>
                  <Heading>
                    <H4>{t('Country code').toUpperCase()}</H4>
                  </Heading>
                  <Field
                    pwId="country-field"
                    errorMessage={errors['country'] ? errors['country'] : ''}
                    disabled={false}
                    height={'41rem'}
                    name="country"
                    width="300rem"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setShipToDetails({ ...values, country: option.value });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>
              </RowSpaceBetween>

              <HorizontalLine />
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Zip code').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="zip-code-field"
                    errorMessage={errors['zip_code'] ? errors['zip_code'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'zip_code');
                    }}
                    height={'43rem'}
                    name="zip_code"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>

                <ColumnContainer>
                  <Heading>
                    <H4>{t('House number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="house-number-field"
                    errorMessage={
                      errors['house_number'] ? errors['house_number'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'house_number');
                    }}
                    height={'43rem'}
                    name="house_number"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('City').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="city-field"
                    errorMessage={errors['city'] ? errors['city'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'city');
                    }}
                    height={'43rem'}
                    name="city"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Street').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="street-field"
                    errorMessage={errors['street'] ? errors['street'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'street');
                    }}
                    height={'43rem'}
                    name="street"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Company name').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="company-name-field"
                    errorMessage={
                      errors['company_name'] ? errors['company_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'company_name');
                    }}
                    height={'43rem'}
                    name="company_name"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
