import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginXs, paddingXs, paddingSm, marginMd } from 'assets/styled';

interface StyledProps {
  isLast: boolean;
}

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const WorkerContainer = styled.div`
  border-bottom: ${(props: StyledProps) =>
    !props.isLast && `1rem solid ${COLORS.GREY_300}`};
  padding: ${paddingXs} ${paddingSm};
  margin-top: ${marginXs};
`;

export const WorkerName = styled.div`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const WorkerDescription = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;
