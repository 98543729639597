import { H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddCustomerComment } from '../hooks';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  Heading,
  Title,
  TitleContainer,
} from './AddNewComment.styled';
import { addCommentValidationSchema } from './validation';

export interface IAddNewCommentModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customerId: string;
}

export const AddNewCommentModal = ({
  setIsOpen,
  isOpen,
  onCancel,
  customerId,
}: IAddNewCommentModalProps) => {
  const { t } = useTranslation();
  const { mutate } = useAddCustomerComment(customerId);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="SECOND"
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Add comment')}</Title>
      </TitleContainer>
      <div>
        <Formik
          validationSchema={addCommentValidationSchema}
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            headline: '',
            description: '',
            is_internal: true,
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            handleBlur,
            isValid,
          }) => {
            return (
              <Container>
                <Heading>
                  <H4>{t('Headline')}</H4>
                </Heading>
                <Input
                  errorMessage={touched['headline'] ? errors['headline'] : ''}
                  height={'41rem'}
                  name="headline"
                  placeholder={t('The purpose of your comment')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('headline', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '300rem',
                  }}
                />

                <Heading>
                  <H4>{t('Description')}</H4>
                </Heading>
                <Input
                  isTextArea
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  height={'150rem'}
                  name="description"
                  placeholder={t(
                    'Detailed account of the reason for the comment'
                  )}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                  }}
                />
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!values.headline || !values.description}
                    onClick={() => {
                      if (isValid) setIsConfirmModalOpen(true);
                    }}
                    primary
                    width="200rem"
                    label={t('Add')}
                  />
                </ApplyWrapper>
                <ConfirmModal
                  level="THIRD"
                  title={t('New Comment')}
                  description={`${t(
                    'Are you sure you want to add a new Comment'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => {
                    mutate(values);
                    onCancel();

                    setIsConfirmModalOpen(false);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
