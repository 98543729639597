import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editPurchaseOrderLinePrice } from 'services/PurchaseOrder/PurchaseOrderService';

export interface IEditPurchaseOrderLinePrice {
  product_purchase_price: number;
  expected_total_amount: number;
}
export const useEditPurchaseOrderLinePrice = (
  purchaseOrderId: string,
  lineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (editLineDTO: IEditPurchaseOrderLinePrice) =>
      editPurchaseOrderLinePrice(purchaseOrderId, lineId, editLineDTO),
    {
      onSuccess: () => {
        toast.success(t('Purchase order line updated'), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_PRICE,
          className: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_PRICE,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER,
          purchaseOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_PRICE,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_PRICE,
    }
  );
};
