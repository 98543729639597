import Button from 'components/Button/Button';
import {
  Container,
  ContentContainer,
  Line,
  Label,
  Title,
  Column,
  EmailConfigurationWrapper,
  LabelSecondary,
  DefaultAttachmentNameLink,
  DefaultAttachmentLabelsWrapper,
  LoaderWrapper,
  DefaultAttachmentNameLinkDeleteIconWrapper,
  ButtonWrapper,
  ButtonContainer,
} from './SettingsPage.styled';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { useEffect, useState } from 'react';
import {
  DefaultAttachmentTypeId,
  useDeleteDefaultAttachmentMailFile,
  useGetDefaultAttachmentMailFiles,
  useGetDealerPanelWebookURL,
  useUploadDefaultAttachmentMailFile,
} from './hooks';
import { Base64 } from 'js-base64';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { IDefaultAttachment } from 'types/File.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';

const LOGYX_DEALER_PANEL_URL = config.logyx.logyxDealerPanelUrl;

const SettingsPage = () => {
  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const pwaDeferredInstallPrompt = useSelector(
    (state: IRootReducerState) => state.pwaInfo.deferredPrompt
  );
  const isMobileApp: boolean = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const {
    data: dealerPanelWebhookUrlData,
    mutate: getDealerPanelWebhookURL,
    isLoading: isGetDealerPanelWebhookURLLoading,
    isSuccess: isGetDealerPanelWebhookURLSuccess,
  } = useGetDealerPanelWebookURL();

  const handleOpenLogyxDealerPanel = () => {
    if (!LOGYX_DEALER_PANEL_URL) {
      alert('Logyx dealer panel app url missing');
      return;
    }
    getDealerPanelWebhookURL();
  };

  useEffect(() => {
    if (isGetDealerPanelWebhookURLSuccess) {
      const intentUUID = dealerPanelWebhookUrlData.intent_uuid;
      const fullDealerPanelWebhookUrl = dealerPanelWebhookUrlData.webhook_url;

      const metadata = { companyId: companyId };

      const base64IntentUUID = Base64.encodeURL(intentUUID);
      const base64WebhookURL = Base64.encodeURL(fullDealerPanelWebhookUrl);
      const base64Metadata = Base64.encode(JSON.stringify(metadata));

      const queryParamsObj = {
        intent: base64IntentUUID,
        webhookUrl: base64WebhookURL,
        metadata: base64Metadata,
        ...(darkMode && { isDarkMode: true }),
        lang: i18n.resolvedLanguage || FALLBACK_I18N_LANG,
        isDealerPanel: true,
      };
      const fullUrl = getUrlWithQueryParams(
        `${LOGYX_DEALER_PANEL_URL}/`,
        queryParamsObj
      );
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.OPEN_DEALER_PANEL,
          payload: fullUrl,
        };
        postMobileMessage(mobileMessage);
      } else {
        window.open(fullUrl, 'LOGYX_DEALER_PANEL');
      }
    }
  }, [isGetDealerPanelWebhookURLSuccess]);

  const {
    mutate: uploadDefaultAttachmentFile,
    isLoading: isUploadDefaultAttachmentFileLoading,
    isSuccess: isUploadDefaultAttachmentFileSuccess,
  } = useUploadDefaultAttachmentMailFile();

  const { mutate: deleteDefaultAttachmentMailFile } =
    useDeleteDefaultAttachmentMailFile();

  const {
    data: defaultAttachmentFiles,
    isLoading: isGetDefaultAttachmentFilesLoading,
  } = useGetDefaultAttachmentMailFiles();

  useEffect(() => {
    if (isUploadDefaultAttachmentFileSuccess) {
      setIsUploadFileModalOpen(false);
    }
  }, [isUploadDefaultAttachmentFileSuccess]);

  const [selectedDefaultAttachmentType, setSelectedDefaultAttachmentType] =
    useState<DefaultAttachmentTypeId | null>(null);

  const handleUploadFile = async (files: any) => {
    if (selectedDefaultAttachmentType) {
      uploadDefaultAttachmentFile({
        defaultAttachmentTypeId: selectedDefaultAttachmentType,
        files,
      });
    }
  };

  return (
    <Container>
      <Title>{t('Settings')}</Title>
      <ContentContainer>
        <ButtonContainer>
          <ButtonWrapper>
            <Label>Logyx</Label>
            <Button
              disabled={isGetDealerPanelWebhookURLLoading}
              label={t('Configure Logyx')}
              green
              width="200rem"
              onClick={handleOpenLogyxDealerPanel}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>{t('User Roles')}</Label>
            <Button
              label={t('Change Roles')}
              primary
              width="200rem"
              onClick={() => navigate(RoutesConfig.UserRoles.path)}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Label>{t('Email templates')}</Label>
            <Button
              label={t('Change templates')}
              primary
              width="200rem"
              onClick={() => navigate(RoutesConfig.EmailTemplates.path)}
            />
          </ButtonWrapper>
          {pwaDeferredInstallPrompt && (
            <ButtonWrapper>
              <Label>{t('Add Blueprint app icon to home screen')}</Label>
              <Button
                width="200rem"
                primary
                label={t('Add icon')}
                onClick={() => pwaDeferredInstallPrompt()}
              />
            </ButtonWrapper>
          )}
        </ButtonContainer>
        <Line />
        <Label>{t('Email configuration')}</Label>
        <EmailConfigurationWrapper>
          <Column>
            <LabelSecondary>
              {t('Default quotation email attachment') + ':'}
            </LabelSecondary>
            <DefaultAttachmentLabelsWrapper>
              {isGetDefaultAttachmentFilesLoading ? (
                <LoaderWrapper>
                  <Spinner />
                </LoaderWrapper>
              ) : (
                defaultAttachmentFiles &&
                defaultAttachmentFiles.quotation_files.map(
                  (defaultAttachment: IDefaultAttachment, index: number) => (
                    <DefaultAttachmentNameLinkDeleteIconWrapper key={index}>
                      <DefaultAttachmentNameLink
                        href={defaultAttachment.preview_url}
                        target={'_blank'}
                      >
                        {defaultAttachment.file_name}
                      </DefaultAttachmentNameLink>
                      <Icon
                        data-testid={`delete-quotation-attachment-${index}`}
                        svg={Trash}
                        color={COLORS.RED_200}
                        size={20}
                        weight="regular"
                        onClick={() => {
                          deleteDefaultAttachmentMailFile(defaultAttachment.id);
                        }}
                      />
                    </DefaultAttachmentNameLinkDeleteIconWrapper>
                  )
                )
              )}
            </DefaultAttachmentLabelsWrapper>
            <Button
              data-testid="add-default-quotation-attachment-button"
              disabled={isUploadDefaultAttachmentFileLoading}
              label={t('Add attachment')}
              secondary
              width={'200rem'}
              onClick={() => {
                setIsUploadFileModalOpen(true);
                setSelectedDefaultAttachmentType(
                  DefaultAttachmentTypeId.QUOTATION
                );
              }}
            />
          </Column>
          <Column>
            <LabelSecondary>
              {t('Default sales order email attachment') + ':'}
            </LabelSecondary>
            <DefaultAttachmentLabelsWrapper>
              {isGetDefaultAttachmentFilesLoading ? (
                <LoaderWrapper>
                  <Spinner />
                </LoaderWrapper>
              ) : (
                defaultAttachmentFiles &&
                defaultAttachmentFiles.sales_order_files.map(
                  (defaultAttachment: IDefaultAttachment, index: number) => (
                    <DefaultAttachmentNameLinkDeleteIconWrapper key={index}>
                      <DefaultAttachmentNameLink
                        href={defaultAttachment.preview_url}
                        target={'_blank'}
                      >
                        {defaultAttachment.file_name}
                      </DefaultAttachmentNameLink>
                      <Icon
                        data-testid={`delete-sales-order-attachment-${index}`}
                        svg={Trash}
                        color={COLORS.RED_200}
                        size={20}
                        weight="regular"
                        onClick={() => {
                          deleteDefaultAttachmentMailFile(defaultAttachment.id);
                        }}
                      />
                    </DefaultAttachmentNameLinkDeleteIconWrapper>
                  )
                )
              )}
            </DefaultAttachmentLabelsWrapper>
            <Button
              data-testid="add-default-sales-order-attachment-button"
              disabled={isUploadDefaultAttachmentFileLoading}
              label={t('Add attachment')}
              secondary
              width={'200rem'}
              onClick={() => {
                setIsUploadFileModalOpen(true);
                setSelectedDefaultAttachmentType(
                  DefaultAttachmentTypeId.SALES_ORDER
                );
              }}
            />
          </Column>
          <Column>
            <LabelSecondary>
              {t('Default invoice email attachment') + ':'}
            </LabelSecondary>
            <DefaultAttachmentLabelsWrapper>
              {isGetDefaultAttachmentFilesLoading ? (
                <LoaderWrapper>
                  <Spinner />
                </LoaderWrapper>
              ) : (
                defaultAttachmentFiles &&
                defaultAttachmentFiles.sales_invoice_files.map(
                  (defaultAttachment: IDefaultAttachment, index: number) => (
                    <DefaultAttachmentNameLinkDeleteIconWrapper key={index}>
                      <DefaultAttachmentNameLink
                        href={defaultAttachment.preview_url}
                        target={'_blank'}
                      >
                        {defaultAttachment.file_name}
                      </DefaultAttachmentNameLink>
                      <Icon
                        data-testid={`delete-invoice-attachment-${index}`}
                        svg={Trash}
                        color={COLORS.RED_200}
                        size={20}
                        weight="regular"
                        onClick={() => {
                          deleteDefaultAttachmentMailFile(defaultAttachment.id);
                        }}
                      />
                    </DefaultAttachmentNameLinkDeleteIconWrapper>
                  )
                )
              )}
            </DefaultAttachmentLabelsWrapper>
            <Button
              data-testid="add-default-invoice-attachment-button"
              disabled={isUploadDefaultAttachmentFileLoading}
              label={t('Add attachment')}
              secondary
              width={'200rem'}
              onClick={() => {
                setIsUploadFileModalOpen(true);
                setSelectedDefaultAttachmentType(
                  DefaultAttachmentTypeId.SALES_INVOICE
                );
              }}
            />
          </Column>
        </EmailConfigurationWrapper>
      </ContentContainer>
      <FileUploadModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        onSubmit={(files: any) => handleUploadFile(files)}
      />
    </Container>
  );
};
export default SettingsPage;
