import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { emailSchema } from './validation';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import {
  Container,
  ContainerWrapper,
  Headline,
  Label,
  LinkLabel,
  Logo,
  RowCenter,
  StyledLink,
} from './RequestPasswordReset.styled';
import bpSymbolBlack from 'assets/icons/bpSymbolBlack.svg';
import { useRequestPasswordReset } from './hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RequestPasswordReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = {
    email: '',
  };

  const handleSubmit = (values: any) => {
    submitRequestPasswordReset(values.email);
  };

  const { mutate: submitRequestPasswordReset, isSuccess } =
    useRequestPasswordReset();

  useEffect(() => {
    if (isSuccess) navigate(RoutesConfig.Login.fullPath);
  }, [isSuccess]);

  return (
    <ContainerWrapper>
      <Container>
        <StyledLink to={RoutesConfig.Home.fullPath}>
          <Logo src={bpSymbolBlack} alt="Logo" />
        </StyledLink>
        <Headline>{t('Forgot your password')}?</Headline>
        <Label>
          {t(
            'Please enter your email address. You will receive a link to create a new password via email'
          )}
          .
        </Label>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={emailSchema}
        >
          {({
            errors,
            touched,
            isValid,
            handleBlur,
            setFieldValue,
            values,
          }) => (
            <Form>
              <FormikInput
                pwId="email-field"
                errorMessage={touched['email'] ? errors['email'] : ''}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('email', value, true);
                  handleBlur(e);
                }}
                height={'55rem'}
                name="email"
                placeholder={t('E-mail')}
                wrapperStyles={{
                  width: '580rem',
                  marginBottom: '10rem',
                  marginTop: '10rem',
                }}
              />
              <Button
                type="submit"
                primary
                width="580rem"
                height={'55rem'}
                disabled={!isValid || !values.email}
                label={t('Reset password')}
              />
              <RowCenter>
                <LinkLabel to={RoutesConfig.Login.fullPath}>
                  {t('Back to Login')}
                </LinkLabel>
              </RowCenter>
            </Form>
          )}
        </Formik>
      </Container>
    </ContainerWrapper>
  );
};

export default RequestPasswordReset;
