import {
  Container,
  Card,
  TextContainer,
  Label,
  TitleContainer,
  TypeLabel,
  CostContainer,
  CostLabelTitle,
  CostLabel,
  TitleWrapper,
  CheckboxContainer,
} from './SinglePurchaseOrderProductsPage.styled';
import { H3 } from 'assets/styled';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useChangePurchaseStatus, useGetSinglePurchaseOrder } from '../hooks';
import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { formatNumber } from 'utils/numberFormatter';
import { useState } from 'react';
import { EditProductModal } from '../EditProductModal/EditProductModal';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useGetPurchaseOrderStatuses } from 'utils/hooks/useGetPurchaseOrderStatuses';
import { PurchaseOrderStatusType, PurchaseOrderStatus } from '../constants';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import DraggableLines, {
  DraggableLineType,
  IDraggableLine,
} from 'components/DragAndDrop/DraggableLines/DraggableLines';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';

const SinglePurchaseOrderProductsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const { data } = useGetSinglePurchaseOrder(id!);
  const { mutate: changePurchaseOrderStatus } = useChangePurchaseStatus();
  const options = useGetPurchaseOrderStatuses(PurchaseOrderStatusType.STATUS);
  const isStatusReceived = data?.status?.name === PurchaseOrderStatus.RECEIVED;

  const [isEditProductModalOpen, setIsEditProductModalOpen] =
    useState<boolean>(false);
  const [
    selectedPurchaseOrderLineForEditing,
    setSelectedPurchaseOrderLineForEditing,
  ] = useState<IPurchaseOrderLine>();

  const canEditPurchaseOrder = useCan(Actions.EDIT_PURCHASE_ORDER);

  return (
    <Container>
      <CheckboxContainer>
        <Checkbox
          disabled={isStatusReceived}
          isChecked={isStatusReceived}
          onChange={() => {
            !isStatusReceived &&
              changePurchaseOrderStatus({
                id: id! || '',
                status_id:
                  options?.find(
                    (option) => option?.name === PurchaseOrderStatus.RECEIVED
                  )?.id || '',
              });
          }}
          label={t('Mark all as received')}
          style={{ fontSize: '18rem' }}
        />
      </CheckboxContainer>
      {data?.service_lines?.map((serviceLine: any) => {
        return (
          <Card key={serviceLine.id}>
            <TitleContainer>
              <TitleWrapper>
                <H3>{serviceLine.service.purpose}</H3>
                <TypeLabel>{t('Service fee')}</TypeLabel>
              </TitleWrapper>
            </TitleContainer>
            <TextContainer>
              <Label>{serviceLine.service.description}</Label>
            </TextContainer>
            <CostContainer>
              <CostLabelTitle>{t('Total cost')}</CostLabelTitle>
              <CostLabel>{`${currencySymbol}${formatNumber(
                serviceLine.total_amount
              )}`}</CostLabel>
            </CostContainer>
          </Card>
        );
      })}
      <DraggableLines
        lines={data?.purchase_order_lines}
        lineType={DraggableLineType.PURCHASE_ORDER}
        onEditClick={(purchaseOrderLine: IDraggableLine) => {
          setSelectedPurchaseOrderLineForEditing(
            purchaseOrderLine as IPurchaseOrderLine
          );
          setIsEditProductModalOpen(true);
        }}
        purchaseOrder={data}
        isDragDisabled={!canEditPurchaseOrder}
      />
      <EditProductModal
        isOpen={isEditProductModalOpen}
        onCancel={() => setIsEditProductModalOpen(false)}
        setIsOpen={setIsEditProductModalOpen}
        purchaseOrderLine={selectedPurchaseOrderLineForEditing!}
      />
    </Container>
  );
};

export default SinglePurchaseOrderProductsPage;
