import {
  IConfiguredProductData,
  ILogyxConfigurationIntent,
} from 'store/Logyx/types';
import { IDiscount } from './Discount.types';
import { IAttachment } from './Attachment.types';

export interface IProduct {
  id: number;
  quantity: number;
  discount?: IDiscount;
  name: string;
  price: string;
  description: string;
  purchase_price: string;
  logyx_query_hash: string;
  attributes?: IProductAttribute[];
  logyx_attributes?: IConfiguredProductData; // Direct orders (verano nav integration) uses `logyx_attributes` instead of `intent.data` for accessing logyx related data
  logyx_model_id: number | null;
  logyx_configuration_intent?: ILogyxConfigurationIntent;
  default_product_id?: number;
  attachments: IAttachment[];
  digital_order_enabled: boolean | null;
}

export interface IProductAttribute {
  type: ProductAttributeType;
  fieldName: string;
  hasDefaultValue: boolean;
  defaultOptionIndex: number | null;
  defaultValue?: string;
  options?: string[];
  value?: string; // Used for storing form value when using IProductAttribute in new quotation form,
  docVisibilityOptions: IDocVisibilityOptionEnum[];
  shouldDisplayAfterLogyxConfiguration: boolean;
}

export enum IDocVisibilityOptionEnum {
  QUOTATION = 'QUOTATION',
  SALES_ORDER = 'SALES_ORDER',
  SALES_INVOICE = 'SALES_INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
}

export enum ProductAttributeType {
  FIELD = 'Field',
  DROPDOWN = 'Drop down',
}
