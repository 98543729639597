import { Option } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IInvoiceStatus } from 'types/Invoice.types';

export const useGetSalesInvoiceStatuses = () => {
  const storeStatuses = useSelector(
    (state: IRootReducerState) => state.commonInfo.salesInvoiceStatuses
  );

  const memoizedStatuses: Option[] = useMemo(() => {
    const salesInvoiceStatuses = storeStatuses?.map(
      (status: IInvoiceStatus) => {
        return {
          name: status.name,
          value: status.name,
          label: status.name,
          id: status.id,
          key: status.id,
        };
      }
    );

    return salesInvoiceStatuses;
  }, [storeStatuses, i18n.resolvedLanguage]);

  return memoizedStatuses;
};
