import { H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import FakeInput from '../../../../components/FakeInput/FakeInput';
import {
  Container,
  CustomerDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  OrderDetailsWrapper,
  RowMarginTop,
  SectionTitle,
  SpinnerWrapper,
  SubmitRow,
} from './OverviewTab.styled';
import { ICreateInvoiceCustomerDetails } from '../CustomerTab/constants';
import { ICreateInvoiceDetails } from '../InvoiceDetailsTab/constants';
import { useEffect, useState } from 'react';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Spinner from 'components/Spinner/Spinner';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Tooltip from 'components/Tooltip/Tooltip';

interface IOverviewTabProps {
  onForward: () => void;
  onBack: () => void;
  customerDetails: ICreateInvoiceCustomerDetails;
  invoiceDetails: ICreateInvoiceDetails;
  status: string;
  newlyCreatedInvoiceId: string;
  isDownPaymentInvoiceCreated: boolean;
  totalAmount: number | undefined;
  isLoadingTotalAmount: boolean;
}

const OverviewTab = ({
  onForward,
  onBack,
  customerDetails,
  invoiceDetails,
  status,
  newlyCreatedInvoiceId,
  isDownPaymentInvoiceCreated,
  totalAmount,
  isLoadingTotalAmount,
}: IOverviewTabProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const [
    isSmallTotalAmountWarningModalOpen,
    setIsSmallTotalAmountWarningModalOpen,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (status === 'success' && newlyCreatedInvoiceId) {
      navigate(
        RoutesConfig.SingleSalesInvoice.fullPath
          .replace(':id', newlyCreatedInvoiceId)
          .replace('/*', ''),
        {
          state: { openModal: true },
        }
      );
    }
  }, [status]);

  return (
    <Container>
      <FirstRow>
        <H3>{t('Overview')}</H3>
      </FirstRow>
      <CustomerDetailsWrapper>
        <SectionTitle>{t('Customer details')}</SectionTitle>
        <RowMarginTop>
          <FakeInput
            label={customerDetails.name}
            isDropdown={false}
            width={'50%'}
            marginTop={marginMd}
            placeholder={t('Name')}
          />
          <FakeInput
            label={customerDetails.last_name}
            isDropdown={false}
            width={'50%'}
            marginTop={marginMd}
            placeholder={t('Last name')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={customerDetails.phone}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Phone number')}
          />
          <FakeInput
            label={customerDetails.title}
            isDropdown={true}
            width={'50%'}
            placeholder={t('Title')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={customerDetails.email}
            isDropdown={false}
            width={'50%'}
            placeholder={t('E-mail')}
          />
          <FakeInput
            label={customerDetails.country}
            isDropdown={true}
            width={'50%'}
            placeholder={t('Country')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={customerDetails.city}
            isDropdown={false}
            width={'50%'}
            placeholder={t('City')}
          />
          <FakeInput
            label={customerDetails.code}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Zip code')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={customerDetails.street}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Street')}
          />
          <FakeInput
            label={customerDetails.house_number}
            isDropdown={false}
            width={'50%'}
            placeholder={t('House number')}
          />
        </RowMarginTop>
        <Line />
      </CustomerDetailsWrapper>

      <OrderDetailsWrapper>
        <SectionTitle>{t('Invoice details')}</SectionTitle>
        <RowMarginTop marginTop={marginMd}>
          {invoiceDetails?.externalId?.length > 30 ? (
            <Tooltip content={invoiceDetails.externalId}>
              <FakeInput
                marginTop={marginMd}
                label={`${invoiceDetails.externalId?.substring(0, 30)}...`}
                isDropdown={false}
                width={'100%'}
                placeholder={t('External reference')}
              />
            </Tooltip>
          ) : (
            <FakeInput
              label={invoiceDetails.externalId}
              isDropdown={false}
              width={'50%'}
              placeholder={t('External reference')}
            />
          )}
          <FakeInput
            label={invoiceDetails?.daysValid}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Term of payment')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={moment(invoiceDetails.date).format('DD.MM.yyyy.')}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Invoice date')}
          />
          {totalAmount !== undefined ? (
            <FakeInput
              label={`${currencySymbol}${totalAmount}`}
              isDropdown={false}
              width={'50%'}
              placeholder={
                isDownPaymentInvoiceCreated
                  ? t('Total amount')
                  : t('Down payment')
              }
            />
          ) : isLoadingTotalAmount ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : null}
        </RowMarginTop>
      </OrderDetailsWrapper>
      <SubmitRow>
        <Button
          width={'200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            width={'200rem'}
            onClick={() => {
              if (totalAmount && totalAmount < 10) {
                setIsSmallTotalAmountWarningModalOpen(true);
              } else {
                onForward();
              }
            }}
            label={t('Finish')}
            primary
            disabled={status === 'loading' || isLoadingTotalAmount}
          />
        </Margin>
      </SubmitRow>
      <ConfirmModal
        isOpen={isSmallTotalAmountWarningModalOpen}
        setIsOpen={setIsSmallTotalAmountWarningModalOpen}
        onConfirm={() => {
          onForward();
          setIsSmallTotalAmountWarningModalOpen(false);
        }}
        onCancel={() => setIsSmallTotalAmountWarningModalOpen(false)}
        title={t('Warning')}
        description={t(
          'Are you sure you wish to create an invoice? The total amount is less than 10'
        )}
      />
    </Container>
  );
};

export default OverviewTab;
