import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommentCard from '../../../../components/Cards/CommentCard/CommentCard';
import { useGetSalesOrderComments } from '../hooks';
import { useTranslation } from 'react-i18next';
import { SALES_ORDER_COMMENTS_PER_PAGE } from './constants';
import {
  NoContentLabel,
  CommentsContainer,
  RightAlign,
  DatePickerWrap,
} from './SingleSalesOrderCommentsPage.styled';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import Button from 'components/Button/Button';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { CustomDateRangePicker } from 'components/CustomDateRangePicker/CustomDateRangePicker';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const SingleSalesOrderCommentsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const { fetchNextPage, isFetching, data, hasNextPage, isFetched } =
    useGetSalesOrderComments(
      SALES_ORDER_COMMENTS_PER_PAGE,
      id!,
      startDate,
      endDate
    );

  const dataRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const comments = data?.pages.map((page) => page.comments).flat();

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER_COMMENTS,
    id,
    isFetched
  );

  return (
    <CommentsContainer
      onScroll={onScroll}
      ref={dataRef}
      className="single-sales-order-step-7"
    >
      <RightAlign>
        <DatePickerWrap>
          <CustomDateRangePicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </DatePickerWrap>
        <Button
          label={t('Add another comment')}
          link
          icon={Plus}
          sizeIcon={20}
          weightIcon="regular"
          colorIcon={COLORS.PRIMARY}
          onClick={() =>
            navigate(
              `/sales/${id}/${SingleSalesOrderRoutes.SingleSalesOrderNewComment.path}`
            )
          }
          fontSize="18rem"
        />
      </RightAlign>
      {!comments?.length && !isFetching ? (
        <NoContentLabel>
          {t('There are currently no comments on this Sales order')}
        </NoContentLabel>
      ) : (
        comments?.map((item: any, index: number) => {
          return (
            <CommentCard
              key={`${index}`}
              title={item.headline}
              name={`${item.user.name} ${item.user.last_name}`}
              type={item.is_internal ? t('Internal') : t('External')}
              date={item.created_at}
              description={item.description}
              imageUrl={item.user.image_url}
            />
          );
        })
      )}
      <IntroductionGuide steps={steps} />
    </CommentsContainer>
  );
};

export default SingleSalesOrderCommentsPage;
