import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSuccessfulPaymentInfo } from 'services/Invoice/SalesInvoiceService';

export const useGetPaymentInfo = (
  payment_intent_id: string,
  token: string,
  companyId: string
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_PAYMENT_INFO],
    queryFn: () =>
      getSuccessfulPaymentInfo(payment_intent_id, token, companyId),
    refetchOnWindowFocus: false,
  });
