import { marginMd, marginXs, paddingMd } from 'assets/styled';
import { paddingLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${paddingLg};

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${marginMd};
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 203rem;
  height: 41rem;
`;
export const RowRightPart = styled.div`
  display: flex;
  gap: 20rem;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const AdvancedFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${marginXs};
`;
