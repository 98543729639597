import { IAddNewMollieCustomerDTO } from 'pages/Manager/SingleCustomerPage/AddNewMollieCustomerModal/hooks';
import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/mollie_customers/';

export const setPreferredMandate = async (
  mollieCustomerId: string,
  mandateId: string
): Promise<any> => {
  const url = `${BASE_URL}${mollieCustomerId}/set-preferred-mandate/${mandateId}`;
  const { data } = await apiClient.patch<any>(url);

  return data.data;
};

export const getMandates = async (mollieCustomerId: string): Promise<any> => {
  const url = `${BASE_URL}${mollieCustomerId}/mandates`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const connectCustomerToMollieCustomer = async (
  addNewMollieCustomerData: IAddNewMollieCustomerDTO
): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, addNewMollieCustomerData);

  return data.data;
};
