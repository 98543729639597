import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  CloseAbsoluteWrapper,
  AwaitingLogyxConfigurationContainer,
  AwaitingLabel,
  LoaderWrapper,
} from './AwaitingLogyxConfigurationModal.styled';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAwaitingPurchaseOrderLineEdit } from 'store/Logyx/actions/logyx';
import { ILogyxReducerState } from 'store/Logyx/reducers/logyx';
import { IRootReducerState } from 'store/store';
import Loader from 'components/Loader/Loader';
import { useState } from 'react';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';

export function AwaitingLogyxConfigurationModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDangerModalOpen, setIsDangerModalOpen] = useState<boolean>(false);
  const logyxInfo: ILogyxReducerState = useSelector(
    (state: IRootReducerState) => state.logyxInfo
  );
  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );
  // isAwaitingPurchaseOrderLineEdit is triggered by onLogyxPurchaseOrderLineReconfigurationFinishedEvent
  return (
    <Modal
      isOpen={logyxInfo.isAwaitingPurchaseOrderLineEdit}
      setIsOpen={() =>
        logyxInfo.isAwaitingPurchaseOrderLineEdit
          ? () => null
          : dispatch(setIsAwaitingPurchaseOrderLineEdit(false))
      }
      modalStyle={{ position: 'fixed', margin: 'auto' }} // Center positioning
    >
      {logyxInfo.isAwaitingPurchaseOrderLineEdit ? (
        <AwaitingLogyxConfigurationContainer>
          <AwaitingLabel>{t('Editing through Logyx')}</AwaitingLabel>
          <LoaderWrapper>
            <Loader positionType="relative" />
          </LoaderWrapper>
          <CloseAbsoluteWrapper>
            <Close
              onClick={() => {
                setIsDangerModalOpen(true);
              }}
            />
          </CloseAbsoluteWrapper>
        </AwaitingLogyxConfigurationContainer>
      ) : null}
      <Modal
        level={'SECOND'}
        isOpen={isDangerModalOpen}
        setIsOpen={setIsDangerModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (isMobileApp) {
                const mobileMessage: IMobileMessage = {
                  type: MobileMessageTypes.CLOSE_LOGYX,
                };
                postMobileMessage(mobileMessage);
              }
              setIsDangerModalOpen(false);
              dispatch(setIsAwaitingPurchaseOrderLineEdit(false));
            },
            text: t('Cancel'),
            disabled: false,
          }}
          cancel={{
            onClick: () => setIsDangerModalOpen(false),
            text: t('Back'),
          }}
          title={t('Cancel configuration')}
          description={
            t('Are you sure you want to cancel editing through Logyx') + '?'
          }
        />
      </Modal>
    </Modal>
  );
}
