export const insertTextAtCursorPosition = (textToInsert: string, ref: any) => {
  const input = ref.current;
  if (input) {
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const currentValue = input.value;

    const newValue =
      currentValue.substring(0, startPos!) +
      textToInsert +
      currentValue.substring(endPos!, currentValue.length);
    input.value = newValue;
    input.setSelectionRange(
      startPos + textToInsert.length,
      startPos + textToInsert.length
    );
  }
};
