export const CUSTOMERS_PER_PAGE = 10;
export const QUOTATIONS_PER_PAGE = 10;
export const SALES_ORDERS_PER_PAGE = 10;
export const WORKERS_PER_PAGE = 10;
export const USERS_PER_PAGE = 10;
export const RESOURCES_PER_PAGE = 10;

export enum GeneralTabOptions {
  CUSTOMER = 'CUSTOMER',
  QUOTATION = 'QUOTATION',
  SALES_ORDER = 'SALES ORDER',
}
