import { Close } from 'components/Close/Close';
import Input from 'components/Input/Input';
import Loader from 'components/Loader/Loader';
import { Modal } from 'components/Modal/Modal';
import TableAlt from 'components/TableAlt/TableAlt';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloseContainer,
  Container,
  Description,
  RowSpaceBetween,
  SearchContainer,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './BulkReceiveModal.styled';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import {
  BULK_BOOK_PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDERS_PER_PAGE,
  SUPPLIERS_PER_PAGE,
  tableConfig,
} from './constants';
import { useGetPurchaseOrders } from '../hooks';
import { formatBulkBookTableData } from './helpers';
import { useBulkBookPurchaseOrder } from './hooks';
import { ISupplier } from 'types/Supplier.types';
import { IBulkBookTableDTO, IPurchaseOrder } from 'types/PurchaseOrders.types';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { useGetSuppliersInfinite } from 'pages/Manager/NewQuotationPage/ProductTab/AddNewProduct/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';

interface IBulkReceiveModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
}
export const BulkReceiveModal = ({
  isOpen,
  setIsOpen,
  onCancel,
}: IBulkReceiveModalProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const [supplierSearchBy, setSupplierSearchBy] = useState<string>('');
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier>();
  const [deletedOrderIds, setDeletedOrderIds] = useState<string[]>([]);
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState<
    string[]
  >([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const debouncedSearchBy = useDebounce(supplierSearchBy);

  const {
    data: suppliersData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingSuppliers,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBy, true);

  const { data: purchaseOrdersData, isLoading } = useGetPurchaseOrders(
    page,
    PURCHASE_ORDERS_PER_PAGE,
    isOpen,
    searchBy,
    BULK_BOOK_PURCHASE_ORDER_STATUSES,
    selectedSupplier ? selectedSupplier.id : undefined
  );
  const { mutate: bulkBook } = useBulkBookPurchaseOrder({
    onSuccess: () => {
      setDeletedOrderIds(selectedPurchaseOrderIds);
    },
  });

  const supplierOptions: Option[] = useMemo(() => {
    const options: Option[] = [{ value: 'all', label: t('All'), key: 'all' }];

    if (suppliersData?.pages?.length) {
      const suppliers = suppliersData.pages
        .map((page) => page.suppliers)
        .flat()
        .map((supplier: any) => ({
          value: supplier,
          label: `${supplier.company_name} ${supplier.number} - ${supplier.contact_person}`,
          key: supplier.id,
        }));

      return options.concat(suppliers);
    }

    return options;
  }, [suppliersData]);

  let purchaseOrdersCurrentData: IBulkBookTableDTO[] = [];

  purchaseOrdersCurrentData = formatBulkBookTableData(
    purchaseOrdersData?.purchase_orders
      ?.map((purchaseOrder: IPurchaseOrder[]) => {
        return purchaseOrder;
      })
      .flat()
  );

  const resetFields = () => {
    setPage(1);
    setSearchBy('');
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={() => {
        resetFields();
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        minWidth: '950rem',
      }}
    >
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              resetFields();
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Bulk receive')}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>
            {t('Select all the orders which have been received')}.
          </Description>
        </TitleContainer>

        <RowSpaceBetween>
          <SearchContainer>
            <Input
              icon={MagnifyingGlass}
              width={'300rem'}
              placeholder={t('Search')}
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
            />
          </SearchContainer>

          <SelectInputWrapper>
            <Select
              name="type"
              placeholder={t('Supplier')}
              isMulti={false}
              isDisabled={false}
              isSearchable={true}
              onChange={(e: any) => {
                setSelectedSupplier(e.value);
              }}
              onInputChange={(value: string) =>
                setSupplierSearchBy(value ? value : '')
              }
              options={supplierOptions}
              isLoading={isLoadingSuppliers}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          </SelectInputWrapper>
        </RowSpaceBetween>

        {isLoading ? (
          <Loader />
        ) : (
          <TableAlt
            page={page}
            perPage={PURCHASE_ORDERS_PER_PAGE}
            total={purchaseOrdersData?.total}
            tableData={purchaseOrdersCurrentData}
            tableConfig={tableConfig}
            hasExtraAction={true}
            loadPage={(newPage) => setPage(newPage)}
            selectable
            selectedRows={selectedPurchaseOrderIds}
            setSelectedRows={setSelectedPurchaseOrderIds}
            removedRows={deletedOrderIds}
            onApply={() => {
              if (!selectedPurchaseOrderIds?.length) return;
              setIsConfirmModalOpen(true);
            }}
            onCancel={() => setIsOpen(false)}
            animatedRows={true}
          />
        )}
      </Container>
      <ConfirmModal
        level="SECOND"
        title={t('Bulk Receive')}
        description={`${t(
          'Are you sure you want to mark selected orders as received'
        )}?`}
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={() => {
          bulkBook({
            purchase_order_ids: selectedPurchaseOrderIds,
          });
          setSelectedPurchaseOrderIds([]);
          setIsConfirmModalOpen(false);
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </Modal>
  );
};
