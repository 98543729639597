export interface IWorkerAndResource {
  id: string;
  name: string;
  description: string;
  company?: any;
}

export interface IEditWorkerDTO {
  name: string;
  description: string;
}

export enum EditingType {
  WORKER = 'WORKER',
  RESOURCE = 'RESOURCE',
}
