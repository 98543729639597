import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled';

interface StyledProps {
  active: boolean;
  finished: boolean;
}

export const Container = styled.div`
  padding-top: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1000rem;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900rem;
  background: ${COLORS.WHITE};
  margin-top: 30rem;
  margin-bottom: 30rem;
  border-radius: 0rem 10rem 10rem 0rem;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50rem;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.GREY_200};
  border-radius: 0rem 10rem 10rem 0rem;
  border-top: 0;
  border-bottom: 0;
  border-left: none;
  color: ${COLORS.BLACK};
  width: 300rem;
  ${(props: StyledProps) =>
    props.active &&
    css`
      border-radius: 0rem 10rem 10rem 0rem;
      color: ${COLORS.PRIMARY};
      border: 1rem solid ${COLORS.PRIMARY};
    `}
  ${(props: StyledProps) =>
    props.finished &&
    css`
      color: ${COLORS.WHITE};
      background-color: ${COLORS.PRIMARY};
      border: none;
      border-radius: 0;
    `}
`;

export const CreateNewSalesOrderLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  width: 903rem;
  text-align: left;
`;
