export enum EmailTemplateColors {
  color_1 = '#EDF4FA',
  color_2 = '#31990d',
  color_3 = '#E51616',
  color_4 = '#82868C',
  color_5 = '#1A7FBF',
}

export interface EmailTemplateDetails {
  headline: string;
  body: string;
}

export enum EmailTemplatesTab {
  DEFAULT = 'Default',
  CUSTOM = 'Custom',
}

export interface ICreateEmailTemplateData {
  headline: string;
  body: string;
  type_id: number;
}

export enum EmailTemplateType {
  QUOTATION = 'QUOTATION',
  SALES_ORDER = 'SALES_ORDER',
  INVOICE = 'SALES_INVOICE',
  APPOINTMENT = 'APPOINTMENT',
}

export interface ICreateEmailTemplateGeneralThemeData {
  theme_color: string;
  headline_font_size: string;
  font_family: string;
  font_size: string;
  font_weight?: string;
}
