import {
  Container,
  Label,
  Logo,
  Row,
  RememberMeLabel,
  RememberMeForgotPasswordWrapper,
  ForgotPasswordLabel,
  PasswordInputWrapper,
  ButtonContainer,
  EmailInputWrapper,
  AbsoluteWrapper,
  LanguageSwitcherWrapper,
  RelativeCenterWrapper,
  ContainerWrapper,
  EyeWrapper,
} from './LoginPage.styled';
import bpSymbolBlack from 'assets/icons/bpSymbolBlack.svg';
import { useEffect, useState } from 'react';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { setUserInfo } from 'store/User/actions/user';
import { useDispatch } from 'react-redux';
import { useLogin } from './hooks';
import LocalStorageService from 'services/LocalStorageService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { updateSocketToken } from 'providers/SocketIOProvider/SocketIOProvider';
import { Switch } from '@mui/material';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { RoutesConfig } from 'navigation/routes';
import { Form, Formik } from 'formik';
import { loginSchema } from './validation';
import { setIsLoadingScreenShown } from 'store/Common/actions/common';
import Icon from 'components/Icon/Icon';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ILoginRequestDTO {
  email: string;
  password: string;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState<boolean>(true);

  const initialValues = {
    email: '',
    password: '',
  };

  const handleEyeClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleCheckboxClick = () => {
    if (!isLoading) {
      setRememberMe(!rememberMe);
    }
  };

  const { data: userData, mutate: login, isLoading } = useLogin();

  useEffect(() => {
    if (userData) {
      dispatch(setUserInfo(userData.user));
      updateSocketToken(userData.access_token);
      LocalStorageService.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN,
        userData.access_token
      );
      if (rememberMe) {
        LocalStorageService.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN,
          userData.refresh_token
        );
      }
      dispatch(setIsLoadingScreenShown(true));
    }
  }, [userData, dispatch]);

  const handleEnterKeyPress = (event: any, values: ILoginRequestDTO) => {
    if (event.key === 'Enter') {
      login(values);
    }
  };

  return (
    <ContainerWrapper>
      <Container>
        <Logo src={bpSymbolBlack} alt="" />
        <Label>{t('Log in')}</Label>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={initialValues}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleBlur,
            setFieldValue,
          }) => (
            <Form>
              <EmailInputWrapper>
                <FormikInput
                  pwId={'email-field'}
                  height={'55rem'}
                  name="email"
                  errorMessage={touched['email'] ? errors['email'] : ''}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value, true);
                    handleBlur(e);
                  }}
                  placeholder={t('E-mail')}
                  wrapperStyles={{
                    width: '580rem',
                  }}
                  onKeyPress={(event) => handleEnterKeyPress(event, values)}
                />
              </EmailInputWrapper>
              <PasswordInputWrapper>
                <FormikInput
                  pwId={'password-field'}
                  height={'55rem'}
                  name="password"
                  errorMessage={touched['password'] ? errors['password'] : ''}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('password', value, true);
                    handleBlur(e);
                  }}
                  placeholder={t('Password')}
                  type={hidePassword ? 'password' : ''}
                  wrapperStyles={{
                    width: '580rem',
                  }}
                  onKeyPress={(event) => handleEnterKeyPress(event, values)}
                />
                <EyeWrapper
                  onClick={() => handleEyeClick()}
                  data-testid={'eye-icon'}
                >
                  <Icon
                    svg={hidePassword ? Eye : EyeSlash}
                    color={COLORS.PRIMARY}
                    size={25}
                  />
                </EyeWrapper>
              </PasswordInputWrapper>
              <RememberMeForgotPasswordWrapper width={'580rem'}>
                <Row>
                  <Switch
                    checked={rememberMe}
                    onChange={() => handleCheckboxClick()}
                  />
                  <RememberMeLabel onClick={() => handleCheckboxClick()}>
                    {t('Remember me')}
                  </RememberMeLabel>
                </Row>
                <ForgotPasswordLabel
                  to={RoutesConfig.RequestPasswordReset.fullPath}
                >
                  {t('Forgot password')}?
                </ForgotPasswordLabel>
              </RememberMeForgotPasswordWrapper>
              <ButtonContainer>
                <Button
                  label={t('Continue')}
                  primary
                  disabled={
                    isLoading || !isValid || (!values.email && !values.password)
                  }
                  width="580rem"
                  height={'55rem'}
                  onClick={() => {
                    if (isValid) {
                      login(values);
                    }
                  }}
                />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
        <RelativeCenterWrapper>
          <AbsoluteWrapper>
            <ToggleDarkMode />
            <LanguageSwitcherWrapper>
              <LanguageSwitcher />
            </LanguageSwitcherWrapper>
          </AbsoluteWrapper>
        </RelativeCenterWrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default LoginPage;
