import { Moment } from 'moment';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getQuotationAppointments,
  getQuotationAppointmentsForDateInterval,
} from 'services/Quotation/QuotationService';

export const useGetSingleQuotationAppointmentsForDateInterval = (
  quotationId: string,
  dateFrom: Moment,
  dateTo: Moment
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_QUOTATION_APPOINTMENTS_FOR_INTERVAL,
      quotationId,
      dateFrom,
      dateTo,
    ],
    queryFn: () => {
      return getQuotationAppointmentsForDateInterval(
        quotationId,
        dateFrom,
        dateTo
      );
    },
  });

export const useGetSingleQuotationAppointments = (
  quotationId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_QUOTATION_APPOINTMENTS,
      quotationId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getQuotationAppointments(quotationId, page, perPage);
    },
  });

export const useGetQuotationId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};
