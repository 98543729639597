import { COLORS, marginSm, paddingMd } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${paddingMd};
  max-height: 859rem;
`;

export const Heading = styled.div`
  padding-top: ${marginSm};
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  padding-bottom: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-right: 10rem;
  margin-top: ${marginSm};
  width: 300rem;
`;
