import { COLORS, marginMd, paddingSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Headline = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
`;

export const Label = styled.span`
  line-height: 21rem;
  font-size: 14rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const QuarterRow = styled.div`
  width: 20%;
  padding: 0 10rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${COLORS.BLACK};
`;

export const Appointments = styled.div`
  border-radius: 10rem;
  border: 1rem solid ${COLORS.GREY_1000};
`;

export const Product = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_1000};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10rem 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const Title = styled(Label)`
  color: ${COLORS.PRIMARY};
`;

export const Subtitle = styled.label`
  font-size: 17rem;
  font-weight: 400;
  line-height: 21rem;
  text-align: center;
  margin-bottom: ${marginMd};
`;
