import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { deleteFwAppointmentLine } from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useDeleteFwAppointmentLine = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (appointmentLineId: string) =>
      deleteFwAppointmentLine(appointmentId, appointmentLineId),
    {
      onSuccess: () => {
        toast.success(t('Appointment line deleted'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_FW_APPOINTMENT_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.DELETE_FW_APPOINTMENT_LINE,
    }
  );
};
