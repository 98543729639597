import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { H3, H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Card,
  ColumnContainer,
  Container,
  Label,
  LabelTitle,
  MainContainer,
  TitleContainer,
  TextContainer,
} from './SingleSalesCreditInvoiceSalesInvoicePage.styled';
import { useGetSingleSalesCreditInvoice } from '../hooks';
import { RoutesConfig } from 'navigation/routes';
import { formatNumber } from 'utils/numberFormatter';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SingleSalesCreditInvoiceSalesInvoicePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const canReadSalesOrder = useCan(Actions.READ_SALES_ORDER);

  const { status, data, isFetching } = useGetSingleSalesCreditInvoice(
    typeof id === 'string' ? id : ''
  );

  return (
    <>
      <Container>
        <Card>
          <TitleContainer>
            <H3>{data?.sales_invoice?.parent_invoice?.invoice_number}</H3>
            <Button
              onClick={() =>
                navigate(
                  `${RoutesConfig.SingleSalesInvoice.fullPath.replace(
                    ':id/*',
                    data?.sales_invoice?.parent_invoice?.id
                  )}`
                )
              }
              label={t('View sales invoice')}
              primary
              disabled={!canReadSalesOrder}
            ></Button>
          </TitleContainer>
          <MainContainer>
            <ColumnContainer>
              <H4>{t('DETAILS')}</H4>
              <TextContainer>
                <LabelTitle>{t('Valid to')}</LabelTitle>
                <Label>
                  {moment(data?.sales_invoice?.parent_invoice?.valid_to).format(
                    'DD-MM-YYYY'
                  )}
                </Label>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Customer')}</LabelTitle>
                <Label>
                  {data?.sales_invoice?.parent_invoice?.customer?.name +
                    ' ' +
                    data?.sales_invoice?.parent_invoice?.customer?.last_name}
                </Label>
              </TextContainer>
              <TextContainer>
                <LabelTitle>{t('Amount')}</LabelTitle>
                <Label>
                  {currencySymbol}
                  {formatNumber(
                    data?.sales_invoice?.parent_invoice?.total_amount
                  )}
                </Label>
              </TextContainer>
            </ColumnContainer>
            <ColumnContainer>
              <H4>{t('STATUS')}</H4>
              <TextContainer>
                <LabelTitle>{t('Payment')}</LabelTitle>
                <Label>
                  {data?.sales_invoice?.parent_invoice?.payment_status?.name}
                </Label>
              </TextContainer>
            </ColumnContainer>
          </MainContainer>
        </Card>
      </Container>
    </>
  );
};

export default SingleSalesCreditInvoiceSalesInvoicePage;
