import Table from 'components/Table/Table';
import { formatCustomerQuotationsTableData } from 'components/Table/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IQuotation } from 'types/Quotations.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { CUSTOMER_QUOTATIONS_PER_PAGE, tableConfig } from './constants';
import { useGetSingleCustomerQuotations } from './hooks';
import {
  Container,
  NoContentLabel,
  TableWrapper,
} from './SingleCustomerQuotationsPage.styled';

export const SingleCustomerQuotationsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const [page, setPage] = useState<number>(1);

  const { data: customerQuotations, isLoading } =
    useGetSingleCustomerQuotations(id!, page, CUSTOMER_QUOTATIONS_PER_PAGE);

  const handleViewButtonClick = (row: any) => {
    navigate(
      RoutesConfig.SingleQuotation.fullPath.replace(
        ':id/*',
        row.navigationID.toString()
      )
    );
  };

  let tableData: IQuotation[] = [];
  if (customerQuotations?.quotations) {
    tableData = formatCustomerQuotationsTableData(
      customerQuotations?.quotations.map((row: IQuotation[]) => row).flat(),
      currencySymbol
    );
  }

  return (
    <Container>
      {!customerQuotations?.quotations?.length && !isLoading ? (
        <NoContentLabel>
          {t('There are currently no quotations for this Customer')}
        </NoContentLabel>
      ) : (
        <TableWrapper>
          <Table
            isLoading={isLoading}
            page={page}
            perPage={CUSTOMER_QUOTATIONS_PER_PAGE}
            total={customerQuotations?.total}
            tableData={tableData}
            tableConfig={tableConfig}
            hasExtraAction={true}
            loadPage={(newPage) => setPage(newPage)}
            onViewButtonClick={handleViewButtonClick}
          />
        </TableWrapper>
      )}
    </Container>
  );
};
