import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.span``;

export const IconWrapper = styled.div``;
