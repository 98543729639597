import { COLORS, H4 } from 'assets/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductAttribute } from 'types/Product.types';
import { IPurchaseOrder, IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { formatAttributeObjectIntoArray } from 'utils/productAttributeFormatting/formatAttributeObjectIntoArray';
import { formatNumber } from 'utils/numberFormatter';
import {
  AttributesWrapper,
  Card,
  ColumnContainer,
  CostContainerAbsolute,
  CostLabel,
  CostLabelTitle,
  DescriptionContainer,
  EditIcon,
  EditLabel,
  GreenLine,
  Label,
  LabelTitle,
  LogyxAttributesLabel,
  MainContainer,
  TextColumnContainer,
  TextContainer,
  TitleContainer,
  TitleWrapper,
  TypeLabel,
  WrapperRelative,
  ActionsRow,
  TitleLabel,
  RefLabel,
  RefLabelsWrapper,
} from './PurchaseOrderProductCard.styled';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute, QuestionType } from 'store/Logyx/types';
import {
  formatPurchaseOrderLineReferences,
  getProductCardTitle,
} from './helpers';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { StatusReceivedSwitch } from './StatusReceivedSwitch/StatusReceivedSwitch';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { CaretDown, CaretUp, PencilSimple } from '@phosphor-icons/react';
import { getBooleanAttributeLabel } from 'components/DragAndDrop/DraggableLines/helpers';

interface IProductCardProps {
  purchaseOrderLine: IPurchaseOrderLine;
  purchaseOrder: IPurchaseOrder;
  onEditClick?: (line: IPurchaseOrderLine) => void;
}

export const PurchaseOrderProductCard = ({
  purchaseOrderLine,
  purchaseOrder,
  onEditClick,
}: IProductCardProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const canEditPurchaseOrder = useCan(Actions.EDIT_PURCHASE_ORDER);

  const attributes = formatAttributeObjectIntoArray(
    purchaseOrderLine.product?.attributes
  );
  const logyxAttributes = formatLogyxAttributesObjectIntoArray(
    purchaseOrderLine.product?.logyx_configuration_intent?.data
  );
  const allAttributes = [...logyxAttributes, ...attributes];

  const renderProductAttributes = () => {
    return allAttributes.map(
      (attribute: IProductAttribute | ILogyxAttribute, index: number) => {
        return (
          <AttributesWrapper key={index}>
            {logyxAttributes.length && index === 0 ? (
              <>
                <GreenLine />
                <LogyxAttributesLabel>
                  {t('Logyx Attributes')}
                </LogyxAttributesLabel>
              </>
            ) : null}
            <TextColumnContainer>
              <LabelTitle>{attribute.fieldName}</LabelTitle>
              <Label>
                {attribute.type === QuestionType.BOOLEAN
                  ? t(getBooleanAttributeLabel(attribute.value))
                  : attribute.value}
              </Label>
            </TextColumnContainer>
            {logyxAttributes.length && index === logyxAttributes.length - 1 ? (
              <GreenLine />
            ) : null}
          </AttributesWrapper>
        );
      }
    );
  };

  return (
    <Card key={purchaseOrderLine?.id}>
      <TitleContainer>
        <TitleWrapper>
          <TitleLabel>{getProductCardTitle(purchaseOrderLine, t)}</TitleLabel>
          <TypeLabel>
            {purchaseOrderLine.stock_item ? t('Stock item') : t('Product')}
          </TypeLabel>
        </TitleWrapper>
        <ActionsRow>
          <StatusReceivedSwitch
            line={purchaseOrderLine}
            purchaseOrder={purchaseOrder}
          />
          {canEditPurchaseOrder && (
            <WrapperRelative
              onClick={() => onEditClick && onEditClick(purchaseOrderLine)}
            >
              <EditLabel
                data-testid={`edit-${getProductCardTitle(
                  purchaseOrderLine,
                  t
                )}`}
              >
                {t('Edit')}
              </EditLabel>
              <EditIcon>
                <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
              </EditIcon>
            </WrapperRelative>
          )}
          <WrapperRelative>
            <Icon
              data-testid={`expand-${getProductCardTitle(
                purchaseOrderLine,
                t
              )}`}
              svg={isExpanded ? CaretUp : CaretDown}
              color={COLORS.PRIMARY}
              size={20}
              weight="regular"
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </WrapperRelative>
        </ActionsRow>
      </TitleContainer>
      <DescriptionContainer>
        <TextContainer>
          <Label>
            {purchaseOrderLine.stock_item_line
              ? purchaseOrderLine.stock_item?.description
              : purchaseOrderLine?.product?.description}
          </Label>
        </TextContainer>
      </DescriptionContainer>
      <MainContainer $isExpanded={isExpanded}>
        <ColumnContainer $isExpanded={isExpanded}>
          <H4>{t('Placement').toUpperCase()}</H4>
          <TextContainer>
            <Label>{purchaseOrderLine?.sales_order_line?.placement}</Label>
          </TextContainer>
        </ColumnContainer>
        {isExpanded && (
          <ColumnContainer $isExpanded={isExpanded}>
            <H4>{t('General').toUpperCase()}</H4>
            {purchaseOrderLine.supplier && (
              <TextColumnContainer>
                <LabelTitle>{t('Supplier')}</LabelTitle>
                <Label>{purchaseOrderLine?.supplier?.company_name}</Label>
              </TextColumnContainer>
            )}
            {purchaseOrderLine?.stock_item_line && (
              <TextColumnContainer>
                <LabelTitle>{t('Item supplier number')}</LabelTitle>
                <Label>{purchaseOrderLine?.stock_item?.number_supplier}</Label>
              </TextColumnContainer>
            )}
            <TextColumnContainer>
              <LabelTitle>{t('Quantity')}</LabelTitle>
              <Label>{purchaseOrderLine?.quantity}</Label>
            </TextColumnContainer>
            <TextColumnContainer>
              <LabelTitle>{t('Delivery Status')}</LabelTitle>
              <Label>{purchaseOrderLine?.delivery_status?.name}</Label>
            </TextColumnContainer>
            <TextColumnContainer>
              <LabelTitle>{t('Payment Status')}</LabelTitle>
              <Label>{t(purchaseOrderLine?.payment_status?.name)}</Label>
            </TextColumnContainer>
            <TextColumnContainer>
              <LabelTitle> {t('Purchase Price')} </LabelTitle>
              <Label>{`${currencySymbol}${formatNumber(
                purchaseOrderLine?.product_purchase_price
              )}`}</Label>
            </TextColumnContainer>
            <TextColumnContainer>
              <LabelTitle> {t('Expected Purchase Price')} </LabelTitle>
              <Label>{`${currencySymbol}${formatNumber(
                purchaseOrderLine?.expected_total_amount
              )}`}</Label>
            </TextColumnContainer>
            <TextColumnContainer gap="10rem">
              <LabelTitle flex="1"> {t('Line References')} </LabelTitle>
              <Label flex="1">
                <RefLabelsWrapper>
                  {formatPurchaseOrderLineReferences(
                    purchaseOrderLine.line_references
                  ).map((ref: string, index: number) => (
                    <RefLabel key={`line-ref-${index}`}>{ref}</RefLabel>
                  ))}
                </RefLabelsWrapper>
              </Label>
            </TextColumnContainer>
          </ColumnContainer>
        )}
        {isExpanded && (
          <ColumnContainer $isExpanded={isExpanded}>
            <H4>{t('Attributes').toUpperCase()}</H4>
            {renderProductAttributes()}
          </ColumnContainer>
        )}
      </MainContainer>
      <CostContainerAbsolute>
        <CostLabelTitle>{t('Total cost')}</CostLabelTitle>
        <CostLabel>{`${currencySymbol}${formatNumber(
          purchaseOrderLine.total_amount
        )}`}</CostLabel>
      </CostContainerAbsolute>
    </Card>
  );
};
