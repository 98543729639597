import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { changeQuotationType } from 'services/Quotation/QuotationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { QuotationTypeIdsEnum } from 'types/Quotations.types';
import { toast } from 'utils/toast';

export const QuotationTypeNameMap = {
  [QuotationTypeIdsEnum.DEFINITIVE]: 'DEFINITIVE',
  [QuotationTypeIdsEnum.DRAFT]: 'DRAFT',
};

export const useChangeQuotationType = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { quotationId: string; quotationTypeId: QuotationTypeIdsEnum }) =>
      changeQuotationType(params.quotationId, params.quotationTypeId),
    {
      onSuccess: (_, params) => {
        // 2nd argument of onSuccess is the parameter provided to the mutate function
        toast.success(t('Quotation updated'), {
          className: ReactMutationKeys.CHANGE_QUOTATION_TYPE,
        });

        queryClient.setQueryData(
          [ReactQueryKeys.GET_SINGLE_QUOTATION, params.quotationId.toString()],
          (oldData: any) => {
            if (oldData) {
              return {
                ...oldData,
                type_id: params.quotationTypeId,
                type: {
                  id: params.quotationTypeId,
                  name: QuotationTypeNameMap[params.quotationTypeId],
                },
              };
            }

            return oldData;
          }
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_QUOTATION_TYPE,
        });
      },
      onSettled: () => {
        // onSettled
      },
      mutationKey: ReactMutationKeys.CHANGE_QUOTATION_TYPE,
    }
  );
};
