import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { bulkBookPurchaseOrder } from 'services/PurchaseOrder/PurchaseOrderService';
import { IBulkBookData } from 'types/PurchaseOrders.types';

export const useBulkBookPurchaseOrder = ({ onSuccess }: any) => {
  const { t } = useTranslation();
  return useMutation(
    (bulkBookData: IBulkBookData) => bulkBookPurchaseOrder(bulkBookData),
    {
      onSuccess: () => {
        toast.success(t('Purchase order received'), {
          toastId: ReactMutationKeys.BULK_BOOK_PURCHASE_ORDER,
        });
        if (onSuccess) onSuccess();
        setTimeout(() => {
          queryClient.invalidateQueries([ReactQueryKeys.GET_PURCHASE_ORDERS]);
        }, 300);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.BULK_BOOK_PURCHASE_ORDER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.BULK_BOOK_PURCHASE_ORDER,
    }
  );
};
