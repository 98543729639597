import { useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getPermissionsGroups,
  updateUserPermissions,
} from 'services/Permissions/PermissionsService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IUserPermission } from './UserRolesPage';
import { queryClient } from 'index';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { IPermission, IUserRolesTableDTO } from 'types/User.types';

export type SetTransformedData = React.Dispatch<
  React.SetStateAction<IUserPermission[]>
>;

export const useGetPermissionsGroups = () => {
  return useQuery({
    queryKey: [ReactQueryKeys.GET_PERMISSIONS_GROUPS],
    queryFn: () => {
      return getPermissionsGroups();
    },
  });
};

export const useUpdateUserPermissions = () => {
  const { t } = useTranslation();
  return useMutation(
    (userPermissions: IUserPermission[]) =>
      updateUserPermissions(userPermissions),
    {
      onSuccess: () => {
        toast.success(t('Permissions updated successfully'), {
          toastId: ReactMutationKeys.UPDATE_USER_PERMISSIONS,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_COMPANY_USERS);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPDATE_USER_PERMISSIONS,
        });
      },
      mutationKey: ReactMutationKeys.UPDATE_USER_PERMISSIONS,
    }
  );
};

// If user already have some permissions he exist in transformedData.
// If we change the existed permission it will toggle checked value.
// If we add new permission it will push new object with the permissionId and checked value into permission array in transformedData.
// If user doesn't exist in transformedData (He don't have any permission yet) we create new object with userId and permission array and push it into transformedData.
export const usePermissionChange = (setTransformedData: SetTransformedData) => {
  const handlePermissionChange = useCallback(
    (userId: number, permissionId: number, checked: boolean) => {
      setTransformedData((currentData: IUserPermission[]) => {
        const updatedData = currentData.map((user) => ({ ...user }));
        const userIndex = updatedData.findIndex((u) => u.user_id === userId);

        if (userIndex !== -1) {
          const userPermissions = updatedData[userIndex].permissions;
          const permissionIndex = userPermissions.findIndex(
            (p) => p.id === permissionId
          );

          if (permissionIndex !== -1) {
            userPermissions[permissionIndex].checked = checked;
          } else {
            userPermissions.push({ id: permissionId, checked });
          }
        } else {
          updatedData.push({
            user_id: userId,
            permissions: [{ id: permissionId, checked }],
          });
        }
        return updatedData;
      });
    },
    [setTransformedData]
  );

  return handlePermissionChange;
};

// Work as usePermissionChange, only difference is that here we toggle only admin check box and according to that we add/remove all of other permissions and checkboxes for that user.
export const useAdminPermissionChange = (
  setTransformedData: SetTransformedData,
  setTableData: React.Dispatch<React.SetStateAction<IUserRolesTableDTO[]>>,
  allPermissions: IPermission[]
) => {
  const updateAdminPermissions = useCallback(
    (userId: number, checked: boolean) => {
      setTransformedData((currentData: IUserPermission[]) => {
        const updatedData = [...currentData];
        const userPermissions = updatedData.find(
          (u) => u.user_id === userId
        )?.permissions;

        if (userPermissions) {
          allPermissions.forEach((permission) => {
            const permIndex = userPermissions.findIndex(
              (p) => p.id === permission.id
            );
            if (permIndex !== -1) {
              userPermissions[permIndex].checked = checked;
            } else {
              userPermissions.push({ id: permission.id, checked });
            }
          });
        } else {
          updatedData.push({
            user_id: userId,
            permissions: allPermissions.map((p) => ({ id: p.id, checked })),
          });
        }

        return updatedData;
      });

      // Update tableData to have updated values on UI on every change
      setTableData((currentTableData) => {
        let isChanged = false;
        const updatedTableData = currentTableData.map((data) => {
          if (data.userId === userId) {
            const newPermissions = Object.keys(data).reduce((acc, key) => {
              if (key.endsWith('Permission')) {
                if (data[key].read !== checked || data[key].edit !== checked) {
                  isChanged = true;
                  acc[key] = {
                    ...data[key],
                    read: checked,
                    edit: checked,
                  };
                } else {
                  acc[key] = data[key];
                }
              }
              return acc;
            }, {});

            return isChanged ? { ...data, ...newPermissions } : data;
          }
          return data;
        });

        return isChanged ? updatedTableData : currentTableData;
      });
    },
    [setTransformedData, allPermissions, setTableData]
  );

  return updateAdminPermissions;
};
