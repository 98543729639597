import { IProductAttribute } from 'types/Product.types';
import { ICreateQuotationProductFormDTO } from '../constants';
import { DiscountType } from 'types/Discount.types';
import { Dispatch, SetStateAction } from 'react';

export const calculateTotalPurchasePriceForSingleProductFormDTO = (
  productFormDto: ICreateQuotationProductFormDTO
) => {
  const totalPurchasePrice = (
    Number(productFormDto.purchasePrice) * Number(productFormDto.quantity)
  )
    .toFixed(2)
    .toString();
  return isNaN(totalPurchasePrice as any) ? '0.00' : totalPurchasePrice;
};

export const calculateTotalSalesPriceForSingleProductFormDTO = (
  productFormDto: ICreateQuotationProductFormDTO
) => {
  const totalSalesPriceWithoutDiscount =
    Number(productFormDto.salesPrice) * Number(productFormDto.quantity);
  let totalSalesPriceWithDiscount;
  if (productFormDto.discountType === DiscountType.PERCENTAGE) {
    totalSalesPriceWithDiscount =
      totalSalesPriceWithoutDiscount *
      ((100 - Number(productFormDto.discount)) / 100);
  } else {
    totalSalesPriceWithDiscount =
      totalSalesPriceWithoutDiscount - Number(productFormDto.discount);
  }
  const totalSalesPriceForSingleProductDto = totalSalesPriceWithDiscount
    .toFixed(2)
    .toString();

  return isNaN(totalSalesPriceForSingleProductDto as any)
    ? '0.00'
    : totalSalesPriceForSingleProductDto;
};

export const getDropdownDefaultValue = (attribute: IProductAttribute) => {
  if (attribute.defaultOptionIndex === null || !attribute.options) {
    return undefined;
  }
  const option = attribute?.options[attribute.defaultOptionIndex];
  return {
    value: option,
    label: option,
    key: option,
  };
};

export const updateHiddenPurchasePriceProductFormDTOIndexes = (
  removedIndex: number,
  hiddenPurchasePriceProductFormDTOIndexes: number[],
  setHiddenPurchasePriceProductFormDTOIndexes: Dispatch<
    SetStateAction<number[]>
  >
) => {
  const updatedIndexes = hiddenPurchasePriceProductFormDTOIndexes.reduce(
    (acc: number[], current: number) => {
      // If the current index is greater than the removed index, decrement it
      if (current > removedIndex) {
        acc.push(current - 1);
      }
      // If the current index is less than the removed index, keep it as is
      else if (current < removedIndex) {
        acc.push(current);
      }
      // Do not include the removed index or adjust indexes equal to the removed one
      return acc;
    },
    []
  );

  // Update the state with the new list of indexes
  setHiddenPurchasePriceProductFormDTOIndexes(updatedIndexes);
};

export const togglePurchasePriceHidden = (
  index: number,
  hiddenPurchasePriceProductFormDTOIndexes: number[],
  setHiddenPurchasePriceProductFormDTOIndexes: Dispatch<
    SetStateAction<number[]>
  >
) => {
  if (hiddenPurchasePriceProductFormDTOIndexes.includes(index)) {
    setHiddenPurchasePriceProductFormDTOIndexes(
      hiddenPurchasePriceProductFormDTOIndexes.filter(
        (formIndex: number) => formIndex !== index
      )
    );
  } else {
    setHiddenPurchasePriceProductFormDTOIndexes([
      ...hiddenPurchasePriceProductFormDTOIndexes,
      index,
    ]);
  }
};
