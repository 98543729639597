import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  max-height: 80vh;
  align-items: center;

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const NoContentLabel = styled.label`
  margin-top: 100rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 390rem;
  width: 850rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  margin-bottom: 50rem;
  padding: 30rem;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 97rem;
  :nth-child(2) {
    padding-right: 102rem;
  }
  :last-child {
    padding-right: 0rem;
  }
`;

export const LabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 129rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;
export const Label = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
