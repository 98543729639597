export enum GuidePages {
  HOME = 'HOME',
  QUOTATIONS = 'QUOTATIONS',
  SINGLE_QUOTATION = 'SINGLE_QUOTATION',
  SINGLE_QUOTATION_PRODUCTS = 'SINGLE_QUOTATION_PRODUCTS',
  SINGLE_QUOTATION_COMMENTS = 'SINGLE_QUOTATION_COMMENTS',
  SINGLE_QUOTATION_QUOTATIONS = 'SINGLE_QUOTATION_QUOTATIONS',
  SINGLE_QUOTATION_SALES_ORDERS = 'SINGLE_QUOTATION_SALES_ORDERS',
  SINGLE_QUOTATION_APPOINTMENTS = 'SINGLE_QUOTATION_APPOINTMENTS',
  SINGLE_QUOTATION_REPORTS = 'SINGLE_QUOTATION_REPORTS',
  SINGLE_QUOTATION_FILES = 'SINGLE_QUOTATION_FILES',
  SALES_ORDERS = 'SALES_ORDERS',
  SINGLE_SALES_ORDER = 'SINGLE_SALES_ORDER',
  SINGLE_SALES_ORDER_PRODUCTS = 'SINGLE_SALES_ORDER_PRODUCTS',
  SINGLE_SALES_ORDER_COMMENTS = 'SINGLE_SALES_ORDER_COMMENTS',
  SINGLE_SALES_ORDER_QUOTATIONS = 'SINGLE_SALES_ORDER_QUOTATIONS',
  SINGLE_SALES_ORDER_INVOICES = 'SINGLE_SALES_ORDER_INVOICES',
  SINGLE_SALES_ORDER_PURCHASE_ORDERS = 'SINGLE_SALES_ORDER_PURCHASE_ORDERS',
  SINGLE_SALES_ORDER_APPOINTMENTS = 'SINGLE_SALES_ORDER_APPOINTMENTS',
  SINGLE_SALES_ORDER_REPORTS = 'SINGLE_SALES_ORDER_REPORTS',
  SINGLE_SALES_ORDER_FILES = 'SINGLE_SALES_ORDER_FILES',
  SINGLE_SALES_INVOICE = 'SINGLE_SALES_INVOICE',
  SINGLE_SALES_INVOICE_SALES_ORDERS = 'SINGLE_SALES_INVOICE_SALES_ORDERS',
  SINGLE_SALES_INVOICE_CREDIT_INVOICES = 'SINGLE_SALES_INVOICE_CREDIT_INVOICES',
  SINGLE_SALES_INVOICE_PAYMENTS = 'SINGLE_SALES_INVOICE_PAYMENTS',
  SINGLE_SALES_INVOICE_FILES = 'SINGLE_SALES_INVOICE_FILES',
  PLANNING = 'PLANNING',
  FIELD_WORKER = 'FIELD_WORKER',
  FIELD_WORKER_LOCATION = 'FIELD_WORKER_LOCATION',
  FIELD_WORKER_TIMESHEET = 'FIELD_WORKER_TIMESHEET',
  FIELD_WORKER_GENERAL = 'FIELD_WORKER_GENERAL',
  FIELD_WORKER_REPORT = 'FIELD_WORKER_REPORT',
}
