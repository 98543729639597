import { IPurchaseOrderFormDTO } from '../constants';

export const isAnyProductSelected = (
  purchaseOrderFormDTO: IPurchaseOrderFormDTO
) => {
  for (const purchaseOrderLine of purchaseOrderFormDTO.purchaseOrderLines) {
    if (purchaseOrderLine.isSelected) {
      return true;
    }
  }
  return false;
};
