import moment from 'moment';
import { ISignatureFormDTO } from './SingleSalesOrderNewSignatureForm/SingleSalesOrderNewSignatureForm';
import { ISalesOrder } from 'types/SalesOrders.types';

export const initializeSignatureFormDto = (salesOrder: ISalesOrder) => {
  const dto: ISignatureFormDTO = {
    salesOrderSignatureId: null,
    salesOrderId: Number(salesOrder.id),
    full_name: salesOrder.customer_snapshot
      ? salesOrder.customer_snapshot.first_name +
        ' ' +
        salesOrder.customer_snapshot.last_name
      : '',
    date: moment().format('YYYY-MM-DD'),
    appointmentId: null,
    type: null,
    signature: '',
  };
  return dto;
};

export const formatGetSignaturesResponseIntoInitialData = (
  salesOrderSignatures: any,
  salesOrderId: number
) => {
  if (!Array.isArray(salesOrderSignatures)) {
    return [];
  }
  const formDTOs: ISignatureFormDTO[] = [];
  for (const salesOrderSignature of salesOrderSignatures) {
    const formDTO: ISignatureFormDTO = {
      salesOrderSignatureId: salesOrderSignature.id,
      salesOrderId: salesOrderId,
      full_name: salesOrderSignature?.fullname,
      date: moment.utc(salesOrderSignature?.date).format('YYYY-MM-DD'),
      appointmentId: 1,
      type: salesOrderSignature.type,
      signature: salesOrderSignature.signature,
    };
    formDTOs.push(formDTO);
  }

  return formDTOs;
};
