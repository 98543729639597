import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  colors,
  defaultTemplate,
  DEFAULT_BODY_SIZE,
  DEFAULT_HEADLINE_SIZE,
} from './constants';
import { EmailTemplatesForm } from './EmailTemplatesForm/EmailTemplatesForm';
import {
  ApplyWrapper,
  ColorInputWrapper,
  ColorOption,
  ColorOptionsContainer,
  Container,
  ContentContainer,
  FontSelectInputWrapper,
  FontSizeWrapper,
  FontWrapper,
  HorizontalLine,
  InputLabel,
  Label,
  Title,
  Wrapper,
} from './EmailTemplatesPage.styled';
import {
  useCreateEmailTemplateGeneralTheme,
  useGetEmailTemplates,
  useGetEmailTemplateVariables,
  useGetFontOptions,
  useGetFontSizeOptions,
  useGetInitialTemplates,
  useGetSelectedFilterTypes,
  useGetTemplateDetails,
} from './hooks';
import { EmailTemplateColors } from './types';

export const EmailTemplatesPage = () => {
  const { t } = useTranslation();
  const fontOptions = useGetFontOptions();
  const fontSizeOptions = useGetFontSizeOptions();

  const { data, isLoading } = useGetEmailTemplates();
  const { data: variablesData } = useGetEmailTemplateVariables();

  const EmailTemplateTypeId: { [key: string]: number } = {};

  variablesData?.email_template_type_ids.forEach((templateType: any) => {
    EmailTemplateTypeId[templateType.name] = templateType.id;
  });

  const {
    draftQuotationTemplate,
    definitiveQuotationTemplate,
    salesOrderTemplate,
    salesInvoiceTemplate,
    salesCreditInvoiceTemplate,
    downPaymentInvoiceTemplate,
    signedQuotationTemplate,
  } = useGetInitialTemplates(data?.email_templates, EmailTemplateTypeId);

  const { mutate: createEmailTemplateGeneralTheme } =
    useCreateEmailTemplateGeneralTheme();

  const [selectedColor, setSelectedColor] = useState<string>('');
  const [fontFamily, setFontFamily] = useState<string>('');
  const [headerFontSize, setHeaderFontSize] = useState<string>(
    DEFAULT_HEADLINE_SIZE
  );
  const [bodyFontSize, setBodyFontSize] = useState<string>(DEFAULT_BODY_SIZE);

  useEffect(() => {
    if (!isLoading && data) {
      setSelectedColor(data?.email_template_general_data?.theme_color);
      setHeaderFontSize(data?.email_template_general_data?.headline_font_size);
      setBodyFontSize(data?.email_template_general_data?.font_size);
      const defaultFontFamily = data?.email_template_general_data?.font_family;

      if (defaultFontFamily.includes('sans-serif'))
        setFontFamily(defaultFontFamily.split(',')[0]);
      else setFontFamily(defaultFontFamily);
    }
  }, [data]);

  const {
    selectedDraftQuotationFilterType,
    selectedDefinitiveQuotationFilterType,
    selectedSalesOrderFilterType,
    selectedSalesInvoiceFilterType,
    selectedSalesCreditInvoiceFilterType,
    selectedDownPaymentInvoiceFilterType,
    selectedSignedQuotationFilterType,
    setSelectedDraftQuotationFilterType,
    setSelectedDefinitiveQuotationFilterType,
    setSelectedSalesOrderFilterType,
    setSelectedSalesInvoiceFilterType,
    setSelectedSalesCreditInvoiceFilterType,
    setSelectedDownPaymentInvoiceFilterType,
    setSelectedSignedQuotationFilterType,
  } = useGetSelectedFilterTypes();

  const {
    draftQuotationEmailDetails,
    definitiveQuotationEmailDetails,
    salesOrderEmailDetails,
    salesInvoiceEmailDetails,
    salesCreditInvoiceEmailDetails,
    downPaymentInvoiceEmailDetails,
    signedQuotationEmailDetails,
    setDraftQuotationEmailDetails,
    setDefinitiveQuotationEmailDetails,
    setSalesOrderEmailDetails,
    setSalesInvoiceEmailDetails,
    setSalesCreditInvoiceEmailDetails,
    setDownPaymentInvoiceEmailDetails,
    setSignedQuotationEmailDetails,
  } = useGetTemplateDetails();

  return (
    <Container>
      <Title>{t('Email templates')}</Title>
      <ContentContainer>
        <Label>{t('Theme')}</Label>
        <Wrapper>
          <ColorOptionsContainer>
            {colors.map((color: EmailTemplateColors, index: number) => {
              return (
                <ColorOption
                  key={index}
                  isSelected={color === selectedColor}
                  color={color}
                  onClick={() => setSelectedColor(color)}
                />
              );
            })}
          </ColorOptionsContainer>

          <ColorInputWrapper>
            <InputLabel>{`${t('Chosen color')}:`}</InputLabel>
            <ColorOption isSelected color={selectedColor} />
            <Input
              width={'100rem'}
              height="41rem"
              value={selectedColor}
              onChange={(e) => setSelectedColor(e.target.value)}
            />
          </ColorInputWrapper>
        </Wrapper>

        <FontWrapper>
          <Label>{t('Font family')}</Label>
          {fontFamily && (
            <FontSelectInputWrapper>
              <Select
                name="font_family"
                placeholder=""
                isMulti={false}
                isDisabled={false}
                isSearchable={false}
                defaultValue={fontOptions.find(
                  (font) => font.label === fontFamily
                )}
                onChange={(e: Option) => {
                  setFontFamily(e.value);
                }}
                options={fontOptions}
              />
            </FontSelectInputWrapper>
          )}
        </FontWrapper>

        <FontWrapper>
          <Label>{t('Header font size')}</Label>
          <FontSizeWrapper>
            {headerFontSize && (
              <FontSelectInputWrapper>
                <Select
                  name="header_font_size"
                  placeholder=""
                  isMulti={false}
                  isDisabled={false}
                  isSearchable={false}
                  defaultValue={fontSizeOptions.find(
                    (font) => font.value === headerFontSize
                  )}
                  onChange={(e: Option) => {
                    setHeaderFontSize(e.value);
                  }}
                  options={fontSizeOptions}
                  applyOptionStyle
                />
              </FontSelectInputWrapper>
            )}
          </FontSizeWrapper>
        </FontWrapper>

        <FontWrapper>
          <Label>{t('Body font size')}</Label>
          <FontSizeWrapper>
            {bodyFontSize && (
              <FontSelectInputWrapper>
                <Select
                  name="body_font_size"
                  placeholder=""
                  isMulti={false}
                  isDisabled={false}
                  isSearchable={false}
                  defaultValue={fontSizeOptions.find(
                    (font) => font.value === bodyFontSize
                  )}
                  onChange={(e: Option) => {
                    setBodyFontSize(e.value);
                  }}
                  options={fontSizeOptions}
                  applyOptionStyle
                />
              </FontSelectInputWrapper>
            )}
          </FontSizeWrapper>
        </FontWrapper>

        <ApplyWrapper>
          <Button
            disabled={
              !fontFamily || !bodyFontSize || !headerFontSize || !selectedColor
            }
            onClick={() => {
              createEmailTemplateGeneralTheme({
                theme_color: selectedColor,
                font_size: bodyFontSize,
                headline_font_size: headerFontSize,
                font_family: fontFamily,
              });
            }}
            primary
            width="200rem"
            label={t('Apply')}
          />
        </ApplyWrapper>

        <HorizontalLine />

        {variablesData?.email_template_variables && (
          <>
            <EmailTemplatesForm
              title={t('Draft quotation email')}
              selectedFilterType={selectedDraftQuotationFilterType}
              setSelectedFilterType={setSelectedDraftQuotationFilterType}
              emailTemplateTypeId={EmailTemplateTypeId.DRAFT_QUOTATION}
              emailTemplate={draftQuotationTemplate}
              details={draftQuotationEmailDetails}
              setDetails={setDraftQuotationEmailDetails}
              defaultHeadline={t(`${defaultTemplate.DRAFT_QUOTATION_HEADLINE}`)}
              defaultBody={t(`${defaultTemplate.DRAFT_QUOTATION_BODY}`)}
              emailTemplateVariables={
                variablesData?.email_template_variables[
                  EmailTemplateTypeId.DRAFT_QUOTATION
                ]
              }
            />

            <HorizontalLine />

            <EmailTemplatesForm
              title={t('Definitive quotation email')}
              selectedFilterType={selectedDefinitiveQuotationFilterType}
              setSelectedFilterType={setSelectedDefinitiveQuotationFilterType}
              emailTemplateTypeId={EmailTemplateTypeId.DEFINITIVE_QUOTATION}
              emailTemplate={definitiveQuotationTemplate}
              details={definitiveQuotationEmailDetails}
              setDetails={setDefinitiveQuotationEmailDetails}
              defaultHeadline={t(
                `${defaultTemplate.DEFINITIVE_QUOTATION_HEADLINE}`
              )}
              defaultBody={t(`${defaultTemplate.DEFINITIVE_QUOTATION_BODY}`)}
              emailTemplateVariables={
                variablesData?.email_template_variables[
                  EmailTemplateTypeId.DEFINITIVE_QUOTATION
                ]
              }
            />

            <HorizontalLine />

            <EmailTemplatesForm
              title={t('Order confirmation email')}
              selectedFilterType={selectedSalesOrderFilterType}
              setSelectedFilterType={setSelectedSalesOrderFilterType}
              emailTemplateTypeId={EmailTemplateTypeId.ORDER_CONFIRMATION}
              emailTemplate={salesOrderTemplate}
              details={salesOrderEmailDetails}
              setDetails={setSalesOrderEmailDetails}
              defaultHeadline={t(`${defaultTemplate.SALES_ORDER_HEADLINE}`)}
              defaultBody={t(`${defaultTemplate.SALES_ORDER_BODY}`)}
              emailTemplateVariables={
                variablesData?.email_template_variables[
                  EmailTemplateTypeId.ORDER_CONFIRMATION
                ]
              }
            />
          </>
        )}

        <HorizontalLine />

        <EmailTemplatesForm
          title={t('Sales invoice email')}
          selectedFilterType={selectedSalesInvoiceFilterType}
          setSelectedFilterType={setSelectedSalesInvoiceFilterType}
          emailTemplateTypeId={EmailTemplateTypeId.SALES_INVOICE}
          emailTemplate={salesInvoiceTemplate}
          details={salesInvoiceEmailDetails}
          setDetails={setSalesInvoiceEmailDetails}
          defaultHeadline={t(`${defaultTemplate.SALES_INVOICE_HEADLINE}`)}
          defaultBody={t(`${defaultTemplate.SALES_INVOICE_BODY}`)}
          emailTemplateVariables={
            variablesData?.email_template_variables[
              EmailTemplateTypeId.SALES_INVOICE
            ]
          }
        />

        <HorizontalLine />

        <EmailTemplatesForm
          title={t('Sales credit invoice email')}
          selectedFilterType={selectedSalesCreditInvoiceFilterType}
          setSelectedFilterType={setSelectedSalesCreditInvoiceFilterType}
          emailTemplateTypeId={EmailTemplateTypeId.SALES_CREDIT_INVOICE}
          emailTemplate={salesCreditInvoiceTemplate}
          details={salesCreditInvoiceEmailDetails}
          setDetails={setSalesCreditInvoiceEmailDetails}
          defaultHeadline={t(
            `${defaultTemplate.SALES_CREDIT_INVOICE_HEADLINE}`
          )}
          defaultBody={t(`${defaultTemplate.SALES_CREDIT_INVOICE_BODY}`)}
          emailTemplateVariables={
            variablesData?.email_template_variables[
              EmailTemplateTypeId.SALES_CREDIT_INVOICE
            ]
          }
        />

        <HorizontalLine />

        <EmailTemplatesForm
          title={t('Down payment invoice email')}
          selectedFilterType={selectedDownPaymentInvoiceFilterType}
          setSelectedFilterType={setSelectedDownPaymentInvoiceFilterType}
          emailTemplateTypeId={EmailTemplateTypeId.DOWN_PAYMENT_INVOICE}
          emailTemplate={downPaymentInvoiceTemplate}
          details={downPaymentInvoiceEmailDetails}
          setDetails={setDownPaymentInvoiceEmailDetails}
          defaultHeadline={t(
            `${defaultTemplate.DOWN_PAYMENT_INVOICE_HEADLINE}`
          )}
          defaultBody={t(`${defaultTemplate.DOWN_PAYMENT_INVOICE_BODY}`)}
          emailTemplateVariables={
            variablesData?.email_template_variables[
              EmailTemplateTypeId.DOWN_PAYMENT_INVOICE
            ]
          }
        />

        <HorizontalLine />

        <EmailTemplatesForm
          title={t('Signed quotation email')}
          selectedFilterType={selectedSignedQuotationFilterType}
          setSelectedFilterType={setSelectedSignedQuotationFilterType}
          emailTemplateTypeId={EmailTemplateTypeId.SIGNED_QUOTATION}
          emailTemplate={signedQuotationTemplate}
          details={signedQuotationEmailDetails}
          setDetails={setSignedQuotationEmailDetails}
          defaultHeadline={t(`${defaultTemplate.SIGNED_QUOTATION_HEADLINE}`)}
          defaultBody={t(`${defaultTemplate.SIGNED_QUOTATION_BODY}`)}
          emailTemplateVariables={
            variablesData?.email_template_variables[
              EmailTemplateTypeId.SIGNED_QUOTATION
            ]
          }
        />
      </ContentContainer>
    </Container>
  );
};
