import {
  LOGOUT,
  SET_PERMISSIONS_ACTIONS,
  SET_UPDATED_USER_INFO,
  SET_USER_INFO,
  SET_USER_INTRO_GUIDE_COMPLETED,
  SET_USER_INTRO_GUIDE_COMPLETED_PAGES,
  SET_USER_PICTURE,
} from '../constants';
import { IEditUserData, IUserData } from '../types';

export const setUserInfo = (userData: IUserData) => ({
  type: SET_USER_INFO,
  payload: userData,
});

export const setPermissionActions = (permissions: number[]) => ({
  type: SET_PERMISSIONS_ACTIONS,
  payload: permissions,
});

export const setUserPicture = (imageUrl: string) => ({
  type: SET_USER_PICTURE,
  payload: imageUrl,
});

export const setUserIntroGuideCompleted = (value: boolean) => ({
  type: SET_USER_INTRO_GUIDE_COMPLETED,
  payload: value,
});

export const setUserIntroGuideCompletedPages = (value: string[]) => ({
  type: SET_USER_INTRO_GUIDE_COMPLETED_PAGES,
  payload: value,
});

export const setUpdatedUserInfo = (userData: IEditUserData) => ({
  type: SET_UPDATED_USER_INFO,
  payload: userData,
});

export const logout = () => ({
  type: LOGOUT,
});
