import { useTranslation } from 'react-i18next';
import { IPurchaseOrderFormDTO } from '../constants';
import { isAnyProductSelected } from './helpers';
import {
  Container,
  FlexColumn,
  FormLabel,
  Line,
  PurchaseOrderLineContainer,
  PurchaseOrderLinesWrapper,
  SupplierCompanyNameLabel,
} from './Overview.styled';
import { COLORS } from 'assets/styled';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Info } from '@phosphor-icons/react';

interface IOverviewProps {
  purchaseOrderFormDTOs: IPurchaseOrderFormDTO[];
}

const Overview = ({ purchaseOrderFormDTOs }: IOverviewProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  return (
    <Container>
      {purchaseOrderFormDTOs
        .filter((purchaseOrderFormDTO) =>
          isAnyProductSelected(purchaseOrderFormDTO)
        )
        .map((purchaseOrderForm, purchaseOrderFormIndex) => {
          let selectedLineCount = 0;
          return (
            <div key={purchaseOrderFormIndex}>
              <SupplierCompanyNameLabel>
                {purchaseOrderForm.supplier.company_name}
              </SupplierCompanyNameLabel>
              <PurchaseOrderLinesWrapper>
                {purchaseOrderForm.purchaseOrderLines
                  .filter((purchaseOrderLine) => {
                    if (purchaseOrderLine.isSelected) {
                      selectedLineCount++;
                    }
                    return purchaseOrderLine.isSelected;
                  })
                  .map((purchaseOrderLine, purchaseOrderLineIndex) => {
                    return (
                      <FlexColumn
                        key={`${purchaseOrderForm.supplier.id}-${purchaseOrderLineIndex}`}
                      >
                        <PurchaseOrderLineContainer isSelected={false}>
                          <FormLabel color={COLORS.BLACK}>
                            {purchaseOrderLine.product.name}
                          </FormLabel>
                          <FormLabel color={COLORS.PRIMARY}>
                            {purchaseOrderLine.isStockItem
                              ? t('Stock item')
                              : t('Product')}
                          </FormLabel>
                          <FormLabel color={COLORS.BLACK}>
                            {purchaseOrderLine.quantity + 'x'}
                          </FormLabel>
                          <FormLabel color={COLORS.BLACK}>
                            {purchaseOrderLine.product.purchase_price}
                            {currencySymbol}
                          </FormLabel>
                          <Tooltip
                            content={t(
                              'This is the purchase price per unit excl. VAT'
                            )}
                          >
                            <Icon svg={Info} size={21} color={COLORS.PRIMARY} />
                          </Tooltip>
                        </PurchaseOrderLineContainer>
                        {selectedLineCount - 1 ===
                        purchaseOrderLineIndex ? null : (
                          <Line />
                        )}
                      </FlexColumn>
                    );
                  })}
              </PurchaseOrderLinesWrapper>
            </div>
          );
        })}
    </Container>
  );
};

export default Overview;
