import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import Button from 'components/Button/Button';
import {
  Wrapper,
  Heading,
  TopContentContainer,
  RowWrapper,
  LabelWrapper,
  LabelKey,
  Label,
  Container,
  ButtonContainer,
  SignatureWrapper,
  Clear,
  ExportPDF,
  ExportText,
  LoaderWrapper,
  SignatureExist,
  Message,
} from './SignQuotationPage.styled';
import { useLocation } from 'react-router-dom';
import {
  useCustomerExport,
  useGetSignatureInfo,
  useUploadCustomerSignature,
} from './hooks';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { changeLanguage } from 'utils/changeLanguage';
import { DownloadSimple } from '@phosphor-icons/react';

const SignQuotationPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const companyId = searchParams.get('company_id');
  const lang = searchParams.get('lang');

  const { t } = useTranslation();
  const sigRef = React.useRef() as React.MutableRefObject<any>;
  const [signature, setSignature] = useState(null);
  const [addedSignature, setAddedSignature] = useState(false);

  const { data: infoData, isLoading: isDataLoading } = useGetSignatureInfo(
    token!,
    companyId!
  );

  const { mutate: uploadCustomerSignature } = useUploadCustomerSignature(
    token!,
    companyId!,
    () => setAddedSignature(true)
  );

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  const { mutate: customerExport, isLoading: isLoadingExport } =
    useCustomerExport(token!, companyId!);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const submitSignature = async () => {
    const url = sigRef.current?.getCanvas().toDataURL('image/png');
    const blob = await fetch(url).then((r) => r.blob());

    if (blob) {
      uploadCustomerSignature({
        file: blob,
      });
    }
  };

  if (!lang && !token && !companyId) return <PageNotFound />;

  return (
    <Container className="sign-quotation">
      {isDataLoading ? (
        <Spinner size={50} />
      ) : (
        <Wrapper>
          <Heading>{t('Customer Signature')}</Heading>

          <TopContentContainer>
            <RowWrapper>
              <LabelWrapper>
                <LabelKey>{t('Quotation')}</LabelKey>
                <Label>{infoData?.quotation?.number}</Label>
              </LabelWrapper>
              <LabelWrapper>
                <LabelKey>{t('Date')}</LabelKey>
                <Label>
                  {moment(infoData?.quotation?.created_at).format('DD-MM-YYYY')}
                </Label>
              </LabelWrapper>
            </RowWrapper>
            <RowWrapper>
              <LabelWrapper>
                <LabelKey>{t('Customer name')}</LabelKey>
                <Label>
                  {infoData?.quotation?.customer?.name}{' '}
                  {infoData?.quotation?.customer?.last_name}
                </Label>
              </LabelWrapper>
              <LabelWrapper>
                <LabelKey>{t('Total amount')}</LabelKey>
                <Label>{`${infoData?.company_currency?.symbol}${formatNumber(
                  infoData?.quotation?.total_amount
                )}`}</Label>
              </LabelWrapper>
            </RowWrapper>
          </TopContentContainer>

          <ExportPDF>
            {isLoadingExport ? (
              <LoaderWrapper>
                <Spinner size={20} />
              </LoaderWrapper>
            ) : (
              <Icon
                data-testid="download-pdf"
                svg={DownloadSimple}
                size={25}
                color={COLORS.PRIMARY}
                marginRight={marginXs}
                marginLeft={marginXs}
                onClick={() => customerExport()}
              />
            )}
            <ExportText onClick={() => customerExport()}>
              {t('Export PDF')}
            </ExportText>
          </ExportPDF>

          {!infoData?.signature_file && !addedSignature && (
            <>
              <SignatureWrapper data-testid="signature-canvas-quotation">
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: 'signature' }}
                  ref={sigRef}
                  onEnd={handleSignatureEnd}
                  clearOnResize={false}
                />
              </SignatureWrapper>
              <Clear onClick={clearSignature}>{t('Clear')}</Clear>
              <ButtonContainer>
                <Button width="200rem" secondary label={t('Cancel')} />
                <Button
                  width="200rem"
                  disabled={!signature}
                  primary
                  label={t('Confirm')}
                  onClick={submitSignature}
                />
              </ButtonContainer>
            </>
          )}

          {infoData?.signature_file && (
            <SignatureExist>
              <Message>{t('Signature already exists')}</Message>
            </SignatureExist>
          )}

          {addedSignature && !infoData?.signature_file && (
            <SignatureExist>
              <Message>{t('Signature is successfully added')}</Message>
            </SignatureExist>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default SignQuotationPage;
