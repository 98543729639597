import moment from 'moment';
import { FC } from 'react';
import {
  AuthorName,
  CommentDate,
  CommentDescription,
  CommentInfo,
  CommentType,
  ProfileImage,
  CommentCardContainer,
  CommentHeadline,
} from './CommentCard.styled';
import Icon from 'components/Icon/Icon';
import { User } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ICommentCard {
  title: string;
  name: string;
  type: string;
  date: string;
  description: string;
  imageUrl?: string;
}

const CommentCard: FC<ICommentCard> = ({
  title,
  name,
  type,
  date,
  description,
  imageUrl,
}) => {
  return (
    <CommentCardContainer>
      <CommentInfo>
        <CommentHeadline>{title}</CommentHeadline>
        <CommentType>{type}</CommentType>
      </CommentInfo>
      <CommentInfo>
        {imageUrl ? (
          <ProfileImage alt="" src={imageUrl} />
        ) : (
          <Icon svg={User} color={COLORS.PRIMARY} size={30} />
        )}
        <AuthorName>{name}</AuthorName>
        <CommentDate>
          {moment.utc(date).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm')}
        </CommentDate>
      </CommentInfo>
      <CommentDescription>{description}</CommentDescription>
    </CommentCardContainer>
  );
};

export default CommentCard;
