import { useQuery } from 'react-query';
import {
  getFwAppointmentInfoById,
  getSingleFwAppointment,
} from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSingleFwAppointment = (appointmentId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT, appointmentId],
    queryFn: () => {
      return getSingleFwAppointment(appointmentId);
    },
    enabled: !!appointmentId,
  });

export const useGetSingleFwAppointmentInfo = (appointmentId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT_INFO, appointmentId],
    queryFn: () => {
      return getFwAppointmentInfoById(appointmentId);
    },
  });
