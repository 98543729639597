export interface ICreatePurchaseOrderItem {
  item: any;
  quantity: string;
  price: string;
}

export const newEmptyItem: ICreatePurchaseOrderItem = {
  item: '',
  quantity: '',
  price: '',
};

export const STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER_PER_PAGE = 10;
