export const formatOptionError = (error: string) => {
  if (!error) {
    return;
  }
  const optionErrorParts: string[] = error.split(' ');
  const firstErrorPartParts = optionErrorParts[0].split('.');
  optionErrorParts.shift();
  return (
    firstErrorPartParts[0].substring(0, 6) +
    ' ' +
    (Number(firstErrorPartParts[1]) + 1) +
    ' ' +
    optionErrorParts.join(' ')
  );
};
