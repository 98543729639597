import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

const useCan = (permission: any) => {
  const permissionActions = useSelector(
    (state: IRootReducerState) => state.userInfo.permissionActions
  );

  const allowed = useMemo(() => {
    if (!permissionActions) return false;

    let isAllowed = false;

    if (permission) {
      isAllowed = !!permissionActions?.find((p: number) => p === permission);
    }

    return isAllowed;
  }, [permission, permissionActions]);

  return allowed;
};

export default useCan;
