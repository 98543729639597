import { FileType } from 'components/Modal/EditFileNameModal/constants';
import { queryClient } from 'index';
import { useDeleteAppointmentFile } from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentFilesPage/hooks';
import { useDeletePurchaseInvoiceFile } from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoiceFilesPage/hooks';
import { useDeleteSalesInvoiceFile } from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceFilesPage/hooks';
import { useDeletePurchaseOrderFile } from 'pages/Manager/SinglePurchaseOrder/hooks';
import { useDeleteQuotationFile } from 'pages/Manager/SingleQuotation/hooks';
import { useDeleteSalesOrderFile } from 'pages/Manager/SingleSalesOrder/hooks';
import { Dispatch, SetStateAction } from 'react';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IDeleteFileDTO {
  fileId: string;
  entityId: string;
  entityType: string;
}

export const useHandleDeleteFile = (
  setFileToDelete: Dispatch<SetStateAction<IDeleteFileDTO | null>>,
  isDeleteFileModalOpen: boolean,
  setIsDeleteFileModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const { mutate: deleteQuotationFile } = useDeleteQuotationFile();
  const { mutate: deleteSalesOrderFile } = useDeleteSalesOrderFile();
  const { mutate: deleteSalesInvoiceFile } = useDeleteSalesInvoiceFile();
  const { mutate: deleteAppointmentFile } = useDeleteAppointmentFile();
  const { mutate: deletePurchaseOrderFile } = useDeletePurchaseOrderFile();
  const { mutate: deletePurchaseInvoiceFile } = useDeletePurchaseInvoiceFile();

  const handleDeleteFile = (deleteFileDTO: IDeleteFileDTO | null) => {
    if (!deleteFileDTO) {
      return;
    }
    if (!isDeleteFileModalOpen) {
      setFileToDelete(deleteFileDTO);
      setIsDeleteFileModalOpen(true);
      return;
    }
    const { fileId, entityId, entityType } = deleteFileDTO;
    switch (entityType) {
      case FileType.QUOTATION:
        deleteQuotationFile({
          quotationId: entityId,
          quotationFileId: fileId,
        });
        break;
      case FileType.SALES_ORDER:
        deleteSalesOrderFile({
          salesOrderId: entityId,
          salesOrderFileId: fileId,
        });
        break;
      case FileType.SALES_INVOICE:
        deleteSalesInvoiceFile({
          salesInvoiceId: entityId,
          salesInvoiceFileId: fileId,
        });
        break;
      case FileType.APPOINTMENT:
        deleteAppointmentFile({
          appointmentId: entityId,
          fileId: fileId,
        });
        break;
      case FileType.PURCHASE_ORDER:
        deletePurchaseOrderFile({
          purchaseOrderId: entityId,
          purchaseOrderFileId: fileId,
        });
        break;
      case FileType.PURCHASE_INVOICE:
        deletePurchaseInvoiceFile({
          purchaseInvoiceId: entityId,
          purchaseInvoiceFileId: fileId,
        });
        break;
      default:
        break;
    }
    setIsDeleteFileModalOpen(false);
  };
  return handleDeleteFile;
};

export const invalidateFileQueries = (shouldInvalidateInfoQueries = true) => {
  // File fetching queries
  queryClient.invalidateQueries([ReactQueryKeys.GET_QUOTATION_FILES]);
  queryClient.invalidateQueries([ReactQueryKeys.GET_SALES_ORDER_FILES]);
  queryClient.invalidateQueries([ReactQueryKeys.GET_SALES_INVOICE_FILES]);
  queryClient.invalidateQueries([ReactQueryKeys.GET_FW_APPOINTMENT_FILES]);
  queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDER_FILES);
  queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_INVOICE_FILES);

  if (shouldInvalidateInfoQueries) {
    // File count info queries (exclamation mark next to comments and files in SubSideMenu)
    queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_QUOTATION_INFO]);
    queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_ORDER_INFO);
    queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE_INFO);
    queryClient.invalidateQueries(
      ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT_INFO
    );

    queryClient.invalidateQueries(
      ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_INFO
    );
    queryClient.invalidateQueries(
      ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE_INFO
    );
  }
};
