import FullCalendar from '@fullcalendar/react'; // Must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import moment, { Moment } from 'moment';
import { useRef, useState } from 'react';
import {
  Container,
  Header,
  CalendarWrapper,
  TopHeader,
  ButtonContainer,
  PlanningLabel,
  TitleWrapper,
  LoaderWrapper,
  RelativeDiv,
  TopContainer,
  CardColumnWrapper,
  WideCardColumnWrapper,
} from './HomePage.styled';
import { Card } from 'pages/Manager/HomePage/Card/Card';
import Notifications from './Notifications/Notifications';
import { useNavigate } from 'react-router-dom';
import { formatAppointmentEventData } from 'pages/Manager/PlanningPage/helpers';
import { useTranslation } from 'react-i18next';
import {
  useGetQuotationInfo,
  useGetSalesInfo,
  useGetPurchaseInfo,
  useGetStockInfo,
  useGetSuppliersInfo,
  useGetCustomersInfo,
  useGetEmailsInfo,
} from './hooks';
import { EventContentArg } from '@fullcalendar/core';
import Event from 'pages/Manager/PlanningPage/CustomEvent/Event';
import CalendarDateIntervalHeader from 'components/Calendar/CalendarDateIntervalHeader';
import { PreviewDayAppointmentsModal } from 'components/Modal/PreviewDayAppointmentsModal/PreviewDayAppointmentsModal';
import { RoutesConfig } from 'navigation/routes';
import Loader from 'components/Loader/Loader';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import {
  Cardholder,
  ShoppingCart,
  CalendarBlank,
  Cards as CardsIcon,
  UsersThree,
  Handshake,
  Envelope,
  Cube,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import MoreButton from 'components/MoreButton/MoreButton';
import {
  useGetAppointments,
  useUpdateCalendarShortDayNamesToResolvedLocale,
} from '../PlanningPage/PlanningTab/hooks';
import { WorkersAndResourcesCard } from './WorkersAndResourcesCard/WorkersAndResourcesCard';
import { WideCard } from './WideCard/WideCard';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import ViewAppointmentModal from '../PlanningPage/ViewAppointmentModal/ViewAppointmentModal';

const HomePage = () => {
  const calendarRef = useRef<FullCalendar>(null);
  const [startDate, setStartDate] = useState<Moment>(moment());
  const [endDate, setEndDate] = useState<Moment>(moment());
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [appointmentId, setAppointmentId] = useState<string>('');
  const [isEditAppointmentModalOpen, setIsEditisNewAppointmentModalOpenOpen] =
    useState<boolean>(false);

  const canReadPlanning = useCan(Actions.READ_APPOINTMENT);
  const canReadSales = useCan(Actions.READ_SALES_ORDER);
  const canReadQuotations = useCan(Actions.READ_QUOTATION);
  const canReadPurchases = useCan(Actions.READ_PURCHASE_ORDER);
  const canReadStockItems = useCan(Actions.READ_STOCK_ITEM);

  const [dateForDaySummary, setDateForDaySummary] = useState<Moment | null>(
    null
  );

  const { data, isFetching: isFetchingAppointments } = useGetAppointments(
    startDate,
    endDate
  );

  const { data: infoQuotation } = useGetQuotationInfo();
  const { data: infoSales } = useGetSalesInfo();
  const { data: infoPurchase } = useGetPurchaseInfo();
  const { data: infoStockItem, isLoading: isLoadingInfoStockItem } =
    useGetStockInfo();
  const { data: infoSuppliers } = useGetSuppliersInfo();
  const { data: infoCustomers } = useGetCustomersInfo();
  const { data: infoEmails, isLoading: isLoadingInfoEmails } =
    useGetEmailsInfo();

  const appointmentEvents = formatAppointmentEventData(data?.appointments);

  const renderEventContent = (eventInfo: EventContentArg) => {
    return <Event content={eventInfo} />;
  };

  useUpdateCalendarShortDayNamesToResolvedLocale(
    startDate,
    endDate,
    setDateForDaySummary
  );

  const { steps } = useRunTour(GuidePages.HOME);
  const currencySymbol = useGetCurrencySymbol();

  const handleAppointmentEventCardClick = (id: string) => {
    setAppointmentId(id);
    setIsEditisNewAppointmentModalOpenOpen(true);
  };

  return (
    <Container className="home-step-1">
      <TopContainer>
        <CardColumnWrapper>
          <Card
            title={t('Quotations')}
            route={RoutesConfig.Quotations.fullPath}
            leftValue={infoQuotation?.ongoing}
            rightValue={infoQuotation?.recently_closed}
            icon={CardsIcon}
            permissionToSee={canReadQuotations}
          />
          <Card
            title={t('Sales')}
            route={RoutesConfig.SalesOrders.fullPath}
            leftValue={infoSales?.ongoing}
            rightValue={infoSales?.recently_closed}
            icon={Cardholder}
            permissionToSee={canReadSales}
          />
          <Card
            title={t('Purchases')}
            route={RoutesConfig.PurchaseOrders.fullPath}
            leftValue={infoPurchase?.ongoing}
            rightValue={infoPurchase?.recently_closed}
            icon={ShoppingCart}
            permissionToSee={canReadPurchases}
          />
          <Card
            title={t('Customers')}
            route={RoutesConfig.Customers.fullPath}
            leftValue={infoCustomers?.customers.length}
            rightValue={infoCustomers?.customers[1]?.ongoing_quotations_count}
            icon={UsersThree}
            permissionToSee={canReadPurchases}
            leftLabel={t('Number')}
            rightLabel={t('Ongoing orders')}
          />
          <Card
            title={t('Suppliers')}
            route={RoutesConfig.Suppliers.fullPath}
            leftValue={infoSuppliers?.suppliers.length}
            rightValue={
              infoSuppliers?.suppliers[1]?.ongoing_purchase_order_count
            }
            icon={Handshake}
            permissionToSee={canReadPurchases}
            leftLabel={t('Number')}
            rightLabel={t('Ongoing orders')}
          />
        </CardColumnWrapper>
        <WideCardColumnWrapper>
          <Notifications />
          <WideCard
            title={t('Emails')}
            icon={Envelope}
            data={[
              { label: t('Total'), number: infoEmails?.total },
              { label: t('Delivered'), number: infoEmails?.delivered },
              { label: t('Failed'), number: infoEmails?.failed },
            ]}
            isLoading={isLoadingInfoEmails}
          />
          <WorkersAndResourcesCard />
          <WideCard
            title={t('Stock Items')}
            icon={Cube}
            route={RoutesConfig.StockItems.fullPath}
            data={[
              { label: t('In stock'), number: infoStockItem?.total_in_stock },
              {
                label: t('Total value'),
                number: infoStockItem?.total_stock_value,
                currencySymbol: currencySymbol,
              },
              { label: t('Units sold'), number: infoStockItem?.units_sold },
            ]}
            permissionToSee={canReadStockItems}
            isLoading={isLoadingInfoStockItem}
          />
        </WideCardColumnWrapper>
      </TopContainer>

      {canReadPlanning && (
        <CalendarWrapper>
          <TopHeader>
            <TitleWrapper>
              <Icon
                svg={CalendarBlank}
                pointer={false}
                size={30}
                color={COLORS.PRIMARY}
              />
              <PlanningLabel>{t('Planning')}</PlanningLabel>
            </TitleWrapper>
            <ButtonContainer
              onClick={() => navigate(RoutesConfig.Planning.fullPath)}
            >
              <MoreButton />
            </ButtonContainer>
          </TopHeader>
          <Header>
            <CalendarDateIntervalHeader
              startDate={startDate}
              endDate={endDate}
              next={() => calendarRef.current?.getApi()?.next()}
              prev={() => calendarRef.current?.getApi()?.prev()}
            />
          </Header>
          <RelativeDiv className={'homepage'}>
            {isFetchingAppointments ? (
              <LoaderWrapper>
                <Loader positionType="relative" />
              </LoaderWrapper>
            ) : null}
            <FullCalendar
              ref={calendarRef}
              firstDay={1}
              allDaySlot={false}
              slotMinTime="06:00:00"
              slotMaxTime="22:00:00"
              events={appointmentEvents}
              slotEventOverlap={false}
              nowIndicator={true}
              eventContent={(content: EventContentArg) =>
                renderEventContent(content)
              }
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              eventClick={(e) => {
                handleAppointmentEventCardClick(e.event.id);
              }}
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }}
              plugins={[timeGridPlugin]}
              initialView={'timeGridWeek'}
              headerToolbar={false}
              dayHeaderFormat={(param) => {
                return param.date.marker.toString().split(' ')[0];
              }}
              datesSet={(arg) => {
                setStartDate(moment(arg.view.activeStart));
                setEndDate(moment(arg.view.activeEnd));
              }}
              height="auto"
            />
          </RelativeDiv>
        </CalendarWrapper>
      )}
      <PreviewDayAppointmentsModal
        dateForDaySummary={dateForDaySummary}
        setDateForDaySummary={setDateForDaySummary}
      />
      <IntroductionGuide steps={steps} />

      {isEditAppointmentModalOpen && (
        <ViewAppointmentModal
          isOpen={isEditAppointmentModalOpen}
          setIsOpen={setIsEditisNewAppointmentModalOpenOpen}
          id={appointmentId}
          viewOnly={true}
        />
      )}
    </Container>
  );
};

export default HomePage;
