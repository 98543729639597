import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { editQuotationFile } from 'services/Quotation/QuotationService';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';

export const useEditQuotationFileName = (
  quotationId: string,
  fileId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (newFileName: string) =>
      editQuotationFile(quotationId, fileId, newFileName),
    {
      onSuccess: () => {
        toast.success(t('File name updated'), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_FILE_NAME,
          className: ReactMutationKeys.EDIT_QUOTATION_FILE_NAME,
        });
        const shouldInvalidateInfoQueries = false;
        invalidateFileQueries(shouldInvalidateInfoQueries);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_FILE_NAME,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_QUOTATION_FILE_NAME,
    }
  );
};
