import { useMutation, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addCommentForSupplier,
  deleteCommentForSupplier,
  getSingleSupplier,
  getSupplierComments,
  getSupplierInfoById,
} from 'services/Supplier/SupplierService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  activateSupplier,
  deleteSupplier,
  editSupplier,
  getSupplierPurchaseOrders,
  getSupplierQuotations,
} from 'services/Supplier/SupplierService';
import { IComment } from '../SingleCustomerPage/types';

export const useGetSingleSupplier = (supplierId: string, enabled = true) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SUPPLIER, supplierId?.toString()],
    queryFn: () => {
      return getSingleSupplier(supplierId);
    },
    enabled: enabled && !!supplierId,
  });

// Unused
export const useGetSingleSupplierQuotations = (supplierId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SUPPLIER_QUOTATIONS, supplierId],
    queryFn: () => {
      return getSupplierQuotations(supplierId);
    },
  });

export const useGetSingleSupplierPurchaseOrders = (
  page: number,
  perPage: number,
  supplierId: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SUPPLIER_PURCHASE_ORDERS,
      page,
      perPage,
      supplierId,
    ],
    queryFn: () => {
      return getSupplierPurchaseOrders(page, perPage, supplierId);
    },
  });

export const useEditSupplier = (supplierId: string) => {
  const { t } = useTranslation();

  return useMutation(
    (editSupplierData: any) => editSupplier(supplierId, editSupplierData),
    {
      onSuccess: () => {
        // Success
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SUPPLIER]);
        toast.success(t('Successfully edited supplier'), {
          className: ReactMutationKeys.EDIT_SUPPLIER,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SUPPLIER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SUPPLIER,
    }
  );
};

export const useDeleteSupplier = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteSupplier(id), {
    onSuccess: () => {
      toast.success(t('Supplier deactivated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_SUPPLIER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SUPPLIER);
      queryClient.invalidateQueries(ReactQueryKeys.GET_SUPPLIERS);
    },
    mutationKey: ReactMutationKeys.DELETE_SUPPLIER,
  });
};

export const useActivateSupplier = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => activateSupplier(id), {
    onSuccess: () => {
      toast.success(t('Supplier activated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ACTIVATE_SUPPLIER,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SUPPLIER);
      queryClient.invalidateQueries(ReactQueryKeys.GET_SUPPLIERS);
    },
    mutationKey: ReactMutationKeys.ACTIVATE_SUPPLIER,
  });
};

export const useGetSingleSupplierComments = (
  supplierId: string,
  isModalOpen: boolean,
  page?: number,
  perPage?: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SUPPLIER_COMMENTS,
      supplierId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getSupplierComments(supplierId, page, perPage);
    },
    enabled: !!isModalOpen && !!supplierId,
  });

export const useAddSupplierComment = (supplierId: string) =>
  useMutation(
    (commentDTO: IComment) => addCommentForSupplier(supplierId, commentDTO),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_SUPPLIER_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SUPPLIER_COMMENTS
        );
      },
      mutationKey: ReactMutationKeys.ADD_SUPPLIER_COMMENT,
    }
  );

export const useDeleteSupplierComment = (supplierId: string) =>
  useMutation(
    (commentId: string) => deleteCommentForSupplier(supplierId, commentId),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_SUPPLIER_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SUPPLIER_COMMENTS
        );
      },
      mutationKey: ReactMutationKeys.DELETE_SUPPLIER_COMMENT,
    }
  );

export const useGetSingleSupplierInfo = (supplierId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SUPPLIER_INFO, supplierId],
    queryFn: () => {
      return getSupplierInfoById(supplierId);
    },
  });
