import { Option } from 'components/Select/type';

export const useGetInvoiceTypeOptions = () => {
  const invoiceTypeOptions: Option[] = [
    { value: 'SALES_INVOICE', label: 'Sales invoices', key: '1' },
    { value: 'PURCHASE_INVOICE', label: 'Purchase invoices', key: '2' },
  ];

  return invoiceTypeOptions;
};
