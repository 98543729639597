import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  Title,
  TitleContainer,
  SearchContainer,
  CloseContainer,
  AddMoreContainer,
  WorkerContainer,
  WorkerDescription,
  WorkerName,
  ButtonContainer,
  Margin,
  MarginTop,
  ClearButton,
  DataWrapper,
  DataContainer,
} from './WorkersModal.styled';
import AddWorkerForm from './AddWorkerForm/AddWorkerForm';
import Pagination from 'components/Table/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { EditingType, IWorkerAndResource } from './type';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useGetWorkers, useDeleteWorkers } from './hooks';
import { EditWorkerOrResourceModal } from '../EditWorkerOrResourceModal/EditWorkerOrResourceModal';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import {
  MagnifyingGlass,
  PencilSimple,
  Plus,
  Trash,
} from '@phosphor-icons/react';

const WORKERS_PER_PAGE = 4;

export interface IWorkersModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedWorkers: (workersIds: string[]) => void;
  selectedWorkers: string[];
}

const WorkersModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  setSelectedWorkers,
  selectedWorkers,
}: IWorkersModalProps) => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedWorker, setSelectedWorker] = useState<IWorkerAndResource>();
  const [page, setPage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('');
  const [localWorkers, setLocalWorkers] = useState<string[]>(selectedWorkers);
  const canEditPlanning = useCan(Actions.EDIT_APPOINTMENT);
  const { t } = useTranslation();
  const { data: workersData } = useGetWorkers(
    page,
    WORKERS_PER_PAGE,
    searchBy,
    undefined,
    undefined,
    isOpen
  );

  const { mutate: deleteWorker, isSuccess } = useDeleteWorkers();

  useEffect(() => {
    setOpenDeleteModal(false);
  }, [isSuccess]);

  const resetSearchBy = () => {
    setSearchBy('');
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={resetSearchBy}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <div>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
              setSearchBy('');
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Workers')}</Title>
        </TitleContainer>
        <SearchContainer>
          <Input
            icon={MagnifyingGlass}
            width={'300rem'}
            placeholder={t('Search')}
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
          <ClearButton
            onClick={() => {
              setLocalWorkers([]);
            }}
          >
            {t('Clear')}
          </ClearButton>
          {canEditPlanning && (
            <AddMoreContainer>
              <Button
                label={t('Add more')}
                link
                icon={Plus}
                weightIcon="regular"
                colorIcon={COLORS.PRIMARY}
                sizeIcon={15}
                onClick={() => setOpenAddModal(true)}
                fontSize="16rem"
              />
            </AddMoreContainer>
          )}
        </SearchContainer>
        {workersData?.workers?.map((worker: IWorkerAndResource) => {
          const isSelected = localWorkers.includes(worker.id);
          return (
            <WorkerContainer selected={isSelected} key={worker.id}>
              <DataContainer>
                <DataWrapper>
                  <WorkerName>{worker.name}</WorkerName>
                  <WorkerDescription>{worker.description}</WorkerDescription>
                </DataWrapper>
                <AddMoreContainer>
                  <Button
                    data-testid={
                      isSelected
                        ? `unselect-button-${worker.name}`
                        : `select-button-${worker.name}`
                    }
                    onClick={() => {
                      isSelected
                        ? setLocalWorkers(
                            localWorkers.filter((id) => id !== worker.id)
                          )
                        : setLocalWorkers([...localWorkers, worker.id]);
                    }}
                    width={'100rem'}
                    secondary
                    label={isSelected ? t('Unselect') : t('Select')}
                  />
                  <Icon
                    svg={PencilSimple}
                    color={COLORS.PRIMARY}
                    size={20}
                    marginLeft={marginXs}
                    data-testid={`edit-icon-${worker.name}`}
                    onClick={() => {
                      setSelectedWorker(worker);
                      setOpenEditModal(true);
                    }}
                  />
                  <Icon
                    data-testid={`trash-icon-${worker.name}`}
                    svg={Trash}
                    color={COLORS.RED_200}
                    size={20}
                    weight="regular"
                    marginLeft={marginXs}
                    onClick={() => {
                      setDeleteId(worker.id);
                      setOpenDeleteModal(true);
                    }}
                  />
                </AddMoreContainer>
              </DataContainer>
            </WorkerContainer>
          );
        })}
        <MarginTop>
          {workersData?.total > WORKERS_PER_PAGE && (
            <Pagination
              page={page}
              perPage={WORKERS_PER_PAGE}
              total={workersData?.total}
              loadPage={(newPage) => setPage(newPage)}
            />
          )}
        </MarginTop>
        <ButtonContainer>
          <Button
            width={'200rem'}
            secondary
            onClick={() => {
              onCancel();
              resetSearchBy();
            }}
            label={t('Cancel')}
          />
          <Margin>
            <Button
              onClick={() => {
                setSelectedWorkers(localWorkers);
                setIsOpen(false);
                resetSearchBy();
              }}
              width={'200rem'}
              primary
              label={t('Apply')}
            />
          </Margin>
        </ButtonContainer>
        <Modal
          level={'SECOND'}
          isOpen={openAddModal}
          setIsOpen={setOpenAddModal}
          modalStyle={{ position: 'fixed', margin: 'auto' }}
        >
          <AddWorkerForm onCancel={() => setOpenAddModal(false)} />
        </Modal>
        <Modal
          level={'SECOND'}
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          modalStyle={{ position: 'fixed', margin: 'auto' }}
        >
          <Danger
            submit={{
              onClick: () => {
                deleteWorker(deleteId);
              },
              text: 'Delete',
              disabled: false,
            }}
            cancel={{ onClick: () => setOpenDeleteModal(false) }}
            title={t('Delete Worker')}
            description={t('Are you sure you want to delete this worker') + '?'}
          />
        </Modal>
        <EditWorkerOrResourceModal
          isOpen={openEditModal}
          setIsOpen={setOpenEditModal}
          onCancel={() => setOpenEditModal(false)}
          entity={selectedWorker!}
          type={EditingType.WORKER}
        />
      </div>
    </Modal>
  );
};

export default WorkersModal;
