import { t } from 'i18next';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { useLocation } from 'react-router-dom';
import { useGetPaymentInfo } from './hooks';
import {
  Container,
  LoadingPaymentInfoLabel,
  PaymentLabel,
} from './PaymentSuccessfulPage.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useEffect } from 'react';
import { changeLanguage } from 'utils/changeLanguage';
import Loader from 'components/Loader/Loader';
import { ERPError } from 'services/api/errors';
import { Check } from '@phosphor-icons/react';

enum PaymentError {
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  NOT_FOUND = 'The resource could not be found.',
}

const PaymentSuccessfulPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const paymentIntentId = searchParams.get('payment_intent_id');
  const companyId = searchParams.get('company_identifier');
  const lang = searchParams.get('lang');
  const errorMessage = searchParams.get('error_message');
  const salesInvoiceNumber = searchParams.get('sales_invoice_number');

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  const token = searchParams.get('token');

  const {
    data,
    error,
    isLoading: isLoadingSalesInvoiceWithPaymentInfo,
  } = useGetPaymentInfo(paymentIntentId!, token!, companyId!);

  const parsedErrorMessage: PaymentError | undefined = (error as ERPError)
    ?.response?.data?.message as PaymentError;

  return (
    <>
      {lang && token && paymentIntentId && companyId ? (
        <Container>
          {data?.data && (
            <>
              <Icon svg={Check} color={COLORS.BLACK} size={60} weight="bold" />
              <PaymentLabel>
                {`${t('Invoice')} ${
                  data?.data?.sales_invoice?.invoice_number
                } ${t('has been paid successfully')}!`}
              </PaymentLabel>
            </>
          )}
          {isLoadingSalesInvoiceWithPaymentInfo ? (
            <>
              <LoadingPaymentInfoLabel>
                {t('Loading payment info')}...
              </LoadingPaymentInfoLabel>
              <Loader />
            </>
          ) : null}
          {error && (
            <PaymentLabel>
              {parsedErrorMessage === PaymentError.CANCELLED
                ? t('Invoice payment has been cancelled')
                : null}
              {parsedErrorMessage === PaymentError.FAILED
                ? t('Invoice payment has failed')
                : null}
              {parsedErrorMessage === PaymentError.NOT_FOUND
                ? t('Payment not found')
                : null}
              {!parsedErrorMessage ? 'Error' : null}
            </PaymentLabel>
          )}
        </Container>
      ) : errorMessage ? (
        <Container>
          {salesInvoiceNumber ? (
            <PaymentLabel>{salesInvoiceNumber}</PaymentLabel>
          ) : null}
          <PaymentLabel>{t(errorMessage)}</PaymentLabel>
        </Container>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default PaymentSuccessfulPage;
