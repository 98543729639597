import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { INotificationType } from 'types/Notification.types';

export const useGetNotificationTitleFromNotificationType = (
  notificationTypeId: number
) => {
  const notificationTypes = useSelector(
    (state: IRootReducerState) => state.commonInfo.notificationTypes
  );
  if (!notificationTypes.length) {
    return '';
  }
  return notificationTypes.find(
    (notificationType: INotificationType) =>
      notificationType.id === notificationTypeId
  ).name;
};
