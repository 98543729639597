import React from 'react';
import App from 'App';
import ReactDOM from 'react-dom/client';
import GlobalStyle from 'assets/styled/global.styled';
import SentryProvider from 'providers/SentryProvider/SentryProvider';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import './providers/i18n/i18n';
import history from 'utils/history';
import { EnvironmentType, config } from 'config';

const persistor = persistStore(store);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: 'always',
      retry: false,
      staleTime: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SentryProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history as any}>
              <GlobalStyle />
              <App />
              {[EnvironmentType.DEV, EnvironmentType.STAGING].includes(
                config.environment as EnvironmentType
              ) && <ReactQueryDevtools initialIsOpen={false} />}
            </Router>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    </SentryProvider>
  </React.StrictMode>
);
