import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import {
  deleteQuotationLine,
  editQuotationPricesDefinitive,
} from 'services/Quotation/QuotationService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useEditQuotationPricesDefinitive = (quotationId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (arePricesDefinitive: boolean) =>
      editQuotationPricesDefinitive(quotationId, arePricesDefinitive),
    {
      onSuccess: () => {
        toast.success(t('Quotation updated'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_QUOTATION]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_PRICES_DEFINITIVE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_QUOTATION_PRICES_DEFINITIVE,
    }
  );
};

export const useDeleteQuotationLine = (quotationId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (quotationLineId: string) =>
      deleteQuotationLine(quotationId, quotationLineId),
    {
      onSuccess: () => {
        toast.success(t('Quotation line deleted'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_QUOTATION]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_QUOTATION_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.DELETE_QUOTATION_LINE,
    }
  );
};
