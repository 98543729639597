export const jsonToFormData = (
  jsonObject: any,
  formData = new FormData(),
  parentKey = ''
) => {
  Object.keys(jsonObject).forEach((key) => {
    const value = jsonObject[key];

    if (Array.isArray(value)) {
      const jsonValue = JSON.stringify(value);
      formData.append(parentKey ? `${parentKey}[${key}]` : key, jsonValue);
    } else if (
      typeof value === 'object' &&
      !(value instanceof File) &&
      !(value instanceof Date)
    ) {
      jsonToFormData(value, formData, parentKey ? `${parentKey}[${key}]` : key);
    } else {
      formData.append(parentKey ? `${parentKey}[${key}]` : key, value);
    }
  });

  return formData;
};
