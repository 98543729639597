import {
  Container,
  Headline,
  LoaderWrapper,
  Message,
  NoResultsContainer,
  ReportFilterTypeLabel,
  ReportFilterTypesWrapper,
  TableWrapper,
} from './SingleQuotationReportsPage.styled';
import { useTranslation } from 'react-i18next';
import TableAlt from 'components/TableAlt/TableAlt';
import { useEffect, useState } from 'react';
import {
  ReportsFilterType,
  REPORTS_CURRENT_PER_PAGE,
  REPORTS_HISTORICAL_PER_PAGE,
  tableConfig,
} from './constants';
import {
  useGetQuotationReportsCurrent,
  useGetQuotationReportsHistorical,
} from './hooks';
import { useParams } from 'react-router-dom';
import { PreviewAppointmentReportModal } from 'pages/Manager/PlanningPage/ViewAppointmentModal/PreviewAppointmentReportModal/PreviewAppointmentReportModal';
import { formatReportTableData } from 'components/TableAlt/tableDataFormatter';
import { IReport, IReportTableDTO } from 'types/Report.types';
import { useGetAppointmentReport } from 'pages/Manager/PlanningPage/ViewAppointmentModal/hooks';
import Loader from 'components/Loader/Loader';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const SingleQuotationReportsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [pageHistorical, setPageHistorical] = useState<number>(1);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [selectedReportFilterType, setSelectedReportFilterType] =
    useState<ReportsFilterType>(ReportsFilterType.CURRENT);

  const canReadQuotation = useCan(Actions.READ_QUOTATION);

  const {
    data: ReportsCurrent,
    isFetching: isFetchingCurrent,
    isFetched: isFetchedCurrent,
  } = useGetQuotationReportsCurrent(
    typeof id === 'string' ? id : '',
    pageCurrent,
    REPORTS_CURRENT_PER_PAGE
  );

  const {
    data: ReportsHistorical,
    isFetching: isFetchingHistorical,
    isFetched: isFetchedHistorical,
  } = useGetQuotationReportsHistorical(
    typeof id === 'string' ? id : '',
    pageHistorical,
    REPORTS_HISTORICAL_PER_PAGE
  );

  let reportsCurrentData: IReportTableDTO[] = [];
  let reportsHistoricalData: IReportTableDTO[] = [];

  if (ReportsCurrent) {
    reportsCurrentData = formatReportTableData(
      ReportsCurrent?.reports
        .map((report: IReport[]) => {
          return report;
        })
        .flat()
    );
  }

  if (ReportsHistorical) {
    reportsHistoricalData = formatReportTableData(
      ReportsHistorical?.reports
        .map((report: any) => {
          return report;
        })
        .flat()
    );
  }

  const {
    data: appointmentReport,
    isSuccess,
    isLoading,
  } = useGetAppointmentReport(
    selectedReport?.appointment?.id || '',
    !!selectedReport?.appointment?.id
  );

  useEffect(() => {
    if (isSuccess) {
      setIsReportModalOpen(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isReportModalOpen) {
      setSelectedReport(null);
    }
  }, [isReportModalOpen]);

  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_REPORTS,
    id,
    isFetchedCurrent && isFetchedHistorical
  );

  const handleButtonClick = (rowData: IReportTableDTO) => {
    setSelectedReport(rowData.entity);
  };

  const reportsAvailable =
    reportsCurrentData.length > 0 || reportsHistoricalData.length > 0;

  const isFetchingReports = isFetchingCurrent || isFetchingHistorical;

  return (
    <>
      {reportsAvailable && canReadQuotation ? (
        <Container className="single-quotation-step-11">
          {isLoading && (
            <LoaderWrapper>
              <Loader positionType="relative" />
            </LoaderWrapper>
          )}

          <TableWrapper>
            <Headline>{t('Reports')}</Headline>
            <ReportFilterTypesWrapper>
              <ReportFilterTypeLabel
                onClick={() => {
                  setSelectedReportFilterType(ReportsFilterType.CURRENT);
                  setPageCurrent(1);
                }}
                isSelected={
                  selectedReportFilterType === ReportsFilterType.CURRENT
                }
              >
                {t(ReportsFilterType.CURRENT)}
              </ReportFilterTypeLabel>
              <ReportFilterTypeLabel
                onClick={() => {
                  setSelectedReportFilterType(ReportsFilterType.HISTORICAL);
                  setPageHistorical(1);
                }}
                isSelected={
                  selectedReportFilterType === ReportsFilterType.HISTORICAL
                }
              >
                {t(ReportsFilterType.HISTORICAL)}
              </ReportFilterTypeLabel>
            </ReportFilterTypesWrapper>
            {reportsCurrentData.length > 0 &&
              selectedReportFilterType === ReportsFilterType.CURRENT && (
                <TableAlt
                  page={pageCurrent}
                  perPage={REPORTS_CURRENT_PER_PAGE}
                  total={ReportsCurrent?.total}
                  tableData={reportsCurrentData}
                  tableConfig={tableConfig}
                  hasExtraAction={true}
                  loadPage={(newPage) => setPageCurrent(newPage)}
                  onButtonClick={handleButtonClick}
                />
              )}
            {reportsHistoricalData.length > 0 &&
              selectedReportFilterType === ReportsFilterType.HISTORICAL && (
                <TableAlt
                  page={pageHistorical}
                  perPage={REPORTS_HISTORICAL_PER_PAGE}
                  total={ReportsHistorical?.total}
                  tableData={reportsHistoricalData}
                  tableConfig={tableConfig}
                  hasExtraAction={true}
                  loadPage={(newPage) => setPageHistorical(newPage)}
                  onButtonClick={handleButtonClick}
                />
              )}
          </TableWrapper>

          <PreviewAppointmentReportModal
            isOpen={isReportModalOpen}
            setIsOpen={setIsReportModalOpen}
            previewReportData={appointmentReport}
            appointment={selectedReport?.appointment}
            level="FIRST"
          />
          <IntroductionGuide steps={steps} />
        </Container>
      ) : (
        <NoResultsContainer className="single-quotation-step-11">
          {!canReadQuotation && <Message>{t('No permission')}</Message>}
          {canReadQuotation && isFetchingReports && (
            <Spinner size={50} color={COLORS.PRIMARY} />
          )}
          {canReadQuotation && !isFetchingReports && (
            <Message>
              {t('There are currently no Reports on this Quotation')}
            </Message>
          )}
          <IntroductionGuide steps={steps} />
        </NoResultsContainer>
      )}
    </>
  );
};

export default SingleQuotationReportsPage;
