import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { gapSm } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: ${gapSm};
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 420rem;
`;

export const Row = styled.div`
  width: 100%;
`;

export const Text = styled.label`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
`;
