import Button from 'components/Button/Button';
import { RowGap, SectionTitle } from '../ReportForm.styled';
import { useTranslation } from 'react-i18next';
import { gapMd, marginSm, marginXs } from 'assets/styled';
import {
  NoIntervalsWrapper,
  ResourcesSection,
  TimesheetHeaderLabel,
  WorkerResourceRow,
  WorkerResourceRowLabel,
  WorkerResourceTimeSpentHeader,
  WorkersSection,
} from './TimerSection.styled';
import { IAppointmentInterval } from 'types/Appointment.types';
import {
  formatIntervalTotalTime,
  groupIntervalsByEntity,
  sumIntervalsTotalTime,
} from './helpers';
import { useState } from 'react';
import { PreviewTimesheetModal } from './PreviewTimesheetModal/PreviewTimesheetModal';
import { filterIntervals } from '../helpers';
import Spinner from 'components/Spinner/Spinner';

interface ITimerSectionProps {
  isReadOnly: boolean;
  intervals: IAppointmentInterval[];
  isLoadingIntervals: boolean;
}

const TimerSection = ({
  isReadOnly,
  intervals,
  isLoadingIntervals,
}: ITimerSectionProps) => {
  const { t } = useTranslation();
  const [isPreviewTimesheetModalOpen, setIsPreviewTimesheetModalOpen] =
    useState<boolean>(false);

  const { workerIntervals, resourceIntervals } = filterIntervals(intervals);

  const summedWorkerIntervals: IAppointmentInterval[] =
    groupIntervalsByEntity(workerIntervals);

  const renderWorkerTotalTime = (
    summedWorkerIntervals: IAppointmentInterval[]
  ) => {
    return (
      <WorkerResourceRow withoutBottomBorder={true}>
        <WorkerResourceRowLabel isTotalTime={true}>
          {t('Total time')}
        </WorkerResourceRowLabel>
        <WorkerResourceRowLabel isTotalTime={true}>
          {sumIntervalsTotalTime(summedWorkerIntervals)}
        </WorkerResourceRowLabel>
      </WorkerResourceRow>
    );
  };

  const summedResourceIntervals: IAppointmentInterval[] =
    groupIntervalsByEntity(resourceIntervals);

  const renderResourceTotalTime = (
    summedResourceIntervals: IAppointmentInterval[]
  ) => {
    return (
      <WorkerResourceRow withoutBottomBorder={true}>
        <WorkerResourceRowLabel isTotalTime={true}>
          {t('Total time')}
        </WorkerResourceRowLabel>
        <WorkerResourceRowLabel isTotalTime={true}>
          {sumIntervalsTotalTime(summedResourceIntervals)}
        </WorkerResourceRowLabel>
      </WorkerResourceRow>
    );
  };

  return (
    <>
      <RowGap gap={gapMd} marginBottom={marginXs}>
        <SectionTitle>{t('TIMER')}</SectionTitle>
        <Button
          secondary
          label={t('Open timesheet')}
          width="200rem"
          onClick={() => setIsPreviewTimesheetModalOpen(true)}
        />
      </RowGap>
      <RowGap gap={'100rem'} alignItems={'flex-start'}>
        {isLoadingIntervals ? (
          <Spinner />
        ) : (
          <>
            <WorkersSection>
              <SectionTitle marginBottom={marginSm}>
                {t('Workers')}
              </SectionTitle>
              {workerIntervals.length ? (
                <>
                  <WorkerResourceTimeSpentHeader>
                    <TimesheetHeaderLabel>{t('Worker')}</TimesheetHeaderLabel>
                    <TimesheetHeaderLabel>
                      {t('Time spent')}
                    </TimesheetHeaderLabel>
                  </WorkerResourceTimeSpentHeader>
                  {summedWorkerIntervals.map(
                    (interval: IAppointmentInterval, index: number) => (
                      <WorkerResourceRow key={index}>
                        <WorkerResourceRowLabel>
                          {interval?.worker?.name}
                        </WorkerResourceRowLabel>
                        <WorkerResourceRowLabel>
                          {formatIntervalTotalTime(interval.total)}
                        </WorkerResourceRowLabel>
                      </WorkerResourceRow>
                    )
                  )}
                  {renderWorkerTotalTime(summedWorkerIntervals)}
                </>
              ) : (
                <NoIntervalsWrapper>
                  {t('No worker intervals')}
                </NoIntervalsWrapper>
              )}
            </WorkersSection>

            <ResourcesSection>
              <SectionTitle marginBottom={marginSm}>
                {t('Resources')}
              </SectionTitle>
              {resourceIntervals.length ? (
                <>
                  <WorkerResourceTimeSpentHeader>
                    <TimesheetHeaderLabel>{t('Resource')}</TimesheetHeaderLabel>
                    <TimesheetHeaderLabel>
                      {t('Time spent')}
                    </TimesheetHeaderLabel>
                  </WorkerResourceTimeSpentHeader>
                  {summedResourceIntervals.map(
                    (interval: IAppointmentInterval, index: number) => (
                      <WorkerResourceRow key={index}>
                        <WorkerResourceRowLabel>
                          {interval?.resource?.name}
                        </WorkerResourceRowLabel>
                        <WorkerResourceRowLabel>
                          {formatIntervalTotalTime(interval.total)}
                        </WorkerResourceRowLabel>
                      </WorkerResourceRow>
                    )
                  )}

                  {renderResourceTotalTime(summedResourceIntervals)}
                </>
              ) : (
                <NoIntervalsWrapper>
                  {t('No resource intervals')}
                </NoIntervalsWrapper>
              )}
            </ResourcesSection>
          </>
        )}
      </RowGap>
      <PreviewTimesheetModal
        isOpen={isPreviewTimesheetModalOpen}
        setIsOpen={setIsPreviewTimesheetModalOpen}
        intervals={intervals}
        level={isReadOnly ? 'THIRD' : 'FIRST'}
      />
    </>
  );
};

export default TimerSection;
