import { COLORS } from './colors';
import { css } from 'styled-components';

// ------------- GAP -------------
export const gapXs = '10rem';
export const gapSm = '20rem';
export const gapMd = '30rem';
export const gapLg = '50rem';

// ------------- PADDING -------------
export const paddingXs = '10rem';
export const paddingSm = '20rem';
export const paddingMd = '30rem';
export const paddingLg = '50rem';

// ------------- MARGIN -------------
export const marginXs = '10rem';
export const marginSm = '20rem';
export const marginMd = '30rem';
export const marginLg = '50rem';

// ------------- BORDER -------------
export const borderRadiusSm = '10rem';

export const borderWidthSm = '1rem';

// ------------- TRANSFORM -------------
export const transition = 'all 0.3s';

// ------------- BOX SHADOW -------------
export const boxShadowInput = `1rem 1rem 5rem 0rem ${COLORS.BLACK_200}`;

export const HideScrollbar = css`
  .container {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;
