import { SortDirection } from './constants';
import { ITableRowConfig } from './Table';
import {
  ArrowsDownUp,
  SortAscending,
  SortDescending,
} from '@phosphor-icons/react';

export const sortableFieldsMapper = (rowConfig: ITableRowConfig) => {
  const sortLabel = rowConfig.alternativeSortField
    ? rowConfig.alternativeSortField
    : rowConfig.header;
  switch (sortLabel) {
    case 'Date':
      return 'created_at';
    case 'Amount':
      return 'total_amount';
    case 'Total price':
      return 'total_amount';
    case 'Sales Order':
      return 'sales_order_number';
    case 'Credit':
      return 'is_credit';
    case 'Measuring':
      return 'measuring_date';
    case 'Total spent':
      return 'amount_sold';
    default:
      return sortLabel.toLowerCase().replaceAll(' ', '_');
  }
};

export const getSortIcon = (
  sortDirection: SortDirection | undefined,
  sortBy: string | undefined,
  rowConfig: ITableRowConfig
) => {
  if (
    sortDirection === SortDirection.ASC &&
    sortBy === sortableFieldsMapper(rowConfig)
  )
    return SortAscending;
  else if (
    sortDirection === SortDirection.DESC &&
    sortBy === sortableFieldsMapper(rowConfig)
  )
    return SortDescending;
  else return ArrowsDownUp;
};
