import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableLabel,
  Container,
  CreatedAtValidToDiv,
  CreatedAtValidToLabel,
  CreatedAtValidToLabelValue,
  InvoiceDescriptionLabel,
  LoaderWrapper,
  PenImg,
  ReferenceSpan,
  ReferenceWrapper,
  RightDiv,
  RowSpaceBetween,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  StatusLabel,
} from './SinglePurchaseInvoiceGeneralPage.styled';
import { useGetSinglePurchaseInvoice } from '../hooks';
import {
  IPurchaseInvoiceLine,
  IPurchaseInvoiceLineTableDTO,
} from 'types/Invoice.types';
import { formatPurchaseInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { tableConfig } from './constants';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { EditExternalReferenceModal } from 'pages/Manager/SingleInvoice/EditExternalReferenceModal/EditExternalReferenceModal';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { PencilSimple } from '@phosphor-icons/react';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import Tooltip from 'components/Tooltip/Tooltip';

const SinglePurchaseInvoiceGeneralPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const [isEditReferenceModalOpen, setIsEditReferenceModalOpen] =
    useState<boolean>(false);

  const { data, isLoading } = useGetSinglePurchaseInvoice(
    typeof id === 'string' ? id : ''
  );

  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  let tableData: IPurchaseInvoiceLineTableDTO[] = [];
  if (data?.purchase_invoice?.purchase_invoice_lines) {
    tableData = formatPurchaseInvoiceLineTableData(
      data.purchase_invoice.purchase_invoice_lines
        .map((purchaseInvoiceLine: IPurchaseInvoiceLine[]) => {
          return purchaseInvoiceLine;
        })
        .flat(),
      currencySymbol
    );
  }

  return (
    <>
      <Container>
        {isLoading ? (
          <LoaderWrapper>
            <Spinner size={50} />
          </LoaderWrapper>
        ) : (
          <>
            <RowSpaceBetween>
              <StatusLabel>
                {t(data?.purchase_invoice?.payment_status?.name)}
              </StatusLabel>
              <RightDiv>
                <ReferenceWrapper>
                  {data?.purchase_invoice?.external_id?.length > 10 ? (
                    <Tooltip content={data?.purchase_invoice?.external_id}>
                      <ReferenceSpan
                        $editable={!canEditPurchaseInvoice}
                        onClick={() => {
                          if (canEditPurchaseInvoice)
                            return setIsEditReferenceModalOpen(true);
                        }}
                      >
                        {`${t('External reference')}${
                          data?.purchase_invoice?.external_id
                            ? ` - 
                              ${data?.purchase_invoice?.external_id.substring(
                                0,
                                10
                              )}...`
                            : ''
                        }`}
                      </ReferenceSpan>
                    </Tooltip>
                  ) : (
                    <ReferenceSpan
                      $editable={!canEditPurchaseInvoice}
                      onClick={() => {
                        if (canEditPurchaseInvoice)
                          return setIsEditReferenceModalOpen(true);
                      }}
                    >
                      {`${t('External reference')}${
                        data?.purchase_invoice?.external_id
                          ? ' - ' + data?.purchase_invoice?.external_id
                          : ''
                      }`}
                    </ReferenceSpan>
                  )}
                  {canEditPurchaseInvoice && (
                    <PenImg data-testid="edit-external-reference">
                      <Icon
                        onClick={() => setIsEditReferenceModalOpen(true)}
                        svg={PencilSimple}
                        color={COLORS.PRIMARY}
                        size={15}
                      />
                    </PenImg>
                  )}
                </ReferenceWrapper>
              </RightDiv>
            </RowSpaceBetween>
            <RowSpaceBetweenMarginTop>
              <CreatedAtValidToDiv>
                <CreatedAtValidToLabel>
                  {t('Invoice date')}
                </CreatedAtValidToLabel>
                <CreatedAtValidToLabelValue>
                  {moment(data?.purchase_invoice?.created_at).format(
                    'DD-MM-YYYY'
                  )}
                </CreatedAtValidToLabelValue>
              </CreatedAtValidToDiv>
              <CreatedAtValidToDiv>
                <CreatedAtValidToLabel>{t('Valid to')}</CreatedAtValidToLabel>
                <CreatedAtValidToLabelValue>
                  {moment(data?.purchase_invoice?.valid_to).format(
                    'DD-MM-YYYY'
                  )}
                </CreatedAtValidToLabelValue>
              </CreatedAtValidToDiv>
            </RowSpaceBetweenMarginTop>
            <InvoiceDescriptionLabel>
              <ClickableLabel
                onClick={() =>
                  navigate(
                    SingleSupplierRoutes.SingleSupplierGeneral.fullPath.replace(
                      ':id',
                      data?.purchase_invoice?.purchase_order?.supplier?.id
                    )
                  )
                }
              >
                {data?.purchase_invoice?.purchase_order?.supplier?.company_name}
              </ClickableLabel>
              {data?.purchase_invoice?.description &&
                ` | ${data?.purchase_invoice?.description}`}
            </InvoiceDescriptionLabel>
            <RowSpaceBetweenMarginTop>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('TOTAL')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.purchase_invoice?.total_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              {!data?.purchase_invoice?.is_credit && (
                <>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('OPEN AMOUNT')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(data?.purchase_invoice?.open_amount)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('AMOUNT PAID')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(data?.purchase_invoice?.total_amount_payed)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                </>
              )}
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('STATUS')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {t(data?.purchase_invoice?.payment_status?.name)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
            </RowSpaceBetweenMarginTop>
            <Table
              page={-1}
              perPage={-1}
              total={data?.total}
              tableData={tableData}
              tableConfig={tableConfig}
              hasExtraAction={false}
              loadPage={() => null}
            />
            <EditExternalReferenceModal
              isOpen={isEditReferenceModalOpen}
              setIsOpen={setIsEditReferenceModalOpen}
              onCancel={() => setIsEditReferenceModalOpen(false)}
              externalId={data?.purchase_invoice?.external_id}
              type={InvoiceType.PURCHASE_INVOICE}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SinglePurchaseInvoiceGeneralPage;
