import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Quantity', flexValue: 1, isBlue: false },
  { header: 'Unit Price', flexValue: 1, isBlue: false },
  { header: 'Unit Name', flexValue: 1, isBlue: false },
  { header: 'Supplier Name', flexValue: 1, isBlue: false },
  { header: 'Billed Amount', flexValue: 1, isBlue: false },
  {
    header: 'Discounted Amount',
    flexValue: 1,
    isBlue: false,
  },
  { header: 'Total Price', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0,
};

export const PURCHASE_INVOICE_CREDIT_INVOICES_PER_PAGE = 10;
