import { Formik } from 'formik';
import { COLORS, H5 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ApplyWrapper,
  DateTimeWrapper,
  WorkersAndResources,
  StyledSelect,
  HorizontalLine,
  WorkersAndResourcesHeading,
  SalesOrderHeading,
  OptionsWrapper,
  OptionLabel,
  Scrollable,
  TimeIntervalErrorMessageCont,
  TimeIntervalErrorMessage,
  DateTimeSection,
  TimeWrapper,
  ArrivalTimeWrapper,
  ArrivalTimeInner,
  ArrivalTime,
  ArrivalTimeText,
  CheckboxContainer,
  HalfCont,
  AltTimeWrapper,
  DateLabel,
} from './GeneralTab.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { useLocation } from 'react-router-dom';
import {
  useCreateAppointment,
  useGetCustomersInfinite,
  useGetQuotationsInfinite,
  useGetSalesOrdersInfinite,
  useGetWorkersInfinite,
  useGetResourcesInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import {
  CUSTOMERS_PER_PAGE,
  GeneralTabOptions,
  QUOTATIONS_PER_PAGE,
  RESOURCES_PER_PAGE,
  SALES_ORDERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from './constants';
import {
  AddEmailNoteModal,
  IEmailDetails,
} from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateAppointmentNavigationState,
  useGetQuotationOptions,
  useGetSalesOrdersOptions,
  useGetSelectedNewAppointmentEntity,
  useHandleSelectOptions,
} from '../hooks';
import { Option } from 'components/Select/type';
import DatePicker from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from '../validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { getNewAppointmentEmailDetails } from '../helpers';
import { Minus, WarningCircle } from '@phosphor-icons/react';

export interface IGeneralTabProps {
  isNewAppointmentModalOpen: boolean;
  isCreatingAppointmentFromNavigationState: boolean; // Currently only general appointments dont have the ability to be made in this way
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  closeAppointmentModal: () => void;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  arrival_to?: string;
  arrival_from?: string;
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
}

const GeneralTab = ({
  isNewAppointmentModalOpen,
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  closeAppointmentModal,
  startDate,
  endDate,
  time_from,
  time_to,
  arrival_to,
  arrival_from,
  selectedWorkersOptions,
  selectedResourcesOptions,
}: IGeneralTabProps) => {
  const { t } = useTranslation();
  const { state }: { state: ICreateAppointmentNavigationState } = useLocation();

  const [customersSearchBy, setCustomersSearchBy] = useState<string>('');
  const debouncedCustomersSearchBy = useDebounce(customersSearchBy);

  const [quotationsSearchBy, setQuotationsSearchBy] = useState<string>('');
  const debouncedQuotationsSearchBy = useDebounce(quotationsSearchBy);

  const [salesOrderSearchBy, setSalesOrderSearchBy] = useState<string>('');
  const debouncedSalesOrderSearchBy = useDebounce(salesOrderSearchBy);

  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);

  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const [formikValues, setFormikValues] = useState<ICreateAppointmentDTO>();
  const [selectedOption, setSelectedOption] = useState<GeneralTabOptions>(
    GeneralTabOptions.CUSTOMER
  );

  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);

  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );
  const { mutate, isSuccess, isLoading } = useCreateAppointment();

  const createAppointmentIsLoading = !!isMutatingCreateAppointment || isLoading;

  const {
    data: customersData,
    fetchNextPage: fetchNextPageCustomers,
    hasNextPage: hasNextPageCustomers,
    isLoading: isLoadingCustomers,
  } = useGetCustomersInfinite(
    CUSTOMERS_PER_PAGE,
    debouncedCustomersSearchBy,
    true,
    isNewAppointmentModalOpen && selectedOption === GeneralTabOptions.CUSTOMER
  );

  const customers = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: any) => {
          return {
            value: customer?.id,
            label: `${customer?.name} ${customer?.last_name}`,
            address_id: customer?.address?.id,
          };
        });
    }
    return [];
  }, [customersData]);

  const {
    data: quotationsData,
    fetchNextPage: fetchNextPageQuotations,
    hasNextPage: hasNextPageQuotations,
    isLoading: isLoadingQuotations,
  } = useGetQuotationsInfinite(
    QUOTATIONS_PER_PAGE,
    debouncedQuotationsSearchBy,
    isNewAppointmentModalOpen && selectedOption === GeneralTabOptions.QUOTATION
  );

  const quotationOptions = useGetQuotationOptions(quotationsData);

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersInfinite(
    SALES_ORDERS_PER_PAGE,
    debouncedSalesOrderSearchBy,
    isNewAppointmentModalOpen &&
      selectedOption === GeneralTabOptions.SALES_ORDER
  );

  const salesOrdersOptions = useGetSalesOrdersOptions(salesOrdersData);

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    undefined,
    workersData,
    resourcesData,
    [],
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  useEffect(() => {
    if (isSuccess) {
      setIsSendEmailModalOpen(false);
      setIsCreatingAppointmentFromNavigationState(false);
      closeAppointmentModal();
    }
  }, [isSuccess]);

  const handleCreateAppointment = (
    values: ICreateAppointmentDTO,
    shouldSendEmail: boolean
  ) => {
    const createAppointmentData: ICreateAppointmentDTO = {
      purpose: values?.purpose,
      ...(values?.customer_id &&
        selectedOption === GeneralTabOptions.CUSTOMER && {
          customer_id: values?.customer_id,
        }),
      ...(values?.quotation_id &&
        selectedOption === GeneralTabOptions.QUOTATION && {
          quotation_id: values?.quotation_id,
        }),
      ...(values?.sales_order_id &&
        selectedOption === GeneralTabOptions.SALES_ORDER && {
          sales_order_id: values?.sales_order_id,
        }),
      date_from: `${values.startDate} ${values.time_from}`,
      date_to: `${values.endDate} ${values.time_to}`,
      appointment_type_id: values.appointment_type_id,
      description: values.description,
      users_ids: values?.users_ids,
      workers_ids: values?.workers_ids,
      resources_ids: values?.resources_ids,
      confirmed: isConfirmed,
      ...(values.arrival_from && {
        arrival_from: `${values.startDate} ${values.arrival_from}`,
      }),
      ...(values.arrival_to && {
        arrival_to: `${values.startDate} ${values.arrival_to}`,
      }),
    };
    mutate({ createAppointmentData, shouldSendEmail, note: '', files: [] });
  };

  const checkEmailSending = (values: ICreateAppointmentDTO) => {
    setFormikValues(values);
    if (!values.customer_id && !values.quotation_id && !values.sales_order_id) {
      handleCreateAppointment(values, false);
    } else {
      setIsSendEmailModalOpen(true);
    }
  };

  const {
    setSelectedQuotationId,
    setSelectedSalesOrderId,
    setSelectedCustomerId,
    selectedQuotation,
    selectedSalesOrder,
    selectedCustomer,
  } = useGetSelectedNewAppointmentEntity(state);

  const emailDetails: IEmailDetails | undefined = getNewAppointmentEmailDetails(
    selectedQuotation,
    selectedSalesOrder,
    selectedCustomer
  );

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // onSubmit
        }}
        initialValues={{
          purpose: '',
          customer_id: '',
          quotation_id: '',
          sales_order_id: '',
          startDate: startDate || '',
          endDate: endDate || '',
          date_from: '',
          date_to: '',
          time_to: time_to?.split('+')[0] || '',
          time_from: time_from?.split('+')[0] || '',
          arrival_to: arrival_to?.split('+')[0] || '',
          arrival_from: arrival_from?.split('+')[0] || '',
          appointment_type_id: '3',
          description: '',
          users_ids: [],
          resources_ids:
            selectedResourcesOptions.map((resource) => resource.id) || [],
          workers_ids: selectedWorkersOptions.map((worker) => worker.id) || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          errors,
          setFieldValue,
          setFieldError,
          values,
          handleBlur,
          isValid,
        }) => {
          return (
            <Container>
              <Scrollable>
                <H5>{t('PURPOSE')}</H5>
                <FormikInput
                  name="purpose"
                  pwId="purpose-field"
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('purpose', value);
                    handleBlur(e);
                  }}
                  height={'41rem'}
                  wrapperStyles={{
                    marginTop: '10rem',
                  }}
                  placeholder={t('Purpose of the appointment')}
                />
                <HorizontalLine />
                <DateTimeSection>
                  <H5>{t('DATE & TIME')}</H5>
                  <DateLabel>{t('From')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'startDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              startDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-from-select"
                        time={moment(values?.time_from, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            values.time_to,
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_from',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <DateLabel>{t('To')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'endDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              endDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-to-select"
                        time={moment(values?.time_to, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            values.time_from,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_to',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <TimeIntervalErrorMessageCont>
                    <TimeIntervalErrorMessage>
                      {errors.time_to}
                    </TimeIntervalErrorMessage>
                  </TimeIntervalErrorMessageCont>

                  <ArrivalTimeWrapper>
                    <ArrivalTimeInner>
                      <ArrivalTime>
                        <ArrivalTimeText
                          onClick={() => setShowArrivalTime(true)}
                        >
                          {t('Arrival time')}
                        </ArrivalTimeText>
                        <Tooltip
                          content={
                            t(
                              'This is the expected arrival time that will be communicated with the customer via the appointment email'
                            ) + '.'
                          }
                        >
                          <Icon
                            svg={WarningCircle}
                            color={COLORS.PRIMARY}
                            size={20}
                            weight="regular"
                            marginLeft={'10rem'}
                            pointer={false}
                          />
                        </Tooltip>
                      </ArrivalTime>

                      {showArrivalTime && (
                        <>
                          <AltTimeWrapper>
                            <TimePicker
                              width="100%"
                              pwId="time-from-select"
                              time={moment(values?.arrival_from, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  newValue?.format('HH:mm:ss'),
                                  values.arrival_to,
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_from',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                            <Icon
                              svg={Minus}
                              size={18}
                              weight="bold"
                              color={COLORS.BLACK}
                              marginLeft="5rem"
                              marginRight="5rem"
                            />
                            <TimePicker
                              width="100%"
                              pwId="time-to-select"
                              time={moment(values?.arrival_to, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  values.arrival_from,
                                  newValue?.format('HH:mm:ss'),
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_to',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                          </AltTimeWrapper>
                        </>
                      )}
                    </ArrivalTimeInner>
                    <TimeIntervalErrorMessageCont>
                      <TimeIntervalErrorMessage>
                        {errors.arrival_to}
                      </TimeIntervalErrorMessage>
                    </TimeIntervalErrorMessageCont>
                  </ArrivalTimeWrapper>
                </DateTimeSection>
                <HorizontalLine />
                <SalesOrderHeading>
                  <OptionsWrapper>
                    <OptionLabel
                      data-testid="customer-label"
                      onClick={() =>
                        setSelectedOption(GeneralTabOptions.CUSTOMER)
                      }
                      isSelected={selectedOption === GeneralTabOptions.CUSTOMER}
                    >
                      {t(GeneralTabOptions.CUSTOMER)}
                    </OptionLabel>
                    <OptionLabel
                      data-testid="quotation-label"
                      onClick={() =>
                        setSelectedOption(GeneralTabOptions.QUOTATION)
                      }
                      isSelected={
                        selectedOption === GeneralTabOptions.QUOTATION
                      }
                    >
                      {t(GeneralTabOptions.QUOTATION)}
                    </OptionLabel>
                    <OptionLabel
                      data-testid="sales-order-label"
                      onClick={() =>
                        setSelectedOption(GeneralTabOptions.SALES_ORDER)
                      }
                      isSelected={
                        selectedOption === GeneralTabOptions.SALES_ORDER
                      }
                    >
                      {t(GeneralTabOptions.SALES_ORDER)}
                    </OptionLabel>
                  </OptionsWrapper>
                </SalesOrderHeading>
                <HalfCont>
                  {selectedOption === GeneralTabOptions.CUSTOMER && (
                    <StyledSelect
                      pwId="customer-id"
                      name="customer_id"
                      options={customers}
                      onInputChange={(e: string) => setCustomersSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Customer')}
                      isMulti={false}
                      onSelect={(option: any) => {
                        setSelectedCustomerId(option?.value);
                        setFieldValue('customer_id', option?.value);
                        setFieldValue('address_id', option?.address_id);
                      }}
                      onMenuScrollToBottom={() => {
                        hasNextPageCustomers && fetchNextPageCustomers();
                      }}
                      isLoading={isLoadingCustomers}
                      translate={false}
                      isClearable
                    />
                  )}
                  {selectedOption === GeneralTabOptions.QUOTATION && (
                    <StyledSelect
                      pwId="quotation-id"
                      name="quotation_id"
                      options={quotationOptions}
                      onInputChange={(e: string) => setQuotationsSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Quotation')}
                      isMulti={false}
                      onSelect={(option: any) => {
                        setSelectedQuotationId(option?.value);
                        setFieldValue('quotation_id', option?.value);
                      }}
                      onMenuScrollToBottom={() => {
                        hasNextPageQuotations && fetchNextPageQuotations();
                      }}
                      isLoading={isLoadingQuotations}
                      translate={false}
                      isClearable
                    />
                  )}
                  {selectedOption === GeneralTabOptions.SALES_ORDER ? (
                    <StyledSelect
                      pwId="sales-order-id"
                      name="sales_order_id"
                      options={salesOrdersOptions}
                      onInputChange={(e: string) => setSalesOrderSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Sales order')}
                      isMulti={false}
                      onSelect={(option: any) => {
                        setSelectedSalesOrderId(option?.value);
                        setFieldValue('sales_order_id', option?.value);
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageSalesOrders && fetchNextPageSalesOrders()
                      }
                      isLoading={isLoadingSalesOrders}
                      translate={false}
                      isClearable
                    />
                  ) : null}
                </HalfCont>
                <HorizontalLine />
                <SalesOrderHeading>
                  <H5>{t('DESCRIPTION')}</H5>
                </SalesOrderHeading>
                <FormikInput
                  pwId="description-field"
                  isTextArea
                  height={'60rem'}
                  name="description"
                  placeholder={t('Details about the appointment')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                />
                <HorizontalLine />
                <WorkersAndResourcesHeading>
                  <H5>{t('WORKERS & RESOURCES')}</H5>
                </WorkersAndResourcesHeading>
                <WorkersAndResources>
                  <HalfCont>
                    <StyledSelect
                      pwId="workers-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => setWorkerSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti={true}
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedWorkerOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageWorkers && fetchNextPageWorkers()
                      }
                      isLoading={isLoadingWorkers}
                      translate={false}
                    />
                  </HalfCont>
                  <HalfCont>
                    <StyledSelect
                      pwId="resources-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => setResourceSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedResourceOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageResources && fetchNextPageResources()
                      }
                      isLoading={isLoadingResources}
                      translate={false}
                    />
                  </HalfCont>
                </WorkersAndResources>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={setIsConfirmed}
                    label={t('The customer already confirmed the appointment')}
                  />
                </CheckboxContainer>
              </Scrollable>
              <ApplyWrapper>
                <Button
                  disabled={createAppointmentIsLoading}
                  onClick={closeAppointmentModal}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  disabled={createAppointmentIsLoading || !isValid}
                  onClick={() => {
                    checkEmailSending(values);
                  }}
                  primary
                  width="200rem"
                  label={t('Save')}
                />
              </ApplyWrapper>
            </Container>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-appointment-confirmation-modal"
        level="SECOND"
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onYes={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onNo={() => {
          handleCreateAppointment(formikValues!, false);
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          isConfirmed
            ? t(
                'Do you want to send the appointment confirmation to the customer'
              ) + '?'
            : t('Do you want to send the appointment offer to the customer') +
              '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={formikValues?.quotation_id || formikValues?.sales_order_id || ''}
        type={AddEmailNoteModalType.GENERAL_CREATION}
        isConfirmedAppointment={isConfirmed}
        newAppointment={formikValues!}
        selectedOptionGeneral={selectedOption}
        onCreateAppointmentSuccess={() => {
          isCreatingAppointmentFromNavigationState &&
            setIsCreatingAppointmentFromNavigationState(false);
          closeAppointmentModal();
        }}
        emailDetails={emailDetails}
      />
    </div>
  );
};

export default GeneralTab;
