// Fw Single appointment
export const FwSingleAppointmentRoutes = {
  FwSingleAppointmentGeneral: {
    path: '',
    fullPath: '/appointments',
  },
  FwSingleAppointmentProducts: {
    path: 'products',
    fullPath: '/appointments/:id/products',
  },
  FwSingleAppointmentComments: {
    path: 'comments',
    fullPath: '/appointments/:id/comments',
  },
  FwSingleAppointmentFiles: {
    path: 'files',
    fullPath: '/appointments/:id/files',
  },
  FwSingleAppointmentLocation: {
    path: 'location',
    fullPath: '/appointments/:id/location',
  },
  FwSingleAppointmentTimesheet: {
    path: 'timesheet',
    fullPath: '/appointments/:id/timesheet',
  },
  FwSingleAppointmentReports: {
    path: 'reports',
    fullPath: '/appointments/:id/reports',
  },
  FwSingleAppointmentLine: {
    path: 'add-line',
    fullPath: '/appointments/:id/add-line',
  },
  FwSingleAppointmentNewComment: {
    path: 'comment',
    fullPath: '/appointments/:id/comment',
  },
};
