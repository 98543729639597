import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { getSingleCustomerByEmail } from 'services/Customer/CustomerService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGetCustomerByEmail = () =>
  useMutation((email: string) => getSingleCustomerByEmail(email), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_CUSTOMER_BY_EMAIL,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.GET_CUSTOMER_BY_EMAIL,
  });
