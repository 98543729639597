import axios, { AxiosError } from 'axios';
import LocalStorageService from 'services/LocalStorageService';
import {
  APP_SESSION_TYPE,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
  WEB_SESSION_TYPE,
} from './constants';
import { store } from '../../store/store';
import { EnvironmentType, config } from 'config';
import {
  setIsSessionExpired,
  setIsUpdateAvailable,
} from 'store/Common/actions/common';
import { queryClient } from 'index';
import { LOGOUT } from 'store/User/constants';
import jwtDecode from 'jwt-decode';
import { setPermissionActions } from 'store/User/actions/user';
import { isJwtToken } from 'listeners/TokenChangedInDifferentTabListener/TokenChangedInDifferentTabListener';
import { updateSocketToken } from 'providers/SocketIOProvider/SocketIOProvider';

export interface IDecodedToken {
  company_identifier?: string;
  exp?: number;
  id?: number;
  permissions?: number[];
}

const API_URL = config.api.baseUrl;
const AUTH_BASE_URL = API_URL + '/v1/users';

const apiClient = axios.create({
  baseURL: API_URL,
});

const authApiClient = axios.create({
  baseURL: AUTH_BASE_URL,
});

authApiClient.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers['Session-Type'] = store.getState().commonInfo.isMobileApp
      ? APP_SESSION_TYPE
      : WEB_SESSION_TYPE;
  }

  return config;
});

// Promise locking mechanism for the refresh token process
// This ensures that only one refresh token request is processed at a time, while other requests wait for its completion
let refreshTokenPromise: Promise<string> | null = null;

const setSessionToExpired = () => {
  // If user is logged in, show the SessionExpiredModal and disable response interceptor
  if (store.getState().userInfo.id) {
    store.dispatch(setIsSessionExpired(true)); // SessionExpiredModalProvider
  }
};

const setPermissionsFromToken = (token: any) => {
  if (token && isJwtToken(token)) {
    const decodedToken = jwtDecode<any>(token);
    const permissionActions = decodedToken?.permissions;
    store.dispatch(setPermissionActions(permissionActions));
  } else {
    console.warn('Failed to extract permissions from token');
  }
};

const addCacheHeaders = (config: any) => {
  if (config.headers) {
    config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
  }
  return config;
};

apiClient.interceptors.request.use(
  function (axiosConfig) {
    const isSessionExpired = store.getState().commonInfo.isSessionExpired;
    const isUpdateAvailable = store.getState().commonInfo.isUpdateAvailable;

    if (isUpdateAvailable) {
      // Cancel the request
      return Promise.reject({
        message: 'Request blocked; Version mismatch',
        config: axiosConfig,
      });
    }
    if (isSessionExpired) {
      // Cancel the request
      return Promise.reject({
        message: 'Session expired',
        config: axiosConfig,
      });
    }

    // If the session is not expired, proceed with setting the Authorization header
    const token = LocalStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

    if (!token) {
      store.dispatch({ type: LOGOUT });
      return Promise.reject({
        message: 'Missing token',
        config: axiosConfig,
      });
    }

    if (axiosConfig.headers) {
      axiosConfig.headers['Authorization'] = `Bearer ${token}`;
      axiosConfig.headers['Session-Type'] = store.getState().commonInfo
        .isMobileApp
        ? APP_SESSION_TYPE
        : WEB_SESSION_TYPE;

      if (config.environment === EnvironmentType.PROD) {
        axiosConfig.headers['Version'] = config.version ? config.version : '';
      }
    }

    if (token) setPermissionsFromToken(token);

    // Add cache control headers
    addCacheHeaders(axiosConfig);

    return axiosConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;

      if (
        error?.response?.status === 400 &&
        error?.response?.data?.errors &&
        error?.response?.data?.errors[0] === '400 Bad Request: Version mismatch'
      ) {
        store.dispatch(setIsUpdateAvailable(true));
      }

      if (error?.response?.status === 401) {
        const currentRefreshToken = LocalStorageService.getItem(
          LOCAL_STORAGE_REFRESH_TOKEN
        );

        if (!currentRefreshToken) {
          setSessionToExpired();
          return Promise.reject(error);
        }

        if (!refreshTokenPromise) {
          refreshTokenPromise = authApiClient
            .post('/refresh-token', {
              refresh_token: currentRefreshToken,
            })
            .then(async (refreshResponse) => {
              const { access_token, refresh_token } = refreshResponse.data.data;
              updateSocketToken(access_token);
              await LocalStorageService.setItem(
                LOCAL_STORAGE_ACCESS_TOKEN,
                access_token
              );
              await LocalStorageService.setItem(
                LOCAL_STORAGE_REFRESH_TOKEN,
                refresh_token
              );
              setPermissionsFromToken(access_token);
              // Potentially multiple requests failed at the moment of refresh token expiration so refetch everything
              queryClient.invalidateQueries();
              // Obtained new tokens, set session as active if it was previously expired
              store.dispatch(setIsSessionExpired(false));
              refreshTokenPromise = null;
              return access_token;
            })
            .catch(async (refreshError) => {
              // If refresh token couldn't obtain new tokens, expire the session
              setSessionToExpired();
              refreshTokenPromise = null;
              throw refreshError;
            });
        }

        try {
          const newAccessToken = await refreshTokenPromise;
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    } catch (err) {
      if (
        err instanceof AxiosError &&
        (err.response?.status === 401 || err.response?.status === 400)
      ) {
        setSessionToExpired();
      }
      return Promise.reject(err);
    }
  }
);

export default apiClient;

export { authApiClient };
