import { Moment } from 'moment';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getSalesOrderAppointments,
  getSalesOrderAppointmentsForDateInterval,
} from 'services/SalesOrder/SalesOrderService';
import { AppointmentTypeIds } from 'types/Appointment.types';

export const useGetSingleSalesOrderAppointmentsForDateInterval = (
  salesOrderId: string,
  dateFrom: Moment,
  dateTo: Moment
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_APPOINTMENTS_FOR_INTERVAL,
      salesOrderId,
      dateFrom,
      dateTo,
    ],
    queryFn: () => {
      return getSalesOrderAppointmentsForDateInterval(
        salesOrderId,
        dateFrom,
        dateTo
      );
    },
  });

export const useGetSingleSalesOrderAppointments = (
  salesOrderId: string,
  page?: number,
  perPage?: number,
  selectedAppointmentTypeId?: AppointmentTypeIds
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_APPOINTMENTS,
      salesOrderId,
      page,
      perPage,
      selectedAppointmentTypeId,
    ],
    queryFn: () => {
      return getSalesOrderAppointments(
        salesOrderId,
        page,
        perPage,
        selectedAppointmentTypeId
      );
    },
    enabled: !!salesOrderId,
  });

export const useGetSalesOrderId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};
