import { RoutesConfig } from 'navigation/routes';
import { Navigate, Outlet } from 'react-router-dom';
import { useIsFieldWorker } from './hooks';

const FieldWorkerRoute = () => {
  const isFieldWorker = useIsFieldWorker();
  return isFieldWorker ? <Outlet /> : <Navigate to={RoutesConfig.Home.path} />;
};

export default FieldWorkerRoute;
