import { useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSinglePurchaseInvoicePayments } from 'services/Invoice/PurchaseInvoiceService';

export const useGetSinglePurchaseCreditInvoicePayments = (
  invoiceId: string,
  perPage: number
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE_PAYMENTS,
      invoiceId,
      perPage,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getSinglePurchaseInvoicePayments(invoiceId, pageParam, perPage),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });
