import styled from 'styled-components';

export const HeaderButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const CalendarIntegrationDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 43rem;
  gap: 10rem;
`;
