import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createPurchaseInvoice } from 'services/Invoice/PurchaseInvoiceService';
import { ICreatePurchaseInvoiceDTO } from 'types/Invoice.types';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useCreatePurchaseInvoice = () =>
  useMutation(
    (createPurchaseInvoiceData: ICreatePurchaseInvoiceDTO) =>
      createPurchaseInvoice(createPurchaseInvoiceData),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_PURCHASE_INVOICE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_INVOICES);
      },
      mutationKey: ReactMutationKeys.CREATE_PURCHASE_INVOICE,
    }
  );

export const useGetPrepopulateInvoiceData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulateInvoiceData;
  } else {
    return null;
  }
};
