import { ITableConfig, ITableRowConfig } from 'components/Table/Table';
import { CellType } from 'components/Table/constants';

const saleRowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Total price', flexValue: 1, isBlue: false },
  { header: 'Valid to', flexValue: 1, isBlue: false },
  { header: 'Created at', flexValue: 1, isBlue: false },
  { header: 'Credit', flexValue: 1, isBlue: false },
  {
    header: 'Accounting',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.ACCOUNTING,
  },
];

export const saleTableConfig: ITableConfig = {
  rowConfigs: saleRowConfigs,
  extraActionsFlexValue: 0.5,
};

export const INVOICES_PER_PAGE = 10;

export enum InvoicesFilterType {
  ALL = 'All',
  SALES_INVOICES = 'Sales invoices',
  PURCHASE_INVOICES = 'Purchase invoices',
}

const purchaseRowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Total price', flexValue: 1, isBlue: false },
  { header: 'Valid to', flexValue: 1, isBlue: false },
  { header: 'Created at', flexValue: 1, isBlue: false },
  { header: 'Credit', flexValue: 1, isBlue: false },
];

export const purchaseTableConfig: ITableConfig = {
  rowConfigs: purchaseRowConfigs,
  extraActionsFlexValue: 0.5,
};
