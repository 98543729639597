import * as Yup from 'yup';
import { typeOptions } from './constants';
import { Option } from 'components/Select/type';

export const createStockItemDetailsTabSchema = (
  unitOfMeasureOptions: Option[]
) => {
  return Yup.object().shape({
    sales_price: Yup.number()
      .required('Sales price is required')
      .typeError('Sales price must be a number')
      .min(0, 'Sales price should be at least 0')
      .max(1000000, `Sales price can't be above 1 million`)
      .test(
        'twoDecimal',
        'Sales price can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      ),
    min_order_amount: Yup.number()
      .typeError('Min order amount must be a number')
      .min(0, 'Min order amount should be at least 0'),
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters long')
      .max(50, 'Name must be shorter than 50 characters')
      .required('Name is required'),
    description: Yup.string().required('Description is required'),
    unit_of_measure: Yup.string()
      .required('Unit of measure is required')
      .when('type_id', {
        is: (value: any) =>
          value &&
          value ===
            typeOptions.find((type: any) => type.key === 'service')?.value,
        then: Yup.string().test(
          'pieceUnit',
          'Only piece is valid for service',
          (value: any) => {
            const unitValue = unitOfMeasureOptions.find(
              (unit: any) => unit.key === 'piece'
            )?.value;
            if (value === unitValue) return true;
            else return false;
          }
        ),
      }),
    purchase_price: Yup.number()
      .required('Purchase price is required')
      .typeError('Purchase price must be a number')
      .min(0, 'Purchase price should be at least 0')
      .max(1000000, `Purchase price can't be above 1 million`)
      .test(
        'twoDecimal',
        'Purchase price can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      ),
    in_stock: Yup.number()
      .required('In stock amount is required')
      .typeError('In stock must be a number')
      .min(0, 'In stock should be at least 0')
      .max(1000000, `In stock can't be above 1 million`)
      .test('wholeNumber', 'In stock must be a whole number', (value) => {
        return Number.isInteger(value);
      }),
    reserved_amount: Yup.number()
      .typeError('Reserved amount must be a number')
      .min(0, 'Reserved amount should be at least 0'),
    number_supplier: Yup.string().max(
      20,
      'Number supplier must be shorter than 20 characters'
    ),
  });
};
