// Single Purchase Order Invoice
export const SalesPurchaseOrderInvoiceRoutes = {
  SinglePurchaseInvoiceGeneral: {
    path: '',
    fullPath: '/purchase-invoices',
  },
  SinglePurchaseInvoicePurchaseOrders: {
    path: 'purchases',
    fullPath: '/purchase-invoices/:id/purchases',
  },
  SinglePurchaseInvoicePayments: {
    path: 'payments',
    fullPath: '/purchase-invoices/:id/payments',
  },
  SinglePurchaseInvoiceFiles: {
    path: 'files',
    fullPath: '/purchase-invoices/:id/files',
  },
  SinglePurchaseInvoiceNewCreditInvoice: {
    path: 'credit/create',
    fullPath: '/purchase-invoices/:id/credit/create',
  },
  SinglePurchaseInvoiceCreditInvoices: {
    path: 'credit',
    fullPath: '/purchase-invoices/:id/credit',
  },
};
