import { H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import FakeInput from 'components/FakeInput/FakeInput';
import { ICreatePurchaseOrderItem } from '../ItemsTab/constants';
import { ICreatePurchaseOrderSupplierDetails } from '../SupplierTab/constants';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SupplierDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  ItemFlexWrap,
  ItemTitle,
  ItemSupplierCompanyNameLabel,
  RowMarginTop,
  Row,
  SectionTitle,
  SingleItemWrapper,
  SubmitRow,
  ItemsWrapper,
  Slash,
  FieldWrapper,
} from './OverviewTab.styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

interface IOverviewTab {
  onForward: () => void;
  onBack: () => void;
  supplierDetails: ICreatePurchaseOrderSupplierDetails;
  items: ICreatePurchaseOrderItem[];
  status: string;
}

const OverviewTab = ({
  onForward,
  onBack,
  supplierDetails,
  items,
  status,
}: IOverviewTab) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const renderProducts = () => {
    return items.map((item: ICreatePurchaseOrderItem, index: number) => (
      <SingleItemWrapper key={index}>
        <Row>
          <ItemTitle>
            {t('Product')} {item.item.product?.name}
          </ItemTitle>
          <Slash>/</Slash>
          <ItemSupplierCompanyNameLabel>
            {item.item.supplier?.company_name}
          </ItemSupplierCompanyNameLabel>
        </Row>
        <ItemFlexWrap>{renderItemFields(item)}</ItemFlexWrap>
      </SingleItemWrapper>
    ));
  };

  const renderItemFields = (item: any) => {
    return (
      <>
        <FieldWrapper>
          <FakeInput
            label={item.quantity}
            isDropdown={false}
            width={'100%'}
            placeholder={t('Quantity')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FakeInput
            label={`${currencySymbol}${item.price}`}
            isDropdown={false}
            width={'100%'}
            placeholder={t('Purchase Price exc. VAT')}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FakeInput
            label={item.totalPrice}
            isDropdown={false}
            width={'100%'}
            placeholder={t('Total Price')}
          />
        </FieldWrapper>
      </>
    );
  };

  return (
    <Container>
      <FirstRow>
        <H3>{t('Overview')}</H3>
      </FirstRow>
      <SupplierDetailsWrapper>
        <SectionTitle>{t('Supplier Details')}</SectionTitle>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.contact_person}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Contact person')}
          />
          <FakeInput
            label={supplierDetails.phone}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Phone number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.company_name}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Company name')}
          />
          <FakeInput
            label={supplierDetails.vat_number}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Company VAT')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.email}
            isDropdown={false}
            width={'50%'}
            placeholder={t('E-mail')}
          />
          <FakeInput
            label={supplierDetails.country}
            isDropdown={true}
            width={'50%'}
            placeholder={t('Country code')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.city}
            isDropdown={false}
            width={'50%'}
            placeholder={t('City')}
          />
          <FakeInput
            label={supplierDetails.code}
            isDropdown={true}
            width={'50%'}
            placeholder={t('Zip code')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.street}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Street')}
          />
          <FakeInput
            label={supplierDetails.house_number}
            isDropdown={true}
            width={'50%'}
            placeholder={t('House number')}
          />
        </RowMarginTop>
        <Line />
      </SupplierDetailsWrapper>
      <ItemsWrapper>
        <SectionTitle>{t('Products')}</SectionTitle>
        {renderProducts()}
        <Line />
      </ItemsWrapper>
      <SubmitRow>
        <Button
          width={'200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            width={'200rem'}
            onClick={() => onForward()}
            label={t('Finish')}
            primary
            disabled={status === 'loading'}
          />
        </Margin>
      </SubmitRow>
    </Container>
  );
};

export default OverviewTab;
