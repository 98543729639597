import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { getCompletedIntroGuidePages } from 'services/User/UserService';

export const useGetCompletedIntroGuidePages = () =>
  useMutation(() => getCompletedIntroGuidePages(), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_COMPLETED_INTRO_GUIDE_PAGES,
      });
    },
    mutationKey: ReactMutationKeys.GET_COMPLETED_INTRO_GUIDE_PAGES,
  });
