import { COLORS } from 'assets/styled';
import { IPurchaseOrderLineFormDTO } from '../constants';

export const getLineColorBasedOnSelectedLines = (
  purchaseOrderLines: IPurchaseOrderLineFormDTO[],
  lineIndex: number
) => {
  return purchaseOrderLines[lineIndex].isSelected ||
    purchaseOrderLines[lineIndex + 1].isSelected
    ? COLORS.PRIMARY
    : COLORS.GREY_1000;
};
