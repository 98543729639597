import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SalesCreditInvoiceRoutes } from 'navigation/SingleInvoice/SalesCreditInvoice/SingleSalesCreditInvoice.routes';

export const PURCHASE_INVOICE_CREDIT_INVOICES_PER_PAGE = 10;

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceGeneral.path,
  },
  {
    text: 'Sales Invoice',
    route: SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceSalesInvoice.path,
  },
  {
    text: 'Payments',
    route: SalesCreditInvoiceRoutes.SingleSalesCreditInvoicePayments.path,
  },
  {
    text: 'Files',
    route: SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceFiles.path,
  },
];
