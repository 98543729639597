// Single Sales Credit Invoice
export const SalesCreditInvoiceRoutes = {
  SingleSalesCreditInvoiceGeneral: {
    path: '',
    fullPath: '/sale-credit-invoices',
  },
  SingleSalesCreditInvoiceSalesInvoice: {
    path: 'invoice',
    fullPath: '/sale-credit-invoices/:id/invoice',
  },
  SingleSalesCreditInvoicePayments: {
    path: 'payments',
    fullPath: '/sale-credit-invoices/:id/payments',
  },
  SingleSalesCreditInvoiceFiles: {
    path: 'files',
    fullPath: '/sale-credit-invoices/:id/files',
  },
};
