import { useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getStockItems } from 'services/StockItems/StockItemsService';

export const useGetStockItemsForCreatingPurchaseOrderInfinite = (
  perPage: number,
  searchBy: string,
  supplierId: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER,
      perPage,
      searchBy,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getStockItems(
        pageParam,
        perPage,
        searchBy,
        true,
        undefined,
        undefined,
        Number(supplierId)
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });
