import { Route, Routes } from 'react-router-dom';
import FwSingleAppointmentGeneralPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentGeneralPage/FwSingleAppointmentGeneralPage';
import FwSingleAppointmentProductsPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentProductsPage/FwSingleAppointmentProductsPage';
import FwSingleAppointmentCommentsPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentCommentsPage/FwSingleAppointmentCommentsPage';
import FwSingleAppointmentFilesPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentFilesPage/FwSingleAppointmentFilesPage';
import FwSingleAppointmentLocationPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentLocationPage/FwSingleAppointmentLocationPage';
import FwSingleAppointmentTimesheetPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentTimesheetPage/FwSingleAppointmentTimesheetPage';
import FwNewAppointmentLinePage from 'pages/FieldWorker/FwSingleAppointmentPage/FwNewAppointmentLinePage/FwNewAppointmentLinePage';
import FwSingleAppointmentNewComment from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentNewComment/FwSingleAppointmentNewComment';
import { FwSingleAppointmentRoutes } from './FwSingleAppointment.routes';
import FwSingleAppointmentReportsPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentReportsPage/FwSingleAppointmentReportsPage';

const FwSingleAppointmentRouter = () => {
  return (
    <Routes>
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentGeneral.path}
        element={<FwSingleAppointmentGeneralPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentProducts.path}
        element={<FwSingleAppointmentProductsPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentComments.path}
        element={<FwSingleAppointmentCommentsPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentFiles.path}
        element={<FwSingleAppointmentFilesPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentLocation.path}
        element={<FwSingleAppointmentLocationPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentReports.path}
        element={<FwSingleAppointmentReportsPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentTimesheet.path}
        element={<FwSingleAppointmentTimesheetPage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentLine.path}
        element={<FwNewAppointmentLinePage />}
      />
      <Route
        path={FwSingleAppointmentRoutes.FwSingleAppointmentNewComment.path}
        element={<FwSingleAppointmentNewComment />}
      />
    </Routes>
  );
};

export default FwSingleAppointmentRouter;
