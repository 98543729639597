import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 16rem;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 15rem;
    height: 15rem;
    transition: all 0.3s;
  }

  &:hover svg {
    right: -5rem;
  }
`;

export const Text = styled.span`
  display: inline-block;
  color: ${COLORS.PRIMARY};
`;
