import { ISupplier } from 'types/Supplier.types';
import { ICreateStockItemSupplierDetails } from './SupplierTab/constants';

export const getInitialSupplierDetailsData = (
  prepopulatePurchaseOrderData: any,
  initialCountryCCA2: string
) => {
  if (prepopulatePurchaseOrderData?.supplier) {
    return {
      supplierId: prepopulatePurchaseOrderData.supplier.id,
      email: prepopulatePurchaseOrderData.supplier.email,
      phone: prepopulatePurchaseOrderData.supplier.phone,
      country: prepopulatePurchaseOrderData.supplier.address.country.cca2,
      vat_number: prepopulatePurchaseOrderData.supplier.vat_number,
      company_name: prepopulatePurchaseOrderData.supplier.company_name,
      code: prepopulatePurchaseOrderData.supplier.address.zip_code,
      city: prepopulatePurchaseOrderData.supplier.address.city,
      street: prepopulatePurchaseOrderData.supplier.address.street,
      house_number: prepopulatePurchaseOrderData.supplier.address.house_number,
      contact_person: prepopulatePurchaseOrderData.supplier.contact_person,
      coc_number: prepopulatePurchaseOrderData.supplier.coc_number,
    };
  } else {
    return {
      supplierId: '',
      email: '',
      phone: '',
      country: initialCountryCCA2,
      code: '',
      vat_number: '',
      company_name: '',
      city: '',
      street: '',
      house_number: '',
      contact_person: '',
      coc_number: '',
    };
  }
};

export const generateExistingSupplierDetails = (supplier: ISupplier) => {
  const supplierDetails: ICreateStockItemSupplierDetails = {
    supplierId: supplier?.id ? supplier.id.toString() : '',
    email: supplier.email,
    phone: supplier.phone,
    country: supplier.address.country.cca2,
    city: supplier.address.city ? supplier.address.city : '',
    street: supplier.address.street,
    house_number: supplier.address.house_number
      ? supplier.address.house_number
      : '',
    code: supplier.address.zip_code,
    vat_number: supplier.vat_number,
    company_name: supplier.company_name,
    contact_person: supplier.contact_person,
    coc_number: supplier.coc_number ? supplier.coc_number : '',
  };
  return supplierDetails;
};
