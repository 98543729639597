import { ICreateInvoiceSupplierDetails } from './SupplierTab/constants';
import { ICreateInvoiceDetails } from './InvoiceDetailsTab/constants';
import { store } from 'store/store';

export const getInitialSupplierDetailsData = (
  prepopulateInvoiceData: any,
  initialCountryCCA2: string
): ICreateInvoiceSupplierDetails => {
  if (prepopulateInvoiceData) {
    return {
      email: prepopulateInvoiceData.supplier.email,
      phone: prepopulateInvoiceData.supplier.phone,
      country: prepopulateInvoiceData.supplier.address.country.cca2,
      street: prepopulateInvoiceData.supplier.address.street,
      code: prepopulateInvoiceData.supplier.address.zip_code,
      city: prepopulateInvoiceData.supplier.address.city,
      house_number: prepopulateInvoiceData.supplier?.address?.house_number,
      company_name: prepopulateInvoiceData.supplier?.company_name,
      contact_person: prepopulateInvoiceData.supplier?.contact_person,
      vat_number: prepopulateInvoiceData.supplier?.vat_number,
    };
  } else {
    return {
      email: '',
      phone: '',
      country: initialCountryCCA2,
      street: '',
      code: '',
      city: '',
      house_number: '',
      company_name: '',
      vat_number: '',
      contact_person: '',
    };
  }
};

export const getInitialInvoiceDetailsData = (
  prepopulateInvoiceData: any
): ICreateInvoiceDetails => {
  return {
    daysValid: store.getState().companyInfo?.settings?.days_valid_invoice,
    externalId: '',
    totalPrice: prepopulateInvoiceData.total_amount,
    date: prepopulateInvoiceData,
  };
};

export const findPurchaseOrderLineWithMatchingId = (
  prepopulateInvoiceData: any,
  product: any
) => {
  for (const purchaseOrderLine of prepopulateInvoiceData.purchase_order_lines) {
    if (purchaseOrderLine.id === product.order_line.id) {
      return purchaseOrderLine;
    }
  }
};

export const findWorkingHourPurchaseOrderLine = (
  prepopulateInvoiceData: any
) => {
  for (const purchaseOrderLine of prepopulateInvoiceData.purchase_order_lines) {
    if (purchaseOrderLine.working_hours_line) {
      return purchaseOrderLine;
    }
  }
};
