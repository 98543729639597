import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { addResource } from 'services/Resources/ResourcesService';
import { IResourceRequest } from './type';
import { queryClient } from 'index';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useAddResource = () =>
  useMutation(
    (params: { resources: IResourceRequest[]; companyId: string }) => {
      return addResource(params.resources, params.companyId);
    },
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_RESOURCE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_RESOURCES);
      },
      mutationKey: ReactMutationKeys.ADD_RESOURCE,
    }
  );
