import { Option } from 'components/Select/type';
import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Status',
    value: 'status',
    type: 'dropdown',
  },
  {
    label: 'Reference',
    value: 'reference',
    type: 'string',
  },
  {
    label: 'Total price',
    value: 'totalPrice',
    type: 'string',
  },
  {
    label: 'Valid to',
    value: 'validTo',
    type: 'date',
  },
  {
    label: 'Created at',
    value: 'createdAt',
    type: 'date',
  },
  {
    label: 'Credit',
    value: 'credit',
    type: 'dropdown',
  },
  {
    label: 'Accounting',
    value: 'accounting',
    type: 'dropdown',
  },
];

const trueFalseOptions: Option[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const useGetInvoicesAdvancedFilters = (statusOptions: Option[]) => {
  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'status') {
      return { ...filter, options: statusOptions };
    } else if (filter.value === 'credit') {
      return { ...filter, options: trueFalseOptions };
    } else if (filter.value === 'accounting') {
      return { ...filter, options: trueFalseOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};
