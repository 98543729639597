import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const useIsManager = () => {
  const isFieldWorkerView = useSelector(
    (state: IRootReducerState) => state.commonInfo.isFieldWorkerView
  );
  const isManager = !isFieldWorkerView;
  return isManager;
};
