import { Option } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { ITwinfieldSalesTransactionStatus } from 'types/Invoice.types';

export const useGetTwinfieldTransactionStatuses = () => {
  const storeStatuses = useSelector(
    (state: IRootReducerState) => state.commonInfo.twinfieldTransactionStatuses
  );

  const memoizedStatuses: Option[] = useMemo(() => {
    const twinfieldTransactionStatuses = storeStatuses?.map(
      (status: ITwinfieldSalesTransactionStatus) => {
        return {
          name: status.name,
          value: status.id,
          label: status.name,
          id: status.id,
          key: status.id,
        };
      }
    );

    return twinfieldTransactionStatuses;
  }, [storeStatuses, i18n.resolvedLanguage]);
  return memoizedStatuses;
};
