import { config } from 'config';
import { Container } from './FwMapComponent.styled';
import GoogleMapReact, { Coords } from 'google-map-react';
import { useEffect, useState } from 'react';

const FwMapComponent = ({ lat, lng }: Coords) => {
  const [mapCenter, setMapCenter] = useState<null | Coords>({
    lat: Number(lat),
    lng: Number(lng),
  });

  useEffect(() => {
    setMapCenter({ lat: Number(lat), lng: Number(lng) });
  }, [lat, lng]);

  const codeflexDefaultProps = {
    center: {
      lat: 51.440505192756035,
      lng: 5.466932452685163,
    },
    zoom: 15,
  };

  const center = mapCenter || codeflexDefaultProps.center;

  const handleApiLoaded = (map: any, maps: any) => {
    new maps.Marker({
      position: { lat: Number(lat), lng: Number(lng) },
      map,
      title: 'Marker',
    });
  };

  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleMapsApiKey || '' }}
        defaultZoom={codeflexDefaultProps.zoom}
        defaultCenter={codeflexDefaultProps.center}
        center={center}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      ></GoogleMapReact>
    </Container>
  );
};

export default FwMapComponent;
