import { borderRadiusSm, COLORS, H4, marginMd, marginSm } from 'assets/styled';
import Tooltip from 'components/Tooltip/Tooltip';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop: string;
  top: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 770rem;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;
`;

export const RowMarginTop = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const CustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  text-transform: uppercase;
  margin-bottom: ${marginSm};
`;

export const ProductTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  margin-bottom: ${marginSm};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-bottom: ${marginSm};
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const SingleProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
`;

export const ProductSupplierLabel = styled(H4)``;

export const ProductFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30rem;
`;

export const GreenLine = styled.div`
  margin-top: ${marginMd};
  border-bottom: 1rem solid ${COLORS.LOGYX_LIGHT_GREEN};
  display: flex;
  width: 100%;
`;

export const TotalPurchasePriceWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const EyeWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 15rem;
  width: 20rem;
  ${(props: StyledProps) =>
    props.top &&
    css`
      top: ${(props: StyledProps) => props.top};
    `}
`;

export const ProductFormDTOFieldWrapper = styled.div`
  position: relative;
  max-width: 48%;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 48%;
`;
