import { COLORS, marginMd, paddingSm, paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $selected: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Headline = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
`;

export const NoLines = styled(Headline)`
  margin-bottom: 0;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
`;

export const Label = styled.span`
  line-height: 21rem;
  font-size: 14rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const HalfRow = styled.div`
  max-width: 45%;
  width: 100%;
  padding: 0 10rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HalfRowAlt = styled(HalfRow)`
  justify-content: space-between;
  max-width: 55%;
`;

export const SelectAll = styled(Label)`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  text-decoration: underline;
`;

export const Products = styled.div`
  border-radius: 10rem;
  border: 1rem solid ${COLORS.GREY_1000};
  overflow: hidden;
`;

export const Product = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_1000};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10rem 0;

  &:last-child {
    border-bottom: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : 'none'};
`;

export const Title = styled(Label)`
  color: ${COLORS.PRIMARY};
`;

export const Amount = styled(Label)`
  width: 80rem;
  text-align: center;
`;

export const More = styled(Label)`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  text-decoration-thickness: 1rem;
  text-underline-position: under;
`;

export const Select = styled.span`
  border: 1rem solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  background-color: ${COLORS.WHITE};
  font-size: 14rem;
  line-height: 1.4;
  padding: 5rem 10rem;
  border-radius: 10rem;
  transition: 0.3s all;
  min-width: 83rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }
`;

export const Margin = styled.div`
  margin-left: ${paddingXs};
`;
