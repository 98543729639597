import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1500rem;
`;

export const NoContentLabel = styled.label`
  margin-top: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  width: 100%;
  max-width: 100%;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
