export enum CreateStockItemTab {
  SUPPLIER_DETAILS = 'supplier',
  ITEMS = 'items',
  OVERVIEW = 'overview',
}

export interface IGetCityAndAddressDTO {
  zipCode: string;
  houseNumber: string;
}
