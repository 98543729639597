import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SingleQuotationRoutes } from '../../../navigation/SingleQuotation/SingleQuotation.routes';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SingleQuotationRoutes.SingleQuotationGeneral.path,
  },
  {
    text: 'Products & Services',
    route: SingleQuotationRoutes.SingleQuotationProducts.path,
  },
  {
    text: 'Comments',
    route: SingleQuotationRoutes.SingleQuotationComments.path,
  },
  {
    text: 'Quotations',
    route: SingleQuotationRoutes.SingleQuotationQuotations.path,
  },
  {
    text: 'Sales orders',
    route: SingleQuotationRoutes.SingleQuotationSales.path,
  },
  {
    text: 'Appointments',
    route: SingleQuotationRoutes.SingleQuotationAppointments.path,
  },
  {
    text: 'Reports',
    route: SingleQuotationRoutes.SingleQuotationReports.path,
  },
  {
    text: 'Files',
    route: SingleQuotationRoutes.SingleQuotationFiles.path,
  },
];

export enum QuotationStatuses {
  NEW = 'NEW',
  IN_TREATMENT = 'IN_TREATMENT',
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  FOLLOW_UP = 'FOLLOW_UP',
  COPIED_TO_NEW = 'COPIED_TO_NEW',
  PRICES_CHECK = 'PRICES_CHECK',
  WAITING_FOR_RESPONSE = 'WAITING_FOR_RESPONSE',
  MAKE_ORDER = 'MAKE_ORDER',
}

export const BASE_64_PREFIX = 'data:image/png;base64,';
