import {
  IPurchaseOrderLine,
  IPurchaseOrderLineReference,
} from 'types/PurchaseOrders.types';

export const getProductCardTitle = (
  purchaseOrderLine: IPurchaseOrderLine,
  t: any
) => {
  const prefixString = `${purchaseOrderLine.quantity}x - `;
  if (purchaseOrderLine.working_hours_line) {
    return t('Working hours');
  } else if (purchaseOrderLine.stock_item) {
    return purchaseOrderLine.stock_item?.name
      ? prefixString + purchaseOrderLine.stock_item?.name
      : prefixString + purchaseOrderLine.stock_item?.sku;
  } else {
    return prefixString + purchaseOrderLine.product?.name;
  }
};

export const formatPurchaseOrderLineReferences = (
  purchaseOrderLineReferences: IPurchaseOrderLineReference[]
): string[] => {
  return purchaseOrderLineReferences
    .filter(
      (purchaseOrderLineReference: IPurchaseOrderLineReference) =>
        purchaseOrderLineReference.reference !== null
    )
    .map(
      (purchaseOrderLineReference: IPurchaseOrderLineReference) =>
        purchaseOrderLineReference.reference as string
    );
};
