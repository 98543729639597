import { SortDirection } from 'components/Table/constants';
import { ICreateCreditInvoiceDTO } from 'pages/Manager/NewCreditInvoice/constants';
import i18n from 'providers/i18n/i18n';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { ICreatePurchaseInvoiceDTO } from 'types/Invoice.types';
import { openFileInNewTab } from 'utils/openFileInNewTab';

const BASE_URL = '/v1/purchase-invoices/';

export const getPurchaseInvoices = async (
  page: number,
  perPage: number,
  searchBy?: string,
  filterNotPaid?: boolean,
  statusOptions?: string,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(searchBy && { search: searchBy }),
    ...(statusOptions && { invoice_statuses: statusOptions }),
    ...(dateInterval && { date_interval: dateInterval }),
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(filterNotPaid && { filter_not_paid: filterNotPaid }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
  };

  const fullUrl = getUrlWithQueryParams(BASE_URL, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSinglePurchaseInvoice = async (
  invoiceId: string
): Promise<any> => {
  const url = `${BASE_URL}${invoiceId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const createPurchaseInvoice = async (
  createInvoiceData: ICreatePurchaseInvoiceDTO
): Promise<any> => {
  const { data } = await apiClient.post<any>(BASE_URL, createInvoiceData);

  return data.data;
};

export const getPurchaseInvoiceFiles = async (
  purchaseInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${purchaseInvoiceId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadPurchaseInvoiceFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deletePurchaseInvoiceFile = async (
  purchaseInvoiceId: string,
  purchaseInvoiceFileId: string
) => {
  const url = BASE_URL + `${purchaseInvoiceId}/files/${purchaseInvoiceFileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const exportPurchaseInvoice = async (
  purchaseInvoiceId: string
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${purchaseInvoiceId}/export`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl, purchaseInvoiceId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const changePurchaseInvoiceStatus = async (
  id: string,
  status_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/status`;
  const { data } = await apiClient.patch<any>(url, {
    new_status_id: status_id,
  });

  return data.data;
};

export const getSinglePurchaseInvoicePayments = async (
  purchaseInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${purchaseInvoiceId}/payments`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const createPurchaseCreditInvoice = async (
  createInvoiceData: ICreateCreditInvoiceDTO
): Promise<any> => {
  delete createInvoiceData.isForSalesInvoice;

  const { data } = await apiClient.post<any>(BASE_URL, createInvoiceData);

  return data.data;
};

export const getSinglePurchaseInvoiceCreditInvoices = async (
  purchaseInvoiceId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${purchaseInvoiceId}/credit-invoices`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const changePurchaseInvoiceExternalId = async (
  id: string,
  external_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.patch<any>(url, {
    external_id: external_id,
  });

  return data.data;
};

export const editPurchaseInvoiceFile = async (
  purchaseInvoiceId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${purchaseInvoiceId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const getPurchaseInvoiceInfoById = async (
  purchaseInvoiceId: string
): Promise<any> => {
  const url = `${BASE_URL}${purchaseInvoiceId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};
