import * as Yup from 'yup';

export const addCommentValidationSchema = Yup.object().shape({
  headline: Yup.string()
    .required('Headline is required')
    .min(5, 'Headline must be at least 5 characters long')
    .max(100, 'Headline should be between 5 and 100 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(10, 'Description must be at least 10 characters long')
    .max(100, 'Description should be between 10 and 100 characters'),
  is_internal: Yup.boolean(),
});
