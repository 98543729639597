import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  disabled: string;
  color: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ClickableContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  accent-color: ${COLORS.PRIMARY};
  appearance: none;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background-color: ${COLORS.STRICT_WHITE};
  position: relative;
  cursor: pointer;
  border: 1px solid ${COLORS.GREY_1000};
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      background-color: ${COLORS.GREY_500};
      cursor: not-allowed !important;
      border-color: transparent;

      &::before,
      &::after {
        background-color: ${COLORS.STRICT_WHITE};
      }
    `};

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: 150ms all linear;
  }

  &::before {
    transform: translate(4.5px, 7.5px) rotate(-50deg);
    transform-origin: left;
    transition-delay: 0ms;
  }

  &::after {
    transform: translate(2px, 4px) rotate(45deg);
    transform-origin: left;
    transition-delay: 150ms;
  }

  &:checked {
    background-color: ${(props: StyledProps) =>
      props.disabled ? COLORS.GREY_500 : COLORS.PRIMARY};
    border-color: transparent;

    &::before,
    &::after {
      background-color: ${COLORS.STRICT_WHITE};
    }

    &::before {
      width: 8px;
      transition-delay: 150ms;
    }

    &::after {
      width: 5px;
      transition-delay: 0ms;
    }
  }
`;

export const CheckboxSpan = styled.span`
  margin-left: 7rem;
  font-weight: 300;
  font-size: 14rem;
  user-select: none;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `};
`;
