import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSingleSalesInvoicePayments } from 'services/Invoice/SalesInvoiceService';
import { deletePayment } from 'services/Payment/PaymentService';

export const useGetSingleSalesInvoicePayments = (
  invoiceId: string,
  perPage: number
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_INVOICE_PAYMENTS,
      invoiceId,
      perPage,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getSingleSalesInvoicePayments(invoiceId, pageParam, perPage),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useDeletePayment = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deletePayment(id), {
    onSuccess: (data) => {
      toast.success(t('Payment deleted'), {
        toastId: ReactMutationKeys.DELETE_PAYMENT,
        className: ReactMutationKeys.DELETE_PAYMENT,
      });
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_SALES_INVOICE_INFO,
      ]);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_PAYMENT,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        ReactQueryKeys.GET_SINGLE_SALES_INVOICE_PAYMENTS
      );
    },
    mutationKey: ReactMutationKeys.DELETE_PAYMENT,
  });
};
