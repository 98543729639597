import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient from '../api/apiService';

const BASE_URL = '/v1/notifications/';

export const getNotifications = async (
  page: number,
  perPage: number,
  searchBy: string
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
    search: searchBy,
  };
  const fullUrl = getUrlWithQueryParams(BASE_URL, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const markAsRead = async (notificationId: number): Promise<any> => {
  const url = `${BASE_URL}${notificationId}/read`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const markAllAsRead = async (): Promise<any> => {
  const url = `${BASE_URL}read-all`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getUnreadNotificationsCount = async (): Promise<any> => {
  const url = `${BASE_URL}unread-count`;
  const { data } = await apiClient.get<any>(url);
  return data.data.count;
};
