import { Option } from './type';
import { SelectWrapper, StyledReactSelect } from './Select.styled';
import { useState } from 'react';
import Placeholder from 'components/Input/Placeholder/Placeholder';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';
import CustomMultiValueRemove from './SelectComponents/CustomMultiValueRemove/CustomMultiValueRemove';
import CustomDropdownIndicator from './SelectComponents/CustomDropdownIndicator/CustomDropdownIndicator';
import { useTranslation } from 'react-i18next';
import CustomClearIndicator from './SelectComponents/CustomClearIndicator/CustomClearIndicator';

interface ISelectProps {
  options: Option[];
  placeholder: string;
  name: string;
  onChange: (selectedValue: Option) => void;
  onInputChange?: (value: string) => void;
  onDeleteOption?: (option: Option) => void;
  menuIsOpen?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  defaultValue?: Option | Option[];
  isOptionDisabled?: (option: Option) => boolean;
  height?: string;
  menuHeight?: string;
  components?:
    | Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  onMenuScrollToBottom?: () => void;
  pwId?: string;
  isLoading?: boolean;
  translate?: boolean;
  labelTransform?: (str: string) => string;
  applyOptionStyle?: boolean;
  menuPlacement?: string;
}

const checkInitialIsValueSelected = (
  defaultValue: Option | Option[] | undefined
) => {
  if (Array.isArray(defaultValue)) {
    return (
      defaultValue.length > 0 &&
      defaultValue.every(
        (option) =>
          typeof option === 'object' &&
          option.value !== undefined &&
          option.label !== undefined
      )
    );
  } else {
    return (
      defaultValue != null &&
      typeof defaultValue === 'object' &&
      defaultValue.value !== undefined &&
      defaultValue.label !== undefined
    );
  }
};

export const Select = ({
  name,
  height = '40rem',
  menuHeight = '300rem',
  placeholder,
  isMulti,
  isDisabled,
  isSearchable,
  onChange,
  onInputChange,
  onDeleteOption,
  menuIsOpen,
  options,
  defaultValue,
  isOptionDisabled,
  components,
  onMenuScrollToBottom,
  pwId,
  isLoading,
  translate = true,
  labelTransform = (str: string) => str,
  applyOptionStyle = false, //Used in EmailTemplates
  menuPlacement = 'auto',
}: ISelectProps) => {
  const [isInputEmpty, setIsInputEmpty] = useState<boolean>(true);
  const [isValueSelected, setIsValueSelected] = useState<boolean>(
    checkInitialIsValueSelected(defaultValue)
  );

  const { t } = useTranslation();

  const formatOptionLabel = (
    { label, value }: any,
    { context }: { context: 'menu' | 'value' }
  ) => {
    const optionStyle = {
      fontSize: context === 'menu' && applyOptionStyle ? value : 'inherit',
    };
    return (
      <div
        data-testid={`${pwId}-${
          context === 'value' ? 'selected-' : ''
        }option-${label}`}
        style={{ display: 'flex', ...optionStyle }}
      >
        {translate ? labelTransform(t(label)) : labelTransform(label)}
      </div>
    );
  };

  return (
    <SelectWrapper data-testid={pwId}>
      <StyledReactSelect
        formatOptionLabel={formatOptionLabel}
        components={{
          MultiValueRemove: (props: any) => (
            <CustomMultiValueRemove
              {...props}
              pwId={`${pwId}-multi-value-remove`}
            />
          ),
          DropdownIndicator: (props: any) => (
            <CustomDropdownIndicator isLoading={isLoading} {...props} />
          ),
          ClearIndicator: (props: any) => <CustomClearIndicator {...props} />,
          IndicatorSeparator: null,
          ...components,
        }}
        menuPlacement={menuPlacement}
        height={height}
        menuHeight={menuHeight}
        options={options}
        placeholder="&nbsp;"
        aria-label={`select-${name}`}
        onChange={(e: any) => {
          onChange && onChange(e);
          setIsValueSelected(true);
        }}
        onDeleteOption={onDeleteOption}
        menuIsOpen={menuIsOpen}
        classNamePrefix="select"
        isSearchable={isSearchable}
        isMulti={isMulti}
        isOptionDisabled={isOptionDisabled}
        isDisabled={isDisabled}
        onInputChange={(value: any) => {
          onInputChange && onInputChange(value);
          if (value) {
            setIsInputEmpty(false);
          } else {
            setIsInputEmpty(true);
          }
        }}
        defaultValue={defaultValue}
        onMenuScrollToBottom={onMenuScrollToBottom}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
      <Placeholder
        isTranslatedToTop={isValueSelected || !isInputEmpty}
        isFormikPlaceholder={false}
        isSelectPlaceholder={true}
        placeholder={placeholder}
      />
    </SelectWrapper>
  );
};
