import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { reorderSalesInvoiceLines } from 'services/Invoice/SalesInvoiceService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';

export const useReorderSalesInvoiceLines = (id: number) => {
  const { t } = useTranslation();
  return useMutation(
    (dto: IReorderLinesRequestDTO) => reorderSalesInvoiceLines(id, dto),
    {
      onSuccess: () => {
        toast.success(t('Sales invoice lines reordered'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REORDER_SALES_INVOICE_LINES,
        });
      },
      mutationKey: ReactMutationKeys.REORDER_SALES_INVOICE_LINES,
    }
  );
};
