import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Input } from 'components/Input/InputFormik';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './EditExternalReferenceModal.styled';
import Button from 'components/Button/Button';
import { useChangePurchaseInvoiceExternalId } from '../SinglePurchaseInvoice/hooks';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { useChangeSalesInvoiceExternalId } from '../SingleSalesInvoice/hooks';
import { useChangeCreditSalesInvoiceExternalId } from '../SingleSalesCreditInvoice/hooks';
import { useChangeCreditPurchaseInvoiceExternalId } from '../SinglePurchaseCreditInvoice/hooks';

interface IEditExternalReferenceModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  externalId: string;
  type: InvoiceType;
}

export const EditExternalReferenceModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  externalId,
  type,
}: IEditExternalReferenceModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { mutate: changePurchaseInvoiceReference } =
    useChangePurchaseInvoiceExternalId();
  const { mutate: changeSalesInvoiceReference } =
    useChangeSalesInvoiceExternalId();
  const { mutate: changeCreditSalesInvoiceReference } =
    useChangeCreditSalesInvoiceExternalId();
  const { mutate: changeCreditPurchaseInvoiceReference } =
    useChangeCreditPurchaseInvoiceExternalId();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit external reference')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={(values) => {
            //
          }}
          initialValues={{
            externalId: externalId ? externalId : '',
          }}
          validateOnBlur={true}
        >
          {({ errors, values, setFieldValue, handleBlur, touched }) => {
            return (
              <Container>
                <Input
                  pwId="external-reference-field"
                  errorMessage={
                    touched['externalId'] ? errors['externalId'] : ''
                  }
                  placeholder={t('External reference')}
                  name="externalId"
                  height={'41rem'}
                  wrapperStyles={{
                    width: '300rem',
                  }}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('externalId', value);
                    handleBlur(e);
                  }}
                />
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!values.externalId}
                    onClick={() => {
                      type === InvoiceType.PURCHASE_INVOICE
                        ? changePurchaseInvoiceReference({
                            id: id!,
                            external_id: values.externalId,
                          })
                        : type === InvoiceType.CREDIT_SALES_INVOICE
                        ? changeCreditSalesInvoiceReference({
                            id: id!,
                            external_id: values.externalId,
                          })
                        : type === InvoiceType.CREDIT_PURCHASE_INVOICE
                        ? changeCreditPurchaseInvoiceReference({
                            id: id!,
                            external_id: values.externalId,
                          })
                        : changeSalesInvoiceReference({
                            id: id!,
                            external_id: values.externalId,
                          });
                      setIsOpen(false);
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
