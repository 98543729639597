import styled from 'styled-components';
import { COLORS, paddingXs, marginXs, paddingSm } from 'assets/styled';
import { Field } from 'formik';
import { hoverLineOut } from 'assets/styled/commonStyles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${paddingXs};
  max-height: 859rem;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
  width: 100%;
  max-width: 100%;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  max-width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
`;

export const TimeHeading = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeWrapper = styled.div`
  width: 50%;
  max-width: 100%;
`;

export const AltTimeWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding-top: 20rem;
`;
export const WorkersAndResources = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;

export const StyledSelect = styled(Field)`
  width: 100%;
  margin-bottom: ${marginXs};
  padding-top: 10rem;
`;

export const Cont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HalfCont = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10rem);
`;

export const FieldLabel = styled.label`
  font-size: 16rem;
  font-weight: 300;
  line-height: 27rem;
  padding: ${marginXs} 0;
`;

export const CheckboxContainer = styled.div`
  margin-top: ${paddingSm};
`;

export const UsersHeading = styled.div`
  margin: ${marginXs};
`;

export const UsersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Scrollable = styled.div`
  max-height: calc(70vh);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 30rem;
`;

export const TimeIntervalErrorMessageCont = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TimeIntervalErrorMessage = styled.label`
  font-size: 14rem;
  font-weight: 100;
  color: ${COLORS.RED_200};
  margin-bottom: 5rem;
`;

export const DateTimeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10rem;
`;

export const ArrivalTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-top: 5rem;
`;

export const ArrivalTimeInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
`;

export const ArrivalTimeText = styled.span`
  ${hoverLineOut}
  cursor: pointer;
  display: inline-block;
  align-self: auto;
  font-size: 18rem;
`;

export const ArrivalTime = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.PRIMARY};
`;

export const DateLabel = styled.label`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  display: block;
  margin-top: 5rem;
  font-weight: 300;
`;
