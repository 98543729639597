import { useMutation } from 'react-query';
import { getIntentByUUID } from 'services/Logyx/LogyxService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useIsFocused } from 'utils/hooks/useIsFocused';
import { Dispatch, useEffect } from 'react';
import { ILogyxReducerState } from 'store/Logyx/reducers/logyx';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { AnyAction } from '@reduxjs/toolkit';
import {
  setIsAwaitingConfiguration,
  setIsAwaitingEdit,
  setLogyxConfigurationIntent,
} from 'store/Logyx/actions/logyx';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import { EnvironmentType, config } from 'config';

const useGetIntentByUUID = () =>
  useMutation((intentUUID: string) => getIntentByUUID(intentUUID), {
    onError: () => {
      // Silent error
    },
    mutationKey: ReactMutationKeys.GET_INTENT_BY_UUID,
  });

// This hook is used as a fallback on production/staging for sockets inside ConfigureLogyxModal and EditWithLogyxModal.
// It obtains the configuration if it exists (If the intent is in status SUCCESS)
// This hook is disabled on dev so socket issues can be caught early on.
export const useCheckForExistingLogyxConfigurationOnPageFocus = (
  isEdit: boolean,
  intentUUID: string | undefined
) => {
  const isProductionOrStagingEnvironment = [
    EnvironmentType.PROD,
    EnvironmentType.STAGING,
  ].includes(config.environment as EnvironmentType);

  const logyxInfo: ILogyxReducerState = useSelector(
    (state: IRootReducerState) => state.logyxInfo
  );
  const isMobileApp: boolean = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const {
    mutate: getIntentByUUID,
    isLoading: isLoadingGetIntentByUUID,
    reset: resetGetIntentByUUID,
  } = useGetIntentByUUID();

  const isAwaitingLogyx =
    logyxInfo.isAwaitingConfiguration || logyxInfo.isAwaitingEdit;

  useEffect(() => {
    if (
      isProductionOrStagingEnvironment &&
      isFocused &&
      intentUUID &&
      !isLoadingGetIntentByUUID &&
      isAwaitingLogyx
    ) {
      getIntentByUUID(intentUUID, {
        onSuccess: (intent: ILogyxConfigurationIntent) => {
          handleGetIntentUUIDSuccess(
            isEdit,
            dispatch,
            intent,
            logyxInfo,
            isMobileApp
          );
          resetGetIntentByUUID();
        },
      });
    }
  }, [isFocused, intentUUID, isAwaitingLogyx]);
};

const handleGetIntentUUIDSuccess = (
  isEdit: boolean,
  dispatch: Dispatch<AnyAction>,
  intent: ILogyxConfigurationIntent,
  logyxInfo: ILogyxReducerState,
  isMobileApp: boolean
) => {
  isEdit
    ? handleEditConfigurationSuccess(dispatch, intent, logyxInfo, isMobileApp)
    : handleInitialConfigurationSuccess(
        dispatch,
        intent,
        logyxInfo,
        isMobileApp
      );
};

const handleInitialConfigurationSuccess = (
  dispatch: Dispatch<AnyAction>,
  intent: ILogyxConfigurationIntent,
  logyxInfo: ILogyxReducerState,
  isMobileApp: boolean
) => {
  if (
    logyxInfo.isAwaitingConfiguration &&
    logyxInfo.pendingIntentUUID === intent.uuid
  ) {
    window.focus();
    dispatch(setIsAwaitingConfiguration(false));
    dispatch(setLogyxConfigurationIntent(intent));
    if (isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.CLOSE_LOGYX,
      };
      postMobileMessage(mobileMessage);
    }
  } else {
    if (logyxInfo.isAwaitingConfiguration) {
      console.log(
        `Expected intent with uuid ${logyxInfo.pendingIntentUUID}, instead got ${intent.uuid}`
      );
    }
  }
};

const handleEditConfigurationSuccess = (
  dispatch: Dispatch<AnyAction>,
  intent: ILogyxConfigurationIntent,
  logyxInfo: ILogyxReducerState,
  isMobileApp: boolean
) => {
  if (logyxInfo.isAwaitingEdit && logyxInfo.pendingIntentUUID === intent.uuid) {
    window.focus();
    dispatch(setIsAwaitingEdit(false));
    dispatch(setLogyxConfigurationIntent(intent));
    if (isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.CLOSE_LOGYX,
      };
      postMobileMessage(mobileMessage);
    }
  } else {
    if (logyxInfo.isAwaitingEdit) {
      console.log(
        `Expected intent with uuid ${logyxInfo.pendingIntentUUID}, instead got ${intent.uuid}`
      );
    }
  }
};
