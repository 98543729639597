import { SingleSupplierAppointmentsPage } from 'pages/Manager/SingleSupplierPage/SingleSupplierAppointmentsPage/SingleSupplierAppointmentsPage';
import { SingleSupplierGeneralPage } from 'pages/Manager/SingleSupplierPage/SingleSupplierGeneralPage/SingleSupplierGeneralPage';
import { SingleSupplierProductsPage } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/SingleSupplierProductsPage';
import { SingleSupplierPurchaseOrdersPage } from 'pages/Manager/SingleSupplierPage/SingleSupplierPurchaseOrdersPage/SingleSupplierPurchaseOrdersPage';
import { Route, Routes } from 'react-router-dom';
import { SingleSupplierRoutes } from './SingleSupplier.routes';

const SingleSupplierRouter = () => {
  return (
    <Routes>
      <Route
        path={SingleSupplierRoutes.SingleSupplierGeneral.path}
        element={<SingleSupplierGeneralPage />}
      />
      <Route
        path={SingleSupplierRoutes.SingleSupplierProducts.path}
        element={<SingleSupplierProductsPage />}
      />
      <Route
        path={SingleSupplierRoutes.SingleSupplierPurchaseOrders.path}
        element={<SingleSupplierPurchaseOrdersPage />}
      />
      {/* <Route
        path={SingleSupplierRoutes.SingleSupplierAppointments.path}
        element={<SingleSupplierAppointmentsPage />}
      /> */}
    </Routes>
  );
};

export default SingleSupplierRouter;
