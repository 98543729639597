import { COLORS, marginMd, marginXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ProductNameLabel = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;

export const FilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30rem;
`;

export const FilterTypeLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const CloseWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;

export const HorizontalLine = styled.div`
  width: 500rem;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-bottom: ${marginXs};
`;
