import { IProduct } from 'types/Product.types';

export const formatTableData = (data: IProduct[]) => {
  return data?.map((product: IProduct) => {
    const tableDTO: any = {
      name: product.name,
      externalNote: product.description,
      id: product.id,
    };
    return tableDTO;
  });
};
