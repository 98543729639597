export const mapFileExtension = (type: string) => {
  switch (type) {
    case 'javascript':
      return 'js';
    case 'plain':
      return 'txt';
    case 'jpeg':
      return 'jpg';
    case 'svg+xml':
      return 'svg';
    case 'x-icon':
      return 'ico';
    case 'msword':
      return 'doc';
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'vnd.ms-powerpoint':
      return 'ppt';
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'pptx';
    case 'vnd.ms-excel':
      return 'xls';
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xslx';
    case 'x-rar-compressed':
      return 'rar';
    case 'x-tar':
      return 'tar';
    case 'gzip':
      return 'gz';
    case 'x-7z-compressed':
      return '7z';
    default:
      return type;
  }
};
