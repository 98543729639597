import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IEditQuotationWorkingHoursLine } from './types';
import { editQuotationWorkingHoursLine } from 'services/Quotation/QuotationService';

export const useEditQuotationWorkingHoursLine = (
  quotationId: string,
  quotationLineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (workingHoursLineDTO: IEditQuotationWorkingHoursLine) =>
      editQuotationWorkingHoursLine(
        quotationId,
        quotationLineId,
        workingHoursLineDTO
      ),
    {
      onSuccess: () => {
        toast.success(t('Quotation line edited'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION,
          quotationId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_WORKING_HOURS_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_QUOTATION_WORKING_HOURS_LINE,
    }
  );
};
