import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  CloseAbsoluteWrapper,
  Container,
  Header,
  Headline,
  Wrapper,
  SubmitRow,
  Label,
  Appointments,
  Product,
  Title,
  QuarterRow,
  Subtitle,
} from './PendingAppointmentsModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { IAppointment } from 'types/Appointment.types';
import moment from 'moment';

interface IPendingAppointmentsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  appointments: IAppointment[];
  onCancel: () => void;
  onSelect: (appointmentId: number) => void;
}

const PendingAppointmentsModal = ({
  isOpen,
  setIsOpen,
  appointments,
  onCancel,
  onSelect,
}: IPendingAppointmentsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (boolean) {
          setIsOpen(boolean);
        } else {
          onCancel();
        }
      }}
      modalStyle={{ margin: 'auto', position: 'fixed', maxWidth: '750rem' }} // Center positioning>
    >
      <Container>
        <CloseAbsoluteWrapper>
          <Close onClick={() => onCancel()} />
        </CloseAbsoluteWrapper>
        <Headline>{t('Pending appointments')}</Headline>
        <Subtitle>
          {t(
            'Is there an active appointment you would like to add this line to'
          ) + '?'}
        </Subtitle>
        <Wrapper>
          <Header>
            <QuarterRow>
              <Label>{t('ID')}</Label>
            </QuarterRow>
            <QuarterRow>
              <Label>{t('Date')}</Label>
            </QuarterRow>
            <QuarterRow>
              <Label>{t('Time')}</Label>
            </QuarterRow>
            <QuarterRow>
              <Label>{t('Type')}</Label>
            </QuarterRow>
            <QuarterRow />
          </Header>
          <Appointments>
            {appointments?.map((appointment: IAppointment) => {
              return (
                <Product key={appointment.id}>
                  <QuarterRow>
                    <Title>{appointment.id}</Title>
                  </QuarterRow>
                  <QuarterRow>
                    {moment(appointment.date_from).format('DD-MM-YYYY')}
                  </QuarterRow>
                  <QuarterRow>
                    {moment(appointment.date_from).format('HH:mm') +
                      ' - ' +
                      moment(appointment.date_to).format('HH:mm')}
                  </QuarterRow>
                  <QuarterRow>
                    <Title>{t(appointment.type.name)}</Title>
                  </QuarterRow>
                  <QuarterRow>
                    <Button
                      label={t('Connect')}
                      width={'100rem'}
                      primary
                      onClick={() => onSelect(appointment.id)}
                    />
                  </QuarterRow>
                </Product>
              );
            })}
          </Appointments>
        </Wrapper>
        <SubmitRow>
          <Button
            width={'200rem'}
            onClick={() => {
              onCancel();
            }}
            label={t('Skip')}
            secondary
          />
        </SubmitRow>
      </Container>
    </Modal>
  );
};

export default PendingAppointmentsModal;
