import Icon from 'components/Icon/Icon';
import {
  CurrentItemsLabel,
  RowCenter,
  TotalItemsLabel,
} from './Pagination.styled';
import { COLORS, marginXs } from 'assets/styled';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

interface IPaginatedProps {
  page: number;
  perPage: number;
  total: number;
  loadPage: (page: number) => void;
}

export const Pagination = ({
  page,
  perPage,
  total,
  loadPage,
}: IPaginatedProps) => {
  const getSecondPaginationNumber = () => {
    if (page * perPage > total) {
      return total;
    } else {
      return page * perPage;
    }
  };
  const hasNextPage = perPage * page < total;

  const handleLoadPage = (newPage: number) => {
    if (newPage === 0) {
      return;
    }
    loadPage(newPage);
  };

  return (
    <RowCenter>
      <Icon
        svg={CaretLeft}
        color={page === 1 ? COLORS.GREY_1000 : COLORS.PRIMARY}
        size={18}
        weight="bold"
        onClick={() => handleLoadPage(page - 1)}
        marginRight={marginXs}
      />
      <CurrentItemsLabel>
        {(page - 1) * perPage + 1} - {getSecondPaginationNumber()}
      </CurrentItemsLabel>
      &nbsp; <TotalItemsLabel> of {total}</TotalItemsLabel>
      <Icon
        svg={CaretRight}
        color={hasNextPage ? COLORS.PRIMARY : COLORS.GREY_1000}
        size={18}
        weight="bold"
        marginLeft={marginXs}
        onClick={() => hasNextPage && handleLoadPage(page + 1)}
      />
    </RowCenter>
  );
};

export default Pagination;
