import { useQuery } from 'react-query';
import { getUnreadNotificationsCount } from 'services/Notification/NotificationService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetUnreadNotificationsCount = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
    queryFn: () => {
      return getUnreadNotificationsCount();
    },
  });
