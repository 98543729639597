import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createPurchaseOrder } from 'services/PurchaseOrder/PurchaseOrderService';
import { ICreatePurchaseOrderData } from 'types/PurchaseOrders.types';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useCreatePurchaseOrder = () =>
  useMutation(
    (createPurchaseOrderData: ICreatePurchaseOrderData) =>
      createPurchaseOrder(createPurchaseOrderData),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_PURCHASE_ORDER,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDERS);
      },
      mutationKey: ReactMutationKeys.CREATE_PURCHASE_ORDER,
    }
  );

export const useGetPrepopulatePurchaseOrderData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulatePurchaseOrderData;
  } else {
    return null;
  }
};
