import { ITableConfig, ITableRowConfig } from 'components/Table/Table';
import { CellType } from 'components/Table/constants';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: true },
  { header: 'Name', flexValue: 1, isBlue: false },
  { header: 'Address', flexValue: 1, isBlue: false },
  { header: 'Zip code', flexValue: 1, isBlue: false },
  { header: 'Company name', flexValue: 1.5, isBlue: false },
  { header: 'Total spent', flexValue: 1, isBlue: false },
  { header: 'Total orders', flexValue: 1, isBlue: true },
  {
    header: 'Accounting',
    flexValue: 1,
    isBlue: true,
    cellType: CellType.ACCOUNTING,
  },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const CUSTOMERS_PER_PAGE = 10;
