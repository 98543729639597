import { COLORS } from 'assets/styled';
import { marginLg, marginMd, marginXs } from 'assets/styled/variables';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface StyledProps {
  width: string;
}

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 15rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }
`;

export const Label = styled.label`
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 500rem;
  margin-bottom: ${marginLg};

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }
`;

export const EmailInputWrapper = styled.div`
  margin-bottom: 20rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RememberMeForgotPasswordWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

export const RememberMeLabel = styled.label`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  margin-left: ${marginXs};
`;

export const ForgotPasswordLabel = styled(Link)`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  text-decoration: underline;
  color: ${COLORS.BLACK};
`;

export const EyeWrapper = styled.div`
  position: absolute;
  top: 14rem;
  right: 15rem;
  width: 25rem;
  cursor: pointer;
  user-select: none;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${marginXs};
`;

export const ButtonContainer = styled.div`
  margin-top: ${marginMd};
  width: 100%;
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0rem;
  top: 0rem;
  display: flex;
  align-items: center;
`;

export const LanguageSwitcherWrapper = styled.div`
  margin-left: 20rem;
`;

export const RelativeCenterWrapper = styled.div`
  top: -700rem;
  position: relative;
  background-color: red;
  width: 580rem;
`;
