class LocalStorageService {
  getItem = (key: string) => {
    return localStorage.getItem(key);
  };

  setItem = (key: string, value: string) => {
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  };

  removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  removeAll = async () => {
    await localStorage.removeAll();
  };
}

export default new LocalStorageService();
