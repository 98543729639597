import { useTranslation } from 'react-i18next';
import {
  BlueQuantityLabel,
  Column,
  Container,
  HorizontalLine,
  Label,
  Row,
} from './PreviewDayAppointmentCard.styled';
import { gapSm, gapXs } from 'assets/styled';
import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import { useIsFieldWorker } from 'components/Routes/FieldWorkerRoute/hooks';
import { IAppointmentSummaryData } from '../PreviewDayAppointmentsModal';
import { IAppointment } from 'types/Appointment.types';
import moment from 'moment';
import { IQuotationLine } from 'types/Quotations.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import { getAppointmentShippingData } from 'pages/Manager/PlanningPage/helpers';

interface IPreviewDayAppointmentCardProps {
  appointmentSummary: IAppointmentSummaryData;
  isLast: boolean;
}

export const PreviewDayAppointmentCard = ({
  appointmentSummary,
  isLast,
}: IPreviewDayAppointmentCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isFieldWorker = useIsFieldWorker();

  const handleClick = () => {
    if (isFieldWorker) {
      navigate(
        `${RoutesConfig.FwAppointments.fullPath}/${appointmentSummary.appointment.id}`
      );
    }
  };

  const appointment: IAppointment = appointmentSummary.appointment;

  const shippingData = getAppointmentShippingData(appointment);

  const lines: IQuotationLine[] | ISalesOrderLine[] =
    appointmentSummary.quotation_lines
      ? appointmentSummary.quotation_lines
      : appointmentSummary.sales_order_lines;

  return (
    <Container onClick={handleClick} isCursorPointer={isFieldWorker}>
      <Row gap={gapSm}>
        <Label fontSize={'24rem'}>{t(appointment.type.name)}</Label>
        <Label fontSize={'18rem'}>{appointment.id}</Label>
      </Row>
      <Row gap={'27rem'}>
        <Row gap={gapXs}>
          <Label fontSize={'14rem'}>{t('From') + ':'}</Label>
          <Label fontSize={'18rem'}>
            {moment(appointment.date_from).format('HH:mm')}
          </Label>
        </Row>
        <Row gap={gapXs}>
          <Label fontSize={'14rem'}>{t('To') + ':'}</Label>
          <Label fontSize={'18rem'}>
            {moment(appointment.date_to).format('HH:mm')}
          </Label>
        </Row>
      </Row>
      <Column gap={gapXs}>
        <Label fontSize={'18rem'}>
          {shippingData
            ? `${shippingData.first_name} ${shippingData.last_name}`
            : `${appointment.customer.name} ${appointment.customer.last_name}`}
        </Label>
        <Row gap={gapSm}>
          <Label fontSize={'16rem'}>
            {shippingData
              ? shippingData.address.full_address
              : appointment.customer.address.full_address}
          </Label>
          <Label fontSize={'16rem'}>
            {shippingData ? shippingData.phone : appointment.customer.phone}
          </Label>
        </Row>
      </Column>
      <Column gap={gapXs}>
        <Label fontSize={'16rem'}>{t('WORKERS')}</Label>
        <Label fontSize={'18rem'}>
          {appointment.workers.map(
            (worker: IWorkerAndResource, index: number) =>
              `${worker.name}${
                appointment.workers.length - 1 > index ? ', ' : ''
              }`
          )}
        </Label>
      </Column>
      <Column gap={gapXs}>
        <Label fontSize={'16rem'}>{t('RESOURCES')}</Label>
        <Label fontSize={'18rem'}>
          {appointment.resources.map(
            (resource: IWorkerAndResource, index: number) =>
              `${resource.name}${
                appointment.resources.length - 1 > index ? ', ' : ''
              }`
          )}
        </Label>
      </Column>
      <Column gap={gapXs}>
        <Label fontSize={'16rem'}>{t('PRODUCTS')}</Label>
        {lines.map((line: IQuotationLine | ISalesOrderLine, index: number) => (
          <Label fontSize={'18rem'} key={index}>
            {`${line?.product?.name}${lines.length - 1 > index ? ', ' : ''} `}
            <BlueQuantityLabel>{line?.quantity}x</BlueQuantityLabel>
          </Label>
        ))}
      </Column>
      {isLast ? null : <HorizontalLine />}
    </Container>
  );
};
