import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 18rem;
  margin-bottom: 20rem;
`;

export const Subtitle = styled.label`
  font-weight: 300;
  font-size: 14rem;
`;
