import { Select } from 'components/Select/Select';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { useGetSuppliersInfinite } from 'pages/Manager/NewQuotationPage/ProductTab/AddNewProduct/hooks';
import { useGetSingleSupplier } from 'pages/Manager/SingleSupplierPage/hooks';
import { Option } from 'components/Select/type';

const SUPPLIERS_PER_PAGE = 10;

interface ISuppliersSelectProps {
  handleValueChange: (e: any) => void;
  defaultValue: Option | number | string;
}

const SuppliersSelect = ({
  handleValueChange,
  defaultValue,
}: ISuppliersSelectProps) => {
  const { t } = useTranslation();
  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const {
    data: suppliersData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingSuppliers,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBy, true);

  const {
    data: supplierData,
    isLoading: isLoadingSupplier,
    isFetched,
  } = useGetSingleSupplier(
    defaultValue?.toString() || '',
    typeof defaultValue === 'string' || typeof defaultValue === 'number'
  );

  const isLoading = isLoadingSupplier || isLoadingSuppliers;

  const preselectedSupplier =
    typeof defaultValue === 'object'
      ? defaultValue
      : {
          label: isFetched ? supplierData?.supplier?.company_name : '',
          value: supplierData?.supplier?.id,
        };

  const suppliers = useMemo(() => {
    if (suppliersData?.pages?.length) {
      return suppliersData.pages
        .map((page) => page.suppliers)
        .flat()
        .map((supplier: any) => {
          return {
            value: supplier?.id,
            label: supplier?.company_name,
          };
        });
    }
    return [];
  }, [suppliersData]);

  return (
    <Select
      key={`${isLoadingSupplier}`}
      name="advancedFilterValue"
      placeholder={t('Value')}
      isMulti={false}
      isDisabled={false}
      isSearchable={true}
      defaultValue={preselectedSupplier}
      onChange={handleValueChange}
      onInputChange={(e: string) => setSearchBy(e)}
      onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
      options={suppliers || []}
      labelTransform={(string: string) => capitalizeFirstLetter(string)}
      isLoading={isLoading}
    />
  );
};

export default SuppliersSelect;
