import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ICreateCreditInvoiceDTO,
  ICreateCreditInvoiceFormDTO,
} from './constants';
import {
  useCreateCreditInvoice,
  useGetOffsetPaymentTypeId,
  useGetPrepopulateCreditInvoiceData,
  useSalesCreditInvoiceCreationInfo,
} from './hooks';
import {
  ButtonsWrapper,
  Container,
  CreateNewCreditInvoiceTitle,
  FormikContainer,
  InputWrapper,
  Row,
  RowMarginTop,
} from './NewCreditInvoicePage.styled';
import Overview from './Overview/Overview';
import { createCreditInvoiceSchema } from './validation';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { useAddSalesInvoicePayment } from 'components/Modal/AddPaymentModal/hooks';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { toast } from 'utils/toast';
import { getCreateCreditInvoicePayload } from './helpers';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const NewCreditInvoicePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState<boolean>(false);
  const [isOffsetNotPossibleModalOpen, setIsOffsetNotPossibleModalOpen] =
    useState<boolean>(false);
  const [isOffsetSelected, setIsOffsetSelected] = useState<boolean>(false);
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const prepopulateCreditInvoiceData = useGetPrepopulateCreditInvoiceData();
  const isForSalesInvoice = !!prepopulateCreditInvoiceData?.sales_invoice;
  const [creditInvoiceFormData, setCreditInvoiceFormData] =
    useState<ICreateCreditInvoiceFormDTO>({
      reason: '',
      correctiveAmount: null,
      reference: '',
      detailedReason: '',
    });

  const {
    data,
    isSuccess: createCreditInvoiceSuccess,
    mutate: createCreditInvoice,
  } = useCreateCreditInvoice();

  const {
    mutate: addSalesInvoicePayment,
    isSuccess: addSalesInvoiceOffsetPaymentIsSuccess,
  } = useAddSalesInvoicePayment();

  const offsetPaymentTypeId = useGetOffsetPaymentTypeId();
  useEffect(() => {
    if (createCreditInvoiceSuccess && data) {
      if (isOffsetSelected) {
        // Send offset payment after sales credit invoice has been created with offset option selected
        if (offsetPaymentTypeId !== null && offsetPaymentTypeId !== undefined) {
          addSalesInvoicePayment({
            amount: Number(data.sales_invoice.open_amount),
            paymentTypeId: Number(offsetPaymentTypeId),
            invoiceId: data.sales_invoice.id,
          });
        } else {
          toast.error('Error creating offset payment');
        }
      } else {
        if (data?.sales_invoice?.id) {
          navigate('/sale-credit-invoices/' + data?.sales_invoice?.id);
        } else if (data?.purchase_invoice?.id) {
          navigate('/purchase-credit-invoices/' + data?.purchase_invoice?.id);
        }
      }
    }
  }, [createCreditInvoiceSuccess]);

  useEffect(() => {
    if (addSalesInvoiceOffsetPaymentIsSuccess && data?.sales_invoice?.id) {
      navigate('/sale-credit-invoices/' + data?.sales_invoice?.id);
    }
  }, [addSalesInvoiceOffsetPaymentIsSuccess]);

  const {
    salesCreditInvoiceCreationInfoData,
    isLoadingSalesCreditInvoiceCreationInfo,
  } = useSalesCreditInvoiceCreationInfo(
    isOverview,
    creditInvoiceFormData,
    prepopulateCreditInvoiceData,
    isForSalesInvoice,
    !isOffsetSelected
  );

  const handleCreateCreditInvoice = () => {
    const createCreditInvoiceData: ICreateCreditInvoiceDTO =
      getCreateCreditInvoicePayload(
        creditInvoiceFormData,
        prepopulateCreditInvoiceData,
        isForSalesInvoice,
        !isOffsetSelected
      );

    createCreditInvoice(createCreditInvoiceData);
  };

  return (
    <div>
      <CreateNewCreditInvoiceTitle>
        {t('Create New Credit Invoice')}
      </CreateNewCreditInvoiceTitle>
      {!isOverview ? (
        <Container>
          <Formik
            enableReinitialize
            initialValues={creditInvoiceFormData}
            validationSchema={createCreditInvoiceSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={() => {
              // onSubmit
            }}
            validateOnMount={true}
          >
            {({
              handleBlur,
              setFieldValue,
              values,
              errors,
              touched,
              isValid,
            }) => {
              return (
                <FormikContainer>
                  <Row>
                    <Input
                      pwId="reason-field"
                      errorMessage={touched['reason'] ? errors['reason'] : ''}
                      height={'41rem'}
                      name="reason"
                      placeholder={t('Reason')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('reason', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                    />
                    <Input
                      pwId="corrective-amount-field"
                      type="number"
                      errorMessage={
                        touched['correctiveAmount']
                          ? errors['correctiveAmount']
                          : ''
                      }
                      height={'41rem'}
                      name="correctiveAmount"
                      placeholder={t('Corrective amount')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('correctiveAmount', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '50%',
                      }}
                      symbol={currencySymbol}
                    />
                  </Row>
                  <RowMarginTop>
                    <InputWrapper>
                      <Input
                        pwId="reference-field"
                        errorMessage={
                          touched['reference'] ? errors['reference'] : ''
                        }
                        height={'41rem'}
                        name="reference"
                        placeholder={t('Reference')}
                        onBlur={(e) => {
                          const value = e.target.value.trim();
                          setFieldValue('reference', value);
                          handleBlur(e);
                        }}
                        wrapperStyles={{
                          width: '100%',
                        }}
                      />
                    </InputWrapper>
                  </RowMarginTop>
                  <RowMarginTop>
                    <Input
                      pwId="detailed-reason-field"
                      isTextArea
                      errorMessage={
                        touched['detailedReason']
                          ? errors['detailedReason']
                          : ''
                      }
                      height={'120rem'}
                      name="detailedReason"
                      placeholder={t('Detailed reason')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('detailedReason', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: '100%',
                      }}
                    />
                  </RowMarginTop>
                  <ButtonsWrapper>
                    <Button
                      label={t('Back')}
                      secondary
                      width="200rem"
                      onClick={() => {
                        navigate(
                          location.pathname.replace('/credit/create', '')
                        );
                      }}
                    />
                    <Button
                      disabled={!isValid}
                      label={t('Next')}
                      primary
                      width="200rem"
                      onClick={() => {
                        setCreditInvoiceFormData(values);
                        if (isForSalesInvoice) {
                          setIsPaymentMethodModalOpen(true);
                        } else {
                          setIsOverview(true);
                        }
                      }}
                    />
                  </ButtonsWrapper>
                </FormikContainer>
              );
            }}
          </Formik>
        </Container>
      ) : (
        <Overview
          creditInvoiceFormData={creditInvoiceFormData}
          onBack={() => setIsOverview(false)}
          handleSubmitForm={() => handleCreateCreditInvoice()}
          salesCreditInvoiceCreationInfoData={
            salesCreditInvoiceCreationInfoData?.sales_invoice_info || undefined
          }
          isLoadingSalesCreditInvoiceCreationInfo={
            isLoadingSalesCreditInvoiceCreationInfo
          }
        />
      )}
      <ThreeButtonsModal
        pwId="three-buttons-modal-payment-method"
        level="FIRST"
        isOpen={isPaymentMethodModalOpen}
        setIsOpen={setIsPaymentMethodModalOpen}
        onCancel={() => setIsPaymentMethodModalOpen(false)}
        firstButtonLabel={t('Back')}
        onFirstButton={() => setIsPaymentMethodModalOpen(false)}
        secondButtonLabel={t('Offset')}
        onSecondButton={() => {
          const canOffsetBeClicked =
            prepopulateCreditInvoiceData.sales_invoice.open_amount >=
            creditInvoiceFormData.correctiveAmount!;
          if (canOffsetBeClicked) {
            setIsOffsetSelected(true);
            setIsOverview(true);
            setIsPaymentMethodModalOpen(false);
          } else {
            setIsOffsetNotPossibleModalOpen(true);
          }
        }}
        thirdButtonlabel={t('Repay')}
        onThirdButton={() => {
          setIsOffsetSelected(false);
          setIsOverview(true);
          setIsPaymentMethodModalOpen(false);
        }}
        title={t('Choose payment method')}
        description={
          t(
            'Do you want to offset the credit amount with the sales invoice, or do you want to repay the credit amount'
          ) + '?'
        }
      />
      <OkModal
        level="SECOND"
        onClick={() => setIsOffsetNotPossibleModalOpen(false)}
        description="Open amount on the sales invoice is smaller than the credit amount, therefore you cannot offset"
        buttonLabel="OK"
        setIsOpen={setIsOffsetNotPossibleModalOpen}
        isOpen={isOffsetNotPossibleModalOpen}
      />
    </div>
  );
};

export default NewCreditInvoicePage;
