import { useGetSinglePurchaseInvoice } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { H3, H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import moment from 'moment';
import {
  Card,
  ColumnContainer,
  Container,
  Label,
  LabelTitle,
  MainContainer,
  TextContainer,
  TitleContainer,
} from './SinglePurchaseCreditInvoicePurchaseInvoicePage.styled';
import { RoutesConfig } from 'navigation/routes';
import { formatNumber } from 'utils/numberFormatter';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseCreditInvoicePurchaseInvoicePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const canReadPurchaseOrder = useCan(Actions.READ_PURCHASE_ORDER);
  const currencySymbol = useGetCurrencySymbol();

  const { status, data, isFetching } = useGetSinglePurchaseInvoice(
    typeof id === 'string' ? id : ''
  );

  return (
    <Container>
      <Card>
        <TitleContainer>
          <H3>{data?.purchase_invoice?.parent_invoice?.invoice_number}</H3>
          <Button
            onClick={() =>
              navigate(
                `${RoutesConfig.SinglePurchaseInvoice.fullPath.replace(
                  ':id/*',
                  data?.purchase_invoice?.parent_invoice?.id
                )}`
              )
            }
            label={t('View purchase invoice')}
            primary
            disabled={!canReadPurchaseOrder}
          ></Button>
        </TitleContainer>
        <MainContainer>
          <ColumnContainer>
            <H4>{t('DETAILS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Valid to')}</LabelTitle>
              <Label>
                {moment(
                  data?.purchase_invoice?.parent_invoice?.valid_to
                ).format('DD-MM-YYYY')}
              </Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Customer')}</LabelTitle>
              <Label>
                {data?.purchase_invoice?.parent_invoice?.customer?.name +
                  ' ' +
                  data?.purchase_invoice?.parent_invoice?.customer?.last_name}
              </Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Amount')}</LabelTitle>
              <Label>
                {currencySymbol}
                {formatNumber(
                  data?.purchase_invoice?.parent_invoice?.total_amount
                )}
              </Label>
            </TextContainer>
          </ColumnContainer>
          <ColumnContainer>
            <H4>{t('STATUS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Payment')}</LabelTitle>
              <Label>
                {data?.purchase_invoice?.parent_invoice?.payment_status?.name}
              </Label>
            </TextContainer>
          </ColumnContainer>
        </MainContainer>
      </Card>
    </Container>
  );
};

export default SinglePurchaseCreditInvoicePurchaseInvoicePage;
