import { IAppointmentInterval } from 'types/Appointment.types';
import { IResourceWorkingHours } from './SituationDescriptionResourceSection/SituationDescriptionResourceSection';
import { ISubmitReportResourcesDTO } from '../hooks';

export const filterIntervals = (intervals: IAppointmentInterval[]) => {
  const workerIntervals: IAppointmentInterval[] = [];
  const resourceIntervals: IAppointmentInterval[] = [];
  for (const interval of intervals) {
    if (interval.worker) {
      workerIntervals.push(interval);
    }
    if (interval.resource) {
      resourceIntervals.push(interval);
    }
  }
  return { workerIntervals, resourceIntervals };
};

export const formatResourceWorkingHoursIntoReportResources = (
  resourceWorkingHours: IResourceWorkingHours[]
): ISubmitReportResourcesDTO[] => {
  const reportResources = [];
  for (const resourceWorkingHour of resourceWorkingHours) {
    const reportResource: ISubmitReportResourcesDTO = {
      resource_id: Number(resourceWorkingHour.resource.id),
      expected_working_hours: resourceWorkingHour.workingHours,
    };
    reportResources.push(reportResource);
  }
  return reportResources;
};
