import { COLORS, marginMd, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  withoutMarginTop: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  width: 750rem;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
  margin-left: ${marginMd};
`;

export const Heading = styled.label`
  padding: 0rem 0 30rem 0rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginSm};
  flex: 1;
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AddSignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NoContentLabel = styled.label`
  margin-top: 43rem;
  margin-bottom: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
  ${(props: StyledProps) =>
    props.withoutMarginTop &&
    css`
      margin-top: 0;
    `};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${marginSm};
`;
