import * as Yup from 'yup';

export const addProductSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters long')
      .max(50, 'Name must be shorter than 50 characters')
      .required('Name is required'),
    description: Yup.string().required('Description is required'),
    logyx_model_id: Yup.number()
      .transform((_value, originalValue) =>
        originalValue
          ? Number(originalValue.toString().replace(/,/, '.'))
          : originalValue
      )
      .optional()
      .test(
        'wholeNumber',
        'Logyx model id  must be a whole number',
        (value) => {
          return value ? Number.isInteger(value) : true;
        }
      )
      .typeError('Logyx model id must be a number'),
  });
};
