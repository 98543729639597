import { useFetchUserInfo } from 'listeners/TokenChangedInDifferentTabListener/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';
import { setIsLoadingScreenShown } from 'store/Common/actions/common';
import { setUserInfo } from 'store/User/actions/user';
import { IRootReducerState } from 'store/store';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';

interface IUserInfoProviderProps {
  children: React.ReactNode;
}

const UserInfoProvider = ({ children }: IUserInfoProviderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, name } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const { isMobileApp } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  const { data, mutate: fetchUserInfo, error } = useFetchUserInfo();

  useEffect(() => {
    if (error) {
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.LOGOUT,
          payload: t('Session expired'),
        };
        postMobileMessage(mobileMessage);
      } else {
        dispatch(setIsLoadingScreenShown(false));
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(setUserInfo(data.user));
    }
  }, [data, dispatch]);

  const isAuthenticated = !!id && !!name;
  useEffect(() => {
    (async () => {
      const accessToken = await LocalStorageService.getItem(
        LOCAL_STORAGE_ACCESS_TOKEN
      );

      if (!isAuthenticated && accessToken) {
        fetchUserInfo();
      }
    })();
  }, [isAuthenticated]);

  return <>{children}</>;
};

export default UserInfoProvider;
