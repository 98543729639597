import { COLORS } from 'assets/styled';
import Loader from 'components/Loader/Loader';
import Spinner from 'components/Spinner/Spinner';
import TableAlt from 'components/TableAlt/TableAlt';
import { formatReportTableData } from 'components/TableAlt/tableDataFormatter';
import { useGetAppointmentReport } from 'pages/Manager/PlanningPage/ViewAppointmentModal/hooks';
import { PreviewAppointmentReportModal } from 'pages/Manager/PlanningPage/ViewAppointmentModal/PreviewAppointmentReportModal/PreviewAppointmentReportModal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IReport, IReportTableDTO } from 'types/Report.types';
import {
  ReportsFilterType,
  REPORTS_CURRENT_PER_PAGE,
  REPORTS_HISTORICAL_PER_PAGE,
  tableConfig,
} from './constants';
import {
  useGetCustomerReportsCurrent,
  useGetCustomerReportsHistorical,
} from './hooks';
import {
  Container,
  Headline,
  LoaderWrapper,
  Message,
  NoReportsContainer,
  NoResultsContainer,
  ReportFilterTypeLabel,
  ReportFilterTypesWrapper,
  TableWrapper,
} from './SingleCustomerReportsPage.styled';

export const SingleCustomerReportsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [pageHistorical, setPageHistorical] = useState<number>(1);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [selectedReportFilterType, setSelectedReportFilterType] =
    useState<ReportsFilterType>(ReportsFilterType.CURRENT);

  const { data: ReportsCurrent, isFetching: isFetchingCurrent } =
    useGetCustomerReportsCurrent(
      typeof id === 'string' ? id : '',
      pageCurrent,
      REPORTS_CURRENT_PER_PAGE
    );

  const { data: ReportsHistorical, isFetching: isFetchingHistorical } =
    useGetCustomerReportsHistorical(
      typeof id === 'string' ? id : '',
      pageHistorical,
      REPORTS_HISTORICAL_PER_PAGE
    );

  let reportsCurrentData: IReportTableDTO[] = [];
  let reportsHistoricalData: IReportTableDTO[] = [];

  if (ReportsCurrent) {
    reportsCurrentData = formatReportTableData(
      ReportsCurrent?.reports
        .map((report: IReport[]) => {
          return report;
        })
        .flat()
    );
  }

  if (ReportsHistorical) {
    reportsHistoricalData = formatReportTableData(
      ReportsHistorical?.reports
        .map((report: any) => {
          return report;
        })
        .flat()
    );
  }

  const {
    data: appointmentReport,
    isSuccess,
    isLoading,
  } = useGetAppointmentReport(
    selectedReport?.appointment?.id || '',
    !!selectedReport?.appointment?.id
  );

  useEffect(() => {
    if (isSuccess) {
      setIsReportModalOpen(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isReportModalOpen) {
      setSelectedReport(null);
    }
  }, [isReportModalOpen]);

  const handleButtonClick = (rowData: IReportTableDTO) => {
    setSelectedReport(rowData.entity);
  };

  const reportsAvailable =
    reportsCurrentData.length > 0 || reportsHistoricalData.length > 0;

  const isFetchingReports = isFetchingCurrent || isFetchingHistorical;

  return (
    <>
      {reportsAvailable ? (
        <Container>
          {isLoading && (
            <LoaderWrapper>
              <Loader positionType="relative" />
            </LoaderWrapper>
          )}

          <TableWrapper>
            <Headline>{t('Reports')}</Headline>
            <ReportFilterTypesWrapper>
              <ReportFilterTypeLabel
                onClick={() => {
                  setSelectedReportFilterType(ReportsFilterType.CURRENT);
                  setPageCurrent(1);
                }}
                isSelected={
                  selectedReportFilterType === ReportsFilterType.CURRENT
                }
              >
                {t(ReportsFilterType.CURRENT)}
              </ReportFilterTypeLabel>
              <ReportFilterTypeLabel
                onClick={() => {
                  setSelectedReportFilterType(ReportsFilterType.HISTORICAL);
                  setPageHistorical(1);
                }}
                isSelected={
                  selectedReportFilterType === ReportsFilterType.HISTORICAL
                }
              >
                {t(ReportsFilterType.HISTORICAL)}
              </ReportFilterTypeLabel>
            </ReportFilterTypesWrapper>
            {selectedReportFilterType === ReportsFilterType.CURRENT && (
              <>
                {reportsCurrentData.length > 0 ? (
                  <TableAlt
                    page={pageCurrent}
                    perPage={REPORTS_CURRENT_PER_PAGE}
                    total={ReportsCurrent?.total}
                    tableData={reportsCurrentData}
                    tableConfig={tableConfig}
                    hasExtraAction={true}
                    loadPage={(newPage) => setPageCurrent(newPage)}
                    onButtonClick={handleButtonClick}
                  />
                ) : (
                  <NoReportsContainer>
                    <Message>{`${t('No current reports')}.`}</Message>
                  </NoReportsContainer>
                )}
              </>
            )}
            {selectedReportFilterType === ReportsFilterType.HISTORICAL && (
              <>
                {reportsHistoricalData.length > 0 ? (
                  <TableAlt
                    page={pageHistorical}
                    perPage={REPORTS_HISTORICAL_PER_PAGE}
                    total={ReportsHistorical?.total}
                    tableData={reportsHistoricalData}
                    tableConfig={tableConfig}
                    hasExtraAction={true}
                    loadPage={(newPage) => setPageHistorical(newPage)}
                    onButtonClick={handleButtonClick}
                  />
                ) : (
                  <NoReportsContainer>
                    <Message>{`${t('No historical reports')}.`}</Message>
                  </NoReportsContainer>
                )}
              </>
            )}
          </TableWrapper>

          <PreviewAppointmentReportModal
            isOpen={isReportModalOpen}
            setIsOpen={setIsReportModalOpen}
            previewReportData={appointmentReport}
            appointment={selectedReport?.appointment}
            level="FIRST"
          />
        </Container>
      ) : (
        <NoResultsContainer>
          {isFetchingReports && <Spinner size={50} color={COLORS.PRIMARY} />}
          {!isFetchingReports && (
            <Message>
              {t('There are currently no Reports for this Customer')}
            </Message>
          )}
        </NoResultsContainer>
      )}
    </>
  );
};
