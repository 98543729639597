import Table from 'components/Table/Table';
import { formatSupplierPurchaseOrdersTableData } from 'components/Table/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IQuotation } from 'types/Quotations.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { SUPPLIER_PURCHASE_ORDERS_PER_PAGE, tableConfig } from './constants';
import { useGetSingleSupplierPurchaseOrders } from './hooks';

import {
  Container,
  NoContentLabel,
  TableWrapper,
} from './SingleSupplierPurchaseOrdersPage.styled';

export const SingleSupplierPurchaseOrdersPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const [page, setPage] = useState<number>(1);

  const { data: supplierPurchaseOrders, isLoading } =
    useGetSingleSupplierPurchaseOrders(
      page,
      SUPPLIER_PURCHASE_ORDERS_PER_PAGE,
      id || ''
    );

  const handleViewButtonClick = (row: any) => {
    navigate(
      RoutesConfig.SinglePurchaseOrder.fullPath.replace(
        ':id/*',
        row.navigationID.toString()
      )
    );
  };

  let tableData: IQuotation[] = [];
  if (supplierPurchaseOrders?.purchase_orders) {
    tableData = formatSupplierPurchaseOrdersTableData(
      supplierPurchaseOrders?.purchase_orders
        .map((row: IQuotation[]) => row)
        .flat(),
      currencySymbol
    );
  }

  return (
    <Container>
      {!supplierPurchaseOrders?.purchase_orders?.length && !isLoading ? (
        <NoContentLabel>
          {t('There are currently no purchase orders for this Supplier')}
        </NoContentLabel>
      ) : (
        <TableWrapper>
          <Table
            isLoading={isLoading}
            page={page}
            perPage={SUPPLIER_PURCHASE_ORDERS_PER_PAGE}
            total={supplierPurchaseOrders?.total}
            tableData={tableData}
            tableConfig={tableConfig}
            hasExtraAction={true}
            loadPage={(newPage) => setPage(newPage)}
            onViewButtonClick={handleViewButtonClick}
          />
        </TableWrapper>
      )}
    </Container>
  );
};
