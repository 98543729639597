import { Field, Formik } from 'formik';
import {
  ICreateGoogleCalendarPreference,
  useCreateGoogleCalendarPreference,
} from '../hooks';
import { createGoogleCalendarPreferencesSchema } from './validation';
import { useEffect, useRef, useState } from 'react';
import {
  FormContainer,
  ButtonsWrapper,
  StyledCustomSelect,
} from './GoogleCalendarPreferencesForm.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { useGetTimezoneOptions } from './hooks';
import Button from 'components/Button/Button';

interface IGoogleCalendarPreferencesFormProps {
  onSuccess: () => void;
}

const GoogleCalendarPreferencesForm = ({
  onSuccess,
}: IGoogleCalendarPreferencesFormProps) => {
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const formikRef = useRef<any>(null);

  const {
    mutate: createGoogleCalendarPreference,
    isLoading,
    isSuccess,
    reset,
  } = useCreateGoogleCalendarPreference();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      reset();
    }
  }, [isSuccess]);

  const handleCreateCalendarPreference = () => {
    const { values } = formikRef.current;
    const createGoogleCalendarPreferenceDTO: ICreateGoogleCalendarPreference = {
      name: values.name,
      description: values.description,
      time_zone: values.time_zone,
      custom_calendar_is_preferred: true,
    };
    createGoogleCalendarPreference(createGoogleCalendarPreferenceDTO);
  };

  // const timezoneOptions = useGetTimezoneOptions();
  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        validationSchema={createGoogleCalendarPreferencesSchema}
        initialValues={{
          name: '',
          description: '',
          time_zone: 'Europe/Amsterdam',
        }}
      >
        {(formikProps: any) => {
          const { values, errors, setFieldValue } = formikProps;

          useEffect(() => {
            formikRef.current = formikProps;
          }, [formikProps]);

          return (
            <FormContainer>
              <FormikInput
                placeholder={t('Name')}
                pwId="name-field"
                errorMessage={areAllFieldsTouched && errors['name']}
                height={'43rem'}
                name="name"
                wrapperStyles={{
                  width: '400rem',
                  marginBottom: '4rem',
                  marginTop: '20rem',
                }}
              />
              <FormikInput
                placeholder={t('Description')}
                height={'80rem'}
                isTextArea
                pwId="description-field"
                errorMessage={areAllFieldsTouched && errors['description']}
                name="description"
                wrapperStyles={{
                  width: '400rem',
                  marginBottom: '20rem',
                  marginTop: '20rem',
                }}
              />
              {/* <Field
                pwId={'time_zone-field'}
                height={'41rem'}
                width="203rem"
                name="time_zone"
                options={timezoneOptions}
                component={StyledCustomSelect}
                placeholder={t('Timezone')}
                isMulti={false}
                onSelect={(value: string) => setFieldValue('time_zone', value)}
                errorMessage={areAllFieldsTouched && errors['time_zone']}
              /> */}
            </FormContainer>
          );
        }}
      </Formik>
      <ButtonsWrapper>
        <Button
          disabled={isLoading}
          label={t('Skip')}
          secondary
          width="200rem"
          onClick={() => {
            const createGoogleCalendarPreferenceDTO: ICreateGoogleCalendarPreference =
              {
                custom_calendar_is_preferred: false,
              };
            createGoogleCalendarPreference(createGoogleCalendarPreferenceDTO);
          }}
        />
        <Button
          disabled={isLoading}
          label={t('Save')}
          primary
          width="200rem"
          onClick={() => {
            if (formikRef.current?.isValid) {
              handleCreateCalendarPreference();
            } else {
              if (!areAllFieldsTouched) {
                setAreAllFieldsTouched(true);
              }
            }
          }}
        />
      </ButtonsWrapper>
    </>
  );
};

export default GoogleCalendarPreferencesForm;
