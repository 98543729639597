import { Route, Routes } from 'react-router-dom';
import { SingleSalesInvoiceRoutes } from './SingleSalesInvoice.routes';
import SingleSalesInvoiceSalesOrderPage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceSalesOrderPage/SingleSalesInvoiceSalesOrderPage';
import SingleSalesInvoiceGeneralPage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceGeneralPage/SingleSalesInvoiceGeneralPage';
import SingleSalesInvoiceFilesPage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceFilesPage/SingleSalesInvoiceFilesPage';
import SingleSalesInvoicePaymentsPage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoicePaymentsPage/SingleSalesInvoicePaymentsPage';
import NewCreditInvoicePage from 'pages/Manager/NewCreditInvoice/NewCreditInvoicePage';
import SingleSalesInvoiceCreditInvoicesPage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceCreditInvoicesPage/SingleSalesInvoiceCreditInvoicesPage';

const SingleSalesInvoiceRouter = () => {
  return (
    <Routes>
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoiceGeneral.path}
        element={<SingleSalesInvoiceGeneralPage />}
      />
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoiceSalesOrders.path}
        element={<SingleSalesInvoiceSalesOrderPage />}
      />
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.path}
        element={<SingleSalesInvoicePaymentsPage />}
      />
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoiceFiles.path}
        element={<SingleSalesInvoiceFilesPage />}
      />
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoiceNewCreditInvoice.path}
        element={<NewCreditInvoicePage />}
      />
      <Route
        path={SingleSalesInvoiceRoutes.SingleSalesInvoiceCreditInvoice.path}
        element={<SingleSalesInvoiceCreditInvoicesPage />}
      />
    </Routes>
  );
};

export default SingleSalesInvoiceRouter;
