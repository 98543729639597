import { useNavigate, useParams } from 'react-router-dom';
import {
  SingleCreditInvoiceContainer,
  InvoiceDescriptionLabel,
  RowSpaceBetween,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  StatusLabel,
  Container,
  NoContentLabel,
} from './SinglePurchaseInvoiceCreditInvoicesPage.styled';
import { useState } from 'react';
import { useGetSinglePurchaseInvoiceCreditInvoices } from './hooks';
import {
  PURCHASE_INVOICE_CREDIT_INVOICES_PER_PAGE,
  tableConfig,
} from './constants';
import Table from 'components/Table/Table';
import {
  IPurchaseInvoice,
  IPurchaseInvoiceLineTableDTO,
} from 'types/Invoice.types';
import { formatPurchaseInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseInvoiceCreditInvoicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const [page, setPage] = useState<number>(1);

  const { status, data, isFetching } =
    useGetSinglePurchaseInvoiceCreditInvoices(
      typeof id === 'string' ? id : '',
      page,
      PURCHASE_INVOICE_CREDIT_INVOICES_PER_PAGE
    );

  return (
    <Container>
      {data?.credit_invoices?.length ? (
        data?.credit_invoices.map(
          (creditInvoice: IPurchaseInvoice, index: number) => {
            const creditInvoiceTableData: IPurchaseInvoiceLineTableDTO[] =
              formatPurchaseInvoiceLineTableData(
                creditInvoice.purchase_invoice_lines,
                currencySymbol
              );
            return (
              <SingleCreditInvoiceContainer key={index}>
                <RowSpaceBetween>
                  <StatusLabel>
                    {creditInvoice.payment_status?.name}
                  </StatusLabel>
                  <Button
                    label={t('Open')}
                    width="200rem"
                    primary
                    onClick={() =>
                      navigate(
                        `${RoutesConfig.SinglePurchaseCreditInvoice.fullPath}`.replace(
                          ':id/*',
                          creditInvoice.id.toString()
                        ),
                        {
                          state: { isSalesInvoiceCredit: false },
                        }
                      )
                    }
                  />
                </RowSpaceBetween>
                <InvoiceDescriptionLabel>
                  {creditInvoice.description}
                </InvoiceDescriptionLabel>
                <RowSpaceBetweenMarginTop>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>{t('INVOICED')}</SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(
                        creditInvoice.parent_invoice.total_amount -
                          creditInvoice.total_amount
                      )}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('CORRECTIVE AMOUNT')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(creditInvoice.total_amount)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('AFTER CORRECTION')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(creditInvoice.parent_invoice.total_amount)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                </RowSpaceBetweenMarginTop>
                <Table
                  page={0}
                  perPage={0}
                  total={-1}
                  tableData={creditInvoiceTableData}
                  tableConfig={tableConfig}
                  hasExtraAction={false}
                  loadPage={(newPage) => setPage(newPage)}
                />
              </SingleCreditInvoiceContainer>
            );
          }
        )
      ) : (
        <>
          {isFetching && <Spinner size={50} color={COLORS.PRIMARY} />}
          {!isFetching && (
            <NoContentLabel>
              {t(
                'There are currently no credit invoices for this Purchase invoice'
              )}
            </NoContentLabel>
          )}
        </>
      )}
    </Container>
  );
};

export default SinglePurchaseInvoiceCreditInvoicesPage;
