import { marginXs, paddingLg, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${paddingLg};

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30rem;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 203rem;
  height: 41rem;
`;
export const RowRightPart = styled.div`
  display: flex;
`;

export const AdvancedFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${marginXs};
`;
