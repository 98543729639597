import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { fetchUserInfo } from 'services/User/UserService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useFetchUserInfo = () =>
  useMutation(() => fetchUserInfo(), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.FETCH_USER_INFO,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.FETCH_USER_INFO,
  });
