import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import {
  Title,
  TitleContainer,
  CloseContainer,
  WorkerContainer,
  WorkerDescription,
  WorkerName,
  ButtonContainer,
} from './PreviewWorkersModal.styled';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';

export interface IPreviewWorkersModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  workers: IWorkerAndResource[];
}

const PreviewWorkersModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  workers,
}: IPreviewWorkersModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed' }}
    >
      <div>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Workers')}</Title>
        </TitleContainer>
        {workers?.map((worker: IWorkerAndResource, index: number) => {
          return (
            <WorkerContainer isLast={index === workers.length - 1} key={index}>
              <WorkerName>{worker.name}</WorkerName>
              <WorkerDescription>{worker.description}</WorkerDescription>
            </WorkerContainer>
          );
        })}
        <ButtonContainer>
          <Button
            width={'200rem'}
            secondary
            onClick={() => {
              onCancel();
            }}
            label={t('Close')}
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default PreviewWorkersModal;
