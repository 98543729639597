import { COLORS, marginMd, paddingSm } from 'assets/styled';
import styled from 'styled-components';

interface StyledProps {
  $selected: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Headline = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: 20rem;
`;

export const Description = styled.p`
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 20rem;
  font-size: 16rem;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
  gap: 30rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
`;

export const Label = styled.span`
  line-height: 21rem;
  font-size: 14rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`;

export const Lines = styled.div`
  border-radius: 10rem;
  border: 1rem solid ${COLORS.GREY_1000};
  overflow: hidden;
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_1000};
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10rem 0;

  &:last-child {
    border-bottom: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : 'none'};
`;

export const Text = styled(Label)`
  color: ${COLORS.BLACK};
`;

export const Select = styled.span`
  border: 1rem solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  background-color: ${COLORS.WHITE};
  font-size: 14rem;
  line-height: 1.4;
  padding: 5rem 10rem;
  border-radius: 10rem;
  transition: 0.3s all;
  min-width: 83rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
  }
`;
