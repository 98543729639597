import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { markAsConnected } from 'services/Invoice/SalesInvoiceService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IMarkAsConnectedProps {
  twinfieldTransactionNumber: string;
  twinfieldTransactionStatus: number;
}

export const useMarkAsConnected = (salesInvoiceId: number | string | null) => {
  const { t } = useTranslation();
  return useMutation(
    (props: IMarkAsConnectedProps) => markAsConnected(salesInvoiceId, props),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
        toast.success(t('Invoice marked as connected'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.MARK_SALES_INVOICE_AS_CONNECTED,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.MARK_SALES_INVOICE_AS_CONNECTED,
    }
  );
};
