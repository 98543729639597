import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClearLabel,
  Container,
  LoaderWrapper,
  More,
  PaginationWrapper,
  ProductContainer,
  ProductName,
  SearchContainer,
  SelectAllContainer,
  SelectAllLabel,
} from './SalesOrderLines.styled';
import Button from 'components/Button/Button';
import Pagination from 'components/Table/Pagination/Pagination';
import { useGetSingleSalesOrder } from 'pages/Manager/SingleSalesOrder/hooks';
import { ViewProductModal } from 'components/Modal/ViewProductModal/ViewProductModal';
import { ViewProductModalFormatter } from 'components/Modal/ViewProductModal/helpers';
import { ViewProductType } from 'components/Modal/ViewProductModal/type';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import Spinner from 'components/Spinner/Spinner';
import { MagnifyingGlass } from '@phosphor-icons/react';

const SALES_ORDER_LINES_PER_PAGE = 4;

export interface ISalesOrderLinesProps {
  selectedSalesOrderId?: string;
  onSubmit?: (orderLines: ISalesOrderLine[]) => void;
  isReadOnly?: boolean; // ViewAppointmentModal
  selectedSalesOrderLineIds?: number[];
}
export const SalesOrderLines = ({
  selectedSalesOrderId,
  onSubmit,
  selectedSalesOrderLineIds: initialSelectedSalesOrderLineIds,
  isReadOnly = false,
}: ISalesOrderLinesProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data: salesOrder, isLoading: isLoadingSingleSalesOrder } =
    useGetSingleSalesOrder(selectedSalesOrderId!, true);
  const [isViewProductModalOpen, setIsViewProductModalOpen] =
    useState<boolean>(false);

  const [selectedLine, setSelectedLine] = useState<ISalesOrderLine>();
  const [salesOrderLines, setSalesOrderLines] = useState<ISalesOrderLine[]>([]);
  const [selectedSalesOrderLineIds, setSelectedSalesOrderLineIds] = useState<
    number[]
  >(initialSelectedSalesOrderLineIds || []);

  // Local pagination
  const getElementsForCurrentPage = () => {
    return salesOrderLines.filter((element: ISalesOrderLine, index: number) => {
      if (
        index < SALES_ORDER_LINES_PER_PAGE * page &&
        index >= SALES_ORDER_LINES_PER_PAGE * (page - 1) &&
        element.product?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
        return element;
    });
  };

  // Setting initial data
  useEffect(() => {
    // Ensure data is fetched
    if (!salesOrder) return;

    const newSelectedSalesOrderLineIds: number[] = [];
    let newLinesArray: ISalesOrderLine[] = salesOrder.sales_order_lines
      ?.filter((element: ISalesOrderLine) => !element.working_hours_line) // Remove working hour lines
      ?.map((line: ISalesOrderLine) => {
        // Calculate isSelected (selectedSalesOrderLineIds)
        let isInitiallySelected = false;
        if (
          initialSelectedSalesOrderLineIds?.length &&
          initialSelectedSalesOrderLineIds?.includes(Number(line.id))
        ) {
          isInitiallySelected = true;
        }
        if (isInitiallySelected) {
          newSelectedSalesOrderLineIds.push(Number(line.id));
        }
        return line;
      });

    if (isReadOnly) {
      // Do not display line ids that arent initially provided when in readonly mode
      newLinesArray = newLinesArray.filter((line: ISalesOrderLine) =>
        initialSelectedSalesOrderLineIds?.includes(Number(line.id))
      );
    }

    setSelectedSalesOrderLineIds(newSelectedSalesOrderLineIds);
    setSalesOrderLines(newLinesArray);
  }, [salesOrder]);

  // Based on the currently selected line ids, call the provided onSubmit function
  // with the lines from the fetched sales order that match the ids
  useEffect(() => {
    if (!onSubmit) return;
    onSubmit(
      salesOrderLines.filter((line: ISalesOrderLine) =>
        selectedSalesOrderLineIds.includes(Number(line.id))
      )
    );
  }, [selectedSalesOrderLineIds]);

  // Toggle isSelected for line
  const toggleIsSelected = (lineId: number) => {
    if (selectedSalesOrderLineIds.includes(lineId)) {
      const newSelectedSalesOrderLineIds: number[] =
        selectedSalesOrderLineIds.filter(
          (selectedLineId: number) => selectedLineId !== lineId
        );
      setSelectedSalesOrderLineIds(newSelectedSalesOrderLineIds);
    } else {
      setSelectedSalesOrderLineIds([...selectedSalesOrderLineIds, lineId]);
    }
  };

  const selectAllLines = () => {
    const newSelectedSalesOrderLineIds: number[] = salesOrderLines?.map(
      (line: ISalesOrderLine) => Number(line.id)
    );
    setSelectedSalesOrderLineIds(newSelectedSalesOrderLineIds);
  };

  return (
    <Container>
      <SearchContainer isReadOnly={isReadOnly}>
        {!isReadOnly && (
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            width={'200rem'}
            placeholder={t('Search')}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        {!isReadOnly && !!salesOrderLines.length && (
          <SelectAllContainer>
            <SelectAllLabel
              data-testid="select-all"
              onClick={() => selectAllLines()}
            >
              {t('Select all')}
            </SelectAllLabel>
            <ClearLabel
              data-testid="clear"
              onClick={() => setSelectedSalesOrderLineIds([])}
            >
              {t('Clear')}
            </ClearLabel>
          </SelectAllContainer>
        )}
      </SearchContainer>
      {isLoadingSingleSalesOrder ? (
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      ) : (
        getElementsForCurrentPage().map((line: ISalesOrderLine) => {
          const isSelected = selectedSalesOrderLineIds.includes(
            Number(line.id)
          );
          return (
            <ProductContainer
              key={line.id}
              selected={isSelected}
              isReadOnly={isReadOnly}
            >
              <ProductName>{line.product?.name}</ProductName>

              <SelectAllContainer>
                {line.product && (
                  <More
                    onClick={() => {
                      setSelectedLine(line);
                      setIsViewProductModalOpen(true);
                    }}
                  >
                    {t('More')}
                  </More>
                )}
                {!isReadOnly && (
                  <Button
                    data-testid={
                      isSelected
                        ? `unselect-button-${line.product?.name}`
                        : `select-button-${line.product?.name}`
                    }
                    onClick={() => toggleIsSelected(Number(line.id))}
                    width={'90rem'}
                    secondary
                    height="30rem"
                    label={isSelected ? t('Unselect') : t('Select')}
                  />
                )}
              </SelectAllContainer>
            </ProductContainer>
          );
        })
      )}
      {salesOrderLines?.length > SALES_ORDER_LINES_PER_PAGE && (
        <PaginationWrapper>
          <Pagination
            page={page}
            perPage={SALES_ORDER_LINES_PER_PAGE}
            total={salesOrderLines.length}
            loadPage={(newPage) => setPage(newPage)}
          />
        </PaginationWrapper>
      )}
      <ViewProductModal
        level="SECOND"
        isOpen={isViewProductModalOpen}
        setIsOpen={setIsViewProductModalOpen}
        onCancel={() => setIsViewProductModalOpen(false)}
        productLineData={ViewProductModalFormatter(
          selectedLine,
          ViewProductType.SALES_ORDER
        )}
      />
    </Container>
  );
};
