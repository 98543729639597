import Button from 'components/Button/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import {
  Container,
  PleaseRefreshLabel,
  UpdateAvailableLabel,
} from './UpdateAvailableModal.styled';

export interface IUpdateAvailableModalProps {
  onSubmit: any;
  isOpen: boolean;
}

export function UpdateAvailableModal({
  onSubmit,
  isOpen,
}: IUpdateAvailableModalProps) {
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && isOpen) {
        onSubmit && onSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isOpen]);

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => null}
      modalStyle={{ margin: 'auto', position: 'fixed' }} // Center positioning
    >
      <Container>
        <UpdateAvailableLabel>
          {t('A new update is available for Blueprint')}
        </UpdateAvailableLabel>
        <PleaseRefreshLabel>
          {t('Please refresh the page to apply latest changes')}
        </PleaseRefreshLabel>
        <Button
          label={t('Refresh')}
          primary
          width="200rem"
          onClick={onSubmit}
        />
      </Container>
    </Modal>
  );
}
