import { useTranslation } from 'react-i18next';
import {
  DragText,
  DragTextBlue,
  DropContainer,
  FileName,
  FileWrapper,
  IconWrapper,
  TextWrapper,
} from './FileUploader.styled';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { toast } from 'utils/toast';
import { allowedTypes } from 'components/Modal/FileUploadModal/constants';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { cutLongWords } from 'utils/stringUtils';
import { compressImage, fileRejectHandler } from './helpers';
import { Trash } from '@phosphor-icons/react';

const maxSize = 32000000; // 32MB

const FileUploader = ({
  values,
  setFieldValue,
  name,
  setDeletedAttachments,
  localDelete,
}: any) => {
  const { t } = useTranslation();
  const [isFileRejected, setIsFileRejected] = useState<boolean>(false);

  const onDrop = useCallback(
    async (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length) {
        setIsFileRejected(true);
        fileRejectHandler(fileRejections, t);
      }

      setIsFileRejected(false);

      try {
        const compressedFiles = await Promise.all(
          acceptedFiles.map(compressImage)
        );

        setFieldValue(name, values[name].concat(compressedFiles));
      } catch (error) {
        toast.error(t('Something went wrong with File'));
      }
    },
    [setFieldValue, values[name], name]
  );

  const removeFile = (fileToRemove: any) => {
    if (fileToRemove.id && !localDelete) {
      setDeletedAttachments((prevIds: any) => [...prevIds, fileToRemove.id]);
    }
    setFieldValue(
      name,
      values[name].filter((file: any) => file !== fileToRemove)
    );
  };

  const onDropRejected = useCallback(() => {
    setIsFileRejected(true);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive: dropzoneDragActive,
  } = useDropzone({
    onDrop,
    onDropRejected,
    accept: allowedTypes,
    maxSize,
    multiple: true,
  });

  return (
    <>
      <DropContainer
        {...getRootProps()}
        isDragActive={dropzoneDragActive}
        isFileRejected={isFileRejected}
      >
        <input name={name} {...getInputProps()} />

        {dropzoneDragActive ? (
          <DragText>{t('Drag the file here ...')}</DragText>
        ) : (
          <TextWrapper>
            <DragText>{t('Drag and drop the file or')}</DragText>{' '}
            <DragTextBlue>{t('upload from computer')}</DragTextBlue>
          </TextWrapper>
        )}
      </DropContainer>
      {values && values[name]?.length > 0 && (
        <FileWrapper>
          {values[name].map((file: any, index: number) => (
            <FileName key={!file.id ? file.name + index : file.id + index}>
              {cutLongWords(file.name || file.file_name, 48)}
              <IconWrapper>
                <Icon
                  size={18}
                  marginLeft="10rem"
                  svg={Trash}
                  weight="regular"
                  color={COLORS.PRIMARY}
                  onClick={() => removeFile(file)}
                />
              </IconWrapper>
            </FileName>
          ))}
        </FileWrapper>
      )}
    </>
  );
};

export default FileUploader;
