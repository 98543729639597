import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export const useGetTitleOptions = () => {
  const { t } = useTranslation();
  const titles = useSelector(
    (state: IRootReducerState) => state.commonInfo.customerTitles
  );
  const options: Option[] = titles.map((title: string) => {
    return {
      value: title,
      label: t(title),
      key: title,
    };
  });
  return options;
};
