import { COLORS, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Button from 'components/Button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};

  ${respondTo.bigTablet`
    width: 100%;
    padding: 0 ${paddingMd};
  `}
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 50rem auto 30rem;
`;

export const Title = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;

export const StyledButton = styled(Button)`
  background-color: ${COLORS.GREY_1000};

  &:hover {
    background-color: ${COLORS.GREY_1000};
  }
`;

export const Search = styled.div`
  width: 100%;
  display: flex;
`;
