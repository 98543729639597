export const excludedProductFormDTOKeys: string[] = [
  'product',
  'supplier',
  'attributes',
  'logyxConfigurationIntent',
  'isStockItem',
  'stockItemId',
];

export const pricingProductFormDTOKeys: string[] = [
  'salesPrice',
  'purchasePrice',
  'discount',
  'discountType',
];
