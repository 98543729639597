import { COLORS, H4 } from 'assets/styled';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
  width: 300rem;
  align-self: flex-start;
`;

export const LogyxAttributesLabel = styled(H4)`
  text-align: center;
  width: 100%;
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

export const Section = styled.div`
  width: 500rem;
`;

export const GreenLine = styled.div`
  border-bottom: 1rem solid ${COLORS.LOGYX_LIGHT_GREEN};
  display: flex;
  width: 100%;
`;

export const Text = styled.span`
  color: ${COLORS.BLACK};
  padding-top: 15rem;
`;
