import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { createTransform, persistReducer } from 'redux-persist';
import userReducer from './User/reducers/user';
import commonDataReducer, {
  ICommonReducerState,
  INIT_STATE as COMMON_REDUCER_INIT_STATE,
} from './Common/reducers/common';
import companyReducer from './Company/reducers/company';
import logyxReducer from './Logyx/reducers/logyx';
import filtersReducer, {
  FiltersReducerState,
} from './Filters/reducers/filters';
import pwaReducer from './PWA/reducers/pwa';
import LocalStorageService from 'services/LocalStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'services/api/constants';

const storeTransform = createTransform(
  // Transform the incoming state, persisting only the desired part
  (inboundState: ICommonReducerState | FiltersReducerState, key) => {
    if (key === 'commonInfo') {
      const token = LocalStorageService.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
      return {
        ...COMMON_REDUCER_INIT_STATE,
        darkMode: (inboundState as ICommonReducerState).darkMode,
        isLoadingScreenShown: !!token,
        isFieldWorkerView: (inboundState as ICommonReducerState)
          .isFieldWorkerView,
        isSideMenuCollapsed:
          window.innerWidth > 1180
            ? (inboundState as ICommonReducerState).isSideMenuCollapsed
            : true,
      };
    } else if (key === 'filtersInfo') {
      const filtersState = inboundState as FiltersReducerState;
      return {
        quotationsPage: filtersState.quotationsPage || null,
        salesOrdersPage: filtersState.salesOrdersPage || null,
        purchaseOrdersPage: filtersState.purchaseOrdersPage || null,
        invoicesPage: filtersState.invoicesPage || null,
        planningPage: filtersState?.planningPage?.tabs?.length
          ? filtersState.planningPage
          : null,
        stockItemsPage: filtersState.stockItemsPage || null,
        customersPage: filtersState.customersPage || null,
        suppliersPage: filtersState.suppliersPage || null,
        fwAppointmentsPage: filtersState.fwAppointmentsPage || null,
        analyticsPage: filtersState.analyticsPage || null,
        userRolesPage: filtersState.userRolesPage || null,
      };
    }
    return inboundState;
  },
  // No need for an outbound transform
  (outboundState: any, key) => {
    return outboundState;
  }
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['commonInfo', 'filtersInfo'], // filtersInfo is persisted, commonInfo has transformation
  transforms: [storeTransform],
};

const rootReducer = combineReducers({
  userInfo: userReducer,
  commonInfo: commonDataReducer,
  companyInfo: companyReducer,
  logyxInfo: logyxReducer,
  filtersInfo: filtersReducer,
  pwaInfo: pwaReducer,
});
export type IRootReducerState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
