import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  InvoiceDescriptionLabel,
  NoContentLabel,
  RowSpaceBetween,
  RowSpaceEvenlyMarginTop,
  SingleCreditInvoiceContainer,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  StatusLabel,
} from './SingleSalesInvoiceCreditInvoicesPage.styled';
import { useState } from 'react';
import { useGetSingleSalesInvoiceCreditInvoices } from './hooks';
import {
  SALES_INVOICE_CREDIT_INVOICES_PER_PAGE,
  tableConfig,
} from './constants';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import {
  ISalesInvoice,
  ISalesCreditInvoiceLineTableDTO,
} from 'types/Invoice.types';
import { formatSalesCreditInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const SingleSalesInvoiceCreditInvoicesPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [page, setPage] = useState<number>(1);
  const currencySymbol = useGetCurrencySymbol();

  const { data, isFetched, isFetching } =
    useGetSingleSalesInvoiceCreditInvoices(
      typeof id === 'string' ? id : '',
      page,
      SALES_INVOICE_CREDIT_INVOICES_PER_PAGE
    );

  const taxRate = useSelector(
    (state: IRootReducerState) => state?.companyInfo?.settings?.tax_rate
  );

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_INVOICE_CREDIT_INVOICES,
    id,
    isFetched
  );

  return (
    <Container className="single-sales-invoice-step-7">
      {data?.credit_invoices?.length ? (
        data.credit_invoices.map(
          (creditInvoice: ISalesInvoice, index: number) => {
            const creditInvoiceTableData: ISalesCreditInvoiceLineTableDTO[] =
              formatSalesCreditInvoiceLineTableData(
                creditInvoice.sales_invoice_lines,
                taxRate,
                currencySymbol
              );
            return (
              <SingleCreditInvoiceContainer key={index}>
                <RowSpaceBetween>
                  <StatusLabel>
                    {t(creditInvoice?.payment_status?.name)}
                  </StatusLabel>
                  <Button
                    label={t('Open')}
                    width="200rem"
                    primary
                    onClick={() =>
                      navigate(
                        `${RoutesConfig.SingleSalesCreditInvoice.fullPath}`.replace(
                          ':id/*',
                          creditInvoice.id.toString()
                        ),
                        {
                          state: { isSalesInvoiceCredit: true },
                        }
                      )
                    }
                  />
                </RowSpaceBetween>
                <InvoiceDescriptionLabel>
                  {creditInvoice.description}
                </InvoiceDescriptionLabel>
                <RowSpaceEvenlyMarginTop>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>{t('INVOICED')}</SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {creditInvoice.sent_to_customer
                        ? '0'
                        : formatNumber(creditInvoice.total_amount)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('CORRECTIVE AMOUNT')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(creditInvoice.total_amount)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                  <SmallColumnDiv>
                    <SmallColumnDivLabel>
                      {t('AFTER CORRECTION')}
                    </SmallColumnDivLabel>
                    <SmallColumnDivLabelValue>
                      {currencySymbol}
                      {formatNumber(creditInvoice.after_correction)}
                    </SmallColumnDivLabelValue>
                  </SmallColumnDiv>
                </RowSpaceEvenlyMarginTop>
                <Table
                  page={0}
                  perPage={0}
                  total={-1}
                  tableData={creditInvoiceTableData}
                  tableConfig={tableConfig}
                  hasExtraAction={false}
                  loadPage={(newPage) => setPage(newPage)}
                />
              </SingleCreditInvoiceContainer>
            );
          }
        )
      ) : (
        <>
          {isFetching && <Spinner size={50} color={COLORS.PRIMARY} />}
          {!isFetching && (
            <NoContentLabel>
              {t(
                'There are currently no credit invoices for this Sales invoice'
              )}
            </NoContentLabel>
          )}
        </>
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesInvoiceCreditInvoicesPage;
