import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  CloseAbsoluteWrapper,
  Container,
  Header,
  Headline,
  Wrapper,
  SubmitRow,
  Label,
  Lines,
  Line,
  Text,
  Select,
  Cell,
  Description,
} from './ManualModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import moment from 'moment';
import { BigLoaderWrapper } from '../DirectLogyxOrderPage.styled';
import Loader from 'components/Loader/Loader';
import {
  IMoveToNewOrderDTO,
  useMovePurchaseOrderLineToNewOrder,
} from './hooks';
import { IPurchaseOrder } from 'types/PurchaseOrders.types';
import { useParams } from 'react-router-dom';

interface IManualModalProps {
  isOpen: boolean;
  availablePurchaseOrders: IPurchaseOrder[];
  isLoading: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  moveToExistingOrder: (selectedOrderId: number) => void;
  selectedLineIdForManualOrder: number;
  retryLogyxOrderValidation: any;
}

const ManualModal = ({
  isOpen,
  availablePurchaseOrders,
  isLoading,
  setIsOpen,
  moveToExistingOrder,
  selectedLineIdForManualOrder,
  retryLogyxOrderValidation,
}: IManualModalProps) => {
  const { t } = useTranslation();
  const { id: originalPurchaseOrderId } = useParams();
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);

  const { mutate: moveToNewOrder, isSuccess: isSuccessMoveToNewOrder } =
    useMovePurchaseOrderLineToNewOrder();

  useEffect(() => {
    if (!isOpen) {
      setSelectedOrderId(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccessMoveToNewOrder) {
      retryLogyxOrderValidation();
    }
  }, [isSuccessMoveToNewOrder]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        setIsOpen(boolean);
      }}
      modalStyle={{ margin: 'auto', position: 'fixed', maxWidth: '750rem' }}
    >
      <Container>
        {isLoading && (
          <BigLoaderWrapper>
            <Loader />
          </BigLoaderWrapper>
        )}
        <Headline>{t('Move to Manual')}</Headline>
        <Description>
          {t(
            'Choose a Manual Purchase Order to move the product to, or create a new purchase order'
          )}
        </Description>
        <Wrapper>
          <Header>
            <Cell>
              <Label>{t('Number')}</Label>
            </Cell>
            <Cell>
              <Label>{t('Reference')}</Label>
            </Cell>
            <Cell>
              <Label>{t('Creation date')}</Label>
            </Cell>
            <Cell>
              <Label></Label>
            </Cell>
          </Header>
          <Lines>
            {availablePurchaseOrders?.map((line: any) => {
              const isSelected = selectedOrderId === line.id;
              return (
                <Line key={line.id} $selected={isSelected}>
                  <Cell>
                    <Text>{line.number}</Text>
                  </Cell>
                  <Cell>
                    <Text>{line.reference}</Text>
                  </Cell>
                  <Cell>
                    <Text>
                      {moment
                        .utc(line.creation_date)
                        .tz(moment.tz.guess())
                        .format('DD-MM-YYYY')}
                    </Text>
                  </Cell>
                  <Cell>
                    <Select
                      onClick={() =>
                        isSelected
                          ? setSelectedOrderId(null)
                          : setSelectedOrderId(line.id)
                      }
                    >
                      {isSelected ? t('Unselect') : t('Select')}
                    </Select>
                  </Cell>
                </Line>
              );
            })}
          </Lines>
        </Wrapper>
        <SubmitRow>
          <Button
            width={'200rem'}
            onClick={() => setIsOpen(false)}
            label={t('Cancel')}
            secondary
          />
          <Button
            width={'200rem'}
            onClick={() => {
              const moveToNewOrderDTO: IMoveToNewOrderDTO = {
                purchase_order_id: Number(originalPurchaseOrderId),
                purchase_order_line_ids: [selectedLineIdForManualOrder],
              };
              moveToNewOrder(moveToNewOrderDTO);
              setIsOpen(false);
              setSelectedOrderId(null);
            }}
            label={t('Move to new order')}
            primary
          />
          <Button
            disabled={!selectedOrderId}
            width={'200rem'}
            onClick={() => {
              moveToExistingOrder(selectedOrderId!);
              setIsOpen(false);
              setSelectedOrderId(null);
            }}
            label={t('Move')}
            primary
          />
        </SubmitRow>
        <CloseAbsoluteWrapper>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseAbsoluteWrapper>
      </Container>
    </Modal>
  );
};

export default ManualModal;
