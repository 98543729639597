import { PHONE_NUMBER_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export const editCustomerValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Last name is required'),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .optional(),
    address: Yup.object().shape({
      street: Yup.string()
        .min(1, 'Street should be between 1 and 80 characters')
        .max(80, 'Street should be between 1 and 80 characters')
        .required('Street is required'),
      house_number: Yup.string().required('House number is required'),
      city: Yup.string()
        .min(1, 'City should be between 1 and 80 characters')
        .max(80, 'City should be between 1 and 80 characters')
        .required('City is required'),
      zip_code: Yup.string()
        .min(3, 'Zip code should be between 3 and 10 characters')
        .max(10, 'Zip code should be between 3 and 10 characters')
        .required('Zip code is required'),
      country: Yup.string().required('Country is required'),
    }),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    coc_number: Yup.string()
      .max(20, 'COC number should be between 1 and 20 characters')
      .optional(),
    title: Yup.string(),
  });
};

export const editSupplierValidationSchema = () => {
  return Yup.object().shape({
    company_name: Yup.string().required('Company name is required'),
    contact_person: Yup.string().required('Contact person is required'),
    address: Yup.object().shape({
      street: Yup.string()
        .min(1, 'Street should be between 1 and 80 characters')
        .max(80, 'Street should be between 1 and 80 characters')
        .required('Street is required'),
      house_number: Yup.string().required('House number is required'),
      city: Yup.string()
        .min(1, 'City should be between 1 and 80 characters')
        .max(80, 'City should be between 1 and 80 characters')
        .required('City is required'),
      zip_code: Yup.string()
        .min(3, 'Zip code should be between 3 and 10 characters')
        .max(10, 'Zip code should be between 3 and 10 characters')
        .required('Zip code is required'),
      country: Yup.string().required('Country is required'),
    }),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .optional(),
    number: Yup.string().required('Number is required'),
    coc_number: Yup.string()
      .max(20, 'COC number should be between 1 and 20 characters')
      .optional(),
  });
};
