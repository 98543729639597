import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createStockItem } from 'services/StockItems/StockItemsService';
import { ICreateStockItemData } from 'types/StockItem.types';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import { getCityAndAddress } from 'services/Common/CommonService';
import { IGetCityAndAddressDTO } from './constants';

export const useCreateStockItem = () => {
  const { t } = useTranslation();
  return useMutation(
    (createStockItemData: ICreateStockItemData) =>
      createStockItem(createStockItemData),
    {
      onSuccess: (data) => {
        toast.success(t('Successfully added stock item'), {
          className: ReactMutationKeys.CREATE_STOCK_ITEM,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_STOCK_ITEM,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_STOCK_ITEMS);
      },
      mutationKey: ReactMutationKeys.CREATE_STOCK_ITEM,
    }
  );
};

export const useGetPrepopulateStockItemData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulatePurchaseOrderData;
  } else {
    return null;
  }
};

export const useGetCityAndAddress = () =>
  useMutation(
    (data: IGetCityAndAddressDTO) =>
      getCityAndAddress(data.zipCode, data.houseNumber),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        //Error
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
    }
  );
