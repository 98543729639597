import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  AppointmentList,
  BlockedSection,
  BlockingAppointmentsList,
  CloseContainer,
  HorizontalLine,
  Label,
  NoContentLabel,
  Row,
  RunningSection,
  SingleAppointmentWrapper,
  TooltipContentWrapper,
} from './RunningAndBlockedAppointmentsModal.styled';
import {
  useGetBlockedFwAppointments,
  useGetRunningAppointments,
} from './hooks';
import { COLORS, H3, gapSm, gapXs } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { IAppointment } from 'types/Appointment.types';
import moment from 'moment';
import { IBlockedAppointmentDTO } from './types';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { WarningCircle } from '@phosphor-icons/react';

export interface IRunningAndBlockedAppointmentsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const RunningAndBlockedAppointmentsModal = ({
  isOpen,
  setIsOpen,
}: IRunningAndBlockedAppointmentsModalProps) => {
  const { t } = useTranslation();
  // Is fetching only past running appointments
  const {
    data: runningAppointmentsData,
    isSuccess: isSuccessRunningFwAppointments,
  } = useGetRunningAppointments();

  // Is fetching only blocked appointments for today
  const {
    data: blockedAppointmentsData,
    isSuccess: isSuccessBlockedFwAppointments,
  } = useGetBlockedFwAppointments();

  useEffect(() => {
    if (
      isSuccessBlockedFwAppointments &&
      isSuccessRunningFwAppointments &&
      (runningAppointmentsData?.appointments?.length ||
        blockedAppointmentsData?.blocked_appointment_dtos?.length)
    ) {
      setIsOpen(true);
    }
  }, [isSuccessBlockedFwAppointments, isSuccessRunningFwAppointments]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <BlockedSection>
        <H3>{t('Blocked appointments for today')}:</H3>
        {blockedAppointmentsData?.blocked_appointment_dtos?.length ? (
          <AppointmentList>
            {blockedAppointmentsData.blocked_appointment_dtos.map(
              (dto: IBlockedAppointmentDTO, index: number) => {
                return (
                  <React.Fragment key={`blocked-appointment-${index}`}>
                    <SingleAppointmentWrapper>
                      <Row gap={gapSm}>
                        <Label fontSize={'24rem'}>
                          {t(dto.appointment.type.name)}
                        </Label>
                        <Label fontSize={'18rem'}>{dto.appointment.id}</Label>
                        <Row gap={gapXs}>
                          <Label fontSize={'14rem'}>{t('From') + ':'}</Label>
                          <Label fontSize={'18rem'}>
                            {moment(dto.appointment.date_from).format('HH:mm')}
                          </Label>
                        </Row>
                        <Row gap={gapXs}>
                          <Label fontSize={'14rem'}>{t('To') + ':'}</Label>
                          <Label fontSize={'18rem'}>
                            {moment(dto.appointment.date_to).format('HH:mm')}
                          </Label>
                        </Row>
                      </Row>
                      <Row gap={gapXs} alignItems="flex-start">
                        <Label fontSize={'18rem'}>
                          {t('Blocking appointments')}:
                        </Label>
                        <BlockingAppointmentsList>
                          {dto.blocked_because_appointments.map(
                            (
                              blockedBecauseAppointment: IAppointment,
                              index: number
                            ) => {
                              return (
                                <>
                                  <Row gap={gapSm}>
                                    <Label fontSize={'20rem'}>
                                      {t(blockedBecauseAppointment.type.name)}
                                    </Label>
                                    <Label fontSize={'18rem'}>
                                      {blockedBecauseAppointment.id}
                                    </Label>
                                    <Tooltip
                                      content={
                                        <TooltipContentWrapper>
                                          <Row gap={gapSm}>
                                            {dto.appointment.quotation ? (
                                              <>
                                                <Label fontSize={'14rem'}>
                                                  {t('Quotation')}:
                                                </Label>
                                                <Label fontSize={'18rem'}>
                                                  {
                                                    dto.appointment.quotation
                                                      .number
                                                  }
                                                </Label>
                                              </>
                                            ) : null}
                                            {dto.appointment.sales_order ? (
                                              <>
                                                <Label fontSize={'14rem'}>
                                                  {t('Sales order')}
                                                </Label>
                                                <Label fontSize={'18rem'}>
                                                  {
                                                    dto.appointment.sales_order
                                                      .number
                                                  }
                                                </Label>
                                              </>
                                            ) : null}
                                          </Row>
                                          <Row gap={gapSm}>
                                            <Row gap={gapXs}>
                                              <Label fontSize={'14rem'}>
                                                {t('From') + ':'}
                                              </Label>
                                              <Label fontSize={'18rem'}>
                                                {moment(
                                                  blockedBecauseAppointment.date_from
                                                ).format('HH:mm')}
                                              </Label>
                                            </Row>
                                            <Row gap={gapXs}>
                                              <Label fontSize={'14rem'}>
                                                {t('To') + ':'}
                                              </Label>
                                              <Label fontSize={'18rem'}>
                                                {moment(
                                                  blockedBecauseAppointment.date_to
                                                ).format('HH:mm')}
                                              </Label>
                                            </Row>
                                          </Row>
                                        </TooltipContentWrapper>
                                      }
                                      key={`blocked-because-appointment-${index}`}
                                    >
                                      <Icon
                                        svg={WarningCircle}
                                        size={22}
                                        color={COLORS.PRIMARY}
                                      />
                                    </Tooltip>
                                  </Row>
                                </>
                              );
                            }
                          )}
                        </BlockingAppointmentsList>
                      </Row>
                    </SingleAppointmentWrapper>
                    {index <
                    blockedAppointmentsData.blocked_appointment_dtos.length -
                      1 ? (
                      <HorizontalLine />
                    ) : null}
                  </React.Fragment>
                );
              }
            )}
          </AppointmentList>
        ) : (
          <NoContentLabel>{t('No blocked appointments today')}</NoContentLabel>
        )}
      </BlockedSection>
      <HorizontalLine />
      <RunningSection>
        <H3>{t('Running past appointments')}:</H3>
        {runningAppointmentsData?.appointments?.length ? (
          <AppointmentList>
            {runningAppointmentsData.appointments.map(
              (appointment: IAppointment, index: number) => {
                return (
                  <React.Fragment key={`running-appointment-${index}`}>
                    <SingleAppointmentWrapper>
                      <Row gap={gapSm}>
                        <Label fontSize={'24rem'}>
                          {t(appointment.type.name)}
                        </Label>
                        <Label fontSize={'18rem'}>{appointment.id}</Label>
                      </Row>
                      <Row gap={'27rem'}>
                        <Row gap={gapXs}>
                          <Label fontSize={'14rem'}>{t('From') + ':'}</Label>
                          <Label fontSize={'18rem'}>
                            {moment(appointment.date_from).format('HH:mm')}
                          </Label>
                        </Row>
                        <Row gap={gapXs}>
                          <Label fontSize={'14rem'}>{t('To') + ':'}</Label>
                          <Label fontSize={'18rem'}>
                            {moment(appointment.date_to).format('HH:mm')}
                          </Label>
                        </Row>
                        <Row gap={gapXs}>
                          <Label fontSize={'14rem'}>{t('Date') + ':'}</Label>
                          <Label fontSize={'18rem'}>
                            {moment(appointment.date_from).format('DD-MM-YYYY')}
                          </Label>
                        </Row>
                      </Row>
                    </SingleAppointmentWrapper>
                    {runningAppointmentsData.appointments.length - 1 > index ? (
                      <HorizontalLine />
                    ) : null}
                  </React.Fragment>
                );
              }
            )}
          </AppointmentList>
        ) : (
          <NoContentLabel>{t('No running past appointments')}</NoContentLabel>
        )}
      </RunningSection>
    </Modal>
  );
};

export default RunningAndBlockedAppointmentsModal;
