import Button from 'components/Button/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import {
  Container,
  YourSessionHasExpiredLabel,
} from './SessionExpiredModal.styled';

export interface ISessionExpiredModalProps {
  onSubmit: any;
  isOpen: boolean;
}

export function SessionExpiredModal({
  onSubmit,
  isOpen,
}: ISessionExpiredModalProps) {
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && isOpen) {
        onSubmit && onSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isOpen]);

  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={() => null}
      modalStyle={{ margin: 'auto', position: 'fixed' }} // Center positioning
    >
      <Container>
        <YourSessionHasExpiredLabel>
          {t('Your session has expired')}
        </YourSessionHasExpiredLabel>
        <Button
          label={t('To Login Page')}
          primary
          width="200rem"
          onClick={onSubmit}
        />
      </Container>
    </Modal>
  );
}
