import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_IS_AWAITING_CONFIGURATION,
  SET_IS_AWAITING_EDIT,
  SET_LOGYX_CONFIGURATION_INTENT,
  SET_IS_AWAITING_PURCHASE_ORDER_LINE_EDIT,
  SET_PENDING_INTENT_UUID,
} from '../constants';
import { LOGOUT } from 'store/User/constants';
import { ILogyxConfigurationIntent } from '../types';

export interface ILogyxReducerState {
  isAwaitingConfiguration: boolean; // *
  isAwaitingEdit: boolean; // *
  isAwaitingPurchaseOrderLineEdit: boolean; // No configuration data is transfered to the frontend in this case, it is used only for loader display
  pendingIntentUUID: string | null; // Intent uuid that is expected to arrive, Used as failsafe for retried socket messages, so a product never receives the wrong configuration
  logyxConfigurationIntent: ILogyxConfigurationIntent | null; // Intent that arrived that the holds data necessary for product configuration
}
// * These are required so that the "Awaiting" logyx configuration modal is shown before we even know what the actual intent uuid is

const INIT_STATE: ILogyxReducerState = {
  isAwaitingConfiguration: false,
  logyxConfigurationIntent: null,
  isAwaitingEdit: false,
  isAwaitingPurchaseOrderLineEdit: false,
  pendingIntentUUID: null,
};

const logyxReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_PENDING_INTENT_UUID:
      return {
        ...state,
        pendingIntentUUID: action.payload,
      };
    case SET_IS_AWAITING_CONFIGURATION:
      return {
        ...state,
        isAwaitingConfiguration: action.payload,
      };
    case SET_LOGYX_CONFIGURATION_INTENT:
      return {
        ...state,
        logyxConfigurationIntent: action.payload,
      };
    case SET_IS_AWAITING_EDIT:
      return {
        ...state,
        isAwaitingEdit: action.payload,
      };
    case SET_IS_AWAITING_PURCHASE_ORDER_LINE_EDIT:
      return {
        ...state,
        isAwaitingPurchaseOrderLineEdit: action.payload,
      };
    case LOGOUT: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default logyxReducer;
