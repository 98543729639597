import { borderRadiusSm, COLORS, marginMd, paddingMd } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  marginTop: string;
}

export const OverviewContainer = styled.div`
  width: 690rem;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  margin-top: ${marginMd};
  align-self: center;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 430rem;
  justify-content: space-between;
  margin-top: ${marginMd};
  align-self: center;
`;

export const RowMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const InvoiceCreationInfoWrapper = styled.div`
  margin-top: ${marginMd};
`;
