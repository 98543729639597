import { COLORS, marginLg, marginMd, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $darkMode: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  width: 750rem;
  border-radius: 10rem;
  margin-top: 60rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin-top: ${marginMd};
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
  margin-left: ${marginMd};
`;

export const Heading = styled.label`
  padding: 0rem 0 30rem 0rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginSm};
  flex: 1;
`;

export const Wrapper = styled.div`
  width: 700rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const SignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  margin: 0 auto;
  width: 500rem;

  ${(props: StyledProps) =>
    props.$darkMode &&
    css`
      background-color: ${COLORS.CANVAS};
    `}
`;

export const ValueWrapperRelative = styled.div`
  display: flex;
  position: relative;
  margin-right: ${marginLg};
`;

export const RowHalf = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const NonEditModeLabelWrapper = styled.div`
  margin-top: 8rem;
  margin-bottom: 8rem;
  height: 41rem;
  color: ${COLORS.BLACK};
`;

export const SignatureImage = styled.img`
  max-width: 500rem;
`;
