import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editSalesOrderWorkingHoursLine } from 'services/SalesOrder/SalesOrderService';
import { IEditSalesOrderWorkingHoursLine } from './types';

export const useEditSalesOrderWorkingHoursLine = (
  salesOrderId: string,
  salesOrderLineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (workingHoursLineDTO: IEditSalesOrderWorkingHoursLine) =>
      editSalesOrderWorkingHoursLine(
        salesOrderId,
        salesOrderLineId,
        workingHoursLineDTO
      ),
    {
      onSuccess: () => {
        toast.success(t('Sales order line edited'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_WORKING_HOURS_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_WORKING_HOURS_LINE,
    }
  );
};
