import { ICustomer, ITwinfieldCustomer } from './Customer.types';

export enum AccountingType {
  TWINFIELD = 'TWINFIELD',
}

export interface IExternalTwinfieldCustomer {
  customer: ICustomer;
  twinfield_customer?: ITwinfieldCustomer;
}

export interface IExternalTwinfieldCustomerDetails {
  email: string;
}
