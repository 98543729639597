import { Route, Routes } from 'react-router-dom';
import { SinglePurchaseOrderRoutes } from 'navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';
import SinglePurchaseOrderProductsPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderProductsPage/SinglePurchaseOrderProductsPage';
import SinglePurchaseOrderCommentsPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderCommentsPage/SinglePurchaseOrderCommentsPage';
import SinglePurchaseOrderFilesPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderFilesPage/SinglePurchaseOrderFilesPage';
import SinglePurchaseOrderInvoicesPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderInvoicesPage/SinglePurchaseOrderInvoicesPage';
import SinglePurchaseOrderGeneralPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderGeneralPage/SinglePurchaseOrderGeneralPage';
import SinglePurchaseOrderNewComment from 'pages/Manager/SinglePurchaseOrder/NewComment/SinglePurchaseOrderNewComment';
import SinglePurchaseOrderSalesOrderPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderSalesOrderPage/SinglePurchaseOrderSalesOrdersPage';
import NewPurchaseInvoicePage from 'pages/Manager/NewPurchaseInvoicePage/NewPurchaseInvoicePage';
import DirectLogyxOrderPage from 'pages/Manager/DirectLogyxOrderPage/DirectLogyxOrderPage';

const SinglePurchaseOrderRouter = () => {
  return (
    <Routes>
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderGeneral.path}
        element={<SinglePurchaseOrderGeneralPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderProducts.path}
        element={<SinglePurchaseOrderProductsPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderComments.path}
        element={<SinglePurchaseOrderCommentsPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderInvoices.path}
        element={<SinglePurchaseOrderInvoicesPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderSales.path}
        element={<SinglePurchaseOrderSalesOrderPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderFiles.path}
        element={<SinglePurchaseOrderFilesPage />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment.path}
        element={<SinglePurchaseOrderNewComment />}
      />
      <Route
        path={SinglePurchaseOrderRoutes.SinglePurchaseOrderNewInvoice.path}
        element={<NewPurchaseInvoicePage />}
      />
      <Route
        path={
          SinglePurchaseOrderRoutes.SinglePurchaseOrderDirectLogyxOrder.path
        }
        element={<DirectLogyxOrderPage />}
      />
    </Routes>
  );
};

export default SinglePurchaseOrderRouter;
