import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1, isBlue: false },
  { header: 'Name', flexValue: 2, isBlue: false },
  { header: 'Quantity bought', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Supplier', flexValue: 1, isBlue: true },
  { header: 'In stock', flexValue: 1, isBlue: false },
  {
    header: 'Units sold',
    flexValue: 1,
    isBlue: false,
    alternativeSortField: 'quantity_sold',
  },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};

export const STOCK_ITEMS_PER_PAGE = 10;
