import {
  Container,
  TabContainer,
  Tab,
  CreateNewInvoiceLabel,
} from './NewSalesInvoicePage.styled';
import { useEffect, useState } from 'react';
import { CreateInvoiceTab, IPendingInvoiceLine } from './constants';
import {
  useCreateSalesInvoice,
  useGetPrepopulateInvoiceData,
  useSalesInvoiceCreationInfo,
} from './hooks';
import {
  getInitialCustomerDetailsData,
  calculateInvoiceDetails,
  getInitialInvoiceDetailsData,
  getCreateDownpaymentInvoicePayload,
  getCreateInvoicePayload,
} from './helpers';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { ICreateInvoiceCustomerDetails } from './CustomerTab/constants';
import CustomerTab from './CustomerTab/CustomerTab';
import { ICreateInvoiceDetails } from './InvoiceDetailsTab/constants';
import { useTranslation } from 'react-i18next';
import InvoiceDetailsTab from './InvoiceDetailsTab/InvoiceDetailsTab';
import OverviewTab from './OverviewTab/OverviewTab';
import {
  ICreateDownpaymentInvoiceDTO,
  ICreateSalesInvoiceDTO,
} from 'types/Invoice.types';
import { useGetSingleSalesOrderInvoices } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderInvoicesPage/hooks';
import { ISalesOrder } from 'types/SalesOrders.types';
import { useGetPendingInvoiceLines } from './CustomerTab/hooks';
import { useParams } from 'react-router-dom';

const PAGE = 1;
const PER_PAGE = 30;

const NewSalesInvoicePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const prepopulateInvoiceData: ISalesOrder = useGetPrepopulateInvoiceData();
  const [selectedPartialIds, setSelectedPartialIds] = useState<number[]>([]);
  const {
    data: pendingInvoiceLines,
    isSuccess: isSuccessGetPendingInvoiceLines,
  } = useGetPendingInvoiceLines(Number(id));
  useEffect(() => {
    if (isSuccessGetPendingInvoiceLines) {
      setSelectedPartialIds(
        pendingInvoiceLines?.map((line: IPendingInvoiceLine) => line.id) || []
      );
    }
  }, [isSuccessGetPendingInvoiceLines]);

  const handleToggleSelectPartials = (id: number) => {
    setSelectedPartialIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );
  };

  const handleSelectAllPartials = () => {
    const allProductIds =
      pendingInvoiceLines?.map((line: IPendingInvoiceLine) => line.id) || [];
    setSelectedPartialIds((prevIds) =>
      prevIds.length === allProductIds.length ? [] : allProductIds
    );
  };

  const isDownPaymentInvoiceCreated =
    !prepopulateInvoiceData.downpayment ||
    prepopulateInvoiceData.downpayment_invoice_created;
  const initialCountry = useGetInitialCountry();

  const [activeTab, setActiveTab] = useState<string>(
    CreateInvoiceTab.CUSTOMER_DETAILS
  );

  const [customerDetails, setCustomerDetails] =
    useState<ICreateInvoiceCustomerDetails>(
      getInitialCustomerDetailsData(prepopulateInvoiceData, initialCountry.cca2)
    );

  // totalPrice from invoice details is not sent to the backend, it is preview only
  const [invoiceDetails, setInvoiceDetails] = useState<ICreateInvoiceDetails>(
    getInitialInvoiceDetailsData()
  );

  const {
    mutate: createSalesinvoice,
    data,
    status,
    isLoading: isLoadingCreateSalesInvoice,
  } = useCreateSalesInvoice();
  const {
    data: salesOrderInvoices,
    isSuccess: isSuccessGetSalesOrderInvoices,
    isLoading: isLoadingGetSalesOrderInvoices,
  } = useGetSingleSalesOrderInvoices(
    prepopulateInvoiceData?.id,
    PAGE,
    PER_PAGE
  );

  const { invoiceCreationInfoData, isLoadingInvoiceCreationInfo } =
    useSalesInvoiceCreationInfo(
      isSuccessGetSalesOrderInvoices,
      prepopulateInvoiceData,
      invoiceDetails,
      selectedPartialIds,
      isDownPaymentInvoiceCreated,
      salesOrderInvoices
    );

  const createInvoice = () => {
    const createInvoiceData: ICreateSalesInvoiceDTO = getCreateInvoicePayload(
      prepopulateInvoiceData,
      invoiceDetails,
      selectedPartialIds,
      isDownPaymentInvoiceCreated,
      salesOrderInvoices?.sales_invoices || []
    );
    createSalesinvoice(createInvoiceData);
  };

  const createDownpaymentInvoice = () => {
    const createDownpaymentInvoiceData: ICreateDownpaymentInvoiceDTO =
      getCreateDownpaymentInvoicePayload(
        prepopulateInvoiceData,
        invoiceDetails
      );
    createSalesinvoice(createDownpaymentInvoiceData);
  };

  const handleActiveTab = () => {
    if (activeTab === CreateInvoiceTab.CUSTOMER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1. {t('Customer details')}</Tab>
          <Tab>2. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateInvoiceTab.INVOICE_DETAILS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab active>2. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateInvoiceTab.OVERVIEW) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab finished>2. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    }
  };

  const handleCreateInvoice = () => {
    isDownPaymentInvoiceCreated ? createInvoice() : createDownpaymentInvoice();
  };

  return (
    <Container>
      <CreateNewInvoiceLabel>
        {isDownPaymentInvoiceCreated
          ? t('Create new invoice')
          : t('Create down payment invoice')}
      </CreateNewInvoiceLabel>
      {handleActiveTab()}
      {activeTab === CreateInvoiceTab.CUSTOMER_DETAILS && (
        <CustomerTab
          onForward={() => setActiveTab(CreateInvoiceTab.INVOICE_DETAILS)}
          customerDetails={customerDetails}
          setCustomerDetails={setCustomerDetails}
          selectedPartialIds={selectedPartialIds}
          handleToggleSelectPartials={handleToggleSelectPartials}
          handleSelectAllPartials={handleSelectAllPartials}
          pendingInvoiceLines={pendingInvoiceLines}
          isDownPaymentInvoiceCreated={isDownPaymentInvoiceCreated}
        />
      )}
      {activeTab === CreateInvoiceTab.INVOICE_DETAILS && (
        <InvoiceDetailsTab
          onBack={() => setActiveTab(CreateInvoiceTab.CUSTOMER_DETAILS)}
          onForward={() => setActiveTab(CreateInvoiceTab.OVERVIEW)}
          calculateInvoiceDetails={() =>
            calculateInvoiceDetails(prepopulateInvoiceData, invoiceDetails)
          }
          setInvoiceDetails={setInvoiceDetails}
          isDownpaymentInvoiceCreated={isDownPaymentInvoiceCreated}
          totalAmount={
            invoiceCreationInfoData?.sales_invoice_info?.total_amount
          }
          isLoadingTotalAmount={
            isLoadingGetSalesOrderInvoices || isLoadingInvoiceCreationInfo
          }
        />
      )}
      {activeTab === CreateInvoiceTab.OVERVIEW && (
        <OverviewTab
          isDownPaymentInvoiceCreated={isDownPaymentInvoiceCreated}
          onBack={() => setActiveTab(CreateInvoiceTab.INVOICE_DETAILS)}
          onForward={() =>
            !isLoadingCreateSalesInvoice && handleCreateInvoice()
          }
          customerDetails={customerDetails}
          invoiceDetails={invoiceDetails}
          status={status}
          newlyCreatedInvoiceId={data?.sales_invoice?.id}
          totalAmount={
            invoiceCreationInfoData?.sales_invoice_info?.total_amount
          }
          isLoadingTotalAmount={
            isLoadingGetSalesOrderInvoices || isLoadingInvoiceCreationInfo
          }
        />
      )}
    </Container>
  );
};

export default NewSalesInvoicePage;
