import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editCustomer } from 'services/Customer/CustomerService';
import { editSupplier } from 'services/Supplier/SupplierService';

export const useEditCustomer = (customerId: string) => {
  const { t } = useTranslation();

  return useMutation(
    (editCustomerData: any) => editCustomer(customerId, editCustomerData),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_CUSTOMER]);
        toast.success(t('Successfully edited customer'), {
          className: ReactMutationKeys.EDIT_CUSTOMER,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_CUSTOMER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_CUSTOMER,
    }
  );
};

export const useEditSupplier = (supplierId: string) => {
  const { t } = useTranslation();

  return useMutation(
    (editSupplierData: any) => editSupplier(supplierId, editSupplierData),
    {
      onSuccess: (data) => {
        // Success
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SUPPLIER]);
        toast.success(t('Successfully edited supplier'), {
          className: ReactMutationKeys.EDIT_SUPPLIER,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SUPPLIER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SUPPLIER,
    }
  );
};
