import { COLORS, marginSm, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400rem;
  background-color: ${COLORS.WHITE};
  padding-bottom: ${paddingSm};
  padding-top: ${paddingSm};
  border-top: 1rem solid ${COLORS.GREY_1000};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  letter-spacing: 0.005em;
  text-align: left;
  margin-right: ${marginSm};
  color: ${COLORS.PRIMARY};

  ${respondTo.bigTablet`
    font-size: 18rem;
  `}
`;

export const Date = styled.div`
  font-family: Poppins;
  font-size: 14rem;
  font-weight: 400;
  line-height: 22rem;
  letter-spacing: 0.005em;
  text-align: left;
  color: ${COLORS.GREY_1000};

  ${respondTo.bigTablet`
    font-size: 16rem;
  `}
`;

export const Description = styled.div`
  font-family: Poppins;
  font-size: 14rem;
  font-weight: 400;
  line-height: 22rem;
  letter-spacing: 0.005em;
  text-align: left;
  color: ${COLORS.BLACK_200};

  ${respondTo.bigTablet`
    font-size: 16rem;
  `}
`;
