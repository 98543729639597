import { Option } from 'components/Select/type';
import { SalesOrderStatusType } from 'pages/Manager/SingleSalesOrder/constants';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { ISalesOrderStatus } from 'types/SalesOrders.types';

export const useGetSalesOrderStatuses = (
  statusType: SalesOrderStatusType,
  includeAll = false
) => {
  const storeSalesOrderStatuses = useSelector(
    (state: IRootReducerState) => state.commonInfo.salesOrderStatuses
  );
  const memoizedStatuses: Option[] = useMemo(() => {
    switch (statusType) {
      case SalesOrderStatusType.STATUS: {
        const statuses = storeSalesOrderStatuses?.statuses?.map(
          (status: ISalesOrderStatus) => {
            return {
              name: status.name,
              value: status.name,
              label: status.name,
              id: status.id,
              key: status.id,
            };
          }
        );
        if (includeAll) {
          statuses?.unshift({ value: '', label: 'All' });
        }
        return statuses;
      }
      case SalesOrderStatusType.SUBSTATUS: {
        const substatuses = storeSalesOrderStatuses?.sub_statuses?.map(
          (status: ISalesOrderStatus) => {
            return {
              id: status.id,
              name: status.name,
              value: status.name,
              label: status.name,
              key: status.id,
            };
          }
        );
        if (includeAll) {
          substatuses?.unshift({ value: '', label: 'All' });
        }
        return substatuses;
      }
      case SalesOrderStatusType.PAYMENT: {
        const payment_statuses = storeSalesOrderStatuses?.payment_statuses?.map(
          (status: ISalesOrderStatus) => {
            return {
              name: status.name,
              value: status.name,
              label: status.name,
              id: status.id,
              key: status.id,
            };
          }
        );
        if (includeAll) {
          payment_statuses?.unshift({ value: '', label: 'All' });
        }
        return payment_statuses;
      }
      default:
        return;
    }
  }, [storeSalesOrderStatuses, i18n.resolvedLanguage]);
  return memoizedStatuses;
};
