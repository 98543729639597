import {
  IAddPurchaseInvoicePaymentDTO,
  IAddSalesInvoicePaymentDTO,
} from 'components/Modal/AddPaymentModal/hooks';
import apiClient from '../api/apiService';

const BASE_URL = '/v1/payments/';

export const addSalesInvoicePayment = async (
  addPaymentDTO: IAddSalesInvoicePaymentDTO
): Promise<any> => {
  const addPaymentData = {
    amount: addPaymentDTO.amount,
    payment_type_id: addPaymentDTO.paymentTypeId,
    sales_invoice_id: addPaymentDTO.invoiceId,
    date: addPaymentDTO.date,
    reference: addPaymentDTO.reference,
  };
  const { data } = await apiClient.post<any>(BASE_URL, addPaymentData);
  return data.data;
};

export const addPurchaseInvoicePayment = async (
  addPaymentDTO: IAddPurchaseInvoicePaymentDTO
): Promise<any> => {
  const addPaymentData = {
    amount: addPaymentDTO.amount,
    payment_type_id: addPaymentDTO.paymentTypeId,
    purchase_invoice_id: addPaymentDTO.invoiceId,
    date: addPaymentDTO.date,
    reference: addPaymentDTO.reference,
  };
  const { data } = await apiClient.post<any>(BASE_URL, addPaymentData);
  return data.data;
};

export const deletePayment = async (paymentId: string): Promise<any> => {
  const url = `${BASE_URL}${paymentId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};
