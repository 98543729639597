import { borderRadiusSm, COLORS, paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  width: string;
  height: string;
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  marginBottom: string;
  maxHeight: string;
}

export const Container = styled.div`
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${(props: StyledProps) => props.width};
    `}
  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `}
  ${(props: StyledProps) =>
    props.marginLeft &&
    css`
      margin-left: ${(props: StyledProps) => props.marginLeft};
    `}
    ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${(props: StyledProps) => props.marginRight};
    `}
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
    ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props: StyledProps) => props.marginBottom};
    `}
  ${(props: StyledProps) =>
    props.maxHeight &&
    css`
      max-height: ${(props: StyledProps) => props.maxHeight};
    `}
  font-family: inherit;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.GREY_200};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  cursor: not-allowed;
  position: relative;
`;

export const FakeInputLabel = styled.label`
  cursor: not-allowed;
  color: ${COLORS.GREY_1000};
  font-size: 13rem;
  line-height: 15.85rem;
  margin-left: 6rem;
`;
