import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRunTour } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';

interface IBackEventInterceptorProviderProps {
  children: React.ReactNode;
}

export const BackEventInterceptorProvider = ({
  children,
}: IBackEventInterceptorProviderProps) => {
  const dispatch = useDispatch();
  const runTour = useSelector(
    (state: IRootReducerState) => state.commonInfo.runTour
  );

  useEffect(() => {
    const handleBackEvent = () => {
      if (runTour) {
        dispatch(setRunTour(false));
        document.body.style.overflow = 'auto';
      }
    };
    window.addEventListener('popstate', handleBackEvent);

    return () => {
      window.removeEventListener('popstate', handleBackEvent);
    };
  }, [runTour]);

  return <>{children}</>;
};
