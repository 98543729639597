import { COLORS, gapLg, marginMd, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
  gap: ${gapLg};
`;

export const TimelineCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
`;

export const BackgroundColorWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;

export const EditedFromToLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1rem;
    text-underline-position: under;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 850rem;
  margin-bottom: ${marginSm};
`;

export const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;
