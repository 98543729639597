// Single Supplier
export const SingleSupplierRoutes = {
  SingleSupplierGeneral: {
    path: '',
    fullPath: '/suppliers/:id',
  },
  SingleSupplierProducts: {
    path: 'products',
    fullPath: '/suppliers/:id/products',
  },
  SingleSupplierPurchaseOrders: {
    path: 'purchase-orders',
    fullPath: '/suppliers/:id/purchase-orders',
  },
  SingleSupplierAppointments: {
    path: 'appointments',
    fullPath: '/suppliers/:id/appointments',
  },
  SingleSupplierNewPurchaseOrder: {
    path: 'suppliers/:id/purchase-order',
    fullPath: '/suppliers/:id/purchase-order',
  },
};
