import FullCalendar from '@fullcalendar/react'; // Must go before plugins
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  CalendarWrapper,
  Container,
  ButtonsWrapper,
  Header,
  TodayButton,
  SelectInputWrapper,
  ViewTypeOptionLabel,
  RelativeDiv,
  LoaderWrapper,
} from './FwAppointmentsCalendarView.styled';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { EventContentArg } from '@fullcalendar/core';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import CalendarDateIntervalHeader from 'components/Calendar/CalendarDateIntervalHeader';
import Event from 'pages/Manager/PlanningPage/CustomEvent/Event';
import { formatAppointmentEventData } from 'pages/Manager/PlanningPage/helpers';
import { useUpdateCalendarShortDayNamesToResolvedLocale } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { useTranslation } from 'react-i18next';
import { FwAppointmentsViewType } from '../FwAppointmentsPage';
import { IAppointment } from 'types/Appointment.types';
import { useNavigate } from 'react-router-dom';
import { PreviewDayAppointmentsModal } from 'components/Modal/PreviewDayAppointmentsModal/PreviewDayAppointmentsModal';
import Loader from 'components/Loader/Loader';

interface IFwAppointmentsCalendarViewProps {
  appointments: IAppointment[];
  startDate: Moment;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  endDate: Moment;
  setEndDate: Dispatch<SetStateAction<Moment>>;
  selectedAppointmentTypeOption: Option;
  setSelectedAppointmentTypeOption: Dispatch<SetStateAction<Option>>;
  setSelectedViewType: Dispatch<SetStateAction<FwAppointmentsViewType>>;
  appointmentTypeOptions: Option[];
  isFetchingFwAppointments: boolean;
}

const FwAppointmentsCalendarView = ({
  appointments,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedAppointmentTypeOption,
  setSelectedAppointmentTypeOption,
  setSelectedViewType,
  appointmentTypeOptions,
  isFetchingFwAppointments,
}: IFwAppointmentsCalendarViewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dateForDaySummary, setDateForDaySummary] = useState<Moment | null>(
    null
  );

  const calendarRef = useRef<FullCalendar>(null);

  const handleAppointmentEventCardClick = (id: string) => {
    navigate(id);
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return <Event content={eventInfo} />;
  };

  const appointmentEvents = formatAppointmentEventData(appointments);

  useUpdateCalendarShortDayNamesToResolvedLocale(
    startDate,
    endDate,
    setDateForDaySummary
  );

  return (
    <Container>
      <CalendarWrapper>
        <Header>
          <CalendarDateIntervalHeader
            startDate={startDate}
            endDate={endDate}
            next={() => calendarRef.current?.getApi()?.next()}
            prev={() => calendarRef.current?.getApi()?.prev()}
          />
          <TodayButton
            disabled={
              moment().isSame(
                moment(calendarRef.current?.getApi()?.view.activeStart),
                'day'
              ) ||
              moment().isSame(
                moment(calendarRef.current?.getApi()?.view.activeEnd).subtract(
                  1,
                  'day'
                ),
                'day'
              )
            }
            onClick={() => {
              calendarRef.current?.getApi()?.today();
            }}
            label={t('Today')}
            secondary
          />
          <ButtonsWrapper>
            <SelectInputWrapper>
              <Select
                defaultValue={
                  selectedAppointmentTypeOption
                    ? selectedAppointmentTypeOption
                    : appointmentTypeOptions[0]
                }
                name="type"
                placeholder={t('Type')}
                isMulti={false}
                isDisabled={false}
                isSearchable={false}
                onChange={(e: Option) => setSelectedAppointmentTypeOption(e)}
                options={appointmentTypeOptions}
              />
            </SelectInputWrapper>
            <ViewTypeOptionLabel isSelected={true}>
              {t('Calendar view')}
            </ViewTypeOptionLabel>
            <ViewTypeOptionLabel
              onClick={() => setSelectedViewType(FwAppointmentsViewType.LIST)}
            >
              {t('List view')}
            </ViewTypeOptionLabel>
          </ButtonsWrapper>
        </Header>
        <RelativeDiv className="fullcalendar_homepage_wrapper">
          {isFetchingFwAppointments ? (
            <LoaderWrapper>
              <Loader positionType="relative" />
            </LoaderWrapper>
          ) : null}
          <FullCalendar
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            eventClick={(e) => {
              handleAppointmentEventCardClick(e.event.id);
            }}
            ref={calendarRef}
            firstDay={1} // Start from Monday
            allDaySlot={false} // Hide all day row
            slotMinTime="06:00:00"
            slotMaxTime="22:00:00"
            nowIndicator={true}
            droppable={false}
            editable={false}
            selectable={false}
            selectMirror={true}
            events={appointmentEvents}
            slotEventOverlap={false}
            eventContent={(content: EventContentArg) =>
              renderEventContent(content)
            }
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView={'timeGridWeek'}
            headerToolbar={false} // Hide built-in header
            dayHeaderFormat={(param) => {
              // Day header values
              return param.date.marker.toString().split(' ')[0];
            }}
            datesSet={(arg) => {
              // Used for custom header date interval
              setStartDate(moment(arg.view.activeStart));
              setEndDate(moment(arg.view.activeEnd));
            }}
            initialDate={
              startDate instanceof moment
                ? startDate.toDate()
                : moment(startDate).toDate()
            }
            height="auto"
          />
        </RelativeDiv>
      </CalendarWrapper>
      <PreviewDayAppointmentsModal
        dateForDaySummary={dateForDaySummary}
        setDateForDaySummary={setDateForDaySummary}
      />
    </Container>
  );
};

export default FwAppointmentsCalendarView;
