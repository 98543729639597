import { css } from 'styled-components';

const lineStyles = css`
  content: '';
  background-color: currentColor;
  position: absolute;
  bottom: 0;
  height: 1px;
  transition: ease 0.3s;
`;

export const hoverLineIn = css`
  position: relative;
  align-self: flex-start;

  &::before,
  &::after {
    ${lineStyles}
    width: 0;
  }

  &::before {
    left: 50%;
  }

  &::after {
    right: 50%;
  }

  &:hover::before,
  &:hover::after {
    width: 50%;
  }

  &:hover::before {
    left: 0;
  }

  &:hover::after {
    right: 0;
  }
`;

export const hoverLineOut = css`
  position: relative;
  align-self: flex-start;

  &::before,
  &::after {
    ${lineStyles}
    width: 100%;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:hover::before {
    width: 0;
    left: 50%;
  }

  &:hover::after {
    width: 0;
    right: 50%;
  }
`;
