import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editSalesOrderServiceLine } from 'services/SalesOrder/SalesOrderService';
import { IEditSalesOrderServiceLine } from './constants';

export const useEditSalesOrderServiceLine = (
  salesOrderId: string,
  salesOrderLineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (serviceLineDTO: IEditSalesOrderServiceLine) =>
      editSalesOrderServiceLine(salesOrderId, salesOrderLineId, serviceLineDTO),
    {
      onSuccess: () => {
        toast.success(t('Sales order line edited'), {
          className: ReactMutationKeys.EDIT_SALES_ORDER_SERVICE_LINE,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_SERVICE_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_SERVICE_LINE,
    }
  );
};
