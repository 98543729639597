import { CLEAR_PROMPT, SAVE_PROMPT } from '../constants';

export const savePrompt = (prompt: any) => ({
  type: SAVE_PROMPT,
  payload: prompt,
});

export const clearPrompt = () => ({
  type: CLEAR_PROMPT,
});
