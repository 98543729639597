import {
  CloseContainer,
  BlueHeader,
  TopRow,
  BottomRow,
  Content,
  HeaderLabel,
  TimelineRow,
  TimelineRowLabel,
  HeaderLabelWrapper,
  TimelineRowLabelWrapper,
  NoContentLabel,
} from './PreviewTimesheetModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'components/Modal/Modal';
import { IAppointmentInterval } from 'types/Appointment.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import {
  calculateTotalTime,
  getIntervalLabel,
  splitIntervalByPauses,
} from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentTimesheetPage/helpers';
import moment from 'moment';
import { Level } from 'components/Modal/type';

interface IPreviewTimesheetModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  intervals: IAppointmentInterval[];
  level: Level;
}

export const PreviewTimesheetModal = ({
  isOpen,
  setIsOpen,
  intervals: allIntervals,
  level = 'FIRST',
}: IPreviewTimesheetModalProps) => {
  const { t } = useTranslation();

  const { name: firstName, last_name: lastName } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  // Remove user intervals, keep only worker or resource intervals
  const intervals = allIntervals.filter(
    (interval: IAppointmentInterval) => interval.worker || interval.resource
  );
  // Flatten and sort all subintervals
  const allSubIntervals = intervals
    .flatMap((interval: IAppointmentInterval) =>
      splitIntervalByPauses(interval).map((subInterval) => ({
        ...subInterval,
        parentInterval: interval,
      }))
    )
    .sort(
      (a: { start: string; end: string }, b: { start: string; end: string }) =>
        moment(a.start).valueOf() - moment(b.start).valueOf()
    );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed', maxWidth: '850rem' }}
      level={level}
    >
      <CloseContainer>
        {t('Timesheet')}
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <BlueHeader>
        <TopRow>
          <HeaderLabel>{firstName + ' ' + lastName}</HeaderLabel>
          <HeaderLabel>
            {t('Total time for workers')}: {calculateTotalTime(intervals)}
          </HeaderLabel>
        </TopRow>
        <BottomRow>
          <HeaderLabelWrapper>
            <HeaderLabel>
              {t('Worker')}/{t('Resource')}
            </HeaderLabel>
          </HeaderLabelWrapper>
          <HeaderLabelWrapper>
            <HeaderLabel>{t('Start time')}</HeaderLabel>
          </HeaderLabelWrapper>
          <HeaderLabelWrapper>
            <HeaderLabel>{t('Stop time')}</HeaderLabel>
          </HeaderLabelWrapper>
        </BottomRow>
      </BlueHeader>
      <Content>
        {allSubIntervals.length ? (
          allSubIntervals.map(
            (
              subInterval: {
                start: string;
                end: string;
                parentInterval: IAppointmentInterval;
              },
              index: number
            ) => (
              <TimelineRow
                withoutBorderBottom={allSubIntervals.length - 1 <= index}
                key={index} // Unique key for each sub-interval
              >
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {getIntervalLabel(
                      subInterval.parentInterval,
                      firstName + ' ' + lastName
                    )}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {moment
                      .utc(subInterval.start)
                      .tz(moment.tz.guess())
                      .format('HH:mm:ss')}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
                <TimelineRowLabelWrapper>
                  <TimelineRowLabel>
                    {subInterval.end
                      ? moment
                          .utc(subInterval.end)
                          .tz(moment.tz.guess())
                          .format('HH:mm:ss')
                      : '/'}
                  </TimelineRowLabel>
                </TimelineRowLabelWrapper>
              </TimelineRow>
            )
          )
        ) : (
          <NoContentLabel>
            {t('There are currently no intervals for this appointment')}
          </NoContentLabel>
        )}
      </Content>
    </Modal>
  );
};
