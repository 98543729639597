import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';
import { LayoutColumn, LayoutRow } from './ManagerLayout.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

export function ManagerLayout() {
  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  return (
    <LayoutRow>
      <SideMenu />
      <LayoutColumn isSideMenuCollapsed={isSideMenuCollapsed}>
        <Header />
        <Outlet />
      </LayoutColumn>
    </LayoutRow>
  );
}

export default ManagerLayout;
