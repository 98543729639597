import { FormikContextType, getIn, useFormikContext } from 'formik';
import { CSSProperties, HTMLProps, useEffect, useRef, useState } from 'react';
import {
  Container,
  ErrorContainer,
  ErrorMessage,
  InputLabel,
  StyledInput,
  StyledTextArea,
  IconWrap,
  SymbolWrap,
  SymbolSpan,
} from './InputFormik.styled';
import Placeholder from './Placeholder/Placeholder';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';

interface IInput extends HTMLProps<HTMLInputElement> {
  secure?: boolean;
  label?: string;
  styles?: CSSProperties;
  errorMessage?: string;
  wrapperStyles?: CSSProperties;
  validate?: (value: string, fieldName: string) => void;
  isTextArea?: boolean;
  icon?: any;
  handleOnChange?: (value: string) => void;
  type?: string;
  pwId?: string;
  symbol?: string;
}

export const Input = ({
  errorMessage,
  type,
  styles,
  wrapperStyles,
  label,
  validate,
  isTextArea,
  handleOnChange,
  pwId,
  ...props
}: IInput) => {
  const context: FormikContextType<any> = useFormikContext();
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(
    props.name && getIn(context.values, props.name) ? true : false
  );

  useEffect(() => {
    if (props.name && getIn(context.values, props.name)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [context.values]);

  useEffect(() => {
    if (props.disabled && !inputRef?.current?.value) {
      setIsFocused(false);
    }
  }, [props.disabled]);

  return (
    <Container
      wrapperStyles={wrapperStyles}
      onClick={() => {
        inputRef?.current?.focus();
        !props.disabled && setIsFocused(true);
      }}
    >
      {label && (
        <InputLabel type={props} htmlFor={props.name}>
          {label}
        </InputLabel>
      )}

      {isTextArea ? (
        <StyledTextArea
          {...props}
          data-testid={pwId}
          component={'textarea'}
          innerRef={inputRef}
          validate={validate}
          placeholder="&nbsp;"
          type={type || 'text'}
          styles={styles}
          errormessage={errorMessage}
          onBlur={(e: any) => {
            props.onBlur && props.onBlur(e);
            setIsFocused(false);
          }}
          secure={props.secure}
        />
      ) : (
        <>
          <StyledInput
            {...props}
            data-testid={pwId}
            innerRef={inputRef}
            validate={validate}
            type={type || 'text'}
            styles={styles}
            placeholder="&nbsp;"
            onFocus={(e: any) => {
              if (e.target.value == 0 && !props.secure)
                context.setFieldValue(props.name!, '');
            }}
            onChange={(e: any) => {
              context.setFieldValue(props.name!, e.target.value);
              props.onChange && props.onChange(e.target.value);
              handleOnChange && handleOnChange(e.target.value);
            }}
            errormessage={errorMessage}
            onBlur={(e: any) => {
              props.onBlur && props.onBlur(e);
              const initialValue = getIn(context.initialValues, props.name!);
              if (e.target.value.trim() === '' && initialValue == 0) {
                context.setFieldValue(props.name!, initialValue);
              }
              setIsFocused(false);
            }}
            secure={props.secure}
            $hasIcon={props.icon || props.symbol}
          />
          {props.icon && (
            <IconWrap>
              <Icon
                svg={props.icon}
                size={15}
                weight="regular"
                color={COLORS.GREY_1000}
                pointer={false}
              />
            </IconWrap>
          )}
          {props.symbol && (
            <SymbolWrap>
              <SymbolSpan>{props.symbol}</SymbolSpan>
            </SymbolWrap>
          )}
        </>
      )}
      <Placeholder
        isTranslatedToTop={isFocused || isDirty || !!inputRef?.current?.value}
        placeholder={props.placeholder}
        isFormikPlaceholder={true}
        isSelectPlaceholder={false}
        isTextAreaPlaceholder={isTextArea}
      />
      <ErrorContainer>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorContainer>
    </Container>
  );
};
