import Button from 'components/Button/Button';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTab } from '../CustomTab/CustomTab';
import { DefaultTab } from '../DefaultTab/DefaultTab';
import {
  FilterTypeLabel,
  FilterTypesWrapper,
  Label,
  RowSpaceBetween,
} from '../EmailTemplatesPage.styled';
import {
  useCreateEmailTemplate,
  useGetVariablesOptions,
  useTestEmailTemplate,
} from '../hooks';
import { EmailTemplateDetails, EmailTemplatesTab } from '../types';

interface IEmailTemplatesFormProps {
  title: string;
  selectedFilterType: EmailTemplatesTab | undefined;
  setSelectedFilterType: (type: EmailTemplatesTab) => void;
  emailTemplateTypeId: number;
  emailTemplate: any;
  details: EmailTemplateDetails;
  setDetails: (details: EmailTemplateDetails) => void;
  defaultHeadline: string;
  defaultBody: string;
  emailTemplateVariables: any[];
}
export const EmailTemplatesForm = ({
  title,
  selectedFilterType,
  setSelectedFilterType,
  emailTemplateTypeId,
  emailTemplate,
  details,
  setDetails,
  defaultHeadline,
  defaultBody,
  emailTemplateVariables,
}: IEmailTemplatesFormProps) => {
  const { t } = useTranslation();
  const { mutate: createEmailTemplate } = useCreateEmailTemplate();
  const { mutate: testEmailTemplate } = useTestEmailTemplate();

  const variableOptions = useGetVariablesOptions(emailTemplateVariables || []);

  useEffect(() => {
    setSelectedFilterType(
      emailTemplate ? EmailTemplatesTab.CUSTOM : EmailTemplatesTab.DEFAULT
    );
    setDetails({
      headline: emailTemplate?.headline || defaultHeadline,
      body: emailTemplate?.body || defaultBody,
    });
  }, [emailTemplate]);

  return (
    <>
      <RowSpaceBetween>
        <Label>{title}</Label>
        {emailTemplate && (
          <Button
            label={t('Test email')}
            secondary
            onClick={() => testEmailTemplate(emailTemplateTypeId)}
          />
        )}
      </RowSpaceBetween>

      <FilterTypesWrapper>
        <FilterTypeLabel
          onClick={() => setSelectedFilterType(EmailTemplatesTab.DEFAULT)}
          isSelected={selectedFilterType === EmailTemplatesTab.DEFAULT}
        >
          {t(EmailTemplatesTab.DEFAULT)}
        </FilterTypeLabel>
        <FilterTypeLabel
          onClick={() => setSelectedFilterType(EmailTemplatesTab.CUSTOM)}
          isSelected={selectedFilterType === EmailTemplatesTab.CUSTOM}
        >
          {t(EmailTemplatesTab.CUSTOM)}
        </FilterTypeLabel>
      </FilterTypesWrapper>

      {selectedFilterType === EmailTemplatesTab.DEFAULT && (
        <DefaultTab
          details={{
            headline: defaultHeadline,
            body: defaultBody,
          }}
          templateTypeId={emailTemplate?.type_id}
        />
      )}
      {selectedFilterType === EmailTemplatesTab.CUSTOM && (
        <CustomTab
          details={details}
          onApply={(details: EmailTemplateDetails) => {
            createEmailTemplate({
              headline: details.headline,
              body: details.body,
              type_id: emailTemplateTypeId,
            });
          }}
          variableOptions={variableOptions || []}
        />
      )}
    </>
  );
};
