import { useQuery } from 'react-query';
import { getQuotationQuotations } from 'services/Quotation/QuotationService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetSingleQuotationQuotations = (
  quotationId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_QUOTATION_QUOTATIONS,
      quotationId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getQuotationQuotations(quotationId, page, perPage);
    },
  });
