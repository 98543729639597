import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import { IProductAttribute, ProductAttributeType } from 'types/Product.types';
import { ISupplier } from 'types/Supplier.types';

export interface ICreateQuotationSupplierOption {
  supplier: any;
  value?: number;
  label: string;
  key?: number;
}

export const formatSupplierData = (
  supplierData: ISupplier[]
): ICreateQuotationSupplierOption[] => {
  const supplierOptions: ICreateQuotationSupplierOption[] = [];
  if (supplierData?.length) {
    for (const supplier of supplierData) {
      supplierOptions.push({
        supplier: supplier as any,
        value: supplier.id,
        label: supplier.company_name,
        key: supplier.id,
      });
    }
  }
  return supplierOptions;
};

export const filterAttributesAndInjectValue = (
  attributes: IProductAttribute[],
  logyxConfigurationIntent: ILogyxConfigurationIntent | null
) => {
  const filteredAttributes: IProductAttribute[] = [];
  for (const attribute of attributes) {
    if (
      logyxConfigurationIntent &&
      !attribute.shouldDisplayAfterLogyxConfiguration
    ) {
      continue;
    }
    if (attribute.hasDefaultValue) {
      switch (attribute.type) {
        case ProductAttributeType.DROPDOWN:
          if (attribute.options && attribute.defaultOptionIndex !== null) {
            attribute.value = attribute.options[attribute.defaultOptionIndex];
          } else {
            attribute.value = '';
          }
          break;
        case ProductAttributeType.FIELD:
          attribute.value = attribute.defaultValue;
          break;
      }
    } else {
      attribute.value = '';
    }
    filteredAttributes.push(attribute);
  }
  return filteredAttributes;
};

const LOGIX_FORMS_PRICE = 'Sales_Price';
export const extractSalesPriceFromIntent = (
  logyxConfigurationIntent: ILogyxConfigurationIntent
) => {
  const variables = logyxConfigurationIntent.data?.variables;
  const pricing = logyxConfigurationIntent.data?.pricing;
  const shouldRoundPricing = logyxConfigurationIntent.data?.roundPricing;

  if (variables) {
    // Legacy intents that rely on variables
    return variables[LOGIX_FORMS_PRICE].value;
  } else if (pricing) {
    // New intents that use pricing
    let totalGrossPrice = 0;
    for (const pricingItem of Object.values(pricing)) {
      if (pricingItem.type.includes('gross')) {
        totalGrossPrice = totalGrossPrice + pricingItem.value;
      }
    }
    return totalGrossPrice.toFixed(shouldRoundPricing ? 0 : 2);
  } else {
    return '';
  }
};
