import * as Yup from 'yup';

export const createInvoiceDetailsSchema = () => {
  return Yup.object().shape({
    daysValid: Yup.number()
      .typeError('Days valid must be a number')
      .min(1)
      .max(365)
      .test('wholeNumber', 'Days valid must be a whole number', (value) => {
        return Number.isInteger(value);
      })
      .required('Days valid is required'),
    totalPrice: Yup.number()
      .typeError('Total price must be a number')
      .required('Total price is required'),
    externalId: Yup.string().max(
      80,
      'Reference must be less than 80 characters'
    ),
  });
};
