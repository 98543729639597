import styled from 'styled-components';
import { COLORS, P, marginMd } from 'assets/styled';

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 10rem;
  margin-top: 60rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  justify-content: space-between;
`;

export const Title = styled(P)`
  margin-bottom: 10rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;
