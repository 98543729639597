import { IConfiguredProductData, ILogyxAttribute } from 'store/Logyx/types';

export const formatLogyxAttributesObjectIntoArray = (
  configuredProductData: IConfiguredProductData | undefined
) => {
  if (!configuredProductData) {
    return [];
  }
  const logyxAttributes = [];
  for (const question of configuredProductData.questions) {
    if (!question.hidden) {
      const addedLogyxField: ILogyxAttribute = {
        value: configuredProductData.valuesFromOutputs[question.name],
        type: question.type,
        fieldName: question.name ? question.name : question.initialKey,
      };
      logyxAttributes.push(addedLogyxField);
    }
  }
  return logyxAttributes;
};
