import { ICreateQuotationProductFormDTO } from './constants';
import { Dispatch, SetStateAction, useState } from 'react';
import AddNewProduct from './AddNewProduct/AddNewProduct';
import ProductList from './ProductList/ProductList';
import { RelativeDiv } from './ProductTab.styled';
import { useNavigate } from 'react-router-dom';

interface IProductTabProps {
  onForward: (productFormDtosData: ICreateQuotationProductFormDTO[]) => void; // productFormDtosData is for order tab initial downpayment calculation
  onBack: () => void;
  productFormDtos: ICreateQuotationProductFormDTO[];
  setProductFormDtos: Dispatch<
    SetStateAction<ICreateQuotationProductFormDTO[]>
  >;
  canAddAnotherProduct?: boolean;
  canAddOnlyStockItems?: boolean;
  withoutPrices?: boolean;
}

const ProductTab = ({
  onForward,
  onBack,
  productFormDtos,
  setProductFormDtos,
  canAddAnotherProduct = true,
  canAddOnlyStockItems = false,
  withoutPrices = false,
}: IProductTabProps) => {
  const navigate = useNavigate();

  const [shouldScrollToLastProduct, setShouldScrollToLastProduct] =
    useState<boolean>(false);

  const [isAddingNewProduct, setIsAddingNewProduct] = useState<boolean>(
    !productFormDtos.length
  );

  const handleAddProductFormDto = (formDTO: ICreateQuotationProductFormDTO) => {
    setProductFormDtos([...productFormDtos, formDTO]);
    setIsAddingNewProduct(false);
    setShouldScrollToLastProduct(true);
  };

  return (
    <RelativeDiv>
      {isAddingNewProduct ? (
        <AddNewProduct
          addProductFormDto={handleAddProductFormDto}
          onBack={onBack}
          productsLength={productFormDtos?.length}
          canAddOnlyStockItems={canAddOnlyStockItems}
        />
      ) : (
        <ProductList
          onForward={onForward}
          onBack={() => {
            // canAddAnotherProduct is true when creating quotation, and is false when adding/editing salesOrder/appointment line
            if (!canAddAnotherProduct) {
              navigate(-1);
            } else {
              onBack();
            }
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
          addNewProduct={() => setIsAddingNewProduct(true)}
          canAddAnotherProduct={canAddAnotherProduct}
          withoutPrices={withoutPrices}
          shouldScrollToLastProduct={shouldScrollToLastProduct}
          setShouldScrollToLastProduct={setShouldScrollToLastProduct}
        />
      )}
    </RelativeDiv>
  );
};

export default ProductTab;
