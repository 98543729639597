import styled from 'styled-components';
import { COLORS, borderRadiusSm, marginMd } from 'assets/styled';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  width: 100%;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 24rem;
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const FirstRow = styled.div`
  margin-bottom: ${marginMd};
`;

export const SpinnerWrapper = styled.div`
  margin-left: ${marginMd};
`;
