import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
import {
  DocumentWrapper,
  FileDate,
  FileImage,
  FileImageWrapper,
  FileType,
  QuotationCardButton,
  QuotationCardContainer,
  QuotationCardTitle,
  QuotationCardWrapper,
  SalesPersonName,
} from './FileCard.styled';
import { IFile, IFileResponseDTO } from '../../../types/File.types';
import moment from 'moment';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { PencilSimple, Trash } from '@phosphor-icons/react';

interface IFileCard {
  name: string;
  fileType: string;
  date: string;
  icon: string;
  id?: string;
  onClick: () => void;
  onDelete?: () => void;
  onEdit?: (file: any) => void;
  file: IFile | IFileResponseDTO;
  editable?: boolean;
}

const FileCard: FC<IFileCard> = ({
  name,
  fileType,
  date,
  icon,
  id,
  onClick,
  onDelete,
  onEdit,
  file,
  editable,
}) => {
  const { t } = useTranslation();

  return (
    <QuotationCardContainer>
      <QuotationCardWrapper>
        {fileType === 'pdf' ? (
          <DocumentWrapper>
            <Document file={icon} onItemClick={onClick}>
              <Page
                pageNumber={1}
                height={200}
                width={200}
                onClick={onClick}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onLoadError={(error) => console.log(error)}
              />
            </Document>
          </DocumentWrapper>
        ) : (
          <FileImageWrapper>
            <FileImage src={icon} onClick={onClick} />
          </FileImageWrapper>
        )}
        <div>
          <QuotationCardTitle>
            {onDelete && (
              <Icon
                svg={Trash}
                onClick={() => onDelete()}
                marginTop="5rem"
                color={COLORS.PRIMARY}
                size={25}
                disabled={!editable}
              />
            )}
            {onEdit && (
              <Icon
                svg={PencilSimple}
                color={COLORS.PRIMARY}
                marginTop="5rem"
                disabled={!editable}
                onClick={() =>
                  onEdit!({
                    name: name,
                    entityId: file?.entity_id,
                    entityType: file?.file_type,
                    navigationID: id,
                  })
                }
              />
            )}
          </QuotationCardTitle>
          <FileType>{fileType}</FileType>
          <SalesPersonName>
            <div>{name}</div>
          </SalesPersonName>
          <FileDate>
            {date &&
              moment
                .utc(date)
                .utcOffset(moment().utcOffset())
                .format('DD-MM-YYYY HH:mm:ss')}
          </FileDate>
          <QuotationCardButton
            onClick={onClick}
            label={t('View')}
            width="200rem"
            primary
          />
        </div>
      </QuotationCardWrapper>
    </QuotationCardContainer>
  );
};

export default FileCard;
