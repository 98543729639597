import { COLORS, paddingMd } from 'assets/styled';
import styled from 'styled-components';

export const CommentCardContainer = styled.div`
  display: flex;
  width: 850rem;
  flex-direction: column;
  background: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.GREY_200};
  border-radius: 10rem;
  padding: ${paddingMd};
  padding-top: 0;
`;

export const CommentInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
  margin: 25rem 0 20rem 0;
`;

export const CommentType = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  line-height: 27rem;
`;

export const CommentHeadline = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  word-break: break-word;
  color: ${COLORS.BLACK};
`;

export const CommentDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.GREY_1000};
`;

export const CommentDescription = styled.div`
  font-size: 14rem;
  line-height: 21rem;
  word-break: break-word;
  color: ${COLORS.BLACK};
`;

export const ProfileImage = styled.img`
  width: 35rem;
  height: 35rem;
  border-radius: 100%;
`;

export const AuthorName = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.BLACK};
`;
