// Keys existing in tableData that will not be displayed as a column
export const excludedTableDataKeys: string[] = [
  'consumer_name',
  'enabled',
  'id',
  'mollie_customer_id',
  'signature_date',
];

export const translatableColumnFields: string[] = ['type', 'status'];
