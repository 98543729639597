import styled from 'styled-components';
import CustomSelect from 'components/Select/FormikSelect';
import { marginMd, marginXs } from 'assets/styled';

export const FormContainer = styled.div``;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-bottom: ${marginXs};
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  gap: 20rem;
`;
