import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useAdminPermissionChange,
  useGetPermissionsGroups,
  usePermissionChange,
  useUpdateUserPermissions,
} from './hooks';
import { useGetUsers } from '../PlanningPage/UsersModal/hooks';
import {
  ButtonsWrapper,
  Container,
  Search,
  Title,
  TopWrapper,
} from './UserRolesPage.styled';
import { USERS_PER_PAGE, tableConfig } from './constats';
import { formatUserRolesTableData } from 'components/Table/tableDataFormatter';
import { IFullUser, IUserRolesTableDTO } from 'types/User.types';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Table from 'components/Table/Table';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { SortDirection } from 'components/Table/constants';
import { MagnifyingGlass } from '@phosphor-icons/react';

export interface IUserPermission {
  user_id: number;
  permissions: IPermission[];
}

interface IPermission {
  id: number;
  checked: boolean;
}

const UserRolesPage = () => {
  const { t } = useTranslation();
  const { data: permissionsGroups } = useGetPermissionsGroups();
  const [tableData, setTableData] = useState<IUserRolesTableDTO[]>([]);
  const [transformedData, setTransformedData] = useState<IUserPermission[]>([]);
  const [preventModal, setPreventModal] = useState<boolean>(false);
  const [pendingPage, setPendingPage] = useState(null);
  const [resetPerms, setResetPerms] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const [sortBy, setSortBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    undefined
  );

  const debouncedSearchBy = useDebounce(searchBy);

  const { data: usersData, isLoading: isLoadingData } = useGetUsers(
    page,
    USERS_PER_PAGE,
    debouncedSearchBy,
    undefined, // dateFrom
    undefined, // dateTo,
    true,
    sortDirection,
    true // activeOnly
  );

  const { mutate: updateUserPermissions } = useUpdateUserPermissions();

  useEffect(() => {
    if (usersData) {
      setTableData(
        formatUserRolesTableData(
          usersData.users.map((user: IFullUser[]) => user).flat()
        )
      );
    }
  }, [usersData, resetPerms]);

  const handlePermissionChange = (
    userId: number,
    permissionId: number,
    checked: boolean
  ) => {
    //Admin id's for write & read
    if (permissionId === 13 || permissionId === 14) {
      updateAdminPermissions(userId, checked);
    } else {
      updatePermissions(userId, permissionId, checked);
    }
  };

  const updatePermissions = usePermissionChange(setTransformedData);
  const updateAdminPermissions = useAdminPermissionChange(
    setTransformedData,
    setTableData,
    permissionsGroups?.map((pg: any) => ({ id: pg.id, checked: false })) || []
  );

  const handleSubmit = () => {
    updateUserPermissions(transformedData);
    setTransformedData([]);
  };

  const loadPage = (newPage: any) => {
    setPendingPage(newPage);
    setPreventModal(true);
  };

  const confirmPageChange = () => {
    if (pendingPage !== null) {
      setPage(pendingPage);
      setPendingPage(null);
    }
    setTransformedData([]);
    setResetPerms(true);
    setPreventModal(false);
  };

  const resetState = () => {
    setResetPerms(true);
    setTransformedData([]);
  };

  return (
    <Container className="userRoles__page">
      <TopWrapper>
        <Title>{t('Overview')}</Title>
        <ButtonsWrapper>
          <Button
            secondary
            disabled={!transformedData.length}
            label={t('Cancel changes')}
            width="200rem"
            onClick={() => resetState()}
          />
          <Button
            onClick={() => handleSubmit()}
            label={t('Apply changes')}
            primary
            width="200rem"
            disabled={!transformedData.length}
          />
        </ButtonsWrapper>
      </TopWrapper>
      <Search>
        <Input
          pwId="search-field"
          icon={MagnifyingGlass}
          placeholder={t('Search')}
          width={'400rem'}
          height="41rem"
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        />
      </Search>
      <Table
        page={page}
        isLoading={isLoadingData}
        perPage={USERS_PER_PAGE}
        total={usersData?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={false}
        loadPage={
          !transformedData.length
            ? (newPage) => {
                setPage(newPage);
              }
            : loadPage
        }
        resetPerms={resetPerms}
        setResetPerms={setResetPerms}
        handlePermissionChange={handlePermissionChange}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />

      <YesOrNoModal
        pwId="yes-or-no-permissions-modal"
        isOpen={preventModal}
        setIsOpen={setPreventModal}
        onYes={confirmPageChange}
        onNo={() => setPreventModal(false)}
        title={'Save your work'}
        description={`${t('Leave without apply changes')}?`}
      />
    </Container>
  );
};

export default UserRolesPage;
