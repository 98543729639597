import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm, paddingMd } from 'assets/styled';
import { hoverLineIn } from 'assets/styled/commonStyles';

interface StyledProps {
  width: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const CardContentWrapper = styled.span`
  padding: 0 ${paddingMd} ${paddingMd} ${paddingMd};
  font-size: 16rem;
  display: grid;
  grid-template-columns: minmax(130rem, auto) 1fr;
  grid-gap: 10rem;
  color: ${COLORS.BLACK};
`;

export const ValueWrapperRelative = styled.div`
  display: flex;
  position: relative;
  margin-right: ${marginSm};
`;

export const EditLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineIn}
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 15rem;
  height: 15rem;
  right: -20rem;
  top: 1rem;
`;

export const Heading = styled.div`
  padding: 30rem 0 20rem 30rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackgroundColorWrapper = styled.div`
  padding-right: 30rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
`;

export const Label = styled.label`
  margin-left: 30rem;
  margin-right: 30rem;
  font-size: 14rem;
  line-height: 21rem;
  font-weight: 600;
  white-space: nowrap;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${marginSm};
`;
