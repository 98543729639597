import Input from 'components/Input/Input';
import { Container, Description, Headline } from './SubmitFeedback.styled';
import { marginMd } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import { useSubmitFeedback } from '../hooks';

const SubmitFeedback = () => {
  const { t } = useTranslation();
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { mutate: submitFeedback, isLoading, isSuccess } = useSubmitFeedback();

  useEffect(() => {
    if (isSuccess) {
      setText('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (text.length > 10000) {
      setError('Feedback can not exceed 10000 characters');
    } else {
      setError('');
    }
  }, [text]);

  return (
    <Container>
      <Headline>{t('Report feedback')}:</Headline>
      <Description>
        {t(
          'How do you like using Blueprint so far? We would love to hear your feedback on any topic.'
        )}
      </Description>
      <Input
        error={!!error}
        errorMessage={error}
        pwId="feedback-field"
        placeholder={t('Write your feedback here')}
        width={'713rem'}
        height="150rem"
        isTextArea
        wrapperStyles={{ marginBottom: marginMd }}
        value={text}
        onChange={(e: any) => setText(e.target.value)}
      />
      <Button
        width={'200rem'}
        onClick={() => submitFeedback(text)}
        label={t('Send report')}
        primary
        disabled={!text || isLoading || !!error}
      />
    </Container>
  );
};

export default SubmitFeedback;
