import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { Input } from 'components/Input/InputFormik';
import {
  FormikContainer,
  CloseContainer,
  Title,
  AlignRow,
  SubmitRow,
  Margin,
  TitleContainer,
  Row,
} from './EditStockItemPriceModal.styled';
import Button from 'components/Button/Button';
import { useParams } from 'react-router-dom';
import { PriceType } from '../constants';
import {
  validateNumericField,
  validateNumericFieldWithZero,
} from 'utils/validation';
import { useEditStockPrice } from './hooks';

interface IEditPriceModalModal {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  price: string;
  type: PriceType;
}
export const EditStockItemPriceModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  type,
  price,
}: IEditPriceModalModal) => {
  const { id } = useParams();
  const isSalesPrice = type === PriceType.SALES_PRICE;

  const { mutate } = useEditStockPrice(id!);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>
          {isSalesPrice ? t('Edit sales price') : t('Edit cost price')}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            price: price,
          }}
          onSubmit={() => {
            //
          }}
        >
          {({
            handleBlur,
            setFieldValue,
            submitForm,
            isValid,
            values,
            errors,
            touched,
          }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    pwId="price-field"
                    type="number"
                    validate={(value: string) => {
                      isSalesPrice
                        ? validateNumericField(value, 'price')
                        : validateNumericFieldWithZero(value, 'price');
                    }}
                    errorMessage={errors['price'] as string}
                    height={'41rem'}
                    name="price"
                    placeholder={
                      isSalesPrice ? t('Sales price') : t('Cost price')
                    }
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('price', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '300rem',
                    }}
                  />
                </Row>
                <AlignRow>
                  <SubmitRow>
                    <Margin>
                      <Button
                        width={'200rem'}
                        onClick={onCancel}
                        label={t('Cancel')}
                        secondary
                      />
                    </Margin>
                    <Button
                      disabled={!isValid || !values.price}
                      width={'200rem'}
                      onClick={() => {
                        mutate({
                          ...(isSalesPrice
                            ? { price: values.price }
                            : { purchase_price: values.price }),
                        });
                        setIsOpen(false);
                      }}
                      label={t('Edit')}
                      primary
                    />
                  </SubmitRow>
                </AlignRow>
              </FormikContainer>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
