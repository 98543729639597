import { useSelector, useDispatch } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { setDarkMode } from 'store/Common/actions/common';
import { Container } from './ToggleDarkMode.styled';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import { Sun, Moon } from '@phosphor-icons/react';

const ToggleDarkMode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { darkMode, isMobileApp } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const handleDarkModeChange = () => {
    dispatch(setDarkMode(!darkMode));
    if (isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.TOGGLE_DARK_MODE,
        payload: { darkMode: !darkMode },
      };
      postMobileMessage(mobileMessage);
    }
  };

  return (
    <Container onClick={handleDarkModeChange}>
      <Tooltip
        content={darkMode ? t('Turn on the light') : t('Turn off the light')}
      >
        <Icon
          svg={darkMode ? Sun : Moon}
          color={COLORS.PRIMARY}
          size={25}
          onClick={handleDarkModeChange}
        />
      </Tooltip>
    </Container>
  );
};

export default ToggleDarkMode;
