import { DiscountType } from 'types/Discount.types';
import {
  calculateTotalPurchasePriceForSingleProductFormDTO,
  calculateTotalSalesPriceForSingleProductFormDTO,
} from '../ProductTab/ProductList/helpers';
import { ICreateQuotationProductFormDTO } from '../ProductTab/constants';
import { ICreateQuotationOrderDetails } from './constants';
import { calculateInitialDownpayment } from '../helpers';
import { ICompanySettings } from 'types/Company.types';

export const calculateTotalWorkingHoursPrice = (
  orderDetails: ICreateQuotationOrderDetails
) => {
  const totalWorkingHoursPriceWithoutDiscount =
    Number(orderDetails.workingHoursRate) * Number(orderDetails.workingHours);
  let totalWorkingHoursPriceWithDiscount;
  if (orderDetails.workingHoursDiscountType === DiscountType.PERCENTAGE) {
    totalWorkingHoursPriceWithDiscount =
      totalWorkingHoursPriceWithoutDiscount *
      ((100 - Number(orderDetails.workingHoursDiscount)) / 100);
  } else {
    totalWorkingHoursPriceWithDiscount =
      totalWorkingHoursPriceWithoutDiscount -
      Number(orderDetails.workingHoursDiscount);
  }
  const totalWorkingHoursPrice = totalWorkingHoursPriceWithDiscount
    .toFixed(2)
    .toString();

  return isNaN(totalWorkingHoursPrice as any) ? '0.00' : totalWorkingHoursPrice;
};

export const calculateSalesPriceExcludingDiscount = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  let salesPriceExcludingDiscount = 0;
  for (const productFormDto of productFormDtos) {
    salesPriceExcludingDiscount += Number(
      calculateTotalSalesPriceForSingleProductFormDTO(productFormDto)
    );
  }
  return (
    Number(calculateTotalWorkingHoursPrice(orderDetails)) +
    Number(salesPriceExcludingDiscount)
  )
    .toFixed(2)
    .toString();
};

export const calculateSalesPriceWithDiscount = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  const salesPriceExcludingDiscount = calculateSalesPriceExcludingDiscount(
    orderDetails,
    productFormDtos
  );
  let totalSalesPrice = 0;
  if (orderDetails.discountType === DiscountType.PERCENTAGE) {
    totalSalesPrice =
      Number(salesPriceExcludingDiscount) *
      ((100 - Number(orderDetails.discount)) / 100);
  } else {
    totalSalesPrice =
      Number(salesPriceExcludingDiscount) - Number(orderDetails.discount);
  }
  return totalSalesPrice.toFixed(2).toString();
};

export const calculateTotalPurchasePrice = (
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  let totalPurchasePrice = 0;
  for (const productFormDto of productFormDtos) {
    totalPurchasePrice += Number(
      calculateTotalPurchasePriceForSingleProductFormDTO(productFormDto)
    );
  }
  return totalPurchasePrice.toFixed(2).toString();
};

export const validateDownpayment = (
  downpayment: any,
  salesPriceWithDiscount: number,
  setFieldError: any
) => {
  if (downpayment !== '') {
    if (downpayment < 0) {
      setFieldError('downPayment', 'Downpayment must be larger than 0');
      return false;
    }
    if (downpayment > salesPriceWithDiscount) {
      setFieldError(
        'downPayment',
        'Downpayment cant be larger than sales price'
      );
      return false;
    }
  }
  return true;
};

export const calculateAndSetDownpayment = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[],
  companySettings: ICompanySettings,
  setFieldValue: any
) => {
  if (
    !companySettings?.downpayment_threshold_amount &&
    !companySettings?.downpayment_default_percentage
  ) {
    return;
  }
  const salesPriceWithDiscount = calculateSalesPriceWithDiscount(
    orderDetails,
    productFormDtos
  );
  const downPayment = calculateInitialDownpayment(
    salesPriceWithDiscount,
    companySettings
  ).toFixed(2);
  if (salesPriceWithDiscount <= downPayment) {
    setFieldValue('downPayment', 0);
  } else {
    setFieldValue('downPayment', downPayment);
  }
};
