export interface ISocket {
  connected: boolean;
  connect: () => void;
  disconnect: () => void;
  auth: any;
  on: any;
  off: any;
  io: {
    opts: any;
  };
  emit: (message: string, payload: object | string) => void;
}
// Custom headers can be set in io.opts.extraHeaders (used when "transports: ['websocket']" is off)

export enum SocketMessage {
  MESSAGE_ACKNOWLEDGED = 'message-acknowledged',
}
