import {
  ITableAltConfig,
  ITableAltRowConfig,
} from 'components/TableAlt/TableAlt';

const rowConfigs: ITableAltRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Sales Order', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Delivery date', flexValue: 1, isBlue: false },
];
export const tableConfig: ITableAltConfig = {
  rowConfigs: rowConfigs,
};
export const PURCHASE_ORDERS_PER_PAGE = 8;
export const SUPPLIERS_PER_PAGE = 10;

export const BULK_BOOK_PURCHASE_ORDER_STATUSES =
  'NEW,SENT,CONFIRMED,PARTIALLY_RECEIVED';
