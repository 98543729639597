import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isBlueColor: boolean;
  flexValue: number;
  $withoutPagination: boolean;
  hoverEffectEnabled: boolean;
}

export const TableResponsive = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
`;

export const CustomTable = styled.table<StyledProps>`
  margin-top: ${marginMd};
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;

  ${(props: StyledProps) =>
    props.$withoutPagination &&
    css`
      margin-top: 0;
      thead tr th {
        position: sticky;
        top: 0;
      }
    `}

  th {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
    font-weight: 300;
    display: flex;
    align-items: center;

    label {
      color: ${COLORS.WHITE};
    }
  }
  th:first-child {
    border-top-left-radius: ${borderRadiusSm};
    flex: 0;
  }
  th:last-child {
    border-top-right-radius: ${borderRadiusSm};
    flex: 0;
  }

  th,
  td {
    min-width: 0;
    padding-top: 20rem;
    padding-bottom: 20rem;
    padding-left: 10rem;
    padding-right: 10rem;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
  }
  td {
    background-color: ${COLORS.WHITE};
    border-bottom: 1px solid ${COLORS.GREY_300};
  }

  ${respondTo.bigTablet`
    th,
    td {
      min-width: 150rem;
    }
    td:first-child,
    th:first-child {
      min-width: 0;
    }
    td:last-child,
    th:last-child {
      min-width: 0;
    }
  `}

  td:first-child {
    flex: 0;
    border: none;
    width: 30rem;
  }
  td:last-child {
    flex: 0;
    border: none;
    width: 30rem;
  }

  tr:not(:last-child) {
    td {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  tr:last-child {
    border-color: transparent;
    td {
      flex: 1;
      padding: 10rem;
    }
    td:first-child {
      flex: 0;
    }
    td:last-child {
      flex: 0;
    }
  }
`;

export const Th = styled.th<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `}
`;

export const ThWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ThLabel = styled.label`
  font-weight: 500;
  color: ${COLORS.WHITE};
`;

export const TD = styled.td<StyledProps>`
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};
  min-height: 66rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TdLabel = styled.label<StyledProps>`
  font-weight: 500;
  color: ${COLORS.BLACK};
  display: block;

  ${(props: StyledProps) =>
    props.isBlueColor &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;

export const TrBody = styled.tr<StyledProps>`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  ${(props: StyledProps) =>
    props.hoverEffectEnabled &&
    css`
      &:hover td {
        background-color: ${COLORS.GREY_200};
      }
    `};
`;
