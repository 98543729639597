import {
  Container,
  FlexAlignCenter,
  Row,
  TitleContainer,
  RowRightPart,
  HeaderButton,
  AdvancedFilterWrapper,
} from './PurchaseOrdersPage.styled';
import { COLORS, H3 } from 'assets/styled';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table/Table';
import { formatPurchaseOrdersTableData } from 'components/Table/tableDataFormatter';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import { PURCHASE_ORDERS_PER_PAGE, tableConfig } from './constants';
import {
  useExportPurchaseOrderExcel,
  useGetPurchaseOrders,
  useGetPurchaseOrdersPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import {
  IPurchaseOrder,
  IPurchaseOrderTableDTO,
} from 'types/PurchaseOrders.types';
import { useDebounce } from 'utils/hooks/useDebounce';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { BulkReceiveModal } from './BulkReceiveModal/BulkReceiveModal';
import { useState } from 'react';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { MicrosoftExcelLogo, MagnifyingGlass } from '@phosphor-icons/react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import Button from 'components/Button/Button';
import { useGetPurchaseOrderAdvancedFilters } from './advancedFilters';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

const PurchaseOrdersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState<string>('');

  const storePurchaseOrderPaymentStatuses = useSelector(
    (state: IRootReducerState) =>
      state.commonInfo.purchaseOrderStatuses.purchase_order_payment_statuses
  );

  const [isBulkReceiveModalOpen, setIsBulkReceiveModalOpen] =
    useState<boolean>(false);
  const { purchaseOrdersPageFilters, statusOptions, dateIntervalOptions } =
    useGetPurchaseOrdersPageFilters();

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useManageAndSaveFilters(purchaseOrdersPageFilters);

  const debouncedSearchBy = useDebounce(searchBy);

  const { mutate: exportPurchaseOrderExcel } = useExportPurchaseOrderExcel();

  const { isLoading, data } = useGetPurchaseOrders(
    page,
    PURCHASE_ORDERS_PER_PAGE,
    true,
    debouncedSearchBy,
    selectedStatusOptions?.map((option: Option) => option.value).join(','),
    undefined,
    selectedDateIntervalOption.value,
    sortBy,
    sortDirection,
    advancedFilters
  );

  let tableData: IPurchaseOrderTableDTO[] = [];
  if (data) {
    tableData = formatPurchaseOrdersTableData(
      data.purchase_orders
        .map((purchaseOrder: IPurchaseOrder[]) => {
          return purchaseOrder;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: any) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetPurchaseOrderAdvancedFilters(
    statusOptions,
    storePurchaseOrderPaymentStatuses
  );

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <RowRightPart>
          <HeaderButton
            width={'200rem'}
            onClick={() => setIsBulkReceiveModalOpen(true)}
            label={t('Bulk receive')}
            secondary
          />
          <HeaderButton
            width={'200rem'}
            icon={MicrosoftExcelLogo}
            onClick={exportPurchaseOrderExcel}
            label={t('Export excel')}
            secondary
            colorIcon={COLORS.BLACK}
            sizeIcon={20}
          />
        </RowRightPart>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <RowRightPart>
          <CheckableSelect
            placeholder={t('Status')}
            isMulti
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedStatusOptions(e)}
            defaultValues={selectedStatusOptions}
          />
          <CheckableSelect
            placeholder={t('Date')}
            options={dateIntervalOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedDateIntervalOption(options[0])
            }
            defaultValues={
              selectedDateIntervalOption
                ? [selectedDateIntervalOption]
                : [dateIntervalOptions[0]]
            }
          />
        </RowRightPart>
      </Row>
      <AdvancedFilterWrapper>
        <Button
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </AdvancedFilterWrapper>
      <Table
        isLoading={isLoading}
        page={page}
        perPage={PURCHASE_ORDERS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />
      {isBulkReceiveModalOpen && (
        <BulkReceiveModal
          isOpen={isBulkReceiveModalOpen}
          setIsOpen={setIsBulkReceiveModalOpen}
          onCancel={() => setIsBulkReceiveModalOpen(false)}
        />
      )}

      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />
    </Container>
  );
};

export default PurchaseOrdersPage;
