import { ISocket, SocketMessage } from 'providers/SocketIOProvider/types';
import {
  setLogyxConfigurationIntent,
  setIsAwaitingConfiguration,
  setIsAwaitingEdit,
  setIsAwaitingPurchaseOrderLineEdit,
} from 'store/Logyx/actions/logyx';
import { ILogyxConfigurationIntent } from 'store/Logyx/types';
import { IRootReducerState, store } from 'store/store';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';

export const onLogyxConfigurationFinishedEvent = (
  event: {
    intent: ILogyxConfigurationIntent;
    message_uuid: string;
  },
  socket: ISocket
) => {
  const state: IRootReducerState = store.getState();
  if (
    state.logyxInfo.isAwaitingConfiguration &&
    state.logyxInfo.pendingIntentUUID === event.intent.uuid
  ) {
    window.focus();
    store.dispatch(setIsAwaitingConfiguration(false));
    store.dispatch(setLogyxConfigurationIntent(event.intent));
    if (state.commonInfo.isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.CLOSE_LOGYX,
      };
      postMobileMessage(mobileMessage);
    }
  } else {
    if (state.logyxInfo.isAwaitingConfiguration) {
      console.log(
        `Expected intent with uuid ${state.logyxInfo.pendingIntentUUID}, instead got ${event.intent.uuid}`
      );
    }
  }
  socket.emit(SocketMessage.MESSAGE_ACKNOWLEDGED, event.message_uuid);
};

export const onLogyxEditConfigurationFinishedEvent = (
  event: {
    intent: ILogyxConfigurationIntent;
    message_uuid: string;
  },
  socket: ISocket
) => {
  const state: IRootReducerState = store.getState();
  if (
    state.logyxInfo.isAwaitingEdit &&
    state.logyxInfo.pendingIntentUUID === event.intent.uuid
  ) {
    window.focus();
    store.dispatch(setIsAwaitingEdit(false));
    store.dispatch(setLogyxConfigurationIntent(event.intent));
    if (state.commonInfo.isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.CLOSE_LOGYX,
      };
      postMobileMessage(mobileMessage);
    }
  } else {
    if (state.logyxInfo.isAwaitingEdit) {
      console.log(
        `Expected intent with uuid ${state.logyxInfo.pendingIntentUUID}, instead got ${event.intent.uuid}`
      );
    }
  }
  socket.emit(SocketMessage.MESSAGE_ACKNOWLEDGED, event.message_uuid);
};

export const onLogyxPurchaseOrderLineReconfigurationFinishedEvent = (
  event: {
    message_uuid: string;
  },
  socket: ISocket
) => {
  const state: IRootReducerState = store.getState();
  if (state.logyxInfo.isAwaitingPurchaseOrderLineEdit) {
    window.focus();
    store.dispatch(setIsAwaitingPurchaseOrderLineEdit(false));
    if (state.commonInfo.isMobileApp) {
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.CLOSE_LOGYX,
      };
      postMobileMessage(mobileMessage);
    }
  }
  socket.emit(SocketMessage.MESSAGE_ACKNOWLEDGED, event.message_uuid);
};
