import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';
import { Option } from 'components/Select/type';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Name',
    value: 'customer',
    type: 'dropdown',
  },
  {
    label: 'Address',
    value: 'address',
    type: 'string',
  },
  {
    label: 'Zip code',
    value: 'zip_code',
    type: 'string',
  },
  {
    label: 'Company',
    value: 'company',
    type: 'dropdown',
  },
  {
    label: 'Total orders',
    value: 'total_orders',
    type: 'string',
  },
  {
    label: 'Accounting',
    value: 'accounting',
    type: 'dropdown',
  },
];

const trueFalseOptions: Option[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const useGetCustomersAdvancedFilters = () => {
  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'accounting') {
      return { ...filter, options: trueFalseOptions };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};
