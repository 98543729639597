import { COLORS, borderRadiusSm, marginMd, paddingMd } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  withoutBorderBottom: boolean;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 30rem;
  top: 20rem;
  width: calc(100% - 60rem);
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: ${COLORS.BLACK};
`;

export const BlueHeader = styled.div`
  margin-top: ${marginMd};
  background-color: ${COLORS.PRIMARY};
  border-top-left-radius: ${borderRadiusSm};
  border-top-right-radius: ${borderRadiusSm};
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60rem;
  border-bottom: 1rem solid ${COLORS.WHITE};
  margin-left: ${paddingMd};
  margin-right: ${paddingMd};
`;

export const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 60rem;
  margin-left: ${paddingMd};
  margin-right: ${paddingMd};
`;

export const HeaderLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const HeaderLabel = styled.label`
  display: flex;
  font-size: 14rem;
  font-weight: 300;
  color: ${COLORS.WHITE};
`;

export const TimelineRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: ${COLORS.WHITE};
  padding-left: ${paddingMd};
  padding-right: ${paddingMd};
  height: 60rem;
  ${(props: StyledProps) =>
    !props.withoutBorderBottom &&
    css`
      border-bottom: 1rem solid ${COLORS.GREY_200};
    `}
`;

export const TimelineRowLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const TimelineRowLabel = styled.label`
  font-size: 14rem;
  font-weight: 300;
`;

export const NoContentLabel = styled.label`
  margin-bottom: ${marginMd};
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  width: 100%;
  margin-top: 20rem;
`;
