import { Option } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IQuotationStatus } from 'types/Quotations.types';

function reorderStatuses(
  quotationStatuses: Option[],
  orderedStatusIds: number[]
) {
  const orderedStatuses: Option[] = [];
  const statusMap = new Map(
    quotationStatuses.map((status: Option) => [status.id.toString(), status])
  );

  // Add statuses in the order specified by orderOfStatuses
  orderedStatusIds.forEach((id: number) => {
    if (statusMap.has(id.toString())) {
      const foundStatus = statusMap.get(id.toString());
      if (foundStatus) {
        orderedStatuses.push(foundStatus);
      }
      statusMap.delete(id.toString()); // Remove added status
    }
  });

  // Add any remaining statuses that were not in orderOfStatuses
  statusMap.forEach((status) => orderedStatuses.push(status));

  return orderedStatuses;
}

export const useGetQuotationStatuses = () => {
  const storeQuotationStatuses = useSelector(
    (state: IRootReducerState) => state.commonInfo.quotationStatuses
  );

  const memoizedQuotationStatuses: Option[] = useMemo(() => {
    let quotationStatuses = storeQuotationStatuses?.map(
      (status: IQuotationStatus) => {
        return {
          name: status.name,
          value: status.name,
          label: status.name,
          id: status.id,
          key: status.id,
        };
      }
    );

    const orderedStatusIds = [1, 2, 7, 8, 9, 5, 3, 4, 6]; // Sorted status ids

    // Reorder statuses
    quotationStatuses = reorderStatuses(quotationStatuses, orderedStatusIds);

    return quotationStatuses;
  }, [storeQuotationStatuses, i18n.resolvedLanguage]);

  return memoizedQuotationStatuses;
};
