import { NoContentLabel, SectionTitle } from '../ReportForm.styled';
import { gapLg, marginSm } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import {
  InvoiceLabel,
  InvoicesWrapper,
  SingleInvoiceWrapper,
} from './InvoicesSection.styled';
import { ISalesInvoice } from 'types/Invoice.types';
import Button from 'components/Button/Button';
import { useState } from 'react';
import { PreviewSalesInvoiceModal } from './PreviewSalesInvoiceModal/PreviewSalesInvoiceModal';
import Spinner from 'components/Spinner/Spinner';

interface IInvoicesSectionProps {
  isLoadingInvoices: boolean;
  invoices: ISalesInvoice[];
}

const InvoicesSection = ({
  isLoadingInvoices,
  invoices,
}: IInvoicesSectionProps) => {
  const { t } = useTranslation();
  const [invoiceIdForPreview, setInvoiceIdForPreview] = useState<number | null>(
    null
  );
  const [isPreviewSalesInvoiceModalOpen, setIsPreviewSalesInvoiceModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <SectionTitle marginBottom={marginSm}>{t('INVOICES')}</SectionTitle>
      {isLoadingInvoices ? (
        <Spinner />
      ) : (
        <InvoicesWrapper gap={gapLg}>
          {invoices?.length ? (
            invoices?.map((salesInvoice: ISalesInvoice, index: number) => (
              <SingleInvoiceWrapper key={index}>
                <InvoiceLabel marginBottom={marginSm}>
                  {salesInvoice.invoice_number}
                </InvoiceLabel>
                <Button
                  label={t('Open')}
                  secondary
                  width="200rem"
                  onClick={() => {
                    setIsPreviewSalesInvoiceModalOpen(true);
                    setInvoiceIdForPreview(salesInvoice.id);
                  }}
                />
              </SingleInvoiceWrapper>
            ))
          ) : (
            <NoContentLabel>
              {t('There are no invoices connected to this appointment')}
            </NoContentLabel>
          )}
        </InvoicesWrapper>
      )}
      <PreviewSalesInvoiceModal
        isOpen={isPreviewSalesInvoiceModalOpen}
        setIsOpen={setIsPreviewSalesInvoiceModalOpen}
        invoiceIdForPreview={invoiceIdForPreview}
      />
    </>
  );
};

export default InvoicesSection;
