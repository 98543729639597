import { useParams } from 'react-router-dom';
import {
  Container,
  NoContentLabel,
  MainContainer,
  Card,
  TextContainer,
  Label,
  LabelTitle,
  ColumnContainer,
  TitleContainer,
  DeleteIcon,
  EditDeleteWrapperRelative,
  DeleteLabel,
} from './SingleSalesInvoicePaymentsPage.styled';
import { useGetSingleSalesInvoice } from '../hooks';
import { useDeletePayment, useGetSingleSalesInvoicePayments } from './hooks';
import { useTranslation } from 'react-i18next';
import { COLORS, H3, H4 } from 'assets/styled';
import moment from 'moment';
import { IPayment } from 'types/Payment.types';
import { useCallback, useRef, useState } from 'react';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { formatNumber } from 'utils/numberFormatter';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Trash } from '@phosphor-icons/react';

const SingleSalesInvoicePaymentsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [paymentForDeleting, setPaymentForDeleting] = useState<IPayment>();

  const { mutate: deletePayment } = useDeletePayment();

  const canEditSalesInvoice = useCan(Actions.EDIT_SALES_INVOICE);
  const currencySymbol = useGetCurrencySymbol();

  const { data } = useGetSingleSalesInvoice(typeof id === 'string' ? id : '');

  const {
    data: invoicePaymentsData,
    isFetching: isFetchingPayments,
    hasNextPage,
    fetchNextPage,
    isFetched,
  } = useGetSingleSalesInvoicePayments(typeof id === 'string' ? id : '', 5);

  const dataRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight - 1
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_INVOICE_PAYMENTS,
    id,
    isFetched
  );

  const invoicePayments = invoicePaymentsData?.pages
    .map((page: any) => page.payments)
    .flat();
  if (!invoicePayments?.length)
    return isFetchingPayments ? (
      <></>
    ) : (
      <>
        <NoContentLabel className="single-sales-invoice-step-8">
          {t('There are currently no payments on this Sales invoice')}
        </NoContentLabel>
        <IntroductionGuide steps={steps} />
      </>
    );

  return (
    <Container
      onScroll={onScroll}
      ref={dataRef}
      className="single-sales-invoice-step-8"
    >
      {invoicePayments.map((invoicePayment: IPayment, index) => {
        const isMolliePayment = invoicePayment.payment_type.name === 'MOLLIE';
        return (
          <Card key={index}>
            <TitleContainer>
              <H3>
                {t('Payment')} {invoicePayment.number}
              </H3>
              {canEditSalesInvoice && !isMolliePayment && (
                <EditDeleteWrapperRelative
                  data-testid={`delete-payment-${invoicePayment.number}`}
                  onClick={() => {
                    setPaymentForDeleting(invoicePayment);
                    setOpenDeleteModal(true);
                  }}
                >
                  <DeleteLabel>{t('Delete')}</DeleteLabel>
                  <DeleteIcon>
                    <Icon svg={Trash} size={18} color={COLORS.RED_200} />
                  </DeleteIcon>
                </EditDeleteWrapperRelative>
              )}
            </TitleContainer>
            <MainContainer>
              <ColumnContainer>
                <H4>{t('DETAILS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Date')}</LabelTitle>
                  {invoicePayment.date && (
                    <Label>{`${moment(invoicePayment.date)
                      .tz(moment.tz.guess())
                      .format('DD-MM-YYYY')}`}</Label>
                  )}
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount')}</LabelTitle>
                  <Label>
                    {currencySymbol}
                    {formatNumber(invoicePayment.amount)}
                  </Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Type of payment')}</LabelTitle>
                  <Label>
                    {t(invoicePayment.payment_type.name).toUpperCase()}
                  </Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Reference')}</LabelTitle>
                  <Label>{t(invoicePayment?.reference).toUpperCase()}</Label>
                </TextContainer>
              </ColumnContainer>
              {/* <ColumnContainer>
                <H4>{t('STATUS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Total amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount paid')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Open amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
              </ColumnContainer> */}
            </MainContainer>
          </Card>
        );
      })}
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.sales_invoice?.id}
        invoiceType={InvoiceType.SALES_INVOICE}
        initialPaymentAmount={data?.sales_invoice?.open_amount}
      />
      <ConfirmModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        onConfirm={() => {
          deletePayment(paymentForDeleting!.id.toString());
          setOpenDeleteModal(false);
        }}
        onCancel={() => setOpenDeleteModal(false)}
        title={t('Delete Payment')}
        description={t('Are you sure you want to delete this payment') + '?'}
      />
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesInvoicePaymentsPage;
