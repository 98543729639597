import { RoutesConfig } from 'navigation/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { IRootReducerState } from 'store/store';

const PublicRoute = () => {
  const isAuthenticated = useSelector(
    (state: IRootReducerState) => state.userInfo.id
  );
  return isAuthenticated ? (
    <Navigate to={RoutesConfig.Home.path} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
