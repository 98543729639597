import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { fetchCommonData } from 'services/Common/CommonService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IFetchCommonDataResponse } from 'types/Common.types';

export const useGetCommonData = () =>
  useMutation(() => fetchCommonData(), {
    onSuccess: (data: IFetchCommonDataResponse) => {
      //
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.FETCH_COMMON_DATA,
      });
    },
    mutationKey: ReactMutationKeys.FETCH_COMMON_DATA,
  });
