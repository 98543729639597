import { H4 } from 'assets/styled';
import { Formik } from 'formik';
import {
  ColumnContainer,
  Container,
  Heading,
  RowSpaceBetween,
} from '../EditCustomerDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { IEditCustomerContactDetails } from '../constants';
import { editCustomerDetailsContactTabSchema } from '../validation';
interface IContactTabProps {
  contactDetails: IEditCustomerContactDetails;
  setContactDetails: (contactDetails: IEditCustomerContactDetails) => void;
  setIsValid: (isValid: boolean) => void;
}
export const ContactTab = ({
  contactDetails,
  setContactDetails,
  setIsValid,
}: IContactTabProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          //
        }}
        initialValues={contactDetails}
        validationSchema={editCustomerDetailsContactTabSchema}
      >
        {({ values, setFieldValue, handleBlur, errors, isValid }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setContactDetails(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('E-MAIL')}</H4>
                  </Heading>
                  <FormikInput
                    pwId="email-field"
                    errorMessage={errors['email'] ? errors['email'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'email');
                    }}
                    height={'43rem'}
                    name="email"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Phone number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    pwId="phone-field"
                    errorMessage={errors['phone'] ? errors['phone'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'phone');
                    }}
                    height={'43rem'}
                    name="phone"
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
