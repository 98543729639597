import {
  CardsWrapperRow,
  Container,
  ContentWrapper,
  DescriptionCardWrapper,
  DescriptionTitle,
  DescriptionValueLabel,
  LeftSide,
  RightSide,
  Separator50,
  StockItemIsActiveLabel,
  TitleContainer,
} from './SingleStockItemPage.styled';
import { COLORS, H3 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import { useGetSingleStockItem } from 'pages/Manager/StockItemsPage/hooks';
import { RoutesConfig } from 'navigation/routes';
import SmallCard from 'components/Cards/SmallCard/SmallCard';
import SimpleCard from 'components/Cards/SimpleCard/SimpleCard';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useEffect, useState } from 'react';
import { useActivateStockitem, useDeleteStockItem } from './hooks';
import { EditStockAmountModal } from './EditStockAmountModal/EditStockAmountModal';
import { formatNumber } from 'utils/numberFormatter';
import { PriceType, StockItemType } from './constants';
import { EditStockItemDetailsModal } from './EditStockItemDetailsModal/EditStockItemDetailsModal';
import { EditStockItemPriceModal } from './EditStockItemPriceModal/EditStockItemPriceModal';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import AddEditDefaultFields from './AddEditDefaultFields/AddEditDefaultFields';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { CaretLeft } from '@phosphor-icons/react';
import { useGetSingleSupplier } from '../SingleSupplierPage/hooks';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';

const SingleStockItemPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const canEditStock = useCan(Actions.EDIT_STOCK_ITEM);
  const canDeleteStock = useCan(Actions.DELETE_STOCK_ITEM);
  const canCreatePurchaseOrder = useCan(Actions.CREATE_PURCHASE_ORDER);
  const currencySymbol = useGetCurrencySymbol();

  const {
    data,
    isLoading,
    isError: isErrorStockItem,
    isFetched: isFetchedStockItem,
  } = useGetSingleStockItem(id!);
  const { data: supplierData } = useGetSingleSupplier(
    data?.stock_item?.supplier_id
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateStockItemModalOpen, setIsActivateStockItemModalOpen] =
    useState<boolean>(false);
  const [isEditStockAmountModalOpen, setIsEditStockAmountModalOpen] =
    useState<boolean>(false);
  const [isEditStockItemModalOpen, setIsEditStockItemModalOpen] =
    useState<boolean>(false);
  const [isEditSalesPriceModalOpen, setIsEditSalesPriceModalOpen] =
    useState<boolean>(false);
  const [isEditPurchasePriceModalOpen, setIsEditPurchasePriceModalOpen] =
    useState<boolean>(false);
  const [isAddDefaultFieldsModalOpen, setIsAddDefaultFieldsModalOpen] =
    useState<boolean>(false);

  const {
    mutate: deleteStockitem,
    isLoading: deleteStockItemIsLoading,
    isSuccess: deleteStockItemIsSuccess,
  } = useDeleteStockItem();

  const {
    mutate: activateStockItem,
    isLoading: activateStockItemIsLoading,
    isSuccess: activateStockItemIsSuccess,
  } = useActivateStockitem();

  useEffect(() => {
    if (deleteStockItemIsSuccess) {
      setIsDeleteModalOpen(false);
    }
  }, [deleteStockItemIsSuccess]);

  useEffect(() => {
    if (activateStockItemIsSuccess) {
      setIsActivateStockItemModalOpen(false);
    }
  }, [activateStockItemIsSuccess]);

  const handleCreatePurchaseOrder = () => {
    navigate(
      `${SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath}`.replace(
        ':id',
        data?.stock_item?.supplier_id
      ),
      {
        state: {
          prepopulatePurchaseOrderData: {
            supplierData: supplierData,
            stockItem: data,
          },
        },
      }
    );
  };

  const cardData: any = {
    Number: data?.stock_item?.number,
    'Product name': data?.stock_item?.name,
    'Item type':
      data?.stock_item?.type &&
      data?.stock_item?.type?.name.charAt(0) +
        data?.stock_item?.type?.name.slice(1).toLowerCase(),
    Supplier: data?.stock_item?.supplier?.company_name,
    'Contact person': data?.stock_item?.supplier?.contact_person,
    'E-mail': data?.stock_item?.supplier?.email,
    'Phone number': data?.stock_item?.supplier?.phone,
    SKU: data?.stock_item?.sku,
    'Item supplier number': data?.stock_item?.number_supplier,
  };

  return !isErrorStockItem ? (
    <Container>
      <TitleContainer>
        <LeftSide>
          <Icon
            svg={CaretLeft}
            marginRight="15rem"
            size={15}
            weight="regular"
            color={COLORS.BLACK}
            onClick={() => navigate(RoutesConfig.StockItems.fullPath)}
          />
          <H3>{data?.stock_item?.name}</H3>
          <StockItemIsActiveLabel>
            {data?.stock_item?.is_active ? t('ACTIVE') : t('DELETED')}
          </StockItemIsActiveLabel>
        </LeftSide>
        <RightSide>
          <Button
            width={'200rem'}
            onClick={() => setIsAddDefaultFieldsModalOpen(true)}
            label={t('Add default fields')}
            secondary
          />
          {data?.stock_item?.is_active ? (
            <Button
              width={'200rem'}
              onClick={() => setIsDeleteModalOpen(true)}
              label={t('Delete stock item')}
              redOutline
              disabled={!canDeleteStock}
            />
          ) : (
            <Button
              width={'200rem'}
              onClick={() => setIsActivateStockItemModalOpen(true)}
              secondary
              label={t('Activate stock item')}
              disabled={!canDeleteStock}
            />
          )}
          {data?.stock_item?.type?.name !== StockItemType.SERVICE && (
            <Button
              width={'200rem'}
              onClick={() => handleCreatePurchaseOrder()}
              label={t('Create purchase order')}
              primary
              disabled={!canCreatePurchaseOrder}
            />
          )}
        </RightSide>
      </TitleContainer>
      <ContentWrapper>
        <CardsWrapperRow>
          <SmallCard
            label={t('Sales Price')}
            value={`${currencySymbol}${formatNumber(data?.stock_item?.price)}`}
            onEditClick={() => setIsEditSalesPriceModalOpen(true)}
            isLoading={isLoading}
            editable={canEditStock}
          />
          <SmallCard
            label={t('Cost Price')}
            value={`${currencySymbol}${formatNumber(
              data?.stock_item?.purchase_price
            )}`}
            onEditClick={() => setIsEditPurchasePriceModalOpen(true)}
            isLoading={isLoading}
            editable={canEditStock}
          />
          <SmallCard
            label={t('Units Bought')}
            value={formatNumber(data?.stock_item?.bought_amount, false)}
            isLoading={isLoading}
            editable={canEditStock}
          />
          <SmallCard
            label={t('Units Sold')}
            value={formatNumber(data?.stock_item?.sold_amount, false)}
            isLoading={isLoading}
            editable={canEditStock}
          />
          {data?.stock_item?.type?.name !== StockItemType.SERVICE && (
            <SmallCard
              label={t('In Stock')}
              value={formatNumber(data?.stock_item?.in_stock, false)}
              onEditClick={() => setIsEditStockAmountModalOpen(true)}
              isLoading={isLoading}
              editable={canEditStock}
            />
          )}
        </CardsWrapperRow>
        <Separator50 />
        <CardsWrapperRow>
          <SimpleCard
            cardTitle={t('Details')}
            cardData={cardData}
            minLabelWidth={'105rem'}
            onEditClick={() => setIsEditStockItemModalOpen(true)}
          />
          <DescriptionCardWrapper>
            <DescriptionTitle>{t('Description')}</DescriptionTitle>
            <br />
            <DescriptionValueLabel>
              {data?.stock_item?.description}
            </DescriptionValueLabel>
          </DescriptionCardWrapper>
        </CardsWrapperRow>
      </ContentWrapper>
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteStockitem(id!);
            },
            text: t('Delete'),
            disabled: deleteStockItemIsLoading,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Delete Stock Item')}
          description={
            t(
              `Are you sure you want to set this Stock Item's status to Deleted`
            ) + '?'
          }
        />
      </Modal>
      <YesOrNoModal
        pwId="yes-or-no-stock-activate-stock-item-modal"
        disabled={activateStockItemIsLoading}
        isOpen={isActivateStockItemModalOpen}
        setIsOpen={setIsActivateStockItemModalOpen}
        onYes={() => {
          activateStockItem(id!);
        }}
        onNo={() => setIsActivateStockItemModalOpen(false)}
        title={t('Activate Stock Item')}
        description={`${t(
          `Are you sure you want to set this Stock Item's status to Active`
        )}?`}
      />
      <EditStockAmountModal
        isOpen={isEditStockAmountModalOpen}
        setIsOpen={setIsEditStockAmountModalOpen}
        initialAmount={data?.stock_item?.in_stock}
      />
      <EditStockItemDetailsModal
        isOpen={isEditStockItemModalOpen}
        setIsOpen={setIsEditStockItemModalOpen}
        onCancel={() => setIsEditStockItemModalOpen(false)}
        stockItemDetails={data?.stock_item}
      />
      <EditStockItemPriceModal
        isOpen={isEditSalesPriceModalOpen}
        setIsOpen={setIsEditSalesPriceModalOpen}
        onCancel={() => setIsEditSalesPriceModalOpen(false)}
        type={PriceType.SALES_PRICE}
        price={data?.stock_item?.price}
      />
      <EditStockItemPriceModal
        isOpen={isEditPurchasePriceModalOpen}
        setIsOpen={setIsEditPurchasePriceModalOpen}
        onCancel={() => setIsEditPurchasePriceModalOpen(false)}
        type={PriceType.PURCHASE_PRICE}
        price={data?.stock_item?.purchase_price}
      />
      {isFetchedStockItem && (
        <Modal
          level={'SECOND'}
          isOpen={isAddDefaultFieldsModalOpen}
          setIsOpen={setIsAddDefaultFieldsModalOpen}
          modalStyle={{
            position: 'fixed',
            margin: 'auto',
            maxWidth: '695rem',
          }}
        >
          <AddEditDefaultFields
            stockItemId={id!}
            stockItemAttributes={data?.stock_item?.attributes || []}
            onCancel={() => setIsAddDefaultFieldsModalOpen(false)}
          />
        </Modal>
      )}
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleStockItemPage;
