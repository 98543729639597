import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSupplierPurchaseOrders } from 'services/Supplier/SupplierService';

export const useGetSingleSupplierPurchaseOrders = (
  page: number,
  perPage: number,
  supplierId: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SUPPLIER_PURCHASE_ORDERS,
      page,
      perPage,
      supplierId,
    ],
    queryFn: () => {
      return getSupplierPurchaseOrders(page, perPage, supplierId);
    },
    enabled: !!supplierId,
  });
