import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'utils/toast';
import LocalStorageService from 'services/LocalStorageService';
import { logout } from 'services/User/UserService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { logout as logoutAction } from 'store/User/actions/user';
import { setIsFieldWorkerView } from 'store/Common/actions/common';

export const useLogout = () => {
  const dispatch = useDispatch();
  return useMutation(() => logout(), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.LOGOUT,
      });
    },
    onSettled: () => {
      LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
      dispatch(setIsFieldWorkerView(false));
      dispatch(logoutAction());
    },
    mutationKey: ReactMutationKeys.LOGOUT,
  });
};
