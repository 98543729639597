import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginMd, paddingSm } from 'assets/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const EditStockAmountLabel = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 410rem;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
