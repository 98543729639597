import { useSelector } from 'react-redux';
import { PlaceholderSpan } from './Placeholder.styled';
import { IRootReducerState } from 'store/store';

interface IPlaceholderProps {
  isTranslatedToTop: boolean;
  isFormikPlaceholder: boolean;
  isTextAreaPlaceholder?: boolean;
  isSelectPlaceholder: boolean;
  placeholder: string | undefined;
}

const Placeholder = ({
  isTranslatedToTop,
  isFormikPlaceholder,
  isSelectPlaceholder,
  isTextAreaPlaceholder,
  placeholder,
}: IPlaceholderProps) => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  return (
    <PlaceholderSpan
      isTranslatedToTop={isTranslatedToTop}
      isFormikPlaceholder={isFormikPlaceholder}
      isSelectPlaceholder={isSelectPlaceholder}
      isTextAreaPlaceholder={isTextAreaPlaceholder}
      darkMode={darkMode}
    >
      {placeholder}
    </PlaceholderSpan>
  );
};

export default Placeholder;
