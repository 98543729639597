import Placeholder from 'components/Input/Placeholder/Placeholder';
import { Container, FakeInputLabel } from './FakeLogyxInput.styled';
import { QuestionType } from 'store/Logyx/types';
import { useTranslation } from 'react-i18next';

interface IFakeInputProps {
  placeholder: string;
  label: string;
  questionType: QuestionType;
  width: string;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  maxHeight?: string;
}

const FakeLogyxInput = ({
  placeholder,
  label,
  questionType,
  width,
  height,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  maxHeight,
}: IFakeInputProps) => {
  const { t } = useTranslation();
  const renderLabel = (label: any) => {
    switch (label) {
      case true:
        return t('Yes');
      case false:
        return t('No');
      default:
        return label;
    }
  };
  switch (questionType) {
    case QuestionType.LIST:
    case QuestionType.TABLE:
    case QuestionType.BOOLEAN:
    case QuestionType.NUMBER:
    case QuestionType.DATETIME:
    case QuestionType.RADIO:
    case QuestionType.COLOR:
    case QuestionType.TEXT: {
      return (
        <Container
          maxHeight={maxHeight}
          width={width}
          height={height}
          marginLeft={marginLeft}
          marginTop={marginTop}
          marginBottom={marginBottom}
          marginRight={marginRight}
        >
          <FakeInputLabel>{renderLabel(label)}</FakeInputLabel>
          <Placeholder
            isTranslatedToTop={true}
            placeholder={placeholder}
            isFormikPlaceholder={false}
            isSelectPlaceholder={false}
          />
        </Container>
      );
    }
    default:
      return null;
  }
};

export default FakeLogyxInput;
