import Button from 'components/Button/Button';
import FakeInput from 'components/FakeInput/FakeInput';
import { useTranslation } from 'react-i18next';
import { ICreateCreditInvoiceFormDTO } from '../constants';
import {
  ButtonsWrapper,
  InvoiceCreationInfoWrapper,
  OverviewContainer,
  RowMarginTop,
} from './Overview.styled';
import { H3, H4, marginMd } from 'assets/styled';
import { Grid } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Loader from 'components/Loader/Loader';

interface ISalesCreditInvoiceCreationInfo {
  parent_invoice_total_credit_amount: number;
  total_amount: number;
  total_tax_amount: number;
}

interface IOverviewProps {
  creditInvoiceFormData: ICreateCreditInvoiceFormDTO;
  onBack: () => void;
  handleSubmitForm: () => void;
  salesCreditInvoiceCreationInfoData:
    | ISalesCreditInvoiceCreationInfo
    | undefined;
  isLoadingSalesCreditInvoiceCreationInfo: boolean;
}

const Overview = ({
  creditInvoiceFormData,
  onBack,
  handleSubmitForm,
  salesCreditInvoiceCreationInfoData,
  isLoadingSalesCreditInvoiceCreationInfo,
}: IOverviewProps) => {
  const { t } = useTranslation();

  return (
    <OverviewContainer>
      <H3>{t('Overview')}</H3>
      <RowMarginTop marginTop={marginMd}>
        <FakeInput
          width={'300rem'}
          placeholder={t('Reason')}
          label={creditInvoiceFormData.reason}
          isDropdown={false}
        />
        <FakeInput
          width={'300rem'}
          placeholder={t('Corrective amount')}
          label={
            creditInvoiceFormData.correctiveAmount
              ? creditInvoiceFormData.correctiveAmount.toString()
              : ''
          }
          isDropdown={false}
          marginLeft={marginMd}
        />
      </RowMarginTop>
      <RowMarginTop marginTop={marginMd}>
        <FakeInput
          width={'300rem'}
          placeholder={t('Reference')}
          label={creditInvoiceFormData.reference}
          isDropdown={false}
        />
      </RowMarginTop>
      <FakeInput
        width={'100%'}
        placeholder={t('Corrective amount')}
        label={
          creditInvoiceFormData.correctiveAmount
            ? creditInvoiceFormData.correctiveAmount.toString()
            : ''
        }
        isDropdown={false}
        marginTop={marginMd}
        height="120rem"
      />
      {salesCreditInvoiceCreationInfoData ? (
        <InvoiceCreationInfoWrapper>
          <H4>{t('Invoice creation info')}</H4>
          <Grid container>
            {Object.keys(salesCreditInvoiceCreationInfoData).map(
              (infoKey: string, index: number) => {
                return (
                  <Grid key={index} item>
                    <FakeInput
                      width={'300rem'}
                      placeholder={t(
                        capitalizeFirstLetter(infoKey.replaceAll('_', ' '))
                      )}
                      label={salesCreditInvoiceCreationInfoData[infoKey]}
                      isDropdown={false}
                      marginLeft={index % 2 ? marginMd : ''}
                      marginTop={marginMd}
                    />
                  </Grid>
                );
              }
            )}
          </Grid>
        </InvoiceCreationInfoWrapper>
      ) : isLoadingSalesCreditInvoiceCreationInfo ? (
        <Loader />
      ) : null}
      <ButtonsWrapper>
        <Button
          label={t('Back')}
          secondary
          width="200rem"
          onClick={() => {
            onBack();
          }}
        />
        <Button
          disabled={isLoadingSalesCreditInvoiceCreationInfo}
          label={t('Next')}
          primary
          width="200rem"
          onClick={() => handleSubmitForm()}
        />
      </ButtonsWrapper>
    </OverviewContainer>
  );
};

export default Overview;
