import { useLocation } from 'react-router-dom';

//Used to remove padding from Router Wrapper
export const useCheckRouterWithoutPaddingBottom = () => {
  const location = useLocation();
  if (
    location.pathname.includes('comments') ||
    location.pathname.includes('payments')
  ) {
    return true;
  } else return false;
};
