import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './locales/en.json';
import nlTranslations from './locales/nl.json';
import deTranslations from './locales/de.json';
import noTranslations from './locales/no.json';
import frTranslations from './locales/fr.json';
import srTranslations from './locales/sr.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const FALLBACK_I18N_LANG = 'nl';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, //to disable console logs change it to false
    returnNull: false, // Fix for typescript error:  Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz
    fallbackLng: FALLBACK_I18N_LANG,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: enTranslations,
      nl: nlTranslations,
      de: deTranslations,
      no: noTranslations,
      fr: frTranslations,
      sr: srTranslations,
    },
  });

export default i18n;
