import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SingleSalesOrderRoutes } from '../../../navigation/SingleSalesOrder/SingleSalesOrder.routes';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SingleSalesOrderRoutes.SingleSalesOrderGeneral.path,
  },
  {
    text: 'Products & Services',
    route: SingleSalesOrderRoutes.SingleSalesOrderProducts.path,
  },
  {
    text: 'Comments',
    route: SingleSalesOrderRoutes.SingleSalesOrderComments.path,
  },
  {
    text: 'Quotations',
    route: SingleSalesOrderRoutes.SingleSalesOrderQuotations.path,
  },
  {
    text: 'Invoices',
    route: SingleSalesOrderRoutes.SingleSalesOrderInvoices.path,
  },
  {
    text: 'Purchase orders',
    route: SingleSalesOrderRoutes.SingleSalesOrderPurchaseOrders.path,
  },
  {
    text: 'Appointments',
    route: SingleSalesOrderRoutes.SingleSalesOrderAppointments.path,
  },
  {
    text: 'Reports',
    route: SingleSalesOrderRoutes.SingleSalesOrderReports.path,
  },
  {
    text: 'Files',
    route: SingleSalesOrderRoutes.SingleSalesOrderFiles.path,
  },
];
//Sales order statuses
export const NEW_STATUS = 'NEW';
export const ONGOING_STATUS = 'ONGOING';
export const CLOSED_STATUS = 'CLOSED';

//Sales order substatuses
export const CREATE_DOWN_PAYMENT_INVOICE = 'CREATE_DOWN_PAYMENT_INVOICE';
export const TO_ORDER = 'TO_ORDER';
export const PARTLY_ORDERED = 3;
export const ORDERED = 4;
export const PARTLY_AVAILABLE = 5;
export const AVAILABLE = 6;
export const PARTLY_PLANNED = 7;
export const PLANNED = 8;
export const PARTLY_DELIVERED = 9;
export const DELIVERED = 10;

export const EMPTY_PRICE = '0';

export enum SalesOrderStatusType {
  STATUS = 'STATUS',
  SUBSTATUS = 'SUBSTATUS',
  PAYMENT = 'PAYMENT',
}
