export const COLORS = {
  WHITE: 'var(--color-white)',
  BLACK: 'var(--color-black)',
  PRIMARY: 'var(--color-primary)',
  PRIMARY_DISABLED: 'var(--color-primary-disabled)',
  BLACK_200: 'var(--color-black-200)',
  BLACK_200_OPACITY: 'var(--color-black-200-opacity)',
  BLACK_300_OPACITY: 'var(--color-black-300-opacity)',
  BLACK_400_OPACITY: 'var(--color-black-400-opacity)',
  RIPPLE_EFFECT_OPACITY: 'var(--color-ripple-effect-opacity)',
  RIPPLE_CHILD_EFFECT_OPACITY: 'var(--color-ripple-child-effect-opacity)',
  GREY_100: 'var(--color-grey-100)',
  GREY_200: 'var(--color-grey-200)',
  GREY_300: 'var(--color-grey-300)',
  GREY_400: 'var(--color-grey-400)',
  GREY_500: 'var(--color-grey-500)',
  GREY_700: 'var(--color-grey-700)',
  GREY_800: 'var(--color-grey-800)',
  GREY_900: 'var(--color-grey-900)',
  GREY_1000: 'var(--color-grey-1000)',
  RED_100: 'var(--color-red-100)',
  RED_200: 'var(--color-red-200)',
  RED_TRASH: 'var(--color-red-trash)',
  ERP_BLACK: 'var(--color-erp-black)',
  BACKGROUND_COLOR: 'var(--color-background)',
  LOGYX_GREEN: 'var(--color-logyx-green)',
  LOGYX_LIGHT_GREEN: 'var(--color-logyx-light-green)',
  STRICT_WHITE: 'var(--color-strict-white)',
  STRICT_BLACK: 'var(--color-strict-black)',
  CANVAS: 'var(--color-canvas)',

  AP_WHITE: 'var(--color-ap-white)',
  GREEN: 'var(--color-green)',
  LIGHT_GREEN: 'var(--color-light-green)',
  ORANGE: 'var(--color-orange)',
  LIGHT_ORANGE: 'var(--color-light-orange)',
  PURPLE: 'var(--color-purple)',
  LIGHT_PURPLE: 'var(--color-light-purple)',
  SHIP_GREY: 'var(--color-ship-grey)',
  LIGHT_PRIMARY: 'var(--color-light-primary)',
};
