import { Field, Formik } from 'formik';
import { COLORS, H5 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Container,
  ApplyWrapper,
  WorkersAndResources,
  StyledSelect,
  Cont,
  StyledSelectTop,
  HorizontalLine,
  DateTimeSection,
  WorkersAndResourcesHeading,
  ArrivalTimeText,
  SalesOrderHeading,
  OptionsWrapper,
  OptionLabel,
  UsersHeading,
  UsersContainer,
  Scrollable,
  DateTimeWrapper,
  TimeIntervalErrorMessageCont,
  TimeIntervalErrorMessage,
  ArrivalTimeWrapper,
  ArrivalTime,
  ArrivalTimeInner,
  CheckboxContainer,
  HalfCont,
  AltTimeWrapper,
  DateLabel,
  TimeWrapper,
} from './MeasurementTab.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useCreateAppointment,
  useGetQuotationsInfinite,
  useGetResourcesInfinite,
  useGetSalesOrdersInfinite,
  useGetUsersInfinite,
  useGetWorkersInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import { Option } from 'components/Select/type';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import {
  AddEmailNoteModal,
  IEmailDetails,
} from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import {
  MeasurementTabOptions,
  QUOTATIONS_PER_PAGE,
  SALES_ORDERS_PER_PAGE,
} from './constants';
import {
  RESOURCES_PER_PAGE,
  USERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from '../GeneralTab/constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateAppointmentNavigationState,
  useGetSelectedNewAppointmentEntity,
  useHandleSelectOptions,
} from '../hooks';
import DatePicker from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from '../validation';
import { validateQuotationAndSalesOrderField } from './validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { getNewAppointmentEmailDetails } from '../helpers';
import { Minus, WarningCircle } from '@phosphor-icons/react';

export interface IMeasurementTabProps {
  isNewAppointmentModalOpen: boolean;
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  closeAppointmentModal: () => void;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  arrival_to?: string;
  arrival_from?: string;
  selectedUsersOptions: Option[];
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
}

const MeasurementTab = ({
  isNewAppointmentModalOpen,
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  closeAppointmentModal,
  startDate,
  endDate,
  time_from,
  time_to,
  arrival_to,
  arrival_from,
  selectedUsersOptions,
  selectedWorkersOptions,
  selectedResourcesOptions,
}: IMeasurementTabProps) => {
  const { t } = useTranslation();
  const { state }: { state: ICreateAppointmentNavigationState } = useLocation();

  const [quotationsSearchBy, setQuotationsSearchBy] = useState<string>('');
  const debouncedQuotationsSearchBy = useDebounce(quotationsSearchBy);

  const [salesOrderSearchBy, setSalesOrderSearchBy] = useState<string>('');
  const debouncedSalesOrderSearchBy = useDebounce(salesOrderSearchBy);

  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);

  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);

  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [formikValues, setFormikValues] = useState<ICreateAppointmentDTO>();
  const [selectedOption, setSelectedOption] = useState<MeasurementTabOptions>(
    isCreatingAppointmentFromNavigationState && state?.sales_order_id
      ? MeasurementTabOptions.SALES_ORDER
      : MeasurementTabOptions.QUOTATION
  );

  const {
    data: quotationsData,
    fetchNextPage: fetchNextPageQuotations,
    hasNextPage: hasNextPageQuotations,
    isLoading: isLoadingQuotations,
  } = useGetQuotationsInfinite(
    QUOTATIONS_PER_PAGE,
    debouncedQuotationsSearchBy,
    selectedOption === MeasurementTabOptions.QUOTATION
  );

  const quotations = useMemo(() => {
    if (quotationsData?.pages?.length) {
      return quotationsData.pages
        .map((page) => page.quotations)
        .flat()
        .map((quotation: any) => {
          return {
            value: quotation.id,
            label: `${quotation.number}`,
            customer_id: `${quotation.customer.id}`,
          };
        });
    }
    return [];
  }, [quotationsData]);

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersInfinite(
    SALES_ORDERS_PER_PAGE,
    debouncedSalesOrderSearchBy,
    selectedOption === MeasurementTabOptions.SALES_ORDER
  );

  const salesOrders = useMemo(() => {
    if (salesOrdersData?.pages?.length) {
      return salesOrdersData.pages
        .map((page) => page.sales_orders)
        .flat()
        .map((sales_order: any) => {
          return {
            value: sales_order.id,
            label: `${sales_order.number}`,
            customer_id: `${sales_order.customer.id}`,
          };
        });
    }
    return [];
  }, [salesOrdersData]);

  const {
    mutate: createAppointment,
    isSuccess,
    isLoading,
  } = useCreateAppointment();

  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );
  const createAppointmentIsLoading = !!isMutatingCreateAppointment || isLoading;

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersInfinite(
    USERS_PER_PAGE,
    debouncedUserSearchBy,
    undefined, // dateFrom
    undefined, // dateTo
    isNewAppointmentModalOpen
  );

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    usersData,
    workersData,
    resourcesData,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  useEffect(() => {
    if (isSuccess) {
      setIsCreatingAppointmentFromNavigationState(false);
      closeAppointmentModal();
      setIsSendEmailModalOpen(false);
    }
  }, [isSuccess]);

  const checkEmailSending = (values: ICreateAppointmentDTO) => {
    setFormikValues(values);
    setIsSendEmailModalOpen(true);
  };
  const handleCreateAppointment = async (
    values: ICreateAppointmentDTO,
    shouldSendEmail: boolean
  ) => {
    const createAppointmentData: ICreateAppointmentDTO = {
      ...(values?.quotation_id &&
        selectedOption === MeasurementTabOptions.QUOTATION && {
          quotation_id: values?.quotation_id,
        }),
      ...(values?.sales_order_id &&
        selectedOption === MeasurementTabOptions.SALES_ORDER && {
          sales_order_id: values?.sales_order_id,
        }),
      date_from: `${values.startDate} ${values.time_from}`,
      date_to: `${values.endDate} ${values.time_to}`,
      appointment_type_id: values.appointment_type_id,
      users_ids: values?.users_ids,
      workers_ids: values?.workers_ids,
      resources_ids: values?.resources_ids,
      confirmed: isConfirmed,
      ...(values.arrival_from && {
        arrival_from: `${values.startDate} ${values.arrival_from}`,
      }),
      ...(values.arrival_to && {
        arrival_to: `${values.startDate} ${values.arrival_to}`,
      }),
    };
    createAppointment({
      createAppointmentData,
      shouldSendEmail,
      note: '',
      files: [],
    });
  };

  const {
    setSelectedQuotationId,
    setSelectedSalesOrderId,
    selectedQuotation,
    selectedSalesOrder,
  } = useGetSelectedNewAppointmentEntity(state);

  const emailDetails: IEmailDetails | undefined = getNewAppointmentEmailDetails(
    selectedQuotation,
    selectedSalesOrder
  );

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // onSubmit
        }}
        initialValues={{
          quotation_id:
            isCreatingAppointmentFromNavigationState && state?.quotation_id
              ? state?.quotation_id
              : '',
          sales_order_id:
            isCreatingAppointmentFromNavigationState && state?.sales_order_id
              ? state?.sales_order_id
              : '',
          startDate: startDate || '',
          endDate: endDate || '',
          date_from: '',
          date_to: '',
          time_to: time_to?.split('+')[0] || '',
          time_from: time_from?.split('+')[0] || '',
          arrival_to: arrival_to?.split('+')[0] || '',
          arrival_from: arrival_from?.split('+')[0] || '',
          appointment_type_id: '1',
          users_ids: selectedUsersOptions.map((user) => user.id) || [],
          resources_ids:
            selectedResourcesOptions.map((resource) => resource.id) || [],
          workers_ids: selectedWorkersOptions.map((worker) => worker.id) || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, setFieldError, values, isValid }) => {
          return (
            <Container>
              <Scrollable>
                <DateTimeSection>
                  <H5>{t('DATE & TIME')}</H5>
                  <DateLabel>{t('From')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'startDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              startDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <Field
                        name="time_from"
                        component={TimePicker}
                        width="100%"
                        pwId="time-from-select"
                        time={moment(values?.time_from, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            values.time_to,
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_from',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <DateLabel>{t('To')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.endDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'endDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              endDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <Field
                        name="time_to"
                        component={TimePicker}
                        width="100%"
                        pwId="time-to-select"
                        time={moment(values?.time_to, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            values.time_from,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_to',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <TimeIntervalErrorMessageCont>
                    <TimeIntervalErrorMessage>
                      {errors.time_to}
                    </TimeIntervalErrorMessage>
                  </TimeIntervalErrorMessageCont>
                  <ArrivalTimeWrapper>
                    <ArrivalTimeInner>
                      <ArrivalTime>
                        <ArrivalTimeText
                          onClick={() => setShowArrivalTime(true)}
                        >
                          {t('Arrival time')}
                        </ArrivalTimeText>
                        <Tooltip
                          content={
                            t(
                              'This is the expected arrival time that will be communicated with the customer via the appointment email'
                            ) + '.'
                          }
                        >
                          <Icon
                            svg={WarningCircle}
                            color={COLORS.PRIMARY}
                            size={20}
                            weight="regular"
                            marginLeft={'10rem'}
                            pointer={false}
                          />
                        </Tooltip>
                      </ArrivalTime>
                      {showArrivalTime && (
                        <>
                          <AltTimeWrapper>
                            <Field
                              name="arrival_from"
                              component={TimePicker}
                              width="100%"
                              pwId="time-from-select"
                              time={moment(values?.arrival_from, 'HH:mm:ss')}
                              setTime={(newValue: Moment) => {
                                validateTimeOnlyInterval(
                                  newValue?.format('HH:mm:ss'),
                                  values.arrival_to,
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_from',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                            <Icon
                              svg={Minus}
                              size={18}
                              weight="bold"
                              color={COLORS.BLACK}
                              marginLeft="5rem"
                              marginRight="5rem"
                            />
                            <Field
                              name="arrival_to"
                              component={TimePicker}
                              width="100%"
                              pwId="time-to-select"
                              time={moment(values?.arrival_to, 'HH:mm:ss')}
                              setTime={(newValue: Moment) => {
                                validateTimeOnlyInterval(
                                  values.arrival_from,
                                  newValue?.format('HH:mm:ss'),
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_to',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                          </AltTimeWrapper>
                        </>
                      )}
                    </ArrivalTimeInner>
                    <TimeIntervalErrorMessageCont>
                      <TimeIntervalErrorMessage>
                        {errors.arrival_to}
                      </TimeIntervalErrorMessage>
                    </TimeIntervalErrorMessageCont>
                  </ArrivalTimeWrapper>
                </DateTimeSection>
                <HorizontalLine />
                <SalesOrderHeading>
                  <OptionsWrapper>
                    {isCreatingAppointmentFromNavigationState &&
                    state?.sales_order_id ? null : (
                      <OptionLabel
                        data-testid="quotation-label"
                        onClick={() => {
                          setSelectedOption(MeasurementTabOptions.QUOTATION);
                          validateQuotationAndSalesOrderField(
                            values.quotation_id,
                            values.sales_order_id,
                            setFieldError,
                            t,
                            MeasurementTabOptions.QUOTATION
                          );
                        }}
                        isSelected={
                          selectedOption === MeasurementTabOptions.QUOTATION
                        }
                      >
                        {t(MeasurementTabOptions.QUOTATION)}
                      </OptionLabel>
                    )}
                    {isCreatingAppointmentFromNavigationState &&
                    state?.quotation_id ? null : (
                      <OptionLabel
                        data-testid="sales-order-label"
                        onClick={() => {
                          setSelectedOption(MeasurementTabOptions.SALES_ORDER);
                          validateQuotationAndSalesOrderField(
                            values.quotation_id,
                            values.sales_order_id,
                            setFieldError,
                            t,
                            MeasurementTabOptions.SALES_ORDER
                          );
                        }}
                        isSelected={
                          selectedOption === MeasurementTabOptions.SALES_ORDER
                        }
                      >
                        {t(MeasurementTabOptions.SALES_ORDER)}
                      </OptionLabel>
                    )}
                  </OptionsWrapper>
                </SalesOrderHeading>
                {selectedOption === MeasurementTabOptions.QUOTATION &&
                  (isCreatingAppointmentFromNavigationState &&
                  state?.quotation_id ? (
                    <FormikInput
                      disabled
                      height={'41rem'}
                      name="quotation_id"
                      value={state?.quotation_number}
                      onChange={(e: any) =>
                        setQuotationsSearchBy(e.target.value)
                      }
                      wrapperStyles={{
                        width: '200rem',
                      }}
                    />
                  ) : (
                    <StyledSelectTop
                      pwId="quotation-id"
                      name="quotation_id"
                      options={quotations}
                      onInputChange={(e: string) => setQuotationsSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Quotation')}
                      isMulti={false}
                      onSelect={({ value, customer_id }: any) => {
                        setSelectedSalesOrderId(undefined);
                        setSelectedQuotationId(value);
                        setFieldValue('quotation_id', value);
                        setFieldValue('sales_order_id', '');
                        setFieldValue('customer_id', customer_id);
                        validateQuotationAndSalesOrderField(
                          value,
                          values.sales_order_id,
                          setFieldError,
                          t,
                          selectedOption
                        );
                      }}
                      onMenuScrollToBottom={() => {
                        hasNextPageQuotations && fetchNextPageQuotations();
                      }}
                      isLoading={isLoadingQuotations}
                      translate={false}
                      errorMessage={errors.quotation_id}
                    />
                  ))}
                {selectedOption === MeasurementTabOptions.SALES_ORDER ? (
                  isCreatingAppointmentFromNavigationState &&
                  state?.sales_order_id ? (
                    <FormikInput
                      disabled
                      height={'41rem'}
                      name="sales_order_id"
                      value={state?.sales_order_number}
                      wrapperStyles={{
                        width: '200rem',
                      }}
                    />
                  ) : (
                    <StyledSelectTop
                      pwId="sales-order-id"
                      name="sales_order_id"
                      options={salesOrders}
                      onInputChange={(e: string) => setSalesOrderSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Sales order')}
                      isMulti={false}
                      onSelect={({ value, customer_id }: any) => {
                        setSelectedQuotationId(undefined);
                        setSelectedSalesOrderId(value);
                        setFieldValue('sales_order_id', value);
                        setFieldValue('quotation_id', '');
                        setFieldValue('customer_id', customer_id);
                        validateQuotationAndSalesOrderField(
                          values.quotation_id,
                          value,
                          setFieldError,
                          t,
                          selectedOption
                        );
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageSalesOrders && fetchNextPageSalesOrders()
                      }
                      isLoading={isLoadingSalesOrders}
                      translate={false}
                      errorMessage={errors.sales_order_id}
                    />
                  )
                ) : null}
                <HorizontalLine />
                <UsersHeading>
                  <H5>{t('USERS')}</H5>
                </UsersHeading>
                <UsersContainer>
                  <HalfCont>
                    <StyledSelect
                      name="users_ids"
                      options={userOptions}
                      onInputChange={(e: string) => setUserSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Users')}
                      isMulti={true}
                      onSelect={(value: Option[]) =>
                        setSelectedUserOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageUsers && fetchNextPageUsers()
                      }
                      isLoading={isLoadingUsers}
                      translate={false}
                    />
                  </HalfCont>
                </UsersContainer>
                <HorizontalLine />
                <WorkersAndResourcesHeading>
                  <H5>{t('WORKERS & RESOURCES')}</H5>
                </WorkersAndResourcesHeading>
                <WorkersAndResources>
                  <Cont>
                    <StyledSelect
                      width="200rem"
                      pwId="workers-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => setWorkerSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti={true}
                      onSelect={(value: Option[]) =>
                        setSelectedWorkerOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageWorkers && fetchNextPageWorkers()
                      }
                      isLoading={isLoadingWorkers}
                      translate={false}
                    />
                  </Cont>
                  <Cont>
                    <StyledSelect
                      width="200rem"
                      pwId="resources-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => setResourceSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onSelect={(value: Option[]) =>
                        setSelectedResourceOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageResources && fetchNextPageResources()
                      }
                      isLoading={isLoadingResources}
                      translate={false}
                    />
                  </Cont>
                </WorkersAndResources>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={setIsConfirmed}
                    label={t('The customer already confirmed the appointment')}
                  />
                </CheckboxContainer>
              </Scrollable>
              <ApplyWrapper>
                <Button
                  disabled={createAppointmentIsLoading}
                  onClick={closeAppointmentModal}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  disabled={createAppointmentIsLoading || !isValid}
                  onClick={() => {
                    if (
                      !validateQuotationAndSalesOrderField(
                        values.quotation_id,
                        values.sales_order_id,
                        setFieldError,
                        t,
                        selectedOption
                      )
                    ) {
                      return;
                    }
                    checkEmailSending(values);
                  }}
                  primary
                  width="200rem"
                  label={t('Save')}
                />
              </ApplyWrapper>
            </Container>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-appointment-confirmation-modal"
        level="SECOND"
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onYes={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onNo={() => {
          handleCreateAppointment(formikValues!, false);
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          isConfirmed
            ? t(
                'Do you want to send the appointment confirmation to the customer'
              ) + '?'
            : t('Do you want to send the appointment offer to the customer') +
              '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={formikValues?.quotation_id || formikValues?.sales_order_id || ''}
        type={AddEmailNoteModalType.MEASUREMENT_CREATION}
        newAppointment={formikValues!}
        isConfirmedAppointment={isConfirmed}
        onCreateAppointmentSuccess={() => {
          isCreatingAppointmentFromNavigationState &&
            setIsCreatingAppointmentFromNavigationState(false);
          closeAppointmentModal();
        }}
        emailDetails={emailDetails}
      />
    </div>
  );
};

export default MeasurementTab;
