import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttributesTab } from './AttributesTab/AttributesTab';
import { GeneralTab } from './GeneralTab/GeneralTab';
import { PlacementTab } from './PlacementTab/PlacementTab';
import {
  CloseContainer,
  Container,
  FilterTypeLabel,
  FilterTypesWrapper,
  ProductNameLabel,
} from './ViewProductModal.styled';
import { IViewProductProps } from './type';
import { ProductTab } from './constants';
import { Level } from '../type';

export interface IViewProductModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  productLineData: IViewProductProps;
  level?: Level;
}

export const ViewProductModal = ({
  isOpen,
  onCancel,
  setIsOpen,
  productLineData,
  level = 'FIRST',
}: IViewProductModalProps) => {
  const { t } = useTranslation();
  const [selectedProductFilterType, setSelectedProductFilterType] =
    useState<ProductTab>(ProductTab.GENERAL);

  const resetTabs = () => {
    setSelectedProductFilterType(ProductTab.GENERAL);
  };

  return (
    <Modal
      level={level}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
      }}
      onBackDropClick={resetTabs}
    >
      <CloseContainer>
        <Close
          onClick={() => {
            onCancel();
            resetTabs();
          }}
        />
      </CloseContainer>
      <Container>
        <ProductNameLabel>{productLineData?.product?.name}</ProductNameLabel>
        <FilterTypesWrapper>
          <FilterTypeLabel
            onClick={() => setSelectedProductFilterType(ProductTab.GENERAL)}
            isSelected={selectedProductFilterType === ProductTab.GENERAL}
          >
            {t(ProductTab.GENERAL)}
          </FilterTypeLabel>
          <FilterTypeLabel
            onClick={() => setSelectedProductFilterType(ProductTab.ATTRIBUTES)}
            isSelected={selectedProductFilterType === ProductTab.ATTRIBUTES}
          >
            {t(ProductTab.ATTRIBUTES)}
          </FilterTypeLabel>
          <FilterTypeLabel
            onClick={() => setSelectedProductFilterType(ProductTab.PLACEMENT)}
            isSelected={selectedProductFilterType === ProductTab.PLACEMENT}
          >
            {t(ProductTab.PLACEMENT)}
          </FilterTypeLabel>
        </FilterTypesWrapper>
        {selectedProductFilterType === ProductTab.ATTRIBUTES && (
          <AttributesTab
            productLineData={productLineData}
            onCancel={() => {
              onCancel();
              resetTabs();
            }}
          />
        )}
        {selectedProductFilterType === ProductTab.PLACEMENT && (
          <PlacementTab
            productLineData={productLineData}
            onCancel={() => {
              onCancel();
              resetTabs();
            }}
          />
        )}
        {selectedProductFilterType === ProductTab.GENERAL && (
          <GeneralTab
            productLineData={productLineData}
            onCancel={() => {
              onCancel();
              resetTabs();
            }}
          />
        )}
      </Container>
    </Modal>
  );
};
