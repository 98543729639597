import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';

export const getSubMenuItems = (customerData: any): ISubMenuItem[] => {
  const baseItems: ISubMenuItem[] = [
    {
      text: 'General',
      route: SingleCustomerRoutes.SingleCustomerGeneral.path,
    },
    {
      text: 'Quotations',
      route: SingleCustomerRoutes.SingleCustomerQuotations.path,
    },
    {
      text: 'Sales orders',
      route: SingleCustomerRoutes.SingleCustomerSales.path,
    },
    {
      text: 'Appointments',
      route: SingleCustomerRoutes.SingleCustomerAppointments.path,
    },
    {
      text: 'Reports',
      route: SingleCustomerRoutes.SingleCustomerReports.path,
    },
  ];

  if (customerData?.customer?.mollie_customer) {
    baseItems.push({
      text: 'Mollie mandates',
      route: SingleCustomerRoutes.SingleCustomerMollieMandates.path,
    });
  }

  return baseItems;
};
