import { H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  Heading,
  TabContainer,
  TabLabel,
} from '../EmailTemplatesPage.styled';
import { useDeactivateEmailTemplate } from '../hooks';
import { EmailTemplateDetails } from '../types';

interface IDefaultTabProps {
  details: EmailTemplateDetails;
  templateTypeId: number;
}
export const DefaultTab = ({ details, templateTypeId }: IDefaultTabProps) => {
  const { t } = useTranslation();
  const { mutate: deactivateEmailTemplate } = useDeactivateEmailTemplate();

  return (
    <TabContainer>
      <Heading>
        <H4>{t('Headline').toUpperCase()}</H4>
      </Heading>
      <TabLabel>{details.headline}</TabLabel>

      <Heading>
        <H4>{t('Body').toUpperCase()}</H4>
      </Heading>
      <TabLabel>{details.body}</TabLabel>

      {templateTypeId && (
        <ApplyWrapper>
          <Button
            onClick={() => deactivateEmailTemplate(templateTypeId)}
            primary
            width="200rem"
            label={t('Apply')}
          />
        </ApplyWrapper>
      )}
    </TabContainer>
  );
};
