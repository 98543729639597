import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCustomers } from 'services/Customer/CustomerService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { ICustomersPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';

export const useGetStatusOptions = () => {
  const statusOptions: Option[] = [
    { value: '', label: 'All', key: 'all' },
    { value: 'ACTIVE', label: 'Active', key: 'active' },
    { value: 'DELETED', label: 'Deleted', key: 'deleted' },
  ];
  return statusOptions;
};

export const useGetCustomers = (
  page: number,
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_CUSTOMERS,
      page,
      perPage,
      searchBy,
      isActive,
      sortBy,
      sortDirection,
      advancedFilters,
    ],
    queryFn: () => {
      return getCustomers(
        page,
        perPage,
        searchBy,
        isActive,
        sortBy,
        sortDirection,
        advancedFilters
      );
    },
  });

export const useGetCustomersPageFilters = () => {
  const dispatch = useDispatch();
  const customersPageFilters: ICustomersPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.customersPage
  );

  const statusOptions = useGetStatusOptions();

  if (!customersPageFilters) {
    const initialCustomersPageFilters: ICustomersPageFilters = {
      page: 1,
      searchBy: '',
      selectedStatusOption: statusOptions[1],
      sortBy: undefined,
      sortDirection: undefined,
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.CUSTOMERS,
        data: initialCustomersPageFilters,
      })
    );
    return {
      customersPageFilters: initialCustomersPageFilters,
      statusOptions,
    };
  }

  return {
    customersPageFilters,
    statusOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: ICustomersPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOption, setSelectedStatusOption] = useState<Option>(
    initialFilters.selectedStatusOption
  );
  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [searchBy, selectedStatusOption, sortBy, sortDirection]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<ICustomersPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      selectedStatusOption,
      sortBy,
      sortDirection,
    };
  }, [searchBy, selectedStatusOption, sortBy, sortDirection]);
  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.CUSTOMERS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  };
};
