import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addCommentForAppointment,
  getCommentsForAppointment,
} from 'services/Appointment/AppointmentService';

export const useGetCommentsForAppointment = (
  appointmentId: string,
  page?: number,
  perPage?: number,
  isEnabled?: boolean
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_APPOINTMENT_COMMENTS,
      appointmentId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCommentsForAppointment(appointmentId, page, perPage);
    },
    enabled: isEnabled === undefined ? !!appointmentId : isEnabled,
  });

interface IComment {
  headline: string;
  description: string;
  is_internal: boolean;
}

export const useAddAppointmentComment = (appointmentId: string) =>
  useMutation(
    (commentDTO: IComment) =>
      addCommentForAppointment(appointmentId, commentDTO),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_APPOINTMENT_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_APPOINTMENT_COMMENTS);
      },
      mutationKey: ReactMutationKeys.ADD_APPOINTMENT_COMMENT,
    }
  );
