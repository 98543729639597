import { marginLg, paddingLg, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 1000rem;
  padding: ${paddingLg};

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35rem;
  gap: 35rem;
  min-width: 1200rem;

  ${respondTo.midTablet`
    gap: 30rem;
    min-width: auto;
    width: 100%;
  `}
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 770rem;
  gap: 35rem;

  ${respondTo.midTablet`
    gap: 30rem;
    width: 60%;
  `}
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginLg};
  min-width: 1200rem;

  ${respondTo.bigTablet`
    margin-bottom: 0;
  `}

  ${respondTo.midTablet`
    min-width: auto;
    width: 100%;
    max-width: 100%;
  `}
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 770rem;
  gap: 35rem;

  ${respondTo.midTablet`
    gap: 30rem;
    width: 40%;
  `}
`;
