import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editQuotationLineCustomerDetails } from 'services/Quotation/QuotationService';
import { editSalesOrderLineCustomerDetails } from 'services/SalesOrder/SalesOrderService';
import { IEditCustomerDetailsDTO } from './constants';

export const useEditCustomerDetailsOnSalesOrder = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (customerDetailsDTO: IEditCustomerDetailsDTO) =>
      editSalesOrderLineCustomerDetails(salesOrderId, customerDetailsDTO),
    {
      onSuccess: () => {
        toast.success(t('Customer details edited'), {
          className: ReactMutationKeys.EDIT_SALES_ORDER_CUSTOMER_DETAILS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_CUSTOMER_DETAILS,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_CUSTOMER_DETAILS,
    }
  );
};

export const useEditCustomerDetailsOnQuotation = (quotationId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (customerDetailsDTO: IEditCustomerDetailsDTO) =>
      editQuotationLineCustomerDetails(quotationId, customerDetailsDTO),
    {
      onSuccess: () => {
        toast.success(t('Customer details edited'), {
          className: ReactMutationKeys.EDIT_QUOTATION_CUSTOMER_DETAILS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION,
          quotationId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_QUOTATION_CUSTOMER_DETAILS,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_QUOTATION_CUSTOMER_DETAILS,
    }
  );
};
