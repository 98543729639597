import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient from '../api/apiService';

const BASE_URL = '/v1/permissions/';

export const getPermissionsGroups = async (): Promise<any> => {
  const queryParamsObj = {};
  const url = `${BASE_URL}groups`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const updateUserPermissions = async (
  userPermissions: any
): Promise<any> => {
  const url = `${BASE_URL}edit-permissions-bulk`;
  const { data } = await apiClient.patch<any>(url, {
    updates: userPermissions,
  });

  return data.data;
};
