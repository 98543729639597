import { CSSProperties, useState } from 'react';
import { ClearIndicatorProps } from 'react-select';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { XCircle } from '@phosphor-icons/react';

const CustomClearIcon = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const fillColor = isHovered ? COLORS.RED_100 : COLORS.GREY_1000;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Icon
        svg={XCircle}
        color={fillColor}
        size={20}
        weight="bold"
        onClick={() => true}
      />
    </div>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const {
    children = <CustomClearIcon />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      {children}
    </div>
  );
};

export default ClearIndicator;
