import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SinglePurchaseOrderRoutes } from '../../../navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderGeneral.path,
  },
  {
    text: 'Products & Services',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderProducts.path,
  },
  {
    text: 'Comments',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderComments.path,
  },
  {
    text: 'Invoices',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderInvoices.path,
  },
  {
    text: 'Sales orders',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderSales.path,
  },
  {
    text: 'Files',
    route: SinglePurchaseOrderRoutes.SinglePurchaseOrderFiles.path,
  },
];

export enum PurchaseOrderStatusType {
  STATUS = 'STATUS',
  PAYMENT = 'PAYMENT',
  LINE_PAYMENT = 'LINE_PAYMENT',
  LINE_DELIVERY = 'LINE DELIVERY',
}

export enum PurchaseOrderStatus {
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  CANCELLED = 'CANCELLED',
}
