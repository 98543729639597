import { AppointmentType, IAppointment } from 'types/Appointment.types';
import { IQuotationLine } from 'types/Quotations.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';

export const canAddOnlyStockItems = (appointment: IAppointment) => {
  if (!appointment) {
    return true;
  }
  if (!appointment) {
    return true;
  }
  const isForSalesOrder = appointment.sales_order;
  if (!isForSalesOrder) {
    return false;
  }
  const appointmentTypeName = appointment.type.name;
  switch (appointmentTypeName) {
    case AppointmentType.MEASURING:
      return false;
    case AppointmentType.INSTALLATION:
      return true;
    case AppointmentType.SERVICE:
      return true;
    case AppointmentType.GENERAL:
      return false;
  }
};

// Determining if a unique property of ISalesOrderLine exists on line
export const isSalesOrderLine = (line: ISalesOrderLine | IQuotationLine) => {
  return 'is_linked_invoice' in line;
};
