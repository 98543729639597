import { COLORS, H3, H4 } from 'assets/styled';
import {
  ButtonContainer,
  Col,
  Container,
  Count,
  Number,
  NumberContainer,
  SelectInputWrapper,
  TitleContainer,
  TitleWrapper,
} from './QuotationsCard.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useGetQuotationsAnalytic } from '../hooks';
import { formatNumberCompact } from 'utils/numberFormatter';
import { RoutesConfig } from 'navigation/routes';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Cards } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import MoreButton from 'components/MoreButton/MoreButton';
import { IAnalyticsNavigationState } from '../types';

interface IQuotationsCardProps {
  dateOptions: Option[];
  selectedQuotations: Option;
  setSelectedQuotations: (quotation: Option) => void;
}

export const QuotationsCard = ({
  dateOptions,
  selectedQuotations,
  setSelectedQuotations,
}: IQuotationsCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const { data: quotations } = useGetQuotationsAnalytic(selectedQuotations);

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={Cards} size={30} color={COLORS.PRIMARY} />
          <H3>{t('Quotations')}</H3>
        </TitleWrapper>
        <SelectInputWrapper>
          <Select
            defaultValue={
              selectedQuotations ? selectedQuotations : dateOptions[0]
            }
            name="activeFilter"
            placeholder={''}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: Option) => setSelectedQuotations(e)}
            options={dateOptions}
          />
        </SelectInputWrapper>
      </TitleContainer>
      <NumberContainer>
        <Number>
          <H4>{t('TOTAL')}</H4>
          <Col>
            <Count>
              {quotations?.number_of_total_quotation === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(quotations?.number_of_total_quotation)
              )}
            </Count>
            <Count>
              {quotations?.total_value === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  quotations?.total_value
                )}`
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('OPEN')}</H4>
          <Col>
            <Count>
              {quotations?.number_of_open_quotation === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(quotations?.number_of_open_quotation)
              )}
            </Count>
            <Count>
              {quotations?.total_open_value === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  quotations?.total_open_value
                )}`
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('SCORED')}</H4>
          <Col>
            <Count>
              {quotations?.number_of_scored_quotation === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(quotations?.number_of_scored_quotation)
              )}
            </Count>
            <Count>
              {quotations?.total_scored_value === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  quotations?.total_scored_value
                )}`
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('TURNED DOWN')}</H4>
          <Col>
            <Count>
              {quotations?.number_of_turned_down_quotation === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(quotations?.number_of_turned_down_quotation)
              )}
            </Count>
            <Count>
              {quotations?.total_turned_down_value === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  quotations?.total_turned_down_value
                )}`
              )}
            </Count>
          </Col>
        </Number>
      </NumberContainer>
      <ButtonContainer
        onClick={() => {
          const analyticsNavigationState: IAnalyticsNavigationState = {
            dateIntervalOption: selectedQuotations,
          };
          navigate(RoutesConfig.Quotations.fullPath, {
            state: analyticsNavigationState,
          });
        }}
      >
        <MoreButton />
      </ButtonContainer>
    </Container>
  );
};
