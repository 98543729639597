import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'utils/toast';
import { markAsRead } from 'services/Notification/NotificationService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { IRootReducerState } from 'store/store';
import { INotificationType } from 'types/Notification.types';

export const useGetNotificationTitleFromNotificationType = (
  notificationTypeId: number
) => {
  const notificationTypes = useSelector(
    (state: IRootReducerState) => state.commonInfo.notificationTypes
  );
  if (!notificationTypes.length) {
    return '';
  }
  return notificationTypes.find(
    (notificationType: INotificationType) =>
      notificationType.id === notificationTypeId
  ).name;
};

export const useMarkAsRead = () =>
  useMutation((notificationId: number) => markAsRead(notificationId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.MARK_NOTIFICATION_AS_READ,
  });
