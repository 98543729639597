import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getQuotations } from 'services/Quotation/QuotationService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IQuotationsPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { useGetDateIntervalOptions } from 'utils/hooks/useGetDateIntervalOptions';
import { useGetQuotationStatuses } from 'utils/hooks/useGetQuotationStatuses';
import { IAnalyticsNavigationState } from '../AnalyticsPage/types';
import { useLocation } from 'react-router-dom';

export const useGetActiveFilterOptions = () => {
  const activeFilterOptions: Option[] = [
    { value: '', label: 'All', key: 'all' },
    { value: 'ACTIVE', label: 'Active', key: 'active' },
    { value: 'CLOSED', label: 'Closed', key: 'closed' },
  ];
  return activeFilterOptions;
};

export const useGetQuotationTypeOptions = () => {
  const quotationTypeOptions: Option[] = [
    { value: '', label: 'All', key: 'all' },
    {
      value: 'DRAFT',
      label: 'Draft',
      key: 'draft',
    },
    {
      value: 'DEFINITIVE',
      label: 'Definitive',
      key: 'definitive',
    },
  ];
  return quotationTypeOptions;
};

export const useGetQuotations = (
  page?: number,
  perPage?: number,
  quotationStatuses?: string,
  dateInterval?: string,
  activeFilter?: string,
  type?: string,
  searchBy?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_QUOTATIONS,
      page,
      perPage,
      quotationStatuses,
      dateInterval,
      activeFilter,
      type,
      searchBy,
      sortBy,
      sortDirection,
      advancedFilters,
    ],
    queryFn: () => {
      return getQuotations(
        page,
        perPage,
        quotationStatuses,
        dateInterval,
        activeFilter,
        type,
        searchBy,
        sortBy,
        sortDirection,
        advancedFilters
      );
    },
  });

export const useGetQuotationsPageFilters = () => {
  const dispatch = useDispatch();
  const quotationsPageFilters: IQuotationsPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.quotationsPage
  );
  const statusOptions: Option[] = useGetQuotationStatuses();
  const dateIntervalOptions = useGetDateIntervalOptions();
  const activeFilterOptions = useGetActiveFilterOptions();
  const quotationTypeOptions = useGetQuotationTypeOptions();

  if (!quotationsPageFilters) {
    const initialQuotationsPageFilters: IQuotationsPageFilters = {
      page: 1,
      searchBy: '',
      selectedStatusOptions: [],
      selectedDateIntervalOption:
        dateIntervalOptions[dateIntervalOptions.length - 1],
      sortBy: undefined,
      sortDirection: undefined,
      selectedActiveFilterOption: activeFilterOptions[1],
      selectedQuotationTypeOption: quotationTypeOptions[0],
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.QUOTATIONS,
        data: initialQuotationsPageFilters,
      })
    );
    return {
      quotationsPageFilters: initialQuotationsPageFilters,
      statusOptions,
      dateIntervalOptions,
      activeFilterOptions,
      quotationTypeOptions,
    };
  }

  return {
    quotationsPageFilters,
    statusOptions,
    dateIntervalOptions,
    activeFilterOptions,
    quotationTypeOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IQuotationsPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<Option[]>(
    initialFilters.selectedStatusOptions
  );
  const [selectedDateIntervalOption, setSelectedDateIntervalOption] =
    useState<Option>(initialFilters.selectedDateIntervalOption);
  const [selectedActiveFilterOption, setSelectedActiveFilterOption] =
    useState<Option>(initialFilters.selectedActiveFilterOption);
  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );
  const [selectedQuotationTypeOption, setSelectedQuotationTypeOption] =
    useState<Option>(initialFilters.selectedQuotationTypeOption);

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
    selectedActiveFilterOption,
    selectedQuotationTypeOption,
  ]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IQuotationsPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      selectedStatusOptions,
      selectedDateIntervalOption,
      sortBy,
      sortDirection,
      selectedActiveFilterOption,
      selectedQuotationTypeOption,
    };
  }, [
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
    selectedActiveFilterOption,
    selectedQuotationTypeOption,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.QUOTATIONS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    selectedActiveFilterOption,
    setSelectedActiveFilterOption,
    selectedQuotationTypeOption,
    setSelectedQuotationTypeOption,
  };
};

export const useHandleAnalyticsNavigationState = (
  setSelectedDateIntervalOption: Dispatch<SetStateAction<Option>>
) => {
  const { state }: { state: IAnalyticsNavigationState } = useLocation();
  useEffect(() => {
    if (state?.dateIntervalOption) {
      setSelectedDateIntervalOption(state.dateIntervalOption);
    }
  }, []);
};
