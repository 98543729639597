import * as Yup from 'yup';

export const createStockItemDetailsTabSchema = () => {
  return Yup.object().shape({
    price: Yup.number()
      .required('Sales price is required')
      .min(0, 'Sales price should be at least 0')
      .max(1000000, `Sales price can't be above 1 million`)
      .test(
        'twoDecimal',
        'Sales price can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      ),
    name: Yup.string()
      .required('Name is required')
      .max(50, 'Name should have max 50 characters'),
    purchase_price: Yup.number()
      .required('Purchase price is required')
      .typeError('Purchase price must be a number')
      .min(0, 'Purchase price should be at least 0')
      .max(1000000, `Purchase price can't be above 1 million`)
      .test(
        'twoDecimal',
        'Purchase price can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      ),
  });
};
