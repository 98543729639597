import styled, { css } from 'styled-components';
import { marginSm } from 'assets/styled';

interface StyledProps {
  top: string;
  right: string;
}

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
  ${(props: StyledProps) =>
    props.top &&
    css`
      top: ${props.top};
    `};
  ${(props: StyledProps) =>
    props.right &&
    css`
      right: ${props.right};
    `};
`;

export const AwaitingLogyxConfigurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150rem;
`;

export const AwaitingLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 60rem;
  height: 60rem;
  margin-top: ${marginSm};
`;
