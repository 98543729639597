import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
  InputWrapper,
  Container,
  InputContainer,
} from './FollowUpStatusModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/Modal/Modal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useCreateQuotationAction } from '../hooks';
import Input from 'components/Input/Input';
import { useParams } from 'react-router-dom';
import { ICreateQuotationActionData } from 'types/Quotations.types';
import { useGetQuotationActions } from '../../../../utils/hooks/useGetQuotationsActions';

interface IFollowUpStatusModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  description?: string;
  title: string;
}

export const FollowUpStatusModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  description,
  title,
}: IFollowUpStatusModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { mutate } = useCreateQuotationAction(id!);
  const actionOptions = useGetQuotationActions();
  const [selectedActionOption, setSelectedActionOption] =
    useState<Option | null>(null);
  const [customActionName, setCustomActionName] = useState<string>('');

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        margin: 'auto',
        position: 'fixed',
        overflow: 'visible',
      }}
    >
      <Container>
        <TopContainer>
          <CloseContainer>
            <Close
              onClick={() => {
                onCancel && onCancel();
              }}
            />
          </CloseContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TitleContainer>
            <Description>{description}</Description>
          </TitleContainer>
          <InputContainer>
            <InputWrapper>
              <Select
                name="action"
                placeholder={t('Action')}
                isMulti={false}
                isDisabled={false}
                isSearchable={false}
                onChange={(e: Option) => {
                  setSelectedActionOption(e);
                }}
                options={actionOptions}
              />
            </InputWrapper>
          </InputContainer>
          {selectedActionOption &&
            (selectedActionOption.label === 'Custom' ||
              selectedActionOption.label === 'Aangepast') && (
              <InputContainer>
                <InputWrapper>
                  <Input
                    maxLength={50}
                    onChange={(e: any) => {
                      setCustomActionName(e.target.value);
                    }}
                  />
                </InputWrapper>
              </InputContainer>
            )}
        </TopContainer>
        <ButtonContainer>
          <Margin>
            <Button
              onClick={() => {
                setSelectedActionOption(null);
                onCancel && onCancel();
              }}
              secondary
              width="200rem"
              label={t('Cancel')}
            />
          </Margin>
          <Button
            primary
            width="200rem"
            label={t('Confirm')}
            onClick={() => {
              if (!selectedActionOption) return;
              else {
                const createQuotationActionData: ICreateQuotationActionData = {
                  type_id: +selectedActionOption.id,
                  name: customActionName,
                };
                if (customActionName === '')
                  delete createQuotationActionData.name;
                mutate(createQuotationActionData);
                setSelectedActionOption(null);
                onCancel && onCancel();
              }
            }}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
