import ReactSelect from 'react-select';
import { Option } from './type';
import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';

interface StyledProps {
  isError: boolean;
  height: string;
  menuHeight: string;
}

export const selectStyles = css`
  .select__option {
    padding: 10rem 5rem;
    color: ${COLORS.GREY_800} !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${COLORS.GREY_300};
    }
    padding-left: 10rem;
  }

  .select__single-value {
    color: ${COLORS.BLACK};
  }

  .select__single-value--is-disabled {
    color: ${COLORS.GREY_1000} !important;
  }

  .select__input-container {
    color: ${COLORS.BLACK};
  }

  .select__option--is-selected {
    background-color: ${COLORS.GREY_300} !important;
  }

  .select__option--is-focused {
    background-color: ${COLORS.BACKGROUND_COLOR} !important;
  }

  .select__indicator {
    height: 41rem;
  }

  .select__menu {
    border-radius: 10rem !important;
  }

  .select__menu-list {
    background-color: ${COLORS.WHITE};
    border-radius: 10rem;
    border: 1px solid ${COLORS.GREY_1000};
    color: ${COLORS.GREY_100};
  }

  .select__control {
    min-height: 40rem;
    border: 1rem solid ${COLORS.GREY_800};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-radius: 8rem;
    background-color: ${COLORS.WHITE};
    color: ${COLORS.GREY_100};
    &:hover {
      background-color: ${COLORS.WHITE};
      border: 1rem solid ${COLORS.BLACK};
      box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
    }
  }

  .select__control--is-focused {
    border: 1rem solid ${COLORS.PRIMARY} !important;
    box-shadow: 0 0 0 0.5rem ${COLORS.PRIMARY} !important;
  }

  .select__control--is-disabled {
    cursor: not-allowed;
    pointer-events: all;
    background-color: ${COLORS.GREY_200};
    &:hover {
      background-color: ${COLORS.GREY_200};
      border-color: ${COLORS.GREY_1000};
      box-shadow: none;
    }
  }
`;

export const StyledReactSelect = styled(ReactSelect<Option, boolean>)`
  ${selectStyles}

  .select__menu-list {
    ${(props: StyledProps) =>
      props.menuHeight &&
      css`
        max-height: ${props.menuHeight};
      `};
  }

  .select__control {
    ${(props: StyledProps) =>
      props.height &&
      css`
        min-height: ${props.height};
      `};
    ${(props: StyledProps) =>
      props.isError
        ? css`
            border: 1rem solid ${COLORS.RED_100};
          `
        : css`
            border: 1rem solid ${COLORS.GREY_800};
          `}
  }
`;

export const Indicator = styled(`img`)`
  padding: 10rem 8rem;
`;

export const ErrorMessageWrapper = styled.div`
  min-height: 16rem;
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 3rem;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const RelativeDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
