import { COLORS, marginMd, marginSm } from 'assets/styled';
import { Field } from 'formik';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14rem; //30-16
`;

export const StyledSelect = styled(Field)`
  width: 300rem;
  /* margin-right: ${marginMd}; */
`;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
