import Icon from 'components/Icon/Icon';
import {
  CalendarIntegrationDiv,
  HeaderButtonWrapper,
} from './CalendarIntegration.styled';
import { ReactComponent as GoogleIcon } from 'assets/icons/google-icon.svg';
import { ReactComponent as GoogleCheckmarkIcon } from 'assets/icons/googleChecked-icon.svg';
import { ReactComponent as OutlookIcon } from 'assets/icons/outlook-icon.svg';
import { ReactComponent as OutlookCheckmarkIcon } from 'assets/icons/outlookChecked-icon.svg';
import {
  useAuthorizeGoogleAccount,
  useAuthorizeOutlook,
  useGetCalendarPreferences,
  useLogoutGoogleAccount,
  useLogoutOutlook,
  useShowCalendarPreferenceModal,
} from './hooks';
import { useEffect, useState } from 'react';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { useTranslation } from 'react-i18next';
import ManageCalendarPreferencesModal from './ManageCalendarPreferencesModal/ManageCalendarPreferencesModal';

export enum CalendarType {
  GOOGLE = 'GOOGLE',
  OUTLOOK = 'OUTLOOK',
}

const CalendarIntegration = () => {
  const [managePreferenceCalendarType, setManagePreferenceCalendarType] =
    useState<CalendarType | null>(null);
  const { t } = useTranslation();
  const [isGoogleSignOutModalOpen, setIsGoogleSignOutModalOpen] =
    useState<boolean>(false);

  const { data: authGoogleAccountData } = useAuthorizeGoogleAccount();
  const { data: authOutlookData } = useAuthorizeOutlook();

  const { mutate: logoutFromGoogle } = useLogoutGoogleAccount();
  const {
    mutate: logoutFromMicrosoft,
    isSuccess: microsoftLogoutSuccess,
    data: microsoftLogoutData,
  } = useLogoutOutlook();

  useEffect(() => {
    if (microsoftLogoutSuccess) {
      window.open(microsoftLogoutData?.redirect_url);
    }
  }, [microsoftLogoutSuccess]);

  const {
    isGoogleCustomCalendarPrefered,
    isOutlookCustomCalendarPrefered,
    isLoadingGoogleCalendarPreferences,
    isLoadingOutlookCalendarPreferences,
  } = useGetCalendarPreferences();

  const handleGoogleIconClick = () => {
    if (authGoogleAccountData?.auth_url) {
      window.open(authGoogleAccountData?.auth_url);
    } else if (authGoogleAccountData?.token) {
      setIsGoogleSignOutModalOpen(true);
    }
  };

  const handleOutlookIconClick = () => {
    if (authOutlookData?.auth_url) {
      window.open(authOutlookData?.auth_url);
    } else if (authOutlookData?.token) {
      logoutFromMicrosoft();
    }
  };

  useShowCalendarPreferenceModal(
    !!authGoogleAccountData?.token,
    !!authOutlookData?.token,
    isGoogleCustomCalendarPrefered,
    isOutlookCustomCalendarPrefered,
    setManagePreferenceCalendarType
  );

  return (
    <>
      <HeaderButtonWrapper>
        <CalendarIntegrationDiv>
          <Icon
            disabled={isLoadingGoogleCalendarPreferences}
            size={30}
            svg={
              authGoogleAccountData?.token ? GoogleCheckmarkIcon : GoogleIcon
            }
            onClick={() => handleGoogleIconClick()}
          />
          <Icon
            disabled={isLoadingOutlookCalendarPreferences}
            size={30}
            svg={authOutlookData?.token ? OutlookCheckmarkIcon : OutlookIcon}
            onClick={() => handleOutlookIconClick()}
          />
        </CalendarIntegrationDiv>
      </HeaderButtonWrapper>
      <YesOrNoModal
        setIsOpen={setIsGoogleSignOutModalOpen}
        isOpen={isGoogleSignOutModalOpen}
        onNo={() => setIsGoogleSignOutModalOpen(false)}
        onYes={() => {
          logoutFromGoogle();
          setIsGoogleSignOutModalOpen(false);
        }}
        title=""
        description={`${t(
          'Are you sure you want to sign out from Google account'
        )}?`}
      />
      <ManageCalendarPreferencesModal
        setIsOpen={setManagePreferenceCalendarType}
        isOpen={managePreferenceCalendarType}
        calendarType={managePreferenceCalendarType}
      />
    </>
  );
};

export default CalendarIntegration;
