import { useInfiniteQuery, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getProducts } from 'services/Product/ProductService';
import { getSuppliers } from 'services/Supplier/SupplierService';

export const useGetProducts = (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_PRODUCTS,
      page,
      perPage,
      searchBy,
      supplierId,
    ],
    queryFn: () => {
      return getProducts(page, perPage, searchBy, supplierId);
    },
    enabled: !!supplierId,
  });

export const useGetProductsInfinite = (
  perPage: number,
  searchBy: string,
  supplierId: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_PRODUCTS_INFINITE,
      perPage,
      searchBy,
      supplierId,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getProducts(pageParam, perPage, searchBy, supplierId),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    staleTime: 30000,
    enabled: !!supplierId,
  });

export const useGetSuppliersInfinite = (
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  sortBy?: string,
  sortDirection?: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_SUPPLIERS_INFINITE,
      perPage,
      searchBy,
      isActive,
      sortBy,
      sortDirection,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getSuppliers(
        pageParam,
        perPage,
        searchBy,
        isActive,
        sortBy,
        sortDirection
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });
