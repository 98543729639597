import { useNavigate } from 'react-router-dom';
import {
  ButtonsWrapper,
  Container,
  Header,
  SecondRow,
  SelectInputWrapper,
  TableWrapper,
  ViewTypeOptionLabel,
} from './FwAppointmentsListView.styled';
import { Dispatch, SetStateAction } from 'react';
import { FwAppointmentsViewType } from '../FwAppointmentsPage';
import CalendarDateIntervalHeader from 'components/Calendar/CalendarDateIntervalHeader';
import { Select } from 'components/Select/Select';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import moment, { Moment } from 'moment';
import { tableConfig } from './constants';
import Table from 'components/Table/Table';
import { IAppointment, IFwAppointmentTableDTO } from 'types/Appointment.types';
import { SortDirection } from 'components/Table/constants';
import Input from 'components/Input/Input';
import { formatFwAppointmentsTableData } from 'components/Table/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';

interface IFwAppointmentsListViewProps {
  appointments: IAppointment[];
  searchBy: string;
  setSearchBy: (value: string) => void;
  sortBy: string | undefined;
  setSortBy: Dispatch<SetStateAction<string | undefined>>;
  sortDirection: SortDirection | undefined;
  setSortDirection: Dispatch<SetStateAction<SortDirection | undefined>>;
  startDate: Moment;
  setStartDate: Dispatch<SetStateAction<Moment>>;
  endDate: Moment;
  setEndDate: Dispatch<SetStateAction<Moment>>;
  selectedAppointmentTypeOption: Option;
  setSelectedAppointmentTypeOption: Dispatch<SetStateAction<Option>>;
  setSelectedViewType: Dispatch<SetStateAction<FwAppointmentsViewType>>;
  appointmentTypeOptions: Option[];
}

const FwAppointmentsListView = ({
  appointments,
  searchBy,
  setSearchBy,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedAppointmentTypeOption,
  setSelectedAppointmentTypeOption,
  setSelectedViewType,
  appointmentTypeOptions,
}: IFwAppointmentsListViewProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewButtonClick = (rowData: IFwAppointmentTableDTO) => {
    navigate(`${RoutesConfig.FwAppointments.fullPath}/${rowData.navigationID}`);
  };

  let tableData: IFwAppointmentTableDTO[] = [];
  if (appointments?.length) {
    tableData = formatFwAppointmentsTableData(appointments);
  }

  return (
    <Container>
      <Header>
        <CalendarDateIntervalHeader
          startDate={startDate}
          endDate={endDate}
          next={() => {
            setStartDate(moment(startDate).add(1, 'week'));
            setEndDate(moment(endDate).add(1, 'week'));
          }}
          prev={() => {
            setStartDate(moment(startDate).subtract(1, 'week'));
            setEndDate(moment(endDate).subtract(1, 'week'));
          }}
        />

        <ButtonsWrapper>
          <SelectInputWrapper>
            <Select
              defaultValue={
                selectedAppointmentTypeOption
                  ? selectedAppointmentTypeOption
                  : appointmentTypeOptions[0]
              }
              name="type"
              placeholder={t('Type')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedAppointmentTypeOption(e)}
              options={appointmentTypeOptions}
            />
          </SelectInputWrapper>
          <ViewTypeOptionLabel
            onClick={() => setSelectedViewType(FwAppointmentsViewType.CALENDAR)}
          >
            {t('Calendar view')}
          </ViewTypeOptionLabel>
          <ViewTypeOptionLabel isSelected={true}>
            {t('List view')}
          </ViewTypeOptionLabel>
        </ButtonsWrapper>
      </Header>
      <SecondRow>
        <Input
          width={'450rem'}
          placeholder={t('Search')}
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        />
      </SecondRow>
      <TableWrapper>
        <Table
          page={-1}
          perPage={-1}
          total={-2}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={() => null}
          onViewButtonClick={handleViewButtonClick}
          sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        />
      </TableWrapper>
    </Container>
  );
};

export default FwAppointmentsListView;
