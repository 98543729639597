export enum CreateQuotationTab {
  CUSTOMER_DETAILS = 'customer',
  PRODUCTS = 'products',
  ORDER_DETAILS = 'order_details',
  OVERVIEW = 'overview',
}

export interface IGetCityAndAddressDTO {
  zipCode: string;
  houseNumber: string;
}
