import { COLORS, borderRadiusSm, marginMd, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const RowMarginTop = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const CustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  text-transform: uppercase;
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-top: ${marginMd};
  margin-bottom: ${marginSm};
`;

export const SpinnerWrapper = styled.div`
  margin-left: ${marginMd};
`;
