import moment, { Moment } from 'moment';
import { useQuery } from 'react-query';
import { getFwAppointmentsDaySummary } from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetDaySummaryAppointments = (dateFrom: Moment | null) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_DAY_SUMMARY_APPOINTMENTS,
      dateFrom?.valueOf(),
    ],
    queryFn: () => {
      return getFwAppointmentsDaySummary(dateFrom || moment());
    },
    enabled: !!dateFrom,
  });
