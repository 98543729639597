import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddCommentContainer,
  AuthorName,
  CloseContainer,
  CommentCardContainer,
  CommentDate,
  CommentDescription,
  CommentHeadline,
  CommentInfo,
  CommentType,
  Margin,
  Title,
  TitleContainer,
} from './CommentsModal.styled';
import moment from 'moment';
import Pagination from 'components/Table/Pagination/Pagination';
import { AddNewCommentModal } from '../AddNewCommentModal/AddNewCommentModal';
import { useGetCommentsForAppointment } from './hooks';
import Icon from 'components/Icon/Icon';
import { Plus, User } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const COMMENTS_PER_PAGE = 3;
export interface ICommentsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  appointmentId: string;
}

export const CommentsModal = ({
  setIsOpen,
  isOpen,
  onCancel,
  appointmentId,
}: ICommentsModalProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);

  const { data } = useGetCommentsForAppointment(
    appointmentId,
    page,
    COMMENTS_PER_PAGE,
    isOpen
  );
  const [isAddNewCommentModalOpen, setIsAddNewCommentModalOpen] =
    useState<boolean>(false);
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="SECOND"
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        padding: '30rem 20rem 20rem',
      }}
    >
      <CloseContainer data-testid="close-comments">
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Comments')}</Title>
      </TitleContainer>
      <AddCommentContainer>
        <Button
          label={t('Add another comment')}
          link
          icon={Plus}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          onClick={() => setIsAddNewCommentModalOpen(true)}
          fontSize="16rem"
        />
      </AddCommentContainer>
      {data?.comments.map((item: any, index: number) => {
        //TODO: fetch data from BE
        return (
          <CommentCardContainer key={index}>
            <CommentInfo>
              <CommentHeadline>{item?.headline}</CommentHeadline>
              <CommentType>
                {item?.is_internal ? t('Internal') : t('External')}
              </CommentType>
            </CommentInfo>
            <CommentInfo>
              <Icon svg={User} size={25} color={COLORS.PRIMARY} />
              <AuthorName>{`${item?.user?.name} ${item?.user?.last_name}`}</AuthorName>
              <CommentDate>
                {moment
                  .utc(item?.created_at)
                  .tz(moment.tz.guess())
                  .format('DD-MM-YYYY HH:mm')}
              </CommentDate>
            </CommentInfo>
            <CommentDescription>{item?.description}</CommentDescription>
          </CommentCardContainer>
        );
      })}
      <Margin />
      {data?.total > COMMENTS_PER_PAGE && (
        <Pagination
          page={page}
          perPage={COMMENTS_PER_PAGE}
          total={data?.total}
          loadPage={(newPage) => setPage(newPage)}
        />
      )}
      <AddNewCommentModal
        isOpen={isAddNewCommentModalOpen}
        setIsOpen={setIsAddNewCommentModalOpen}
        onCancel={() => setIsAddNewCommentModalOpen(false)}
        appointmentId={appointmentId}
      />
    </Modal>
  );
};
