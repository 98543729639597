import { useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { useAppTheme } from './hooks';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useLocale } from 'utils/hooks/useLocale';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/fr';
import 'moment/locale/sr';

export const AppThemeProvider = ({ children }: any) => {
  const theme = useAppTheme();
  const { locale } = useLocale();

  const localizationProviderProps = useMemo(
    () => ({
      dateAdapter: AdapterMoment,
      adapterLocale: locale || 'en',
    }),
    [locale, AdapterMoment]
  );

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider {...localizationProviderProps}>
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
