import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { IEditSalesOrderDetailsDTO } from '../constants';
import { editSalesOrderDetails } from 'services/SalesOrder/SalesOrderService';
import { toast } from 'utils/toast';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useEditSalesOrderDetails = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editSalesOrderDetailsDTO: IEditSalesOrderDetailsDTO) =>
      editSalesOrderDetails(salesOrderId, editSalesOrderDetailsDTO),
    {
      onSuccess: () => {
        toast.success(t('Order details edited'), {
          className: ReactMutationKeys.EDIT_SALES_ORDER_DETAILS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_SALES_ORDER,
          salesOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_DETAILS,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_DETAILS,
    }
  );
};
