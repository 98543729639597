import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

export const QUOTATION_FILES_PER_PAGE = 10;

export enum FilesPageViewType {
  BOX_VIEW = 'box_view',
  LIST_VIEW = 'list_view',
}

const rowConfigs: ITableRowConfig[] = [
  { header: 'Name', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1, isBlue: false },
  { header: 'Date', flexValue: 1, isBlue: false },
  { header: 'Added by', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};
