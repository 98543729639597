import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { getSelectedLanguageIcon } from './helpers';
import {
  LanguageImage,
  LanguageLabel,
  LanguageOption,
  LanguagesDropdown,
  Line,
  RelativeContainer,
} from './LanguageSwitcher.styled';
import RippleEffect from 'components/RippleEffect/RippleEffect';
import { changeLanguage } from 'utils/changeLanguage';

const lngs = {
  en: { nativeName: 'English' },
  nl: { nativeName: 'Nederlands' },
  sr: { nativeName: 'Srpski' },
  de: { nativeName: 'Deutsch' },
  no: { nativeName: 'Norsk' },
  fr: { nativeName: 'Français' },
};
const LanguageSwitcher = () => {
  const [isLanguagePickerShown, setIsLanguagePickerShown] = useState(false);
  const [selectedLanguageIcon, setSelectedLanguageIcon] = useState<string>(
    getSelectedLanguageIcon(i18n.resolvedLanguage || FALLBACK_I18N_LANG)
  );

  const languageSwitcherWrapperRef = useRef();
  useOutsideAlerter(languageSwitcherWrapperRef, () =>
    setIsLanguagePickerShown(false)
  );

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setSelectedLanguageIcon(getSelectedLanguageIcon(lng));
    };
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  return (
    <RelativeContainer ref={languageSwitcherWrapperRef}>
      <RippleEffect
        size={40}
        onClick={() => setIsLanguagePickerShown(!isLanguagePickerShown)}
      >
        <LanguageImage alt="" src={selectedLanguageIcon} />
      </RippleEffect>
      {isLanguagePickerShown && (
        <LanguagesDropdown>
          {Object.keys(lngs).map((lng, index) => (
            <Fragment key={lng}>
              <LanguageOption onClick={() => changeLanguage(lng as any)}>
                <LanguageImage
                  alt=""
                  src={getSelectedLanguageIcon(lng)}
                  onClick={() =>
                    setIsLanguagePickerShown(!isLanguagePickerShown)
                  }
                />
                <LanguageLabel
                  isSelectedLanguage={i18n.resolvedLanguage === lng}
                >
                  {lngs[lng].nativeName}
                </LanguageLabel>
              </LanguageOption>
              {index < Object.keys(lngs).length - 1 && <Line />}
            </Fragment>
          ))}
        </LanguagesDropdown>
      )}
    </RelativeContainer>
  );
};

export default LanguageSwitcher;
