import { COLORS, marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ProductsWrapper = styled.div`
  align-self: center;
  margin-top: ${marginMd};
  max-width: 100%;
  width: 100%;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;
