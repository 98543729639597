import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 785rem;
  padding-bottom: 50rem;
`;
export const ProfileCard = styled.div`
  width: 785rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
`;

export const H3 = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  padding-top: 50rem;
  padding-bottom: 50rem;
  color: ${COLORS.BLACK};
`;
export const ProfileImg = styled.img`
  position: relative;
  width: fit-content;
  max-width: 150rem;
  height: 150rem;
`;

export const ProfileImgWrapper = styled.div`
  width: 150rem;
  height: 150rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  position: absolute;
  width: 40rem;
  height: 40rem;
  left: 428rem;
  top: 30rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeadingWrapper = styled.div`
  width: 785rem;
  display: flex;
  align-self: left;
`;

export const ProfileCardImg = styled.div`
  position: relative;
  padding-top: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
