import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSinglePurchaseOrderInvoices } from 'services/PurchaseOrder/PurchaseOrderService';

export const useGetPurchaseOrderId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};

export const useGetSinglePurchaseOrderInvoices = (
  purchaseOrderId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_PURCHASE_ORDERS,
      purchaseOrderId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getSinglePurchaseOrderInvoices(purchaseOrderId, page, perPage);
    },
  });
