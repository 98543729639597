import { IQuotation, IQuotationLine } from 'types/Quotations.types';
import { QuotationStatuses } from '../constants';

export const getProductCardTitle = (quotationLine: IQuotationLine, t: any) => {
  const prefixString = `${quotationLine.quantity}x - `;
  if (quotationLine.working_hours_line) {
    return t('Working hours');
  } else if (quotationLine.stock_item_line) {
    return prefixString + quotationLine.product?.name;
  } else {
    return prefixString + quotationLine.product?.name;
  }
};

export const getProductCardType = (quotationLine: IQuotationLine, t: any) => {
  if (!quotationLine.working_hours_line) {
    if (quotationLine.stock_item_line) {
      return t('Stock item');
    } else {
      return t('Product');
    }
  }
};

export const canManipulateQuotationLines = (quotation?: IQuotation) => {
  if (!quotation) {
    return false;
  }

  const isQuotationActive = [
    QuotationStatuses.NEW.valueOf(),
    QuotationStatuses.IN_TREATMENT.valueOf(),
    QuotationStatuses.FOLLOW_UP.valueOf(),
    QuotationStatuses.PRICES_CHECK.valueOf(),
  ].includes(quotation.status.name);
  return isQuotationActive;
};
