import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getQuotationInfo } from 'services/Quotation/QuotationService';
import { getSalesInfo } from 'services/SalesOrder/SalesOrderService';
import { getPurchaseInfo } from 'services/PurchaseOrder/PurchaseOrderService';
import { getStockInfo } from 'services/StockItems/StockItemsService';
import { getSupplierInfo } from 'services/Supplier/SupplierService';
import { getCustomersInfo } from 'services/Customer/CustomerService';
import { getEmailsInfo } from 'services/Email/EmailService';
import { getResourcesInfo } from 'services/Resources/ResourcesService';
import { getWorkersInfo } from 'services/Workers/WorkersService';

export const useGetQuotationInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_QUOTATION_INFO,
    queryFn: () => {
      return getQuotationInfo();
    },
  });

export const useGetSalesInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_SALES_INFO,
    queryFn: () => {
      return getSalesInfo();
    },
  });

export const useGetPurchaseInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_PURCHASE_INFO,
    queryFn: () => {
      return getPurchaseInfo();
    },
  });

export const useGetStockInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_STOCK_INFO,
    queryFn: () => {
      return getStockInfo();
    },
  });

export const useGetCustomersInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_CUSTOMERS_INFO,
    queryFn: () => {
      return getCustomersInfo();
    },
  });

export const useGetSuppliersInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_SUPPLIERS_INFO,
    queryFn: () => {
      return getSupplierInfo();
    },
  });

export const useGetEmailsInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_EMAILS_INFO,
    queryFn: () => {
      return getEmailsInfo();
    },
  });

export const useGetResourcesInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_RESOURCES_INFO,
    queryFn: () => {
      return getResourcesInfo();
    },
  });

export const useGetWorkersInfo = () =>
  useQuery({
    queryKey: ReactQueryKeys.GET_WORKERS_INFO,
    queryFn: () => {
      return getWorkersInfo();
    },
  });
