import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Company', flexValue: 1, isBlue: true },
  { header: 'Address', flexValue: 1, isBlue: false },
  { header: 'Zip code', flexValue: 1, isBlue: false },
  { header: 'Phone Number', flexValue: 1.5, isBlue: false },
  { header: 'Total spent', flexValue: 1, isBlue: false },
  { header: 'Total orders', flexValue: 1, isBlue: true },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const SUPPLIERS_PER_PAGE = 10;
