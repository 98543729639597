import { Option } from 'components/Select/type';
import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Payment',
    value: 'payment',
    type: 'dropdown',
  },
  {
    label: 'Status',
    value: 'status',
    type: 'dropdown',
  },
  {
    label: 'Date',
    value: 'date',
    type: 'date',
  },
  {
    label: 'Reference',
    value: 'reference',
    type: 'string',
  },
  {
    label: 'Sales order',
    value: 'salesOrder',
    type: 'string',
  },
  {
    label: 'Company',
    value: 'company',
    type: 'dropdown',
  },
  {
    label: 'Amount',
    value: 'amount',
    type: 'string',
  },
  {
    label: 'Delivery Date',
    value: 'deliveryDate',
    type: 'date',
  },
];

export const useGetPurchaseOrderAdvancedFilters = (
  statusOptions: Option[],
  storePurchaseOrderPaymentStatuses: Option[]
) => {
  const payments = storePurchaseOrderPaymentStatuses.map((el) => {
    return { label: el.name, value: el.id };
  });
  const filterTypes = filterTypesOptions.map((filter) => {
    if (filter.value === 'status') {
      return { ...filter, options: statusOptions };
    } else if (filter.value === 'payment') {
      return { ...filter, options: payments };
    }
    return filter;
  });

  return {
    filterTypes,
  };
};
