import {
  useGetSingleQuotationHistory,
  useGetSingleQuotationParentQuotationHistory,
} from 'pages/Manager/SingleQuotation/SingleQuotationGeneralPage/hooks';
import { useGetSingleSalesOrderHistory } from '../hooks';
import { useGetSingleQuotation } from 'pages/Manager/SingleQuotation/hooks';
import { injectDataIntoHistoryItems } from 'pages/Manager/SingleQuotation/SingleQuotationGeneralPage/helpers';
import { ISalesOrder } from 'types/SalesOrders.types';
import { HistoryEventEntityTypeEnum } from 'components/CustomSwiper/CustomSwiper';

export const useGetHistoryItems = (salesOrder: ISalesOrder) => {
  const {
    data: salesOrderHistoryData,
    isFetched: isSalesOrderHistoryDataFetched,
  } = useGetSingleSalesOrderHistory(salesOrder?.id);

  const {
    data: quotationHistoryData,
    isFetched: isQuotationHistoryDataFetched,
  } = useGetSingleQuotationHistory(salesOrder?.quotation_id);

  const { data: quotation } = useGetSingleQuotation(salesOrder?.quotation_id);

  const {
    data: parentQuotationHistoryData,
    isFetched: isParentQuotationHistoryDataFetched,
  } = useGetSingleQuotationParentQuotationHistory(
    quotation?.parent_quotation?.id
  );

  const concatenateHistoryItems = () => {
    let historyItems: any = [];
    if (parentQuotationHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          quotation?.parent_quotation?.number,
          parentQuotationHistoryData?.history,
          HistoryEventEntityTypeEnum.QUOTATION
        )
      );
    }
    if (salesOrderHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          salesOrder?.number,
          salesOrderHistoryData?.history,
          HistoryEventEntityTypeEnum.SALES_ORDER
        )
      );
    }
    if (quotationHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          quotation?.number,
          quotationHistoryData?.history,
          HistoryEventEntityTypeEnum.QUOTATION
        )
      );
    }
    return historyItems;
  };
  let isFetchedHistoryItems =
    isSalesOrderHistoryDataFetched && isQuotationHistoryDataFetched;

  if (quotation?.parent_quotation?.id) {
    isFetchedHistoryItems =
      isFetchedHistoryItems && isParentQuotationHistoryDataFetched;
  }
  return { historyItems: concatenateHistoryItems(), isFetchedHistoryItems };
};
