import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  pointer-events: all;
  cursor: default;
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
`;

export const CurrentItemsLabel = styled.label`
  color: ${COLORS.PRIMARY};
`;

export const TotalLabel = styled.label`
  color: ${COLORS.BLACK};
`;
