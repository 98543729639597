import { DefaultAttachmentTypeId } from 'pages/Manager/SettingsPage/hooks';
import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/default-attachments';

export const getDefaultAttachmentMailFiles = async (): Promise<any> => {
  const url = `${BASE_URL}/`;
  const { data } = await apiClient.get(url);

  return data.data;
};

export const uploadDefaultAttachmentMailFile = async (
  defaultAttachmentTypeId: DefaultAttachmentTypeId,
  fileFormData: FormData
): Promise<any> => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = `${BASE_URL}/${defaultAttachmentTypeId}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deleteDefaultAttachmentMailFile = async (
  defaultAttachmentId: number
): Promise<any> => {
  const url = `${BASE_URL}/${defaultAttachmentId}`;
  const { data } = await apiClient.delete(url);

  return data.data;
};
