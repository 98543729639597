import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm } from 'assets/styled';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Subtitle = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 16rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20rem;
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginSm};
`;

export const CustomersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: scroll;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 10rem;
`;

export const CustomerWrapper = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  padding: 10rem;
  margin: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CodeLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;

  color: ${COLORS.PRIMARY};
  cursor: pointer;
  margin-right: 10rem;
`;
export const UsernameLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;

  color: ${COLORS.BLACK};
  cursor: pointer;
  margin-left: 10rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200rem;
`;

export const NoContentLabel = styled.label`
  width: 100%;
  margin-top: 40rem;
  margin-bottom: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;
