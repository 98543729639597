import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider as LibDndProvider } from 'react-dnd';

interface IDndProviderProps {
  children: React.ReactNode;
}

const DndProvider = ({ children }: IDndProviderProps) => {
  // simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
  const isTouchDevice = () => {
    if ('ontouchstart' in window) {
      return true;
    }
    return false;
  };

  // Assigning backend based on touch support on the device
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

  return <LibDndProvider backend={backendForDND}>{children}</LibDndProvider>;
};

export default DndProvider;
