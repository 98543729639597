import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/companies/';
const BASE_SETTINGS_URL = '/v1/settings/';

export const getCompany = async (companyId: string): Promise<any> => {
  const url = `${BASE_URL}${companyId}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getCompanySettings = async (): Promise<any> => {
  const url = `${BASE_SETTINGS_URL}`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getAllCompanyUsers = async (companyId: string): Promise<any> => {
  const url = `${BASE_URL}${companyId}/users`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getCompanyLogo = async (companyId: string): Promise<any> => {
  const url = `${BASE_URL}${companyId}/logo`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};
