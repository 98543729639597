import { SingleCustomerAppointmentsPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerAppointmentsPage/SingleCustomerAppointmentsPage';
import { SingleCustomerGeneralPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerGeneralPage/SingleCustomerGeneralPage';
import { SingleCustomerMollieMandatesPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerMollieMandatesPage/SingleCustomerMollieMandatesPage';
import { SingleCustomerQuotationsPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerQuotationsPage/SingleCustomerQuotationsPage';
import { SingleCustomerReportsPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerReportsPage/SingleCustomerReportsPage';
import { SingleCustomerSalesOrdersPage } from 'pages/Manager/SingleCustomerPage/SingleCustomerSalesOrdersPage/SingleCustomerSalesOrdersPage';
import { Route, Routes } from 'react-router-dom';
import { SingleCustomerRoutes } from './SingleCustomer.routes';

const SingleCustomerRouter = () => {
  return (
    <Routes>
      <Route
        path={SingleCustomerRoutes.SingleCustomerGeneral.path}
        element={<SingleCustomerGeneralPage />}
      />
      <Route
        path={SingleCustomerRoutes.SingleCustomerQuotations.path}
        element={<SingleCustomerQuotationsPage />}
      />
      <Route
        path={SingleCustomerRoutes.SingleCustomerSales.path}
        element={<SingleCustomerSalesOrdersPage />}
      />
      <Route
        path={SingleCustomerRoutes.SingleCustomerMollieMandates.path}
        element={<SingleCustomerMollieMandatesPage />}
      />
      <Route
        path={SingleCustomerRoutes.SingleCustomerAppointments.path}
        element={<SingleCustomerAppointmentsPage />}
      />
      <Route
        path={SingleCustomerRoutes.SingleCustomerReports.path}
        element={<SingleCustomerReportsPage />}
      />
    </Routes>
  );
};

export default SingleCustomerRouter;
