import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { getCityAndAddress } from 'services/Common/CommonService';
import { ICreateCustomerDTO, IGetCityAndAddressDTO } from './types';
import { useTranslation } from 'react-i18next';
import { createCustomer } from 'services/Customer/CustomerService';
import { toast } from 'utils/toast';

export const useGetCityAndAddress = () =>
  useMutation(
    (data: IGetCityAndAddressDTO) =>
      getCityAndAddress(data.zipCode, data.houseNumber),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        //Error
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
    }
  );

export const useCreateCustomer = () => {
  const { t } = useTranslation();
  return useMutation(
    (createCustomerData: ICreateCustomerDTO) =>
      createCustomer(createCustomerData),
    {
      onSuccess: (data) => {
        toast.success(t('Successfully added customer'), {
          toastId: ReactMutationKeys.CREATE_CUSTOMER,
          className: ReactMutationKeys.CREATE_CUSTOMER,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_CUSTOMER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_CUSTOMER,
    }
  );
};
