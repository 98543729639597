import {
  ITableAltConfig,
  ITableAltRowConfig,
} from 'components/TableAlt/TableAlt';

export const REPORTS_CURRENT_PER_PAGE = 10;
export const REPORTS_HISTORICAL_PER_PAGE = 10;

const rowConfigs: ITableAltRowConfig[] = [
  { header: 'Type', flexValue: 1 },
  { header: 'Creation date', flexValue: 1 },
  { header: 'Address', flexValue: 1 },
  { header: 'User', flexValue: 1 },
];

export const tableConfig: ITableAltConfig = {
  rowConfigs: rowConfigs,
};

export enum ReportsFilterType {
  CURRENT = 'Current',
  HISTORICAL = 'Historical',
}
