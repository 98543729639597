import {
  AppointmentType,
  IAppointment,
  IFwAppointmentLine,
} from 'types/Appointment.types';

export const getProductCardTitle = (
  appointmentLine: IFwAppointmentLine,
  t: any
) => {
  const prefixString = `${appointmentLine.quantity}x - `;
  if (appointmentLine.stock_item_line) {
    return prefixString + appointmentLine.product.name;
  } else if (appointmentLine.product) {
    return prefixString + appointmentLine.product.name;
  }
};

export const getProductCardType = (
  appointmentLine: IFwAppointmentLine,
  t: any
) => {
  if (appointmentLine.stock_item_line) {
    return t('Stock item');
  } else if (appointmentLine.product) {
    return t('Product');
  }
};

export const canEditAppointmentLine = (
  appointment: IAppointment,
  appointmentLine: IFwAppointmentLine
) => {
  if (!appointment) {
    return false;
  }
  const isForSalesOrder = appointment.sales_order;
  if (!isForSalesOrder) {
    return true;
  }
  const appointmentTypeName = appointment.type.name;

  switch (appointmentTypeName) {
    case AppointmentType.MEASURING:
      return true;
    case AppointmentType.INSTALLATION:
      return appointmentLine?.stock_item_line &&
        !appointmentLine?.original_sales_order_line_id
        ? true
        : false;
    case AppointmentType.SERVICE:
      return appointmentLine?.stock_item_line &&
        !appointmentLine?.original_sales_order_line_id
        ? true
        : false;
    case AppointmentType.GENERAL:
      return true;
  }
};

export const canDeleteAppointmentLine = (
  appointment: IAppointment,
  appointmentLine: IFwAppointmentLine
) => {
  if (!appointment) {
    return false;
  }
  const isForSalesOrder = appointment.sales_order;
  if (!isForSalesOrder) {
    return true;
  }
  const appointmentTypeName = appointment.type.name;
  switch (appointmentTypeName) {
    case AppointmentType.MEASURING:
      return true;
    case AppointmentType.INSTALLATION:
      return appointmentLine?.stock_item_line &&
        !appointmentLine?.original_sales_order_line_id
        ? true
        : false;
    case AppointmentType.SERVICE:
      return appointmentLine?.stock_item_line &&
        !appointmentLine?.original_sales_order_line_id
        ? true
        : false;
    case AppointmentType.GENERAL:
      return true;
  }
};
