import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  createGoogleCalendarPreference,
  createOutlookCalendarPreference,
} from 'services/Calendar/CalendarService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { CalendarType } from '../CalendarIntegration';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { toast } from 'utils/toast';

export interface ICreateGoogleCalendarPreference {
  name?: string;
  description?: string;
  time_zone?: string;
  custom_calendar_is_preferred?: boolean;
}

export const useCreateGoogleCalendarPreference = () => {
  const { t } = useTranslation();
  return useMutation(
    (createGoogleCalendarPreferenceDTO: ICreateGoogleCalendarPreference) =>
      createGoogleCalendarPreference(createGoogleCalendarPreferenceDTO),
    {
      onSuccess: () => {
        toast.success(
          t('Created {CALENDAR_TYPE} calendar preference').replace(
            '{CALENDAR_TYPE}',
            capitalizeFirstLetter(CalendarType.GOOGLE)
          ),
          {
            toastId: ReactMutationKeys.CREATE_GOOGLE_CALENDAR_PREFERENCE,
            className: ReactMutationKeys.CREATE_GOOGLE_CALENDAR_PREFERENCE,
          }
        );
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_GOOGLE_CALENDAR_PREFERENCES
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_GOOGLE_CALENDAR_PREFERENCE,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_GOOGLE_CALENDAR_PREFERENCE,
    }
  );
};

export interface ICreateOutlookCalendarPreference {
  name?: string;
  custom_calendar_is_preferred?: boolean;
}

export const useCreateOutlookCalendarPreference = () => {
  const { t } = useTranslation();
  return useMutation(
    (createOutlookCalendarPreferenceDTO: ICreateOutlookCalendarPreference) =>
      createOutlookCalendarPreference(createOutlookCalendarPreferenceDTO),
    {
      onSuccess: () => {
        toast.success(
          t('Created {CALENDAR_TYPE} calendar preference').replace(
            '{CALENDAR_TYPE}',
            capitalizeFirstLetter(CalendarType.OUTLOOK)
          ),
          {
            toastId: ReactMutationKeys.CREATE_OUTLOOK_CALENDAR_PREFERENCE,
            className: ReactMutationKeys.CREATE_OUTLOOK_CALENDAR_PREFERENCE,
          }
        );
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_OUTLOOK_CALENDAR_PREFERENCES
        );
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.message?.endsWith('has already been taken')
        ) {
          const calendarName = error?.response?.data?.message
            .replace('Name = ', '')
            .replace(' has already been taken', '');

          toast.error(
            t('Name = {CALENDAR_NAME} has already been taken').replace(
              '{CALENDAR_NAME}',
              calendarName
            ),
            {
              toastId: ReactMutationKeys.CREATE_OUTLOOK_CALENDAR_PREFERENCE,
            }
          );
        } else {
          toast.error(getToastErrorMessage(error), {
            toastId: ReactMutationKeys.CREATE_OUTLOOK_CALENDAR_PREFERENCE,
          });
        }
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_OUTLOOK_CALENDAR_PREFERENCE,
    }
  );
};
