import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { ISalesOrderLine } from 'types/SalesOrders.types';

const mapObjectFieldsIntoArray = (attributesObject: object) => {
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};

export const getInitialProductsData = (
  salesOrderLine: ISalesOrderLine | null
) => {
  if (salesOrderLine) {
    const productFormDTOObj: ICreateQuotationProductFormDTO = {
      supplier: salesOrderLine.supplier,
      product: salesOrderLine.stock_item_line
        ? {
            name: salesOrderLine.stock_item?.name,
          }
        : salesOrderLine.product,
      placement: salesOrderLine.placement,
      description: salesOrderLine.description,
      salesPrice: salesOrderLine.stock_item_line
        ? salesOrderLine.product_sales_price.toString()
        : salesOrderLine.product?.price
        ? salesOrderLine.product?.price
        : '',
      purchasePrice: salesOrderLine.stock_item_line
        ? salesOrderLine.product_purchase_price.toString()
        : salesOrderLine.product?.purchase_price
        ? salesOrderLine.product?.purchase_price
        : '',
      quantity: salesOrderLine.quantity,
      discount: salesOrderLine.discount.amount.toString(),
      discountType: salesOrderLine.discount.type,
      attributes: salesOrderLine.product?.attributes
        ? mapObjectFieldsIntoArray(salesOrderLine.product.attributes)
        : [],
      logyxConfigurationIntent: salesOrderLine.product
        ?.logyx_configuration_intent
        ? salesOrderLine.product?.logyx_configuration_intent
        : null,
      isStockItem: !!salesOrderLine.stock_item_line,
      stockItemId:
        salesOrderLine.stock_item_line && salesOrderLine.stock_item?.id
          ? Number(salesOrderLine.stock_item.id)
          : undefined,
      ...(salesOrderLine.stock_item_line && {
        stockItemType: salesOrderLine.stock_item?.type.name,
      }),
    };

    if (salesOrderLine.product?.attributes) {
      for (const addedAttributeKey of Object.keys(
        salesOrderLine.product.attributes
      )) {
        productFormDTOObj.product[addedAttributeKey] =
          salesOrderLine.product.attributes[addedAttributeKey];
      }
    }

    return [productFormDTOObj];
  } else {
    return [];
  }
};
