import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  align-items: center;
  color: ${COLORS.BLACK};
`;

export const DateIntervalLabel = styled.label`
  font-size: 18rem;
  line-height: 16rem;
  font-weight: 300;
  text-align: center;
`;

export const WeekNumber = styled.label`
  font-size: 18rem;
  color: ${COLORS.PRIMARY};
  font-weight: 300;
`;
