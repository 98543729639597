import { AppointmentType, IAppointment } from 'types/Appointment.types';

export const getAppointmentTypeLabel = (appointment: IAppointment) => {
  if (!appointment?.type?.name) {
    return 'Loading';
  }
  switch (appointment.type.name) {
    case AppointmentType.MEASURING:
      return 'Measurement';
    case AppointmentType.SERVICE:
      return 'Service';
    case AppointmentType.INSTALLATION:
      return 'Installation';
    case AppointmentType.GENERAL:
      return 'General';
  }
};
