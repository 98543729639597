import { marginSm } from 'assets/styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { CloseWrapper, Container } from '../ViewProductModal.styled';
import Input from 'components/Input/Input';
import { IViewProductProps } from '../type';

interface IPlacementTabProps {
  productLineData: IViewProductProps;
  onCancel: () => void;
}

export const PlacementTab = ({
  productLineData,
  onCancel,
}: IPlacementTabProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Input
        disabled
        height={'140rem'}
        isTextArea
        value={productLineData?.placement ? productLineData?.placement : ' '}
        placeholder={t('Placement')}
        wrapperStyles={{
          width: '530rem',
          marginTop: marginSm,
        }}
      />
      <CloseWrapper>
        <Button onClick={onCancel} primary width="200rem" label={t('Close')} />
      </CloseWrapper>
    </Container>
  );
};
