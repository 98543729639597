import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Option } from '../../components/Select/type';
import i18n from '../../providers/i18n/i18n';
import { IRootReducerState } from '../../store/store';
import { IQuotationAction } from '../../types/Quotations.types';
import { capitalizeFirstLetter } from '../stringUtils';

export const useGetQuotationActions = () => {
  const { t } = useTranslation();
  const storeQuotationActions = useSelector(
    (state: IRootReducerState) => state.commonInfo.quotationActions
  );

  const memoizedQuotationActions: Option[] = useMemo(() => {
    const quotationActions = storeQuotationActions?.map(
      (action: IQuotationAction) => {
        return {
          name: action.name,
          value: action.name,
          label: capitalizeFirstLetter(t(action.name)),
          id: action.id,
        };
      }
    );

    return quotationActions;
  }, [storeQuotationActions, i18n.resolvedLanguage]);

  return memoizedQuotationActions;
};
