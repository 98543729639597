import { marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1500rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TableWrapper = styled.div`
  align-self: center;
  max-width: 100%;
  width: 100%;
`;

export const NoContentLabel = styled.label`
  margin-top: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;
