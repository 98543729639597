import { EditableProfileUserDataType } from '../constants';

export const isButtonDisabled = (
  type: EditableProfileUserDataType,
  inputValue: string,
  currentPassword: string,
  newPassword: string,
  confirmPassword: string,
  inputErrorMessage: string,
  passwordErrorMessage: string
) => {
  return type === EditableProfileUserDataType.PASSWORD
    ? currentPassword === '' ||
        newPassword === '' ||
        confirmPassword === '' ||
        passwordErrorMessage !== ''
    : inputValue === '' || inputErrorMessage !== '';
};
