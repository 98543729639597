import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ISubmitReportDTO } from '../hooks';
import { toast } from 'utils/toast';
import { submitReport } from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useSubmitReport = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: ISubmitReportDTO) => {
      const formData = new FormData();
      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          formData.append(`files`, file);
        });
      }
      formData.append('file', params.file);
      formData.append('situation_description', params.situation_description);
      formData.append('signature_fullname', params.signature_fullname);
      formData.append('customer_comment', params.customer_comment);
      params.expected_working_hours &&
        formData.append(
          'expected_working_hours',
          params.expected_working_hours?.toString()
        );
      formData.append(
        'follow_up_action_description',
        params.follow_up_action_description.toString()
      );
      formData.append(
        'report_resources',
        JSON.stringify(params.report_resources)
      );
      return submitReport(appointmentId, formData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Report submitted successfully'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSettled: () => {
        // onSettled
      },
      mutationKey: ReactMutationKeys.SUBMIT_REPORT,
    }
  );
};
