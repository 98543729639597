import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Customer', flexValue: 1, isBlue: false },
  { header: 'Address', flexValue: 2, isBlue: false },
  { header: 'Phone number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1, isBlue: false },
  {
    header: 'Date',
    flexValue: 1,
    isBlue: false,
    alternativeSortField: 'date',
  },
  { header: 'Time slot', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};

export enum ACTIVE_FILTER {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}
