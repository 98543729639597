import { COLORS, H3, H4 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TitleContainer,
  ButtonContainer,
  TitleWrapper,
  NoPermissionMessage,
  NumberContainer,
  Number,
  Count,
  SpinnerWrapper,
} from './WideCard.styled';
import { useNavigate } from 'react-router';
import Icon from 'components/Icon/Icon';
import MoreButton from 'components/MoreButton/MoreButton';
import { formatNumberCompact } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';

interface IWideCard {
  title: string;
  icon: any;
  route?: string;
  permissionToSee?: boolean;
  data: { label: string; number: number; currencySymbol?: string }[];
  isLoading: boolean;
}
export const WideCard = ({
  title,
  icon,
  route,
  permissionToSee = true,
  data,
  isLoading,
}: IWideCard) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={icon} color={COLORS.PRIMARY} size={30} />
          <H3>{title}</H3>
        </TitleWrapper>
        {route && permissionToSee && (
          <ButtonContainer onClick={() => navigate(route)}>
            <MoreButton />
          </ButtonContainer>
        )}
      </TitleContainer>
      {permissionToSee ? (
        <NumberContainer>
          {data?.map((info: any, index: number) => {
            return (
              <Number key={`number-span-${index}`}>
                <H4>{info.label}</H4>
                {isLoading ? (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                ) : (
                  <Count>
                    {info.currencySymbol}
                    {formatNumberCompact(info.number)}
                  </Count>
                )}
              </Number>
            );
          })}
        </NumberContainer>
      ) : (
        <NoPermissionMessage>{t('No permission')}</NoPermissionMessage>
      )}
    </Container>
  );
};
