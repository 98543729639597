import { COLORS, marginMd, paddingXs } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
`;

export const AddCommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const CommentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.WHITE};
  border-bottom: 1rem solid ${COLORS.GREY_200};
  padding: ${paddingXs} 0;
`;

export const CommentInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;
  margin: 7rem 0;
`;

export const CommentType = styled.div`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  line-height: 27rem;
`;

export const CommentHeadline = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const CommentDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.GREY_1000};
`;

export const CommentDescription = styled.div`
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
`;

export const AuthorName = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.BLACK};
`;

export const Margin = styled.div`
  margin-top: ${marginMd};
`;
