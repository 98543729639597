import { COLORS, gapLg, marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
  gap: ${gapLg};
`;

export const TimelineCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
`;

export const BackgroundColorWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;

export const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;
