import { ICreateQuotationCustomerDetails } from './CustomerTab/constants';
import { ICreateQuotationOrderDetails } from './OrderTab/constants';
import { ICreateQuotationProductFormDTO } from './ProductTab/constants';
import { ICustomer } from 'types/Customer.types';
import { IQuotationLine, QuotationTypeIdsEnum } from 'types/Quotations.types';
import { DiscountType } from 'types/Discount.types';
import { Option } from 'components/Select/type';
import { ICompanySettings } from 'types/Company.types';

export const getInitialCustomerDetailsData = (
  prepopulateCustomerData: any,
  prepopulateQuotationData: any,
  initialCountryCCA2: string
): ICreateQuotationCustomerDetails => {
  if (prepopulateCustomerData) {
    return {
      existingCustomer: prepopulateCustomerData.customer,
      name: prepopulateCustomerData.customer.name,
      last_name: prepopulateCustomerData.customer.last_name,
      gender: prepopulateCustomerData.customer.gender,
      email: prepopulateCustomerData.customer.email,
      phone: prepopulateCustomerData.customer.phone,
      country: prepopulateCustomerData.customer.address.country.cca2,
      street: prepopulateCustomerData.customer.address.street,
      code: prepopulateCustomerData.customer.address.zip_code,
      city: prepopulateCustomerData.customer.address.city,
      house_number: prepopulateCustomerData.customer?.address?.house_number,
      company_name: prepopulateCustomerData.customer?.company_name,
      vat_number: prepopulateCustomerData.customer?.vat_number,
      title: prepopulateCustomerData.customer?.title,
    };
  } else if (prepopulateQuotationData) {
    return {
      existingCustomer: prepopulateQuotationData.customer,
      name: prepopulateQuotationData.customer?.name,
      last_name: prepopulateQuotationData.customer?.last_name,
      gender: prepopulateQuotationData.customer?.gender,
      email: prepopulateQuotationData.customer?.email,
      phone: prepopulateQuotationData.customer?.phone,
      country: prepopulateQuotationData.customer?.address.country.cca2,
      street: prepopulateQuotationData.customer?.address.street,
      code: prepopulateQuotationData.customer?.address.zip_code,
      city: prepopulateQuotationData.customer?.address.city,
      house_number: prepopulateQuotationData.customer?.address?.house_number,
      company_name: prepopulateQuotationData.customer?.company_name,
      vat_number: prepopulateQuotationData.customer?.vat_number,
      different_shipping_address:
        prepopulateQuotationData.different_shipping_address,
      shipping_data: {
        first_name: prepopulateQuotationData.shipping_data?.first_name,
        last_name: prepopulateQuotationData.shipping_data?.last_name,
        phone: prepopulateQuotationData.shipping_data?.phone,
        country: prepopulateQuotationData.shipping_data?.address.country.cca2,
        street: prepopulateQuotationData.shipping_data?.address.street,
        city: prepopulateQuotationData.shipping_data?.address.city,
        zip_code: prepopulateQuotationData.shipping_data?.address.zip_code,
        house_number:
          prepopulateQuotationData.shipping_data?.address.house_number,
        company_name: prepopulateQuotationData.shipping_data?.company_name,
        email: prepopulateQuotationData.shipping_data?.email,
        title: prepopulateQuotationData.shipping_data?.title,
      },
      isShippingDataFormShown:
        prepopulateQuotationData.different_shipping_address,
      title: prepopulateQuotationData.customer?.title,
    };
  } else {
    return {
      existingCustomer: null,
      name: '',
      gender: '',
      email: '',
      phone: '',
      country: initialCountryCCA2,
      street: '',
      code: '',
      last_name: '',
      city: '',
      house_number: '',
      company_name: '',
      vat_number: '',
      shipping_data: {
        first_name: '',
        last_name: '',
        phone: '',
        country: '',
        street: '',
        city: '',
        zip_code: '',
        house_number: '',
        company_name: '',
        email: '',
        title: '',
      },
      title: '',
    };
  }
};

const mapObjectFieldsIntoArray = (attributesObject: object) => {
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};

export const getInitialProductsData = (prepopulateQuotationData: any) => {
  if (prepopulateQuotationData) {
    return prepopulateQuotationData.quotation_lines
      .filter(
        (quotation_line: IQuotationLine) => !quotation_line.working_hours_line
      )
      .map((quotation_line: IQuotationLine) => {
        const productFormDTOObj: ICreateQuotationProductFormDTO = {
          supplier: quotation_line.supplier,
          product: quotation_line.stock_item_line
            ? {
                name: quotation_line.stock_item?.name,
              }
            : quotation_line.product,
          placement: quotation_line.placement,
          description: quotation_line.description,
          salesPrice: quotation_line.stock_item_line
            ? quotation_line.product_sales_price.toString()
            : quotation_line.product?.price,
          purchasePrice: quotation_line.stock_item_line
            ? quotation_line.product_purchase_price?.toString()
            : quotation_line.product?.purchase_price,
          quantity: quotation_line.quantity,
          discount: quotation_line.discount.amount.toString(),
          discountType: quotation_line.discount.type,
          attributes: quotation_line.product?.attributes
            ? mapObjectFieldsIntoArray(quotation_line.product.attributes)
            : [],
          logyxConfigurationIntent: quotation_line.product
            ?.logyx_configuration_intent
            ? quotation_line.product?.logyx_configuration_intent
            : null,
          isStockItem: !!quotation_line.stock_item_line,
          stockItemId:
            quotation_line.stock_item_line && quotation_line.stock_item?.id
              ? Number(quotation_line.stock_item.id)
              : undefined,
          ...(quotation_line.stock_item_line && {
            stockItemType: quotation_line.stock_item?.type.name,
          }),
        };

        if (quotation_line.product?.attributes) {
          for (const addedAttributeKey of Object.keys(
            quotation_line.product.attributes
          )) {
            productFormDTOObj.product[addedAttributeKey] =
              quotation_line.product.attributes[addedAttributeKey];
          }
        }

        return productFormDTOObj;
      });
  } else {
    return [];
  }
};

export const calculateInitialDownpayment = (
  totalSalesPrice: string,
  companySettings: ICompanySettings
) => {
  if (!companySettings) {
    return 0;
  }
  if (
    Number(totalSalesPrice) >
    Number(companySettings?.downpayment_threshold_amount)
  ) {
    return (
      (Number(totalSalesPrice) *
        Number(companySettings.downpayment_default_percentage)) /
      100
    );
  } else {
    return 0;
  }
};

export const getInitialOrderDetailsData = (
  prepopulateQuotationData: any,
  companySettings: ICompanySettings
): ICreateQuotationOrderDetails => {
  if (prepopulateQuotationData) {
    const quotationWorkingHourLine: IQuotationLine =
      prepopulateQuotationData.quotation_lines.find(
        (line: IQuotationLine) => line.working_hours_line
      );

    return {
      isDraft: false,
      paymentCondition: prepopulateQuotationData.payment_condition,
      reference: prepopulateQuotationData.reference,
      downPayment: prepopulateQuotationData.downpayment,
      daysValid: companySettings.days_valid_quotation.toString(),
      workingHours: quotationWorkingHourLine
        ? quotationWorkingHourLine.quantity
        : '0',
      workingHoursRate:
        quotationWorkingHourLine && quotationWorkingHourLine.working_hours_rate
          ? quotationWorkingHourLine.working_hours_rate.toString()
          : '0',
      workingHoursDiscount: quotationWorkingHourLine
        ? quotationWorkingHourLine.discount.amount.toString()
        : '0',
      workingHoursDiscountType:
        quotationWorkingHourLine && quotationWorkingHourLine.discount
          ? quotationWorkingHourLine.discount.type
          : DiscountType.PERCENTAGE,
      discount: prepopulateQuotationData.discount.amount,
      discountType: prepopulateQuotationData.discount.type,
    };
  } else {
    return {
      isDraft: companySettings.default_new_quotation_type_id
        ? Number(companySettings.default_new_quotation_type_id) ===
          QuotationTypeIdsEnum.DRAFT
        : true,
      paymentCondition: companySettings.payment_condition,
      reference: '',
      downPayment: '',
      daysValid: companySettings.days_valid_quotation.toString(),
      workingHours: '0',
      workingHoursRate: companySettings.working_hours_rate.toString(),
      workingHoursDiscount: '0',
      workingHoursDiscountType: DiscountType.PERCENTAGE,
      discount: '0',
      discountType: DiscountType.PERCENTAGE,
    };
  }
};

export const generateQuickQuotationCustomerDetails = (
  isShippingDataFormShown: boolean
) => {
  const genderOptions: Option[] = [
    { value: 'MALE', label: 'Male', key: 'male' },
    { value: 'FEMALE', label: 'Female', key: 'female' },
    { value: 'OTHER', label: 'Other', key: 'other' },
  ];

  const customerDetails: ICreateQuotationCustomerDetails = {
    existingCustomer: null,
    name: 'Sample quotation',
    last_name: 'Sample quotation',
    gender: genderOptions[0].value,
    email: 'sampleQuotation@email.com',
    country: 'NL',
    phone: '+3811231213',
    street: 'Sample quotation',
    code: '21000',
    city: 'Sample quotation',
    house_number: '1337',
    company_name: 'Sample quotation',
    vat_number: '211',
    title: 'Mr.',
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    isShippingDataFormShown,
  };
  return customerDetails;
};

export const generateExistingCustomerDetails = (
  customer: ICustomer,
  isShippingDataFormShown: boolean
) => {
  const customerDetails: ICreateQuotationCustomerDetails = {
    existingCustomer: customer,
    name: customer.name,
    last_name: customer.last_name,
    gender: customer.gender!,
    email: customer.email,
    country: customer.address.country.cca2,
    phone: customer.phone,
    street: customer.address.street,
    code: customer.address.zip_code,
    city: customer.address.city!,
    house_number: customer.address.house_number!,
    company_name: customer.company_name!,
    vat_number: customer.vat_number!,
    title: customer.title!,
    shipping_data: {
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      street: '',
      city: '',
      zip_code: '',
      house_number: '',
      company_name: '',
      email: '',
      title: '',
    },
    isShippingDataFormShown,
  };
  return customerDetails;
};
