import { H3 } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  SubmitRow,
  Margin,
  FirstRow,
} from './InvoiceDetailsTab.styled';
import Button from 'components/Button/Button';
import { Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { ICreateInvoiceDetails } from './constants';
import { Dispatch, SetStateAction } from 'react';
import { createInvoiceDetailsSchema } from './validation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Day } from 'assets/icons/day.svg';

interface IInvoiceDetailsTabProps {
  onForward: () => void;
  onBack: () => void;
  invoiceDetails: ICreateInvoiceDetails;
  setInvoiceDetails: Dispatch<SetStateAction<ICreateInvoiceDetails>>;
}

const InvoiceDetailsTab = ({
  onForward,
  onBack,
  invoiceDetails,
  setInvoiceDetails,
}: IInvoiceDetailsTabProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <FirstRow>
        <H3>{t('Invoice details')}</H3>
      </FirstRow>
      <Formik
        enableReinitialize
        initialValues={invoiceDetails}
        validationSchema={createInvoiceDetailsSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          setTouched,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId="external-reference-field"
                  errorMessage={
                    touched['externalId'] ? errors['externalId'] : ''
                  }
                  height={'41rem'}
                  name="externalId"
                  placeholder={t('External reference')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('externalId', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
                <Input
                  pwId="days-valid-field"
                  type="number"
                  icon={Day}
                  errorMessage={touched['daysValid'] ? errors['daysValid'] : ''}
                  height={'41rem'}
                  name="daysValid"
                  placeholder={t('Days valid')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('daysValid', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                    marginBottom: '14rem',
                  }}
                />
              </Row>
              <SubmitRow>
                <Button
                  width={'200rem'}
                  onClick={() => onBack()}
                  label={t('Back')}
                  secondary
                />
                <Margin>
                  <Button
                    width={'200rem'}
                    onClick={async () => {
                      const touched = {};
                      Object.keys(values).forEach((key) => {
                        touched[key] = true;
                      });
                      setTouched(touched); // Trigger validation for all fields
                      if (isValid) {
                        setInvoiceDetails(values);
                        onForward();
                      }
                    }}
                    label={t('Next')}
                    primary
                  />
                </Margin>
              </SubmitRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default InvoiceDetailsTab;
