import { FormikErrors, getIn } from 'formik';
import { IPurchaseOrderFormDTO } from '../constants';
import * as Yup from 'yup';

export const getFormikErrorMesssage = (
  index: number,
  values: IPurchaseOrderFormDTO,
  errors: FormikErrors<any>,
  getInString: string
) => {
  if (values.purchaseOrderLines[index].isSelected) {
    return errors.purchaseOrderLines && getIn(errors, getInString);
  } else return null;
};

export const createPurchaseOrderSchema = () => {
  return Yup.object().shape({
    purchaseOrderLines: Yup.array().of(
      Yup.object().shape({
        product: Yup.object().shape({
          purchase_price: Yup.string()
            .required('Purchase price is required')
            .test(
              'min-max',
              'Purchase price should be between 0 and 1 million',
              (value: any) => {
                const numericValue = parseFloat(String(value));
                return numericValue >= 0 && numericValue <= 1000000;
              }
            )
            .test(
              'twoDecimal',
              'Purchase price can only have two decimal places',
              (value) => {
                const numericValue = parseFloat(String(value));
                if (
                  typeof numericValue !== 'number' ||
                  !Number.isFinite(numericValue)
                ) {
                  return false;
                }
                const roundedValue = Number(numericValue.toFixed(2));
                return roundedValue === numericValue;
              }
            ),
        }),
      })
    ),
  });
};
