import {
  DocumentSelectWrapper,
  EnableFieldAfterLogyxConfigurationWrapper,
  InputWrapper,
  LeftPart,
  RightPart,
  RowAlignCenter,
  SelectWrapper,
  containerStyle,
} from './ProductAttribute.styled';
import { useState } from 'react';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
  ProductAttributeType,
} from 'types/Product.types';
import { Select } from 'components/Select/Select';
import {
  formatOptionsIntoDocVisibilityOptionEnumsArray,
  getDropdownProductAttributeOptions,
  useInitializeOptionsFromDocVisibilityOptions,
  useGetDropdownDefaultValue,
} from './helpers';
import Input from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Icon from 'components/Icon/Icon';
import { COLORS, marginSm } from 'assets/styled';
import Draggable from 'components/DragAndDrop/Draggable/Draggable';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { PencilSimple } from '@phosphor-icons/react';

interface IProductAttributeProps {
  isLogyxEnabledForProduct?: boolean;
  onEditAttribute: () => void;
  index: number;
  attribute: IProductAttribute;
  handleRemoveAttribute: () => void;
  onDragHover: (dragIndex: number, hoverIndex: number) => void;
  handleSetDocVisibilityOptionsForAttribute: (
    docVisibilityOptions: IDocVisibilityOptionEnum[],
    attributeIndex: number
  ) => void;
  handleSetShouldDisplayAfterLogyxConfigurationForAttribute: (
    boolean: boolean,
    attributeIndex: number
  ) => void;
}

const ProductAttribute = ({
  isLogyxEnabledForProduct,
  onEditAttribute,
  index,
  attribute,
  handleRemoveAttribute,
  onDragHover,
  handleSetDocVisibilityOptionsForAttribute,
  handleSetShouldDisplayAfterLogyxConfigurationForAttribute,
}: IProductAttributeProps) => {
  const { t } = useTranslation();
  const [isRemoveAttributeModalOpen, setIsRemoveAttributeModalOpen] =
    useState<boolean>(false);

  const dropdownDefaultValue = useGetDropdownDefaultValue(attribute);

  const [initiallySelectedDocVisibilityOptions, allDocVisibilityOptions] =
    useInitializeOptionsFromDocVisibilityOptions(
      attribute.docVisibilityOptions
    );

  return (
    <Draggable style={containerStyle} onDragHover={onDragHover} index={index}>
      <LeftPart>
        <RowAlignCenter>
          {attribute.type === ProductAttributeType.FIELD && (
            <InputWrapper>
              <Input
                value={attribute.defaultValue}
                placeholder={attribute.fieldName}
                width={'300rem'}
              />
            </InputWrapper>
          )}
          {attribute.type === ProductAttributeType.DROPDOWN && (
            <SelectWrapper>
              <Select
                key={JSON.stringify(dropdownDefaultValue)} // Force rerender react-select component
                defaultValue={dropdownDefaultValue}
                placeholder={attribute.fieldName}
                name={''}
                onChange={() => null}
                isOptionDisabled={() => true}
                options={
                  attribute?.options?.length
                    ? getDropdownProductAttributeOptions(attribute.options)
                    : []
                }
              />
            </SelectWrapper>
          )}
          <Icon
            svg={PencilSimple}
            color={COLORS.PRIMARY}
            size={20}
            marginLeft={marginSm}
            onClick={onEditAttribute}
          />
          <Icon
            svg={Trash}
            color={COLORS.RED_200}
            weight="regular"
            size={20}
            marginLeft={marginSm}
            onClick={() => setIsRemoveAttributeModalOpen(true)}
          />
        </RowAlignCenter>
        {isLogyxEnabledForProduct && (
          <EnableFieldAfterLogyxConfigurationWrapper>
            <Checkbox
              label={t('Enable field after Logyx configuration')}
              isChecked={attribute.shouldDisplayAfterLogyxConfiguration}
              onChange={() =>
                handleSetShouldDisplayAfterLogyxConfigurationForAttribute(
                  !attribute.shouldDisplayAfterLogyxConfiguration,
                  index
                )
              }
            />
          </EnableFieldAfterLogyxConfigurationWrapper>
        )}
      </LeftPart>
      <RightPart>
        <DocumentSelectWrapper>
          <Select
            key={JSON.stringify(initiallySelectedDocVisibilityOptions)}
            pwId={`document-select-${index}`}
            isMulti
            defaultValue={initiallySelectedDocVisibilityOptions}
            options={allDocVisibilityOptions}
            placeholder={t('Document select')}
            name="documentSelect"
            onChange={(selectedOptions: any) => {
              handleSetDocVisibilityOptionsForAttribute(
                formatOptionsIntoDocVisibilityOptionEnumsArray(selectedOptions),
                index
              );
            }}
          />
        </DocumentSelectWrapper>
      </RightPart>
      <Modal
        level={'THIRD'}
        isOpen={isRemoveAttributeModalOpen}
        setIsOpen={setIsRemoveAttributeModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              handleRemoveAttribute();
              setIsRemoveAttributeModalOpen(false);
            },
            text: 'Delete',
            disabled: false,
          }}
          cancel={{ onClick: () => setIsRemoveAttributeModalOpen(false) }}
          title={t('Remove default field')}
          description={
            t('Are you sure you want to delete this default field') + '?'
          }
        />
      </Modal>
    </Draggable>
  );
};

export default ProductAttribute;
