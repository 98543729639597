import { COLORS, borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';
import Button from '../../Button/Button';

export const QuotationCardContainer = styled.div`
  width: 100%;
  background: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.GREY_200};
  border-radius: 10rem;
  padding: 10rem;
  max-height: 350rem;
`;

export const QuotationCardTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10rem;
  gap: 10rem;
`;

export const SalesPersonName = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  color: ${COLORS.GREY_1000};
`;
export const QuotationCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const QuotationCardTitleText = styled.div`
  font-weight: 300;
  line-height: 24rem;
  color: ${COLORS.BLACK};
`;

export const FileType = styled.div`
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
  margin-bottom: 10rem;
`;

export const FileImage = styled.img`
  cursor: pointer;
  height: 100%;
  max-height: 129rem;
  width: fit-content;
  max-width: 100%;
  border-radius: ${borderRadiusSm};
`;

export const QuotationCardButton = styled(Button)`
  margin-top: auto;
  width: 100%;
`;

export const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;

  .react-pdf__Page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-pdf__Document {
    height: 100%;
  }
  .react-pdf__Page__canvas {
    max-height: 129rem;
    max-width: 80%;
  }
`;

export const FileImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FileDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16rem;
  margin-bottom: 10rem;
  color: ${COLORS.GREY_1000};
`;
