import { components } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { IconWrapper, Text, Wrapper } from './CustomOptionWithDelete.styled';

interface CustomOptionWithDeleteProps extends OptionProps<any> {
  onDeleteOption?: (option: any) => void;
}

const CustomOptionWithDelete = (props: CustomOptionWithDeleteProps) => {
  const handleDeleteClick = (e: any) => {
    e.stopPropagation();
    if (props.onDeleteOption) {
      props.onDeleteOption(props.data);
    }
  };

  return (
    <components.Option {...props}>
      <Wrapper>
        <Text>{props.data.label}</Text>
        <IconWrapper onClick={handleDeleteClick}>
          <Icon svg={Trash} color={COLORS.RED_100} weight="regular" size={20} />
        </IconWrapper>
      </Wrapper>
    </components.Option>
  );
};

export default CustomOptionWithDelete;
