import { marginLg } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${marginLg};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1000rem;
`;

export const AddNewProductLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  width: 770rem;
  text-align: left;
  margin-bottom: 30rem;
`;
