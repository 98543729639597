import { changeLanguage } from 'i18next';
import { useFetchUserInfo } from 'listeners/TokenChangedInDifferentTabListener/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setDarkMode,
  setIsLoadingScreenShown,
  setIsMobileApp,
  toggleSideMenu,
} from 'store/Common/actions/common';
import { setUserInfo } from 'store/User/actions/user';

interface IMobileAppUrlParamsListenerProps {
  children: React.ReactNode;
}

// Tokens are inserted using the injectJavascript prop from react native webview
const MobileAppUrlParamsListener = ({
  children,
}: IMobileAppUrlParamsListenerProps) => {
  const dispatch = useDispatch();
  const { mutate: fetchUserInfo, data } = useFetchUserInfo();

  useEffect(() => {
    if (data) {
      dispatch(setUserInfo(data.user));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isMobileApp = searchParams.get('isMobileApp');
    const isDarkMode = searchParams.get('darkMode');
    const lang = searchParams.get('lang');

    if (isMobileApp) {
      dispatch(setIsMobileApp(true));
      dispatch(toggleSideMenu(true));
      dispatch(setIsLoadingScreenShown(true));
      lang && changeLanguage(lang);
      isDarkMode && dispatch(setDarkMode(true));
      fetchUserInfo();
    }
  }, [dispatch]);

  return <>{children}</>;
};

export default MobileAppUrlParamsListener;
