import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  changeSalesInvoiceExternalId,
  changeSalesInvoiceStatus,
  getSingleSalesInvoice,
} from 'services/Invoice/SalesInvoiceService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';
import { queryClient } from 'index';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGetSingleSalesCreditInvoice = (invoiceId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_SALES_CREDIT_INVOICE, invoiceId],
    queryFn: () => {
      return getSingleSalesInvoice(invoiceId);
    },
    refetchInterval: 7000,
  });

export const useChangeCreditSalesInvoiceExternalId = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: { id: string; external_id: string }) =>
      changeSalesInvoiceExternalId(params.id, params.external_id),
    {
      onSuccess: (data) => {
        toast.success(t('External reference edited'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_CREDIT_INVOICE
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_CREDIT_SALES_INVOICE_REFERENCE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.CHANGE_SALES_INVOICE_REFERENCE,
    }
  );
};

export const useChangeSalesCreditInvoiceStatus = () =>
  useMutation(
    (params: { id: string; status_id: string }) =>
      changeSalesInvoiceStatus(params.id, params.status_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_SALES_INVOICE_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_CREDIT_INVOICE
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
      },
      mutationKey: ReactMutationKeys.CHANGE_SALES_INVOICE_STATUS,
    }
  );
