import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFieldWorkerView } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';

interface IViewSwitcherProviderProps {
  children: React.ReactNode;
}

export enum LicenseTypeIds {
  OFFICE_WORKER = 1,
  FIELD_WORKER = 2,
}

const ViewSwitcherProvider = ({ children }: IViewSwitcherProviderProps) => {
  const dispatch = useDispatch();
  const { license } = useSelector((state: IRootReducerState) => state.userInfo);
  const { isFieldWorkerView } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  useEffect(() => {
    if (license?.id === LicenseTypeIds.FIELD_WORKER && !isFieldWorkerView) {
      dispatch(setIsFieldWorkerView(true));
    }
  }, [license?.id]);

  return <>{children}</>;
};

export default ViewSwitcherProvider;
