import Button from 'components/Button/Button';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Container, DescriptionLabel } from './OkModal.styled';
import { Level } from '../type';

export interface IOkModalProps {
  onClick: any;
  description: string;
  buttonLabel: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  level: Level;
}

export function OkModal({
  onClick,
  description,
  buttonLabel,
  isOpen,
  setIsOpen,
  level,
}: IOkModalProps) {
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && isOpen) {
        onClick && onClick();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isOpen]);

  const { t } = useTranslation();
  return (
    <Modal
      level={level}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed' }} // Center positioning
      onBackDropClick={() => setIsOpen(false)}
    >
      <Container>
        <DescriptionLabel>{t(description)}</DescriptionLabel>
        <Button
          label={t(buttonLabel)}
          primary
          width="200rem"
          onClick={onClick}
        />
      </Container>
    </Modal>
  );
}
