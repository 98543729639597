import {
  CircleInner,
  CircleOuter,
  CircleWhite,
  HalfCircle,
} from './Loader.styled';

interface ILoaderProps {
  positionType?: string;
}

const Loader = ({ positionType = 'fixed' }: ILoaderProps) => {
  return (
    <CircleOuter positionType={positionType}>
      <CircleWhite>
        <CircleInner />
        <HalfCircle />
      </CircleWhite>
    </CircleOuter>
  );
};

export default Loader;
