import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isDragActive: boolean;
  isFileRejected: boolean;
}

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  border-radius: 10rem;
  border: 1rem solid ${COLORS.GREY_1000};
  cursor: pointer;
  ${(props: StyledProps) =>
    props.isDragActive &&
    css`
      border-color: ${COLORS.PRIMARY};
      background-color: ${COLORS.BACKGROUND_COLOR};
    `}

  ${(props: StyledProps) =>
    props.isFileRejected &&
    css`
      border-color: ${COLORS.RED_100};
    `}
`;

export const DragText = styled.span`
  color: ${COLORS.BLACK};
  font-size: 14rem;
  display: block;
  padding: 5rem 0;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DragTextBlue = styled(DragText)`
  color: ${COLORS.PRIMARY};
  font-size: 14rem;
  margin-left: 5rem;
`;

export const FileName = styled.div`
  width: 100%;
  padding: 5rem 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;

export const FileWrapper = styled.div`
  max-height: 150rem;
  padding: 5rem 0;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  border: 1rem solid ${COLORS.GREY_1000};
  margin-top: 15rem;
`;

export const IconWrapper = styled.div``;
