import { Option } from 'components/Select/type';

export const useGetQuickFieldOptions = () => {
  const quickFieldOptions: Option[] = [
    { value: 'Width', label: 'Width', key: 'Width' },
    { value: 'Height', label: 'Height', key: 'Height' },
    { value: 'Color', label: 'Color', key: 'Color' },
    { value: 'Depth', label: 'Depth', key: 'Depth' },
  ];
  return quickFieldOptions;
};
