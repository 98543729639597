import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { deleteProduct } from 'services/Product/ProductService';

export const useDeleteProduct = () => {
  const { t } = useTranslation();
  return useMutation((productId: string) => deleteProduct(productId), {
    onSuccess: () => {
      toast.success(t('Product deleted'));
      queryClient.invalidateQueries([ReactQueryKeys.GET_PRODUCTS]);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_PRODUCT,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.DELETE_PRODUCT,
  });
};
