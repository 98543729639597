import styled, { css } from 'styled-components';
import { COLORS, marginMd } from 'assets/styled';

interface StyledProps {
  isDraft: boolean;
}

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${marginMd};
`;

export const IsDraftLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.GREY_700};
  ${(props: StyledProps) =>
    !props.isDraft &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;
