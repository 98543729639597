import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/numberFormatter';

export const formatInvoicesTableData = (
  invoiceData: any[],
  currencySymbol: string
) => {
  const { t } = useTranslation();
  return invoiceData?.map((invoice: any) => {
    const invoiceTableDTO: any = {
      number: invoice?.invoice_number,
      customer: invoice?.customer
        ? `${invoice?.customer.name} ${invoice?.customer.last_name}`
        : '',
      customerNumber: invoice?.customer?.number,
      reference: invoice?.external_id,
      status: t(invoice?.payment_status.name),
      openAmount: `${currencySymbol}${formatNumber(invoice?.open_amount)}`,
      invoiceId: invoice?.id,
      invoiceOpenAmount: invoice?.open_amount,
    };
    return invoiceTableDTO;
  });
};
