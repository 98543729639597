import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'utils/toast';
import { login } from 'services/User/UserService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useTranslation } from 'react-i18next';

interface IUseLoginParams {
  email: string;
  password: string;
}
export const useLogin = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseLoginParams) => login(params.email, params.password),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(t(getToastErrorMessage(error)));
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.LOGIN,
    }
  );
};
