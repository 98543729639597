import {
  PurchaseOrderStatus,
  PurchaseOrderStatusType,
  subMenuItems,
} from './constants';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import SinglePurchaseOrderRouter from 'navigation/SinglePurchaseOrder/SinglePurchaseOrderRouter';
import {
  TitleContainer,
  ButtonContainer,
  PenImg,
  P,
  H3,
  Wrapper,
  LeftSide,
  Container,
  IconContainer,
  StatusContainer,
  Option,
  StatusOptionsContainer,
  HeaderWrapper,
  RouterWrapper,
  LoaderWrapper,
  DirectOrderButton,
} from './SinglePurchaseOrderPage.styled';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogyxLogo } from 'assets/icons/logyxLogoSmall.svg';
import {
  useGetSinglePurchaseOrder,
  useChangePurchaseStatus,
  useExportPurchaseOrder,
  useSendPurchaseOrderEmail,
  useGetSinglePurchaseOrderInfo,
} from './hooks';
import { useState, useEffect, useRef } from 'react';
import { SinglePurchaseOrderRoutes } from 'navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';
import Tooltip from 'components/Tooltip/Tooltip';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { useGetPurchaseOrderStatuses } from 'utils/hooks/useGetPurchaseOrderStatuses';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { PurchaseOrderConfirmationModal } from 'components/Modal/PurchaseOrderConfirmationModal/PurchaseOrderConfirmationModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import Icon from 'components/Icon/Icon';
import { COLORS, marginSm, marginXs } from 'assets/styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Spinner from 'components/Spinner/Spinner';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';
import {
  Cardholder,
  CaretLeft,
  ChatCircle,
  ChatCircleDots,
  DownloadSimple,
  PencilSimple,
} from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SinglePurchaseOrderPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isError: isErrorPurchaseOrder } = useGetSinglePurchaseOrder(
    id!
  );
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] =
    useState<boolean>(false);
  const [
    isPurchaseOrderConfirmationModalOpen,
    setIsPurchaseOrderConfirmationModalOpen,
  ] = useState<boolean>(false);
  const [isDirectOrderModalOpen, setIsDirectOrderModalOpen] =
    useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const canEditPurchaseOrder = useCan(Actions.EDIT_PURCHASE_ORDER);

  const { mutate, isSuccess } = useChangePurchaseStatus();
  const { mutate: sendPurchaseOrderEmail } = useSendPurchaseOrderEmail(id!);

  const options = useGetPurchaseOrderStatuses(PurchaseOrderStatusType.STATUS);

  useEffect(() => {
    setIsStatusDropdownOpen(false);
  }, [isSuccess]);

  const { mutate: exportPurchaseOrder, isLoading: isLoadingExport } =
    useExportPurchaseOrder();

  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditPurchaseOrder && setIsStatusDropdownOpen(false)
  );

  const { data: infoData } = useGetSinglePurchaseOrderInfo(id!);

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );

  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  const { isTablet } = useBreakpointFlag();

  return !isErrorPurchaseOrder ? (
    <Container>
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(
          infoData?.purchase_order_info
        )}
      />
      <Wrapper>
        <HeaderWrapper
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                weight="regular"
                size={15}
                color={COLORS.BLACK}
                onClick={() => navigate(RoutesConfig.PurchaseOrders.fullPath)}
              />
              <H3>
                {t('Purchase Order')} {data?.number}
              </H3>
              <StatusContainer ref={statusContainerWrapperRef}>
                <P
                  onClick={() =>
                    canEditPurchaseOrder &&
                    setIsStatusDropdownOpen(!isStatusDropdownOpen)
                  }
                >
                  {capitalizeFirstLetter(t(data?.status?.name))}
                </P>
                {canEditPurchaseOrder && (
                  <PenImg>
                    <Icon
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={15}
                      onClick={() =>
                        setIsStatusDropdownOpen(!isStatusDropdownOpen)
                      }
                    />
                  </PenImg>
                )}
                {isStatusDropdownOpen && (
                  <StatusOptionsContainer>
                    {options.map((option) => {
                      return (
                        <Option
                          isSelected={option.name === data?.status?.name}
                          key={option.value}
                          onClick={() =>
                            mutate({
                              id: data?.id || '',
                              status_id: option.id,
                            })
                          }
                        >
                          {capitalizeFirstLetter(t(option.label))}
                        </Option>
                      );
                    })}
                  </StatusOptionsContainer>
                )}
              </StatusContainer>

              <Tooltip
                content={t(data?.payment_status?.name)}
                key={'paymentKey'}
              >
                <Icon
                  svg={Cardholder}
                  color={COLORS.PRIMARY}
                  size={25}
                  marginLeft={marginSm}
                  marginRight={marginSm}
                  pointer={false}
                />
              </Tooltip>
            </LeftSide>

            <IconContainer>
              <Tooltip content={t('Open PDF')} key={'emailKey'}>
                {isLoadingExport ? (
                  <LoaderWrapper>
                    <Spinner size={20} />
                  </LoaderWrapper>
                ) : (
                  <Icon
                    data-testid="download-pdf"
                    svg={DownloadSimple}
                    size={25}
                    color={COLORS.PRIMARY}
                    marginRight={marginXs}
                    marginLeft={marginXs}
                    onClick={() => exportPurchaseOrder(data?.id)}
                  />
                )}
              </Tooltip>
              {/* <MailImg
              src={data?.sent_to_supplier ? MailSent : Mail}
              mailSent={data?.sent_to_supplier}
              alt=""
              onClick={() => setIsSendEmailModalOpen(true)}
            /> */}
              {/* <PhoneImg src={Phone} alt="" /> */}
              <Tooltip content={t('Add comment')} key={'commentKey'}>
                <Icon
                  data-testid="add-comment"
                  size={25}
                  color={COLORS.PRIMARY}
                  marginRight={marginXs}
                  marginLeft={marginXs}
                  svg={
                    !infoData?.purchase_order_info?.comment_count
                      ? ChatCircle
                      : ChatCircleDots
                  }
                  onClick={() => {
                    navigate(
                      SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment
                        .path
                    );
                  }}
                />
              </Tooltip>
            </IconContainer>
          </TitleContainer>
          <ButtonContainer>
            <Tooltip
              content={
                data?.logyx_connection_in_progress ||
                data?.status?.name !== PurchaseOrderStatus.NEW
                  ? t('Direct order already exists')
                  : null
              }
            >
              {data?.digital_order_enabled && (
                <DirectOrderButton
                  onClick={() => setIsDirectOrderModalOpen(true)}
                  label={t('Direct order')}
                  width="200rem"
                  greenOutline
                  icon={LogyxLogo}
                  iconStyles={{ width: '18rem', height: '18rem' }}
                  disabled={
                    data?.logyx_connection_in_progress ||
                    data?.status?.name !== PurchaseOrderStatus.NEW
                  }
                />
              )}
            </Tooltip>
            <Button
              onClick={() => setIsPurchaseOrderConfirmationModalOpen(true)}
              disabled={!data || !canEditPurchaseOrder}
              label={t('Order confirmation')}
              width="200rem"
              secondary
            />
            <Button
              onClick={() => {
                navigate(
                  SinglePurchaseOrderRoutes.SinglePurchaseOrderNewInvoice.path,
                  {
                    state: { prepopulateInvoiceData: data },
                  }
                );
              }}
              disabled={!data || !canEditPurchaseOrder}
              label={t('Add invoice')}
              width="200rem"
              secondary
            />
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper routerWithoutPaddingBottom={routerWithoutPaddingBottom}>
          <SinglePurchaseOrderRouter />
        </RouterWrapper>
      </Wrapper>
      {data && (
        <PurchaseOrderConfirmationModal
          purchaseOrder={data}
          isOpen={isPurchaseOrderConfirmationModalOpen}
          setIsOpen={setIsPurchaseOrderConfirmationModalOpen}
          onCancel={() => setIsPurchaseOrderConfirmationModalOpen(false)}
        />
      )}
      <ConfirmModal
        isOpen={isDirectOrderModalOpen}
        setIsOpen={setIsDirectOrderModalOpen}
        onConfirm={() => {
          navigate(
            SinglePurchaseOrderRoutes.SinglePurchaseOrderDirectLogyxOrder.path,
            {
              state: { initialPurchaseLines: data?.purchase_order_lines },
            }
          );
          setIsDirectOrderModalOpen(false);
        }}
        onCancel={() => setIsDirectOrderModalOpen(false)}
        title={t('Place Direct Order')}
        description={t('Are you sure you want to place the order') + '?'}
      />
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          sendPurchaseOrderEmail(id!);
          setIsSendEmailModalOpen(false);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t(
            'Are you sure you want to send this Purchase order via email to the supplier'
          ) + '?'
        }
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SinglePurchaseOrderPage;
