import moment from 'moment';

export const formatTableData = (data: any[]) => {
  return data?.map((mandate: any) => {
    const tableDTO: any = {
      creation_date: moment(mandate.date_created).format('DD-MM-YYYY'),
      method: mandate.method,
      enabled: mandate.enabled,
      id: mandate.id,
    };
    return tableDTO;
  });
};
