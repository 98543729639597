import { formatQuotationsTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { RoutesConfig } from 'navigation/routes';
import {
  QUOTATIONS_PER_PAGE,
  tableConfig,
} from 'pages/Manager/QuotationsPage/constants';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IQuotation, IQuotationTableDTO } from 'types/Quotations.types';
import { useGetSingleSalesOrderQuotations } from '../hooks';
import {
  Container,
  LoaderWrapper,
  NoContentLabel,
} from './SingleSalesOrderQuotationsPage.styled';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const SingleSalesOrderQuotationsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { isLoading, data, isFetching, isFetched } =
    useGetSingleSalesOrderQuotations(typeof id === 'string' ? id : '');

  const [page, setPage] = useState<number>(1);

  const handleViewButtonClick = (rowData: IQuotationTableDTO) => {
    navigate(
      RoutesConfig.SingleQuotation.fullPath
        .replace(':id', rowData.navigationID)
        .replace('/*', '')
    );
  };

  let tableData: IQuotationTableDTO[] = [];
  if (data) {
    tableData = formatQuotationsTableData(
      // TODO: include all parent quotations
      data
        .map((quotation: IQuotation[]) => {
          return quotation;
        })
        .flat(),
      currencySymbol
    );
  }

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER_QUOTATIONS,
    id,
    isFetched
  );

  return (
    <Container className="single-sales-order-step-8">
      {tableData.length ? (
        <Table
          isLoading={isLoading}
          page={page}
          perPage={QUOTATIONS_PER_PAGE}
          total={data?.total}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={(newPage) => setPage(newPage)}
          onViewButtonClick={handleViewButtonClick}
        />
      ) : (
        <>
          {isLoading && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <NoContentLabel>
              {t('There are currently no Quotations on this Sales order')}
            </NoContentLabel>
          )}
        </>
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesOrderQuotationsPage;
