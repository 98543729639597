import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isChecked: boolean;
}

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1rem solid ${COLORS.PRIMARY};
  height: 25rem;
  width: 25rem;
  border-radius: 3rem;

  ${(props: StyledProps) =>
    props.isChecked &&
    css`
      background-color: ${COLORS.PRIMARY};
    `};
`;
