import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isReceived: boolean;
}

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;
`;

export const ReceivedLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.GREY_700};
  ${(props: StyledProps) =>
    props.isReceived &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;
