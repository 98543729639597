import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSingleSalesOrderInvoices } from 'services/SalesOrder/SalesOrderService';

export const useGetSalesOrderId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};

export const useGetSingleSalesOrderInvoices = (
  salesOrderId: string,
  page?: number,
  perPage?: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_SALES_ORDER_INVOICES,
      salesOrderId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getSingleSalesOrderInvoices(salesOrderId, page, perPage);
    },
    enabled: !!salesOrderId,
  });
