import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import {
  Container,
  ContainerWrapper,
  EyeWrapper,
  Headline,
  Logo,
  PasswordInputWrapper,
  StyledLink,
  Wrapper,
} from './ChangePassword.styled';
import bpSymbolBlack from 'assets/icons/bpSymbolBlack.svg';
import Input from 'components/Input/Input';
import { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { useResetPassword } from './hooks';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { changeLanguage } from 'utils/changeLanguage';
import { Eye, EyeSlash } from '@phosphor-icons/react';

const ResetPassword = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const companyId = searchParams.get('company_id');
  const lang = searchParams.get('lang');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [newPassword, setNewPassword] = useState<string>('');
  const [hideNewPassword, setHideNewPassword] = useState<boolean>(true);
  const [passwordInputErrorMessage, setPasswordInputErrorMessage] =
    useState<string>('');

  const handleEyeClickNewPassword = () => {
    setHideNewPassword(!hideNewPassword);
  };

  const handleEyeClickConfirmPassword = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const handleNewPasswordChange = (newPasswordInput: string) => {
    setNewPassword(newPasswordInput);
    doPasswordsMatch(newPasswordInput, confirmPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Error: Passwords do not match');
  };

  const handleConfirmPasswordChange = (confirmPasswordInput: string) => {
    setConfirmPassword(confirmPasswordInput);
    doPasswordsMatch(confirmPasswordInput, newPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Error: Passwords do not match');
  };

  const doPasswordsMatch = (firstPassword: string, secondPassword: string) => {
    return firstPassword === secondPassword;
  };

  const submitResetPassword = () => {
    resetPassword({ newPassword, confirmPassword });
  };

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  const { mutate: resetPassword } = useResetPassword(token!, companyId!);

  if (!token) return <PageNotFound />;

  return (
    <ContainerWrapper>
      <Container>
        <StyledLink to={RoutesConfig.Home.fullPath}>
          <Logo src={bpSymbolBlack} alt="Logo" />
        </StyledLink>
        <Headline>{t('New password')}</Headline>
        <Wrapper>
          <PasswordInputWrapper>
            <Input
              pwId={`new-password-field`}
              placeholder={t('Password')}
              value={newPassword}
              type={hideNewPassword ? 'password' : ''}
              onChange={(e) => handleNewPasswordChange(e.target.value)}
              height={'41rem'}
              wrapperStyles={{
                width: '300rem',
              }}
            />
            <EyeWrapper onClick={() => handleEyeClickNewPassword()}>
              <Icon
                svg={hideNewPassword ? Eye : EyeSlash}
                color={COLORS.PRIMARY}
                size={20}
              />
            </EyeWrapper>
          </PasswordInputWrapper>
          <PasswordInputWrapper>
            <Input
              pwId={`confirm-password-field`}
              placeholder={t('Confirm password')}
              value={confirmPassword}
              type={hideConfirmPassword ? 'password' : ''}
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              height={'41rem'}
              error={true}
              errorMessage={passwordInputErrorMessage}
              wrapperStyles={{
                width: '300rem',
              }}
            />
            <EyeWrapper onClick={() => handleEyeClickConfirmPassword()}>
              <Icon
                svg={hideConfirmPassword ? Eye : EyeSlash}
                color={COLORS.PRIMARY}
                size={20}
              />
            </EyeWrapper>
          </PasswordInputWrapper>
          <Button
            primary
            width="300rem"
            disabled={!newPassword.length || !confirmPassword.length}
            label={t('Submit')}
            onClick={() => submitResetPassword()}
          />
        </Wrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default ResetPassword;
