import { COLORS, paddingSm } from 'assets/styled';
import styled from 'styled-components';

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const TableWrapper = styled.div`
  padding-top: ${paddingSm};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
