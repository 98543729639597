import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { setUserIntroGuideCompletedPages } from 'store/User/actions/user';
import { useGetCompletedIntroGuidePages } from './hooks';

interface IIntroGuideProviderProps {
  children: React.ReactNode;
}
export const IntroGuideProvider = ({ children }: IIntroGuideProviderProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = !!useSelector(
    (state: IRootReducerState) => state.userInfo.id
  );
  const completedPages = useSelector(
    (state: IRootReducerState) => state.userInfo.completed_guide_pages
  );

  const {
    mutate: getCompletedIntroGuidePages,
    data: introGuidePagesData,
    isLoading,
    isSuccess,
    reset,
  } = useGetCompletedIntroGuidePages();

  useEffect(() => {
    if (isAuthenticated && !completedPages?.length && !isLoading) {
      getCompletedIntroGuidePages();
    }
  }, [isAuthenticated, isLoading, completedPages?.length]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setUserIntroGuideCompletedPages(introGuidePagesData.guide_pages)
      );
      reset();
    }
  }, [isSuccess]);

  return <>{children}</>;
};
