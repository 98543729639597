import moment from 'moment';
import { store } from 'store/store';
import { ICreateInvoiceCustomerDetails } from './CustomerTab/constants';
import { ICreateInvoiceDetails } from './InvoiceDetailsTab/constants';
import {
  ICreateDownpaymentInvoiceDTO,
  ICreateSalesInvoiceDTO,
  IDownpaymentInvoiceLine,
  ISalesInvoice,
  ISalesOrderInvoiceLine,
  ISalesOrderInvoiceWorkingHoursLine,
  IServiceInvoiceLine,
  IWorkingHourInvoiceLine,
} from 'types/Invoice.types';
import { ISalesOrder, ISalesOrderLine } from 'types/SalesOrders.types';
import { DiscountType } from 'types/Discount.types';

export const getInitialCustomerDetailsData = (
  prepopulateInvoiceData: any,
  initialCountryCCA2: string
): ICreateInvoiceCustomerDetails => {
  if (prepopulateInvoiceData) {
    return {
      name: prepopulateInvoiceData.customer_snapshot.first_name,
      last_name: prepopulateInvoiceData.customer_snapshot.last_name,
      gender: prepopulateInvoiceData.customer_snapshot.gender,
      email: prepopulateInvoiceData.customer_snapshot.email,
      phone: prepopulateInvoiceData.customer_snapshot.phone_number,
      country: prepopulateInvoiceData.customer_snapshot.address.country.cca2,
      street: prepopulateInvoiceData.customer_snapshot.address.street,
      code: prepopulateInvoiceData.customer_snapshot.address.zip_code,
      city: prepopulateInvoiceData.customer_snapshot.address.city,
      house_number:
        prepopulateInvoiceData.customer_snapshot?.address?.house_number,
      company_name: prepopulateInvoiceData.customer_snapshot?.company_name,
      vat_number: prepopulateInvoiceData.customer_snapshot?.company_vat,
      title: prepopulateInvoiceData.customer_snapshot?.title,
    };
  } else {
    return {
      name: '',
      gender: '',
      email: '',
      phone: '',
      country: initialCountryCCA2,
      street: '',
      code: '',
      last_name: '',
      city: '',
      house_number: '',
      company_name: '',
      vat_number: '',
      title: '',
    };
  }
};

export const getInitialInvoiceDetailsData = () => {
  return {
    daysValid: store.getState().companyInfo?.settings?.days_valid_invoice,
    externalId: '',
    date: moment().format('YYYY-MM-DD'),
  };
};

export const calculateInvoiceDetails = (
  prepopulateInvoiceData: any,
  invoiceDetails: ICreateInvoiceDetails
): ICreateInvoiceDetails => {
  return {
    daysValid: invoiceDetails.daysValid
      ? invoiceDetails.daysValid
      : prepopulateInvoiceData.valid_to,
    externalId: prepopulateInvoiceData.reference || invoiceDetails.externalId,
    date: moment().format('YYYY-MM-DD'),
  };
};

export const findWorkingHourSalesOrderLine = (prepopulateInvoiceData: any) => {
  for (const salesOrderLine of prepopulateInvoiceData.sales_order_lines) {
    if (salesOrderLine.working_hours_line) {
      return salesOrderLine;
    }
  }
};

export const getCreateDownpaymentInvoicePayload = (
  prepopulateInvoiceData: ISalesOrder,
  invoiceDetails: ICreateInvoiceDetails
) => {
  const invoiceLines: Array<IDownpaymentInvoiceLine> = [];

  const line: IDownpaymentInvoiceLine = {
    billed_amount: prepopulateInvoiceData.downpayment,
    quantity: 1,
    downpayment_unit_price: prepopulateInvoiceData.downpayment,
    downpayment_line_description: '',
  };

  invoiceLines.push(line);

  const createInvoiceData: ICreateDownpaymentInvoiceDTO = {
    valid_to: `${moment(invoiceDetails.date)
      .endOf('day')
      .add(invoiceDetails.daysValid, 'days')
      .format('YYYY-MM-DD')} 00:00:00`,
    sales_invoice_lines: invoiceLines,
    is_downpayment: true,
    sales_order_id: prepopulateInvoiceData.id,
    invoice_date: `${moment(invoiceDetails.date).format(
      'YYYY-MM-DD'
    )} 00:00:00`,
    external_id: invoiceDetails.externalId,
  };
  return createInvoiceData;
};

export const getCreateInvoicePayload = (
  prepopulateInvoiceData: ISalesOrder,
  invoiceDetails: ICreateInvoiceDetails,
  selectedPartialIds: number[],
  isDownPaymentInvoiceCreated: boolean,
  salesInvoices: ISalesInvoice[]
) => {
  const invoiceLines: Array<
    | ISalesOrderInvoiceLine
    | IWorkingHourInvoiceLine
    | ISalesOrderInvoiceWorkingHoursLine
    | IServiceInvoiceLine
  > = [];

  for (const salesOrderLine of prepopulateInvoiceData.sales_order_lines as ISalesOrderLine[]) {
    if (salesOrderLine.working_hours_line) {
      continue;
    }
    const invoiceLine = salesOrderLine.service
      ? {
          sales_order_line_id: Number(salesOrderLine?.id),
          billed_amount:
            Number(salesOrderLine.service.amount) *
            Number(salesOrderLine.quantity),
          quantity: Number(salesOrderLine.quantity),
          discount: {
            type: salesOrderLine.discount?.type
              ? salesOrderLine.discount.type
              : DiscountType.PERCENTAGE,
            amount: salesOrderLine.discount?.amount
              ? Number(salesOrderLine.discount.amount)
              : 0,
          },
          service_id: salesOrderLine.service?.id,
        }
      : {
          sales_order_line_id: Number(salesOrderLine?.id),
          billed_amount: (
            Number(salesOrderLine.product_sales_price) *
            Number(salesOrderLine.quantity)
          ).toString(),
          quantity: salesOrderLine.quantity,
          discount: {
            type: salesOrderLine.discount?.type
              ? salesOrderLine.discount.type
              : DiscountType.PERCENTAGE,
            amount: salesOrderLine.discount?.amount
              ? Number(salesOrderLine.discount.amount)
              : 0,
          },
          product_id: salesOrderLine.product?.id,
          supplier_id: salesOrderLine.supplier?.id,
          ...(salesOrderLine.stock_item?.id && {
            stock_item_id: salesOrderLine.stock_item.id,
          }),
        };

    invoiceLines.push(invoiceLine);
  }

  const workingHoursSalesOrderLine = findWorkingHourSalesOrderLine(
    prepopulateInvoiceData
  );

  if (workingHoursSalesOrderLine && !workingHoursSalesOrderLine?.invoiced) {
    const workingHourInvoiceLine: IWorkingHourInvoiceLine = {
      sales_order_line_id: workingHoursSalesOrderLine?.id,
      billed_amount: workingHoursSalesOrderLine.total_amount,
      quantity: workingHoursSalesOrderLine.quantity,
      working_hours_rate: workingHoursSalesOrderLine.working_hours_rate,
      working_hours_line: true,
      discount: workingHoursSalesOrderLine.discount
        ? workingHoursSalesOrderLine.discount
        : {
            type: DiscountType.PERCENTAGE,
            amount: 0,
          },
    };
    invoiceLines.push(workingHourInvoiceLine);
  }

  const createInvoiceData: ICreateSalesInvoiceDTO = {
    valid_to: `${moment(invoiceDetails.date)
      .endOf('day')
      .add(invoiceDetails.daysValid, 'days')
      .format('YYYY-MM-DD')} 00:00:00`,
    is_credit: false,
    sales_order_id: prepopulateInvoiceData?.id,
    sales_invoice_lines: invoiceLines,
    external_id: invoiceDetails.externalId,
    sales_order_line_ids: selectedPartialIds,
    invoice_date: `${moment(invoiceDetails.date).format(
      'YYYY-MM-DD'
    )} 00:00:00`,
  };

  if (isDownPaymentInvoiceCreated && prepopulateInvoiceData.downpayment) {
    createInvoiceData.parent_invoice_id = salesInvoices
      ?.filter((salesInvoice: any) => salesInvoice.is_downpayment)[0]
      ?.id.toString();
  }

  return createInvoiceData;
};
