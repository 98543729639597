import { CardContainer } from './SinglePurchaseOrderGeneralPage.styled';
import { useGetSinglePurchaseOrder } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import moment from 'moment';
import { useState } from 'react';
import { EditOrderDetailsModal } from '../EditOrderDetailsModal/EditOrderDetailsModal';
import { formatNumber } from 'utils/numberFormatter';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useLocale } from 'utils/hooks/useLocale';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseOrderGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const canEditPurchaseOrder = useCan(Actions.EDIT_PURCHASE_ORDER);
  const currencySymbol = useGetCurrencySymbol();

  const [isEditOrderDetailsModalOpen, setIsEditOrderDetailsModalOpen] =
    useState<boolean>(false);

  const { data, isLoading } = useGetSinglePurchaseOrder(
    typeof id === 'string' ? id : ''
  );

  const orderDetailsCardLeftData = {
    'Purchase order number': data?.number,
    'Sales order': data?.sales_order_number,
    'Creation date': data?.created_at
      ? moment(data?.created_at?.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    'Expected delivery date': data?.expected_delivery_date
      ? moment(data?.expected_delivery_date?.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    'Confirmed delivery date': data?.promised_delivery_date
      ? moment(data?.promised_delivery_date?.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    'Actual delivery date': data?.delivery_date
      ? moment(data?.delivery_date?.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    Reference: data?.reference,
  };
  const orderDetailsCardRightData = {
    'Total cost': `${currencySymbol}${formatNumber(data?.total_amount)}`,
    'Open amount': `${currencySymbol}${formatNumber(data?.open_amount)}`,
    'Amount paid': `${currencySymbol}${formatNumber(data?.total_amount_paid)}`,
    'Expected total cost': `${currencySymbol}${formatNumber(
      data?.expected_total_amount
    )}`,
  };

  const supplierDetailsCardLeftData = {
    Number: data?.supplier?.number,
    'Company name': data?.supplier?.company_name,
    Address: data?.supplier?.address?.full_address,
    supplierID: data?.supplier?.id,
  };
  const supplierDetailsCardRightData = {
    'E-mail': data?.supplier?.email,
    'Phone number': data?.supplier?.phone,
    'Contact person': data?.supplier?.contact_person,
  };
  return (
    <>
      <CardContainer>
        <SimpleWideCard
          cardTitle={t('Supplier details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('CONTACT')}
          leftData={supplierDetailsCardLeftData}
          rightData={supplierDetailsCardRightData}
          isLoading={isLoading}
        />
        <SimpleWideCard
          cardTitle={t('Order details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('PRICE')}
          leftData={orderDetailsCardLeftData}
          rightData={orderDetailsCardRightData}
          editable={canEditPurchaseOrder}
          onEditClick={() => setIsEditOrderDetailsModalOpen(true)}
          isLoading={isLoading}
        />
      </CardContainer>
      <EditOrderDetailsModal
        isOpen={isEditOrderDetailsModalOpen}
        setIsOpen={setIsEditOrderDetailsModalOpen}
        onCancel={() => setIsEditOrderDetailsModalOpen(false)}
      />
    </>
  );
};

export default SinglePurchaseOrderGeneralPage;
