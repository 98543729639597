import { paddingLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSideMenuCollapsed: boolean;
}

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 265rem;
  margin-top: 75rem;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      margin-left: 115rem;
    `}

  ${respondTo.bigTablet`
    padding-bottom: ${paddingLg};
    max-width: calc(100% - 115rem);
  `}
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
