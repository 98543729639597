import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Field, Formik, getIn } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
  Title,
  TitleContainer,
} from './EditCustomerOrSupplierModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { EditingCustomerOrSupplierEnum } from './constants';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import {
  editCustomerValidationSchema,
  editSupplierValidationSchema,
} from './validation';
import { useGetSingleCustomer } from 'pages/Manager/SingleCustomerPage/hooks';
import { useEditCustomer, useEditSupplier } from './hooks';
import { useGetSingleSupplier } from 'pages/Manager/SingleSupplierPage/hooks';

interface IEditCustomerOrSupplierModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: EditingCustomerOrSupplierEnum;
}

export const EditCustomerOrSupplierModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  type,
}: IEditCustomerOrSupplierModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const { data: customerData } = useGetSingleCustomer(
    id || '',
    isOpen && type === EditingCustomerOrSupplierEnum.CUSTOMER
  );
  const { data: supplierData } = useGetSingleSupplier(
    id || '',
    isOpen && type === EditingCustomerOrSupplierEnum.SUPPLIER
  );
  const { mutate: editCustomerDetails } = useEditCustomer(id || '');
  const { mutate: editSupplierDetails } = useEditSupplier(id || '');
  const countryOptions: Option[] = useGetCountryOptions();
  const titleOptions: Option[] = useGetTitleOptions();

  const customerInitialData = {
    name: customerData?.customer?.name,
    last_name: customerData?.customer.last_name,
    company_name: customerData?.customer.company_name,
    vat_number: customerData?.customer.vat_number,
    address: {
      street: customerData?.customer?.address?.street,
      house_number: customerData?.customer?.address?.house_number,
      city: customerData?.customer?.address?.city,
      zip_code: customerData?.customer?.address?.zip_code,
      country: customerData?.customer?.address?.country?.cca2,
    },
    email: customerData?.customer?.email,
    phone: customerData?.customer?.phone,
    coc_number: customerData?.customer?.coc_number,
    title: customerData?.customer?.title,
  };

  const supplierInitialData = {
    company_name: supplierData?.supplier?.company_name,
    contact_person: supplierData?.supplier?.contact_person,
    address: {
      street: supplierData?.supplier?.address?.street,
      house_number: supplierData?.supplier?.address?.house_number,
      city: supplierData?.supplier?.address?.city,
      zip_code: supplierData?.supplier?.address?.zip_code,
      country: supplierData?.supplier?.address?.country?.cca2,
    },
    email: supplierData?.supplier?.email,
    phone: supplierData?.supplier?.phone,
    vat_number: supplierData?.supplier?.vat_number,
    number: supplierData?.supplier?.number,
    coc_number: supplierData?.supplier?.coc_number,
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'auto',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>
          {type === EditingCustomerOrSupplierEnum.CUSTOMER
            ? `${customerData?.customer.name} ${customerData?.customer.last_name}`
            : `${supplierData?.supplier.company_name}`}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={
            type === EditingCustomerOrSupplierEnum.CUSTOMER
              ? customerInitialData
              : supplierInitialData
          }
          validationSchema={
            type === EditingCustomerOrSupplierEnum.CUSTOMER
              ? editCustomerValidationSchema
              : editSupplierValidationSchema
          }
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
        >
          {({ values, errors, setFieldValue, handleBlur, isValid }) => {
            return (
              <Container>
                {type === EditingCustomerOrSupplierEnum.CUSTOMER && (
                  <>
                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('Title').toUpperCase()}</H4>
                        </Heading>
                        <Field
                          pwId="title-field"
                          disabled={false}
                          height={'41rem'}
                          name="title"
                          width="300rem"
                          options={titleOptions}
                          component={StyledCustomSelect}
                          isMulti={false}
                          onSelect={(value: string) =>
                            setFieldValue('title', value)
                          }
                          errorMessage={areAllFieldsTouched && errors['title']}
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('First name').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="first-name-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="name"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={areAllFieldsTouched && errors['name']}
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('Last name').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="last-name-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('last_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="last_name"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['last_name']
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('Company name').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="company-name-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('company_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="company_name"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['company_name']
                              ? (errors['company_name'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('VAT number').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="vat-number-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('vat_number', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="vat_number"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['vat_number']
                              ? (errors['vat_number'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>
                  </>
                )}
                {type === EditingCustomerOrSupplierEnum.SUPPLIER && (
                  <>
                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('Company name').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="company-name-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('company_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="company_name"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['company_name']
                              ? (errors['company_name'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('Contact person').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="contact-person-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('contact_person', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="contact_person"
                          wrapperStyles={{
                            width: '300rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['contact_person']
                              ? (errors['contact_person'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4>{t('VAT number').toUpperCase()}</H4>
                        </Heading>
                        <FormikInput
                          pwId="vat-number-field"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('vat_number', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="vat_number"
                          wrapperStyles={{
                            width: '630rem',
                            marginTop: '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['vat_number']
                              ? (errors['vat_number'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>
                  </>
                )}
                <HorizontalLine />
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('E-MAIL')}</H4>
                    </Heading>
                    <FormikInput
                      pwId="email-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('email', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="email"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['email']
                          ? (errors['email'] as string)
                          : ''
                      }
                    />
                  </ColumnContainer>

                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Phone number').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="phone-number-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('phone', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="phone"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['phone']
                          ? (errors['phone'] as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <HorizontalLine />

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Street').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="street-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.street', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.street"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.street') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('House number').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="house-number-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.house_number', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.house_number"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.house_number') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <HorizontalLine />

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('City').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="city-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.city', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.city"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.city') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Zip code').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="zip-code-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.zip_code', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.zip_code"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.zip_code') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <HorizontalLine />
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Country code').toUpperCase()}</H4>
                    </Heading>
                    <Field
                      pwId="country-code-field"
                      disabled={false}
                      height={'41rem'}
                      name="address.country"
                      width="300rem"
                      options={countryOptions}
                      component={StyledCustomSelect}
                      isMulti={false}
                      onSelect={(value: string) =>
                        setFieldValue('address.country', value)
                      }
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.country') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('COC number').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      pwId="coc-number-field"
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('coc_number', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="coc_number"
                      wrapperStyles={{
                        width: '300rem',
                        marginTop: '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['coc_number']
                          ? (errors['coc_number'] as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={areAllFieldsTouched && !isValid}
                    onClick={() => {
                      if (!areAllFieldsTouched && !isValid) {
                        setAreAllFieldsTouched(true);
                        return;
                      }
                      if (!isValid) {
                        return;
                      }
                      if (type === EditingCustomerOrSupplierEnum.CUSTOMER) {
                        editCustomerDetails(values);
                      } else {
                        editSupplierDetails(values);
                      }

                      setIsOpen(false);
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
