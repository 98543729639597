import {
  CheckboxContainer,
  Container,
  RightAlign,
  Row,
} from './SingleQuotationProductsPage.styled';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetQuotationActiveAppointmentInfo,
  useGetSingleQuotation,
} from '../hooks';
import { IQuotation, IQuotationLine } from 'types/Quotations.types';
import { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import {
  useDeleteQuotationLine,
  useEditQuotationPricesDefinitive,
} from './hooks';
import { canManipulateQuotationLines } from './helpers';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { RoutesConfig } from 'navigation/routes';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { EditQuotationWorkingHoursLineModal } from './EditQuotationWorkingHoursLineModal/EditQuotationWorkingHoursLineModal';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Button from 'components/Button/Button';
import DraggableLines, {
  DraggableLineType,
  IDraggableLine,
} from 'components/DragAndDrop/DraggableLines/DraggableLines';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useRunTour } from 'components/IntroductionGuide/hooks';

const SingleQuotationProductsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isUnableToEditModalOpen, setIsUnableToEditModalOpen] =
    useState<boolean>(false);
  const [isEditWorkingHoursLineModalOpen, setIsEditWorkingHoursLineModalOpen] =
    useState<boolean>(false);
  const [workingHoursLineForEditing, setWorkingHoursLineForEditing] =
    useState<IQuotationLine | null>(null);

  const canEditQuotation = useCan(Actions.EDIT_QUOTATION);

  const {
    data,
    isLoading: isLoadingSingleQuotation,
    isFetched,
  } = useGetSingleQuotation(id!);
  const { isSuccess: appointmentInProgress } =
    useGetQuotationActiveAppointmentInfo(id!);

  // Quotation email is sent to general address
  const emailDetails = {
    email: data?.customer?.email,
    type: 'General',
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [lineForDeletion, setLineForDeletion] = useState<IQuotationLine | null>(
    null
  );

  const {
    mutate: editPricesDefinitive,
    isLoading: isLoadingEditPricesDefinitive,
    isSuccess: isSuccessEditPricesDefinitive,
  } = useEditQuotationPricesDefinitive(id!);

  useEffect(() => {
    if (isSuccessEditPricesDefinitive) {
      // The value is previous because on isSuccessEditPricesDefinitive the single quotaton query
      // is invalidated and the new updated value doesnt get fetched yet
      const previousPricesDefinitiveValue = data?.prices_definitive;
      const newPricesDefinitiveValue = !previousPricesDefinitiveValue;
      if (newPricesDefinitiveValue) {
        setIsSendEmailModalOpen(true);
      }
    }
  }, [isSuccessEditPricesDefinitive]);

  const {
    mutate: deleteQuotationLine,
    isLoading: deleteQuotationLineIsLoading,
  } = useDeleteQuotationLine(id!);

  const handleDeleteClick = (line: IQuotationLine) => {
    setLineForDeletion(line);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (line: IQuotationLine) => {
    if (line.working_hours_line) {
      setIsEditWorkingHoursLineModalOpen(true);
      setWorkingHoursLineForEditing(line);
    } else {
      navigate(RoutesConfig.NewQuotationLine.fullPath.replace(':id', id!), {
        state: {
          line: line,
        },
      });
    }
  };

  const canManipulateLines = canManipulateQuotationLines(data as IQuotation);

  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_PRODUCTS,
    id,
    isFetched
  );

  return (
    <Container className="single-quotation-step-6">
      <Row>
        <CheckboxContainer>
          <Checkbox
            disabled={
              isLoadingEditPricesDefinitive ||
              isLoadingSingleQuotation ||
              !canEditQuotation
            }
            isChecked={data?.prices_definitive}
            onChange={() => editPricesDefinitive(!data?.prices_definitive)}
            label={t('Prices definitive')}
            style={{ fontSize: '18rem' }}
          />
        </CheckboxContainer>
        {canEditQuotation && (
          <RightAlign>
            <Button
              label={t('Add new product')}
              link
              icon={Plus}
              sizeIcon={20}
              weightIcon="regular"
              colorIcon={COLORS.PRIMARY}
              onClick={() =>
                canManipulateLines && !appointmentInProgress
                  ? navigate(
                      RoutesConfig.NewQuotationLine.fullPath.replace(':id', id!)
                    )
                  : setIsUnableToEditModalOpen(true)
              }
              fontSize="18rem"
            />
          </RightAlign>
        )}
      </Row>
      <DraggableLines
        lines={data?.quotation_lines}
        lineType={DraggableLineType.QUOTATION}
        onEditClick={
          canManipulateLines && !appointmentInProgress
            ? (quotationLine: IDraggableLine) =>
                handleEditClick(quotationLine as IQuotationLine)
            : () => setIsUnableToEditModalOpen(true)
        }
        onDeleteClick={
          canManipulateLines && !appointmentInProgress
            ? (quotationLine: IDraggableLine) =>
                handleDeleteClick(quotationLine as IQuotationLine)
            : () => setIsUnableToEditModalOpen(true)
        }
        isDragDisabled={isLoadingSingleQuotation || !canEditQuotation}
      />
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t('Do you want to send the quotation to the customer') + '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.id}
        type={AddEmailNoteModalType.QUOTATION}
        emailDetails={emailDetails}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (lineForDeletion) {
                deleteQuotationLine(lineForDeletion.id.toString());
                setLineForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: deleteQuotationLineIsLoading,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete line')}
          description={
            t('Are you sure you want to delete this Quotation line') + '?'
          }
        />
      </Modal>
      <OkModal
        level="FIRST"
        onClick={() => setIsUnableToEditModalOpen(false)}
        description={
          appointmentInProgress
            ? t('You cannot edit a quotation with active appointment')
            : t('You cannot edit a closed quotation')
        }
        buttonLabel="OK"
        setIsOpen={setIsUnableToEditModalOpen}
        isOpen={isUnableToEditModalOpen}
      />
      {isEditWorkingHoursLineModalOpen && workingHoursLineForEditing && (
        <EditQuotationWorkingHoursLineModal
          isOpen={isEditWorkingHoursLineModalOpen}
          setIsOpen={setIsEditWorkingHoursLineModalOpen}
          onCancel={() => setIsEditWorkingHoursLineModalOpen(false)}
          quotationLine={workingHoursLineForEditing}
        />
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleQuotationProductsPage;
