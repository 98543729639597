import styled from 'styled-components';
import { marginMd } from 'assets/styled';

export const FormContainer = styled.div``;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  gap: 20rem;
`;
