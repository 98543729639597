import { IBreadcrumbsConfig } from './types';

// Finds and returns the route configuration for a given path.
export const findRouteConfig = (
  pathToMatch: string,
  breadcrumbsConfig: any
): IBreadcrumbsConfig | undefined => {
  if (pathToMatch === '/') {
    return breadcrumbsConfig['/'];
  }

  for (const key in breadcrumbsConfig) {
    if (key !== '/') {
      const routePattern = new RegExp(
        '^' + key.replace(/:[^\s/]+/g, '([\\w-]+)') + '/?$'
      );
      if (routePattern.test(pathToMatch)) {
        return breadcrumbsConfig[key];
      }
    }
  }
};

// Capitalizes the first letter of a string, and handles hyphen-separated words.
export const capitalizeFirstLetter = (string: string): string => {
  if (string.includes('-')) {
    return string
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

// Safely retrieves a nested property from an object using a dot-separated path.
export const getNestedProperty = (obj: Record<string, any>, path: string) => {
  return path
    .split('.')
    .reduce((acc: any, part: string) => acc && acc[part], obj);
};
