import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editPurchaseOrderLineStatus } from 'services/PurchaseOrder/PurchaseOrderService';
import { IEditPurchaseOrderLineStatus } from 'types/PurchaseOrders.types';

export const useEditPurchaseOrderLineStatus = (
  purchaseOrderId: string,
  lineId: string
) => {
  const { t } = useTranslation();

  return useMutation(
    (editLineDTO: IEditPurchaseOrderLineStatus) =>
      editPurchaseOrderLineStatus(purchaseOrderId, lineId, editLineDTO),
    {
      onSuccess: () => {
        toast.success(t('Purchase order line status updated'), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_STATUS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_STATUS,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_PURCHASE_ORDER_LINE_STATUS,
    }
  );
};
