import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (input: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(input);

  useEffect(() => {
    const handler = debounce(() => setDebouncedValue(input), delay);
    // Call the debounced function
    handler();
    // Cancel the debounce on useEffect cleanup
    return handler.cancel;
  }, [input, delay]); // Only re-call effect if input or delay changes

  return debouncedValue;
};
