import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SingleSupplierRoutes.SingleSupplierGeneral.path,
  },
  {
    text: 'Products',
    route: SingleSupplierRoutes.SingleSupplierProducts.path,
  },
  {
    text: 'Purchase orders',
    route: SingleSupplierRoutes.SingleSupplierPurchaseOrders.path,
  },
  // {
  //   text: 'Appointments',
  //   route: SingleSupplierRoutes.SingleSupplierAppointments.path,
  // },
];
