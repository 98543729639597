import { AnyAction } from '@reduxjs/toolkit';
import { LOGOUT } from 'store/User/constants';
import {
  ADD_PLANNING_TAB,
  INITIALIZE_PAGE_FILTERS,
  REMOVE_PLANNING_TAB,
  SAVE_PAGE_FILTERS_ON_DISMOUNT,
  SELECT_PLANNING_TAB,
  UPDATE_PLANNING_TAB,
  UPDATE_TAB_NAME,
} from '../constants';
import {
  IAnalyticsPageFilters,
  ICustomersPageFilters,
  IFwAppointmentsPageFilters,
  IInvoicesPageFilters,
  IPlanningPageFilters,
  IPlanningTabFilter,
  IPurchaseOrdersPageFilters,
  IQuotationsPageFilters,
  ISalesOrdersPageFilters,
  IStockItemsPageFilters,
  ISuppliersPageFilters,
  IUserRolesPageFilters,
} from '../types';

export interface FiltersReducerState {
  quotationsPage: IQuotationsPageFilters | null;
  salesOrdersPage: ISalesOrdersPageFilters | null;
  purchaseOrdersPage: IPurchaseOrdersPageFilters | null;
  invoicesPage: IInvoicesPageFilters | null;
  planningPage: IPlanningPageFilters | null;
  stockItemsPage: IStockItemsPageFilters | null;
  customersPage: ICustomersPageFilters | null;
  suppliersPage: ISuppliersPageFilters | null;
  fwAppointmentsPage: IFwAppointmentsPageFilters | null;
  analyticsPage: IAnalyticsPageFilters | null;
  userRolesPage: IUserRolesPageFilters | null;
}

const INIT_STATE: FiltersReducerState = {
  quotationsPage: null,
  salesOrdersPage: null,
  purchaseOrdersPage: null,
  invoicesPage: null,
  planningPage: null,
  stockItemsPage: null,
  customersPage: null,
  suppliersPage: null,
  fwAppointmentsPage: null,
  analyticsPage: null,
  userRolesPage: null,
};

const filtersReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case INITIALIZE_PAGE_FILTERS: {
      const { page, data } = action.payload;
      return {
        ...state,
        [page]: data,
      };
    }
    case SAVE_PAGE_FILTERS_ON_DISMOUNT: {
      const { page, data } = action.payload;
      return {
        ...state,
        [page]: data,
      };
    }
    case REMOVE_PLANNING_TAB: {
      const { page, tabId } = action.payload;
      const currentTabs = state[page].tabs;
      const filteredTabs = currentTabs.filter(
        (tab: IPlanningTabFilter) => tab.id !== tabId
      );
      let newActiveTabId = state[page].activeTabId;

      if (tabId === state[page].activeTabId) {
        const tabIndex = currentTabs.findIndex(
          (tab: IPlanningTabFilter) => tab.id === tabId
        );
        if (tabIndex > 0) {
          newActiveTabId = currentTabs[tabIndex - 1].id;
        } else if (currentTabs.length > 1) {
          newActiveTabId = currentTabs[1].id;
        }
      }

      return {
        ...state,
        [page]: {
          ...state[page],
          tabs: filteredTabs,
          deletedTabIds: [...state[page].deletedTabIds, tabId],
          activeTabId: newActiveTabId,
        },
      };
    }
    case SELECT_PLANNING_TAB: {
      const { page, tabId } = action.payload;
      return {
        ...state,
        [page]: {
          ...state[page],
          activeTabId: tabId,
        },
      };
    }
    case ADD_PLANNING_TAB: {
      const { page, newTab } = action.payload;
      return {
        ...state,
        [page]: {
          ...state[page],
          tabs: [...state[page].tabs, newTab],
          activeTabId: newTab.id,
        },
      };
    }
    case UPDATE_PLANNING_TAB: {
      const { page, activeTabId, data } = action.payload;
      const currentPage = state[page];

      if (!currentPage) {
        return { ...state };
      }

      const isAlreadyDeleted = currentPage.deletedTabIds.includes(activeTabId);
      let tabExists = false;
      let isDataChanged = false;

      const updatedTabs = currentPage.tabs.map((tab: IPlanningTabFilter) => {
        if (tab.id === activeTabId) {
          tabExists = true;
          // Check if data is changed
          if (JSON.stringify(tab) !== JSON.stringify({ ...tab, ...data })) {
            isDataChanged = true;
            return { ...tab, ...data };
          }
          return tab;
        }
        return tab;
      });

      // If tab doesn't exist and if it's not deleted, add tab
      if (!tabExists && !isAlreadyDeleted) {
        updatedTabs.push({ id: activeTabId, ...data });
        isDataChanged = true;
      }

      // Return current state if data stay the same
      if (!isDataChanged) {
        return state;
      }

      return {
        ...state,
        [page]: {
          ...currentPage,
          tabs: updatedTabs,
        },
      };
    }

    case UPDATE_TAB_NAME: {
      const { page, tabId, newName } = action.payload;
      const currentPage = state[page];

      const tabIndex = currentPage.tabs.findIndex(
        (tab: IPlanningTabFilter) => tab.id === tabId
      );

      if (tabIndex === -1) {
        return state;
      }

      const updatedTabs = [...currentPage.tabs];
      updatedTabs[tabIndex] = { ...updatedTabs[tabIndex], name: newName };

      return {
        ...state,
        [page]: {
          ...currentPage,
          tabs: updatedTabs,
        },
      };
    }

    case LOGOUT: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default filtersReducer;
