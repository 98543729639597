import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import {
  connectCustomerToTwinfieldCustomer,
  createTwinfieldCustomer,
  getTwinfieldCustomerDetails,
  getTwinfieldCustomersByPostalCode,
} from 'services/TwinfieldCustomer/TwinfieldCustomerService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useTranslation } from 'react-i18next';

export const useGetTwinfieldCustomersByPostalCode = (
  isEnabled: boolean,
  zipCode: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_TWINFIELD_CUSTOMERS_BY_POSTAL_CODE,
      zipCode,
      page,
      perPage,
    ],
    queryFn: () => {
      return getTwinfieldCustomersByPostalCode(zipCode, page, perPage);
    },
    enabled: !!zipCode && isEnabled,
  });

export const useGetTwinfieldCustomerDetails = () =>
  useMutation((code: number) => getTwinfieldCustomerDetails(code), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_TWINFIELD_CUSTOMER_DETAILS,
      });
    },
    onSettled: () => {
      // onSettled
    },
    mutationKey: ReactMutationKeys.GET_TWINFIELD_CUSTOMER_DETAILS,
  });

export interface IConnectCustomerToTwinfieldCustomer {
  code: number;
  customerId: number;
}
export const useConnectCustomerToTwinfieldCustomer = () =>
  useMutation(
    (params: IConnectCustomerToTwinfieldCustomer) =>
      connectCustomerToTwinfieldCustomer(params.code, params.customerId),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CONNECT_CUSTOMER_TO_TWINFIELD_CUSTOMER,
        });
      },
      onSettled: () => {
        // onSuccess
      },
      mutationKey: ReactMutationKeys.CONNECT_CUSTOMER_TO_TWINFIELD_CUSTOMER,
    }
  );

export const useCreateTwinfieldCustomer = () => {
  const { t } = useTranslation();
  return useMutation(
    (customerId: number) => createTwinfieldCustomer(customerId),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.errors?.[0] ===
          '400 Bad Request: Customer already has a twinfield customer connected'
        ) {
          toast.error(
            t('Customer already has a Twinfield customer connected'),
            {
              toastId: ReactMutationKeys.CREATE_TWINFIELD_CUSTOMER,
            }
          );
        } else {
          toast.error(getToastErrorMessage(error), {
            toastId: ReactMutationKeys.CREATE_TWINFIELD_CUSTOMER,
          });
        }
      },
      onSettled: () => {
        // onSuccess
      },
      mutationKey: ReactMutationKeys.CREATE_TWINFIELD_CUSTOMER,
    }
  );
};
