import { AppThemeProvider } from 'providers/AppThemeProvider/AppThemeProvider';
import AppNavigationRouter from 'navigation/AppNavigationRouter';
import SessionExpiredModalProvider from 'providers/SessionExpiredModalProvider/SessionExpiredModalProvider';
import SocketIOProvider from 'providers/SocketIOProvider/SocketIOProvider';
import TokenChangedInDifferentTabListener from 'listeners/TokenChangedInDifferentTabListener/TokenChangedInDifferentTabListener';
import UpdateAvailableModalProvider from 'providers/UpdateAvailableModalProvider/UpdateAvailableModalProvider';
import PWAInstallProvider from 'providers/PWAIconInstallProvider/PWAIconInstallProvider';
import UserInfoProvider from 'providers/UserInfoProvider/UserInfoProvider';
import usePreventScrollOnFocusedNumberInput from 'utils/hooks/usePreventScrollOnFocusedNumberInput';
import ViewSwitcherProvider from 'providers/ViewSwitcherProvider/ViewSwitcherProvider';
import { HtmlThemeProvider } from 'providers/HtmlThemeProvider/HtmlThemeProvider';
import { ToastProvider } from 'providers/ToastProvider/ToastProvider';
import MobileAppUrlParamsListener from 'listeners/MobileAppUrlParamsListener/MobileAppUrlParamsListener';
import useModalObserver from 'utils/hooks/useModalObserver';
import { BackEventInterceptorProvider } from 'providers/BackEventInterceptorProvider/BackEventInterceptorProvider';
import { IntroGuideProvider } from 'providers/IntroGuideProvider/IntroGuideProvider';
import CommonDataProvider from 'providers/CommonDataProvider/CommonDataProvider';
import CompanyDataProvider from 'providers/CompanyDataProvider/CompanyDataProvider';
import { useHandleLoadingScreenState } from 'utils/hooks/useHandleLoadingScreenState';
import { MarkAsConnectedModalProvider } from 'providers/MarkAsConnectedModalProvider/MarkAsConnectedModalProvider';

function App() {
  useHandleLoadingScreenState();
  usePreventScrollOnFocusedNumberInput();
  useModalObserver();

  return (
    <MobileAppUrlParamsListener>
      <HtmlThemeProvider>
        <ToastProvider>
          <AppThemeProvider>
            <UserInfoProvider>
              <CompanyDataProvider>
                <ViewSwitcherProvider>
                  <SocketIOProvider>
                    <PWAInstallProvider>
                      <TokenChangedInDifferentTabListener>
                        <CommonDataProvider>
                          <SessionExpiredModalProvider>
                            <UpdateAvailableModalProvider>
                              <IntroGuideProvider>
                                <BackEventInterceptorProvider>
                                  <MarkAsConnectedModalProvider>
                                    <AppNavigationRouter />
                                  </MarkAsConnectedModalProvider>
                                </BackEventInterceptorProvider>
                              </IntroGuideProvider>
                            </UpdateAvailableModalProvider>
                          </SessionExpiredModalProvider>
                        </CommonDataProvider>
                      </TokenChangedInDifferentTabListener>
                    </PWAInstallProvider>
                  </SocketIOProvider>
                </ViewSwitcherProvider>
              </CompanyDataProvider>
            </UserInfoProvider>
          </AppThemeProvider>
        </ToastProvider>
      </HtmlThemeProvider>
    </MobileAppUrlParamsListener>
  );
}

export default App;
