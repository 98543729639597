import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import {
  Title,
  TitleContainer,
  SearchContainer,
  CloseContainer,
  UserContainer,
  UserDescription,
  ButtonContainer,
  Margin,
  MarginTop,
  UserName,
  AddMoreContainer,
  ClearButton,
  DataWrapper,
  DataContainer,
} from './UsersModal.styled';
import Pagination from 'components/Table/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { useState, Dispatch, SetStateAction } from 'react';
import { useGetUsers } from './hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import { MagnifyingGlass } from '@phosphor-icons/react';

const USERS_PER_PAGE = 4;
export interface IUsersModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  setSelectedUsers: (userIds: string[]) => void;
  selectedUsers: string[];
}

const UsersModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  setSelectedUsers,
  selectedUsers,
}: IUsersModalProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');
  const [localUsers, setLocalUsers] = useState<string[]>(selectedUsers);

  const debouncedSearchBy = useDebounce(searchBy);

  const { data: usersData, isLoading: isLoadingUsers } = useGetUsers(
    page,
    USERS_PER_PAGE,
    debouncedSearchBy,
    undefined, // dateFrom
    undefined, // dateTo,
    isOpen
  );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={() => setSearchBy('')}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close
          onClick={() => {
            onCancel();
            setSearchBy('');
          }}
        />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Users')}</Title>
      </TitleContainer>
      <SearchContainer>
        <Input
          icon={MagnifyingGlass}
          width={'300rem'}
          placeholder={t('Search')}
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        />
        <ClearButton
          onClick={() => {
            setLocalUsers([]);
          }}
        >
          {t('Clear')}
        </ClearButton>
      </SearchContainer>

      {usersData?.users?.map((user: any) => {
        const isSelected = localUsers.includes(user.id);
        return (
          <UserContainer key={user.id} selected={isSelected}>
            <DataContainer>
              <DataWrapper>
                <UserName>{user.name + ' ' + user.last_name}</UserName>
                <UserDescription>{user.email}</UserDescription>
              </DataWrapper>
              <AddMoreContainer>
                <Button
                  onClick={() => {
                    isSelected
                      ? setLocalUsers(localUsers.filter((id) => id !== user.id))
                      : setLocalUsers([...localUsers, user.id]);
                  }}
                  width={'100rem'}
                  secondary
                  label={isSelected ? t('Unselect') : t('Select')}
                />
              </AddMoreContainer>
            </DataContainer>
          </UserContainer>
        );
      })}
      <MarginTop>
        {usersData?.total > USERS_PER_PAGE && (
          <Pagination
            page={page}
            perPage={USERS_PER_PAGE}
            total={usersData?.total}
            loadPage={(newPage) => setPage(newPage)}
          />
        )}
      </MarginTop>

      <ButtonContainer>
        <Button
          width={'200rem'}
          secondary
          onClick={() => {
            onCancel();
            setSearchBy('');
          }}
          label={t('Cancel')}
        />
        <Margin>
          <Button
            onClick={() => {
              setIsOpen(false);
              setSearchBy('');
              setSelectedUsers(localUsers);
            }}
            width={'200rem'}
            primary
            label={t('Apply')}
          />
        </Margin>
      </ButtonContainer>
    </Modal>
  );
};

export default UsersModal;
