import { IFilterType } from 'components/Modal/AdvancedFilterModal/types';

export const filterTypesOptions: IFilterType[] = [
  {
    label: 'Number',
    value: 'number',
    type: 'string',
  },
  {
    label: 'Company',
    value: 'company',
    type: 'dropdown',
  },
  {
    label: 'Address',
    value: 'address',
    type: 'string',
  },
  {
    label: 'Zip code',
    value: 'zip_code',
    type: 'string',
  },
  {
    label: 'Phone number',
    value: 'phone_number',
    type: 'string',
  },
  {
    label: 'Total orders',
    value: 'total_orders',
    type: 'string',
  },
];

export const useGetSuppliersAdvancedFilters = () => {
  const filterTypes = filterTypesOptions;

  return {
    filterTypes,
  };
};
