import { COLORS, marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
  display: flex;
  justify-content: center;
`;

export const MollieMandatesWrapper = styled.div`
  align-self: center;
  margin-top: ${marginMd};
  max-width: 100%;
  width: 100%;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
`;
