import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Container, PageNotFoundLabel } from './PageNotFound.styled';
import { useTranslation } from 'react-i18next';
import { RoutesConfig } from 'navigation/routes';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container>
      <PageNotFoundLabel>{t('Page not found')}</PageNotFoundLabel>
      <Button
        primary
        label={'Return'}
        width={'150rem'}
        onClick={() => navigate(RoutesConfig.Home.fullPath)}
      />
    </Container>
  );
};

export default PageNotFound;
