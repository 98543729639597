import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
} from './ThreeButtonsModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Level } from 'components/Modal/type';

interface IThreeButtonsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  onFirstButton: () => void;
  firstButtonLabel: string;
  firstButtonDisabled?: boolean;
  onSecondButton: () => void;
  secondButtonLabel: string;
  secondButtonDisabled?: boolean;
  onThirdButton?: () => void;
  thirdButtonlabel: string;
  thirdButtonDisabled?: boolean;
  description?: string;
  title: string;
  level?: Level;
  pwId?: string;
}

export const ThreeButtonsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  onFirstButton,
  firstButtonLabel,
  firstButtonDisabled,
  onSecondButton,
  secondButtonLabel,
  secondButtonDisabled,
  onThirdButton,
  thirdButtonlabel,
  thirdButtonDisabled,
  description,
  title,
  level = 'FIRST',
  pwId,
}: IThreeButtonsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', maxWidth: '700rem', position: 'fixed' }}
      level={level}
    >
      <TopContainer data-testid={pwId}>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>{description}</Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            onClick={() => {
              onFirstButton && onFirstButton();
            }}
            secondary
            width="200rem"
            label={firstButtonLabel}
            disabled={firstButtonDisabled}
          />
        </Margin>
        <Margin>
          <Button
            primary
            width="200rem"
            label={secondButtonLabel}
            onClick={() => {
              onSecondButton && onSecondButton();
            }}
            disabled={secondButtonDisabled}
          />
        </Margin>
        <Button
          primary
          width="200rem"
          label={thirdButtonlabel}
          onClick={() => {
            onThirdButton && onThirdButton();
          }}
          disabled={thirdButtonDisabled}
        />
      </ButtonContainer>
    </Modal>
  );
};
