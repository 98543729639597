import { COLORS, marginMd, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 25rem;
  top: 25rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 100;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14rem; //30-16
  justify-content: center;
`;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const EmailRow = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
`;

export const EmailCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Value = styled.span`
  display: inline-block;
`;

export const BlueValue = styled(Value)`
  margin-right: 10rem;
  color: ${COLORS.PRIMARY};
`;

export const Label = styled.label`
  font-size: 14rem;
  font-weight: 300;
  margin-bottom: 10rem;
`;
