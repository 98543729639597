import {
  ButtonContainer,
  Title,
  TitleContainer,
  TopContainer,
  Margin,
  CustomersWrapper,
  CustomerWrapper,
  CodeLabel,
  UsernameLabel,
  LeftSide,
  RightSide,
  LoaderWrapper,
  Subtitle,
  NoContentLabel,
} from './ExternalTwinfieldCustomersModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Level } from 'components/Modal/type';
import {
  IExternalTwinfieldCustomer,
  IExternalTwinfieldCustomerDetails,
} from 'types/Accounting.types';
import {
  useConnectCustomerToTwinfieldCustomer,
  useCreateTwinfieldCustomer,
  useGetTwinfieldCustomerDetails,
  useGetTwinfieldCustomersByPostalCode,
} from './hooks';
import { ExternalTwinfieldCustomerDetailsModal } from './ExternalTwinfieldCustomerDetailsModal/ExternalTwinfieldCustomerDetailsModal';
import Loader from 'components/Loader/Loader';
import { EXTERNAL_TWINFIELD_CUSTOMERS_PER_PAGE } from './constants';
import BasicPagination from './BasicPagination/BasicPagination';
import Spinner from 'components/Spinner/Spinner';

interface IExternalTwinfieldCustomersModalProps {
  level?: Level;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onActionComplete: () => void;
  zipCode: string;
  customerId: number;
}

// If the sales order customer is not connected to Twinfield, we should either create a new twinfield customer,
// or we can choose an existing twinfield account to which we will connect the current customer.
// After the connect/create action is complete, onActionComplete is called
export const ExternalTwinfieldCustomersModal = ({
  level = 'FIRST',
  isOpen,
  setIsOpen,
  onActionComplete,
  zipCode,
  customerId,
}: IExternalTwinfieldCustomersModalProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [details, setDetails] =
    useState<IExternalTwinfieldCustomerDetails | null>(null);

  const {
    data: twinfieldCustomersData,
    isLoading: isLoadingGetTwinfieldCustomerByPostalCode,
  } = useGetTwinfieldCustomersByPostalCode(
    isOpen,
    zipCode,
    page,
    EXTERNAL_TWINFIELD_CUSTOMERS_PER_PAGE
  );

  const {
    data: twinfieldCustomerDetailsData,
    mutate: getTwinfieldCustomerDetails,
    isLoading: isLoadingGetTwinfieldCustomerDetails,
    isSuccess: isSuccessGetTwinfieldCustomerDetails,
    reset: resetGetTwinfieldCustomerDetails,
  } = useGetTwinfieldCustomerDetails();

  useEffect(() => {
    if (isSuccessGetTwinfieldCustomerDetails) {
      setIsDetailsModalOpen(true);
      setDetails(twinfieldCustomerDetailsData);
      resetGetTwinfieldCustomerDetails();
    }
  }, [isSuccessGetTwinfieldCustomerDetails]);

  const twinfieldCustomers = twinfieldCustomersData?.customers
    ? twinfieldCustomersData?.customers
    : [];

  const {
    mutate: connectCustomerToTwinfieldCustomer,
    isSuccess: isSuccessConnectCustomerToTwinfieldCustomer,
    isLoading: isLoadingConnectCustomerToTwinfieldCustomer,
    reset: resetConnectCustomerToTwinfieldCustomer,
  } = useConnectCustomerToTwinfieldCustomer();

  const {
    mutate: createTwinfieldCustomer,
    isSuccess: isSuccessCreateTwinfieldCustomer,
    isLoading: isLoadingCreateTwinfieldCustomer,
    reset: resetCreateTwinfieldCustomer,
  } = useCreateTwinfieldCustomer();

  const isLoading =
    isLoadingConnectCustomerToTwinfieldCustomer ||
    isLoadingCreateTwinfieldCustomer;
  const isSuccess =
    isSuccessConnectCustomerToTwinfieldCustomer ||
    isSuccessCreateTwinfieldCustomer;

  const handleClose = () => {
    setPage(1);
    resetConnectCustomerToTwinfieldCustomer();
    resetCreateTwinfieldCustomer();
    setIsOpen(false);
    setDetails(null);
    onActionComplete(); // Do not proceed with twinfield flow if skip is pressed
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          handleClose();
        }
        setIsOpen(boolean);
      }}
      level={level}
      modalStyle={{ position: 'fixed' }}
    >
      {isLoadingGetTwinfieldCustomerDetails ? <Loader /> : null}
      <TopContainer>
        <TitleContainer>
          <Title>{t('Connect customer to Twinfield')}</Title>
        </TitleContainer>
        {twinfieldCustomers?.length ? (
          <TitleContainer>
            <Subtitle>
              {t('Showing customers with postal code') + ' ' + zipCode}
            </Subtitle>
          </TitleContainer>
        ) : null}
      </TopContainer>
      <CustomersWrapper>
        {isLoadingGetTwinfieldCustomerByPostalCode ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          twinfieldCustomers?.map(
            (twinfieldCustomer: IExternalTwinfieldCustomer, index: number) => (
              <CustomerWrapper key={index}>
                <LeftSide>
                  <CodeLabel>
                    {twinfieldCustomer.customer.twinfield_customer.code}
                  </CodeLabel>
                  :
                  <UsernameLabel>
                    {twinfieldCustomer.customer.name}{' '}
                    {twinfieldCustomer.customer.last_name} |{' '}
                    {twinfieldCustomer.customer.address.full_address}
                  </UsernameLabel>
                </LeftSide>
                <RightSide>
                  <Button
                    label={t('Details')}
                    secondary
                    onClick={() =>
                      getTwinfieldCustomerDetails(
                        twinfieldCustomer.customer.twinfield_customer.code
                      )
                    }
                    disabled={isLoadingGetTwinfieldCustomerDetails}
                  />
                  <Button
                    label={t('Connect')}
                    primary
                    width="100rem"
                    disabled={isLoadingGetTwinfieldCustomerDetails}
                    onClick={() => {
                      connectCustomerToTwinfieldCustomer({
                        code: twinfieldCustomer.customer.twinfield_customer
                          .code,
                        customerId,
                      });
                    }}
                  />
                </RightSide>
              </CustomerWrapper>
            )
          )
        )}
      </CustomersWrapper>
      {!!twinfieldCustomers?.length &&
        !!twinfieldCustomersData?.has_next_page && (
          <BasicPagination
            page={page}
            perPage={EXTERNAL_TWINFIELD_CUSTOMERS_PER_PAGE}
            total={
              (page - 1) * EXTERNAL_TWINFIELD_CUSTOMERS_PER_PAGE +
              (twinfieldCustomersData?.customers
                ? twinfieldCustomers?.length
                : EXTERNAL_TWINFIELD_CUSTOMERS_PER_PAGE)
            }
            hasNextPage={twinfieldCustomersData?.has_next_page}
            loadPage={(page: number) => setPage(page)}
          />
        )}
      {!twinfieldCustomers?.length &&
        !isLoadingGetTwinfieldCustomerByPostalCode && (
          <NoContentLabel>
            {t('No Twinfield customers found for this postal code')}
          </NoContentLabel>
        )}
      <ButtonContainer>
        <Margin>
          <Button
            onClick={() => handleClose()}
            secondary
            width="200rem"
            label={t('Skip')}
            disabled={isLoading}
          />
        </Margin>
        <Button
          primary
          width="200rem"
          label={t('Create new customer')}
          onClick={() => {
            createTwinfieldCustomer(customerId);
          }}
          disabled={isLoading}
        />
      </ButtonContainer>
      <ExternalTwinfieldCustomerDetailsModal
        isOpen={isDetailsModalOpen}
        setIsOpen={setIsDetailsModalOpen}
        onConfirm={() => null}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setDetails(null);
        }}
        details={details}
        level="SECOND"
      />
    </Modal>
  );
};
