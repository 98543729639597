import { COLORS, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10rem;
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  width: 700rem;
  height: 100%;
  justify-content: space-between;

  ${respondTo.midTablet`
    width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6.17rem;
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20rem;
  padding-right: 20rem;
`;

export const Number = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: ${marginMd};
`;

export const Count = styled.div`
  font-size: 40rem;
  font-weight: 500;
  line-height: 16rem;
  letter-spacing: 0.75rem;
  text-align: center;
  color: ${COLORS.PRIMARY};
  margin-top: ${marginSm};
  height: 30rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80rem;
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 170rem;
  height: 41rem;
`;
