import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Description', flexValue: 1, isBlue: false },
  { header: 'Total price', flexValue: 1, isBlue: false },
  { header: 'Credit', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};

export const PURCHASE_INVOICES_PER_PAGE = 10;
