import { queryClient } from 'index';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deleteSalesInvoiceFile,
  editSalesInvoiceFile,
  getSalesInvoiceFiles,
  uploadSalesInvoiceFile,
} from 'services/Invoice/SalesInvoiceService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';

export const useGetSalesInvoiceFiles = (
  perPage: number,
  salesInvoiceId: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_INVOICE_FILES, salesInvoiceId],
    queryFn: ({ pageParam = 1 }) =>
      getSalesInvoiceFiles(salesInvoiceId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
  });

interface IUseDeleteSalesInvoiceFile {
  salesInvoiceId: string;
  salesInvoiceFileId: string;
}
export const useDeleteSalesInvoiceFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeleteSalesInvoiceFile) =>
      deleteSalesInvoiceFile(params.salesInvoiceId, params.salesInvoiceFileId),
    {
      onSuccess: (data) => {
        toast.success(t('File successfully deleted'));
        invalidateFileQueries();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_SALES_INVOICE_FILE,
        });
      },
      onSettled: () => {
        //
      },
    }
  );
};

interface IUseUploadSalesInvoiceFileParams {
  files: any;
  salesInvoiceId: string;
}
export const useUploadSalesInvoiceFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadSalesInvoiceFileParams) => {
      const fileFormData = new FormData();

      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      return uploadSalesInvoiceFile(params.salesInvoiceId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_SALES_INVOICE_FILE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_INVOICE_INFO
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICE_FILES);
      },
    }
  );
};

export const useEditSalesInvoiceFileName = (
  salesInvoiceId: string,
  fileId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (newFileName: string) =>
      editSalesInvoiceFile(salesInvoiceId, fileId, newFileName),
    {
      onSuccess: () => {
        toast.success(t('File name updated'), {
          toastId: ReactMutationKeys.EDIT_SALES_INVOICE_FILE_NAME,
        });
        const shouldInvalidateInfoQueries = false;
        invalidateFileQueries(shouldInvalidateInfoQueries);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_INVOICE_FILE_NAME,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_INVOICE_FILE_NAME,
    }
  );
};
