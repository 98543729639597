import { AnyAction } from '@reduxjs/toolkit';
import { CLEAR_PROMPT, SAVE_PROMPT } from '../constants';

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export interface IPWAReducerState {
  deferredPrompt: BeforeInstallPromptEvent | null;
}

const INIT_STATE: IPWAReducerState = {
  deferredPrompt: null,
};

const pwaReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SAVE_PROMPT:
      return {
        ...state,
        deferredPrompt: action.payload,
      };
    case CLEAR_PROMPT:
      return {
        ...state,
        deferredPrompt: null,
      };
    default:
      return state;
  }
};

export default pwaReducer;
