import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import {
  deleteDefaultAttachmentMailFile,
  getDefaultAttachmentMailFiles,
  uploadDefaultAttachmentMailFile,
} from 'services/DefaultAttachment/DefaultAttachmentService';
import { getDealerPanelWebookURL } from 'services/Logyx/LogyxService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetDealerPanelWebookURL = () =>
  useMutation(() => getDealerPanelWebookURL(), {
    onSuccess: () => {
      // onSuccess
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_DEALER_PANEL_WEBHOOK_URL,
      });
    },
    onSettled: () => {
      // On settled
    },
    mutationKey: ReactMutationKeys.GET_DEALER_PANEL_WEBHOOK_URL,
  });

export enum DefaultAttachmentTypeId {
  QUOTATION = 1,
  SALES_ORDER = 2,
  SALES_INVOICE = 3,
}
export interface IUploadDefaultAttachmentTypeDTO {
  defaultAttachmentTypeId: DefaultAttachmentTypeId;
  files: any;
}

export const useUploadDefaultAttachmentMailFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (uploadDefaultAttachmentDTO: IUploadDefaultAttachmentTypeDTO) => {
      const fileFormData = new FormData();

      if (
        uploadDefaultAttachmentDTO.files &&
        uploadDefaultAttachmentDTO.files.length
      ) {
        uploadDefaultAttachmentDTO.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }

      return uploadDefaultAttachmentMailFile(
        uploadDefaultAttachmentDTO.defaultAttachmentTypeId,
        fileFormData
      );
    },
    {
      onSuccess: () => {
        toast.success(t('Successfully uploaded default attachment'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_DEFAULT_ATTACHMENT_MAIL_FILES
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_DEFAULT_ATTACHMENT_MAIL_FILE,
    }
  );
};

export const useDeleteDefaultAttachmentMailFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (defaultAttachmentId: number) =>
      deleteDefaultAttachmentMailFile(defaultAttachmentId),
    {
      onSuccess: () => {
        toast.success(t('Successfully deleted default attachment'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_DEFAULT_ATTACHMENT_MAIL_FILES
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_DEFAULT_ATTACHMENT_MAIL_FILE,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.DELETE_DEFAULT_ATTACHMENT_MAIL_FILE,
    }
  );
};

export const useGetDefaultAttachmentMailFiles = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_DEFAULT_ATTACHMENT_MAIL_FILES],
    queryFn: () => {
      return getDefaultAttachmentMailFiles();
    },
  });
