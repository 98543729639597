import i18n from 'providers/i18n/i18n';
import { store } from 'store/store';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from './mobile/postMobileMessage';

export const changeLanguage = (
  lang: 'en' | 'nl' | 'de' | 'no' | 'fr' | 'sr'
) => {
  if (!lang) {
    return;
  }
  i18n.changeLanguage(lang);
  if (store.getState().commonInfo.isMobileApp) {
    const mobileMessage: IMobileMessage = {
      type: MobileMessageTypes.CHANGE_LANGUAGE,
      payload: lang,
    };
    postMobileMessage(mobileMessage);
  }
};
