import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCommonData } from './hooks';
import {
  ISetCommonDataPayload,
  setCommonData,
} from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';

interface ICommonDataProviderProps {
  children: React.ReactNode;
}

const CommonDataProvider = ({ children }: ICommonDataProviderProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: IRootReducerState) => state.userInfo.id
  );

  const {
    mutate: fetchCommonData,
    data: commonData,
    isSuccess,
  } = useGetCommonData();

  useEffect(() => {
    if (isAuthenticated) {
      fetchCommonData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      const setCommonDataPayload: ISetCommonDataPayload = {
        countries: commonData.countries,
        notificationTypes: commonData.notification_types,
        quotationStatuses: commonData.quotation_statuses,
        purchaseOrderStatuses: commonData.purchase_order_statuses,
        salesOrderStatuses: commonData.sales_order_statuses,
        salesInvoiceStatuses: commonData.sales_invoice_statuses,
        purchaseInvoiceStatuses: commonData.purchase_invoice_statuses,
        quotationActions: commonData.action_types,
        customerTitles: commonData.customer_titles,
        twinfieldTransactionStatuses: commonData.twinfield_transaction_statuses,
      };
      dispatch(setCommonData(setCommonDataPayload));
    }
  }, [isSuccess]);

  return <>{children}</>;
};

export default CommonDataProvider;
