import { COLORS, H4 } from 'assets/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductAttribute } from 'types/Product.types';
import { formatAttributeObjectIntoArray } from 'utils/productAttributeFormatting/formatAttributeObjectIntoArray';
import { formatNumber } from 'utils/numberFormatter';
import { getProductCardTitle, getProductCardType } from '../helpers';
import {
  ActionsRow,
  AttributesWrapper,
  Card,
  ColumnContainer,
  CostContainerAbsolute,
  CostLabel,
  CostLabelTitle,
  DeleteIcon,
  DeleteLabel,
  DescriptionContainer,
  EditDeleteWrapperRelative,
  EditIcon,
  EditLabel,
  EyeWrapper,
  GreenLine,
  Label,
  LabelTitle,
  LogyxAttributesLabel,
  MainContainer,
  EyeLabelWrapper,
  TextColumnContainer,
  TextContainer,
  TitleContainer,
  TitleLabel,
  TitleWrapper,
  TypeLabel,
  WrapperRelative,
} from './QuotationProductCard.styled';
import { IQuotationLine } from 'types/Quotations.types';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute, QuestionType } from 'store/Logyx/types';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import {
  CaretDown,
  CaretUp,
  Eye,
  EyeSlash,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import { getBooleanAttributeLabel } from 'components/DragAndDrop/DraggableLines/helpers';

interface IQuotationProductCardProps {
  quotationLine: IQuotationLine;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
}
export const QuotationProductCard = ({
  quotationLine,
  onDeleteClick,
  onEditClick,
}: IQuotationProductCardProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const canEditQuotation = useCan(Actions.EDIT_QUOTATION);
  const [isPurchasePriceHidden, setIsPurchasePriceHidden] =
    useState<boolean>(true);

  const attributes = formatAttributeObjectIntoArray(
    quotationLine.product?.attributes
  );
  const logyxAttributes = formatLogyxAttributesObjectIntoArray(
    quotationLine.product?.logyx_configuration_intent?.data
  );
  const allAttributes = [...logyxAttributes, ...attributes];

  const renderProductAttributes = () => {
    return allAttributes.map(
      (attribute: IProductAttribute | ILogyxAttribute, index: number) => {
        return (
          <AttributesWrapper key={index}>
            {logyxAttributes.length && index === 0 ? (
              <>
                <GreenLine />
                <LogyxAttributesLabel>
                  {t('Logyx Attributes')}
                </LogyxAttributesLabel>
              </>
            ) : null}
            <TextColumnContainer>
              <LabelTitle>{attribute.fieldName}</LabelTitle>
              <Label>
                {attribute.type === QuestionType.BOOLEAN
                  ? t(getBooleanAttributeLabel(attribute.value))
                  : attribute.value}
              </Label>
            </TextColumnContainer>
            {logyxAttributes.length && index === logyxAttributes.length - 1 ? (
              <GreenLine />
            ) : null}
          </AttributesWrapper>
        );
      }
    );
  };

  return (
    <Card key={quotationLine.id}>
      <TitleContainer>
        <TitleWrapper>
          <TitleLabel>{getProductCardTitle(quotationLine, t)}</TitleLabel>
          <TypeLabel>{getProductCardType(quotationLine, t)}</TypeLabel>
        </TitleWrapper>
        <ActionsRow>
          {onEditClick && canEditQuotation ? (
            <EditDeleteWrapperRelative onClick={() => onEditClick()}>
              <EditLabel>{t('Edit')}</EditLabel>
              <EditIcon>
                <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
              </EditIcon>
            </EditDeleteWrapperRelative>
          ) : (
            <div />
          )}
          {onDeleteClick && canEditQuotation ? (
            <EditDeleteWrapperRelative onClick={() => onDeleteClick()}>
              <DeleteLabel>{t('Delete')}</DeleteLabel>
              <DeleteIcon>
                <Icon
                  svg={Trash}
                  size={18}
                  color={COLORS.RED_200}
                  weight="regular"
                />
              </DeleteIcon>
            </EditDeleteWrapperRelative>
          ) : (
            <div />
          )}
          {quotationLine.working_hours_line ? (
            <div />
          ) : (
            <WrapperRelative>
              <Icon
                svg={isExpanded ? CaretUp : CaretDown}
                color={COLORS.PRIMARY}
                size={20}
                weight="regular"
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </WrapperRelative>
          )}
        </ActionsRow>
      </TitleContainer>
      {quotationLine.description && (
        <DescriptionContainer>
          <TextContainer>
            <Label>{quotationLine.description}</Label>
          </TextContainer>
        </DescriptionContainer>
      )}
      {!quotationLine.working_hours_line && (
        <MainContainer isExpanded={isExpanded}>
          <ColumnContainer isExpanded={isExpanded}>
            <H4>{t('Placement').toUpperCase()}</H4>
            <TextContainer>
              <Label>{quotationLine.placement}</Label>
            </TextContainer>
          </ColumnContainer>
          {isExpanded && (
            <ColumnContainer isExpanded={isExpanded}>
              <H4>{t('General').toUpperCase()}</H4>
              {/* <TextContainer>
                    <LabelTitle>{t('Status')}</LabelTitle>
                    <Label>{''}</Label>
                  </TextContainer>
                  <TextContainer>
                    <LabelTitle>{t('Payment')}</LabelTitle>
                    <Label>{''}</Label>
                  </TextContainer>
                  <TextContainer>
                    <LabelTitle>{t('Delivery date')}</LabelTitle>
                    <Label>{''}</Label>
                  </TextContainer> */}
              <TextColumnContainer>
                <LabelTitle>{t('Supplier')}</LabelTitle>
                <Label>{quotationLine.supplier?.company_name}</Label>
              </TextColumnContainer>
              {quotationLine?.stock_item_line && (
                <TextColumnContainer>
                  <LabelTitle>{t('Item supplier number')}</LabelTitle>
                  <Label>{quotationLine.stock_item?.number_supplier}</Label>
                </TextColumnContainer>
              )}
              <TextColumnContainer>
                <LabelTitle>{t('Quantity')}</LabelTitle>
                <Label>{formatNumber(quotationLine.quantity)}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Sales price')}</LabelTitle>
                <Label>{`${currencySymbol}${formatNumber(
                  quotationLine?.product_sales_price
                )}`}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Total discount')}</LabelTitle>
                <Label>{`${currencySymbol}${
                  quotationLine?.discount_amount
                    ? formatNumber(quotationLine?.discount_amount)
                    : '0'
                }`}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Total purchase cost')}</LabelTitle>
                <EyeLabelWrapper>
                  <Label secure={isPurchasePriceHidden}>
                    {`${currencySymbol}${formatNumber(
                      quotationLine?.product_purchase_price *
                        +quotationLine?.quantity
                    )}`}
                  </Label>
                  <EyeWrapper
                    data-testid={'eye-icon'}
                    onClick={() =>
                      setIsPurchasePriceHidden(!isPurchasePriceHidden)
                    }
                  >
                    <Icon
                      svg={isPurchasePriceHidden ? Eye : EyeSlash}
                      color={COLORS.BLACK}
                      size={20}
                    />
                  </EyeWrapper>
                </EyeLabelWrapper>
              </TextColumnContainer>
            </ColumnContainer>
          )}
          {isExpanded && (
            <ColumnContainer isExpanded={isExpanded}>
              <H4>{t('Attributes').toUpperCase()}</H4>
              {renderProductAttributes()}
            </ColumnContainer>
          )}
        </MainContainer>
      )}
      {quotationLine.working_hours_line && (
        <>
          <TextContainer>
            <LabelTitle>{t('Rate')}</LabelTitle>
            <Label>{`${currencySymbol}${formatNumber(
              quotationLine.working_hours_rate
            )}`}</Label>
          </TextContainer>
          <TextContainer>
            <LabelTitle>{t('Hours')}</LabelTitle>
            <Label>{`${formatNumber(quotationLine.quantity)}`}</Label>
          </TextContainer>
        </>
      )}

      <CostContainerAbsolute isExpanded={isExpanded}>
        <CostLabelTitle>{t('Total amount')}</CostLabelTitle>
        <CostLabel>{`${currencySymbol}${formatNumber(
          quotationLine.total_amount
        )}`}</CostLabel>
      </CostContainerAbsolute>
    </Card>
  );
};
