import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import {
  CloseContainer,
  Title,
  TimeWrapper,
  Text,
  Column,
  Row,
  ApplyWrapper,
  TitleContainer,
  UnderlineText,
  LinkText,
  LoaderWrapper,
  Scrollable,
  LeftAlign,
  BottomWrapper,
  RowColumn,
  GroupText,
  ArrivalTime,
  DoubleColumn,
  InsideColumn,
  ColumnTitle,
  InnerColumn,
  DateLabel,
} from './ViewAppointmentModal.styled';
import { COLORS, H5, marginXs } from 'assets/styled';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Button from 'components/Button/Button';
import { Danger } from 'components/Modal/Danger/Danger';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import {
  useConfirmAppointment,
  useGetAppointmentReport,
  useMarkAsFailedAppointment,
  useMarkAsSuccessfulAppointment,
  useGetAppointmentLatestEmailStatus,
  useUpdateIsAppointmentProcessedInOffice,
} from './hooks';
import moment from 'moment';
import { SalesOrderLines } from '../SalesOrderLines/SalesOrderLines';
import {
  AppointmentEmailType,
  AppointmentType,
  AppointmentTypeIds,
} from 'types/Appointment.types';
import { ThreeButtonsModal } from 'components/Modal/ThreeButtonsModal/ThreeButtonsModal';
import { getAppointmentShippingData } from '../helpers';
import { CommentsModal } from '../CommentsModal/CommentsModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  getEmailDetails,
  renderCustomerAddress,
  renderCustomerForGeneralAppointment,
  renderCustomerName,
  renderCustomerPhone,
} from './helpers';
import { IShippingData } from 'types/Quotations.types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { PreviewAppointmentReportModal } from './PreviewAppointmentReportModal/PreviewAppointmentReportModal';
import { IUser } from 'types/User.types';
import { IWorkerAndResource } from '../WorkersModal/type';
import { EmailStatuses } from 'types/Email.types';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import {
  ChatCircle,
  EnvelopeSimple,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import {
  useDeleteAppointment,
  useGetSingleAppointment,
} from '../PlanningTab/hooks';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';

export interface IViewAppointmentModalProps {
  onEdit?: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  id: string;
  viewOnly?: boolean;
}

export const ViewAppointmentModal = ({
  isOpen,
  setIsOpen,
  id,
  onEdit,
  viewOnly = false,
}: IViewAppointmentModalProps) => {
  const navigate = useNavigate();
  const { data, isLoading: getSingleAppointmentIsLoading } =
    useGetSingleAppointment(id!, isOpen);

  const emailDetails = getEmailDetails(data?.appointment);

  const { data: emailStatus } = useGetAppointmentLatestEmailStatus(id!, isOpen);

  const { mutate: deleteAppointment, isSuccess: sentEmailSuccess } =
    useDeleteAppointment();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] =
    useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [
    isSendAppointmentConfirmationReminderEmailModalOpen,
    setIsSendAppointmentConfirmationReminderEmailModalOpen,
  ] = useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteConfirmModalOpen, setIsAddEmailNoteConfirmModalOpen] =
    useState<boolean>(false);
  const [appointmentEmailType, setAppointmentEmailType] =
    useState<AppointmentEmailType>();
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);

  const [isConfirmFailedModalOpen, setIsConfirmFailedModalOpen] =
    useState<boolean>(false);
  const [isConfirmSuccessfulModalOpen, setIsConfirmSuccessfulModalOpen] =
    useState<boolean>(false);

  const canEditAppointment = useCan(Actions.EDIT_APPOINTMENT);
  const canDeleteAppointment = useCan(Actions.DELETE_APPOINTMENT);

  const {
    mutate: confirmAppointment,
    isSuccess: confirmAppointmentSuccess,
    isLoading: _confirmAppointmentIsLoading,
  } = useConfirmAppointment(id!);
  const isMutatingConfirmAppointment = useIsMutating(
    ReactMutationKeys.CONFIRM_APPOINTMENT
  );
  const confirmAppointmentIsLoading =
    _confirmAppointmentIsLoading || !!isMutatingConfirmAppointment;
  const {
    mutate: markAsFailedAppointment,
    isLoading: markAsFailedAppointmentIsLoading,
  } = useMarkAsFailedAppointment(id!);
  const {
    mutate: markAsSuccessfulAppointment,
    isLoading: markAsSuccessfulAppointmentIsLoading,
  } = useMarkAsSuccessfulAppointment(id!);

  const { t } = useTranslation();

  useEffect(() => {
    if (sentEmailSuccess || confirmAppointmentSuccess) {
      setIsOpen(false);
    }
  }, [sentEmailSuccess, confirmAppointmentSuccess]);

  const isDateInPast = (): boolean =>
    moment(data?.appointment?.date_to) < moment();

  const appointmentShippingData = getAppointmentShippingData(data?.appointment);

  const { data: appointmentReport, isLoading: isLoadingReport } =
    useGetAppointmentReport(id!, isOpen);
  const hasNewQuotation =
    appointmentReport?.report?.new_quotation && !isLoadingReport;

  const {
    mutate: updateIsAppointmentProcessedInOffice,
    isLoading: isLoadingUpdateIsAppointmentProcessedInOffice,
  } = useUpdateIsAppointmentProcessedInOffice(id!);

  const isGeneral = data?.appointment?.type?.name === AppointmentType.GENERAL;

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!isMutatingConfirmAppointment) {
          setIsOpen(boolean);
        }
      }}
      level="FIRST"
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <div>
        <CloseContainer>
          <Close
            onClick={() => {
              if (!isMutatingConfirmAppointment) {
                setIsOpen(false);
              }
            }}
          />
        </CloseContainer>
        {getSingleAppointmentIsLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <>
            <TitleContainer>
              <Title>
                {data?.appointment?.type.id === AppointmentTypeIds.GENERAL
                  ? data?.appointment?.purpose.toUpperCase()
                  : t(
                      data?.appointment?.type.name.charAt(0) +
                        data?.appointment?.type.name.slice(1).toLowerCase()
                    ).toUpperCase()}
              </Title>
              {!viewOnly && (
                <>
                  {(data?.appointment?.customer ||
                    data?.appointment?.quotation ||
                    data?.appointment?.sales_order) && (
                    <Icon
                      data-testid="send-email"
                      svg={
                        data?.appointment?.sent_to_customer
                          ? emailStatus?.status === EmailStatuses.SENT
                            ? MailSent
                            : emailStatus?.status === EmailStatuses.PENDING
                            ? MailPending
                            : MailFailed
                          : EnvelopeSimple
                      }
                      size={25}
                      onClick={() => {
                        if (data?.appointment?.confirmed) {
                          setIsSendAppointmentConfirmationReminderEmailModalOpen(
                            true
                          );
                        } else {
                          setAppointmentEmailType(AppointmentEmailType.OFFER);
                          setIsAddEmailNoteModalOpen(true);
                        }
                      }}
                      color={COLORS.PRIMARY}
                      marginLeft={marginXs}
                    />
                  )}
                  <Icon
                    svg={ChatCircle}
                    color={COLORS.PRIMARY}
                    size={25}
                    onClick={() => setIsCommentsModalOpen(true)}
                    marginLeft={marginXs}
                  />
                  {/* Only users can be edited in past appointments. General appointments dont have users for editing */}
                  {isGeneral && isDateInPast() ? null : (
                    <Icon
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      size={25}
                      onClick={() => {
                        onEdit && onEdit();
                        setIsOpen(false);
                      }}
                      marginLeft={marginXs}
                      disabled={
                        confirmAppointmentIsLoading || !canEditAppointment
                      }
                    />
                  )}
                  <Icon
                    data-testid="delete-appointment "
                    svg={Trash}
                    color={COLORS.RED_200}
                    size={25}
                    onClick={() => setOpenDeleteModal(true)}
                    marginLeft={marginXs}
                    disabled={!canDeleteAppointment}
                  />
                </>
              )}
            </TitleContainer>
            <Scrollable>
              {!appointmentReport ? null : (
                <Column>
                  <LeftAlign>
                    <Button
                      secondary
                      label={t('Open report')}
                      width="200rem"
                      onClick={() => setIsReportModalOpen(true)}
                    />
                  </LeftAlign>
                </Column>
              )}
              {data?.appointment?.purpose && (
                <Column>
                  <H5>{t('PURPOSE')}</H5>
                  <Row>{data?.appointment?.purpose}</Row>
                </Column>
              )}
              <Column>
                <H5>{t('DATE & TIME')}</H5>
                <RowColumn>
                  <InnerColumn>
                    <DateLabel>{t('From')}:</DateLabel>
                    <GroupText>
                      <Text>
                        {moment(data?.appointment?.date_from).format(
                          'DD-MM-YYYY'
                        )}
                      </Text>
                      <Text>
                        {moment(data?.appointment?.date_from).format('HH:mm')}
                      </Text>
                    </GroupText>
                  </InnerColumn>
                  <InnerColumn>
                    <DateLabel>{t('To')}:</DateLabel>
                    <GroupText>
                      <Text>
                        {moment(data?.appointment?.date_to).format(
                          'DD-MM-YYYY'
                        )}
                      </Text>
                      <Text>
                        {moment(data?.appointment?.date_to).format('HH:mm')}
                      </Text>
                    </GroupText>
                  </InnerColumn>
                </RowColumn>
              </Column>
              {data?.appointment?.arrival_from && (
                <Column>
                  <ArrivalTime>{t('Arrival time')}</ArrivalTime>
                  <TimeWrapper>
                    <Text>
                      {moment(data?.appointment?.arrival_from).format('HH:mm')}
                    </Text>
                    <Text>-</Text>
                    <Text>
                      {moment(data?.appointment?.arrival_to).format('HH:mm')}
                    </Text>
                  </TimeWrapper>
                </Column>
              )}
              {data?.appointment?.sales_order_id &&
                data?.appointment?.type.id !==
                  Number(AppointmentTypeIds.SERVICE) && (
                  <Column>
                    <H5>{t('PRODUCTS')}</H5>
                    <SalesOrderLines
                      isReadOnly
                      selectedSalesOrderId={data?.appointment?.sales_order_id}
                      selectedSalesOrderLineIds={data?.appointment?.appointment_sales_order_lines?.map(
                        (line: any) => line.id
                      )}
                    />
                  </Column>
                )}
              <Column>
                <H5>{t('CUSTOMER')}</H5>
                {data?.appointment?.customer ? (
                  <Row>
                    <LinkText
                      isAlternativeShippingData={!!appointmentShippingData}
                      href={SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                        ':id',
                        data?.appointment?.customer.id
                      )}
                      onClick={(e: any) => {
                        e.preventDefault();
                        !appointmentShippingData &&
                          navigate(
                            SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                              ':id',
                              data?.appointment?.customer.id
                            )
                          );
                      }}
                    >
                      {renderCustomerName(
                        data?.appointment,
                        appointmentShippingData as IShippingData
                      )}
                    </LinkText>
                    <UnderlineText>
                      {renderCustomerPhone(data?.appointment)}
                    </UnderlineText>
                  </Row>
                ) : (
                  <Row>
                    <LinkText
                      isAlternativeShippingData={!!appointmentShippingData}
                      href={() => {
                        if (data?.appointment?.quotation) {
                          return SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                            ':id',
                            data?.appointment?.quotation?.id
                          );
                        } else if (data?.appointment?.sales_order) {
                          return SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                            ':id',
                            data?.appointment?.sales_order?.id
                          );
                        }
                        return null;
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        const dynamicId =
                          data?.appointment?.quotation?.id ||
                          data?.appointment?.sales_order?.id ||
                          null;

                        dynamicId &&
                          !appointmentShippingData &&
                          navigate(
                            SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                              ':id',
                              dynamicId
                            )
                          );
                      }}
                    >
                      {renderCustomerForGeneralAppointment(
                        data?.appointment,
                        appointmentShippingData as IShippingData
                      )}
                    </LinkText>
                    <UnderlineText>
                      {renderCustomerPhone(data?.appointment)}
                    </UnderlineText>
                  </Row>
                )}
                {data?.google_maps_url ? (
                  <LinkText
                    href={data?.google_maps_url}
                    onClick={(e: any) => {
                      e.preventDefault();
                      window.open(data?.google_maps_url);
                    }}
                  >
                    {renderCustomerAddress(
                      data?.appointment,
                      appointmentShippingData as IShippingData
                    )}
                  </LinkText>
                ) : (
                  <Text>
                    {renderCustomerAddress(
                      data?.appointment,
                      appointmentShippingData as IShippingData
                    )}
                  </Text>
                )}
              </Column>
              {hasNewQuotation ? (
                <Column>
                  <H5>{t('NEW QUOTATION')}</H5>
                  <Row>
                    <LinkText
                      href={RoutesConfig.SingleQuotation.fullPath.replace(
                        ':id/*',
                        appointmentReport?.report?.new_quotation?.id
                      )}
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(
                          RoutesConfig.SingleQuotation.fullPath.replace(
                            ':id/*',
                            appointmentReport?.report?.new_quotation?.id
                          )
                        );
                      }}
                    >
                      {appointmentReport?.report?.new_quotation?.number}
                    </LinkText>
                  </Row>
                </Column>
              ) : null}
              {data?.appointment?.quotation_id ? (
                <Column>
                  <H5>
                    {hasNewQuotation ? t('ORIGINAL QUOTATION') : t('QUOTATION')}
                  </H5>
                  <Row>
                    <LinkText
                      href={RoutesConfig.SingleQuotation.fullPath.replace(
                        ':id/*',
                        data?.appointment?.quotation_id
                      )}
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(
                          RoutesConfig.SingleQuotation.fullPath.replace(
                            ':id/*',
                            data?.appointment?.quotation_id
                          )
                        );
                      }}
                    >
                      {data?.appointment?.quotation?.number}
                    </LinkText>
                  </Row>
                </Column>
              ) : null}

              {data?.appointment?.sales_order_id ? (
                <Column>
                  <H5>{t('SALES ORDER')}</H5>
                  <Row>
                    <LinkText
                      href={RoutesConfig.SingleSalesOrder.fullPath.replace(
                        ':id/*',
                        data?.appointment?.sales_order_id
                      )}
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(
                          RoutesConfig.SingleSalesOrder.fullPath.replace(
                            ':id/*',
                            data?.appointment?.sales_order_id
                          )
                        );
                      }}
                    >
                      {data?.appointment?.sales_order.number}
                    </LinkText>
                  </Row>
                </Column>
              ) : null}
              {data?.appointment?.description && (
                <Column>
                  <H5>{t('DESCRIPTION')}</H5>
                  <Row>{data?.appointment?.description}</Row>
                </Column>
              )}
              <Column>
                <H5>{t('USERS')}</H5>
                {data?.appointment?.users?.map((user: IUser) => {
                  return (
                    <Row key={user.id}>
                      <Text>{user?.name + ' ' + user.last_name}</Text>
                    </Row>
                  );
                })}
              </Column>
              <Column>
                <H5>{t('WORKERS & RESOURCES')}</H5>
                <DoubleColumn>
                  <InsideColumn>
                    <ColumnTitle>{t('WORKERS')}</ColumnTitle>

                    {data?.appointment?.workers.map(
                      (worker: IWorkerAndResource) => {
                        return (
                          <Row key={worker.id}>
                            <Text>{worker?.name}</Text>
                          </Row>
                        );
                      }
                    )}
                  </InsideColumn>
                  <InsideColumn>
                    <ColumnTitle>{t('RESOURCES')}</ColumnTitle>
                    {data?.appointment?.resources.map(
                      (resource: IWorkerAndResource) => {
                        return (
                          <Row key={resource.id}>
                            <Text>{resource?.name}</Text>
                          </Row>
                        );
                      }
                    )}
                  </InsideColumn>
                </DoubleColumn>
              </Column>
            </Scrollable>
          </>
        )}

        {!viewOnly && (
          <BottomWrapper>
            <Checkbox
              label={t('Processed in office')}
              onChange={() => {
                if (!isLoadingUpdateIsAppointmentProcessedInOffice) {
                  if (data?.appointment?.processed_in_office) {
                    updateIsAppointmentProcessedInOffice(false);
                  } else {
                    updateIsAppointmentProcessedInOffice(true);
                  }
                }
              }}
              isChecked={data?.appointment?.processed_in_office}
              disabled={
                isLoadingUpdateIsAppointmentProcessedInOffice ||
                !canEditAppointment
              }
            />
            <ApplyWrapper>
              {!getSingleAppointmentIsLoading && (
                <>
                  {!isDateInPast() && !data?.appointment?.confirmed && (
                    <Button
                      disabled={
                        confirmAppointmentIsLoading || !canEditAppointment
                      }
                      onClick={() => setIsConfirmationModalOpen(true)}
                      primary
                      width="150rem"
                      label={t('Confirm')}
                    />
                  )}
                  {!data?.appointment?.is_failed ? (
                    <Button
                      onClick={() => {
                        setIsConfirmFailedModalOpen(true);
                      }}
                      disabled={
                        confirmAppointmentIsLoading ||
                        markAsSuccessfulAppointmentIsLoading ||
                        markAsFailedAppointmentIsLoading ||
                        !canEditAppointment
                      }
                      redOutline
                      width="150rem"
                      label={t('Mark as failed')}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        setIsConfirmSuccessfulModalOpen(true);
                      }}
                      disabled={
                        markAsSuccessfulAppointmentIsLoading ||
                        markAsFailedAppointmentIsLoading ||
                        !canEditAppointment
                      }
                      primary
                      green
                      width="150rem"
                      label={t('Mark as successful')}
                    />
                  )}
                </>
              )}
            </ApplyWrapper>
          </BottomWrapper>
        )}
        <ConfirmModal
          level="SECOND"
          title={t('Mark as failed')}
          description={`${t(
            'Are you sure you want to mark this appointment as failed'
          )}?`}
          isOpen={isConfirmFailedModalOpen}
          setIsOpen={setIsConfirmFailedModalOpen}
          onConfirm={() => {
            markAsFailedAppointment(id);
            setIsOpen(false);
            setIsConfirmFailedModalOpen(false);
          }}
          onCancel={() => setIsConfirmFailedModalOpen(false)}
        />
        <ConfirmModal
          level="SECOND"
          title={t('Mark as successful')}
          description={`${t(
            'Are you sure you want to mark this appointment as successful'
          )}?`}
          isOpen={isConfirmSuccessfulModalOpen}
          setIsOpen={setIsConfirmSuccessfulModalOpen}
          onConfirm={() => {
            markAsSuccessfulAppointment(id);
            setIsOpen(false);
            setIsConfirmSuccessfulModalOpen(false);
          }}
          onCancel={() => setIsConfirmSuccessfulModalOpen(false)}
        />
        <Modal
          level={'SECOND'}
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          modalStyle={{ position: 'fixed', margin: 'auto' }}
        >
          <Danger
            submit={{
              onClick: () => {
                deleteAppointment(id);
                setOpenDeleteModal(false);
              },
              text: t('Delete'),
              disabled: false,
            }}
            cancel={{ onClick: () => setOpenDeleteModal(false) }}
            title={t('Delete Appointment')}
            description={
              t('Are you sure you want to delete this appointment') + '?'
            }
          />
        </Modal>
        <ThreeButtonsModal
          level="SECOND"
          isOpen={isSendAppointmentConfirmationReminderEmailModalOpen}
          setIsOpen={setIsSendAppointmentConfirmationReminderEmailModalOpen}
          onCancel={() =>
            setIsSendAppointmentConfirmationReminderEmailModalOpen(false)
          }
          firstButtonLabel={t('Cancel')}
          onFirstButton={() =>
            setIsSendAppointmentConfirmationReminderEmailModalOpen(false)
          }
          secondButtonLabel={t('Reminder')}
          onSecondButton={() => {
            setAppointmentEmailType(AppointmentEmailType.REMINDER);
            setIsAddEmailNoteModalOpen(true);
            setIsSendAppointmentConfirmationReminderEmailModalOpen(false);
          }}
          thirdButtonlabel={t('Confirmation')}
          onThirdButton={() => {
            setAppointmentEmailType(AppointmentEmailType.CONFIRMATION);
            setIsAddEmailNoteModalOpen(true);
            setIsSendAppointmentConfirmationReminderEmailModalOpen(false);
          }}
          title={t('Send E-mail')}
          description={
            t('Do you want to send the appointment confirmation or reminder') +
            '?'
          }
        />
        <ThreeButtonsModal
          level="SECOND"
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          onCancel={() => setIsConfirmationModalOpen(false)}
          firstButtonLabel={t('Cancel')}
          onFirstButton={() => setIsConfirmationModalOpen(false)}
          secondButtonLabel={t('Confirm with E-mail')}
          onSecondButton={() => {
            setIsAddEmailNoteConfirmModalOpen(true);
            setIsConfirmationModalOpen(false);
          }}
          thirdButtonlabel={t('Confirm without E-mail')}
          onThirdButton={() => {
            confirmAppointment({
              appointmentId: id,
              send_confirm_email: false,
            });
            setIsConfirmationModalOpen(false);
          }}
          title={t('Confirm appointment')}
          description={t('Do you also want to send a confirmation email') + '?'}
        />
      </div>
      <CommentsModal
        setIsOpen={setIsCommentsModalOpen}
        isOpen={isCommentsModalOpen}
        onCancel={() => setIsCommentsModalOpen(false)}
        appointmentId={data?.appointment?.id}
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.appointment?.id}
        type={AddEmailNoteModalType.APPOINTMENT}
        appointmentEmailType={appointmentEmailType}
        emailDetails={emailDetails}
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteConfirmModalOpen}
        setIsOpen={setIsAddEmailNoteConfirmModalOpen}
        onCancel={() => setIsAddEmailNoteConfirmModalOpen(false)}
        id={data?.appointment?.id}
        type={AddEmailNoteModalType.APPOINTMENT_CONFIRM}
        onConfirmAppointmentSuccess={() => setIsOpen(false)}
        emailDetails={emailDetails}
      />
      <PreviewAppointmentReportModal
        isOpen={isReportModalOpen}
        setIsOpen={setIsReportModalOpen}
        previewReportData={appointmentReport}
        appointment={data?.appointment}
      />
    </Modal>
  );
};

export default ViewAppointmentModal;
