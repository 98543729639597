import { COLORS, gapSm } from 'assets/styled';
import Button from 'components/Button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${gapSm};
`;

export const IconColorInvertButton = styled(Button)`
  svg {
    filter: brightness(0) invert(1);
  }
`;

export const HoverIconColorInvertButton = styled(Button)`
  &:hover svg {
    filter: brightness(0) invert(1);
  }
`;

export const IntervalDurationLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  color: ${COLORS.PRIMARY};
`;
