import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title,
  TitleContainer,
  CloseContainer,
  ButtonContainer,
  ResourceContainer,
  ResourceName,
  ResourceDescription,
} from './PreviewResourcesModal.styled';

export interface IPreviewResourcesModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  resources: IWorkerAndResource[];
}

const PreviewResourcesModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  resources,
}: IPreviewResourcesModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed' }}
    >
      <div>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Resources')}</Title>
        </TitleContainer>

        {resources?.map((resource: IWorkerAndResource, index: number) => {
          return (
            <ResourceContainer
              isLast={index === resources.length - 1}
              key={index}
            >
              <ResourceName>{resource.name}</ResourceName>
              <ResourceDescription>{resource.description}</ResourceDescription>
            </ResourceContainer>
          );
        })}

        <ButtonContainer>
          <Button
            width={'200rem'}
            secondary
            onClick={() => {
              onCancel();
            }}
            label={t('Close')}
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default PreviewResourcesModal;
