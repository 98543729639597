import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createPurchaseOrderBatch } from 'services/PurchaseOrder/PurchaseOrderService';
import { ICreatePurchaseOrderData } from 'types/PurchaseOrders.types';

export const useGetPrepopulateFormData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulatePurchaseOrderData;
  } else {
    return null;
  }
};

export const useCreatePurchaseOrderBatch = () => {
  const { t } = useTranslation();
  return useMutation(
    (createPurchaseOrderBatchData: ICreatePurchaseOrderData[]) =>
      createPurchaseOrderBatch(createPurchaseOrderBatchData),
    {
      onSuccess: () => {
        toast.success(t('Purchase orders created'), {
          className: ReactMutationKeys.CREATE_PURCHASE_ORDER,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDERS);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_ORDER_PURCHASE_ORDERS
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_PURCHASE_ORDER_BATCH,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_PURCHASE_ORDER_BATCH,
    }
  );
};
