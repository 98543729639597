import {
  Container,
  TabContainer,
  Tab,
  CreateNewPurchaseOrderLabel,
} from './NewPurchaseOrderPage.styled';
import { useEffect, useState } from 'react';
import {
  CreatePurchaseOrderTab,
  ICreatePurchaseOrderProductLine,
} from './constants';
import {
  useCreatePurchaseOrder,
  useGetPrepopulatePurchaseOrderData,
} from './hooks';
import { getInitialItemsData, getInitialSupplierDetailsData } from './helpers';
import { ICreatePurchaseOrderSupplierDetails } from './SupplierTab/constants';
import { ICreatePurchaseOrderItem } from './ItemsTab/constants';
import SupplierTab from './SupplierTab/SupplierTab';
import OverviewTab from './OverviewTab/OverviewTab';
import { useTranslation } from 'react-i18next';
import ItemsTab from './ItemsTab/ItemsTab';
import { ICreatePurchaseOrderData } from 'types/PurchaseOrders.types';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { ICreateAddressDTO } from 'types/Address.types';
import { ICreateSupplierDTO } from '../NewSupplierPage/types';
import { useGetSingleSupplier } from '../SingleSupplierPage/hooks';

const NewPurchaseOrderPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prepopulatePurchaseOrderData = useGetPrepopulatePurchaseOrderData();
  const initialCountry = useGetInitialCountry();

  const [activeTab, setActiveTab] = useState<string>(
    CreatePurchaseOrderTab.SUPPLIER_DETAILS
  );

  const { id } = useParams();
  useGetSingleSupplier(id!);

  const [supplierDetails, setSupplierDetails] =
    useState<ICreatePurchaseOrderSupplierDetails>(
      getInitialSupplierDetailsData(
        prepopulatePurchaseOrderData.supplierData,
        initialCountry.cca2
      )
    );

  const [items, setItems] = useState<ICreatePurchaseOrderItem[]>(
    getInitialItemsData(prepopulatePurchaseOrderData)
  );

  const { mutate, data, status, isSuccess } = useCreatePurchaseOrder();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/purchases/${data?.purchase_order?.id}`);
    }
  }, [isSuccess]);

  const createPurchaseOrder = () => {
    const createPurchaseOrderData: ICreatePurchaseOrderData = {
      description: 'TODO: description',
      product_lines: [],
      sales_order_id: prepopulatePurchaseOrderData?.id,
    };
    if (prepopulatePurchaseOrderData?.supplier) {
      createPurchaseOrderData.supplier_id =
        prepopulatePurchaseOrderData?.supplier?.id;
    } else {
      const address: ICreateAddressDTO = {
        house_number: supplierDetails.house_number,
        country: supplierDetails.country,
        street: supplierDetails.street,
        zip_code: supplierDetails.code,
        city: supplierDetails.city,
      };
      const supplier: ICreateSupplierDTO = {
        contact_person: supplierDetails.contact_person,
        phone: supplierDetails.phone,
        email: supplierDetails.email,
        address: address,
        vat_number: supplierDetails.vat_number,
        company_name: supplierDetails.company_name,
      };

      createPurchaseOrderData.supplier = supplier;
    }

    if (prepopulatePurchaseOrderData?.quotation?.reference) {
      createPurchaseOrderData.reference =
        prepopulatePurchaseOrderData.quotation.reference;
    }

    const purchaseOrderProductLines: ICreatePurchaseOrderProductLine[] =
      items.map((item: ICreatePurchaseOrderItem) => {
        return {
          product_id: item.item.product?.id,
          supplier_id: item.item.supplier?.id,
          quantity: item.quantity,
          product_purchase_price: item.price,
          stock_item_id: item.item?.id,
        };
      });
    createPurchaseOrderData.product_lines = purchaseOrderProductLines;
    mutate(createPurchaseOrderData);
  };

  const handleActiveTab = () => {
    if (activeTab === CreatePurchaseOrderTab.SUPPLIER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1. {t('Supplier details')}</Tab>
          <Tab>2. {t('Add items')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreatePurchaseOrderTab.ITEMS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Supplier details')}</Tab>
          <Tab active>2. {t('Add items')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreatePurchaseOrderTab.OVERVIEW) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Supplier details')}</Tab>
          <Tab finished>2. {t('Add items')}</Tab>
        </TabContainer>
      );
    }
  };

  return (
    <Container>
      <CreateNewPurchaseOrderLabel>
        {t('Add Purchase Order')}
      </CreateNewPurchaseOrderLabel>
      {handleActiveTab()}
      {activeTab === CreatePurchaseOrderTab.SUPPLIER_DETAILS && (
        <SupplierTab
          isSupplierPrepopulated={prepopulatePurchaseOrderData?.supplier}
          onForward={() => setActiveTab(CreatePurchaseOrderTab.ITEMS)}
          supplierDetails={supplierDetails}
          setSupplierDetails={setSupplierDetails}
        />
      )}
      {activeTab === CreatePurchaseOrderTab.ITEMS && (
        <ItemsTab
          onBack={() => setActiveTab(CreatePurchaseOrderTab.SUPPLIER_DETAILS)}
          onForward={() => setActiveTab(CreatePurchaseOrderTab.OVERVIEW)}
          items={items}
          setItems={setItems}
        />
      )}
      {activeTab === CreatePurchaseOrderTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(CreatePurchaseOrderTab.ITEMS)}
          onForward={() => createPurchaseOrder()}
          supplierDetails={supplierDetails}
          items={items}
          status={status}
        />
      )}
    </Container>
  );
};

export default NewPurchaseOrderPage;
