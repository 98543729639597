import { RoutesConfig } from 'navigation/routes';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { IRootReducerState } from 'store/store';

const PrivateRoute = () => {
  const isAuthenticated = useSelector(
    (state: IRootReducerState) => state.userInfo.id
  );
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesConfig.Login.path} />
  );
};

export default PrivateRoute;
