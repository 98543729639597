import { ReactNode } from 'react';
import { ChildWrapper } from './Tooltip.styled';
import { Tooltip as MuiTooltip, Zoom } from '@mui/material';

interface ITooltip {
  children: ReactNode;
  content: string | React.ReactNode;
  width?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

export const Tooltip = ({ children, content, placement = 'top' }: ITooltip) => {
  return (
    <MuiTooltip
      title={content}
      TransitionComponent={Zoom}
      placement={placement}
      arrow={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        },
      }}
    >
      <ChildWrapper>{children}</ChildWrapper>
    </MuiTooltip>
  );
};

export default Tooltip;
