import { Option } from 'components/Select/type';

export const useGetFileTypes = () => {
  const options: Option[] = [
    { value: 'ALL', label: 'All', key: 'all' },
    { value: 'IMAGE', label: 'Image', key: 'image' },
    { value: 'E-mail', label: 'E-mail', key: 'email' },
    { value: 'TEXT', label: 'Text', key: 'text' },
  ];
  return options;
};
