import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const FormContainer = styled.div`
  width: 100%;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: ${marginXs};
`;

export const ProductAttributeTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${marginMd};
`;

export const ProductTypeLabel = styled.label`
  user-select: none;
  cursor: pointer;
  margin-top: ${marginSm};
  margin-bottom: -10rem;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;
