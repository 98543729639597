import { RoutesConfig } from 'navigation/routes';
import { GuidePages } from './constants';

export const isCurrentLocationMatchingRoute = (
  page: GuidePages,
  location: string,
  id: string
) => {
  let route: string;
  switch (page) {
    case GuidePages.SINGLE_QUOTATION:
      route = RoutesConfig.SingleQuotation.fullPath;
      break;
    case GuidePages.SINGLE_SALES_ORDER:
      route = RoutesConfig.SingleSalesOrder.fullPath;
      break;
    case GuidePages.SINGLE_SALES_INVOICE:
      route = RoutesConfig.SingleSalesInvoice.fullPath;
      break;
    case GuidePages.FIELD_WORKER_GENERAL:
      route = RoutesConfig.FwSingleAppointment.fullPath;
      break;
    default:
      return false;
  }
  return route.replace(':id/*', id) === location;
};
