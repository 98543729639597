import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Payment', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Date', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  {
    header: 'Sales Order',
    flexValue: 1,
    isBlue: false,
  },
  { header: 'Company', flexValue: 1, isBlue: true },
  { header: 'Amount', flexValue: 1, isBlue: false },
  { header: 'Delivery Date', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};

export const PURCHASE_ORDERS_PER_PAGE = 10;
