import { Dispatch, SetStateAction } from 'react';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
  ProductAttributeType,
} from 'types/Product.types';

export const getInitialAttributes = (
  attributeForEditing?: IProductAttribute | null
): IProductAttribute[] => {
  let attribute: IProductAttribute;
  if (attributeForEditing) {
    attribute = {
      type: attributeForEditing.type,
      fieldName: attributeForEditing.fieldName,
      hasDefaultValue: attributeForEditing.hasDefaultValue,
      defaultOptionIndex: attributeForEditing.defaultOptionIndex,
      defaultValue: attributeForEditing.defaultValue,
      options: attributeForEditing.options,
      docVisibilityOptions: attributeForEditing.docVisibilityOptions,
      shouldDisplayAfterLogyxConfiguration:
        attributeForEditing.shouldDisplayAfterLogyxConfiguration,
    };
  } else {
    attribute = {
      type: ProductAttributeType.FIELD,
      fieldName: '',
      hasDefaultValue: false,
      defaultOptionIndex: null,
      defaultValue: '',
      options: ['', ''],
      docVisibilityOptions: Object.values(IDocVisibilityOptionEnum),
      shouldDisplayAfterLogyxConfiguration: false,
    };
  }
  return [attribute];
};

export const handleSetAttributes = (
  attributeIndex: number,
  newAttribute: IProductAttribute | undefined,
  attributes: IProductAttribute[],
  setAttributes: Dispatch<SetStateAction<IProductAttribute[]>>
) => {
  const attributesCopy = [...attributes];
  if (newAttribute === undefined) {
    attributesCopy.splice(attributeIndex, 1);
  } else {
    attributesCopy[attributeIndex] = newAttribute;
  }
  setAttributes(attributesCopy);
};

export const areAllAttributesValid = (validAttributesArray: boolean[]) => {
  for (const attribute of validAttributesArray) {
    if (!attribute) {
      return false;
    }
  }
  return true;
};

export const handleFormatAttributesDefaultValues = (
  originalAttributes: IProductAttribute[]
) => {
  const attributes = [...originalAttributes];
  const formattedAttributes: IProductAttribute[] = [];
  for (const attribute of attributes) {
    let formattedAttribute: IProductAttribute;
    if (attribute.type === ProductAttributeType.DROPDOWN) {
      formattedAttribute = { ...attribute, defaultValue: '' }; // Dropdown can have defaultOptionIndex, but not defaultValue
    } else {
      formattedAttribute = { ...attribute, defaultOptionIndex: null };
    }
    formattedAttributes.push(formattedAttribute);
  }
  return formattedAttributes;
};
