import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddCommentContainer,
  AuthorName,
  CloseContainer,
  CommentCardContainer,
  CommentDate,
  CommentDescription,
  CommentHeadline,
  CommentInfo,
  CommentInfoWrapper,
  CommentType,
  Margin,
  Title,
  TitleContainer,
} from './CommentsModal.styled';
import moment from 'moment';
import Pagination from 'components/Table/Pagination/Pagination';
import { AddNewCommentModal } from '../AddNewCommentModal/AddNewCommentModal';
import {
  useDeleteSupplierComment,
  useGetSingleSupplierComments,
} from '../hooks';
import { Danger } from 'components/Modal/Danger/Danger';
import Icon from 'components/Icon/Icon';
import { Plus, Trash, User } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const COMMENTS_PER_PAGE = 3;
export interface ICommentsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  supplierId: string;
}

export const CommentsModal = ({
  setIsOpen,
  isOpen,
  onCancel,
  supplierId,
}: ICommentsModalProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const [deleteCommentId, setDeleteCommentId] = useState<string>('');
  const [isDeleteCommentModalOpen, setIsDeleteCommentModalOpen] =
    useState<boolean>(false);

  const { data } = useGetSingleSupplierComments(
    supplierId,
    isOpen,
    page,
    COMMENTS_PER_PAGE
  );

  const { mutate: deleteComment } = useDeleteSupplierComment(supplierId);

  const [isAddNewCommentModalOpen, setIsAddNewCommentModalOpen] =
    useState<boolean>(false);
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Comments')}</Title>
      </TitleContainer>
      <AddCommentContainer>
        <Button
          label={t('Add another comment')}
          link
          icon={Plus}
          colorIcon={COLORS.PRIMARY}
          weightIcon="regular"
          sizeIcon={15}
          onClick={() => setIsAddNewCommentModalOpen(true)}
          fontSize="16rem"
        />
      </AddCommentContainer>
      {data?.comments.map((item: any, index: number) => {
        return (
          <CommentCardContainer key={index}>
            <CommentInfoWrapper>
              <CommentInfo>
                <CommentHeadline>{item?.headline}</CommentHeadline>
                <CommentType>
                  {item?.is_internal ? t('Internal') : t('External')}
                </CommentType>
              </CommentInfo>
              <Icon
                svg={Trash}
                color={COLORS.RED_200}
                weight="regular"
                size={20}
                onClick={() => {
                  setDeleteCommentId(item?.id);
                  setIsDeleteCommentModalOpen(true);
                }}
                marginLeft="10rem"
              />
            </CommentInfoWrapper>
            <CommentInfo>
              <Icon svg={User} size={25} color={COLORS.PRIMARY} />
              <AuthorName>{`${item?.user?.name} ${item?.user?.last_name}`}</AuthorName>
              <CommentDate>
                {moment
                  .utc(item?.created_at)
                  .tz(moment.tz.guess())
                  .format('DD-MM-YYYY HH:mm')}
              </CommentDate>
            </CommentInfo>
            <CommentDescription>{item?.description}</CommentDescription>
          </CommentCardContainer>
        );
      })}
      <Margin />
      {data?.total > COMMENTS_PER_PAGE && (
        <Pagination
          page={page}
          perPage={COMMENTS_PER_PAGE}
          total={data?.total}
          loadPage={(newPage) => setPage(newPage)}
        />
      )}
      <AddNewCommentModal
        isOpen={isAddNewCommentModalOpen}
        setIsOpen={setIsAddNewCommentModalOpen}
        onCancel={() => setIsAddNewCommentModalOpen(false)}
        supplierId={supplierId}
      />
      <Modal
        level={'SECOND'}
        isOpen={isDeleteCommentModalOpen}
        setIsOpen={setIsDeleteCommentModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteComment(deleteCommentId);
              setIsDeleteCommentModalOpen(false);
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setIsDeleteCommentModalOpen(false) }}
          title={t('Delete Comment')}
          description={`${t('Are you sure you want to delete this comment')}?`}
        />
      </Modal>
    </Modal>
  );
};
