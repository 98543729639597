import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient from 'services/api/apiService';
import {
  ICreateGoogleCalendarPreference,
  ICreateOutlookCalendarPreference,
} from 'pages/Manager/PlanningPage/CalendarIntegration/ManageCalendarPreferencesModal/hooks';

const BASE_URL = '/v1';

export const authorizeGoogleAccount = async (): Promise<any> => {
  const queryParamsObj = {
    redirect_page: window.location.href,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}/google_calendar/authorize`,
    queryParamsObj
  );

  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const signOutFromGoogleAccount = async (): Promise<any> => {
  const url = `${BASE_URL}/google_calendar/logout`;

  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const signOutFromMicrosoft = async (): Promise<any> => {
  const queryParamsObj = {
    redirect_page: window.location.href,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}/outlook_calendar/logout`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const authorizeOutlook = async (): Promise<any> => {
  const queryParamsObj = {
    redirect_page: window.location.href,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}/outlook_calendar/authorize`,
    queryParamsObj
  );

  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getGoogleCalendarPreferences = async (): Promise<any> => {
  const { data } = await apiClient.get<any>(
    `${BASE_URL}/google_calendar/custom`
  );
  return data.data;
};

export const getOutlookCalendarPreferences = async (): Promise<any> => {
  const { data } = await apiClient.get<any>(
    `${BASE_URL}/outlook_calendar/custom`
  );
  return data.data;
};

export const createGoogleCalendarPreference = async (
  createGoogleCalendarPreferenceDTO: ICreateGoogleCalendarPreference
): Promise<any> => {
  const url = `${BASE_URL}/google_calendar/custom`;
  const { data } = await apiClient.post<any>(
    url,
    createGoogleCalendarPreferenceDTO
  );

  return data.data;
};

export const createOutlookCalendarPreference = async (
  createOutlookCalendarPreferenceDTO: ICreateOutlookCalendarPreference
): Promise<any> => {
  const url = `${BASE_URL}/outlook_calendar/custom`;
  const { data } = await apiClient.post<any>(
    url,
    createOutlookCalendarPreferenceDTO
  );

  return data.data;
};
