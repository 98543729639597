import { paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginRight: string;
  $backgroundColor: string;
  $textColor: string;
  $borderColor: string;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5rem ${paddingXs};
  height: 100%;
  cursor: pointer;
`;

export const Content = styled.div`
  overflow: hidden;
  height: inherit;
  display: flex;
  flex-direction: column;
`;

export const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  position: absolute;
  top: 0;
  right: 10rem;
  max-width: 90%;
  transform: translateY(-100%);
  border: 1px solid transparent;
  border-bottom: none;
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  padding: 0 7rem;
  overflow: hidden;
  ${(props: StyledProps) =>
    props.$backgroundColor &&
    css`
      background-color: ${props.$backgroundColor};
    `};
  ${(props: StyledProps) =>
    props.$borderColor &&
    css`
      border-color: ${props.$borderColor};
    `};
`;

export const FlagText = styled.span`
  font-size: 12rem;
  line-height: 1.7;
  ${(props: StyledProps) =>
    props.$textColor &&
    css`
      color: ${props.$textColor};
    `};
`;

export const TimeText = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 16rem;
  cursor: pointer;
`;

export const TitleText = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 16rem;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
`;

export const CityText = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 16rem;
  cursor: pointer;
`;

export const StreetText = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 16rem;
  cursor: pointer;
  overflow: hidden;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -2rem;
`;

export const AbsoluteContainer = styled.div`
  position: relative;
  top: -4px;
  left: 0;

  .MuiLinearProgress-root-itixFm {
    height: 2px;
  }
`;
