import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 30rem;
  top: 20rem;
  width: calc(100% - 60rem);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Scrollable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: calc(90vh - 200rem);
  overflow-y: scroll;
`;

export const AppointmentReportLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  margin-bottom: 10rem;
`;
