import { IUser } from 'types/User.types';

export interface IQuestion {
  name: string;
  default: string[];
  description: string | null;
  group: string | null;
  hidden: boolean;
  inputs: string[];
  multiple: boolean;
  options: [];
  order: string | null;
  outputs: object;
  required: boolean;
  type: QuestionType;
  min: number;
  max: number;
  initialKey: string; // Name of question key that gets overriden in case question name is provided
}

export enum QuestionType {
  NUMBER = 'number',
  LIST = 'list',
  TABLE = 'table',
  BOOLEAN = 'boolean',
  TEXT = 'text',
  DATETIME = 'datetime',
  RADIO = 'radio',
  COLOR = 'color',
}

export interface IConfiguredProductData {
  modelId: number | null;
  versionNumber: number | null;
  variables: object | null; // Exists on legacy intents
  pricing: object | null;
  roundPricing: boolean;
  questions: IQuestion[];
  general: object | null;
  valuesFromOutputs: object;
  queryPayload: object;
}

// Used in new quotation
export interface ILogyxAttribute {
  type: QuestionType;
  value: any;
  fieldName: string;
}

export interface ILogyxConfigurationIntent {
  configuration_status: ILogyxConfigurationIntentStatus;
  data: IConfiguredProductData;
  logyx_model_id: number;
  uuid: string;
  user: IUser;
}

export interface ILogyxConfigurationIntentStatus {
  name: string;
  id: string;
}
