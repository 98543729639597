import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCompany,
  setCompanyLogo,
  setCompanySettings,
} from 'store/Company/actions/company';
import { IRootReducerState } from 'store/store';
import {
  useGetCompany,
  useGetCompanyLogo,
  useGetCompanySettings,
} from './hooks';

interface ICompanyDataProviderProps {
  children: React.ReactNode;
}

const CompanyDataProvider = ({ children }: ICompanyDataProviderProps) => {
  const dispatch = useDispatch();
  const { id: isAuthenticated, company_id: userCompanyId } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const companyId = useSelector(
    (state: IRootReducerState) => state.companyInfo.id
  );
  const companySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const {
    mutate: fetchCompany,
    data: companyData,
    isLoading: isLoadingCompany,
    isSuccess: isSuccessCompany,
    reset: resetFetchCompany,
  } = useGetCompany(userCompanyId);

  const {
    mutate: fetchCompanyLogo,
    data: companyLogo,
    isLoading: isLoadingCompanyLogo,
    isSuccess: isSuccessCompanyLogo,
    reset: resetFetchCompanyLogo,
  } = useGetCompanyLogo(userCompanyId);

  const {
    mutate: fetchCompanySettings,
    data: companySettingsData,
    isLoading: isLoadingCompanySettings,
    isSuccess: isSuccessCompanySettings,
    reset: resetFetchCompanySettings,
  } = useGetCompanySettings();

  // Company
  useEffect(() => {
    if (isAuthenticated && userCompanyId && !isLoadingCompany && !companyId) {
      fetchCompany(userCompanyId);
    }
  }, [userCompanyId]);

  useEffect(() => {
    if (isSuccessCompany) {
      dispatch(setCompany(companyData));
      resetFetchCompany();
    }
    if (companyData && companyData.company.name) {
      document.title = `Blueprint - ${companyData.company.name}`;
    }
  }, [isSuccessCompany, companyData]);

  // Company logo
  useEffect(() => {
    if (
      isAuthenticated &&
      userCompanyId &&
      !isLoadingCompanyLogo &&
      !companyLogo
    ) {
      fetchCompanyLogo(userCompanyId);
    }
  }, [userCompanyId]);

  useEffect(() => {
    if (isSuccessCompanyLogo && companyLogo.company_logo_url) {
      dispatch(setCompanyLogo(companyLogo.company_logo_url));
      resetFetchCompanyLogo();
    }
  }, [isSuccessCompanyLogo, companyLogo]);

  // Company settings
  useEffect(() => {
    if (isAuthenticated && !companySettings && !isLoadingCompanySettings) {
      fetchCompanySettings();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isSuccessCompanySettings) {
      dispatch(setCompanySettings(companySettingsData));
      resetFetchCompanySettings();
    }
  }, [isSuccessCompanySettings, companySettingsData]);

  return <>{children}</>;
};

export default CompanyDataProvider;
