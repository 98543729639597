import { useParams } from 'react-router-dom';
import {
  Card,
  ColumnContainer,
  Container,
  DeleteIcon,
  DeleteLabel,
  EditDeleteWrapperRelative,
  Label,
  LabelTitle,
  MainContainer,
  NoContentLabel,
  TextContainer,
  TitleContainer,
} from './SinglePurchaseInvoicePaymentsPage.styled';
import { useGetSinglePurchaseInvoice } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef, useState } from 'react';
import { IPayment } from 'types/Payment.types';
import { COLORS, H3, H4 } from 'assets/styled';
import moment from 'moment';
import { PURCHASE_INVOICE_PAYMENTS_PER_PAGE } from './constants';
import { useDeletePayment, useGetSinglePurchaseInvoicePayments } from './hooks';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { Trash } from '@phosphor-icons/react';

const SinglePurchaseInvoicePaymentsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [paymentForDeleting, setPaymentForDeleting] = useState<IPayment>();

  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  const { data } = useGetSinglePurchaseInvoice(
    typeof id === 'string' ? id : ''
  );

  const { mutate: deletePayment } = useDeletePayment();

  const {
    data: invoicePaymentsData,
    isFetching: isFetchingPayments,
    hasNextPage,
    fetchNextPage,
  } = useGetSinglePurchaseInvoicePayments(
    typeof id === 'string' ? id : '',
    PURCHASE_INVOICE_PAYMENTS_PER_PAGE
  );

  const dataRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight - 1
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const invoicePayments = invoicePaymentsData?.pages
    .map((page: any) => page.payments)
    .flat();
  if (!invoicePayments?.length)
    return isFetchingPayments ? (
      <></>
    ) : (
      <NoContentLabel>
        {t('There are currently no payments on this Purchase invoice')}
      </NoContentLabel>
    );

  return (
    <Container onScroll={onScroll} ref={dataRef}>
      {invoicePayments.map((invoicePayment: IPayment, index: number) => {
        return (
          <Card key={index}>
            <TitleContainer>
              <H3>
                {t('Payment')} {invoicePayment.number}
              </H3>
              {canEditPurchaseInvoice && (
                <EditDeleteWrapperRelative
                  data-testid={`delete-payment-${invoicePayment.number}`}
                  onClick={() => {
                    setPaymentForDeleting(invoicePayment);
                    setOpenDeleteModal(true);
                  }}
                >
                  <DeleteLabel>{t('Delete')}</DeleteLabel>
                  <DeleteIcon>
                    <Icon svg={Trash} size={18} color={COLORS.RED_200} />
                  </DeleteIcon>
                </EditDeleteWrapperRelative>
              )}
            </TitleContainer>
            <MainContainer>
              <ColumnContainer>
                <H4>{t('DETAILS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Date')}</LabelTitle>
                  <Label>{`${moment(invoicePayment?.created_at)
                    .tz(moment.tz.guess())
                    .format('DD-MM-YYYY')}`}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount')}</LabelTitle>
                  <Label>{invoicePayment.amount}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Type of payment')}</LabelTitle>
                  <Label>
                    {t(invoicePayment.payment_type.name).toUpperCase()}
                  </Label>
                </TextContainer>
              </ColumnContainer>
              <ColumnContainer>
                <H4>{t('STATUS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Total amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount paid')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Open amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
              </ColumnContainer>
            </MainContainer>
          </Card>
        );
      })}
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.purchase_invoice?.id}
        invoiceType={InvoiceType.PURCHASE_INVOICE}
        initialPaymentAmount={
          data?.purchase_invoice?.total_amount -
          data?.purchase_invoice?.total_amount_payed
        }
      />
      <ConfirmModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        onConfirm={() => {
          deletePayment(paymentForDeleting!.id.toString());
          setOpenDeleteModal(false);
        }}
        onCancel={() => setOpenDeleteModal(false)}
        title={t('Delete Payment')}
        description={t('Are you sure you want to delete this payment') + '?'}
      />
    </Container>
  );
};

export default SinglePurchaseInvoicePaymentsPage;
