import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';

const compactNumberFormatters = {
  en: new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 4,
  }),
  nl: new Intl.NumberFormat('nl', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumSignificantDigits: 4,
  }),
};
export const formatNumberCompact = (number: number) => {
  const resolvedLanguage = i18n.resolvedLanguage || FALLBACK_I18N_LANG;
  const compactNumberFormatter =
    compactNumberFormatters[resolvedLanguage] || compactNumberFormatters.en;
  return compactNumberFormatter.format(number);
};

const numberFormatters = {
  en: new Intl.NumberFormat('en', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }),
  nl: new Intl.NumberFormat('nl', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }),
  enMonetary: new Intl.NumberFormat('en', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
  nlMonetary: new Intl.NumberFormat('nl', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
};

// Pass isMonetary as false to remove fraction digits from formatting output
export const formatNumber = (number: number | any, isMonetary = true) => {
  const resolvedLanguage = i18n.resolvedLanguage || FALLBACK_I18N_LANG;
  const numberFormatter =
    numberFormatters[`${resolvedLanguage}${isMonetary ? 'Monetary' : ''}`] ||
    numberFormatters.en;
  return numberFormatter.format(number);
};
