import { useEffect, useMemo, useState } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { breadcrumbsConfig } from './config';
import { IBreadcrumb } from './types';
import {
  capitalizeFirstLetter,
  findRouteConfig,
  getNestedProperty,
} from './helpers';
import { useTranslation } from 'react-i18next';

// Custom hook for generating breadcrumb navigation data based on the current URL
const useBreadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isFetching = useIsFetching();
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([]);
  const [dynamicLoading, setDynamicLoading] = useState({});

  const pathnames = useMemo(
    () => location.pathname.split('/').filter((x) => x),
    [location.pathname]
  );

  const newBreadcrumbs = useMemo(() => {
    // Setting 'Home' breadcrumb for the root path
    if (location.pathname === '/') {
      return [{ name: 'Home', path: '/', isLast: true }];
    }

    return pathnames.map((value, index) => {
      // Constructing the full path up to the current segment
      const pathToMatch = `/${pathnames.slice(0, index + 1).join('/')}`;
      // Finding route configuration based on the path
      const routeConfig = findRouteConfig(pathToMatch, breadcrumbsConfig);

      // Default name is the capitalized segment or a custom name from routeConfig
      let name = routeConfig?.breadcrumbs || capitalizeFirstLetter(value);

      // If the route has a dynamic segment, fetch and use it's data
      if (routeConfig?.dynamicSegment && /^\d+$/.test(value)) {
        // Indicate loading for this dynamic segment
        setDynamicLoading((prev) => ({ ...prev, [pathToMatch]: true }));
        const queryData: any = queryClient.getQueryData([
          routeConfig.dynamicSegment.key,
          value,
        ]);

        if (queryData) {
          // Once data is fetched, indicate loading is complete
          setDynamicLoading((prev) => ({ ...prev, [pathToMatch]: false }));
        }

        // Custom transformed dynamic value in config file
        if (routeConfig?.dynamicSegment?.transformation) {
          const customValue = `${t(
            routeConfig.dynamicSegment.transformation(queryData)
          )} #${queryData?.appointment?.id}`;
          name = customValue;
        } else {
          // Extracting the dynamic value from query data using the configured property path
          const dynamicValue = queryData
            ? getNestedProperty(queryData, routeConfig.dynamicSegment.property)
            : null;
          name = dynamicValue || name;
        }
      }

      return {
        name,
        path: pathToMatch,
        isLast: index === pathnames.length - 1,
      };
    });
  }, [location.pathname, pathnames, queryClient, isFetching]);

  useEffect(() => {
    setBreadcrumbs(newBreadcrumbs);
  }, [newBreadcrumbs]);

  return { breadcrumbs, dynamicLoading };
};

export default useBreadcrumbs;
