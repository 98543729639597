import * as Yup from 'yup';

export const createQuotationOrderTabSchema = () => {
  return Yup.object().shape({
    reference: Yup.string()
      .optional()
      .max(80, 'Reference should be less than 80 characters'),
    downPayment: Yup.number()
      .typeError('Down payment must be a number')
      .test(
        'twoDecimal',
        'Down payment can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .optional(),
    daysValid: Yup.number()
      .typeError('Days valid must be a number')
      .min(1, 'Enter a number larger than 0')
      .max(365, 'Enter a number up to 365')
      .test('wholeNumber', 'Days valid must be a whole number', (value) => {
        return Number.isInteger(value);
      })
      .required('Days valid is required'),
    workingHours: Yup.number()
      .min(0, 'Invalid working hours ')
      .typeError('Working hours must be a number')
      .test(
        'twoDecimal',
        'Working hours can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .required('Working hours is required'),
    workingHoursRate: Yup.number()
      .min(0, 'Invalid hourly rate')
      .typeError('Hourly rate must be a number')
      .test(
        'twoDecimal',
        'Working hours rate can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .required('Hourly rate is required'),
  });
};

export const createSalesOrderOrderTabSchema = () => {
  return Yup.object().shape({
    reference: Yup.string()
      .optional()
      .max(80, 'Reference should be less than 80 characters'),
    downPayment: Yup.number()
      .typeError('Down payment must be a number')
      .test(
        'twoDecimal',
        'Down payment can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .optional(),
    workingHours: Yup.number()
      .min(0, 'Invalid working hours ')
      .typeError('Working hours must be a number')
      .test(
        'twoDecimal',
        'Working hours can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .required('Working hours is required'),
    workingHoursRate: Yup.number()
      .min(0, 'Invalid hourly rate')
      .typeError('Hourly rate must be a number')
      .test(
        'twoDecimal',
        'Working hours rate can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .required('Hourly rate is required'),
  });
};
