import {
  Container,
  FullNameLabel,
  ImageAvatar,
  LogoutButton,
  ButtonWrapper,
  ManageProfileButton,
  Version,
  SwitchViewButton,
  ImageAvatarWrapper,
  ToggleDarkModeWrapper,
  CompanyLogoWrapper,
  CompanyLogo,
  UserGuidesSpan,
  UserGuidesLabel,
} from './ProfileDropdown.styled';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLogout } from './hooks';
import { setIsFieldWorkerView, setRunTour } from 'store/Common/actions/common';
import { config } from 'config';
import { LicenseTypeIds } from 'providers/ViewSwitcherProvider/ViewSwitcherProvider';
import { RoutesConfig } from 'navigation/routes';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { useIsFieldWorker } from 'components/Routes/FieldWorkerRoute/hooks';
import {
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { User, Question, BookOpen } from '@phosphor-icons/react';
import { useChangeIntroGuideCompletedStatus } from 'components/IntroductionGuide/hooks';
import {
  setUserIntroGuideCompleted,
  setUserIntroGuideCompletedPages,
} from 'store/User/actions/user';
import { toast } from 'utils/toast';

const ProfileDropdown = () => {
  const userGuidesLink = process.env.REACT_APP_USER_GUIDES_LINK;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canReadFieldWorker = useCan(Actions.READ_FIELD_WORKER);
  const {
    name: firstName,
    last_name: lastName,
    image_url: imageUrl,
  } = useSelector((state: IRootReducerState) => state.userInfo);
  const isFieldWorkerView = useIsFieldWorker();

  // onSettled  clears local storage
  const { mutate: logout } = useLogout();

  const { license } = useSelector((state: IRootReducerState) => state.userInfo);
  const companyLogo = useSelector(
    (state: IRootReducerState) => state.companyInfo.companyLogo
  );
  const isCurrentUserFieldWorker = license?.id === LicenseTypeIds.FIELD_WORKER;

  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  const navigateToUserGuidesPage = () => {
    window.open(userGuidesLink, '_blank');
  };

  const { mutate: turnOnIntroductionGuide } =
    useChangeIntroGuideCompletedStatus();

  const restartGuide = () => {
    turnOnIntroductionGuide(false, {
      onSuccess: () => {
        dispatch(setUserIntroGuideCompleted(false));
        dispatch(setUserIntroGuideCompletedPages([]));
        dispatch(setRunTour(true));
        document.body.style.overflow = 'hidden';
        toast.success(
          t(
            'Introduction guide is activated. It will be shown on pages where it is available.'
          )
        );
      },
    });
  };

  return (
    <Container>
      <UserGuidesSpan>
        <Icon
          svg={Question}
          color={COLORS.PRIMARY}
          size={25}
          onClick={() => navigateToUserGuidesPage()}
        />
        <UserGuidesLabel onClick={() => navigateToUserGuidesPage()}>
          {t('User guides')}
        </UserGuidesLabel>
      </UserGuidesSpan>
      <UserGuidesSpan>
        <Icon
          svg={BookOpen}
          color={COLORS.PRIMARY}
          size={25}
          weight="regular"
          onClick={() => restartGuide()}
        />
        <UserGuidesLabel onClick={() => restartGuide()}>
          {t('Restart introduction guide')}
        </UserGuidesLabel>
      </UserGuidesSpan>

      <ToggleDarkModeWrapper>
        <ToggleDarkMode />
      </ToggleDarkModeWrapper>
      <ImageAvatarWrapper>
        {imageUrl ? (
          <ImageAvatar alt="" src={imageUrl} />
        ) : (
          <Icon svg={User} color={COLORS.PRIMARY} size={30} />
        )}
      </ImageAvatarWrapper>
      {companyLogo && (
        <CompanyLogoWrapper>
          <CompanyLogo alt="" src={companyLogo} />
        </CompanyLogoWrapper>
      )}
      <FullNameLabel>
        {firstName} {lastName}
      </FullNameLabel>
      <ButtonWrapper>
        <ManageProfileButton
          primary
          onClick={() => {
            if (isCurrentUserFieldWorker) {
              navigate(RoutesConfig.FwProfile.fullPath);
            } else {
              if (isFieldWorkerView) {
                navigate(RoutesConfig.FwProfile.fullPath);
              } else {
                navigate(RoutesConfig.Profile.fullPath);
              }
            }
          }}
          label={t('Manage profile')}
          width={'100%'}
        />
      </ButtonWrapper>
      {isCurrentUserFieldWorker ? null : (
        <ButtonWrapper>
          {canReadFieldWorker && (
            <SwitchViewButton
              secondary
              onClick={() => dispatch(setIsFieldWorkerView(!isFieldWorkerView))}
              label={
                isFieldWorkerView
                  ? t('Office worker view')
                  : t('Field worker view')
              }
              width={'100%'}
            />
          )}
        </ButtonWrapper>
      )}
      <ButtonWrapper>
        <LogoutButton
          red
          onClick={() => {
            logout();
            isMobileApp &&
              postMobileMessage({
                type: MobileMessageTypes.LOGOUT,
              });
          }}
          label={t('Log out')}
          width={'100%'}
        />
      </ButtonWrapper>
      {config.version && <Version>{config.version}</Version>}
    </Container>
  );
};

export default ProfileDropdown;
