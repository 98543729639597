import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  marginSm,
  paddingMd,
} from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${marginMd};
  padding: ${paddingMd};
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding-top: ${marginLg};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
`;

export const RowMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14rem;
`;

export const InputWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${marginMd};
  width: 100%;
  margin-top: ${marginSm};
`;

export const CreateNewCreditInvoiceTitle = styled.label`
  width: 690rem;
  align-self: center;
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`;
