import { useEffect } from 'react';
import history from 'utils/history';

export const useBlocker = (blocker: any, when: boolean) => {
  useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [blocker, when]);
};
