import { useParams } from 'react-router-dom';
import {
  AddNewFileButton,
  CardWrapper,
  Container,
  Row,
  RowSpaceBetween,
  SelectInputWrapper,
  ViewTypeLabel,
  NoResultsContainer,
  Message,
  TableWrapper,
  LoaderWrapper,
  Column,
} from './SinglePurchaseInvoiceFilesPage.styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  fileFilterTypes,
  FilesPageViewType,
  PURCHASE_INVOICE_FILES_PER_PAGE,
  tableConfig,
} from './constants';
import { Option } from 'components/Select/type';
import FileCard from 'components/Cards/FileCard/FileCard';
import { IFile, IFileResponseDTO, IFileTableDTO } from 'types/File.types';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import Table from 'components/Table/Table';
import { Select } from 'components/Select/Select';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { useTranslation } from 'react-i18next';
import { formatPurchaseInvoiceFilesTableData } from 'components/Table/tableDataFormatter';
import {
  useGetPurchaseInvoiceFiles,
  useUploadPurchaseInvoiceFile,
} from './hooks';
import {
  useChangePurchaseInvoiceStatus,
  useGetSinglePurchaseInvoice,
} from '../hooks';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import {
  EditFileNameModal,
  IEditFileNameDTO,
} from 'components/Modal/EditFileNameModal/EditFileNameModal';
import { cutLongWords } from 'utils/stringUtils';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  IDeleteFileDTO,
  useHandleDeleteFile,
} from 'utils/hooks/useHandleDeleteFile';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { FULLY_PAID, SENT_STATUS } from '../../SingleSalesInvoice/constants';

const SinglePurchaseInvoiceFilesPage = () => {
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] =
    useState<boolean>(false);
  const [isEditFileNameModalOpen, setIsEditFileNameModalOpen] =
    useState<boolean>(false);
  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] =
    useState<boolean>(false);
  const [fileToEdit, setFileToEdit] = useState<IEditFileNameDTO | null>(null);
  const [fileToDelete, setFileToDelete] = useState<IDeleteFileDTO | null>(null);
  const [selectedViewType, setSelectedViewType] = useState<FilesPageViewType>(
    FilesPageViewType.LIST_VIEW
  );
  const [selectedFileFilterType, setSelectedFileFilterType] = useState<Option>(
    fileFilterTypes[0]
  );
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] =
    useState<boolean>(false);

  const { id: loggedInUserId } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  const boxViewDataRef = useRef<HTMLDivElement>(null);
  const listViewDataRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const { fetchNextPage, isLoading, data, hasNextPage, isFetching } =
    useGetPurchaseInvoiceFiles(
      PURCHASE_INVOICE_FILES_PER_PAGE,
      typeof id === 'string' ? id : ''
    );
  const { mutate: changeStatus } = useChangePurchaseInvoiceStatus();
  const { data: purchaseInvoiceData } = useGetSinglePurchaseInvoice(id!);

  const onScroll = useCallback(async () => {
    let dataEl;
    if (selectedViewType === FilesPageViewType.BOX_VIEW)
      dataEl = boxViewDataRef.current;
    else dataEl = listViewDataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight
      )
    ) {
      return;
    }
    fetchNextPage();
  }, [
    boxViewDataRef,
    boxViewDataRef.current,
    listViewDataRef,
    listViewDataRef.current,
    hasNextPage,
    selectedViewType,
  ]);

  const files = data?.pages.map((page: any) => page.files).flat();

  const renderBoxViewContent = () => {
    if (files?.length) {
      return (
        <>
          {isLoadingFileUpload && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          <CardWrapper onScroll={onScroll} ref={boxViewDataRef}>
            {files.map((file: IFile, index: number) => (
              <FileCard
                file={file}
                name={cutLongWords(file.name, 20)}
                fileType={file.type}
                date={file.created_at}
                icon={file.preview_url}
                key={index}
                id={file.id}
                editable={
                  canEditPurchaseInvoice ||
                  file?.uploaded_by?.id === loggedInUserId
                }
                onClick={() => openFileInNewTab(file.preview_url)}
                onDelete={() => {
                  const deleteFileDTO: IDeleteFileDTO = {
                    fileId: file.id,
                    entityId: file.entity_id,
                    entityType: file.file_type,
                  };
                  handleDeleteFile(deleteFileDTO);
                }}
                onEdit={() => {
                  const editFileNameDTO: IEditFileNameDTO = {
                    initialName: file.name,
                    fileId: file.id,
                    entityId: file.entity_id,
                    entityType: file.file_type,
                  };
                  setFileToEdit(editFileNameDTO);
                  setIsEditFileNameModalOpen(true);
                }}
              />
            ))}
          </CardWrapper>
        </>
      );
    }
  };

  const handleViewButtonClick = (file: any) => {
    openFileInNewTab(file.previewUrl);
  };

  let tableData: IFileTableDTO[] = [];
  const purchaseInvoiceFiles = data?.pages
    .map((page: any) => page.files)
    .flat();
  if (data && purchaseInvoiceFiles) {
    tableData = formatPurchaseInvoiceFilesTableData(
      purchaseInvoiceFiles
        .map((purchaseInvoiceFile: IFileResponseDTO[]) => purchaseInvoiceFile)
        .flat()
    );
  }

  const renderListViewContent = () => {
    if (files?.length) {
      return (
        <TableWrapper onScroll={onScroll} ref={listViewDataRef}>
          <Table
            isLoading={isLoading || isLoadingFileUpload}
            withoutPagination
            page={data?.pages?.length || 1}
            perPage={PURCHASE_INVOICE_FILES_PER_PAGE}
            total={data?.pages[0]?.total}
            tableData={tableData}
            tableConfig={tableConfig}
            hasExtraAction={true}
            editableIcon={canEditPurchaseInvoice}
            loadPage={() => {
              //
            }}
            onViewButtonClick={handleViewButtonClick}
            onDelete={(file) => {
              const deleteFileDTO: IDeleteFileDTO = {
                fileId: file.navigationID,
                entityId: file.entityId,
                entityType: file.entityType,
              };
              handleDeleteFile(deleteFileDTO);
            }}
            onEdit={(file) => {
              const editFileNameDTO: IEditFileNameDTO = {
                initialName: file.fileFullName,
                fileId: file.navigationID,
                entityId: file.entityId,
                entityType: file.entityType,
              };
              setFileToEdit(editFileNameDTO);
              setIsEditFileNameModalOpen(true);
            }}
          />
        </TableWrapper>
      );
    }
  };

  const handleDeleteFile = useHandleDeleteFile(
    setFileToDelete,
    isDeleteFileModalOpen,
    setIsDeleteFileModalOpen
  );

  const {
    mutate: uploadPurchaseInvoiceFile,
    isSuccess,
    isLoading: isLoadingFileUpload,
  } = useUploadPurchaseInvoiceFile();

  const handleSubmitAddNewFile = async (files: any) => {
    uploadPurchaseInvoiceFile({ files: files, purchaseInvoiceId: id || '' });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsUploadFileModalOpen(false);
      if (
        purchaseInvoiceData?.purchase_invoice?.payment_status?.name !==
          SENT_STATUS &&
        purchaseInvoiceData?.purchase_invoice?.payment_status?.name !==
          FULLY_PAID
      ) {
        setIsChangeStatusModalOpen(true);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    const collection = document.getElementsByClassName('table-responsive');
    setTimeout(() => {
      const tableWrapper = collection[0];
      if (tableWrapper) {
        tableWrapper?.addEventListener('scroll', onScroll);
      }
    }, 500);
    return () => {
      const tableWrapper = collection[0];
      tableWrapper?.removeEventListener('scroll', onScroll);
    };
  }, [selectedViewType]);

  const { isTablet } = useBreakpointFlag();

  return (
    <>
      {!files?.length ? (
        <NoResultsContainer>
          {!files?.length && isLoadingFileUpload && (
            <Spinner size={50} color={COLORS.PRIMARY} />
          )}
          {!isFetching && !isLoadingFileUpload && (
            <Column>
              <Message>
                {t('There are currently no files on this Purchase invoice')}
              </Message>
              <AddNewFileButton
                width={'200rem'}
                onClick={() => setIsUploadFileModalOpen(true)}
                label={t('Add new file')}
                secondary
              />
            </Column>
          )}
        </NoResultsContainer>
      ) : (
        <Container isTablet={isTablet}>
          <RowSpaceBetween>
            <Row>
              <ViewTypeLabel
                data-testid="box-view-label"
                onClick={() => setSelectedViewType(FilesPageViewType.BOX_VIEW)}
                isSelected={selectedViewType === FilesPageViewType.BOX_VIEW}
              >
                {t('Box view')}
              </ViewTypeLabel>
              <ViewTypeLabel
                data-testid="list-view-label"
                onClick={() => setSelectedViewType(FilesPageViewType.LIST_VIEW)}
                isSelected={selectedViewType === FilesPageViewType.LIST_VIEW}
              >
                {t('List view')}
              </ViewTypeLabel>
            </Row>
            <Row>
              {/* <SelectInputWrapper>
                <Select
                  name="fileFilterType"
                  placeholder={t('File type')}
                  isMulti={false}
                  isDisabled={false}
                  isSearchable={false}
                  onChange={(e: Option) => setSelectedFileFilterType(e)}
                  options={fileFilterTypes}
                />
              </SelectInputWrapper> */}
              <AddNewFileButton
                width={'200rem'}
                onClick={() => setIsUploadFileModalOpen(true)}
                label={t('Add new file')}
                primary
              />
            </Row>
          </RowSpaceBetween>
          {selectedViewType === FilesPageViewType.BOX_VIEW
            ? renderBoxViewContent()
            : renderListViewContent()}
        </Container>
      )}
      <FileUploadModal
        isOpen={isUploadFileModalOpen}
        setIsOpen={setIsUploadFileModalOpen}
        onSubmit={(files: any) => handleSubmitAddNewFile(files)}
      />
      <YesOrNoModal
        pwId="yes-or-no-change-invoice-status-modal"
        isOpen={isChangeStatusModalOpen}
        setIsOpen={setIsChangeStatusModalOpen}
        onYes={() => {
          const params = {
            id: id!,
            status_id: '2',
          };
          changeStatus(params);
          setIsChangeStatusModalOpen(false);
        }}
        onNo={() => setIsChangeStatusModalOpen(false)}
        title={''}
        description={`${t(
          'Do you want to change the invoice status to received'
        )}?`}
      />
      <EditFileNameModal
        isOpen={isEditFileNameModalOpen}
        setIsOpen={setIsEditFileNameModalOpen}
        onCancel={() => setIsEditFileNameModalOpen(false)}
        editFileNameDTO={fileToEdit}
      />
      <Modal
        isOpen={isDeleteFileModalOpen}
        setIsOpen={setIsDeleteFileModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => handleDeleteFile(fileToDelete),
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setIsDeleteFileModalOpen(false) }}
          title={t('Delete File')}
          description={t(`Are you sure you want to delete this file`) + '?'}
        />
      </Modal>
    </>
  );
};

export default SinglePurchaseInvoiceFilesPage;
