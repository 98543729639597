import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { editStockItemAmount } from 'services/StockItems/StockItemsService';

export interface IEditStockAmountDTO {
  stockItemId: number;
  amount: number;
}
export const useEditStockAmount = (stockItemId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editStockAmountDTO: IEditStockAmountDTO) =>
      editStockItemAmount(editStockAmountDTO),
    {
      onSuccess: () => {
        toast.success(t('Stock amount updated'), {
          toastId: ReactMutationKeys.EDIT_STOCK_ITEM_AMOUNT,
          className: ReactMutationKeys.EDIT_STOCK_ITEM_AMOUNT,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_STOCK_ITEM,
          stockItemId,
        ]);
        queryClient.invalidateQueries(ReactQueryKeys.GET_STOCK_ITEMS);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_STOCK_ITEM_AMOUNT,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_STOCK_ITEM_AMOUNT,
    }
  );
};

export const useGetStockItemId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};
