import { ICustomerTableAccountingDTO } from 'types/Customer.types';
import Tooltip from 'components/Tooltip/Tooltip';
import { TdLabel } from 'components/Table/Table.styled';
import { AccountingType } from 'types/Accounting.types';
import TwinfieldLogo from 'assets/images/twinfieldLogo.png';
import { AccountingTypeImg } from './AccountingCell.styled';
import { ISalesInvoiceTableAccountingDTO } from 'types/Invoice.types';
import { useTranslation } from 'react-i18next';

export enum AccountingCellType {
  SALES_INVOICE = 'SALES_INVOICE',
  CUSTOMER = 'CUSTOMER',
}

interface IAccountingCellProps {
  accountingData:
    | ICustomerTableAccountingDTO[]
    | ISalesInvoiceTableAccountingDTO[];
}

const getAccountingServiceIcon = (accountingType: AccountingType) => {
  switch (accountingType) {
    case AccountingType.TWINFIELD:
      return TwinfieldLogo;
  }
};

export const AccountingCell = ({ accountingData }: IAccountingCellProps) => {
  const { t } = useTranslation();
  return (
    <>
      {accountingData.map(
        (
          accountingItem:
            | ICustomerTableAccountingDTO
            | ISalesInvoiceTableAccountingDTO,
          index: number
        ) => {
          switch (accountingItem.accountingCellType) {
            case AccountingCellType.CUSTOMER: {
              return (
                <Tooltip
                  content={`${t('Customer number')}: ${accountingItem.code}`}
                  key={index}
                >
                  <AccountingTypeImg
                    src={getAccountingServiceIcon(
                      accountingItem.accountingType
                    )}
                    alt={accountingItem.accountingType}
                  />
                </Tooltip>
              );
            }
            case AccountingCellType.SALES_INVOICE: {
              return (
                <Tooltip
                  content={
                    accountingItem.id
                      ? `${t('Accounting ID')}: ${accountingItem.id}`
                      : `${t('No Accounting ID')}`
                  }
                  key={index}
                >
                  <TdLabel>
                    {accountingItem?.accountingIntegrated ? t('Yes') : t('No')}
                  </TdLabel>
                </Tooltip>
              );
            }
          }
        }
      )}
    </>
  );
};
