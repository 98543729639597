import { CSSProperties } from '@mui/styled-engine';
import { Container } from './Draggable.styled';
import { useDrag, useDrop } from 'react-dnd';
import { useEffect, useRef } from 'react';

// If provided, id, draggedId and setDraggedId are used to hide the element that is being dragged
interface IDraggableProps {
  children: React.ReactNode;
  style?: CSSProperties;
  onDragHover: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  id?: number;
  draggedId?: number | null;
  setDraggedId?: (id: number | null) => void;
  isDragDisabled?: boolean;
}

// Draggables must be wrapped with DndProvider
const Draggable = ({
  children,
  style,
  onDragHover,
  id,
  index,
  draggedId,
  setDraggedId,
  isDragDisabled = false,
}: IDraggableProps) => {
  // useDrag - the list item is draggable
  const [{ localDraggedId }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    canDrag: !isDragDisabled,
    end: () => setDraggedId && setDraggedId(null),
    collect: (monitor) => {
      return {
        localDraggedId: monitor.isDragging() ? id : null,
      };
    },
  });

  useEffect(() => {
    if (localDraggedId) {
      setDraggedId && setDraggedId(localDraggedId);
    }
  }, [localDraggedId]);

  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item: { index: number }, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = (ref.current as any)?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        (monitor.getClientOffset() as any).y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      item.index = hoverIndex;
      onDragHover(dragIndex, hoverIndex);
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef(null);
  const dragDropRef = dragRef(dropRef(ref));

  return (
    <Container
      containerStyle={style}
      ref={dragDropRef}
      isDragging={id && draggedId === id}
    >
      {children}
    </Container>
  );
};

export default Draggable;
