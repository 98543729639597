import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  RowSpaceBetween,
  SelectWrapper,
  StyledSelect,
  Title,
  TitleContainer,
} from './EditOrderDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Option } from 'components/Select/type';
import CustomSelect from 'components/Select/FormikSelect';
import { useParams } from 'react-router-dom';
import { useGetCompanyUsers } from 'utils/hooks/useGetCompanyUsers';
import { useEditOrderDetails } from './hooks';
import { editOrderDetailsSchema } from './validation';
import { validateOptionalNumericField } from 'utils/validation';

interface IEditOrderDetailsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  quotationData: any;
}
export const EditOrderDetailsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  quotationData,
}: IEditOrderDetailsModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const { data: companyUsers } = useGetCompanyUsers(companyId);
  const { mutate: editOrderDetails } = useEditOrderDetails(id!);

  const salesPersonOptions: Option[] = companyUsers?.users?.map((user: any) => {
    return {
      value: user,
      label: `${user.name} ${user.last_name}`,
      key: user.id,
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'initial',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Order details')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            reference: `${quotationData?.reference}`,
            sales_person: quotationData?.user,
            downpayment: quotationData?.downpayment,
          }}
          validationSchema={editOrderDetailsSchema}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            errors,
            isValid,
            touched,
          }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Sales person').toUpperCase()}</H4>
                    </Heading>
                    <SelectWrapper>
                      <StyledSelect
                        pwId="sales-person-field"
                        errorMessage={
                          touched['sales_person'] ? errors['sales_person'] : ''
                        }
                        name="sales_person"
                        options={salesPersonOptions}
                        component={CustomSelect}
                        isMulti={false}
                        onSelect={({ value }: any) => {
                          setFieldValue('sales_person', value);
                        }}
                      />
                    </SelectWrapper>
                  </ColumnContainer>
                  <RowSpaceBetween>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Reference').toUpperCase()}</H4>
                      </Heading>
                      <FormikInput
                        pwId="reference-field"
                        errorMessage={
                          errors['reference'] ? errors['reference'] : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'reference');
                        }}
                        height={'43rem'}
                        name="reference"
                        wrapperStyles={{
                          width: '300rem',
                          marginBottom: '4rem',
                          marginTop: '20rem',
                        }}
                      />
                    </ColumnContainer>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Down payment').toUpperCase()}</H4>
                      </Heading>
                      <FormikInput
                        pwId="downpayment-field"
                        errorMessage={
                          errors['downpayment']
                            ? (errors['downpayment'] as string)
                            : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'downpayment');
                        }}
                        height={'43rem'}
                        name="downpayment"
                        wrapperStyles={{
                          width: '300rem',
                          marginBottom: '4rem',
                          marginTop: '20rem',
                        }}
                      />
                    </ColumnContainer>
                  </RowSpaceBetween>
                </ColumnContainer>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!values.sales_person}
                    onClick={() => {
                      if (isValid) {
                        editOrderDetails({
                          reference: values.reference,
                          sales_person_id: values.sales_person.id,
                          ...(values.downpayment && {
                            downpayment_amount: values.downpayment,
                          }),
                        });
                        onCancel();
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
