import { Switch } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import Tooltip from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { ISalesInvoice } from 'types/Invoice.types';
import {
  AccountingFlowLabel,
  SwitchWrapper,
} from './AccountingFlowSwitch.styled';
import {
  useConnectToThirdParty,
  useIsTwinfieldCustomerActionAvailable,
} from './hooks';
import { useState } from 'react';
import { ExternalTwinfieldCustomersModal } from './ExternalTwinfieldCustomersModal/ExternalTwinfieldCustomersModal';
import { getTooltipText } from './helpers';
import { ERPError } from 'services/api/errors';
import { useDispatch } from 'react-redux';
import { setMarkAsConnectedInvoiceId } from 'store/Common/actions/common';

interface IAccountingFlowSwitchProps {
  invoice: ISalesInvoice;
}
export const AccountingFlowSwitch = ({
  invoice,
}: IAccountingFlowSwitchProps) => {
  const { t } = useTranslation();
  const accountingIntegrated = invoice?.accounting_connected || false;
  const accountingPending = invoice?.accounting_connection_pending || false;

  const [
    isExternalTwinfieldCustomerModalOpen,
    setIsExternalTwinfieldCustomerModalOpen,
  ] = useState<boolean>(false);
  const isTwinfieldCustomerActionAvailable =
    useIsTwinfieldCustomerActionAvailable(
      invoice?.customer?.twinfield_customer
    );

  const { mutate: enableAccounting, isLoading: isLoadingConnectToThirdParty } =
    useConnectToThirdParty(invoice?.id, invoice?.is_credit);

  const dispatch = useDispatch();
  const callEnableAccounting = () => {
    enableAccounting(undefined, {
      onError: (error: ERPError) => {
        if (error.response.data.code == 409) {
          dispatch(setMarkAsConnectedInvoiceId(invoice?.id));
        }
      },
    });
  };

  return (
    <SwitchWrapper>
      <AccountingFlowLabel isConnected={accountingIntegrated}>
        {t('Accounting')}
      </AccountingFlowLabel>
      <Tooltip content={getTooltipText(t, accountingIntegrated)}>
        <Switch
          disabled={accountingIntegrated}
          checked={accountingIntegrated}
          onChange={() => {
            isTwinfieldCustomerActionAvailable
              ? setIsExternalTwinfieldCustomerModalOpen(true)
              : !isLoadingConnectToThirdParty && callEnableAccounting();
          }}
        />
      </Tooltip>
      {(accountingPending || isLoadingConnectToThirdParty) && (
        <Spinner size={15} />
      )}
      <ExternalTwinfieldCustomersModal
        isOpen={isExternalTwinfieldCustomerModalOpen}
        setIsOpen={setIsExternalTwinfieldCustomerModalOpen}
        onActionComplete={() => callEnableAccounting()}
        zipCode={invoice?.customer?.address?.zip_code}
        customerId={invoice.customer.id}
      />
    </SwitchWrapper>
  );
};
