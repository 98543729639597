import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { deleteMandate, editMandate } from 'services/Mandate/MandateService';
import { setPreferredMandate } from 'services/MollieCustomer/MollieCustomerService';
import { IEditMandateDTO } from './types';

export const useSetPreferredMandate = (mollieCustomerId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (mandateId: string) => setPreferredMandate(mollieCustomerId, mandateId),
    {
      onSuccess: () => {
        toast.success(t('Mandate set as preferred'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SET_PREFERRED_MANDATE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_CUSTOMER);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_MOLLIE_CUSTOMER_MANDATES
        );
      },
      mutationKey: ReactMutationKeys.SET_PREFERRED_MANDATE,
    }
  );
};

export const useDeleteMandate = () => {
  const { t } = useTranslation();
  return useMutation((mandateId: string) => deleteMandate(mandateId), {
    onSuccess: () => {
      toast.success(t('Mandate deleted'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_MANDATE,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_CUSTOMER);
      queryClient.invalidateQueries(
        ReactQueryKeys.GET_SINGLE_MOLLIE_CUSTOMER_MANDATES
      );
    },
    mutationKey: ReactMutationKeys.DELETE_MANDATE,
  });
};

export const useEditMandate = () => {
  const { t } = useTranslation();
  return useMutation(
    (editMandateDTO: IEditMandateDTO) =>
      editMandate(editMandateDTO.mandateId, editMandateDTO.enabled),
    {
      onSuccess: () => {
        toast.success(t('Mandate edited'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_MANDATE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_CUSTOMER);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_MOLLIE_CUSTOMER_MANDATES
        );
      },
      mutationKey: ReactMutationKeys.EDIT_MANDATE,
    }
  );
};
