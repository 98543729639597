import { COLORS, gapXs } from 'assets/styled';
import styled from 'styled-components';

export const RelativeCanvasWrapper = styled.div`
  position: relative;
  background-color: ${COLORS.CANVAS};
  border-radius: 10rem;
`;

export const SignatureErrorLabel = styled.label`
  position: absolute;
  left: 2rem;
  bottom: -15rem;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
`;

export const ClearSignatureLabel = styled.label`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  bottom: -15rem;
  font-weight: 400;
  font-size: 14rem;
  line-height: 10rem;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
`;

export const ColumnGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gapXs};
`;

export const SignatureImage = styled.img`
  width: 350rem;
  height: 250rem;
`;
