import { BlueLabel, Item, Label, List, StyledNav } from './Breadcrumbs.styled';
import useBreadcrumbs from './hooks';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { RoutesConfig } from 'navigation/routes';
import { CaretRight } from '@phosphor-icons/react';

const Breadcrumbs = () => {
  const { breadcrumbs, dynamicLoading } = useBreadcrumbs();
  const { t } = useTranslation();

  return (
    <StyledNav>
      <List>
        {breadcrumbs.map(({ name, path, isLast }, index) => {
          if (path === RoutesConfig.FwHome.fullPath) {
            // Skip rendering /fw breadcrumb
            return;
          }
          const isDynamicLoading = dynamicLoading[path];
          return (
            name && (
              <Item key={index}>
                {isDynamicLoading ? (
                  <>
                    <Spinner size={15} />
                    {!isLast && (
                      <Icon
                        size={15}
                        svg={CaretRight}
                        weight="regular"
                        marginLeft="10rem"
                        marginRight="10rem"
                        color={COLORS.BLACK}
                      />
                    )}
                  </>
                ) : isLast ? (
                  <BlueLabel>{t(name)}</BlueLabel>
                ) : (
                  <Label to={path}>
                    {t(name)}{' '}
                    <Icon
                      size={15}
                      svg={CaretRight}
                      weight="regular"
                      marginLeft="10rem"
                      marginRight="10rem"
                      color={COLORS.BLACK}
                    />
                  </Label>
                )}
              </Item>
            )
          );
        })}
      </List>
    </StyledNav>
  );
};

export default Breadcrumbs;
