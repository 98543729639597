import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSinglePurchaseOrderSalesOrders } from 'services/PurchaseOrder/PurchaseOrderService';

export const useGetPurchaseOrderId = () => {
  const location = useLocation();
  return location.pathname.split('/')[2];
};

export const useGetSinglePurchaseOrderSalesOrders = (
  salesOrderId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_SALES_ORDERS,
      salesOrderId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getSinglePurchaseOrderSalesOrders(salesOrderId, page, perPage);
    },
  });
