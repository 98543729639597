import { COLORS, borderRadiusSm, marginMd, paddingMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 850rem;
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

export const LocationLabel = styled.label`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
`;
