import { Option } from 'components/Select/type';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/analytics/';

export const getSalesOrdersAnalytic = async (
  selectedSalesOrders: Option
): Promise<any> => {
  const queryParamsObj = { time_range: selectedSalesOrders.value };
  const url = `${BASE_URL}sales-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data.analytics_data;
};

export const getOrderPortfolioAnalytic = async (
  selectedOrderPortfolio: any
): Promise<any> => {
  const queryParamsObj = {
    status_id: 2,
    sub_status_id: selectedOrderPortfolio.id,
  };
  const url = `${BASE_URL}order-portfolio`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data.analytics_data;
};

export const getStockItemsAnalytic = async (): Promise<any> => {
  const url = `${BASE_URL}stock-items`;
  const { data } = await apiClient.get<any>(url);
  return data.data.analytics_data;
};

export const getQuotationsAnalytic = async (
  selectedQuotations: Option
): Promise<any> => {
  const queryParamsObj = { time_range: selectedQuotations.value };
  const url = `${BASE_URL}quotations`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data.analytics_data;
};

export const getSalesInvoicesAnalytic = async (
  selectedInvoices: Option
): Promise<any> => {
  const queryParamsObj = { time_range: selectedInvoices.value };
  const url = `${BASE_URL}sales-invoices`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data.analytics_data;
};

export const getPurchaseOrderAnalytic = async (): Promise<any> => {
  const url = `${BASE_URL}purchase-orders`;
  const { data } = await apiClient.get<any>(url);
  return data.data.analytics_data;
};
