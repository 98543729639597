import { useGetSinglePurchaseInvoice } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  ColumnContainer,
  Label,
  LabelTitle,
  MainContainer,
  TextContainer,
  TitleContainer,
} from './SinglePurchaseInvoicePurchaseOrderPage.styled';
import { H3, H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import { useGetSinglePurchaseOrder } from 'pages/Manager/SinglePurchaseOrder/hooks';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseInvoicePurchaseOrderPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const canReadPurchaseOrder = useCan(Actions.READ_PURCHASE_ORDER);
  const { status, data, isFetching } = useGetSinglePurchaseInvoice(
    typeof id === 'string' ? id : ''
  );

  const { data: purchaseOrder } = useGetSinglePurchaseOrder(
    data?.purchase_invoice.purchase_order_id
  );
  const currencySymbol = useGetCurrencySymbol();

  return (
    <>
      <Card>
        <TitleContainer>
          <H3>{purchaseOrder?.invoice_number}</H3>
          <Button
            onClick={() =>
              navigate(
                `/purchases/${data?.purchase_invoice?.purchase_order_id}`
              )
            }
            label={t('View purchase order')}
            primary
            disabled={!canReadPurchaseOrder}
          ></Button>
        </TitleContainer>
        <MainContainer>
          <ColumnContainer>
            <H4>{t('DETAILS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Date')}</LabelTitle>
              <Label>{`${moment(data?.valid_to)
                .tz(moment.tz.guess())
                .format('DD-MM-YYYY')}`}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Reference')}</LabelTitle>
              <Label>{purchaseOrder?.reference}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Supplier')}</LabelTitle>
              <Label>{purchaseOrder?.supplier?.company_name}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Amount')}</LabelTitle>
              <Label>
                {currencySymbol}
                {formatNumber(purchaseOrder?.total_amount)}
              </Label>
            </TextContainer>
          </ColumnContainer>
          <ColumnContainer>
            <H4>{t('STATUS')}</H4>
            <TextContainer>
              <LabelTitle>{t('Payment')}</LabelTitle>
              <Label>{t(purchaseOrder?.payment_status?.name)}</Label>
            </TextContainer>
            <TextContainer>
              <LabelTitle>{t('Status')}</LabelTitle>
              <Label>{purchaseOrder?.status?.name}</Label>
            </TextContainer>
          </ColumnContainer>
        </MainContainer>
      </Card>
    </>
  );
};

export default SinglePurchaseInvoicePurchaseOrderPage;
