import Button from 'components/Button/Button';
import TableAlt from 'components/TableAlt/TableAlt';
import { formatAppointmentTableData } from 'components/TableAlt/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';
import { EditAppointmentModal } from 'pages/Manager/PlanningPage/EditAppointmentModal/EditAppointmentModal';
import ViewAppointmentModal from 'pages/Manager/PlanningPage/ViewAppointmentModal/ViewAppointmentModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IAppointment } from 'types/Appointment.types';
import { Actions } from 'types/Permissions.types';
import useCan from 'utils/hooks/useCan';
import {
  Container,
  FirstRow,
  Message,
  NoResultsContainer,
  TableWrapper,
  Wrapper,
} from './SingleCustomerAppointmentsPage.styled';
import { useGetSingleCustomerAppointments } from './hooks';
import { APPOINTMENTS_PER_PAGE, tableConfig } from './constants';
import { H3 } from 'assets/styled';
import { IAppointmentTableDTO } from './types';

export const SingleCustomerAppointmentsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isViewAppointmentModalOpen, setIsViewAppointmentModalOpen] =
    useState<boolean>(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<
    number | null
  >(null);
  const [isEditingModalOpen, setIsEditingModalOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const canEditAppointment = useCan(Actions.EDIT_APPOINTMENT);
  const canReadAppointment = useCan(Actions.READ_APPOINTMENT);

  const { data } = useGetSingleCustomerAppointments(
    id!,
    page,
    APPOINTMENTS_PER_PAGE
  );

  const handleButtonClick = (rowData: IAppointmentTableDTO) => {
    setSelectedAppointmentId(rowData.navigationID);
    setIsViewAppointmentModalOpen(true);
  };

  let appointmentsData: IAppointmentTableDTO[] = [];

  if (data?.appointments) {
    appointmentsData = formatAppointmentTableData(
      data?.appointments
        ?.map((appointment: IAppointment) => {
          return appointment;
        })
        .flat()
    );
  }

  return (
    <Container>
      {!data?.appointments?.length && canReadAppointment ? (
        <NoResultsContainer>
          <Message>{t('There are currently no appointments')}</Message>
          <Button
            label={t('Go to planning')}
            secondary
            disabled={!canEditAppointment}
            onClick={() => navigate(RoutesConfig.Planning.fullPath)}
          />
        </NoResultsContainer>
      ) : (
        <Wrapper>
          <FirstRow>
            <H3>{t('Appointments')}</H3>
            <Button
              label={t('Go to planning')}
              secondary
              disabled={!canEditAppointment}
              onClick={() => navigate(RoutesConfig.Planning.fullPath)}
            />
          </FirstRow>
          {canReadAppointment && !!data?.appointments?.length && (
            <TableWrapper>
              <TableAlt
                page={page}
                perPage={APPOINTMENTS_PER_PAGE}
                total={data?.total}
                tableData={appointmentsData}
                tableConfig={tableConfig}
                hasExtraAction={true}
                loadPage={(newPage) => setPage(newPage)}
                onButtonClick={handleButtonClick}
              />
            </TableWrapper>
          )}
          <ViewAppointmentModal
            onEdit={() => setIsEditingModalOpen(true)}
            isOpen={isViewAppointmentModalOpen}
            setIsOpen={setIsViewAppointmentModalOpen}
            id={selectedAppointmentId ? selectedAppointmentId?.toString() : ''}
          />
          <EditAppointmentModal
            isOpen={isEditingModalOpen}
            setIsOpen={setIsEditingModalOpen}
            onCancel={() => setIsEditingModalOpen(false)}
            appointmentId={
              selectedAppointmentId ? selectedAppointmentId?.toString() : ''
            }
          />
        </Wrapper>
      )}

      {!canReadAppointment && (
        <NoResultsContainer>
          <Message>{t('No permission')}</Message>
        </NoResultsContainer>
      )}
    </Container>
  );
};
