import { COLORS, marginSm, paddingSm } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
  margin-top: ${marginSm};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;
