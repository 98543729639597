import { Modal } from 'components/Modal/Modal';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  CloseAbsoluteWrapper,
  Container,
  Header,
  Headline,
  Wrapper,
  SubmitRow,
  Label,
  SelectAll,
  Products,
  Product,
  Title,
  Amount,
  More,
  Select,
  Margin,
  HalfRow,
  HalfRowAlt,
  NoLines,
} from './PartialInvoiceModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { formatNumber } from 'utils/numberFormatter';
import { ViewProductModal } from 'components/Modal/ViewProductModal/ViewProductModal';
import { ViewProductModalFormatter } from 'components/Modal/ViewProductModal/helpers';
import { ViewProductType } from 'components/Modal/ViewProductModal/type';
import { IPendingInvoiceLine } from '../../constants';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

interface IPartialInvoiceModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedPartialIds: number[];
  handleToggleSelectPartials: (id: number) => void;
  handleSelectAllPartials: () => void;
  pendingInvoiceLines: IPendingInvoiceLine[];
}

const PartialInvoiceModal = ({
  isOpen,
  setIsOpen,
  selectedPartialIds,
  handleToggleSelectPartials,
  handleSelectAllPartials,
  pendingInvoiceLines,
}: IPartialInvoiceModalProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const [selectedLine, setSelectedLine] = useState<IPendingInvoiceLine>();
  const [isViewProductModalOpen, setIsViewProductModalOpen] =
    useState<boolean>(false);

  const isAllSelected =
    selectedPartialIds.length === (pendingInvoiceLines?.length || 0);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        setIsOpen(boolean);
      }}
      modalStyle={{ margin: 'auto', position: 'fixed' }} // Center positioning>
    >
      <Container>
        {pendingInvoiceLines?.length !== 0 ? (
          <Fragment>
            <Headline>{t('Partial invoice')}</Headline>
            <Wrapper>
              <Header>
                <HalfRow>
                  <Label>{t('Product')}</Label>
                </HalfRow>
                <HalfRowAlt>
                  <Label>{t('Total amount')}</Label>
                  <SelectAll onClick={handleSelectAllPartials}>
                    {isAllSelected ? t('Deselect all') : t('Select all')}
                  </SelectAll>
                </HalfRowAlt>
              </Header>
              <Products>
                {pendingInvoiceLines?.map((line: IPendingInvoiceLine) => {
                  const title = line.product
                    ? line.product?.name
                    : line?.service?.purpose
                    ? line?.service?.purpose
                    : t('Working hours');

                  const isSelected = selectedPartialIds.includes(line.id);

                  return (
                    <Product key={line.id} $selected={isSelected}>
                      <HalfRow>
                        <Title>{title}</Title>
                      </HalfRow>
                      <HalfRowAlt>
                        <Amount>
                          {currencySymbol}
                          {formatNumber(line.total_amount)}
                        </Amount>
                        {line.product && (
                          <More
                            onClick={() => {
                              setSelectedLine(line);
                              setIsViewProductModalOpen(true);
                            }}
                          >
                            {t('More')}
                          </More>
                        )}
                        <Select
                          onClick={() => handleToggleSelectPartials(line.id)}
                        >
                          {isSelected ? t('Unselect') : t('Select')}
                        </Select>
                      </HalfRowAlt>
                    </Product>
                  );
                })}
              </Products>
            </Wrapper>
            <SubmitRow>
              <Button
                width={'200rem'}
                onClick={() => setIsOpen(false)}
                label={t('Cancel')}
                secondary
              />
              <Margin>
                <Button
                  width={'200rem'}
                  onClick={() => setIsOpen(false)}
                  label={t('Finish')}
                  primary
                />
              </Margin>
            </SubmitRow>
            <CloseAbsoluteWrapper>
              <Close
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </CloseAbsoluteWrapper>
          </Fragment>
        ) : (
          <Fragment>
            <NoLines>No lines to invoice</NoLines>
            <CloseAbsoluteWrapper>
              <Close
                onClick={() => {
                  setIsOpen(false);
                }}
              />
            </CloseAbsoluteWrapper>
          </Fragment>
        )}
        <ViewProductModal
          isOpen={isViewProductModalOpen}
          setIsOpen={setIsViewProductModalOpen}
          onCancel={() => setIsViewProductModalOpen(false)}
          productLineData={ViewProductModalFormatter(
            selectedLine,
            ViewProductType.PENDING_INVOICE
          )}
        />
      </Container>
    </Modal>
  );
};

export default PartialInvoiceModal;
