import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Name', flexValue: 1, isBlue: false },
  { header: 'Quantity', flexValue: 1, isBlue: false },
  { header: 'Unit price', flexValue: 1, isBlue: false },
  { header: 'Attributes', flexValue: 1, isBlue: false },
  { header: 'Total Price', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};
