import { marginSm } from 'assets/styled';
import {
  HorizontalLine,
  NoContentLabel,
  SectionTitle,
} from '../ReportForm.styled';
import { ProductsWrapper, Section } from './ProductsSection.styled';
import { useTranslation } from 'react-i18next';
import { IAppointment, IFwAppointmentLine } from 'types/Appointment.types';
import { Fragment } from 'react';
import { FwAppointmentProductCard } from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentProductsPage/FwAppointmentProductCard/FwAppointmentProductCard';

interface IProductsSectionProps {
  appointment: IAppointment;
  appointmentLines: IFwAppointmentLine[];
}

const ProductsSection = ({
  appointment,
  appointmentLines,
}: IProductsSectionProps) => {
  const { t } = useTranslation();
  return (
    <Section>
      <SectionTitle marginBottom={marginSm}>{t('PRODUCTS')}</SectionTitle>
      <ProductsWrapper>
        {appointmentLines.map(
          (appointmentLine: IFwAppointmentLine, index: number) => {
            return (
              <Fragment key={index}>
                <FwAppointmentProductCard
                  appointment={appointment}
                  appointmentLine={appointmentLine}
                  withoutContainer
                />
                {appointmentLines.length - 1 > index ? (
                  <HorizontalLine />
                ) : null}
              </Fragment>
            );
          }
        )}
        {!appointmentLines?.length ? (
          <NoContentLabel>
            {t('There are no appointment lines on this appointment')}
          </NoContentLabel>
        ) : null}
      </ProductsWrapper>
    </Section>
  );
};

export default ProductsSection;
