import moment, { Moment } from 'moment';
import { updatePlanningTab } from 'store/Filters/actions/filters';
import { queryClient } from 'index';
import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getAppointments,
  exportAppointment,
  editAppointment,
} from 'services/Appointment/AppointmentService';
import {
  createAppointment,
  getSingleAppointment,
  deleteAppointment,
} from 'services/Appointment/AppointmentService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Option } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { COLORS } from 'assets/styled';
import { getQuotations } from 'services/Quotation/QuotationService';
import { getSalesOrders } from 'services/SalesOrder/SalesOrderService';
import { getCustomers } from 'services/Customer/CustomerService';
import { getWorkers, getWorkersById } from 'services/Workers/WorkersService';
import {
  getResources,
  getResourcesById,
} from 'services/Resources/ResourcesService';
import { useDispatch } from 'react-redux';
import { IPlanningTabFilter } from 'store/Filters/types';
import { getUsers, getUsersById } from 'services/User/UserService';
import FullCalendar from '@fullcalendar/react';
import { ICreateAppointmentDTO } from '../types';
import { IEditAppointmentDTO } from '../EditAppointmentModal/hooks';
import { FiltersPageEnum } from 'store/Filters/constants';
import { getOptionsFromData } from '../helpers';

export const useGetAppointments = (
  dateFrom: Moment,
  dateTo: Moment,
  users?: string[],
  workers?: string[],
  resources?: string[],
  selectedTypes?: Option[]
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_APPOINTMENTS,
      dateFrom,
      dateTo,
      users,
      workers,
      resources,
      selectedTypes,
    ],
    queryFn: () => {
      return getAppointments(
        dateFrom,
        dateTo,
        users,
        workers,
        resources,
        selectedTypes
      );
    },
  });

export const useCreateAppointment = () => {
  const { t } = useTranslation();
  return useMutation(
    (data: {
      createAppointmentData: ICreateAppointmentDTO;
      shouldSendEmail: boolean;
      note: string;
      files: any;
    }) =>
      createAppointment(
        data.createAppointmentData,
        data.shouldSendEmail,
        data.note,
        data.files
      ),
    {
      onSuccess: (data) => {
        toast.success(t('Successfully created appointment'), {
          className: ReactMutationKeys.CREATE_APPOINTMENT,
        });
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_APPOINTMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_APPOINTMENTS);
      },
      mutationKey: ReactMutationKeys.CREATE_APPOINTMENT,
    }
  );
};

export const useGetSingleAppointment = (
  appointmentId: string,
  isEnabled: boolean
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_APPOINTMENT, appointmentId],
    queryFn: () => {
      return getSingleAppointment(appointmentId);
    },
    enabled: isEnabled,
  });

export const useDeleteAppointment = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteAppointment(id), {
    onSuccess: () => {
      toast.success(t('Successfully deleted appointment'));
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.errors?.[0]?.split(':')?.[1] ===
        ' Appointment cannot be deleted'
      ) {
        toast.error(t('Appointment cannot be deleted'));
      } else {
        toast.error(getToastErrorMessage(error));
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_APPOINTMENTS);
    },
    mutationKey: ReactMutationKeys.DELETE_APPOINTMENT,
  });
};

export const useExportAppointment = () =>
  useMutation((appointmentId: string) => exportAppointment(appointmentId), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EXPORT_APPOINTMENT,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.EXPORT_APPOINTMENT,
  });

export interface IEditAppointmentDragAndDropDTO {
  appointmentId: string;
  date_from: string;
  date_to: string;
}

export const useEditDragAndDropAppointment = () => {
  const { t } = useTranslation();
  return useMutation(
    (editAppointmentDTO: IEditAppointmentDragAndDropDTO) => {
      const { appointmentId, ...rest } = editAppointmentDTO;
      // "rest" is date_from and date_to (subset of IEditAppointmentDTO)
      return editAppointment(appointmentId, rest as IEditAppointmentDTO);
    },
    {
      onSuccess: () => {
        toast.success(t('Appointment edited'), {
          className: ReactMutationKeys.EDIT_APPOINTMENT,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_APPOINTMENT]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_APPOINTMENT,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_APPOINTMENTS]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_APPOINTMENT]);
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_APPOINTMENT,
    }
  );
};

export const useGetAppointmentTypeOptions = () => {
  return useMemo(
    () => [
      { value: '1', label: 'Measuring', key: 'Measuring' },
      { value: '2', label: 'Installation', key: 'Installation' },
      { value: '3', label: 'General', key: 'General' },
      { value: '4', label: 'Service', key: 'Service' },
    ],
    []
  );
};

export const useGetAppointmentViewOptions = () => {
  return useMemo(
    () => [
      { value: 'timeGridDay', label: 'Day', key: 'Day' },
      { value: 'timeGridWeek', label: 'Week', key: 'Week' },
      { value: 'dayGridMonth', label: 'Month', key: 'Month' },
    ],
    []
  );
};

const enDaysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const nlDaysShort = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'];
const deDaysShort = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
const frDaysShort = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
const noDaysShort = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'];
const srDaysShort = ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'];

export const useUpdateCalendarShortDayNamesToResolvedLocale = (
  startDate: Moment,
  endDate: Moment,
  setDateForDaySummary: Dispatch<SetStateAction<Moment | null>>,
  handleViewChange?: any,
  selectedAppointmentViewOption?: string,
  setFilterDate?: Dispatch<SetStateAction<string>>
) => {
  if (!(startDate instanceof moment)) {
    startDate = moment(startDate);
  }
  if (!(endDate instanceof moment)) {
    endDate = moment(endDate);
  }

  const appointmentViewOptions = useGetAppointmentViewOptions();

  const selectedLocaleDaysShort = useMemo(() => {
    switch (i18n.resolvedLanguage) {
      case 'en':
        return enDaysShort;
      case 'nl':
        return nlDaysShort;
      case 'de':
        return deDaysShort;
      case 'fr':
        return frDaysShort;
      case 'no':
        return noDaysShort;
      case 'sr':
        return srDaysShort;
      default:
        return enDaysShort;
    }
  }, [i18n.resolvedLanguage]);

  const handleCircularContainerClick = (date: Moment) => {
    if (selectedAppointmentViewOption === 'dayGridMonth') return;

    setFilterDate && setFilterDate(date.format('YYYY-MM-DD'));
    handleViewChange && handleViewChange(appointmentViewOptions[0]);
    setDateForDaySummary(date);
  };

  useEffect(() => {
    // Create and insert hover styles
    const hoverStyleId = 'custom-hover-style';
    let hoverStyle = document.getElementById(hoverStyleId);
    if (!hoverStyle) {
      hoverStyle = document.createElement('style');
      hoverStyle.id = hoverStyleId;
      hoverStyle.textContent = `
        .circular-container:hover {
          background-color: ${COLORS.BACKGROUND_COLOR}; 
          cursor: pointer;
        }
      `;
      document.head.appendChild(hoverStyle);
    }

    // Function to update calendar short day names
    const updateCalendarShortDayNames = () => {
      // Remove existing custom elements before adding new ones
      const existingContainers = document.querySelectorAll(
        '.circular-container'
      );
      existingContainers.forEach((container) => container.remove());

      const elements = document.querySelectorAll('.fc-col-header-cell-cushion');
      Array.prototype.forEach.call(elements, (element: HTMLElement, index) => {
        element.style.color = 'transparent';
        element.style.display = 'flex';
        element.style.flexDirection = 'column';
        element.style.justifyContent = 'space-between';
        element.style.alignItems = 'center';

        // Create circular container
        const circularContainer = document.createElement('div');
        circularContainer.style.borderRadius = '50%'; // Make it circular
        circularContainer.style.padding = '10px'; // Adjust padding as needed
        circularContainer.style.display = 'flex';
        circularContainer.style.height = '60rem';
        circularContainer.style.width = '60rem';
        circularContainer.style.flexDirection = 'column';
        circularContainer.style.justifyContent = 'center';
        circularContainer.style.alignItems = 'center';
        circularContainer.className = 'circular-container'; // Add class for styling
        const date = startDate.clone().add(index, 'days');
        circularContainer.onclick = () => handleCircularContainerClick(date); // Attach the event handler
        circularContainer.style.position = 'relative';

        // Create and append the day name element
        const newElement = document.createElement(
          'custom-calendar-short-day-name'
        );
        const dayIndex = enDaysShort.indexOf(element.textContent || '');
        newElement.textContent =
          selectedLocaleDaysShort[
            selectedAppointmentViewOption === 'timeGridDay' ? dayIndex : index
          ];
        newElement.style.color = COLORS.BLACK;
        circularContainer.appendChild(newElement);

        // Create and append the date element
        const secondElement = document.createElement(
          'custom-calendar-short-day-name'
        );
        secondElement.style.color = COLORS.GREY_800;
        if (selectedAppointmentViewOption !== 'dayGridMonth') {
          secondElement.textContent = date.format('DD/MM');
        }
        circularContainer.appendChild(secondElement);

        // Append the circular container to the main element
        element.appendChild(circularContainer);
      });
    };

    // Initial call to update names
    updateCalendarShortDayNames();

    // Cleanup function
    return () => {
      // Remove custom styles and containers
      const hoverStyle = document.getElementById(hoverStyleId);
      if (hoverStyle) {
        hoverStyle.remove();
      }

      const circularContainers = document.querySelectorAll(
        '.circular-container'
      );
      circularContainers.forEach((container) => container.remove());
    };
  }, [selectedLocaleDaysShort, startDate, endDate]);
};

export const useGetQuotationsInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATIONS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getQuotations(
        pageParam,
        perPage,
        undefined,
        undefined,
        undefined,
        searchBy
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetSalesOrdersInfinite = (
  perPage: number,
  searchBy: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SALES_ORDERS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getSalesOrders(
        pageParam,
        perPage,
        undefined,
        undefined,
        undefined,
        searchBy
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetCustomersInfinite = (
  perPage: number,
  searchBy: string,
  isActive: boolean | null,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_CUSTOMERS, perPage, searchBy, isActive],
    queryFn: ({ pageParam = 1 }) =>
      getCustomers(
        pageParam,
        perPage,
        searchBy,
        isActive,
        undefined,
        undefined
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetUsersInfinite = (
  perPage: number,
  searchBy: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_COMPANY_USERS,
      perPage,
      searchBy,
      dateFrom,
      dateTo,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getUsers(pageParam, perPage, searchBy, dateFrom, dateTo),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetWorkersInfinite = (
  perPage: number,
  searchBy: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_WORKERS, perPage, searchBy, dateFrom, dateTo],
    queryFn: ({ pageParam = 1 }) =>
      getWorkers(pageParam, perPage, searchBy, dateFrom, dateTo),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useGetResourcesInfinite = (
  perPage: number,
  searchBy: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_RESOURCES,
      perPage,
      searchBy,
      dateFrom,
      dateTo,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getResources(pageParam, perPage, searchBy, dateFrom, dateTo),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
    cacheTime: 0,
    enabled: isEnabled,
  });

export const useUnselectTemporarySelectionOnEscapeClick = (
  calendarRef: RefObject<FullCalendar>
) => {
  useEffect(() => {
    const close = (e: any) => {
      if (e.key === 'Escape') {
        calendarRef.current?.getApi()?.unselect();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
};

const useFetchUsers = () => {
  return useMutation((userIds: string[]) => getUsersById(userIds), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_USERS_BY_ID,
      });
    },
    mutationKey: ReactMutationKeys.GET_USERS_BY_ID,
  });
};

const useFetchWorkers = () => {
  return useMutation((workerIds: string[]) => getWorkersById(workerIds), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_WORKERS_BY_ID,
      });
    },
    mutationKey: ReactMutationKeys.GET_WORKERS_BY_ID,
  });
};

const useFetchResources = () => {
  return useMutation((resourceIds: string[]) => getResourcesById(resourceIds), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_RESOURCES_BY_ID,
      });
    },
    mutationKey: ReactMutationKeys.GET_RESOURCES_BY_ID,
  });
};

interface IFetchRequiredEntitiesProps {
  selectedUsers: string[];
  selectedWorkers: string[];
  selectedResources: string[];
}

export const useFetchRequiredEntities = ({
  selectedUsers,
  selectedWorkers,
  selectedResources,
}: IFetchRequiredEntitiesProps) => {
  const fetchUsersMutation = useFetchUsers();
  const fetchWorkersMutation = useFetchWorkers();
  const fetchResourcesMutation = useFetchResources();

  const executeFetch = async () => {
    try {
      const results = await Promise.all([
        selectedUsers.length > 0
          ? fetchUsersMutation.mutateAsync(selectedUsers)
          : Promise.resolve(null),
        selectedWorkers.length > 0
          ? fetchWorkersMutation.mutateAsync(selectedWorkers)
          : Promise.resolve(null),
        selectedResources.length > 0
          ? fetchResourcesMutation.mutateAsync(selectedResources)
          : Promise.resolve(null),
      ]);

      const isUsersSuccess = selectedUsers.length === 0 || results[0] !== null;
      const isWorkersSuccess =
        selectedWorkers.length === 0 || results[1] !== null;
      const isResourcesSuccess =
        selectedResources.length === 0 || results[2] !== null;

      return {
        isSuccess: isUsersSuccess && isWorkersSuccess && isResourcesSuccess,
      };
    } catch (error) {
      console.error(error);
      return { isSuccess: false };
    }
  };

  return {
    executeFetch,
    selectedUsersOptions:
      getOptionsFromData(fetchUsersMutation.data, 'Users') || [],
    selectedWorkersOptions:
      getOptionsFromData(fetchWorkersMutation.data, 'Workers') || [],
    selectedResourcesOptions:
      getOptionsFromData(fetchResourcesMutation.data, 'Resources') || [],
  };
};

export const useManageAndSaveFilters = (
  activeTab: IPlanningTabFilter,
  appointmentTypeOptions: Option[],
  appointmentViewOptions: Option[]
) => {
  const dispatch = useDispatch();
  // Ref that holds the latest values of all filters
  const tabRef = useRef<IPlanningTabFilter>(activeTab);

  // State for each filter
  const [startDate, setStartDate] = useState<Moment>(
    moment(activeTab.startDate)
  );
  const [endDate, setEndDate] = useState<Moment>(moment(activeTab.endDate));
  const [filterDate, setFilterDate] = useState<string>(
    activeTab.filterDate || ''
  );
  const [selectedWorkers, setSelectedWorkers] = useState<string[]>(
    activeTab.selectedWorkers || []
  );
  const [selectedResources, setSelectedResources] = useState<string[]>(
    activeTab.selectedResources || []
  );
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    activeTab.selectedUsers || []
  );

  const initialSelectedAppointmentTypeOptions = useMemo(
    () =>
      activeTab.selectedAppointmentTypeOptions || [
        appointmentTypeOptions[0],
        appointmentTypeOptions[1],
        appointmentTypeOptions[2],
        appointmentTypeOptions[3],
      ],
    [appointmentTypeOptions, activeTab.selectedAppointmentTypeOptions]
  );

  const initialSelectedAppointmentViewOption = useMemo(
    () => activeTab.selectedAppointmentViewOption || appointmentViewOptions[1],
    [appointmentViewOptions, activeTab.selectedAppointmentViewOption]
  );

  const [selectedAppointmentTypeOptions, setSelectedAppointmentTypeOptions] =
    useState<Option[]>(initialSelectedAppointmentTypeOptions);
  const [selectedAppointmentViewOption, setSelectedAppointmentViewOption] =
    useState<Option>(initialSelectedAppointmentViewOption);

  // Update the ref every time any filter value changes
  useEffect(() => {
    const updatedTab = {
      id: activeTab.id,
      name: activeTab.name,
      startDate,
      endDate,
      filterDate,
      selectedWorkers,
      selectedResources,
      selectedUsers,
      selectedAppointmentTypeOptions,
      selectedAppointmentViewOption,
    };
    tabRef.current = updatedTab;
  }, [
    activeTab.name,
    startDate,
    endDate,
    filterDate,
    selectedWorkers,
    selectedResources,
    selectedUsers,
    selectedAppointmentTypeOptions,
    selectedAppointmentViewOption,
  ]);

  useEffect(() => {
    return () => {
      dispatch(
        updatePlanningTab({
          page: FiltersPageEnum.PLANNING,
          activeTabId: tabRef.current.id,
          data: tabRef.current,
        })
      );
    };
  }, []);

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterDate,
    setFilterDate,
    selectedWorkers,
    setSelectedWorkers,
    selectedResources,
    setSelectedResources,
    selectedUsers,
    setSelectedUsers,
    selectedAppointmentTypeOptions,
    setSelectedAppointmentTypeOptions,
    selectedAppointmentViewOption,
    setSelectedAppointmentViewOption,
  };
};
