import { IProductsTableConfig, IProductsTableRowConfig } from './ProductsTable';

// Keys existing in tableData that will not be displayed as a column
export const excludedTableDataKeys: string[] = ['id'];

export const translatableColumnFields: string[] = ['type', 'status'];

const rowConfigs: IProductsTableRowConfig[] = [
  { header: 'Name', flexValue: 1 },
  { header: 'Description', flexValue: 1 },
];

export const tableConfig: IProductsTableConfig = {
  rowConfigs: rowConfigs,
};
