import Button from 'components/Button/Button';
import { Pagination } from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { excludedTableDataKeys, translatableColumnFields } from './constants';
import {
  ActionCell,
  ActionHeaderCell,
  Body,
  BodyCell,
  BodyLabel,
  Footer,
  Header,
  HeaderCell,
  HeaderLabel,
  Row,
  Table,
} from './ProductsTable.styled';
import { Copy, PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';

interface IProductsTable {
  page: number;
  perPage: number;
  total: number;
  loadPage: (page: number) => void;
  tableData: any[];
  tableConfig: IProductsTableConfig;
  onEdit?: (param: any) => void;
  onDelete?: (param: any) => void;
  onCopy?: (param: any) => void;
  animatedRows?: boolean;
  removedRows?: string[];
}

export interface IProductsTableConfig {
  rowConfigs: IProductsTableRowConfig[];
}

export interface IProductsTableRowConfig {
  header: string;
  flexValue: number;
  isBlue?: boolean;
}

export const ProductsTable = ({
  page,
  perPage,
  total,
  loadPage,
  tableConfig,
  tableData,
  onEdit,
  onDelete,
  onCopy,
  animatedRows,
  removedRows,
}: IProductsTable) => {
  const { t } = useTranslation();

  return (
    <Table>
      <Header>
        {tableConfig?.rowConfigs.map((rowData, index) => (
          <HeaderCell key={index} $flexValue={rowData.flexValue}>
            <HeaderLabel>{t(rowData.header)}</HeaderLabel>
          </HeaderCell>
        ))}
        {onCopy && <ActionHeaderCell></ActionHeaderCell>}
        {onEdit && <ActionHeaderCell></ActionHeaderCell>}
        {onDelete && <ActionHeaderCell></ActionHeaderCell>}
      </Header>
      <Body>
        {tableData?.map((rowData, rowIndex) => {
          return (
            <Row
              key={rowIndex}
              className={
                animatedRows && removedRows?.includes(rowData.entityId)
                  ? 'exit-row'
                  : ''
              }
            >
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell key={cellIndex}>
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex]?.isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                  </BodyCell>
                ))}
              {onCopy && (
                <ActionCell>
                  <Icon
                    svg={Copy}
                    size={20}
                    color={COLORS.PRIMARY}
                    onClick={() => onCopy(rowData)}
                  />
                </ActionCell>
              )}
              {onEdit && (
                <ActionCell>
                  <Icon
                    svg={PencilSimple}
                    size={20}
                    color={COLORS.PRIMARY}
                    onClick={() => onEdit(rowData)}
                  />
                </ActionCell>
              )}
              {onDelete && (
                <ActionCell>
                  <Icon
                    svg={Trash}
                    size={20}
                    color={COLORS.RED_TRASH}
                    onClick={() => onDelete(rowData)}
                  />
                </ActionCell>
              )}
            </Row>
          );
        })}
      </Body>
      <Footer>
        {total > perPage && (
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            loadPage={loadPage}
          />
        )}
      </Footer>
    </Table>
  );
};
