import { menuItems } from './constants';
import {
  Container,
  ButtonContainer,
  TopContainer,
  ItemContainer,
  ItemText,
  ByText,
  LogoImg,
  ByCodeFlexImg,
  ByCodeFlexWrapper,
  RowCollapse,
} from './SideMenu.styled';
import BlueprintLogoBig from 'assets/images/blueprintLogoBig.png';
import BpSymbolWhite from 'assets/icons/bpSymbolWhite.svg';
import codeflexLogoTextRight from 'assets/images/codeflexLogoTextRight.png';
import codeflexLogo from 'assets/images/codeflexLogo.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { toggleSideMenu } from 'store/Common/actions/common';
import { useCheckPermissions, useGetActiveCurrentRoute } from './hooks';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Link } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { ArrowLineRight, ArrowLineLeft } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SideMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop } = useBreakpointFlag();

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );

  useEffect(() => {
    if (!isSideMenuCollapsed && !isDesktop) {
      dispatch(toggleSideMenu(true));
    }
  }, [isDesktop]);

  const filteredMenuItems = useCheckPermissions(menuItems);

  const currentRoute = useGetActiveCurrentRoute(filteredMenuItems);

  return (
    <Container
      isSideMenuCollapsed={isSideMenuCollapsed}
      className="home-step-6"
    >
      <TopContainer>
        <Link to={RoutesConfig.Home.fullPath}>
          <LogoImg
            isSideMenuCollapsed={isSideMenuCollapsed}
            src={isSideMenuCollapsed ? BpSymbolWhite : BlueprintLogoBig}
            alt=""
          />
        </Link>
        <ButtonContainer>
          {filteredMenuItems.map((menuItem) => {
            const isActive =
              currentRoute && currentRoute.route === menuItem.route;
            return (
              <ItemContainer
                to={menuItem.route}
                $isSideMenuCollapsed={isSideMenuCollapsed}
                key={menuItem.text}
                className={isActive ? 'active' : ''}
                $isActive={isActive}
              >
                <Icon
                  svg={menuItem.icon}
                  size={30}
                  marginLeft={isSideMenuCollapsed ? '40rem' : '50rem'}
                  color={COLORS.STRICT_WHITE}
                  paddingRight="20rem"
                />
                <ItemText isSideMenuCollapsed={isSideMenuCollapsed}>
                  {t(menuItem.text)}
                </ItemText>
              </ItemContainer>
            );
          })}
        </ButtonContainer>
      </TopContainer>

      <ByCodeFlexWrapper>
        {isDesktop && (
          <RowCollapse onClick={() => dispatch(toggleSideMenu())}>
            {isSideMenuCollapsed ? (
              <Icon
                svg={ArrowLineRight}
                size={30}
                color={COLORS.STRICT_WHITE}
              />
            ) : (
              <Icon svg={ArrowLineLeft} size={30} color={COLORS.STRICT_WHITE} />
            )}
          </RowCollapse>
        )}
        <ByText>By</ByText>
        <ByCodeFlexImg
          src={isSideMenuCollapsed ? codeflexLogo : codeflexLogoTextRight}
          alt=""
        />
      </ByCodeFlexWrapper>
    </Container>
  );
};

export default SideMenu;
