import { AnyAction } from '@reduxjs/toolkit';
import { LOGOUT } from 'store/User/constants';
import IAddress from 'types/Address.types';
import { ICompanySettings } from 'types/Company.types';
import {
  SET_COMPANY,
  SET_COMPANY_LOGO,
  SET_COMPANY_SETTINGS,
} from '../constants';

export interface CompanyReducerState {
  address: IAddress | null;
  id: string | null;
  name: string | null;
  vat_number: string | null;
  settings: ICompanySettings | null;
  companyLogo: string | null;
}

const INIT_STATE: CompanyReducerState = {
  address: null,
  id: null,
  name: null,
  vat_number: null,
  settings: null,
  companyLogo: null,
};

const companyReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...state,
        address: action.payload.company.address,
        id: action.payload.company.id,
        name: action.payload.company.name,
        vat_number: action.payload.company.vat_number,
      };
    case SET_COMPANY_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        companyLogo: action.payload.company_logo_url,
      };
    case LOGOUT: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};

export default companyReducer;
