import { PHONE_NUMBER_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export const createPurchaseOrderSupplierTabSchema = () => {
  return Yup.object().shape({
    country: Yup.string().required('Country is required'),
    phone: Yup.string()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
      .required('Phone is required'),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
    city: Yup.string()
      .min(1, 'City should be between 1 and 80 characters')
      .max(80, 'City should be between 1 and 80 characters')
      .required('City is required'),
    street: Yup.string()
      .min(1, 'Street should be between 1 and 80 characters')
      .max(80, 'Street should be between 1 and 80 characters')
      .required('Street is required'),
    house_number: Yup.string().required('House number is required'),
    code: Yup.string()
      .min(3, 'Zip code should be between 3 and 10 characters')
      .max(10, 'Zip code should be between 3 and 10 characters')
      .required('Zip code is required'),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .required('VAT number is required'),
    company_name: Yup.string().required('Company name is required'),
    contact_person: Yup.string().required('Contact person is required'),
  });
};
