import { useQuery } from 'react-query';
import {
  getQuotationReportsCurrent,
  getQuotationReportsHistorical,
} from 'services/Quotation/QuotationService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetQuotationReportsCurrent = (
  quotationId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_QUOTATION_REPORTS_CURRENT,
      quotationId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getQuotationReportsCurrent(quotationId, page, perPage);
    },
  });

export const useGetQuotationReportsHistorical = (
  quotationId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_QUOTATION_REPORTS_HISTORICAL,
      quotationId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getQuotationReportsHistorical(quotationId, page, perPage);
    },
  });
