import styled from 'styled-components';
import { RowGap, SectionTitle } from '../ReportForm.styled';

export const InvoicesWrapper = styled(RowGap)`
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const SingleInvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoiceLabel = styled(SectionTitle)``;
