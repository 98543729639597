import { borderRadiusSm, COLORS } from 'assets/styled';
import styled from 'styled-components';

export const SmallCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadiusSm};
  width: 100%;
  max-width: 100%;
  height: 100rem;
`;

export const SmallCardLabel = styled.label`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16rem;
  line-height: 16rem;
  text-align: center;
`;

export const SmallCardValue = styled.label`
  font-weight: 500;
  text-transform: uppercase;
  font-size: 24rem;
  line-height: 16rem;
  color: ${COLORS.PRIMARY};
  margin-top: 20rem;
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 15rem;
  height: 15rem;
  right: -20rem;
  top: 1rem;
`;

export const ValueWrapperRelative = styled.div`
  position: relative;
`;
