import { COLORS, gapMd, marginMd, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 30rem;
  top: 20rem;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginSm};
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${gapMd};
`;

export const InvoiceNumberLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
`;

export const InvoiceStatusLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  color: ${COLORS.PRIMARY};
`;

export const RightSide = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
`;

export const ExportLabel = styled.label`
  cursor: pointer;
  font-size: 18rem;
  font-weight: 300;
  text-decoration: underline;
  color: ${COLORS.PRIMARY};
`;

export const RowSpaceBetweenMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
`;

export const SmallColumnDiv = styled.div`
  position: relative;
  height: 60rem;
  width: 181rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SmallColumnDivLabel = styled.label`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16rem;
  text-align: center;
`;

export const SmallColumnDivLabelValue = styled.label`
  font-weight: 500;
  font-size: 22rem;
  line-height: 22rem;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;
