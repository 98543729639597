import { COLORS } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Joyride, {
  CallBackProps,
  Step,
  TooltipRenderProps,
} from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRunTour } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import {
  setUserIntroGuideCompleted,
  setUserIntroGuideCompletedPages,
} from 'store/User/actions/user';
import { GuidePages } from './constants';
import {
  useAddIntroGuideCompletedPage,
  useChangeIntroGuideCompletedStatus,
  useGetCompletedGuidePages,
  useGetIsIntroGuideCompleted,
} from './hooks';
import {
  Button,
  Span,
  TooltipBody,
  TooltipContent,
  TooltipFooter,
  TooltipHeader,
  TooltipTitle,
} from './IntroductionGuide.styled';

interface IIntroductionGuideProps {
  steps: Step[];
}

export const IntroductionGuide = ({ steps }: IIntroductionGuideProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const completedPages: string[] = useGetCompletedGuidePages();
  const isGuideCompleted = useGetIsIntroGuideCompleted();

  const run = useSelector(
    (state: IRootReducerState) => state.commonInfo.runTour
  );

  const { mutate: changeIntroGuideStatus } =
    useChangeIntroGuideCompletedStatus();
  const { mutate: addCompletedPage } = useAddIntroGuideCompletedPage();

  const turnOffIntroGuide = () => {
    changeIntroGuideStatus(true, {
      onSuccess: () => {
        dispatch(setUserIntroGuideCompleted(true));
      },
    });
  };

  const turnOffIntroGuideForPage = () => {
    dispatch(setRunTour(false));
    document.body.style.overflow = 'auto';
  };

  const handleCallback = (data: CallBackProps) => {
    const { step, type, status } = data;

    if (status === 'skipped') {
      turnOffIntroGuide();
      document.body.style.overflow = 'auto';
      return;
    }
    if (type === 'tour:end') {
      if (!completedPages?.includes(step?.data?.page)) {
        const updatedCompletedTours = [...completedPages, step.data.page];
        dispatch(setUserIntroGuideCompletedPages(updatedCompletedTours));
        addCompletedPage(step?.data?.page);

        turnOffIntroGuideForPage();

        if (completedPages.length == Object.keys(GuidePages).length - 1) {
          turnOffIntroGuide();
        }
      }
    }
    if (
      type === 'step:after' &&
      step?.data?.next &&
      !completedPages?.includes(step?.data?.nextPage)
    ) {
      navigate(step?.data?.next.replace(':id', step?.data?.id));
    }
  };

  const Tooltip = ({
    index,
    isLastStep,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps) => (
    <TooltipBody {...tooltipProps}>
      <TooltipHeader>
        <Button {...skipProps} $skip>
          <span id="skip">{t('Skip Guide')}</span>
        </Button>
        {!isLastStep && <Close onClick={() => turnOffIntroGuideForPage()} />}
      </TooltipHeader>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      {step.content && <TooltipContent>{step.content}</TooltipContent>}
      <TooltipFooter>
        {index > 0 ? (
          <Button {...backProps} $back>
            <span id="back">{t('Back')}</span>
          </Button>
        ) : (
          <div></div>
        )}
        <Span>
          {index + 1}/{steps.length}
        </Span>
        <Button {...primaryProps} $next>
          <span id="next">{isLastStep ? t('Close') : t('Next')}</span>
        </Button>
      </TooltipFooter>
    </TooltipBody>
  );

  return (
    <Joyride
      steps={steps}
      continuous
      showProgress
      disableScrolling
      disableScrollParentFix
      disableOverlayClose
      showSkipButton
      hideCloseButton
      run={run && !isGuideCompleted}
      callback={handleCallback}
      tooltipComponent={Tooltip}
      styles={{
        options: {
          arrowColor: `${COLORS.WHITE}`,
        },
      }}
    />
  );
};
