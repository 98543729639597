import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { savePrompt } from 'store/PWA/actions/pwa';
import { BeforeInstallPromptEvent } from 'store/PWA/reducers/pwa';

interface IPWAIconInstallProviderProps {
  children: React.ReactNode;
}

const PWAIconInstallProvider = ({ children }: IPWAIconInstallProviderProps) => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setDeferredPrompt(e);

      // Optionally: Update state to show install button or other UI elements
    };

    window.addEventListener(
      'beforeinstallprompt',
      handleBeforeInstallPrompt as any
    );

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt as any
      );
    };
  }, []);

  useEffect(() => {
    if (deferredPrompt) {
      const handleInstall = () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              // console.log('User accepted the install prompt');
            } else {
              // console.log('User dismissed the install prompt');
            }
            setDeferredPrompt(null);
          });
        }
      };
      dispatch(savePrompt(handleInstall));
    }
  }, [deferredPrompt]);

  return <>{children}</>;
};

export default PWAIconInstallProvider;
