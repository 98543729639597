import {
  COLORS,
  H3,
  gapMd,
  marginSm,
  marginXs,
  paddingXs,
} from 'assets/styled';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  $isExpanded: boolean;
  lang: string;
  secure: boolean;
}

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 900rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;
  padding-bottom: 20rem;
`;

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 30rem;
  justify-content: flex-start;
  margin-top: 30rem;
  ${(props: StyledProps) =>
    props.$isExpanded &&
    css`
      margin-bottom: 30rem;
    `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20rem;
`;

export const TextColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props: StyledProps) =>
    props.$isExpanded
      ? css`
          max-width: 40%;
          min-width: 25%;
        `
      : css`
          max-width: 77%;
          min-width: 20%;
        `}
`;

export const LabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 170rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;
export const Label = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
`;

export const CostContainerAbsolute = styled.div`
  position: absolute;
  bottom: 20rem;
  right: 30rem;
  display: flex;
`;

export const CostLabel = styled.div`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  color: ${COLORS.PRIMARY};
  margin-left: ${marginXs};
`;

export const CostLabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 100rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const TypeLabel = styled.h4`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  color: ${COLORS.GREY_1000};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  justify-content: space-between;
  align-items: flex-start;
`;

export const WrapperRelative = styled.div`
  display: flex;
  position: relative;
`;

export const EditIcon = styled.div`
  padding-left: 5rem;
`;

export const EditLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineOut}
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  align-items: flex-start;
`;

export const DescriptionContainer = styled.div`
  max-width: 77%;
`;

export const GreenLine = styled.div`
  margin-top: ${marginSm};
  border-bottom: 1rem solid ${COLORS.LOGYX_LIGHT_GREEN};
  display: flex;
  width: 100%;
`;

export const AttributesWrapper = styled.div`
  position: relative;
`;

export const LogyxAttributesLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  padding-top: ${paddingXs};
  color: ${COLORS.BLACK};
`;

export const TitleLabel = styled(H3)`
  margin-top: -5rem;
`;

export const DeleteLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.RED_TRASH};
  cursor: pointer;
  ${hoverLineOut}
`;

export const DeleteIcon = styled.div`
  padding-left: 5rem;
`;

export const ActionsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${gapMd};
`;

export const EditDeleteWrapperRelative = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;

export const EyeWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  width: 20rem;
`;

export const EyeLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 70rem;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
`;
