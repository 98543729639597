import { IReorderLineDTO } from '../DraggableLines/hooks';

export const swapDragAndHoverRowIndexes = (
  localRows: any[],
  dragIndex: number,
  hoverIndex: number
) => {
  const rowsCopy = localRows.map((row) => {
    return { ...row };
  });
  const temp = rowsCopy[dragIndex].index;
  rowsCopy[dragIndex].index = rowsCopy[hoverIndex].index;
  rowsCopy[hoverIndex].index = temp;

  return rowsCopy;
};

export const sortRowsByIndex = (rows: any[]) => {
  return rows.sort((a: any, b: any) => a.index - b.index);
};

export const isOrderDifferentFromInitial = (
  initialRows: any[], // has index and dndIndex property
  reorderLinesDTOs: IReorderLineDTO[]
): boolean => {
  if (initialRows.length !== reorderLinesDTOs.length) {
    return true; // Length difference implies a change in order or content
  }

  // Create a map of initial line indexes by line id for quick lookup
  const initialIndexMap = new Map<number, number>();
  initialRows.forEach((row) =>
    initialIndexMap.set(Number(row.dndId), row.index)
  );

  // Check each line in the reordered array to see if its index has changed
  for (const line of reorderLinesDTOs) {
    const initialIndex = initialIndexMap.get(Number(line.lineId));

    if (initialIndex === undefined || initialIndex !== Number(line.lineIndex)) {
      return true; // Found a line whose index has changed
    }
  }

  return false; // No changes in the order
};
