import { SortDirection } from 'components/Table/constants';
import { ICreateSupplierDTO } from 'pages/Manager/NewSupplierPage/types';
import {
  IComment,
  IEditCustomerOrSupplierDTO,
} from 'pages/Manager/SingleCustomerPage/types';
import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/suppliers/';

export const getSuppliers = async (
  page: number,
  perPage: number,
  searchBy: string,
  isActive?: boolean | null,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    is_active: isActive?.toString(),
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSingleSupplier = async (supplierId: string): Promise<any> => {
  const url = `${BASE_URL}${supplierId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getSupplierInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const createSupplier = async (
  createSupplierData: ICreateSupplierDTO
): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, createSupplierData);

  return data.data;
};

export const getSupplierQuotations = async (
  supplierId: string
): Promise<any> => {
  const url = `${BASE_URL}${supplierId}/quotations`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getSupplierPurchaseOrders = async (
  page: number,
  perPage: number,
  supplierId: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${supplierId}/purchase-orders`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const editSupplier = async (
  supplierId: string,
  editSupplierData: IEditCustomerOrSupplierDTO
): Promise<any> => {
  const url = `${BASE_URL}${supplierId}`;
  const { data } = await apiClient.patch<any>(url, editSupplierData);

  return data.data;
};

export const getSupplierStockItems = async (
  supplierId: string
): Promise<any> => {
  const url = `${BASE_URL}${supplierId}/stock-items`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const deleteSupplier = async (supplierId: string): Promise<any> => {
  const url = `${BASE_URL}${supplierId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const activateSupplier = async (supplierId: string): Promise<any> => {
  const url = `${BASE_URL}${supplierId}`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const getSupplierComments = async (
  supplierId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${supplierId}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const addCommentForSupplier = async (
  id: string,
  commentData: IComment
): Promise<any> => {
  const url = `${BASE_URL}${id}/comments`;
  const { data } = await apiClient.post<any>(url, commentData);

  return data.data;
};

export const deleteCommentForSupplier = async (
  supplierId: string,
  commentId: string
): Promise<any> => {
  const url = `${BASE_URL}${supplierId}/comments/${commentId}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};

export const getSupplierAppointments = async (
  supplierId: string,
  page?: number,
  perPage?: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const url = `${BASE_URL}${supplierId}/appointments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getSupplierInfoById = async (supplierId: string): Promise<any> => {
  const url = `${BASE_URL}${supplierId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};
