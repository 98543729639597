import { SessionExpiredModal } from 'components/Modal/SessionExpiredModal/SessionExpiredModal';
import { queryClient } from 'index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import LocalStorageService from 'services/LocalStorageService';
import { setIsSessionExpired } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import { LOGOUT } from 'store/User/constants';
import {
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';

interface ISessionExpiredModalProviderProps {
  children: React.ReactNode;
}

const SessionExpiredModalProvider = ({
  children,
}: ISessionExpiredModalProviderProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: IRootReducerState) => state.userInfo.id
  );
  const isSessionExpired = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSessionExpired
  );
  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  const handleOnSubmit = async () => {
    dispatch(setIsSessionExpired(false));
    await LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    await LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    dispatch({ type: LOGOUT });
    queryClient.invalidateQueries();
    isMobileApp &&
      postMobileMessage({
        type: MobileMessageTypes.LOGOUT,
      });
  };

  return (
    <>
      <SessionExpiredModal
        onSubmit={handleOnSubmit}
        isOpen={isSessionExpired && isAuthenticated}
      />
      {children}
    </>
  );
};

export default SessionExpiredModalProvider;
