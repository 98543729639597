import Compressor from 'compressorjs';
import { toast } from 'utils/toast';

export const compressImage = async (file: any) => {
  return new Promise((resolve, reject) => {
    if (file.type.startsWith('image/')) {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 1920,
        success: (compressedResult) => {
          resolve(
            new File([compressedResult], file.name, {
              type: compressedResult.type,
              lastModified: Date.now(),
            })
          );
        },
        error: reject,
      });
    } else {
      resolve(file);
    }
  });
};

export const fileRejectHandler = (files: any, t: any) => {
  files.forEach((file: any) => {
    switch (file.errors[0].code) {
      case 'file-invalid-type':
        return toast.error(t('File type is not valid'));
      case 'file-too-large':
        return toast.error(t('File is larger then 32MB'));
      default:
        return toast.error(t('Something went wrong with File'));
    }
  });
};
