import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm } from 'assets/styled';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginSm};
`;

export const Description = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 20rem;
  color: ${COLORS.BLACK};
`;
