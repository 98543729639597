import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';

interface StyledProps {
  $flexValue: string;
  $isBlueColor: boolean;
  $selected: boolean;
  $isRed: boolean;
  isPaginationPresent: boolean;
}

export const Table = styled.div``;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  padding: 10rem 20rem;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.GREY_500};
  border-radius: ${borderRadiusSm};
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 5rem;
  ${(props: StyledProps) =>
    props.$flexValue &&
    css`
      flex: ${props.$flexValue};
    `};
`;

export const HeaderLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_500};
  padding: 15rem;

  &:last-of-type {
    border: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};
`;

export const BodyCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  padding: 0 5rem;
`;

export const BodyLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;

  ${(props: StyledProps) =>
    props.$isBlueColor &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;

export const ActionHeaderCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
  color: ${COLORS.PRIMARY};
`;

export const ActionCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  padding: 20rem;
`;
