import { Route, Routes } from 'react-router-dom';
import SingleQuotationGeneralPage from 'pages/Manager/SingleQuotation/SingleQuotationGeneralPage/SingleQuotationGeneralPage';
import SingleQuotationProductsPage from 'pages/Manager/SingleQuotation/SingleQuotationProductsPage/SingleQuotationProductsPage';
import SingleQuotationCommentsPage from 'pages/Manager/SingleQuotation/SingleQuotationCommentsPage/SingleQuotationCommentsPage';
import SingleQuotationAppointmentsPage from 'pages/Manager/SingleQuotation/SingleQuotationAppointmentsPage/SingleQuotationAppointmentsPage';
import SingleQuotationSalesOrdersPage from 'pages/Manager/SingleQuotation/SingleQuotationSalesOrdersPage/SingleQuotationSalesOrdersPage';
import SingleQuotationFilesPage from 'pages/Manager/SingleQuotation/SingleQuotationFilesPage/SingleQuotationFilesPage';
import SingleQuotationNewComment from 'pages/Manager/SingleQuotation/SingleQuotationNewComment/SingleQuotationNewComment';
import SingleQuotationNewSignature from 'pages/Manager/SingleQuotation/NewSignature/SingleQuotationNewSignature';
import SingleQuotationQuotationsPage from 'pages/Manager/SingleQuotation/SingleQuotationQuotationsPage/SingleQuotationQuotationsPage';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import SingleQuotationReportsPage from 'pages/Manager/SingleQuotation/SingleQuotationReportsPage/SingleQuotationReportsPage';

const SingleQuotationRouter = () => {
  return (
    <Routes>
      <Route
        path={SingleQuotationRoutes.SingleQuotationGeneral.path}
        element={<SingleQuotationGeneralPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationProducts.path}
        element={<SingleQuotationProductsPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationComments.path}
        element={<SingleQuotationCommentsPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationSales.path}
        element={<SingleQuotationSalesOrdersPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationQuotations.path}
        element={<SingleQuotationQuotationsPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationAppointments.path}
        element={<SingleQuotationAppointmentsPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationReports.path}
        element={<SingleQuotationReportsPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationFiles.path}
        element={<SingleQuotationFilesPage />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationNewComment.path}
        element={<SingleQuotationNewComment />}
      />
      <Route
        path={SingleQuotationRoutes.SingleQuotationSignature.path}
        element={<SingleQuotationNewSignature />}
      />
    </Routes>
  );
};

export default SingleQuotationRouter;
