import { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getQuotationHistory } from 'services/Quotation/QuotationService';
import { IQuotation } from 'types/Quotations.types';
import { useGetSingleQuotationSalesOrder } from '../hooks';
import { injectDataIntoHistoryItems } from './helpers';
import { HistoryEventEntityTypeEnum } from 'components/CustomSwiper/CustomSwiper';
import { useGetSingleSalesOrderHistory } from 'pages/Manager/SingleSalesOrder/hooks';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';

export const useShowCreateSalesOrderAfterSignatureModal = () => {
  const { state } = useLocation();
  const canCreateSalesOrder = useCan(Actions.CREATE_SALES_ORDER);
  const [
    isCreateSalesOrderAfterSignatureModalShown,
    setIsCreateSalesOrderAfterSignatureModalShown,
  ] = useState<boolean>(
    state?.signatureAdded && canCreateSalesOrder ? true : false
  );

  return [
    isCreateSalesOrderAfterSignatureModalShown,
    setIsCreateSalesOrderAfterSignatureModalShown,
  ];
};

export const useGetSingleQuotationHistory = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION_HISTORY, quotationId],
    queryFn: () => {
      return getQuotationHistory(quotationId);
    },
    enabled: !!quotationId,
  });

export const useGetSingleQuotationParentQuotationHistory = (
  parentQuotationId: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_QUOTATION_PARENT_QUOTATION_HISTORY,
      parentQuotationId,
    ],
    queryFn: () => {
      return getQuotationHistory(parentQuotationId);
    },
    enabled: !!parentQuotationId,
  });

export const useGetHistoryItems = (quotation: IQuotation) => {
  const {
    data: quotationHistoryData,
    isFetched: isQuotationHistoryDataFetched,
  } = useGetSingleQuotationHistory(quotation?.id);

  const { data: salesOrder } = useGetSingleQuotationSalesOrder(quotation?.id);

  const {
    data: salesOrderHistoryData,
    isFetched: isSalesOrderHistoryDataFetched,
  } = useGetSingleSalesOrderHistory(salesOrder?.id);

  const {
    data: parentQuotationHistoryData,
    isFetched: isParentQuotationHistoryDataFetched,
  } = useGetSingleQuotationParentQuotationHistory(
    quotation?.parent_quotation?.id
  );

  const concatenateHistoryItems = () => {
    let historyItems: any = [];
    if (parentQuotationHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          quotation?.parent_quotation?.number,
          parentQuotationHistoryData?.history,
          HistoryEventEntityTypeEnum.QUOTATION
        )
      );
    }
    if (salesOrderHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          salesOrder?.number,
          salesOrderHistoryData?.history,
          HistoryEventEntityTypeEnum.SALES_ORDER
        )
      );
    }
    if (quotationHistoryData?.history) {
      historyItems = historyItems.concat(
        injectDataIntoHistoryItems(
          quotation?.number,
          quotationHistoryData?.history,
          HistoryEventEntityTypeEnum.QUOTATION
        )
      );
    }
    return historyItems;
  };
  let isFetchedHistoryItems = isQuotationHistoryDataFetched;

  if (quotation?.parent_quotation?.id) {
    isFetchedHistoryItems =
      isFetchedHistoryItems && isParentQuotationHistoryDataFetched;
  }

  if (salesOrder?.id) {
    isFetchedHistoryItems =
      isFetchedHistoryItems && isSalesOrderHistoryDataFetched;
  }
  return { historyItems: concatenateHistoryItems(), isFetchedHistoryItems };
};
