import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import { PurchaseOrderStatusType } from 'pages/Manager/SinglePurchaseOrder/constants';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  exportPurchaseOrderExcel,
  getPurchaseOrders,
} from 'services/PurchaseOrder/PurchaseOrderService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IPurchaseOrdersPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { useGetPurchaseOrderStatuses } from 'utils/hooks/useGetPurchaseOrderStatuses';
import { useGetDateIntervalOptions } from 'utils/hooks/useGetDateIntervalOptions';

export const useGetPurchaseOrders = (
  page: number,
  perPage: number,
  isEnabled = true,
  searchBy?: string,
  purchaseOrderStatuses?: string,
  supplierId?: number,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_PURCHASE_ORDERS,
      page,
      perPage,
      searchBy,
      purchaseOrderStatuses,
      supplierId,
      dateInterval,
      sortBy,
      sortDirection,
      advancedFilters,
    ],
    queryFn: () => {
      return getPurchaseOrders(
        page,
        perPage,
        searchBy,
        purchaseOrderStatuses,
        supplierId,
        dateInterval,
        sortBy,
        sortDirection,
        advancedFilters
      );
    },
    enabled: isEnabled,
  });

export const useGetPurchaseOrdersPageFilters = () => {
  const dispatch = useDispatch();
  const purchaseOrdersPageFilters: IPurchaseOrdersPageFilters | null =
    useSelector(
      (state: IRootReducerState) => state.filtersInfo.purchaseOrdersPage
    );
  const statusOptions = useGetPurchaseOrderStatuses(
    PurchaseOrderStatusType.STATUS
  );
  const dateIntervalOptions = useGetDateIntervalOptions();

  if (!purchaseOrdersPageFilters) {
    const initialPurchaseOrdersPageFilters: IPurchaseOrdersPageFilters = {
      page: 1,
      searchBy: '',
      selectedStatusOptions: [],
      selectedDateIntervalOption:
        dateIntervalOptions[dateIntervalOptions.length - 1],
      sortBy: undefined,
      sortDirection: undefined,
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.PURCHASE_ORDERS,
        data: initialPurchaseOrdersPageFilters,
      })
    );
    return {
      purchaseOrdersPageFilters: initialPurchaseOrdersPageFilters,
      statusOptions,
      dateIntervalOptions,
    };
  }

  return {
    purchaseOrdersPageFilters,
    statusOptions,
    dateIntervalOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IPurchaseOrdersPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<Option[]>(
    initialFilters.selectedStatusOptions
  );
  const [selectedDateIntervalOption, setSelectedDateIntervalOption] =
    useState<Option>(initialFilters.selectedDateIntervalOption);
  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
  ]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IPurchaseOrdersPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      selectedStatusOptions,
      selectedDateIntervalOption,
      sortBy,
      sortDirection,
    };
  }, [
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.PURCHASE_ORDERS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  };
};

export const useExportPurchaseOrderExcel = () => {
  const { t } = useTranslation();
  return useMutation(() => exportPurchaseOrderExcel(t('Purchase orders')), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EXPORT_PURCHASE_ORDER_EXCEL,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.EXPORT_PURCHASE_ORDER_EXCEL,
  });
};
