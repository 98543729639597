import { COLORS, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 750rem;
`;
export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Description = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20rem;
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 210rem;
  height: 41rem;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;
