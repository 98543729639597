import {
  LegendEl,
  LegendBox,
  LegendText,
  LegendWrapper,
} from './LegendList.styled';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';

const LegendList = () => {
  const { t } = useTranslation();

  return (
    <LegendWrapper>
      <LegendEl>
        <LegendBox legendColor={COLORS.GREEN} />
        <LegendText>{t('Measurement')}</LegendText>
      </LegendEl>
      <LegendEl>
        <LegendBox legendColor={COLORS.PRIMARY} />
        <LegendText>{t('Installation')}</LegendText>
      </LegendEl>
      <LegendEl>
        <LegendBox legendColor={COLORS.ORANGE} />
        <LegendText>{t('General')}</LegendText>
      </LegendEl>
      <LegendEl>
        <LegendBox legendColor={COLORS.PURPLE} />
        <LegendText>{t('Service')}</LegendText>
      </LegendEl>
    </LegendWrapper>
  );
};

export default LegendList;
