// Single Sales Order
export const SingleSalesOrderRoutes = {
  SingleSalesOrderGeneral: {
    path: '',
    fullPath: '/sales',
  },
  SingleSalesOrderProducts: {
    path: 'products',
    fullPath: '/sales/:id/products',
  },
  SingleSalesOrderComments: {
    path: 'comments',
    fullPath: '/sales/:id/comments',
  },
  SingleSalesOrderQuotations: {
    path: 'quotations',
    fullPath: '/sales/:id/quotations',
  },
  SingleSalesOrderInvoices: {
    path: 'invoices',
    fullPath: '/sales/:id/invoices',
  },
  SingleSalesOrderPurchaseOrders: {
    path: 'purchases',
    fullPath: '/sales/:id/purchases',
  },
  SingleSalesOrderAppointments: {
    path: 'appointments',
    fullPath: '/sales/:id/appointments',
  },
  SingleSalesOrderReports: {
    path: 'reports',
    fullPath: '/sales/:id/reports',
  },
  SingleSalesOrderFiles: {
    path: 'files',
    fullPath: '/sales/:id/files',
  },
  SingleSalesOrderNewComment: {
    path: 'comment',
    fullPath: '/sales/:id/comment',
  },
  SingleSalesOrderNewPurchaseOrder: {
    path: 'purchase-order',
    fullPath: '/sales/:id/purchase-order',
  },
  SingleSalesOrderNewInvoice: {
    path: 'invoice',
    fullPath: '/sales/:id/invoice',
  },
  SingleSalesOrderNewSignature: {
    path: 'signature',
    fullPath: '/sales/:id/signature',
  },
};
