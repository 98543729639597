export enum CreatePurchaseOrderTab {
  SUPPLIER_DETAILS = 'supplier',
  ITEMS = 'items',
  OVERVIEW = 'overview',
}

export interface ICreatePurchaseOrderProductLine {
  product_id: string;
  supplier_id: string;
  quantity: string;
}
