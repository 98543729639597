import { IHistoryEvent } from 'components/CustomSwiper/CustomSwiper';
import { TimelineEvents } from './constants';

export const getTimelineEventContent = (historyEvent: IHistoryEvent) => {
  switch (historyEvent.event.name) {
    case TimelineEvents.SALES_ORDER_CREATED:
      return {
        title: 'Sales order',
        message: 'Sales order created',
      };
    case TimelineEvents.MANUAL_CHANGE:
      return {
        title: 'Manual status change',
        message: '',
      };
    case TimelineEvents.DOWN_PAYMENT_INVOICE_CREATED:
      return {
        title: 'Down payment invoice',
        message: 'Down payment invoice created',
      };
    case TimelineEvents.DOWN_PAYMENT_INVOICE_PAID:
      return {
        title: 'Down payment invoice',
        message: 'Down payment invoice paid',
      };
    case TimelineEvents.PURCHASE_ORDER_PARTLY_CREATED:
      return {
        title: 'Purchase order',
        message: 'Products partly on purchase orders',
      };
    case TimelineEvents.PURCHASE_ORDER_CREATED:
      return {
        title: 'Purchase order',
        message: 'All products on purchase orders',
      };
    case TimelineEvents.PURCHASE_ORDER_RECEIVED:
      return {
        title: 'Purchase order',
        message: 'All products received',
      };
    case TimelineEvents.INSTALLATION_PARTLY_PLANNED:
      return {
        title: 'Installation',
        message: 'Installations partly planned',
      };
    case TimelineEvents.INSTALLATION_PLANNED:
      return {
        title: 'Installation',
        message: 'Installation planned',
      };
    case TimelineEvents.INSTALLATION_PARTLY_FINISHED:
      return {
        title: 'Installation',
        message: 'Partly installed',
      };
    case TimelineEvents.INSTALLATION_FINISHED:
      return {
        title: 'Installation',
        message: 'Installation finished',
      };
    case TimelineEvents.SALES_ORDER_FULLY_PAID:
      return {
        title: 'Sales order',
        message: 'Sales order fully paid',
      };
    case TimelineEvents.DOWN_PAYMENT_INVOICE_CANCELLED:
      return {
        title: 'Down payment invoice',
        message: 'Down payment invoice cancelled',
      };
    case TimelineEvents.SALES_ORDER_PARLY_PAID:
      return {
        title: 'Sales order',
        message: 'Sales order partly paid',
      };
    case TimelineEvents.SALES_INVOICE_PARTLY_PAID:
      return {
        title: 'Sales order invoice',
        message: 'Invoice partly paid',
      };
    case TimelineEvents.SALES_INVOICE_PAID:
      return {
        title: 'Sales order invoice',
        message: 'Invoice paid',
      };
    case TimelineEvents.DOWN_PAYMENT_INVOICE_PARTLY_PAID:
      return {
        title: 'Down payment invoice',
        message: 'Down payment invoice partly paid',
      };
    case TimelineEvents.INVOICE_CREATED:
      return {
        title: 'Invoice',
        message: 'Invoice created',
      };
    case TimelineEvents.PURCHASE_ORDER_PARTLY_RECEIVED:
      return {
        title: 'Purchase order',
        message: 'Products partly received',
      };
    case TimelineEvents.QUOTATION_CREATED:
      return {
        title: 'Quotation',
        message: 'Quotation created',
      };
    case TimelineEvents.MEASURING_SCHEDULED:
      return {
        title: 'Measuring',
        message: 'Measurement appointment created',
      };
    case TimelineEvents.MEASURING_FINISHED:
      return {
        title: 'Measuring',
        message: 'Measuring appointment finished',
      };
    case TimelineEvents.QUOTATION_COPIED_TO_NEW:
      return {
        title: 'Quotation',
        message: 'Quotation edited to new quotation',
      };
    case TimelineEvents.QUOTATION_ACCEPTED:
      return {
        title: 'Quotation',
        message: 'Quotation accepted',
      };
    case TimelineEvents.EMAIL_SENT:
      return {
        title: historyEvent.entityType,
        message: 'Email sent',
      };
    case TimelineEvents.SALES_INVOICE_EMAIL_SENT:
      return {
        title: 'Sales order invoice',
        message: 'Sales invoice Email sent',
      };
    case TimelineEvents.DOWN_PAYMENT_INVOICE_EMAIL_SENT:
      return {
        title: 'Down payment invoice',
        message: 'Down payment invoice Email sent',
      };
    case TimelineEvents.CREDIT_INVOICE_EMAIL_SENT:
      return {
        title: 'Credit invoice',
        message: 'Credit invoice Email sent',
      };
    case TimelineEvents.INVOICE_CANCELLED:
      return {
        title: 'Invoice',
        message: 'Invoice cancelled',
      };
    case TimelineEvents.MEASURING_APPOINTMENT_PLANNED:
      return {
        title: 'Measuring',
        message: 'Measuring appointment planned',
      };
    case TimelineEvents.SERVICE_APPOINTMENT_PLANNED:
      return {
        title: 'Service',
        message: 'Service appointment planned',
      };
    case TimelineEvents.GENERAL_APPOINTMENT_PLANNED:
      return {
        title: 'General',
        message: 'General appointment planned',
      };
    case TimelineEvents.MEASURING_APPOINTMENT_DELETED:
      return {
        title: 'Measuring',
        message: 'Measuring appointment deleted',
      };
    case TimelineEvents.SERVICE_APPOINTMENT_DELETED:
      return {
        title: 'Service',
        message: 'Service appointment deleted',
      };
    case TimelineEvents.GENERAL_APPOINTMENT_DELETED:
      return {
        title: 'General',
        message: 'General appointment deleted',
      };
    case TimelineEvents.INSTALLATION_APPOINTMENT_DELETED:
      return {
        title: 'Installation',
        message: 'Installation appointment deleted',
      };
    case TimelineEvents.MEASURING_APPOINTMENT_EDITED:
      return {
        title: 'Measuring',
        message: 'Measuring appointment edited',
      };
    case TimelineEvents.SERVICE_APPOINTMENT_EDITED:
      return {
        title: 'Service',
        message: 'Service appointment edited',
      };
    case TimelineEvents.GENERAL_APPOINTMENT_EDITED:
      return {
        title: 'General',
        message: 'General appointment edited',
      };
    case TimelineEvents.INSTALLATION_APPOINTMENT_EDITED:
      return {
        title: 'Installation',
        message: 'Installation appointment edited',
      };
    case TimelineEvents.QUOTATION_IN_TREATMENT:
      return {
        title: 'Quotation',
        message: 'Quotation in treatment',
      };
    case TimelineEvents.QUOTATION_FOLLOW_UP:
      return {
        title: 'Quotation',
        message: 'Quotation follow up',
      };
    case TimelineEvents.SIGNATURE_ADDED:
      return {
        title: 'Signature',
        message: 'Signature added',
      };
    default:
      break;
  }
};
