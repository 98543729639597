import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/feedback/';

export const submitFeedback = async (text: string): Promise<any> => {
  const url = `${BASE_URL}submit-feedback`;
  const { data } = await apiClient.post<any>(url, { text });

  return data.data;
};
