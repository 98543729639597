import { H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { Formik } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  Heading,
  RowSpaceBetween,
  SelectInputWrapper,
  StyledInput,
  StyledTextArea,
  TabContainer,
} from '../EmailTemplatesPage.styled';
import { insertTextAtCursorPosition } from '../helpers';
import { EmailTemplateDetails } from '../types';

interface ICustomTabProps {
  details: EmailTemplateDetails;
  onApply: (details: EmailTemplateDetails) => void;
  variableOptions: Option[];
}

export const CustomTab = ({
  details,
  onApply,
  variableOptions,
}: ICustomTabProps) => {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Formik
      enableReinitialize
      onSubmit={() => {
        //
      }}
      initialValues={{
        headline: details.headline,
        body: details.body.replaceAll('<br>', '\n'),
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <TabContainer>
            <RowSpaceBetween>
              <Heading>
                <H4>{t('Headline').toUpperCase()}</H4>
              </Heading>
              {variableOptions?.length !== 0 && (
                <SelectInputWrapper>
                  <Select
                    name="headline_variable"
                    placeholder={t('Insert headline variable')}
                    isMulti={false}
                    isDisabled={false}
                    isSearchable={false}
                    onChange={(e: Option) => {
                      insertTextAtCursorPosition(e.value, inputRef);
                      setFieldValue('headline', inputRef?.current?.value);
                    }}
                    options={variableOptions}
                  />
                </SelectInputWrapper>
              )}
            </RowSpaceBetween>
            <StyledInput
              ref={inputRef}
              value={values.headline}
              onChange={(e: any) => {
                const value = e.target.value;
                setFieldValue('headline', value);
              }}
            />
            <RowSpaceBetween>
              <Heading>
                <H4>{t('Body').toUpperCase()}</H4>
              </Heading>
              {variableOptions?.length !== 0 && (
                <SelectInputWrapper>
                  <Select
                    name="variable"
                    placeholder={t('Insert variable')}
                    isMulti={false}
                    isDisabled={false}
                    isSearchable={false}
                    onChange={(e: Option) => {
                      insertTextAtCursorPosition(e.value, textAreaRef);
                      setFieldValue('body', textAreaRef?.current?.value);
                    }}
                    options={variableOptions}
                  />
                </SelectInputWrapper>
              )}
            </RowSpaceBetween>

            <StyledTextArea
              ref={textAreaRef}
              value={values.body}
              onChange={(e: any) => {
                const value = e.target.value;
                setFieldValue('body', value);
              }}
              onKeyUp={(e: any) => {
                if (e.key == 13) {
                  insertTextAtCursorPosition(`\n`, textAreaRef);
                  setFieldValue('body', textAreaRef?.current?.value);
                }
              }}
              style={{ width: '100%', height: '200rem' }}
            />

            <ApplyWrapper>
              <Button
                disabled={!values.headline || !values.body}
                onClick={() => {
                  onApply({
                    headline: values.headline,
                    body: values.body.replaceAll('\n', '<br>'),
                  });
                }}
                primary
                width="200rem"
                label={t('Apply')}
              />
            </ApplyWrapper>
          </TabContainer>
        );
      }}
    </Formik>
  );
};
