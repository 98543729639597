import {
  borderRadiusSm,
  COLORS,
  gapXs,
  paddingLg,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSideMenuCollapsed: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${paddingLg};
  align-items: center;
  justify-content: center;
  width: calc(100vw - 265rem);

  ${respondTo.bigTablet`
    padding: ${paddingMd};
  `}

  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      width: calc(100vw - 115rem);
    `}
`;

export const NavigationStateMessageAndSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10rem;
  gap: ${paddingMd};
`;

export const GoogleSpanText = styled.span`
  display: inline-block;
  vertical-align: middle;
  padding-left: 15rem;
  padding-right: 10rem;
  font-size: 14rem;
`;
export const GoogleButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
  color: #444;
  width: 250rem;
  height: 43rem;
  border-radius: ${borderRadiusSm};
  border: 1rem solid ${COLORS.GREY_500};
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background: ${COLORS.GREY_200};
  }
`;

export const GoogleLabelDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RowAlignCenterWithGap = styled.div`
  display: flex;
  align-items: center;
  gap: ${gapXs};
  font-size: 20rem;
  color: ${COLORS.BLACK};
`;

export const TopCalendarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding-left: 10rem;
`;
