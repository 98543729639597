import { Close } from 'components/Close/Close';
import {
  TabsWrapper,
  CalendarTab,
  TabText,
  CloseWrapper,
  Actions,
} from './CalendarTabs.styled';
import { Size } from 'components/Close/type';
import Icon from 'components/Icon/Icon';
import { PencilSimple, Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { IPlanningTabFilter } from 'store/Filters/types';
import { EditTabNameModal } from 'components/Modal/EditTabNameModal/EditTabNameModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTabName } from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';

export interface ICalendarTabsProps {
  calendarTabs: IPlanningTabFilter[];
  activeCalendarTab?: string;
  onAddTab: () => void;
  onRemoveTab: (tabId: string) => void;
  onSelectTab: (tabId: string) => void;
}

interface IEditTab {
  id: string;
  name: string;
}

const CalendarTabs = ({
  calendarTabs,
  activeCalendarTab,
  onAddTab,
  onRemoveTab,
  onSelectTab,
}: ICalendarTabsProps) => {
  const dispatch = useDispatch();
  const [isEditTabNameModalOpen, setIsEditTabNameModalOpen] =
    useState<boolean>(false);
  const [tabForEdit, setTabForEdit] = useState<IEditTab | null>(null);

  const handleConfirm = (newName: string) => {
    if (tabForEdit) {
      dispatch(updateTabName(FiltersPageEnum.PLANNING, tabForEdit.id, newName));
      setIsEditTabNameModalOpen(false);
    }
  };

  return (
    <TabsWrapper className="planning-step-2">
      {calendarTabs?.map((tab: IPlanningTabFilter) => (
        <CalendarTab
          key={tab.id}
          isActive={tab.id === activeCalendarTab}
          onClick={() => onSelectTab(tab.id)}
        >
          <Actions>
            <Icon
              svg={PencilSimple}
              size={15}
              color={COLORS.STRICT_WHITE}
              onClick={() => {
                setIsEditTabNameModalOpen(true);
                setTabForEdit({ id: tab.id, name: tab.name });
              }}
            />
          </Actions>
          <TabText>{tab.name}</TabText>
          {calendarTabs.length > 1 && (
            <CloseWrapper onClick={(e: MouseEvent) => e.stopPropagation()}>
              <Close
                size={Size.EXTRA_SMALL}
                onClick={() => onRemoveTab(tab.id)}
              />
            </CloseWrapper>
          )}
        </CalendarTab>
      ))}
      <Icon
        svg={Plus}
        size={15}
        weight="regular"
        color={COLORS.PRIMARY}
        onClick={onAddTab}
      />
      <EditTabNameModal
        key={activeCalendarTab}
        isOpen={isEditTabNameModalOpen}
        setIsOpen={setIsEditTabNameModalOpen}
        onCancel={() => setIsEditTabNameModalOpen(false)}
        onConfirm={handleConfirm}
        oldName={tabForEdit?.name}
      />
    </TabsWrapper>
  );
};

export default CalendarTabs;
