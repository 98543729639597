import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import { EditingCustomerOrSupplierEnum } from 'components/Modal/EditCustomerOrSupplierModal/constants';
import { EditCustomerOrSupplierModal } from 'components/Modal/EditCustomerOrSupplierModal/EditCustomerOrSupplierModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { formatNumber } from 'utils/numberFormatter';
import { useGetSingleSupplier } from '../hooks';
import { CardContainer, Container } from './SingleSupplierGeneralPage.styled';

export const SingleSupplierGeneralPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const { data: supplierData, isLoading } = useGetSingleSupplier(id || '');

  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] =
    useState<boolean>(false);

  const supplierDetailsLeftData = {
    Number: supplierData?.supplier?.number,
    'Contact person': supplierData?.supplier?.contact_person,
    'Company name': supplierData?.supplier?.company_name,
    'Company VAT': supplierData?.supplier?.vat_number,
    'Phone number': supplierData?.supplier?.phone,
    'E-mail': supplierData?.supplier?.email,
    'COC number':
      supplierData?.supplier?.coc_number && supplierData?.supplier?.coc_number,
  };

  const supplierDetailsRightData = {
    'Amount sold': `${currencySymbol}${formatNumber(
      supplierData?.amount_sold ? supplierData?.amount_sold : 0
    )}`,
    'Total orders': supplierData?.total_orders,
    'Due payment': `${currencySymbol}${formatNumber(
      supplierData?.due_payment
    )}`,
    'Open orders': supplierData?.open_orders,
  };
  return (
    <Container>
      <CardContainer>
        <SimpleWideCard
          cardTitle={t('Supplier details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('SALES')}
          leftData={supplierDetailsLeftData}
          rightData={supplierDetailsRightData}
          onEditClick={() => setIsEditCustomerModalOpen(true)}
          isLoading={isLoading}
        />
      </CardContainer>
      <EditCustomerOrSupplierModal
        type={EditingCustomerOrSupplierEnum.SUPPLIER}
        isOpen={isEditCustomerModalOpen}
        setIsOpen={setIsEditCustomerModalOpen}
        onCancel={() => setIsEditCustomerModalOpen(false)}
      />
    </Container>
  );
};
