import { COLORS } from 'assets/styled';
import moment from 'moment';
import {
  AppointmentType,
  AppointmentTypeIds,
  IAppointment,
} from 'types/Appointment.types';
import { IUser } from 'types/User.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { IWorkerAndResource } from './WorkersModal/type';

export const formatAppointmentEventData = (appointments: IAppointment[]) => {
  return appointments?.map((appointment) => {
    const isInPast = moment(appointment.date_from) < moment();
    const type = appointment.type.name;

    const getBackgroundColor = () => {
      if (isInPast) {
        return COLORS.AP_WHITE;
      } else if (appointment.confirmed) {
        switch (type) {
          case AppointmentType.MEASURING:
            return COLORS.GREEN;
          case AppointmentType.SERVICE:
            return COLORS.PURPLE;
          case AppointmentType.INSTALLATION:
            return COLORS.PRIMARY;
          case AppointmentType.GENERAL:
            return COLORS.ORANGE;
        }
      } else {
        switch (type) {
          case AppointmentType.MEASURING:
            return COLORS.LIGHT_GREEN;
          case AppointmentType.SERVICE:
            return COLORS.LIGHT_PURPLE;
          case AppointmentType.INSTALLATION:
            return COLORS.LIGHT_PRIMARY;
          case AppointmentType.GENERAL:
            return COLORS.LIGHT_ORANGE;
        }
      }
    };

    const getTextColor = () => {
      if (isInPast) {
        return COLORS.STRICT_BLACK;
      } else if (appointment.confirmed) {
        return COLORS.STRICT_WHITE;
      } else {
        return COLORS.SHIP_GREY;
      }
    };

    const getTitle = () => {
      if (appointment.type.id.toString() === AppointmentTypeIds.GENERAL) {
        return appointment.purpose
          ? capitalizeFirstLetter(appointment.purpose)
          : 'General';
      } else {
        return capitalizeFirstLetter(appointment.type.name.toLowerCase());
      }
    };

    return {
      id: appointment.id.toString(),
      title: getTitle(),
      address: appointment.address,
      shippingData: getAppointmentShippingData(appointment),
      status: appointment.status,
      start: appointment.date_from,
      end: appointment.date_to,
      backgroundColor: getBackgroundColor(),
      textColor: getTextColor(),
      borderColor: isInPast
        ? COLORS.BLACK
        : appointment.is_failed
        ? COLORS.RED_200
        : 'transparent',
      hasReport: appointment.has_report,
      processedInOffice: appointment.processed_in_office,
      isFailed: appointment.is_failed,
    };
  });
};

export const getAppointmentShippingData = (appointment: IAppointment) => {
  if (appointment) {
    switch (appointment.type.name) {
      case AppointmentType.MEASURING: {
        if (appointment?.quotation?.different_shipping_address)
          return appointment.quotation.shipping_data;
        else if (appointment?.sales_order?.customer_snapshot)
          //always true (requires backend implementation)
          return appointment?.sales_order?.customer_snapshot?.shipping_data;
        else return null;
      }
      case AppointmentType.INSTALLATION: {
        return appointment?.sales_order?.customer_snapshot?.shipping_data
          ? appointment.sales_order.customer_snapshot.shipping_data
          : null;
      }
      case AppointmentType.SERVICE: {
        if (appointment?.quotation) {
          return appointment?.quotation?.different_shipping_address
            ? appointment.quotation.shipping_data
            : null;
        } else if (appointment?.sales_order) {
          return appointment?.sales_order?.customer_snapshot?.shipping_data
            ? appointment.sales_order.customer_snapshot.shipping_data
            : null;
        } else {
          return null;
        }
      }
      case AppointmentType.GENERAL: {
        if (appointment?.quotation) {
          return appointment?.quotation?.different_shipping_address
            ? appointment.quotation.shipping_data
            : null;
        } else if (appointment?.sales_order) {
          return appointment?.sales_order?.customer_snapshot?.shipping_data
            ? appointment.sales_order.customer_snapshot.shipping_data
            : null;
        } else {
          return null;
        }
      }
    }
  }
};

export const getOptionsFromData = (data: any, type: string) => {
  if (!data) return;
  if (type === 'Users') {
    return data?.users?.map((user: IUser) => ({
      value: user?.id,
      label: user?.name + '' + user?.last_name,
      id: user?.id,
    }));
  }

  if (type === 'Workers') {
    return data?.workers?.map((worker: IWorkerAndResource) => ({
      value: worker?.id,
      label: worker?.name,
      id: worker?.id,
    }));
  }

  if (type === 'Resources') {
    return data?.resources?.map((resource: IWorkerAndResource) => ({
      value: resource?.id,
      label: resource?.name,
      id: resource?.id,
    }));
  }
};

export const generateUniqueId = () => Math.random().toString(36).substr(2, 10);
