import styled from 'styled-components';
import { COLORS, borderRadiusSm, marginSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginSm};
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  margin-top: 14rem; //30-16
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 50%;
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;
