// Single Purchase Credit Invoice
export const SinglePurchaseCreditInvoiceRoutes = {
  SinglePurchaseCreditInvoiceGeneral: {
    path: '',
    fullPath: '/purchase-invoices',
  },
  SinglePurchaseCreditInvoicePurchaseInvoice: {
    path: 'invoice',
    fullPath: '/purchase-invoices/:id/invoice',
  },
  SinglePurchaseCreditInvoicePayments: {
    path: 'payments',
    fullPath: '/purchase-invoices/:id/payments',
  },
  SinglePurchaseCreditInvoiceFiles: {
    path: 'files',
    fullPath: '/purchase-invoices/:id/files',
  },
};
