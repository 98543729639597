import { useQuery } from 'react-query';
import {
  getCustomerReportsCurrent,
  getCustomerReportsHistorical,
} from 'services/Customer/CustomerService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetCustomerReportsCurrent = (
  customerId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_CUSTOMER_REPORTS_CURRENT,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerReportsCurrent(customerId, page, perPage);
    },
  });

export const useGetCustomerReportsHistorical = (
  customerId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_CUSTOMER_REPORTS_HISTORICAL,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerReportsHistorical(customerId, page, perPage);
    },
  });
