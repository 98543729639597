import { Option } from 'components/Select/type';
import { AppointmentType, IAppointment } from 'types/Appointment.types';
import { mapAppointmentTypeValueToId } from './helpers';

export const useGetTypeOptions = () => {
  const typeOptions = Object.values(AppointmentType).map((type: string) => {
    return { value: mapAppointmentTypeValueToId(type), label: type };
  });
  return typeOptions;
};

export const useGetAppointmentOptions = (appointments: IAppointment[]) => {
  return appointments.map((appointment) => {
    const appointmentOption: Option = {
      value: appointment.id.toString(),
      label: appointment.id + ' ' + appointment.type.name,
      type: appointment.type.name,
    };
    return appointmentOption;
  });
};
