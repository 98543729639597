import {
  IHistoryEvent,
  HistoryEventEntityTypeEnum,
} from 'components/CustomSwiper/CustomSwiper';

export const injectDataIntoHistoryItems = (
  entityNumber: string,
  historyItems: IHistoryEvent[],
  entityType: HistoryEventEntityTypeEnum
) => {
  return historyItems.map((historyItem: IHistoryEvent) => {
    return {
      ...historyItem,
      entityType,
      entityNumber,
    };
  });
};
