import {
  borderRadiusSm,
  COLORS,
  gapSm,
  marginLg,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  $editable?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  margin-top: ${marginLg};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  color: ${COLORS.PRIMARY};
`;

export const InvoiceDescriptionLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: ${marginXs};
`;

export const RowSpaceBetweenMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
`;

export const SmallColumnDiv = styled.div`
  position: relative;
  height: 60rem;
  width: 181rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SmallColumnDivLabel = styled.label`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16rem;
  text-align: center;
`;

export const SmallColumnDivLabelValue = styled.label`
  font-weight: 500;
  font-size: 22rem;
  line-height: 22rem;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;

export const ClickableLabel = styled.span`
  cursor: pointer;
  &:hover {
    color: ${COLORS.PRIMARY};
    text-decoration: underline;
  }
`;

export const PenImg = styled.div`
  display: flex;
  width: 15rem;
  height: 15rem;
  margin-bottom: 15rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const ReferenceSpan = styled.span`
  cursor: pointer;
  color: ${COLORS.PRIMARY};
  font-weight: 300;
  font-size: 18rem;
  margin-right: 3rem;
  ${hoverLineOut}

  ${(props: StyledProps) =>
    props.$editable &&
    css`
      cursor: not-allowed;
    `};
`;

export const ReferenceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RightDiv = styled.div`
  display: flex;
  gap: ${gapSm};
`;

export const CreatedAtValidToDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CreatedAtValidToLabel = styled.label`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16rem;
  text-align: center;
`;

export const CreatedAtValidToLabelValue = styled.label`
  margin-left: ${marginMd};
  font-weight: 500;
  font-size: 22rem;
  line-height: 16rem;
  color: ${COLORS.PRIMARY};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
