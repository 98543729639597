import {
  SET_COMPANY,
  SET_COMPANY_LOGO,
  SET_COMPANY_SETTINGS,
} from '../constants';
import { IGetCompanyResponseData } from '../types';

export const setCompany = (companyData: IGetCompanyResponseData) => ({
  type: SET_COMPANY,
  payload: companyData,
});

export const setCompanySettings = (companySettingsData: object) => ({
  type: SET_COMPANY_SETTINGS,
  payload: companySettingsData,
});

export const setCompanyLogo = (companyLogo: object) => ({
  type: SET_COMPANY_LOGO,
  payload: companyLogo,
});
