import { t } from 'i18next';
import {
  Cards,
  Container,
  TopContainer,
  ColumnContainer,
  BottomContainer,
} from './AnalyticsPage.styled';
import { FilterCard } from './FilterCard/FilterCard';
import { QuotationsCard } from './QuotationsCard/QuotationsCard';
import { IncomingDeliveriesCard } from './IncomingDeliveriesCard/IncomingDeliveriesCard';
import { InvoicesCard } from './InvoicesCard/InvoicesCard';
import { Wallet, Cube, Cardholder } from '@phosphor-icons/react';
import {
  useGetAnalyticsPageFilters,
  useGetOrderPortfolioAnalytic,
  useGetSalesOrderAnalytic,
  useGetStockItemsAnalytic,
  useManageAndSaveFilters,
} from './hooks';

const AnalyticsPage = () => {
  const { analyticsPageFilters, dateOptions, subStatusOptions } =
    useGetAnalyticsPageFilters();

  const {
    selectedSalesOrders,
    setSelectedSalesOrders,
    selectedOrderPortfolio,
    setSelectedOrderPortfolio,
    selectedQuotations,
    setSelectedQuotations,
    selectedInvoices,
    setSelectedInvoices,
  } = useManageAndSaveFilters(analyticsPageFilters);

  const { data: stockItems } = useGetStockItemsAnalytic();
  const { data: salesOrder } = useGetSalesOrderAnalytic(selectedSalesOrders);
  const { data: orderPortfolio } = useGetOrderPortfolioAnalytic(
    selectedOrderPortfolio
  );

  return (
    <Container>
      <TopContainer>
        <ColumnContainer>
          <QuotationsCard
            dateOptions={dateOptions}
            selectedQuotations={selectedQuotations}
            setSelectedQuotations={setSelectedQuotations}
          />
          <IncomingDeliveriesCard />
        </ColumnContainer>
        <Cards>
          <FilterCard
            filterOptions={dateOptions}
            title={'Sales'}
            icon={Cardholder}
            route={'/sales'}
            label={'Sales'}
            value={salesOrder?.number_of_orders}
            totalValue={salesOrder?.total_value}
            onFilterChange={(selectedValue) =>
              setSelectedSalesOrders(selectedValue)
            }
            defaultValue={selectedSalesOrders ? selectedSalesOrders : undefined}
          />
          <FilterCard
            filterOptions={subStatusOptions}
            title={'Order Portfolio'}
            icon={Wallet}
            route={'/sales'}
            label={'Sales'}
            value={orderPortfolio?.number_of_orders}
            totalValue={orderPortfolio?.total_value}
            onFilterChange={(selectedValue) =>
              setSelectedOrderPortfolio(selectedValue)
            }
            defaultValue={
              selectedOrderPortfolio ? selectedOrderPortfolio : undefined
            }
          />
          <FilterCard
            title={'Stock Items'}
            icon={Cube}
            route={'/stock'}
            label={'In stock'}
            value={stockItems?.total_in_stock}
            totalValue={stockItems?.total_stock_value}
          />
        </Cards>
      </TopContainer>
      <BottomContainer>
        <InvoicesCard
          dateOptions={dateOptions}
          selectedInvoices={selectedInvoices}
          setSelectedInvoices={setSelectedInvoices}
        />
      </BottomContainer>
    </Container>
  );
};

export default AnalyticsPage;
