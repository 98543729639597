export const getUrlWithQueryParams = (
  urlWithoutQueryParams: string,
  queryParamsObj: object
) => {
  let url = urlWithoutQueryParams;
  let index = 1;
  // Include query parameter in the url if it is not a falsy value
  for (const queryParamObjectKey of Object.keys(queryParamsObj)) {
    const isArray = Array.isArray(queryParamsObj[queryParamObjectKey]);
    if (isArray && !queryParamsObj[queryParamObjectKey].length) {
      continue;
    }
    if (queryParamsObj[queryParamObjectKey]) {
      url = `${url}${index === 1 ? `?` : `&`}${queryParamObjectKey}=${
        isArray
          ? `[${queryParamsObj[queryParamObjectKey]}]`
          : queryParamsObj[queryParamObjectKey]
      }`;
      index++;
    }
  }
  return url;
};
