import { RoutesConfig } from 'navigation/routes';
import { Navigate, Outlet } from 'react-router-dom';
import { useIsManager } from './hooks';

const ManagerRoute = () => {
  const isManager = useIsManager();
  return isManager ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesConfig.FwAppointments.fullPath} />
  );
};

export default ManagerRoute;
