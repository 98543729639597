import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  width: 650rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.label`
  padding-bottom: 28rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  text-align: center;
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginSm};
  flex: 1;
  justify-content: space-between;

  &:first-child {
    margin-right: 20rem;
  }
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
  text-align: right;
`;

export const SignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Clear = styled.span`
  color: ${COLORS.RED_100};
  font-size: 14rem;
  line-height: 21rem;
  font-weight: 300;
  text-decoration: underline;
  display: block;
  margin-left: auto;
  margin-top: 10rem;
  cursor: pointer;
`;

export const ExportPDF = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 20rem;
`;

export const ExportText = styled.span`
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin-top: ${marginMd};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25rem;
  height: 25rem;
  margin-left: ${marginXs};
  margin-right: ${marginXs};
`;

export const SignatureExist = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20rem 0;
`;

export const Message = styled.span`
  color: ${COLORS.BLACK};
  font-size: 16rem;
`;
