import Icon from 'components/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import {
  ChevronDown,
  Item,
  Menu,
  PlusIcon,
  StyledDropdown,
} from './Dropdown.styled';
import { CaretDown, Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface IProps {
  onAddFilter: () => void;
  onAddFilterGroup: () => void;
}

const Dropdown = ({ onAddFilter, onAddFilterGroup }: IProps) => {
  const { t } = useTranslation();
  const [openDropDownMenu, setOpenDropDownMenu] = useState<boolean>(false);

  const dropdownButtonRef = useRef();
  useOutsideAlerter(dropdownButtonRef, () => setOpenDropDownMenu(false));

  return (
    <StyledDropdown
      onClick={() => setOpenDropDownMenu((prev) => !prev)}
      ref={dropdownButtonRef}
    >
      <PlusIcon>
        <Icon svg={Plus} size={15} color={COLORS.PRIMARY} weight="regular" />
      </PlusIcon>
      {t('Add another filter')}
      <ChevronDown>
        <Icon
          svg={CaretDown}
          size={15}
          color={COLORS.PRIMARY}
          weight="regular"
        />
      </ChevronDown>
      {openDropDownMenu && (
        <Menu>
          <Item onClick={onAddFilter}>{t('Filter')}</Item>
          <Item onClick={onAddFilterGroup}>{t('Filter Group')}</Item>
        </Menu>
      )}
    </StyledDropdown>
  );
};

export default Dropdown;
