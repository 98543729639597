import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { useEditPurchaseInvoiceFileName } from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoiceFilesPage/hooks';
import { useEditSalesInvoiceFileName } from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoiceFilesPage/hooks';
import { useEditPurchaseOrderFileName } from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderFilesPage/hooks';
import { useEditQuotationFileName } from 'pages/Manager/SingleQuotation/SingleQuotationFilesPage/hooks';
import { useEditSalesOrderFileName } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderFilesPage/hooks';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { validateField } from 'utils/validation';
import { FileType } from './constants';
import {
  AlignRow,
  CloseContainer,
  FormikContainer,
  Margin,
  Row,
  SubmitRow,
  Title,
  TitleContainer,
} from './EditFileNameModal.styled';
import { useEditAppointmentFileName } from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentFilesPage/hooks';

export interface IEditFileNameDTO {
  initialName: string;
  fileId: string;
  entityId: string;
  entityType: string;
}

interface IEditFileNameModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  editFileNameDTO: IEditFileNameDTO | null;
}
export const EditFileNameModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  editFileNameDTO,
}: IEditFileNameModalProps) => {
  const fileId = editFileNameDTO?.fileId || '';
  const entityId = editFileNameDTO?.entityId || '';
  const entityType = editFileNameDTO?.entityType || '';
  const initialName = editFileNameDTO?.initialName || '';

  const { t } = useTranslation();
  const { mutate: editQuotationFileName } = useEditQuotationFileName(
    entityId,
    fileId
  );
  const { mutate: editSalesOrderFileName } = useEditSalesOrderFileName(
    entityId,
    fileId
  );
  const { mutate: editPurchaseOrderFileName } = useEditPurchaseOrderFileName(
    entityId,
    fileId
  );
  const { mutate: editSalesInvoiceFileName } = useEditSalesInvoiceFileName(
    entityId,
    fileId
  );
  const { mutate: editPurchaseInvoiceFileName } =
    useEditPurchaseInvoiceFileName(entityId, fileId);
  const { mutate: editAppointmentFileName } = useEditAppointmentFileName(
    entityId,
    fileId
  );

  if (!editFileNameDTO) {
    return <></>;
  }

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit file name')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          initialValues={{
            name: initialName ? initialName : '',
          }}
          onSubmit={() => {
            //
          }}
        >
          {({
            handleBlur,
            setFieldValue,
            submitForm,
            isValid,
            values,
            errors,
            touched,
          }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    pwId="file-name-field"
                    validate={(value: string) => {
                      const errorMessage = validateField(value, 'name');
                      return errorMessage;
                    }}
                    errorMessage={errors['name'] as string}
                    height={'41rem'}
                    name="name"
                    placeholder={t('File name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '600rem',
                    }}
                  />
                </Row>
                <AlignRow>
                  <SubmitRow>
                    <Margin>
                      <Button
                        width={'200rem'}
                        onClick={onCancel}
                        label={t('Cancel')}
                        secondary
                      />
                    </Margin>
                    <Button
                      disabled={!isValid}
                      width={'200rem'}
                      onClick={() => {
                        switch (entityType) {
                          case FileType.QUOTATION:
                            editQuotationFileName(values.name);
                            break;
                          case FileType.SALES_ORDER:
                            editSalesOrderFileName(values.name);
                            break;
                          case FileType.SALES_INVOICE:
                            editSalesInvoiceFileName(values.name);
                            break;
                          case FileType.APPOINTMENT:
                            editAppointmentFileName(values.name);
                            break;
                          case FileType.PURCHASE_ORDER:
                            editPurchaseOrderFileName(values.name);
                            break;
                          case FileType.PURCHASE_INVOICE:
                            editPurchaseInvoiceFileName(values.name);
                            break;
                          default:
                            break;
                        }
                        setIsOpen(false);
                      }}
                      label={t('Edit')}
                      primary
                    />
                  </SubmitRow>
                </AlignRow>
              </FormikContainer>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
