export const getInitialSupplierDetailsData = (
  supplierData: any,
  initialCountryCCA2: string
) => {
  if (supplierData?.supplier) {
    return {
      email: supplierData?.supplier.email,
      phone: supplierData?.supplier.phone,
      country: supplierData?.supplier.address.country.cca2,
      city: supplierData?.supplier.address.city,
      street: supplierData?.supplier.address.street,
      house_number: supplierData?.supplier.address.house_number,
      code: supplierData?.supplier.address.zip_code,
      vat_number: supplierData?.supplier.vat_number,
      company_name: supplierData?.supplier.company_name,
      contact_person: supplierData?.supplier.contact_person,
    };
  } else {
    return {
      name: '',
      gender: '',
      email: '',
      phone: '',
      country: initialCountryCCA2,
      city: '',
      street: '',
      house_number: '',
      code: '',
      vat_number: '',
      company_name: '',
      contact_person: '',
    };
  }
};

export const getInitialItemsData = (prepopulatePurchaseOrderData: any) => {
  if (prepopulatePurchaseOrderData?.stockItem) {
    return [
      {
        item: prepopulatePurchaseOrderData?.stockItem?.stock_item,
        quantity: '',
        price:
          prepopulatePurchaseOrderData?.stockItem?.stock_item?.purchase_price,
      },
    ];
  } else {
    return [
      {
        item: '',
        quantity: '',
        price: '',
      },
    ];
  }
};
