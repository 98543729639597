import { IProduct } from 'types/Product.types';

export enum CreateInvoiceTab {
  CUSTOMER_DETAILS = 'customer_details',
  INVOICE_DETAILS = 'invoice_details',
  OVERVIEW = 'overview',
}
export interface IPendingInvoiceLine {
  id: number;
  product?: IProduct;
  working_hours_line: boolean;
  total_amount: number | string;
  salesPrice?: string;
  status: string;
  service: any;
  placement?: string;
  quantity?: number | string;
  supplier?: string;
  totalDiscount?: string;
  totalPurchaseCost?: string;
}
