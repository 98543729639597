import React, { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import FilterRow from './FilterRow/FilterRow';
import { Close } from 'components/Close/Close';
import { Modal } from '../Modal';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { ISavedAdvancedFilters, IFilterGroup, IFilterType } from './types';
import { defaultFilterGroups } from './constants';
import {
  ButtonWrapper,
  CloseContainer,
  Container,
  Filter,
  FilterWrapper,
  Title,
  TitleContainer,
  ButtonsWrapper,
  FilterContainer,
  SelectWrapper,
  FilterInner,
  ButtonRow,
  UnderlineButton,
  SaveWrapper,
  SaveSelectWrapper,
} from './AdvancedFilterModal.styled';
import Dropdown from './Dropdown/Dropdown';
import {
  checkEmptyValues,
  generateUniqueId,
  prepareData,
  unpackData,
} from './helpers';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { Base64 } from 'js-base64';
import {
  useAddSavedFilters,
  useDeleteSavedFilters,
  useGetConditionOptions,
  useGetPreConditionOptions,
  useGetSavedFilters,
} from './hooks';
import { useLocation } from 'react-router-dom';
import { AdvancedFilterSaveModal } from '../AdvancedFilterSaveModal/AdvancedFilterSaveModal';
import CustomOptionWithDelete from 'components/Select/SelectComponents/CustomOptionWithDelete/CustomOptionWithDelete';

export interface IAdvancedFilterModalProps {
  isOpen: boolean;
  filterTypes: IFilterType[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setAdvancedFilters: Dispatch<SetStateAction<string>>;
  initialFilterGroups?: IFilterGroup[];
}

const AdvancedFilterModal = ({
  isOpen,
  filterTypes,
  setIsOpen,
  setAdvancedFilters,
  initialFilterGroups,
}: IAdvancedFilterModalProps) => {
  const { t } = useTranslation();
  const [filterGroups, setFilterGroups] = useState<IFilterGroup[]>(
    initialFilterGroups || defaultFilterGroups
  );
  const [chosenSavedFilter, setChosenSavedFilter] = useState();

  const [isSaveModalModalOpen, setIsSaveModalModalOpen] =
    useState<boolean>(false);

  const conditionOptions = useGetConditionOptions();
  const preConditionOptions = useGetPreConditionOptions();
  const location = useLocation();
  const page = location.pathname.split('/').filter(Boolean);

  const { data: savedFiltersData } = useGetSavedFilters(page[0]);

  const savedFiltersOptions = savedFiltersData?.map(
    (filter: ISavedAdvancedFilters) => {
      return {
        value: filter.base64_string,
        label: filter.name,
        id: filter.id,
      };
    }
  );

  const { mutate: addSavedFilters } = useAddSavedFilters(page[0]);
  const { mutate: deleteSavedFilter } = useDeleteSavedFilters();

  useEffect(() => {
    return () => {
      resetSubFilters();
    };
  }, []);

  const resetSubFilters = () => {
    setFilterGroups((prevFilterGroups) =>
      prevFilterGroups.map((group) => ({
        ...group,
        subFilters: group.subFilters.slice(0, 2),
      }))
    );
  };

  const addFilter = () => {
    setFilterGroups((prevFilterGroups) => [
      ...prevFilterGroups,
      {
        id: `group${generateUniqueId()}`,
        filters: [
          {
            id: `filter${generateUniqueId()}`,
            type: '',
            precondition: preConditionOptions[0].value,
            condition: conditionOptions[0].value,
            value: '',
          },
        ],
        subFilters: [],
      },
    ]);
  };

  const addFilterGroup = () => {
    setFilterGroups((prevFilterGroups) => [
      ...prevFilterGroups,
      {
        id: `group${generateUniqueId()}`,
        filters: [],
        subFilters: [
          {
            id: `subFilter${generateUniqueId()}`,
            type: '',
            precondition: preConditionOptions[0].value,
            condition: conditionOptions[0].value,
            value: '',
          },
        ],
      },
    ]);
  };

  const addSubFilter = (groupIndex: number) => {
    setFilterGroups((prevFilterGroups) => {
      const newFilterGroups = [...prevFilterGroups];
      newFilterGroups[groupIndex].subFilters.push({
        id: `subFilter${generateUniqueId()}`,
        type: '',
        precondition: preConditionOptions[0].value,
        condition: conditionOptions[0].value,
        value: '',
      });
      return newFilterGroups;
    });
  };

  const removeGroup = (groupId: string) => {
    setFilterGroups((prevFilterGroups) => {
      const isFirstGroup = prevFilterGroups[0].id === groupId;

      if (isFirstGroup) {
        return prevFilterGroups.map((group) =>
          group.id === groupId
            ? {
                ...group,
                subFilters: [],
              }
            : group
        );
      }

      return prevFilterGroups.filter((group) => group.id !== groupId);
    });
  };

  const removeFilter = (groupId: string, filterId: string) => {
    setFilterGroups((prevFilterGroups) => {
      return prevFilterGroups
        .map((group) => {
          if (group.id === groupId) {
            const isFilter = group.filters.some(
              (filter) => filter.id === filterId
            );
            if (isFilter) {
              return {
                ...group,
                filters: group.filters.filter(
                  (filter) => filter.id !== filterId
                ),
                subFilters: group.subFilters.length > 0 ? [] : group.subFilters,
              };
            } else {
              return {
                ...group,
                subFilters: group.subFilters.filter(
                  (filter) => filter.id !== filterId
                ),
              };
            }
          } else {
            return group;
          }
        })
        .filter(
          (group) => group.filters.length > 0 || group.subFilters.length > 0
        );
    });
  };

  const handleFilterChange = (
    groupId: string,
    filterId: string,
    newFilter: any
  ) => {
    setFilterGroups(
      filterGroups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              filters: group.filters.map((filter) =>
                filter.id === filterId ? { ...filter, ...newFilter } : filter
              ),
              subFilters: group.subFilters.map((filter) =>
                filter.id === filterId ? { ...filter, ...newFilter } : filter
              ),
            }
          : group
      )
    );
  };

  const handleApplyFilters = () => {
    const filters = Base64.encode(JSON.stringify(prepareData(filterGroups)));
    setAdvancedFilters(filters);
    setIsOpen(false);
  };

  const clearFilters = () => {
    setAdvancedFilters('');
    setChosenSavedFilter(undefined);

    const newFilterGroups = defaultFilterGroups.map((group) => ({
      ...group,
      id: generateUniqueId(),
      filters: group.filters.map((filter) => ({
        ...filter,
        id: generateUniqueId(),
        type: '',
        condition: 'eq',
        value: '',
      })),
      subFilters: group.subFilters.map((subFilter) => ({
        ...subFilter,
        id: generateUniqueId(),
        type: '',
        precondition: preConditionOptions[0].value,
        condition: conditionOptions[0].value,
        value: '',
      })),
    }));

    setFilterGroups(newFilterGroups);
  };

  const handleValueChange = (e: any) => {
    clearFilters();
    setChosenSavedFilter(e);
    const decodedFilters = Base64.decode(e.value);
    const parsedFilters = JSON.parse(decodedFilters);
    const filters = unpackData(parsedFilters);

    setFilterGroups(filters);
  };

  const handleDeleteOption = (option: Option) => {
    if (option.id) {
      deleteSavedFilter(option.id);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        margin: 'auto',
        position: 'fixed',
        overflow: 'visible',
        maxWidth: '1000rem',
      }}
    >
      <Container>
        <CloseContainer>
          <Close onClick={() => setIsOpen(false)} />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Advanced filter')}</Title>
        </TitleContainer>
        <SaveWrapper>
          <SaveSelectWrapper>
            <Select
              key={chosenSavedFilter}
              name="advancedFilterValue"
              placeholder={t('Use saved filter')}
              isMulti={false}
              components={{
                Option: (props) => (
                  <CustomOptionWithDelete
                    {...props}
                    onDeleteOption={handleDeleteOption}
                  />
                ),
              }}
              onDeleteOption={handleDeleteOption}
              isDisabled={false}
              defaultValue={chosenSavedFilter}
              isSearchable={true}
              onChange={handleValueChange}
              options={savedFiltersOptions || []}
            />
          </SaveSelectWrapper>
          <Button
            label={t('Save new filter')}
            icon={Plus}
            weightIcon="regular"
            colorIcon={COLORS.PRIMARY}
            sizeIcon={15}
            link
            fontSize="18rem"
            onClick={() => setIsSaveModalModalOpen(true)}
          />
        </SaveWrapper>
        <FilterWrapper>
          <FilterInner>
            {filterGroups.flatMap((group, groupIndex) => (
              <React.Fragment key={group.id}>
                <FilterContainer>
                  {!!group.filters.length && (
                    <Filter $isGroup={false}>
                      {group.filters.map((filter) => (
                        <FilterRow
                          key={filter.id}
                          mainRow={groupIndex === 0}
                          firstRow={groupIndex === 0}
                          filterTypes={filterTypes}
                          onFilterChange={(newFilter) =>
                            handleFilterChange(group.id, filter.id, newFilter)
                          }
                          onRemoveFilter={() =>
                            removeFilter(group.id, filter.id)
                          }
                          initialCondition={filter.condition}
                          initialPrecondition={filter.precondition}
                          initialType={filter.type}
                          initialValue={filter.value}
                        />
                      ))}
                    </Filter>
                  )}
                </FilterContainer>
                {!!group.subFilters.length && (
                  <FilterContainer>
                    <SelectWrapper $width={'100rem'}>
                      <Select
                        name="preConditionSelect"
                        isMulti={false}
                        placeholder={''}
                        defaultValue={[
                          group.subFilters?.[0]?.precondition
                            ? {
                                value: group.subFilters?.[0]?.precondition,
                                label:
                                  group.subFilters[0].precondition
                                    .charAt(0)
                                    .toUpperCase() +
                                  group.subFilters[0].precondition.slice(1),
                              }
                            : preConditionOptions[0],
                        ]}
                        isSearchable={false}
                        onChange={(e: Option) =>
                          handleFilterChange(group.id, group.subFilters[0].id, {
                            precondition: e.value,
                          })
                        }
                        options={preConditionOptions}
                      />
                    </SelectWrapper>
                    <Filter $isGroup={true}>
                      {group.subFilters.map((filter, index) => (
                        <FilterRow
                          key={filter.id}
                          firstRow={index === 0}
                          filterTypes={filterTypes}
                          onFilterChange={(newFilter) =>
                            handleFilterChange(group.id, filter.id, newFilter)
                          }
                          onRemoveFilter={() =>
                            removeFilter(group.id, filter.id)
                          }
                          isSubFilter
                          initialCondition={filter.condition}
                          initialPrecondition={filter.precondition}
                          initialType={filter.type}
                          initialValue={filter.value}
                        />
                      ))}
                      <ButtonWrapper>
                        <Button
                          label={t('Add another filter')}
                          icon={Plus}
                          weightIcon="regular"
                          colorIcon={COLORS.PRIMARY}
                          sizeIcon={15}
                          link
                          fontSize="18rem"
                          onClick={() => addSubFilter(groupIndex)}
                        />
                        {group.subFilters.length > 1 && (
                          <UnderlineButton
                            onClick={() => removeGroup(group.id)}
                          >
                            {t('Remove group')}
                          </UnderlineButton>
                        )}
                      </ButtonWrapper>
                    </Filter>
                  </FilterContainer>
                )}
              </React.Fragment>
            ))}
          </FilterInner>
          <ButtonRow>
            <Dropdown
              onAddFilter={addFilter}
              onAddFilterGroup={addFilterGroup}
            />
            <UnderlineButton onClick={() => clearFilters()}>
              {t('Reset')}
            </UnderlineButton>
          </ButtonRow>
        </FilterWrapper>
        <ButtonsWrapper>
          <Button
            label={t('Cancel')}
            secondary
            width="200rem"
            onClick={() => setIsOpen(false)}
          />
          <Button
            label={t('Apply')}
            primary
            disabled={checkEmptyValues(filterGroups)}
            width="200rem"
            onClick={handleApplyFilters}
          />
        </ButtonsWrapper>
      </Container>

      <AdvancedFilterSaveModal
        isOpen={isSaveModalModalOpen}
        setIsOpen={setIsSaveModalModalOpen}
        onConfirm={(name) => {
          addSavedFilters({
            name,
            base64: Base64.encode(JSON.stringify(prepareData(filterGroups))),
          });
          setIsSaveModalModalOpen(false);
        }}
        onCancel={() => setIsSaveModalModalOpen(false)}
      />
    </Modal>
  );
};

export default AdvancedFilterModal;
