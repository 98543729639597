import { H4 } from 'assets/styled';
import {
  CloseWrapper,
  Container,
  HorizontalLine,
} from '../ViewProductModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/numberFormatter';
import FakeInput from 'components/FakeInput/FakeInput';
import { IViewProductProps } from '../type';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

interface IGeneralTabProps {
  productLineData: IViewProductProps;
  onCancel: () => void;
}

export const GeneralTab = ({ productLineData, onCancel }: IGeneralTabProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const values = {
    status: t(
      productLineData?.status
        .toLowerCase()
        .replace(/_/g, ' ')
        .charAt(0)
        .toUpperCase() +
        productLineData!.status.toLowerCase().replace(/_/g, ' ').slice(1)
    ),
    supplier: productLineData?.supplier,
    salesPrice: `${currencySymbol}${formatNumber(productLineData?.salesPrice)}`,
    quantity: productLineData?.quantity,
    totalDiscount: `${currencySymbol}${
      productLineData?.totalDiscount
        ? formatNumber(productLineData?.totalDiscount)
        : '0'
    }`,
    totalPurchaseCost: `${currencySymbol}${formatNumber(
      Number(productLineData?.totalPurchaseCost) *
        Number(productLineData?.quantity)
    )}`,
  };

  return (
    <Container>
      <div>
        <H4>{t('Status')}</H4>
        <FakeInput
          placeholder=""
          label={values.status}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Supplier')}</H4>
        <FakeInput
          placeholder=""
          label={values.supplier || ''}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Sales price')}</H4>
        <FakeInput
          placeholder=""
          label={values.salesPrice || ''}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Quantity')}</H4>
        <FakeInput
          placeholder=""
          label={values.quantity?.toString() || ''}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Total discount')}</H4>
        <FakeInput
          placeholder=""
          label={values.totalDiscount || ''}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Total purchase cost')}</H4>
        <FakeInput
          placeholder=""
          label={values.totalPurchaseCost || ''}
          width="300rem"
          marginBottom="20rem"
          marginTop="20rem"
        />
      </div>
      <CloseWrapper>
        <Button onClick={onCancel} primary width="200rem" label={t('Close')} />
      </CloseWrapper>
    </Container>
  );
};
