import { useQuery } from 'react-query';
import { getUsers } from 'services/User/UserService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetUsers = (
  page: number,
  perPage: number,
  searchBy: string,
  dateFrom?: string,
  dateTo?: string,
  isEnabled?: boolean,
  sortDirection?: string,
  activeOnly?: boolean
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_COMPANY_USERS,
      page,
      perPage,
      searchBy,
      dateFrom,
      dateTo,
      sortDirection,
      activeOnly,
    ],
    queryFn: () =>
      getUsers(
        page,
        perPage,
        searchBy,
        dateFrom,
        dateTo,
        sortDirection,
        activeOnly
      ),
    enabled: isEnabled,
  });
