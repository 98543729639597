import styled from 'styled-components';
import { COLORS, marginMd } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div`
  width: 850rem;
  border-radius: 10rem;
  margin-top: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const Margin = styled.div`
  margin-right: ${marginMd};
`;

export const TopContainer = styled.div``;
export const RowMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
`;

export const SelectInputWrapper = styled.div`
  width: 203rem;
  margin-top: -3rem;
`;
