import {
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  LabelKey,
  LabelValue,
  RowGap,
} from './ExternalTwinfieldCustomerDetailsModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Level } from 'components/Modal/type';
import { IExternalTwinfieldCustomerDetails } from 'types/Accounting.types';

interface IExternalTwinfieldCustomerDetailsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel?: () => void;
  level?: Level;
  details: IExternalTwinfieldCustomerDetails | null;
}

export const ExternalTwinfieldCustomerDetailsModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  level = 'FIRST',
  details,
}: IExternalTwinfieldCustomerDetailsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level={level}
      modalStyle={{ position: 'fixed', width: '550rem' }}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Twinfield customer details')}</Title>
        </TitleContainer>
        <RowGap>
          <LabelKey>{t('E-mail')}</LabelKey>
          <LabelValue>{details?.email}</LabelValue>
        </RowGap>
      </TopContainer>
    </Modal>
  );
};
