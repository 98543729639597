import { COLORS, H3, H4 } from 'assets/styled';
import {
  Container,
  TitleContainer,
  NumberContainer,
  Number,
  Count,
  ButtonContainer,
  TitleWrapper,
  NoPermissionMessage,
} from './Card.styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import MoreButton from 'components/MoreButton/MoreButton';

interface ICardProps {
  title: string;
  leftValue: number;
  rightValue: number;
  route: string;
  icon: any;
  permissionToSee: boolean;
  leftLabel?: string;
  rightLabel?: string;
}

export const Card = ({
  title,
  leftValue,
  rightValue,
  route,
  icon,
  permissionToSee,
  leftLabel,
  rightLabel,
}: ICardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={icon} pointer={false} size={30} color={COLORS.PRIMARY} />
          <H3>{title}</H3>
        </TitleWrapper>
        {permissionToSee && (
          <ButtonContainer onClick={() => navigate(route)}>
            <MoreButton />
          </ButtonContainer>
        )}
      </TitleContainer>
      {permissionToSee ? (
        <NumberContainer>
          <Number>
            <H4>{leftLabel ? leftLabel : t('Ongoing')}</H4>

            <Count>
              {leftValue == undefined ? (
                <Spinner />
              ) : (
                formatNumber(leftValue, false)
              )}
            </Count>
          </Number>
          <Number>
            <H4>{rightLabel ? rightLabel : t('Recently Closed')}</H4>
            <Count>
              {rightValue === undefined ? (
                <Spinner />
              ) : (
                formatNumber(rightValue, false)
              )}
            </Count>
          </Number>
        </NumberContainer>
      ) : (
        <NoPermissionMessage>{t('No permission')}</NoPermissionMessage>
      )}
    </Container>
  );
};
