import { ICustomer } from 'types/Customer.types';
import { ICreateQuotationShippingData } from 'types/Quotations.types';

export interface ICreateQuotationCustomerDetails {
  name: string;
  last_name: string;
  gender: string;
  email: string;
  country: string;
  phone: string;
  street: string;
  code: string;
  city: string;
  house_number: string;
  company_name: string;
  vat_number: string;
  existingCustomer: ICustomer | null;
  shipping_data?: ICreateQuotationShippingData;
  different_shipping_address?: boolean;
  isShippingDataFormShown?: boolean; // Used as initial value in CustomerTab for isShippingDataFormShown
  title: string;
}

export const CUSTOMERS_PER_PAGE = 10;
