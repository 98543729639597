import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { IRootReducerState } from 'store/store';
import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider = ({ children }: any) => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  return (
    <>
      <ToastContainer
        theme={darkMode ? 'dark' : 'light'}
        className={darkMode ? 'toast-dark' : ''}
        closeOnClick
      />
      {children}
    </>
  );
};
