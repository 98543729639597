import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  fontSize: string;
  gap: string;
  alignItems: string;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 25rem;
  top: 25rem;
`;

export const BlockedSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RunningSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppointmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  margin-top: 20rem;
  margin-bottom: 20rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-bottom: 20rem;
`;

export const SingleAppointmentWrapper = styled.div`
  margin-left: 20rem;
`;

export const Label = styled.label`
  font-weight: 300;
  ${(props: StyledProps) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${(props: StyledProps) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};

  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `};
`;

export const BlockingAppointmentsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TooltipContentWrapper = styled.div``;
