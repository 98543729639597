// Single Purchase Order
export const SinglePurchaseOrderRoutes = {
  SinglePurchaseOrderGeneral: {
    path: '',
    fullPath: '/purchases',
  },
  SinglePurchaseOrderProducts: {
    path: 'products',
    fullPath: '/purchases/:id/products',
  },
  SinglePurchaseOrderComments: {
    path: 'comments',
    fullPath: '/purchases/:id/comments',
  },
  SinglePurchaseOrderInvoices: {
    path: 'invoices',
    fullPath: '/purchases/:id/invoices',
  },
  SinglePurchaseOrderSales: {
    path: 'sales',
    fullPath: '/purchases/:id/sales',
  },
  SinglePurchaseOrderAppointments: {
    path: 'appointments',
    fullPath: '/purchases/:id/appointments',
  },
  SinglePurchaseOrderFiles: {
    path: 'files',
    fullPath: '/purchases/:id/files',
  },
  SinglePurchaseOrderNewComment: {
    path: 'comment',
    fullPath: '/purchases/:id/comment',
  },
  SinglePurchaseOrderNewInvoice: {
    path: 'invoice',
    fullPath: '/purchases/:id/invoice',
  },
  SinglePurchaseOrderDirectLogyxOrder: {
    path: 'direct-order',
    fullPath: '/purchases/:id/direct-order',
  },
};
