export const formatAttributeObjectIntoArray = (
  attributesObject: object | undefined
) => {
  if (!attributesObject) {
    return [];
  }
  return Object.keys(attributesObject).map((attributeKey) =>
    attributesObject ? attributesObject[attributeKey] : null
  );
};
