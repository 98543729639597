import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { createQuotation } from 'services/Quotation/QuotationService';
import { ICreateQuotationData } from 'types/Quotations.types';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useEffect, useState } from 'react';
import { getCityAndAddress } from 'services/Common/CommonService';
import { IGetCityAndAddressDTO } from './constants';
import { useCallbackPrompt } from 'utils/hooks/useCallbackPrompt';
import { ICreateQuotationProductFormDTO } from './ProductTab/constants';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useCreateQuotation = () =>
  useMutation(
    (createQuotationData: ICreateQuotationData) =>
      createQuotation(createQuotationData),
    {
      onSuccess: () => {
        // Success
        queryClient.invalidateQueries([ReactQueryKeys.GET_QUOTATION_INFO]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_QUOTATION,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.CREATE_QUOTATION,
    }
  );

export const useGetPrepopulateQuotationData = () => {
  const { state } = useLocation();
  if (state?.prepopulateQuotationData) {
    return state.prepopulateQuotationData;
  } else {
    return null;
  }
};
export const useGetPrepopulateCustomerData = () => {
  const { state } = useLocation();
  if (state?.customerData) {
    return state.customerData;
  } else {
    return null;
  }
};

export const useGetCityAndAddress = () =>
  useMutation(
    (data: IGetCityAndAddressDTO) =>
      getCityAndAddress(data.zipCode, data.houseNumber),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        //Error
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.GET_CITY_AND_ADDRESS,
    }
  );

export const useAddNavigationCheckAfterProductIsAdded = (
  productFormDtos: ICreateQuotationProductFormDTO[],
  status: string
) => {
  const [checkNavigation, setCheckNavigation] = useState<boolean>(false);
  const { showPrompt, confirmNavigation, cancelNavigation } =
    useCallbackPrompt(checkNavigation);

  useEffect(() => {
    if (productFormDtos?.length) {
      setCheckNavigation(
        status === 'loading' || status === 'success' ? false : true
      );
    }
  }, [productFormDtos, status, checkNavigation]);

  return {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  };
};
