import {
  COLORS,
  H3,
  H4,
  marginLg,
  marginMd,
  marginSm,
  marginXs,
  paddingMd,
  paddingXs,
} from 'assets/styled';
import styled, { css } from 'styled-components';
import { borderRadiusSm } from 'assets/styled';
import { Field } from 'formik';

interface StyledProps {
  marginLeft: string;
  marginBottom: string;
  paddingTop: string;
  marginTop: string;
  isSelected: boolean;
  height: string;
  canAddAnotherProduct: boolean;
}

export const Container = styled.div`
  position: relative;
  padding: 30rem;
  display: flex;
  width: 770rem;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginLg};
  ${(props: StyledProps) =>
    !props.canAddAnotherProduct &&
    css`
      padding-top: 0;
    `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30rem;
  max-width: 100%;
  width: 100%;
  margin-top: 14rem;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const Margin = styled.div`
  margin-left: 30rem;
`;

export const Line = styled.div`
  margin-top: ${marginXs};
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
`;

export const GreenLine = styled.div`
  margin-bottom: ${marginMd};
  border-bottom: 1rem solid ${COLORS.LOGYX_LIGHT_GREEN};
  display: flex;
  width: 100%;
`;

export const TextIconButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const ImageSmall = styled.img`
  margin-right: 10rem;
  width: 10rem;
  height: 10rem;
`;

export const TextSmall = styled.span`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  color: ${COLORS.PRIMARY};
`;

export const RightAlign = styled(Row)`
  justify-content: flex-end;
  margin-top: 10rem;
`;

export const LeftAlign = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  margin-top: 10rem;
`;

export const ProductsLabel = styled(H3)`
  margin-bottom: ${marginMd};
`;

export const SingleProductLabel = styled(H4)`
  margin-top: ${marginMd};
  ${(props: StyledProps) =>
    !props.canAddAnotherProduct &&
    css`
      font-size: 24rem;
      font-weight: 300;
      line-height: 36rem;
    `}
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const SingleProductWrapper = styled.div``;

export const ProductTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;
export const ProductSupplierLabel = styled(H4)`
  margin-top: ${marginMd};
`;

export const StyledTextArea = styled(Field)`
  width: 100%;
  resize: none;
  border-radius: ${borderRadiusSm};
  padding-left: 16rem;
  padding-right: 16rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  height: 120rem;
`;

export const RowMarginBottom20 = styled.div`
  margin-bottom: ${marginSm};
`;

export const FakeLogyxInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: 47%;
  width: 100%;
  ${(props: StyledProps) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 48%;
  ${(props: StyledProps) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `}
`;

export const AddedFieldsFlexWrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DeleteProductFieldIcon = styled.div`
  position: absolute;
  cursor: pointer;
  width: 20rem;
  height: 20rem;
  right: -2rem;
  bottom: 58rem;
  background-color: ${COLORS.WHITE};
  border-radius: 5rem;
`;

export const TrashCopyWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  align-items: center;
  gap: 25rem;
`;

export const AbsoluteDiscountOptionsWrapper = styled.div`
  position: absolute;
  top: -50rem;
  display: flex;
  flex-direction: row;
`;

export const DiscountOptionLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const RelativeDiscountFieldAndDiscountOptionsWrapper = styled.div`
  position: relative;
  width: 50%;
`;

export const SeparatorMd = styled.div`
  height: 30rem;
  width: 100%;
  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

export const SelectWrapper = styled.div`
  width: 48%;
  position: relative;
  align-items: flex-start;
  ${(props: StyledProps) =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `}
  ${(props: StyledProps) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
    padding-bottom: ${paddingMd};
`;

export const EditWithLogyxContainer = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50rem;
  right: -10rem;
  bottom: 42rem;
  padding: ${paddingXs};
  padding-left: 0;

  :hover {
    img {
      transform: scale(1.1);
      transition: ease 200ms;
    }
    label {
      text-decoration: underline;
      text-decoration-thickness: 1rem;
      text-underline-position: under;
    }
  }
`;

export const LogyxLogoImage = styled.img`
  width: 30rem;
  height: 30rem;
`;

export const EditLabel = styled.label`
  cursor: pointer;
  font-size: 20rem;
  color: ${COLORS.LOGYX_GREEN};
`;

export const EyeInputWrapper = styled.div`
  position: relative;
  width: 50%;
  padding-right: 15rem;
`;

export const EyeWrapper = styled.div`
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 11rem;
  right: 50rem;
  width: 20rem;
`;
