import Button from 'components/Button/Button';
import {
  BigLoaderWrapper,
  ButtonWrapper,
  Container,
  Headline,
  TableWrapper,
} from './DirectLogyxOrderPage.styled';
import {
  useCreatePurchaseOrderCart,
  useGetAvailableSupplierOrders,
  useLogyxReconfigureFlow,
  useMoveToExistingOrder,
  useGetLogyxOrderLineTableDTOs,
} from './hooks';
import { useTranslation } from 'react-i18next';
import ProductValidationTable from './ProductValidationTable/ProductValidationTable';
import { LogyxOrderStatusId } from './ProductValidationTable/types';
import { ViewProductModal } from 'components/Modal/ViewProductModal/ViewProductModal';
import { useEffect, useState } from 'react';
import { ViewProductModalFormatter } from 'components/Modal/ViewProductModal/helpers';
import { ViewProductType } from 'components/Modal/ViewProductModal/type';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import ManualModal from './ManualModal/ManualModal';
import { AwaitingLogyxConfigurationModal } from './AwaitingLogyxConfigurationModal/AwaitingLogyxConfigurationModal';
import { useDispatch } from 'react-redux';
import { setIsAwaitingPurchaseOrderLineEdit } from 'store/Logyx/actions/logyx';
import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';

const DirectLogyxOrderPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLineForViewMore, setSelectedLineForViewMore] = useState<
    IPurchaseOrderLine | undefined
  >();

  const [selectedLineIdForManualOrder, setSelectedLineIdForManualOrder] =
    useState<number | null>(null);
  const [isManualModalOpen, setIsManualModalOpen] = useState<boolean>(false);
  const [isOrderPlacedModalOpen, setIsOrderPlacedModalOpen] =
    useState<boolean>(false);
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] =
    useState<boolean>(false);

  // Open socket connection
  useSocketConnection();

  // Create logyx order, run validation on its lines, and refetch the logyx order along with its lines after the validation is complete
  const {
    isLogyxOrderLoading,
    isFetchingStartLogyxValidation,
    logyxOrderStatus,
    logyxOrderLineTableDTOs,
    retryLogyxOrderValidation,
  } = useGetLogyxOrderLineTableDTOs();

  // Move to existing order
  const {
    mutate: moveToExistingOrderMutation,
    isSuccess: isSuccessMoveToExistingOrder,
  } = useMoveToExistingOrder();
  const moveToExistingOrder = (selectedOrderId: number) => {
    moveToExistingOrderMutation({
      selectedOrderId,
      selectedOrderLineId: selectedLineIdForManualOrder,
    });
  };

  useEffect(() => {
    if (isSuccessMoveToExistingOrder) {
      retryLogyxOrderValidation();
    }
  }, [isSuccessMoveToExistingOrder]);

  // Fetch available suppliers for manual order
  const {
    mutate: getAvailableSupplierOrders,
    data: availableSupplierOrders,
    isLoading: isLoadingAvailableSupplierOrders,
  } = useGetAvailableSupplierOrders();

  // Create cart
  const { mutate: createCart, isLoading: isLoadingCreateCart } =
    useCreatePurchaseOrderCart(id!);

  const getReconfigureLogyxURL = useLogyxReconfigureFlow(id!);

  return (
    <Container>
      {(isLogyxOrderLoading || isFetchingStartLogyxValidation) && (
        <BigLoaderWrapper>
          <Loader positionType="relative" />
        </BigLoaderWrapper>
      )}
      <Headline>{t('Product validation')}</Headline>
      <TableWrapper>
        <ProductValidationTable
          logyxOrderLineTableDTOs={logyxOrderLineTableDTOs}
          onMoreClick={(line) => {
            setSelectedLineForViewMore(line);
            setIsViewMoreModalOpen(true);
          }}
          onOrderManuallyClick={(line) => {
            getAvailableSupplierOrders(line?.supplier?.id);
            setSelectedLineIdForManualOrder(line.id);
            setIsManualModalOpen(true);
          }}
          onReconfigureClick={(line) => {
            dispatch(setIsAwaitingPurchaseOrderLineEdit(true));
            getReconfigureLogyxURL(line.id);
          }}
        />
      </TableWrapper>
      <ButtonWrapper>
        <Button
          label={t('Cancel')}
          onClick={() => navigate(-1)}
          secondary
          width="200rem"
        />
        <Button
          label={t('Order')}
          primary
          width="200rem"
          onClick={() => setIsOrderPlacedModalOpen(true)}
          disabled={
            logyxOrderStatus !== LogyxOrderStatusId.VALID ||
            isLoadingCreateCart ||
            isFetchingStartLogyxValidation ||
            isLogyxOrderLoading
          }
        />
      </ButtonWrapper>
      <ViewProductModal
        isOpen={isViewMoreModalOpen}
        setIsOpen={setIsViewMoreModalOpen}
        onCancel={() => setIsViewMoreModalOpen(false)}
        productLineData={ViewProductModalFormatter(
          selectedLineForViewMore,
          ViewProductType.PURCHASE_ORDER_LINE
        )}
      />
      <ConfirmModal
        isOpen={isOrderPlacedModalOpen}
        setIsOpen={setIsOrderPlacedModalOpen}
        onConfirm={() => {
          createCart();
          setIsOrderPlacedModalOpen(false);
        }}
        onCancel={() => setIsOrderPlacedModalOpen(false)}
        title={t('Place the order')}
        description={
          t(
            'The Order will be placed and the order details will become visible in a short time'
          ) + '.'
        }
      />
      <AwaitingLogyxConfigurationModal />
      <ManualModal
        isOpen={isManualModalOpen}
        setIsOpen={setIsManualModalOpen}
        availablePurchaseOrders={
          availableSupplierOrders?.available_purchase_orders
        }
        moveToExistingOrder={moveToExistingOrder}
        isLoading={isLoadingAvailableSupplierOrders}
        selectedLineIdForManualOrder={selectedLineIdForManualOrder!}
        retryLogyxOrderValidation={retryLogyxOrderValidation}
      />
    </Container>
  );
};

export default DirectLogyxOrderPage;
