import { borderRadiusSm, marginSm, marginXs } from 'assets/styled';
import styled, { css } from 'styled-components';

export const containerStyle = css`
  position: relative;
  font-family: inherit;
  border-radius: ${borderRadiusSm};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const SelectWrapper = styled.div`
  width: 300rem;
`;

export const DocumentSelectWrapper = styled.div`
  min-width: 238rem;
  margin-left: ${marginSm};
`;

export const InputWrapper = styled.div`
  pointer-events: none;
`;

export const RowAlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const EnableFieldAfterLogyxConfigurationWrapper = styled.div`
  margin-top: ${marginXs};
`;
