import { useInfiniteQuery, useMutation } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getNotifications,
  markAllAsRead,
} from 'services/Notification/NotificationService';
import { toast } from 'utils/toast';

export const useGetNotifications = (perPage: number, searchBy: string) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_NOTIFICATIONS, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) =>
      getNotifications(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useMarkAllAsRead = () =>
  useMutation((_: object) => markAllAsRead(), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.MARK_ALL_NOTIFICATION_AS_READ,
  });
