import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'utils/toast';
import {
  getFwAppointmentIntervals,
  getSingleAppointmentReport,
  pauseFwAppointmentInterval,
  resumeFwAppointmentInterval,
  startFwAppointmentInterval,
  stopFwAppointmentInterval,
} from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { queryClient } from 'index';
import { IAppointmentInterval } from 'types/Appointment.types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { checkIfLastIntervalIsStopped } from './helpers';
import { useTranslation } from 'react-i18next';

export const useGetFwAppointmentIntervals = (appointmentId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_FW_APPOINTMENT_INTERVALS, appointmentId],
    queryFn: () => {
      return getFwAppointmentIntervals(appointmentId);
    },
    enabled: !!appointmentId,
  });

export const useStartFwAppointmentInterval = (appointmentId: string) => {
  const { t } = useTranslation();
  return useMutation(() => startFwAppointmentInterval(appointmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_FW_APPOINTMENT_INTERVALS,
      ]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT]);
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.message?.startsWith(
          'Already an active appointment connected'
        )
      ) {
        const entityNumber = error?.response?.data?.message.replace(
          'Already an active appointment connected to ',
          ''
        );
        toast.error(
          t(
            'Already an active appointment connected to {ENTITY_NUMBER}'
          ).replace('{ENTITY_NUMBER}', entityNumber),
          {
            toastId: ReactMutationKeys.START_FW_APPOINTMENT_INTERVAL,
          }
        );
      } else if (
        error?.response?.data?.errors[0]?.endsWith('is still running')
      ) {
        const entityNumber = error?.response?.data?.errors[0]
          ?.replace('400 Bad Request: Timer for ', '')
          .replace(' is still running', '');

        toast.error(
          t('Timer for {ENTITY_NUMBER} is still running').replace(
            '{ENTITY_NUMBER}',
            entityNumber
          ),
          {
            toastId: ReactMutationKeys.START_FW_APPOINTMENT_INTERVAL,
          }
        );
      } else
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.START_FW_APPOINTMENT_INTERVAL,
        });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.START_FW_APPOINTMENT_INTERVAL,
  });
};
export const useStopFwAppointmentInterval = (appointmentId: string) =>
  useMutation(() => stopFwAppointmentInterval(appointmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_FW_APPOINTMENT_INTERVALS,
      ]);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.STOP_FW_APPOINTMENT_INTERVAL,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.STOP_FW_APPOINTMENT_INTERVAL,
  });

export const usePauseFwAppointmentInterval = (appointmentId: string) =>
  useMutation(() => pauseFwAppointmentInterval(appointmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_FW_APPOINTMENT_INTERVALS,
      ]);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.PAUSE_FW_APPOINTMENT_INTERVAL,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.PAUSE_FW_APPOINTMENT_INTERVAL,
  });

export const useResumeFwAppointmentInterval = (appointmentId: string) =>
  useMutation(() => resumeFwAppointmentInterval(appointmentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_FW_APPOINTMENT_INTERVALS,
      ]);
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.RESUME_FW_APPOINTMENT_INTERVAL,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.RESUME_FW_APPOINTMENT_INTERVAL,
  });

export const useGetIsIntervalPaused = (intervals: IAppointmentInterval[]) => {
  if (!intervals || !intervals.length) {
    return false;
  }

  const lastInterval = intervals[intervals.length - 1];

  if (!lastInterval?.pauses?.length) {
    return false;
  }
  const lastIntervalPause = lastInterval.pauses[lastInterval.pauses.length - 1];

  if (lastIntervalPause.end_date) {
    return false;
  }

  return true;
};

const getCurrentInterval = (intervals: IAppointmentInterval[] | null) => {
  if (!intervals || !intervals.length) {
    return null;
  }

  const lastInterval = intervals[intervals.length - 1];

  if (lastInterval.end_date) {
    return null;
  }
  return lastInterval;
};

export const useGetCurrentInterval = (
  intervals: IAppointmentInterval[]
): IAppointmentInterval | null => {
  const [currentInterval, setCurrentInterval] =
    useState<IAppointmentInterval | null>(getCurrentInterval(intervals));

  useEffect(() => {
    setCurrentInterval(getCurrentInterval(intervals));
  }, [intervals]);

  return currentInterval;
};

export const useGetTotalIntervalTime = (intervals: IAppointmentInterval[]) => {
  if (!intervals || !intervals.length) {
    return null;
  }

  const lastInterval = intervals[intervals.length - 1];

  if (!lastInterval.total) {
    return null;
  }
  return lastInterval.total;
};

export const useCurrentInterval = () => {
  const { id } = useParams();
  const { data: intervalsData, isFetching: isLoadingIntervals } =
    useGetFwAppointmentIntervals(id!);

  const isIntervalPaused = useGetIsIntervalPaused(
    intervalsData?.appointment_intervals || []
  );

  const totalTime = useGetTotalIntervalTime(
    intervalsData?.appointment_intervals || []
  );

  const currentInterval = useGetCurrentInterval(
    intervalsData?.appointment_intervals || []
  );

  const isLastIntervalStopped = checkIfLastIntervalIsStopped(
    intervalsData?.appointment_intervals || []
  );

  return {
    totalTime,
    isLastIntervalStopped,
    currentInterval,
    isIntervalPaused,
    isLoadingIntervals,
  };
};

export const useGetAppointmentReport = (
  appointmentId: string,
  isEnabled: boolean
) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_FW_SINGLE_APPOINTMENT_REPORT, appointmentId],
    queryFn: () => {
      return getSingleAppointmentReport(appointmentId);
    },
    enabled: !!appointmentId && isEnabled,
  });
