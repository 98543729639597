import { useQuery, useQueryClient } from 'react-query';
import {
  getQuotationReportsCurrent,
  getQuotationReportsHistorical,
} from 'services/Quotation/QuotationService';
import {
  getSalesOrderReportsCurrent,
  getSalesOrderReportsHistorical,
} from 'services/SalesOrder/SalesOrderService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetFwAppointmentReportsCurrent = (
  appointmentId: string,
  page: number,
  perPage: number
) => {
  const queryClient = useQueryClient();
  const singleAppointment = queryClient.getQueryData<any>([
    ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
    appointmentId,
  ]);

  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_FW_APPOINTMENT_REPORTS_CURRENT,
      appointmentId,
      page,
      perPage,
    ],
    queryFn: () => {
      if (singleAppointment?.appointment?.sales_order) {
        return getSalesOrderReportsCurrent(
          singleAppointment?.appointment?.sales_order_id,
          page,
          perPage
        );
      } else {
        return getQuotationReportsCurrent(
          singleAppointment?.appointment?.quotation_id,
          page,
          perPage
        );
      }
    },
  });
};

export const useGetFwAppointmentReportsHistorical = (
  appointmentId: string,
  page: number,
  perPage: number
) => {
  const queryClient = useQueryClient();
  const singleAppointment = queryClient.getQueryData<any>([
    ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
    appointmentId,
  ]);

  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_FW_APPOINTMENT_REPORTS_HISTORICAL,
      appointmentId,
      page,
      perPage,
    ],
    queryFn: () => {
      if (singleAppointment?.appointment?.sales_order) {
        return getSalesOrderReportsHistorical(
          singleAppointment?.appointment?.sales_order_id,
          page,
          perPage
        );
      } else {
        return getQuotationReportsHistorical(
          singleAppointment?.appointment?.quotation_id,
          page,
          perPage
        );
      }
    },
  });
};
