import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'utils/toast';
import { requestPasswordReset } from 'services/User/UserService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { LOCAL_STORAGE_LAST_RESET_PASSWORD_TIMESTAMP } from 'services/api/constants';
import { useTranslation } from 'react-i18next';

export const useRequestPasswordReset = () => {
  const { t } = useTranslation();

  const mutation = useMutation((email: string) => requestPasswordReset(email), {
    onSuccess: () => {
      toast.success(t('Password reset email sent'), {
        toastId: ReactMutationKeys.REQUEST_PASSWORD_RESET,
      });
      localStorage.setItem(
        LOCAL_STORAGE_LAST_RESET_PASSWORD_TIMESTAMP,
        Date.now().toString()
      );
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.REQUEST_PASSWORD_RESET,
      });
    },
    mutationKey: ReactMutationKeys.REQUEST_PASSWORD_RESET,
  });

  const sendRequest = (email: string) => {
    const now = Date.now();
    const lastRequestTime = localStorage.getItem(
      LOCAL_STORAGE_LAST_RESET_PASSWORD_TIMESTAMP
    );
    const timeElapsed = lastRequestTime
      ? now - parseInt(lastRequestTime)
      : Infinity;

    if (
      !lastRequestTime ||
      timeElapsed >= 60000 ||
      isNaN(parseInt(lastRequestTime))
    )
      // If last request time is not set or more than one minute has passed
      mutation.mutate(email);
    else
      toast.info(
        `${t(
          'A password reset request has already been sent. Please try again in one minute'
        )}.`,
        {
          toastId: ReactMutationKeys.REQUEST_PASSWORD_RESET,
        }
      );
  };

  return {
    mutate: sendRequest,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
  };
};
