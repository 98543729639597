import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { editUserData, uploadProfilePicture } from 'services/User/UserService';
import { store } from 'store/store';
import { setUserPicture } from 'store/User/actions/user';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { useTranslation } from 'react-i18next';
import { submitFeedback } from 'services/Feedback/FeedbackService';

interface IUseUploadProfilePictureParams {
  files: any;
}
interface IUseUserParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  currentPassword?: string;
  newPassword?: string;
  companyId: string;
}

export const useUploadProfilePicture = () =>
  useMutation(
    (params: IUseUploadProfilePictureParams) => {
      const fileFormData = new FormData();
      fileFormData.append('image', params.files);
      return uploadProfilePicture(fileFormData);
    },
    {
      onSuccess: (data) => {
        // Success
        store.dispatch(setUserPicture(data.image_url));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_PROFILE_PICTURE,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_PROFILE_PICTURE,
    }
  );

export const useEditUserData = () =>
  useMutation(
    (params: IUseUserParams) =>
      editUserData(
        params.companyId,
        params.firstName,
        params.lastName,
        params.email,
        params.currentPassword,
        params.newPassword
      ),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: any) => {
        const errorMessage =
          error.code === 'ERR_BAD_REQUEST' ? 'Wrong password.' : error.message;
        toast.error(errorMessage, {
          toastId: ReactMutationKeys.EDIT_USER_DATA,
        });
      },
      onSettled: (data) => {
        // On Settled
      },
      mutationKey: ReactMutationKeys.EDIT_USER_DATA,
    }
  );

export const useSubmitFeedback = () => {
  const { t } = useTranslation();
  return useMutation((text: string) => submitFeedback(text), {
    onSuccess: () => {
      toast.success(t('Feedback submitted successfully'), {
        className: ReactMutationKeys.SUBMIT_FEEDBACK,
      });
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.SUBMIT_FEEDBACK,
      });
    },
    mutationKey: ReactMutationKeys.SUBMIT_FEEDBACK,
  });
};
