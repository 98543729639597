import { H3 } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  StyledCustomSelect,
} from './CustomerTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { createInvoiceCustomerTabSchema } from './validation';
import Loader from 'components/Loader/Loader';
import PartialInvoiceModal from './PartialInvoiceModal/PartialInvoiceModal';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { useIsFetching } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { Dispatch, SetStateAction } from 'react';
import { IPendingInvoiceLine } from '../constants';
import { ICreateInvoiceCustomerDetails } from './constants';
import Tooltip from 'components/Tooltip/Tooltip';

export interface ICustomerTabProps {
  onForward: () => void;
  customerDetails: ICreateInvoiceCustomerDetails;
  setCustomerDetails: Dispatch<SetStateAction<ICreateInvoiceCustomerDetails>>;
  selectedPartialIds: number[];
  handleToggleSelectPartials: (id: number) => void;
  handleSelectAllPartials: () => void;
  pendingInvoiceLines: IPendingInvoiceLine[];
  isDownPaymentInvoiceCreated: boolean;
}

const CustomerTab = ({
  onForward,
  customerDetails,
  setCustomerDetails,
  selectedPartialIds,
  handleToggleSelectPartials,
  handleSelectAllPartials,
  pendingInvoiceLines,
  isDownPaymentInvoiceCreated,
}: ICustomerTabProps) => {
  const { t } = useTranslation();
  const titleOptions = useGetTitleOptions();
  const countryOptions: Option[] = useGetCountryOptions();
  const [isPartialInvoiceOpen, setIsPartialInvoiceOpen] =
    useState<boolean>(false);

  const isLoading = !!useIsFetching({
    queryKey: ReactQueryKeys.GET_TWINFIELD_CUSTOMERS_BY_POSTAL_CODE,
  });

  return (
    <Container>
      <FirstRow>
        <H3>{t('Customer details')}</H3>
        {isDownPaymentInvoiceCreated && (
          <Button
            width={'200rem'}
            label={t('Partial invoice')}
            secondary
            onClick={() => setIsPartialInvoiceOpen(true)}
          />
        )}
      </FirstRow>
      {isLoading ? <Loader positionType="absolute" /> : null}
      <Formik
        enableReinitialize
        initialValues={customerDetails}
        validationSchema={createInvoiceCustomerTabSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onForward}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'41rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Input
                  errorMessage={touched['last_name'] ? errors['last_name'] : ''}
                  height={'41rem'}
                  name="last_name"
                  placeholder={t('Last name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('last_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
              </Row>
              <Row>
                <Input
                  errorMessage={touched['phone'] ? errors['phone'] : ''}
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Field
                  errorMessage={touched['title'] ? errors['title'] : ''}
                  height={'41rem'}
                  width="50%"
                  name="title"
                  options={titleOptions}
                  component={StyledCustomSelect}
                  placeholder={t('Title')}
                  isMulti={false}
                  onSelect={(value: string) => setFieldValue('title', value)}
                  disabled
                />
              </Row>
              <Row>
                <Input
                  errorMessage={touched['email'] ? errors['email'] : ''}
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Field
                  errorMessage={touched['country'] ? errors['country'] : ''}
                  height={'41rem'}
                  name="country"
                  width="50%"
                  options={countryOptions}
                  component={StyledCustomSelect}
                  placeholder={t('Country Code')}
                  isMulti={false}
                  onSelect={(value: string) => setFieldValue('country', value)}
                  disabled
                />
              </Row>
              <Row>
                <Input
                  errorMessage={
                    touched['company_name'] ? errors['company_name'] : ''
                  }
                  height={'41rem'}
                  name="company_name"
                  placeholder={t('Company Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('company_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Input
                  errorMessage={
                    touched['vat_number'] ? errors['vat_number'] : ''
                  }
                  height={'41rem'}
                  name="vat_number"
                  placeholder={t('Company VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('vat_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
              </Row>
              <Row>
                <Input
                  errorMessage={touched['city'] ? errors['city'] : ''}
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Input
                  errorMessage={touched['code'] ? errors['code'] : ''}
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
              </Row>
              <Row>
                <Input
                  errorMessage={touched['street'] ? errors['street'] : ''}
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
                <Input
                  errorMessage={
                    touched['house_number'] ? errors['house_number'] : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '50%',
                  }}
                  disabled
                />
              </Row>
              <AlignRow>
                <Tooltip
                  content={
                    !selectedPartialIds.length
                      ? t('Select at least 1 line to invoice')
                      : null
                  }
                >
                  <Button
                    width={'200rem'}
                    onClick={() => {
                      submitForm();
                      if (isValid) {
                        setCustomerDetails(values);
                        onForward();
                      }
                    }}
                    label={t('Next')}
                    primary
                    disabled={isLoading || !selectedPartialIds.length}
                  />
                </Tooltip>
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
      <PartialInvoiceModal
        isOpen={isPartialInvoiceOpen}
        setIsOpen={setIsPartialInvoiceOpen}
        selectedPartialIds={selectedPartialIds}
        handleToggleSelectPartials={handleToggleSelectPartials}
        handleSelectAllPartials={handleSelectAllPartials}
        pendingInvoiceLines={pendingInvoiceLines}
      />
    </Container>
  );
};

export default CustomerTab;
