import { COLORS, H3, H4 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ButtonContainer,
  Col,
  Count,
  Number,
  NumberContainer,
  RedCount,
  SelectInputWrapper,
  TitleContainer,
  TitleWrapper,
} from './InvoicesCard.styled';
import { Container } from './InvoicesCard.styled';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { useGetSalesInvoicesAnalytic } from '../hooks';
import { formatNumberCompact } from 'utils/numberFormatter';
import { RoutesConfig } from 'navigation/routes';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Icon from 'components/Icon/Icon';
import { FileText } from '@phosphor-icons/react';
import MoreButton from 'components/MoreButton/MoreButton';
import { IAnalyticsNavigationState } from '../types';

interface IInvoicesCardProps {
  dateOptions: Option[];
  selectedInvoices: Option;
  setSelectedInvoices: (invoice: Option) => void;
}

export const InvoicesCard = ({
  dateOptions,
  selectedInvoices,
  setSelectedInvoices,
}: IInvoicesCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { data: salesInvoices } = useGetSalesInvoicesAnalytic(selectedInvoices);

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={FileText} size={30} color={COLORS.PRIMARY} />
          <H3>{t('Invoices')}</H3>
        </TitleWrapper>
        <SelectInputWrapper>
          <Select
            defaultValue={selectedInvoices ? selectedInvoices : dateOptions[1]}
            name="activeFilter"
            placeholder={''}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: Option) => setSelectedInvoices(e)}
            options={dateOptions}
          />
        </SelectInputWrapper>
      </TitleContainer>
      <NumberContainer>
        <Number>
          <H4>{t('Down payment')}</H4>
          <Col>
            <Count>
              {salesInvoices?.down_payment_invoices === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  salesInvoices?.down_payment_invoices.total_amount
                )}`
              )}
            </Count>
            <Count>
              {salesInvoices?.down_payment_invoices === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(salesInvoices?.down_payment_invoices.count)
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('Sales invoice')}</H4>
          <Col>
            <Count>
              {salesInvoices?.all_sales_invoices === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  salesInvoices?.all_sales_invoices.total_amount
                )}`
              )}
            </Count>
            <Count>
              {salesInvoices?.all_sales_invoices === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(salesInvoices?.all_sales_invoices.count)
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('Payments')}</H4>
          <Col>
            <Count>
              {salesInvoices?.all_payments === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  salesInvoices?.all_payments.total_amount
                )}`
              )}
            </Count>
            <Count>
              {salesInvoices?.all_payments === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(salesInvoices?.all_payments.count)
              )}
            </Count>
          </Col>
        </Number>
        <Number>
          <H4>{t('Credit invoice')}</H4>
          <Col>
            <RedCount>
              {salesInvoices?.credit_invoices === undefined ? (
                <Spinner />
              ) : (
                `${currencySymbol}${formatNumberCompact(
                  salesInvoices?.credit_invoices.total_amount
                )}`
              )}
            </RedCount>
            <RedCount>
              {salesInvoices?.credit_invoices === undefined ? (
                <Spinner />
              ) : (
                formatNumberCompact(salesInvoices?.credit_invoices.count)
              )}
            </RedCount>
          </Col>
        </Number>
      </NumberContainer>
      <ButtonContainer
        onClick={() => {
          const analyticsNavigationState: IAnalyticsNavigationState = {
            dateIntervalOption: selectedInvoices,
          };
          navigate(RoutesConfig.Invoices.fullPath, {
            state: analyticsNavigationState,
          });
        }}
      >
        <MoreButton />
      </ButtonContainer>
    </Container>
  );
};
