import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500rem;
  align-items: center;
`;

export const SingleCreditInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  margin-top: ${marginLg};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatusLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  color: ${COLORS.PRIMARY};
`;

export const InvoiceDescriptionLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: ${marginXs};
`;

export const RowSpaceBetweenMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};
`;

export const SmallColumnDiv = styled.div`
  position: relative;
  height: 60rem;
  width: 200rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SmallColumnDivLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
`;

export const SmallColumnDivLabelValue = styled.label`
  font-weight: 500;
  font-size: 22rem;
  line-height: 22rem;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;

export const NoContentLabel = styled.label`
  margin-top: 100rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;
