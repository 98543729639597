import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

interface IStyledProps {
  $width: string;
  $isSubFilter: boolean;
}

export const Row = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 20rem;
  align-items: center;
`;

export const SelectWrapper = styled.div<IStyledProps>`
  min-width: ${(props: IStyledProps) => props.$width};
  width: ${(props: IStyledProps) => props.$width};
`;

export const AnyWrapper = styled.div`
  min-width: ${(props: IStyledProps) => props.$width};
  width: ${(props: IStyledProps) => props.$width};
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
`;

export const FirstCell = styled.div`
  min-width: 100rem;
  width: 100rem;
  display: flex;
  justify-content: flex-end;

  ${respondTo.bigTablet`
    width: 120rem;
    min-width: 120rem;
  `}
`;

export const Text = styled.span`
  display: inline-block;
  color: ${COLORS.BLACK};
  font-size: 18rem;
`;
