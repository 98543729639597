import { COLORS } from 'assets/styled';
import {
  StyledSkeleton,
  SkeletonRow,
  SkeletonTd,
} from './TableSkeleton.styled';

interface ITableSkeleton {
  numRows: number;
  numColumns: number;
}

const createSkeletonRow = (numColumns: number) => (
  <SkeletonRow>
    {Array.from(new Array(numColumns)).map((_, index) => (
      <StyledSkeleton
        key={index}
        animation="wave"
        variant="rounded"
        height={68}
        sx={{
          backgroundColor: `${COLORS.BACKGROUND_COLOR}`,
        }}
      />
    ))}
  </SkeletonRow>
);

const TableSkeleton = ({ numRows, numColumns }: ITableSkeleton) => {
  return (
    <>
      {numRows > 0 && (
        <tbody>
          {Array.from(new Array(numRows)).map((_, rowIndex) => (
            <tr key={rowIndex}>
              <SkeletonTd>{createSkeletonRow(numColumns)}</SkeletonTd>
            </tr>
          ))}
        </tbody>
      )}
    </>
  );
};

export default TableSkeleton;
