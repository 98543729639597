import { FormikErrors, FormikTouched, getIn } from 'formik';
import * as Yup from 'yup';

export const getFormikErrorMesssage = (
  index: number,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  areAllFieldsTouched: boolean,
  getInString: string
) => {
  return (touched.invoiceLines &&
    getIn(touched.invoiceLines[index], getInString)) ||
    areAllFieldsTouched
    ? errors.invoiceLines && getIn(errors.invoiceLines[index], getInString)
    : '';
};

export const createCreditInvoiceSchema = () => {
  return Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
    correctiveAmount: Yup.number()
      .typeError('Corrective amount must be a number')
      .test(
        'twoDecimal',
        'Corrective amount can only have two decimal places',
        (value) => {
          if (typeof value !== 'number' || !Number.isFinite(value)) {
            return false;
          }
          const roundedValue = Number(value.toFixed(2));
          return roundedValue === value;
        }
      )
      .required('Corrective amount is required'),
    reference: Yup.string()
      .optional()
      .max(80, 'Reference should be less than 80 characters'),
    detailedReason: Yup.string().required('Detailed reason is required'),
  });
};
