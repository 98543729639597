import { Route, Routes } from 'react-router-dom';
import { SinglePurchaseCreditInvoiceRoutes } from './SinglePurchaseCreditInvoice.routes';
import SinglePurchaseCreditInvoiceGeneralPage from 'pages/Manager/SingleInvoice/SinglePurchaseCreditInvoice/SinglePurchaseCreditInvoiceGeneralPage/SinglePurchaseCreditInvoiceGeneralPage';
import SinglePurchaseCreditInvoicePaymentsPage from 'pages/Manager/SingleInvoice/SinglePurchaseCreditInvoice/SinglePurchaseCreditInvoicePaymentsPage/SinglePurchaseCreditInvoicePaymentsPage';
import SinglePurchaseCreditInvoiceFilesPage from 'pages/Manager/SingleInvoice/SinglePurchaseCreditInvoice/SinglePurchaseCreditInvoiceFilesPage/SinglePurchaseCreditInvoiceFilesPage';
import SinglePurchaseCreditInvoicePurchaseInvoicesPage from 'pages/Manager/SingleInvoice/SinglePurchaseCreditInvoice/SinglePurchaseCreditInvoicePurchaseInvoicePage/SinglePurchaseCreditInvoicePurchaseInvoicePage';

const SinglePurchaseCreditInvoiceRouter = () => {
  return (
    <Routes>
      <Route
        path={
          SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoiceGeneral
            .path
        }
        element={<SinglePurchaseCreditInvoiceGeneralPage />}
      />
      <Route
        path={
          SinglePurchaseCreditInvoiceRoutes
            .SinglePurchaseCreditInvoicePurchaseInvoice.path
        }
        element={<SinglePurchaseCreditInvoicePurchaseInvoicesPage />}
      />
      <Route
        path={
          SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoicePayments
            .path
        }
        element={<SinglePurchaseCreditInvoicePaymentsPage />}
      />
      <Route
        path={
          SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoiceFiles
            .path
        }
        element={<SinglePurchaseCreditInvoiceFilesPage />}
      />
    </Routes>
  );
};

export default SinglePurchaseCreditInvoiceRouter;
