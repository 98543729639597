import Button from 'components/Button/Button';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import moment from 'moment';
import { RoutesConfig } from 'navigation/routes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  useGetSingleQuotation,
  useGetSingleQuotationSignature,
  useUploadQuotationSignature,
} from '../hooks';
import {
  ButtonContainer,
  Card,
  Container,
  Heading,
  Label,
  TopContentContainer,
  LabelKey,
  LabelWrapper,
  NonEditModeLabelWrapper,
  RowHalf,
  RowWrapper,
  SignatureImage,
  SignatureWrapper,
  Wrapper,
} from './SingleQuotationNewSignature.styled';
import { Formik } from 'formik';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { BASE_64_PREFIX } from '../constants';
import { isQuotationClosed } from '../helpers';
import DatePicker from 'components/DatePicker/DatePicker';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { DiscardModal } from 'components/Modal/DiscardModal/DiscardModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

const SingleQuotationNewSignature = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();
  const sigRef = React.useRef() as React.MutableRefObject<any>;
  const { data } = useGetSingleQuotation(id!);
  const { data: quotationSignature, isSuccess: signatureFetched } =
    useGetSingleQuotationSignature(id!);
  const {
    mutate: uploadQuotationSignature,
    isSuccess,
    isLoading: uploadSignatureIsLoading,
  } = useUploadQuotationSignature(id!);
  const [signature, setSignature] = useState(null);
  const [isToggleEditModeModalOpen, setIsToggleEditModeModalOpen] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  useEffect(() => {
    if (signatureFetched && data?.signature_uploaded && sigRef.current) {
      sigRef.current.fromDataURL(
        `${BASE_64_PREFIX}${quotationSignature.signaure}`
      );
      setSignature(sigRef.current.toDataURL());
    }
  }, [signatureFetched, isEditMode]);

  useEffect(() => {
    if (isSuccess) {
      navigate(
        RoutesConfig.SingleQuotation.fullPath.replace(
          ':id/*',
          data?.id.toString()
        ),
        {
          state: { signatureAdded: true },
        }
      );
    }
  }, [isSuccess]);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const handleAddSignature = async (values: any) => {
    const url = sigRef.current?.getCanvas().toDataURL('image/png');
    const blob = await fetch(url).then((r) => r.blob());
    if (url) {
      uploadQuotationSignature({
        file: blob,
        quotationId: id || '',
        full_name: values.name,
        date: `${values.date} 00:00:00`,
      });
    }
  };

  return (
    <Container>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={handleAddSignature}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            name: data?.signature_uploaded
              ? (quotationSignature?.fullname as string)
              : `${data?.customer?.name} ${data?.customer?.last_name}`,
            date: data?.signature_uploaded
              ? moment.utc(quotationSignature?.date).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
          }}
          validateOnMount={true}
        >
          {({
            errors,
            touched,
            setFieldValue,
            values,
            handleBlur,
            submitForm,
          }) => {
            return (
              <Wrapper>
                <Heading>{t('Customer Signature')}</Heading>

                <TopContentContainer>
                  <RowWrapper>
                    <LabelWrapper>
                      <LabelKey>{t('Quotation')}</LabelKey>
                      <Label>{data?.number}</Label>
                    </LabelWrapper>
                    <LabelWrapper>
                      <LabelKey>{t('Total amount')}</LabelKey>
                      <Label>{`${currencySymbol}${data?.total_amount?.toFixed(
                        2
                      )}`}</Label>
                    </LabelWrapper>
                  </RowWrapper>
                  <RowWrapper>
                    <RowHalf>
                      <LabelKey>{t('Name')}</LabelKey>
                      {isEditMode ? (
                        <FormikInput
                          pwId="name-field"
                          type="text"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('name', value);
                            handleBlur(e);
                          }}
                          errorMessage={touched['name'] ? errors['name'] : ''}
                          height={'41rem'}
                          name="name"
                          wrapperStyles={{
                            width: '300rem',
                          }}
                        />
                      ) : (
                        <NonEditModeLabelWrapper>
                          {values.name}
                        </NonEditModeLabelWrapper>
                      )}
                    </RowHalf>
                    <RowHalf>
                      <LabelKey>{t('Date')}</LabelKey>
                      {isEditMode ? (
                        <DatePicker
                          pwId="date-field"
                          date={moment(values?.date)}
                          setDate={(newValue: any) =>
                            setFieldValue(
                              'date',
                              newValue?.format('YYYY-MM-DD')
                            )
                          }
                        />
                      ) : (
                        <NonEditModeLabelWrapper>
                          {values.date}
                        </NonEditModeLabelWrapper>
                      )}
                    </RowHalf>
                  </RowWrapper>
                </TopContentContainer>

                <SignatureWrapper
                  data-testid="signature-canvas"
                  $darkMode={darkMode}
                >
                  {isEditMode ? (
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ className: 'signature' }}
                      ref={sigRef}
                      onEnd={handleSignatureEnd}
                      clearOnResize={false}
                    />
                  ) : quotationSignature &&
                    Object.keys(quotationSignature).length ? (
                    <SignatureImage
                      src={`${BASE_64_PREFIX}${quotationSignature?.signaure}`}
                      alt="signature"
                    />
                  ) : null}
                </SignatureWrapper>
                <ButtonContainer>
                  {isEditMode ? (
                    <>
                      <Button
                        width="200rem"
                        secondary
                        label={t('Cancel')}
                        onClick={() => setIsDiscardModalOpen(true)}
                      />
                      <Button
                        width="200rem"
                        disabled={
                          !signature ||
                          !values.date ||
                          !values.name ||
                          uploadSignatureIsLoading
                        }
                        primary
                        label={t('Confirm')}
                        onClick={() => setIsConfirmModalOpen(true)}
                      />
                    </>
                  ) : (
                    <Button
                      disabled={isQuotationClosed(data)}
                      width="200rem"
                      primary
                      label={
                        quotationSignature &&
                        Object.keys(quotationSignature).length
                          ? t('Edit')
                          : t('Add signature')
                      }
                      onClick={() => {
                        if (
                          quotationSignature &&
                          Object.keys(quotationSignature).length
                        ) {
                          setIsToggleEditModeModalOpen(true);
                        } else {
                          setIsEditMode(true);
                        }
                      }}
                    />
                  )}
                </ButtonContainer>
                <ConfirmModal
                  title={t('New Signature')}
                  description={`${t(
                    'Are you sure you want to create a New Signature'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => {
                    handleAddSignature(values);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
                <DiscardModal
                  title={t('Discard Signature')}
                  description={`${t(
                    'Are you sure you want to discard this Signature'
                  )}?`}
                  isOpen={isDiscardModalOpen}
                  setIsOpen={setIsDiscardModalOpen}
                  onDiscard={() => {
                    setIsEditMode(false);
                    setIsDiscardModalOpen(false);
                  }}
                  onCancel={() => setIsDiscardModalOpen(false)}
                />
              </Wrapper>
            );
          }}
        </Formik>
      </Card>
      <YesOrNoModal
        pwId="yes-or-no-add-new-signature-modal"
        isOpen={isToggleEditModeModalOpen}
        setIsOpen={setIsToggleEditModeModalOpen}
        title={''}
        description={
          t(
            'A signature already exists. Do you want to add a new signature? This will clear the existing signature'
          ) + '?'
        }
        onYes={() => {
          setIsEditMode(true);
          setIsToggleEditModeModalOpen(false);
          setTimeout(() => clearSignature(), 100);
        }}
        onNo={() => setIsToggleEditModeModalOpen(false)}
      />
    </Container>
  );
};

export default SingleQuotationNewSignature;
