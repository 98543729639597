import { useNavigate, useParams } from 'react-router-dom';
import {
  CommentsContainer,
  NoContentLabel,
  RightAlign,
} from './FwSingleAppointmentCommentsPage.styled';
import { FW_APPOINTMENT_COMMENTS_PER_PAGE } from './constants';
import { useGetFwAppointmentComments } from './hooks';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FwSingleAppointmentRoutes } from 'navigation/FwSingleAppointment/FwSingleAppointment.routes';
import CommentCard from 'components/Cards/CommentCard/CommentCard';
import { useGetSingleFwAppointment } from '../hooks';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';
import { Plus } from '@phosphor-icons/react';

const FwSingleAppointmentCommentsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchNextPage, isFetching, data, hasNextPage } =
    useGetFwAppointmentComments(FW_APPOINTMENT_COMMENTS_PER_PAGE, id!);

  const { data: appointmentData, isLoading } = useGetSingleFwAppointment(id!);
  const hasReport = appointmentData?.appointment?.has_report;
  const canManipulateComments = !hasReport && !isLoading;

  const dataRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const comments = data?.pages.map((page) => page.comments).flat();

  if (!comments?.length) {
    return (
      <CommentsContainer onScroll={onScroll} ref={dataRef}>
        {canManipulateComments ? (
          <RightAlign>
            <Button
              label={t('Add another comment')}
              icon={Plus}
              sizeIcon={15}
              colorIcon={COLORS.PRIMARY}
              weightIcon="bold"
              link
              onClick={() =>
                navigate(
                  `/fw/appointments/${id!}/${
                    FwSingleAppointmentRoutes.FwSingleAppointmentNewComment.path
                  }`
                )
              }
              fontSize="18rem"
            />
          </RightAlign>
        ) : null}
        {!isFetching && (
          <NoContentLabel>
            {t('There are currently no comments on this Appointment')}
          </NoContentLabel>
        )}
      </CommentsContainer>
    );
  }

  return (
    <CommentsContainer onScroll={onScroll} ref={dataRef}>
      <RightAlign>
        <Button
          label={t('Add another comment')}
          icon={Plus}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          link
          onClick={() =>
            navigate(
              `/fw/appointments/${id!}/${
                FwSingleAppointmentRoutes.FwSingleAppointmentNewComment.path
              }`
            )
          }
          fontSize="18rem"
        />
      </RightAlign>
      {comments.map((item: any, index: number) => {
        return (
          <CommentCard
            key={`${index}`}
            title={item.headline}
            name={`${item.user.name} ${item.user.last_name}`}
            type={item.is_internal ? t('Internal') : t('External')}
            date={item.created_at}
            description={item.description}
            imageUrl={item.user.image_url}
          />
        );
      })}
    </CommentsContainer>
  );
};

export default FwSingleAppointmentCommentsPage;
