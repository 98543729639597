import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import apiClient, { authApiClient } from '../api/apiService';
import openApiClient from 'services/api/openApiService';
import { SortDirection } from 'components/Table/constants';
import i18n from 'providers/i18n/i18n';
const BASE_URL = '/v1/users/';

export const login = async (email: string, password: string): Promise<any> => {
  const { data } = await authApiClient.post<any>('/login', {
    email,
    password,
  });

  return data.data;
};

export const requestPasswordReset = async (email: string): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const url = `${BASE_URL}request-password-reset`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await openApiClient.post<any>(fullUrl, { email });
  return data.data;
};

export const resetPassword = async (
  newPassword: string,
  confirmPassword: string,
  token: string,
  companyId: string
): Promise<any> => {
  const url = `${BASE_URL}reset-password`;
  const { data } = await openApiClient.post<any>(url, {
    password: newPassword,
    password_confirmation: confirmPassword,
    token,
    company_id: companyId,
  });

  return data.data;
};

export const fetchUserInfo = async (): Promise<any> => {
  const url = `${BASE_URL}me`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const logout = async (): Promise<any> => {
  const url = `${BASE_URL}logout`;
  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const uploadProfilePicture = async (pictureFormData: FormData) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `image`;
  const { data } = await apiClient.post(url, pictureFormData, { headers });

  return data.data;
};

export const editUserData = async (
  companyId: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  currentPassword?: string,
  newPassword?: string
) => {
  const url = BASE_URL + `me`;
  const body = {
    company_id: companyId,
    name: firstName,
    last_name: lastName,
    email,
    current_password: currentPassword,
    new_password: newPassword,
  };
  !firstName && delete body.name;
  !lastName && delete body.last_name;
  !currentPassword && delete body.current_password;
  !newPassword && delete body.new_password;
  !email && delete body.email;

  const { data } = await apiClient.patch(url, body);

  return data.data;
};

export const getUsers = async (
  page?: number,
  perPage?: number,
  searchBy?: string,
  dateFrom?: string,
  dateTo?: string,
  sortDirection?: string,
  activeOnly?: boolean
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
    active_only: activeOnly,
    date_from: dateFrom,
    date_to: dateTo,
    ...(sortDirection != SortDirection.NO_SORT && {
      order: sortDirection,
    }),
  };

  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getUsersById = async (ids: string[]): Promise<any> => {
  const queryParamsObj = {
    user_ids: ids,
  };
  const url = `${BASE_URL}by-ids`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const changeIntroGuideCompletedStatus = async (value: boolean) => {
  const queryParamsObj = {
    intro_guide_completed: value.toString(),
  };
  const url = `${BASE_URL}intro-guide`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.patch(fullUrl);

  return data.data;
};

export const getCompletedIntroGuidePages = async () => {
  const url = `${BASE_URL}guide-pages`;
  const { data } = await apiClient.get(url);

  return data.data;
};

export const addIntroGuideCompletedPage = async (value: string) => {
  const url = `${BASE_URL}guide-pages`;
  const { data } = await apiClient.patch(url, {
    guide_pages: [value],
  });

  return data.data;
};
