import { Formik } from 'formik';
import { COLORS, H5 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ApplyWrapper,
  TimeWrapper,
  WorkersAndResources,
  StyledSelect,
  Cont,
  HorizontalLine,
  FieldLabel,
  CheckboxContainer,
  UsersContainer,
  Scrollable,
  DateTimeWrapper,
  TimeIntervalErrorMessageCont,
  TimeIntervalErrorMessage,
  ArrivalTimeWrapper,
  ArrivalTimeInner,
  ArrivalTime,
  ArrivalTimeText,
  DateTimeSection,
  HalfCont,
  AltTimeWrapper,
  DateLabel,
} from './ServiceTab.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { useLocation } from 'react-router-dom';
import {
  useCreateAppointment,
  useGetSalesOrdersInfinite,
  useGetWorkersInfinite,
  useGetResourcesInfinite,
  useGetUsersInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import {
  AddEmailNoteModal,
  IEmailDetails,
} from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  RESOURCES_PER_PAGE,
  SALES_ORDERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from './constants';
import { validateField } from 'utils/validation';
import { validateSalesOrderField } from './validation';
import { USERS_PER_PAGE } from '../GeneralTab/constants';
import { Option } from 'components/Select/type';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateAppointmentNavigationState,
  useGetSalesOrdersOptions,
  useGetSelectedNewAppointmentEntity,
  useHandleSelectOptions,
} from '../hooks';
import DatePicker from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from '../validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { getNewAppointmentEmailDetails } from '../helpers';
import { Minus, WarningCircle } from '@phosphor-icons/react';

export interface IServiceTabProps {
  isNewAppointmentModalOpen: boolean;
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  closeAppointmentModal: () => void;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  arrival_to?: string;
  arrival_from?: string;
  selectedUsersOptions: Option[];
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
}

const ServiceTab = ({
  isNewAppointmentModalOpen,
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  closeAppointmentModal,
  startDate,
  endDate,
  time_from,
  time_to,
  arrival_to,
  arrival_from,
  selectedUsersOptions,
  selectedWorkersOptions,
  selectedResourcesOptions,
}: IServiceTabProps) => {
  const { t } = useTranslation();
  const { state }: { state: ICreateAppointmentNavigationState } = useLocation();

  const [salesOrderSearchBy, setSalesOrderSearchBy] = useState<string>('');
  const debouncedSalesOrderSearchBy = useDebounce(salesOrderSearchBy);
  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);
  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);
  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const [formikValues, setFormikValues] = useState<ICreateAppointmentDTO>();

  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);

  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );
  const { mutate, isSuccess, isLoading } = useCreateAppointment();

  const createAppointmentIsLoading = !!isMutatingCreateAppointment || isLoading;

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersInfinite(
    SALES_ORDERS_PER_PAGE,
    debouncedSalesOrderSearchBy,
    isNewAppointmentModalOpen
  );

  const salesOrdersOptions = useGetSalesOrdersOptions(salesOrdersData);

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersInfinite(
    USERS_PER_PAGE,
    debouncedUserSearchBy,
    undefined, // dateFrom
    undefined, // dateTo,
    isNewAppointmentModalOpen
  );

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    usersData,
    workersData,
    resourcesData,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  useEffect(() => {
    if (isSuccess) {
      setIsSendEmailModalOpen(false);
      setIsCreatingAppointmentFromNavigationState(false);
      closeAppointmentModal();
    }
  }, [isSuccess]);

  const checkEmailSending = (values: ICreateAppointmentDTO) => {
    setFormikValues(values);
    setIsSendEmailModalOpen(true);
  };

  const handleCreateAppointment = (
    values: ICreateAppointmentDTO,
    shouldSendEmail: boolean
  ) => {
    const createAppointmentData: ICreateAppointmentDTO = {
      ...(values?.sales_order_id && {
        sales_order_id: values?.sales_order_id,
      }),
      date_from: `${values.startDate} ${values.time_from}`,
      date_to: `${values.endDate} ${values.time_to}`,
      appointment_type_id: values.appointment_type_id,
      description: values.description,
      users_ids: values?.users_ids,
      workers_ids: values?.workers_ids,
      resources_ids: values?.resources_ids,
      confirmed: isConfirmed,
      ...(values.arrival_from && {
        arrival_from: `${values.startDate} ${values.arrival_from}`,
      }),
      ...(values.arrival_to && {
        arrival_to: `${values.startDate} ${values.arrival_to}`,
      }),
    };
    mutate({ createAppointmentData, shouldSendEmail, note: '', files: [] });
  };

  const { setSelectedSalesOrderId, selectedSalesOrder } =
    useGetSelectedNewAppointmentEntity(state);

  const emailDetails: IEmailDetails | undefined = getNewAppointmentEmailDetails(
    undefined,
    selectedSalesOrder
  );

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // onSubmit
        }}
        initialValues={{
          sales_order_id:
            isCreatingAppointmentFromNavigationState && state?.sales_order_id
              ? state?.sales_order_id
              : '',
          startDate: startDate || '',
          endDate: endDate || '',
          date_from: '',
          date_to: '',
          time_to: time_to?.split('+')[0] || '',
          time_from: time_from?.split('+')[0] || '',
          arrival_to: arrival_to?.split('+')[0] || '',
          arrival_from: arrival_from?.split('+')[0] || '',
          appointment_type_id: '4',
          description: '',
          users_ids: selectedUsersOptions.map((user) => user.id) || [],
          resources_ids:
            selectedResourcesOptions.map((resource) => resource.id) || [],
          workers_ids: selectedWorkersOptions.map((worker) => worker.id) || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldError,
          values,
          handleBlur,
          isValid,
        }) => {
          return (
            <Container>
              <Scrollable>
                <DateTimeSection>
                  <H5>{t('DATE & TIME')}</H5>
                  <DateLabel>{t('From')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'startDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              startDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-from-select"
                        time={moment(values?.time_from, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            values.time_to,
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_from',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <DateLabel>{t('To')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'endDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              endDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-to-select"
                        time={moment(values?.time_to, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            values.time_from,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_to',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <TimeIntervalErrorMessageCont>
                    <TimeIntervalErrorMessage>
                      {errors.time_to}
                    </TimeIntervalErrorMessage>
                  </TimeIntervalErrorMessageCont>
                  <ArrivalTimeWrapper>
                    <ArrivalTimeInner>
                      <ArrivalTime>
                        <ArrivalTimeText
                          onClick={() => setShowArrivalTime(true)}
                        >
                          {t('Arrival time')}
                        </ArrivalTimeText>
                        <Tooltip
                          content={
                            t(
                              'This is the expected arrival time that will be communicated with the customer via the appointment email'
                            ) + '.'
                          }
                        >
                          <Icon
                            svg={WarningCircle}
                            color={COLORS.PRIMARY}
                            size={20}
                            weight="regular"
                            marginLeft={'10rem'}
                            pointer={false}
                          />
                        </Tooltip>
                      </ArrivalTime>
                      {showArrivalTime && (
                        <>
                          <AltTimeWrapper>
                            <TimePicker
                              width="100%"
                              pwId="time-from-select"
                              time={moment(values?.arrival_from, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  newValue?.format('HH:mm:ss'),
                                  values.arrival_to,
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_from',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                            <Icon
                              svg={Minus}
                              size={18}
                              weight="bold"
                              color={COLORS.BLACK}
                              marginLeft="5rem"
                              marginRight="5rem"
                            />
                            <TimePicker
                              width="100%"
                              pwId="time-to-select"
                              time={moment(values?.arrival_to, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  values.arrival_from,
                                  newValue?.format('HH:mm:ss'),
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_to',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                          </AltTimeWrapper>
                        </>
                      )}
                    </ArrivalTimeInner>
                    <TimeIntervalErrorMessageCont>
                      <TimeIntervalErrorMessage>
                        {errors.arrival_to}
                      </TimeIntervalErrorMessage>
                    </TimeIntervalErrorMessageCont>
                  </ArrivalTimeWrapper>
                </DateTimeSection>
                <HorizontalLine />
                <FieldLabel>{t('SALES ORDER')}</FieldLabel>
                {isCreatingAppointmentFromNavigationState &&
                state?.sales_order_id ? (
                  <FormikInput
                    pwId="sales-order-id"
                    disabled
                    height={'41rem'}
                    name="sales_order_id"
                    value={state?.sales_order_number}
                    wrapperStyles={{
                      width: '200rem',
                    }}
                  />
                ) : (
                  <HalfCont>
                    <StyledSelect
                      pwId="sales-order-id"
                      name="sales_order_id"
                      options={salesOrdersOptions}
                      onInputChange={(e: string) => {
                        setSalesOrderSearchBy(e);
                      }}
                      component={CustomSelect}
                      placeholder={t('Sales order')}
                      isMulti={false}
                      onSelect={({ value }: any) => {
                        setSelectedSalesOrderId(value);
                        setFieldValue('sales_order_id', value);
                        validateSalesOrderField(value, setFieldError, t);
                      }}
                      validate={(value: string) =>
                        validateField(value, 'Sales order')
                      }
                      errorMessage={errors.sales_order_id}
                      onMenuScrollToBottom={() =>
                        hasNextPageSalesOrders && fetchNextPageSalesOrders()
                      }
                      isLoading={isLoadingSalesOrders}
                      translate={false}
                    />
                  </HalfCont>
                )}
                <HorizontalLine />
                <FieldLabel>{t('DESCRIPTION')}</FieldLabel>
                <FormikInput
                  pwId="description-field"
                  isTextArea
                  height={'60rem'}
                  name="description"
                  placeholder={t('Details about the appointment')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                />
                <HorizontalLine />
                <FieldLabel>{t('USERS')}</FieldLabel>
                <UsersContainer>
                  <HalfCont>
                    <StyledSelect
                      options={userOptions}
                      name="users_ids"
                      onInputChange={(e: string) => {
                        setUserSearchBy(e);
                      }}
                      component={CustomSelect}
                      placeholder={t('Users')}
                      isMulti={true}
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedUserOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageUsers && fetchNextPageUsers()
                      }
                      isLoading={isLoadingUsers}
                      translate={false}
                    />
                  </HalfCont>
                </UsersContainer>
                <HorizontalLine />
                <FieldLabel>{t('WORKERS & RESOURCES')}</FieldLabel>
                <WorkersAndResources>
                  <Cont>
                    <StyledSelect
                      pwId="workers-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => {
                        setWorkerSearchBy(e);
                      }}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti={true}
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedWorkerOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageWorkers && fetchNextPageWorkers()
                      }
                      isLoading={isLoadingWorkers}
                      translate={false}
                    />
                  </Cont>
                  <Cont>
                    <StyledSelect
                      pwId="resources-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => {
                        setResourceSearchBy(e);
                      }}
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedResourceOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageResources && fetchNextPageResources()
                      }
                      isLoading={isLoadingResources}
                      translate={false}
                    />
                  </Cont>
                </WorkersAndResources>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={setIsConfirmed}
                    label={t('The customer already confirmed the appointment')}
                  />
                </CheckboxContainer>
              </Scrollable>
              <ApplyWrapper>
                <Button
                  disabled={createAppointmentIsLoading}
                  onClick={closeAppointmentModal}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  disabled={createAppointmentIsLoading || !isValid}
                  onClick={() => {
                    if (
                      !validateSalesOrderField(
                        values.sales_order_id,
                        setFieldError,
                        t
                      )
                    ) {
                      return;
                    }

                    checkEmailSending(values);
                  }}
                  primary
                  width="200rem"
                  label={t('Save')}
                />
              </ApplyWrapper>
            </Container>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-appointment-confirmation-modal"
        level="SECOND"
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onYes={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onNo={() => {
          handleCreateAppointment(formikValues!, false);
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          isConfirmed
            ? t(
                'Do you want to send the appointment confirmation to the customer'
              ) + '?'
            : t('Do you want to send the appointment offer to the customer') +
              '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={formikValues?.sales_order_id || ''}
        type={AddEmailNoteModalType.SERVICE_CREATION}
        isConfirmedAppointment={isConfirmed}
        newAppointment={formikValues!}
        onCreateAppointmentSuccess={() => {
          isCreatingAppointmentFromNavigationState &&
            setIsCreatingAppointmentFromNavigationState(false);
          closeAppointmentModal();
        }}
        emailDetails={emailDetails}
      />
    </div>
  );
};

export default ServiceTab;
