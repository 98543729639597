import { borderRadiusSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  height: 500rem;
  width: 100%;

  border-radius: ${borderRadiusSm};
  overflow: hidden;
`;
