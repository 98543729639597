import { useNavigate, useParams } from 'react-router-dom';
import {
  ClickableLabel,
  Container,
  CreatedAtValidToDiv,
  CreatedAtValidToLabel,
  CreatedAtValidToLabelValue,
  InvoiceDescriptionLabel,
  LoaderWrapper,
  PenImg,
  ReferenceSpan,
  ReferenceWrapper,
  RightDiv,
  RowSpaceBetween,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  StatusLabel,
} from './SinglePurchaseCreditInvoiceGeneralPage.styled';
import { useGetSinglePurchaseCreditInvoice } from '../hooks';
import {
  IPurchaseInvoiceLine,
  IPurchaseInvoiceLineTableDTO,
} from 'types/Invoice.types';
import { formatPurchaseInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { EditExternalReferenceModal } from 'pages/Manager/SingleInvoice/EditExternalReferenceModal/EditExternalReferenceModal';
import moment from 'moment';
import { tableConfig } from './constants';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { PencilSimple } from '@phosphor-icons/react/dist/ssr';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import Tooltip from 'components/Tooltip/Tooltip';

const EMPTY_VALUE = 0;

const SinglePurchaseCreditInvoiceGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const { data, isLoading } = useGetSinglePurchaseCreditInvoice(
    typeof id === 'string' ? id : ''
  );
  const [isEditReferenceModalOpen, setIsEditReferenceModalOpen] =
    useState<boolean>(false);
  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  let tableData: IPurchaseInvoiceLineTableDTO[] = [];
  if (data?.purchase_invoice?.purchase_invoice_lines) {
    tableData = formatPurchaseInvoiceLineTableData(
      data.purchase_invoice?.purchase_invoice_lines
        .map((purchaseInvoiceLine: IPurchaseInvoiceLine) => {
          return purchaseInvoiceLine;
        })
        .flat(),
      currencySymbol
    );
  }

  return (
    <Container>
      {isLoading ? (
        <LoaderWrapper>
          <Spinner size={50} />
        </LoaderWrapper>
      ) : (
        <>
          <RowSpaceBetween>
            <StatusLabel>
              {t(data?.purchase_invoice?.payment_status?.name)}
            </StatusLabel>
            <RightDiv>
              <ReferenceWrapper>
                {data?.purchase_invoice?.external_id?.length > 10 ? (
                  <Tooltip content={data?.purchase_invoice?.external_id}>
                    <ReferenceSpan
                      $editable={!canEditPurchaseInvoice}
                      onClick={() => {
                        if (canEditPurchaseInvoice)
                          return setIsEditReferenceModalOpen(true);
                      }}
                    >
                      {`${t('External reference')}${
                        data?.purchase_invoice?.external_id
                          ? ` - 
                              ${data?.purchase_invoice?.external_id.substring(
                                0,
                                10
                              )}...`
                          : ''
                      }`}
                    </ReferenceSpan>
                  </Tooltip>
                ) : (
                  <ReferenceSpan
                    $editable={!canEditPurchaseInvoice}
                    onClick={() => {
                      if (canEditPurchaseInvoice)
                        return setIsEditReferenceModalOpen(true);
                    }}
                  >
                    {`${t('External reference')}${
                      data?.purchase_invoice?.external_id
                        ? ' - ' + data?.purchase_invoice?.external_id
                        : ''
                    }`}
                  </ReferenceSpan>
                )}
                {canEditPurchaseInvoice && (
                  <PenImg data-testid="edit-external-reference">
                    <Icon
                      onClick={() => setIsEditReferenceModalOpen(true)}
                      svg={PencilSimple}
                      color={COLORS.PRIMARY}
                      weight="regular"
                      size={15}
                    />
                  </PenImg>
                )}
              </ReferenceWrapper>
            </RightDiv>
          </RowSpaceBetween>
          <RowSpaceBetweenMarginTop>
            <CreatedAtValidToDiv>
              <CreatedAtValidToLabel>{t('Invoice date')}</CreatedAtValidToLabel>
              <CreatedAtValidToLabelValue>
                {moment(data?.sales_invoice?.created_at).format('DD-MM-YYYY')}
              </CreatedAtValidToLabelValue>
            </CreatedAtValidToDiv>
            <CreatedAtValidToDiv>
              <CreatedAtValidToLabel>{t('Valid to')}</CreatedAtValidToLabel>
              <CreatedAtValidToLabelValue>
                {moment(data?.sales_invoice?.valid_to).format('DD-MM-YYYY')}
              </CreatedAtValidToLabelValue>
            </CreatedAtValidToDiv>
          </RowSpaceBetweenMarginTop>
          <InvoiceDescriptionLabel>
            <ClickableLabel
              onClick={() =>
                navigate(
                  SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                    ':id',
                    data?.purchase_invoice?.customer?.id
                  )
                )
              }
            >
              {data?.purchase_invoice?.customer?.name +
                ' ' +
                data?.purchase_invoice?.customer?.last_name}
            </ClickableLabel>
            {data?.purchase_invoice?.description &&
              ` | ${data?.purchase_invoice?.description}`}
          </InvoiceDescriptionLabel>
          <RowSpaceBetweenMarginTop>
            <SmallColumnDiv>
              <SmallColumnDivLabel>
                {t('Corrective amount')}
              </SmallColumnDivLabel>
              <SmallColumnDivLabelValue>
                {currencySymbol}
                {data?.purchase_invoice?.total_amount}
              </SmallColumnDivLabelValue>
            </SmallColumnDiv>
            <SmallColumnDiv>
              <SmallColumnDivLabel>{t('Invoiced')}</SmallColumnDivLabel>
              <SmallColumnDivLabelValue>
                {currencySymbol}
                {data?.purchase_invoice?.sent_to_customer
                  ? data?.purchase_invoice?.total_amount
                  : EMPTY_VALUE}
              </SmallColumnDivLabelValue>
            </SmallColumnDiv>
            <SmallColumnDiv>
              <SmallColumnDivLabel>{t('Open amount')}</SmallColumnDivLabel>
              <SmallColumnDivLabelValue>
                {currencySymbol}
                {data?.purchase_invoice?.open_amount}
              </SmallColumnDivLabelValue>
            </SmallColumnDiv>
            <SmallColumnDiv>
              <SmallColumnDivLabel>{t('Received amount')}</SmallColumnDivLabel>
              <SmallColumnDivLabelValue>
                {currencySymbol}
                {data?.purchase_invoice?.total_amount_payed}
              </SmallColumnDivLabelValue>
            </SmallColumnDiv>
          </RowSpaceBetweenMarginTop>
          <Table
            page={-1}
            perPage={-1}
            total={data?.total}
            tableData={tableData}
            tableConfig={tableConfig}
            hasExtraAction={false}
            loadPage={() => null}
          />
          <EditExternalReferenceModal
            isOpen={isEditReferenceModalOpen}
            setIsOpen={setIsEditReferenceModalOpen}
            onCancel={() => setIsEditReferenceModalOpen(false)}
            externalId={data?.purchase_invoice?.external_id}
            type={InvoiceType.CREDIT_PURCHASE_INVOICE}
          />
        </>
      )}
    </Container>
  );
};

export default SinglePurchaseCreditInvoiceGeneralPage;
