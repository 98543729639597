export const validateSalesOrderField = (
  salesOrderId: string | number,
  setFieldError: any,
  t: (string: string) => string
) => {
  if (!salesOrderId) {
    setFieldError('sales_order_id', t('Sales order is required'));
    return false;
  }
  setFieldError('sales_order_id', undefined);
  return true;
};
