import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  Row,
  Title,
  TitleContainer,
} from './EditSalesOrderWorkingHoursLineModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { useParams } from 'react-router-dom';
import { validateNumericField } from 'utils/validation';
import { useEditSalesOrderWorkingHoursLine } from './hooks';

interface IEditSalesOrderWorkingHoursLineModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  salesOrderLine: any;
}
export const EditSalesOrderWorkingHoursLineModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  salesOrderLine,
}: IEditSalesOrderWorkingHoursLineModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const {
    mutate: editWorkingHoursLine,
    isLoading,
    isSuccess,
  } = useEditSalesOrderWorkingHoursLine(id!, salesOrderLine.id);

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit working hours')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            working_hours_rate: salesOrderLine.working_hours_rate,
            working_hours: salesOrderLine.quantity,
          }}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Working hours rate').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      validate={(value: string) =>
                        validateNumericField(value, 'working_hours_rate')
                      }
                      errorMessage={
                        errors['working_hours_rate']
                          ? (errors['working_hours_rate'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'working_hours_rate');
                      }}
                      height={'43rem'}
                      name="working_hours_rate"
                      wrapperStyles={{
                        width: '300rem',
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>
                <HorizontalLine />
                <Row>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Working hours amount').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      validate={(value: string) =>
                        validateNumericField(value, 'working_hours')
                      }
                      errorMessage={
                        errors['working_hours']
                          ? (errors['working_hours'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'working_hours');
                      }}
                      height={'43rem'}
                      name="working_hours"
                      wrapperStyles={{
                        width: '300rem',
                        marginBottom: '4rem',
                        marginTop: '20rem',
                      }}
                    />
                  </ColumnContainer>
                </Row>

                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    onClick={() => {
                      if (isValid) {
                        editWorkingHoursLine(values);
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
