import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { movePurchaseOrderLineToNewOrder } from 'services/PurchaseOrder/PurchaseOrderService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';

export interface IMoveToNewOrderDTO {
  purchase_order_id: number;
  purchase_order_line_ids: number[];
}

export const useMovePurchaseOrderLineToNewOrder = () => {
  const { t } = useTranslation();
  return useMutation(
    (moveToNewOrderDTO: IMoveToNewOrderDTO) =>
      movePurchaseOrderLineToNewOrder(moveToNewOrderDTO),
    {
      onSuccess: () => {
        toast.success(t('Moved to new order'), {
          className: ReactMutationKeys.MOVE_PURCHASE_ORDER_LINE_TO_NEW_ORDER,
        });
      },
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.MOVE_PURCHASE_ORDER_LINE_TO_NEW_ORDER,
        });
      },
      mutationKey: ReactMutationKeys.MOVE_PURCHASE_ORDER_LINE_TO_NEW_ORDER,
    }
  );
};
