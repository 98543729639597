import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';
import { NavLink } from 'react-router-dom';

interface StyledProps {
  isSideMenuCollapsed: boolean;
  $isDisabled: boolean;
  isFieldWorker: boolean;
}

export const ContainerTablet = styled.div`
  z-index: 2;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: none;
  bottom: 0;
  border-top: 1px solid ${COLORS.GREY_200};
`;

export const ButtonContainerTablet = styled.div`
  height: 50rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  background-color: ${COLORS.WHITE};
  width: calc(100% - 265rem);
  transition: height ease-in-out 200ms;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      width: calc(100% - 115rem);
    `};
  ${(props: StyledProps) =>
    props.isFieldWorker &&
    css`
      width: 100%;
    `};
`;

export const ItemContainerTablet = styled(NavLink)`
  position: relative;
  cursor: pointer;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color ease 100ms;
  height: 50rem;
`;

export const ItemContainerAbsoluteScalable = styled.div`
  position: absolute;
  transition: all ease 100ms;
  min-width: 100rem;
  display: flex;
  justify-content: center;

  ${ItemContainerTablet}.active & {
    background-color: ${COLORS.WHITE};
    bottom: 20rem;
    transform: scale(1.2);
    padding: 20rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    border-radius: ${borderRadiusSm};
    box-shadow: 2px 4px ${COLORS.GREY_200};
    z-index: 1;
  }
`;

export const ItemTextTablet = styled.span`
  display: flex;
  align-items: center;
  align-self: center;
  color: ${COLORS.WHITE};
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};

  &:hover {
    cursor: pointer;
    transform: scale(1.04);
    transition: ease 200ms;
  }
  transition: color ease 100ms;
  user-select: none;

  ${ItemContainerTablet}.active & {
    font-size: 15rem;
    line-height: 22rem;
    color: ${COLORS.PRIMARY};
  }
`;

export const Container = styled.div`
  z-index: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  width: 265rem;
  min-height: 110vh;
  ${(props: StyledProps) =>
    props.isFieldWorker &&
    css`
      left: 0;
    `};
`;

export const ButtonContainer = styled.div`
  margin-top: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ItemContainer = styled(NavLink)`
  background-color: ${COLORS.WHITE};
  width: 265rem;
  height: 41rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color ease 100ms;

  &:active,
  &.active {
    background-color: ${COLORS.PRIMARY};
  }
`;

export const ItemText = styled.span`
  display: flex;
  padding-left: 50rem;
  align-items: center;
  color: ${COLORS.BLACK};
  font-size: 14rem;
  line-height: 21rem;
  width: 255rem;
  height: 41rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.04);
    transition: ease 200ms;
  }
  transition: color ease 100ms;
  text-align: left;
  ${ItemContainer}.active & {
    color: ${COLORS.WHITE};
  }
`;

export const ArrowWrapper = styled.div`
  width: 40rem;
  height: auto;
`;
