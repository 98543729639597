import { AnyAction } from '@reduxjs/toolkit';
import { queryClient } from 'index';
import {
  LOGOUT,
  SET_PERMISSIONS_ACTIONS,
  SET_UPDATED_USER_INFO,
  SET_USER_INFO,
  SET_USER_INTRO_GUIDE_COMPLETED,
  SET_USER_INTRO_GUIDE_COMPLETED_PAGES,
  SET_USER_PICTURE,
} from '../constants';

export interface UserReducerState {
  id: number | null;
  name: string | null;
  last_name: string | null;
  email: string | null;
  company_id: string | null;
  image_url: string | null;
  intro_guide_completed: boolean;
  completed_guide_pages: string[];
}

const INIT_STATE: UserReducerState = {
  id: null,
  name: null,
  last_name: null,
  email: null,
  company_id: null,
  image_url: null,
  intro_guide_completed: false,
  completed_guide_pages: [],
};

const userReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PERMISSIONS_ACTIONS:
      return {
        ...state,
        permissionActions: action.payload,
      };
    case SET_UPDATED_USER_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_USER_PICTURE: {
      return {
        ...state,
        image_url: action.payload,
      };
    }
    case SET_USER_INTRO_GUIDE_COMPLETED: {
      return {
        ...state,
        intro_guide_completed: action.payload,
      };
    }
    case SET_USER_INTRO_GUIDE_COMPLETED_PAGES: {
      return {
        ...state,
        completed_guide_pages: action.payload,
      };
    }
    case LOGOUT: {
      queryClient.invalidateQueries();
      return {
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
