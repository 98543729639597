import { store } from 'store/store';
import {
  IFileViewerPayload,
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from './mobile/postMobileMessage';

export const openFileInNewTab = async (url: string, isPDFBlob?: boolean) => {
  if (!store.getState().commonInfo.isMobileApp) {
    // This part handles opening URLs in a new tab for non-mobile-app environments
    if (isPDFBlob) {
      const newWindow = window.open(
        URL.createObjectURL(url as any),
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    } else {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  } else {
    // This part handles opening URLs in a new tab for mobile-app environment
    if (isPDFBlob) {
      // Handling of PDF blobs; Parse as base64 and send to mobile app
      try {
        const blob: any = url;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64data = reader.result as string;
          const fileViewerPayload: IFileViewerPayload = {
            fileViewerUrl: base64data,
            isPDF: true,
          };
          const mobileMessage: IMobileMessage = {
            type: MobileMessageTypes.OPEN_FILE,
            payload: fileViewerPayload,
          };
          postMobileMessage(mobileMessage);
        };
      } catch (error) {
        console.error('Error converting blob to base64:', error);
      }
    } else {
      // For non-blob URLs
      const fileViewerPayload: IFileViewerPayload = {
        fileViewerUrl: url,
        isPDF: false,
      };
      const mobileMessage: IMobileMessage = {
        type: MobileMessageTypes.OPEN_FILE,
        payload: fileViewerPayload,
      };
      postMobileMessage(mobileMessage);
    }
  }
};
