import { COLORS, gapSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  color: string;
}

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GeneralGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(130rem, auto) 1fr;
  grid-gap: 10rem;
`;

export const GeneralLabel = styled.label`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const UploadPicturesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-width: 700rem;
`;

export const UploadedPicturesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${gapSm};
`;

export const AddMoreWrapper = styled.div`
  display: flex;
  margin-top: 10rem;
  align-items: center;
`;
