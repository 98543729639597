import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  activateStockItem,
  deleteStockitem,
  editStockItemAttributes,
} from 'services/StockItems/StockItemsService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';

export const useDeleteStockItem = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteStockitem(id), {
    onSuccess: (data) => {
      toast.success(t('Stock item deactivated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.DELETE_STOCK_ITEM,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_STOCK_ITEM);
      queryClient.invalidateQueries(ReactQueryKeys.GET_STOCK_ITEMS);
    },
    mutationKey: ReactMutationKeys.DELETE_STOCK_ITEM,
  });
};

export const useActivateStockitem = () => {
  const { t } = useTranslation();
  return useMutation((id: string) => activateStockItem(id), {
    onSuccess: (data) => {
      toast.success(t('Stock item activated'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ACTIVATE_STOCK_ITEM,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_STOCK_ITEM);
      queryClient.invalidateQueries(ReactQueryKeys.GET_STOCK_ITEMS);
    },
    mutationKey: ReactMutationKeys.ACTIVATE_STOCK_ITEM,
  });
};

export const useEditStockItemAttributes = () => {
  const { t } = useTranslation();
  return useMutation((data: any) => editStockItemAttributes(data), {
    onSuccess: (data) => {
      toast.success(t('Stock item edited'));
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EDIT_STOCK_ITEM_ATTRIBUTES,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_STOCK_ITEM);
    },
    mutationKey: ReactMutationKeys.EDIT_STOCK_ITEM_ATTRIBUTES,
  });
};
