import { marginMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  width: 900rem;
  justify-content: start;
  align-items: center;
  margin-bottom: ${marginMd};
`;

export const Row = styled.div`
  display: flex;
  width: 900rem;
`;

export const RightAlign = styled.div`
  display: flex;
  width: 900rem;
  justify-content: flex-end;
  align-items: center;
  gap: 20rem;
  margin-bottom: ${marginMd};
`;
