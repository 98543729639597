import { useMutation } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { toast } from 'utils/toast';
import { resetPassword } from 'services/User/UserService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { useTranslation } from 'react-i18next';

interface IPasswordProps {
  newPassword: string;
  confirmPassword: string;
}
export const useResetPassword = (token: string, companyId: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useMutation(
    (params: IPasswordProps) =>
      resetPassword(
        params.newPassword,
        params.confirmPassword,
        token,
        companyId
      ),
    {
      onSuccess: () => {
        toast.success(t('Password is successfully reset'), {
          toastId: ReactMutationKeys.RESET_PASSWORD,
        });
        navigate(RoutesConfig.Login.fullPath);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.RESET_PASSWORD,
        });
      },
      mutationKey: ReactMutationKeys.RESET_PASSWORD,
    }
  );
};
