import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommentCard from '../../../../components/Cards/CommentCard/CommentCard';
import { useGetPurchaseOrderComments } from '../hooks';
import { useTranslation } from 'react-i18next';
import { PURCHASE_ORDER_COMMENTS_PER_PAGE } from './constants';
import {
  NoContentLabel,
  CommentsContainer,
  RightAlign,
  DatePickerWrap,
} from './SinglePurchaseOrderCommentsPage.styled';
import { SinglePurchaseOrderRoutes } from 'navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';
import Button from 'components/Button/Button';
import { CustomDateRangePicker } from 'components/CustomDateRangePicker/CustomDateRangePicker';
import { COLORS } from 'assets/styled';
import { Plus } from '@phosphor-icons/react';

const SinglePurchaseOrderCommentsPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const { fetchNextPage, isFetching, data, hasNextPage } =
    useGetPurchaseOrderComments(
      PURCHASE_ORDER_COMMENTS_PER_PAGE,
      params.id!,
      startDate,
      endDate
    );

  const dataRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const comments = data?.pages.map((page) => page.comments).flat();
  if (!comments?.length) {
    return (
      <CommentsContainer onScroll={onScroll} ref={dataRef}>
        <RightAlign>
          <DatePickerWrap>
            <CustomDateRangePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </DatePickerWrap>
          <Button
            label={t('Add another comment')}
            link
            icon={Plus}
            sizeIcon={20}
            weightIcon="regular"
            colorIcon={COLORS.PRIMARY}
            onClick={() => {
              navigate(
                `/purchases/${params.id}/${SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment.path}`
              );
            }}
            fontSize="18rem"
          />
        </RightAlign>
        {!isFetching && (
          <NoContentLabel>
            {t('There are currently no comments on this Purchase order')}
          </NoContentLabel>
        )}
      </CommentsContainer>
    );
  }
  return (
    <CommentsContainer onScroll={onScroll} ref={dataRef}>
      <RightAlign>
        <DatePickerWrap>
          <CustomDateRangePicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </DatePickerWrap>
        <Button
          label={t('Add another comment')}
          link
          icon={Plus}
          sizeIcon={20}
          weightIcon="regular"
          colorIcon={COLORS.PRIMARY}
          onClick={() => {
            navigate(
              `/purchases/${params.id}/${SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment.path}`
            );
          }}
          fontSize="18rem"
        />
      </RightAlign>
      {comments.map((item: any, index: number) => {
        return (
          <CommentCard
            key={`${index}`}
            title={item.headline}
            name={`${item.user.name} ${item.user.last_name}`}
            type={item.is_internal ? t('Internal') : t('External')}
            date={item.created_at}
            description={item.description}
            imageUrl={item.user.image_url}
          />
        );
      })}
    </CommentsContainer>
  );
};

export default SinglePurchaseOrderCommentsPage;
