import { Container, NoContentLabel, AlignCenter } from './FwReportPage.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import { IAppointment } from 'types/Appointment.types';
import { useGetReportPageData } from './hooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import ReportForm, { IReportData } from './ReportForm/ReportForm';
import {
  getReportSignatureFullNameFromAppointment,
  removeInactiveInvoices,
} from './helpers';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const FwReportPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name: firstName, last_name: lastName } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );
  const {
    data,
    isLoadingSingleAppoinment,
    isLoadingIntervals,
    isLoadingInvoices,
  } = useGetReportPageData();

  const { appointmentData, intervalsData, invoicesData } = data;

  const appointment: IAppointment = appointmentData?.appointment;

  const { steps } = useRunTour(GuidePages.FIELD_WORKER_REPORT);

  if (isLoadingSingleAppoinment) {
    return (
      <Container>
        <Loader positionType="absolute" />
      </Container>
    );
  }
  if (!appointment && !isLoadingSingleAppoinment) {
    return (
      <Container>
        <AlignCenter>
          <NoContentLabel>{t('Unknown appointment')}</NoContentLabel>
          <Button
            primary
            label={t('Back')}
            onClick={() => navigate(-1)}
            width="200rem"
          />
        </AlignCenter>
        <IntroductionGuide steps={steps} />
      </Container>
    );
  }

  const reportData: IReportData = {
    appointment: appointmentData?.appointment,
    appointmentLines: appointmentData?.appointment_lines || [],
    intervals: intervalsData?.appointment_intervals || [],
    invoices: invoicesData?.sales_invoices
      ? removeInactiveInvoices(invoicesData?.sales_invoices)
      : [],
    firstName: firstName,
    lastName: lastName,
    signatureFullName: getReportSignatureFullNameFromAppointment(appointment),
  };

  return (
    <Container className="fw-report-step-1">
      <ReportForm
        reportData={reportData}
        isLoadingIntervals={isLoadingIntervals}
        isLoadingInvoices={isLoadingInvoices}
      />
      <IntroductionGuide steps={steps} />
    </Container>
  );
};
export default FwReportPage;
