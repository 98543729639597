import React, { useContext, useEffect, useState } from 'react';
import { MarkAsConnectedModal } from './MarkAsConnectedModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface IMarkAsConnectedModalProviderProps {
  children: React.ReactNode;
}

export const MarkAsConnectedModalProvider = ({
  children,
}: IMarkAsConnectedModalProviderProps) => {
  const invoiceId = useSelector(
    (state: IRootReducerState) => state.commonInfo.markAsConnectedInvoiceId
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!invoiceId);
  }, [invoiceId]);

  return (
    <>
      <MarkAsConnectedModal
        invoiceId={invoiceId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      {children}
    </>
  );
};
