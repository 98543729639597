import { ILogyxOrderLine, ILogyxOrderLineTableDTO } from './types';

export const formatLogyxOrderLinesIntoTableDTOs = (
  logyxOrderLines: ILogyxOrderLine[]
) => {
  return logyxOrderLines?.map((line: ILogyxOrderLine) => {
    const tableDTO: ILogyxOrderLineTableDTO = {
      name: line.purchase_order_line.product.name,
      status: line.status.name,
      line: line.purchase_order_line,
    };
    return tableDTO;
  });
};
