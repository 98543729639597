import {
  ITableAltConfig,
  ITableAltRowConfig,
} from 'components/TableAlt/TableAlt';

const rowConfigs: ITableAltRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Customer', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Amount', flexValue: 1, isBlue: false },
];
export const tableConfig: ITableAltConfig = {
  rowConfigs: rowConfigs,
};

export const INVOICES_PER_PAGE = 8;
