import {
  ITableAltConfig,
  ITableAltRowConfig,
} from 'components/TableAlt/TableAlt';

export const APPOINTMENTS_PER_PAGE = 10;

const rowConfigs: ITableAltRowConfig[] = [
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Type', flexValue: 1 },
  { header: 'Date and time', flexValue: 1 },
  { header: 'Location', flexValue: 1 },
  { header: 'Customer', flexValue: 1 },
];

export const tableConfig: ITableAltConfig = {
  rowConfigs: rowConfigs,
};
