import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { confirmPurchaseOrder } from 'services/PurchaseOrder/PurchaseOrderService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export interface IUseConfirmPurchaseOrderDTO {
  files: any;
  reference: string;
  expected_delivery_date: string;
  confirmed_delivery_date: string;
}

export const useConfirmPurchaseOrder = (purchaseOrderId: number) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseConfirmPurchaseOrderDTO) => {
      const fileFormData = new FormData();
      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      fileFormData.append('reference', params.reference);
      params.expected_delivery_date &&
        fileFormData.append(
          'expected_delivery_date',
          params.expected_delivery_date
        );
      params.confirmed_delivery_date &&
        fileFormData.append(
          'confirmed_delivery_date',
          params.confirmed_delivery_date
        );
      return confirmPurchaseOrder(purchaseOrderId, fileFormData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Purchase order confirmed'), {
          className: ReactMutationKeys.CONFIRM_PURCHASE_ORDER,
        });
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDER_FILES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CONFIRM_PURCHASE_ORDER,
        });
      },
      onSettled: () => {
        // onSettled
      },
      mutationKey: ReactMutationKeys.CONFIRM_PURCHASE_ORDER,
    }
  );
};
