import { Option } from 'components/Select/type';
import { PurchaseOrderStatusType } from 'pages/Manager/SinglePurchaseOrder/constants';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IPurchaseOrderStatus } from 'types/PurchaseOrders.types';

export const useGetPurchaseOrderStatuses = (
  statusType: PurchaseOrderStatusType
) => {
  const storePurchaseOrderStatuses = useSelector(
    (state: IRootReducerState) => state.commonInfo.purchaseOrderStatuses
  );

  const memoizedStatuses: Option[] = useMemo(() => {
    switch (statusType) {
      case PurchaseOrderStatusType.STATUS: {
        const statuses =
          storePurchaseOrderStatuses?.purchase_order_statuses?.map(
            (status: IPurchaseOrderStatus) => {
              return {
                name: status.name,
                value: status.name,
                label: status.name,
                id: status.id,
                key: status.id,
              };
            }
          );
        return statuses;
      }
      case PurchaseOrderStatusType.PAYMENT: {
        const paymentStatuses =
          storePurchaseOrderStatuses?.purchase_order_payment_statuses?.map(
            (status: IPurchaseOrderStatus) => {
              return {
                name: status.name,
                value: status.name,
                label: status.name,
                id: status.id,
              };
            }
          );
        return paymentStatuses;
      }
      case PurchaseOrderStatusType.LINE_PAYMENT: {
        const linePaymentStatuses =
          storePurchaseOrderStatuses?.line_payment_statuses?.map(
            (status: IPurchaseOrderStatus) => {
              return {
                name: status.name,
                value: status.name,
                label: status.name,
                id: status.id,
              };
            }
          );
        return linePaymentStatuses;
      }
      case PurchaseOrderStatusType.LINE_DELIVERY: {
        const lineDeliveryStatuses =
          storePurchaseOrderStatuses?.line_delivery_statuses?.map(
            (status: IPurchaseOrderStatus) => {
              return {
                name: status.name,
                value: status.name,
                label: status.name,
                id: status.id,
              };
            }
          );
        return lineDeliveryStatuses;
      }
      default:
        return;
    }
  }, [storePurchaseOrderStatuses, i18n.resolvedLanguage]);
  return memoizedStatuses;
};
