import styled from 'styled-components';
import { P } from 'assets/styled';

export const Container = styled.div`
  width: 635rem;
`;

export const Title = styled(P)`
  margin-bottom: 10rem;
`;
