import { CircularProgress } from '@mui/material';
import { COLORS } from 'assets/styled';

interface ISpinnerProps {
  size?: number;
  color?: string;
}

const Spinner = ({ size = 30, color = COLORS.PRIMARY }: ISpinnerProps) => {
  return <CircularProgress size={size} style={{ color: `${color}` }} />;
};

export default Spinner;
