export const defaultFilterGroups = [
  {
    id: 'group1',
    filters: [
      {
        id: 'filter1',
        type: '',
        condition: 'eq',
        value: '',
      },
    ],
    subFilters: [
      {
        id: 'subFilter1',
        type: '',
        precondition: 'and',
        condition: 'eq',
        value: '',
      },
      {
        id: 'subFilter2',
        type: '',
        precondition: 'and',
        condition: 'eq',
        value: '',
      },
    ],
  },
];
