import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';
import {
  ConfirmedMessage,
  Container,
  ExpiredLink,
  Heading,
  Message,
  Wrapper,
} from './AppointmentConfirmationPage.styled';
import { useCustomerConfirmation, useGetCustomerConfirmation } from './hooks';
import moment from 'moment';
import Button from 'components/Button/Button';
import { useEffect, useState } from 'react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { changeLanguage } from 'utils/changeLanguage';
import { Check } from '@phosphor-icons/react';

const AppointmentConfirmationPage = () => {
  const location = useLocation();
  const [confirmedAppointment, setConfirmedAppointment] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const companyId = searchParams.get('company_id');
  const lang = searchParams.get('lang');

  const { t } = useTranslation();

  const {
    data: appointmentData,
    isLoading: isDataLoading,
    error: errorData,
  } = useGetCustomerConfirmation(token!, companyId!);

  const { mutate: customerConfirmation } = useCustomerConfirmation(
    token!,
    companyId!,
    () => setConfirmedAppointment(true)
  );

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  const date = moment(appointmentData?.appointment?.date_from).format(
    'DD-MM-YYYY'
  );
  const beginTime = moment(appointmentData?.appointment?.arrival_from).format(
    'HH:mm'
  );
  const endTime = moment(appointmentData?.appointment?.arrival_to).format(
    'HH:mm'
  );
  const address = appointmentData?.appointment?.address?.full_address;

  const timeBetweenMessage = t('confirm_appointment_msg', {
    date: date,
    begin_time: beginTime,
    end_time: endTime,
    address: address,
  });

  const timeExactMessage = t('confirm_exact_appointment_msg', {
    date: date,
    begin_time: beginTime,
    address: address,
  });

  if (!lang && !token && !companyId) return <PageNotFound />;

  if (errorData)
    return (
      <Container>
        <Wrapper>
          <ExpiredLink>{t('Expired link')}</ExpiredLink>
        </Wrapper>
      </Container>
    );

  return (
    <Container>
      {isDataLoading ? (
        <Spinner size={50} />
      ) : (
        <Wrapper>
          {!confirmedAppointment && !appointmentData?.appointment?.confirmed && (
            <>
              <Heading>{t('Appointment confirmation')}</Heading>
              <Message>
                {beginTime === endTime ? timeExactMessage : timeBetweenMessage}
              </Message>
              <Button
                label={t('Confirm')}
                primary
                width="200rem"
                onClick={() => customerConfirmation()}
              />
            </>
          )}
          {confirmedAppointment && !appointmentData?.appointment?.confirmed && (
            <>
              <Icon svg={Check} color={COLORS.BLACK} size={60} weight="bold" />
              <ConfirmedMessage>
                {t('Appointment is successfully confirmed')}.
              </ConfirmedMessage>
            </>
          )}

          {appointmentData?.appointment?.confirmed && (
            <>
              <Icon svg={Check} color={COLORS.BLACK} size={60} weight="bold" />
              <ConfirmedMessage>
                {t('Appointment is already confirmed')}.
              </ConfirmedMessage>
            </>
          )}
        </Wrapper>
      )}
    </Container>
  );
};

export default AppointmentConfirmationPage;
