import { useParams } from 'react-router-dom';
import {
  Container,
  NoContentLabel,
  MainContainer,
  Card,
  TextContainer,
  Label,
  LabelTitle,
  ColumnContainer,
  TitleContainer,
} from './SinglePurchaseCreditInvoicePaymentsPage.styled';
import { useGetSinglePurchaseCreditInvoice } from '../hooks';
import { PURCHASE_CREDIT_INVOICE_PAYMENTS_PER_PAGE } from './constants';
import { useTranslation } from 'react-i18next';
import { H3, H4 } from 'assets/styled';
import moment from 'moment';
import { IPayment } from 'types/Payment.types';
import { useCallback, useRef } from 'react';
import { useGetSinglePurchaseCreditInvoicePayments } from './hooks';

const SinglePurchaseCreditInvoicePaymentsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useGetSinglePurchaseCreditInvoice(
    typeof id === 'string' ? id : ''
  );

  const {
    data: invoicePaymentsData,
    isFetching: isFetchingPayments,
    hasNextPage,
    fetchNextPage,
  } = useGetSinglePurchaseCreditInvoicePayments(
    typeof id === 'string' ? id : '',
    PURCHASE_CREDIT_INVOICE_PAYMENTS_PER_PAGE
  );

  const dataRef = useRef<HTMLDivElement>(null);
  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight - 1
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const invoicePayments = invoicePaymentsData?.pages
    .map((page: any) => page.payments)
    .flat();
  if (!invoicePayments?.length)
    return isFetchingPayments ? (
      <></>
    ) : (
      <NoContentLabel>
        {t('There are currently no payments on this Purchase credit invoice')}
      </NoContentLabel>
    );

  return (
    <Container onScroll={onScroll} ref={dataRef}>
      {invoicePayments.map((invoicePayment: IPayment, index: number) => {
        return (
          <Card key={index}>
            <TitleContainer>
              <H3>
                {t('Payment')} {invoicePayment.number}
              </H3>
            </TitleContainer>
            <MainContainer>
              <ColumnContainer>
                <H4>{t('DETAILS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Date')}</LabelTitle>
                  <Label>{`${moment(invoicePayment?.created_at)
                    .tz(moment.tz.guess())
                    .format('DD-MM-YYYY')}`}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount')}</LabelTitle>
                  <Label>{invoicePayment.amount}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Type of payment')}</LabelTitle>
                  <Label>
                    {t(invoicePayment.payment_type.name).toUpperCase()}
                  </Label>
                </TextContainer>
              </ColumnContainer>
              <ColumnContainer>
                <H4>{t('STATUS')}</H4>
                <TextContainer>
                  <LabelTitle>{t('Total amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Amount paid')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
                <TextContainer>
                  <LabelTitle>{t('Open amount')}</LabelTitle>
                  <Label>{'?'}</Label>
                </TextContainer>
              </ColumnContainer>
            </MainContainer>
          </Card>
        );
      })}
    </Container>
  );
};

export default SinglePurchaseCreditInvoicePaymentsPage;
