import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { createPurchaseCreditInvoice } from 'services/Invoice/PurchaseInvoiceService';
import {
  createSalesCreditInvoice,
  getSalesInvoiceCreationInfo,
} from 'services/Invoice/SalesInvoiceService';
import {
  ICreateCreditInvoiceDTO,
  ICreateCreditInvoiceFormDTO,
} from './constants';
import { useGetPaymentOptions } from 'components/Modal/AddPaymentModal/hooks';
import { useEffect } from 'react';
import { getCreateCreditInvoicePayload } from './helpers';

export const useCreateCreditInvoice = () =>
  useMutation(
    (createCreditInvoiceData: ICreateCreditInvoiceDTO) =>
      createCreditInvoiceData.isForSalesInvoice
        ? createSalesCreditInvoice(createCreditInvoiceData)
        : createPurchaseCreditInvoice(createCreditInvoiceData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_INVOICE_CREDIT_INVOICES
        );
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE_CREDIT_INVOICES
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_CREDIT_INVOICE,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_CREDIT_INVOICE,
    }
  );

export const useGetPrepopulateCreditInvoiceData = () => {
  const { state } = useLocation();
  if (state) {
    return state.prepopulateCreditInvoiceData;
  } else {
    return null;
  }
};

export const useGetOffsetPaymentTypeId = () => {
  const paymentTypeOptions = useGetPaymentOptions(true);
  return paymentTypeOptions.find((option) => option.key === 'offseting')?.value;
};

export const useGetSalesCreditInvoiceCreationInfo = () =>
  useMutation(
    (createCreditInvoiceData: ICreateCreditInvoiceDTO) =>
      getSalesInvoiceCreationInfo(createCreditInvoiceData),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.GET_INVOICE_CREATION_INFO,
        });
      },
      mutationKey: ReactMutationKeys.GET_INVOICE_CREATION_INFO,
    }
  );

// If overview is shown, generate create invoice payload and trigger fetch for invoice creation info
export const useSalesCreditInvoiceCreationInfo = (
  isOverview: boolean,
  creditInvoiceFormData: ICreateCreditInvoiceFormDTO,
  prepopulateCreditInvoiceData: any,
  isForSalesInvoice: boolean,
  isRepay?: boolean
) => {
  const {
    data: salesCreditInvoiceCreationInfoData,
    mutate: getSalesCreditInvoiceCreationInfo,
    isLoading: isLoadingSalesCreditInvoiceCreationInfo,
  } = useGetSalesCreditInvoiceCreationInfo();

  useEffect(() => {
    if (isOverview && isForSalesInvoice) {
      const createCreditInvoiceData: ICreateCreditInvoiceDTO =
        getCreateCreditInvoicePayload(
          creditInvoiceFormData,
          prepopulateCreditInvoiceData,
          isForSalesInvoice,
          isRepay
        );
      delete createCreditInvoiceData.isForSalesInvoice;
      getSalesCreditInvoiceCreationInfo(createCreditInvoiceData);
    }
  }, [isOverview]);

  return {
    salesCreditInvoiceCreationInfoData,
    isLoadingSalesCreditInvoiceCreationInfo,
  };
};
