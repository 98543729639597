import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Roles } from 'types/Permissions.types';

export interface IRoleObj {
  name: string;
  id: number;
}

const useHasRole = (permission: any) => {
  const roles = useSelector(
    (state: IRootReducerState) => state.userInfo.permission_groups
  );

  const hasRole = useMemo(() => {
    if (!roles) return false;

    const isAdmin = roles.some(
      (role: IRoleObj) =>
        role.id === Roles.READ_COMPANY_ADMIN ||
        role.id === Roles.EDIT_COMPANY_ADMIN
    );

    if (isAdmin) return true;

    let hasRole = false;

    if (permission) {
      hasRole = !!roles.find((p: IRoleObj) => p.id === permission);
    }

    return hasRole;
  }, [permission, roles]);

  return hasRole;
};

export default useHasRole;
