import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm, paddingMd } from 'assets/styled';

interface StyledProps {
  top: string;
  right: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Title = styled.label`
  width: 322rem;
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  text-align: center;
  padding-bottom: ${paddingMd};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 420rem;
`;

export const CloseAbsoluteWrapper = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
  ${(props: StyledProps) =>
    props.top &&
    css`
      top: ${props.top};
    `};
  ${(props: StyledProps) =>
    props.right &&
    css`
      right: ${props.right};
    `};
`;

export const AwaitingLogyxConfigurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150rem;
`;

export const AwaitingLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  width: 60rem;
  height: 60rem;
  margin-top: ${marginSm};
`;
