import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCustomerSalesOrders } from 'services/Customer/CustomerService';

export const useGetSingleCustomerSalesOrders = (
  customerId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_CUSTOMER_SALES_ORDERS,
      customerId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getCustomerSalesOrders(customerId, page, perPage);
    },
  });
