import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getSinglePurchaseInvoiceCreditInvoices } from 'services/Invoice/PurchaseInvoiceService';

export const useGetSinglePurchaseInvoiceCreditInvoices = (
  invoiceId: string,
  page: number,
  perPage: number
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE_CREDIT_INVOICES,
      invoiceId,
      page,
      perPage,
    ],
    queryFn: () => {
      return getSinglePurchaseInvoiceCreditInvoices(invoiceId, page, perPage);
    },
  });
