import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

export const SALES_INVOICES_PER_PAGE = 10;

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Status', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Total price', flexValue: 1, isBlue: false },
  { header: 'Credit', flexValue: 1, isBlue: false },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};
