import { useLocation } from 'react-router-dom';
import { useGetSingleFwAppointment } from '../FwSingleAppointmentPage/hooks';
import { useGetFwAppointmentIntervals } from '../FwSingleAppointmentPage/FwTimer/hooks';
import { useGetSingleSalesOrderInvoices } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderInvoicesPage/hooks';

export const useGetReportPageData = () => {
  const { state } = useLocation();
  const appointmentId = state?.appointmentId || null;
  const { data: appointmentData, isLoading: isLoadingSingleAppoinment } =
    useGetSingleFwAppointment(appointmentId);
  const { data: intervalsData, isLoading: isLoadingIntervals } =
    useGetFwAppointmentIntervals(appointmentId);
  const { data: invoicesData, isLoading: isLoadingInvoices } =
    useGetSingleSalesOrderInvoices(
      appointmentData?.appointment?.sales_order?.id,
      1,
      50
    );

  const data = {
    appointmentData,
    intervalsData,
    invoicesData,
  };

  return {
    data,
    isLoadingSingleAppoinment,
    isLoadingIntervals,
    isLoadingInvoices,
  };
};

export interface ISubmitReportResourcesDTO {
  resource_id: number;
  expected_working_hours: number;
}

export interface ISubmitReportDTO {
  files: any; // Pictures
  file: any; // Signature
  situation_description: string;
  signature_fullname: string;
  customer_comment: string;
  expected_working_hours: number | null;
  follow_up_action_description: string;
  report_resources: ISubmitReportResourcesDTO[];
}
