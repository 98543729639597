import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isRead: boolean;
}

export const Container = styled.div`
  width: 1075rem;
  background-color: ${COLORS.WHITE};
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
  ${(props: StyledProps) =>
    props.isRead
      ? css`
          border: 1rem solid transparent;
        `
      : css`
          border: 1rem solid ${COLORS.PRIMARY};
        `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const DateLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.GREY_1000};
`;

export const DescriptionLabel = styled.label`
  margin-top: ${marginXs};
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;
