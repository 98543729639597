import { ITableConfig, ITableRowConfig } from 'components/Table/Table';
import { CellType } from 'components/Table/constants';

export const USERS_PER_PAGE = 10;

const rowConfigs: ITableRowConfig[] = [
  { header: 'Name', flexValue: 1, isBlue: false, bigCell: true },
  {
    header: 'License',
    flexValue: 1,
    isBlue: false,
    isSortable: false,
    bigCell: true,
  },
  {
    header: 'Admin',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    bigCell: true,
  },
  {
    header: 'Sales',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
  {
    header: 'Invoice',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
  {
    header: 'Purchase',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
  {
    header: 'Stock',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
  {
    header: 'Planning',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
  {
    header: 'Field worker',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.PERMISSIONS,
    isSortable: false,
    icon: true,
    bigCell: true,
  },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0,
};
