import { borderRadiusSm, COLORS, paddingSm, paddingXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $skip: boolean;
  $back: boolean;
  $next: boolean;
  $close: boolean;
}
export const TooltipBody = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  max-width: 400rem;
  padding: ${paddingXs};
  font-size: 14rem;
  border-radius: ${borderRadiusSm};
`;

export const TooltipHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
  color: ${COLORS.PRIMARY};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

export const TooltipContent = styled.div`
  color: ${COLORS.BLACK};
  text-align: center;
  padding-bottom: ${paddingSm};
`;

export const TooltipTitle = styled.h2`
  font-weight: 300;
  line-height: 36rem;
  color: ${COLORS.BLACK};
  text-align: center;
`;

export const TooltipFooter = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10rem;
`;

export const Button = styled.button`
  background-color: ${COLORS.PRIMARY};
  color: ${COLORS.STRICT_WHITE};
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${(props: StyledProps) =>
    props.$skip &&
    css`
      text-decoration: underline;
      color: ${COLORS.RED_TRASH};
    `}
  ${(props: StyledProps) =>
    props.$back &&
    css`
      text-decoration: underline;
      color: ${COLORS.PRIMARY};
    `}
  ${(props: StyledProps) =>
    props.$next &&
    css`
      background-color: ${COLORS.PRIMARY};
      border-radius: ${borderRadiusSm};
      min-width: 80rem;
      padding: 5rem;
    `}
`;
