import PageNotAuthorized from 'pages/Shared/PageNotAuthorized/PageNotAuthorized';
import React from 'react';
import useHasRole from 'utils/hooks/useHasRole';

interface IProps {
  permission: any;
  children?: React.ReactNode | React.ReactNode[];
}

const ProtectedRoute: React.FC<IProps> = ({ permission, children }) => {
  const hasPermission = useHasRole(permission);

  if (!hasPermission) {
    return <PageNotAuthorized />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
