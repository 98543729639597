import { useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getMandates } from 'services/MollieCustomer/MollieCustomerService';

export const useGetSingleMollieCustomerMandates = (mollieCustomerId: string) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SINGLE_MOLLIE_CUSTOMER_MANDATES,
      mollieCustomerId,
    ],
    queryFn: () => {
      return getMandates(mollieCustomerId);
    },
    enabled: !!mollieCustomerId,
  });
