import { COLORS, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  marginRight: string;
  isSelectAll: boolean;
  flex: number;
  isMoreLabel: boolean;
  isInputCell: boolean;
  isFirst: boolean;
  isLast: boolean;
  color: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const SupplierCompanyNameLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginSm};
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10rem;
  padding-left: 20rem;
  padding-right: 20rem;
`;

export const HeaderLabel = styled.div`
  color: ${COLORS.BLACK};
  display: flex;
  ${(props: StyledProps) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  text-align: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.isSelectAll &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const PurchaseOrderLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PurchaseOrderLineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 20rem;
  padding-right: 20rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  width: 100%;
  align-items: center;
  border: 1rem solid red;
  ${(props: StyledProps) =>
    props.isFirst &&
    css`
      border-top-left-radius: 10rem;
      border-top-right-radius: 10rem;
    `};
  ${(props: StyledProps) =>
    props.isLast &&
    css`
      border-bottom-left-radius: 10rem;
      border-bottom-right-radius: 10rem;
    `};
  background-color: ${(props: StyledProps) =>
    props.isSelected ? COLORS.BACKGROUND_COLOR : 'none'};

  ${(props: StyledProps) => {
    if (props.isSelected) {
      if (props.isFirst) {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-bottom: none;
        `;
      } else if (props.isLast) {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-top: none;
        `;
      } else {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-top: none;
          border-bottom: none;
        `;
      }
    } else {
      if (props.isFirst) {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-bottom: none;
        `;
      } else if (props.isLast) {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-top: none;
        `;
      } else {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-top: none;
          border-bottom: none;
        `;
      }
    }
  }};
`;

export const FormCell = styled.div`
  display: flex;
  ${(props: StyledProps) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${(props: StyledProps) =>
    props.isInputCell
      ? css`
          margin-top: 16rem;
        `
      : css`
          text-align: center;
          justify-content: center;
        `};
`;

export const FormLabel = styled.div`
  color: ${COLORS.BLACK};
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  ${(props: StyledProps) =>
    props.isMoreLabel &&
    css`
      user-select: none;
      color: ${COLORS.PRIMARY};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1rem;
        text-underline-position: under;
      }
    `};
`;

export const StockItemAbsoluteInfoTooltipWrapper = styled.div`
  position: absolute;
  right: 5rem;
  top: 5rem;
`;

export const IsLogyxProductAbsoluteWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translate(-50%, -50%);
  max-width: 21rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 1rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;
