// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import { useCallback, useRef, useState } from 'react';
import {
  Column,
  Container,
  ContentColumnContainer,
  ItemContainer,
  ItemWrapper,
  Label,
  LeftHorizontalLine,
  MessageLabel,
  NextSlideChevronWrapper,
  PreviousSlideChevronWrapper,
  RightHorizontalLine,
  UserTimeLabel,
  Wrapper,
} from './CustomSwiper.styled';
import { ISalesOrderStatus, ISubStatus } from 'types/SalesOrders.types';
import { getTimelineEventContent } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderGeneralPage/helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TimelineEvents } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderGeneralPage/constants';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';

export const MANUAL_CHANGE = 'MANUAL_CHANGE';

export enum HistoryEventEntityTypeEnum {
  QUOTATION = 'Quotation',
  SALES_ORDER = 'Sales order',
}

export interface IHistoryEvent {
  created_at: string;
  event: { name: string };
  from_payment_status: ISalesOrderStatus;
  from_status: ISalesOrderStatus;
  from_sub_status: ISubStatus;
  to_payment_status: ISalesOrderStatus;
  to_status: ISalesOrderStatus;
  to_sub_status: ISubStatus;
  user: {
    company_id: number;
    last_name: string;
    id: number;
    name: string;
    email: string;
  };
  sales_invoice: any;
  entityType?: HistoryEventEntityTypeEnum; // Generated on the frontend, for distinguishing between identical event names
  entityNumber?: string; // Generated on the frontend, for displaying which entity created the event
}
interface ICustomSwiperProps {
  historyItems: IHistoryEvent[];
}

const SLIDES_PER_VIEW = 3;

const CustomSwiper = ({ historyItems }: ICustomSwiperProps) => {
  const { t } = useTranslation();
  const swiperRef: any = useRef(null);
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);
  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const isEventInvoiceRelated = (event: string) =>
    event === TimelineEvents.INVOICE_CREATED ||
    event === TimelineEvents.INVOICE_CANCELLED ||
    event === TimelineEvents.CREDIT_INVOICE_EMAIL_SENT ||
    event === TimelineEvents.SALES_INVOICE_EMAIL_SENT ||
    event === TimelineEvents.SALES_INVOICE_PARTLY_PAID ||
    event === TimelineEvents.SALES_INVOICE_PAID ||
    event === TimelineEvents.DOWN_PAYMENT_INVOICE_EMAIL_SENT ||
    event === TimelineEvents.DOWN_PAYMENT_INVOICE_PAID ||
    event === TimelineEvents.DOWN_PAYMENT_INVOICE_CANCELLED ||
    event === TimelineEvents.DOWN_PAYMENT_INVOICE_PARTLY_PAID ||
    event === TimelineEvents.DOWN_PAYMENT_INVOICE_CREATED;

  return (
    <Container>
      <Wrapper>
        <Swiper
          initialSlide={historyItems.length - 1}
          spaceBetween={0}
          ref={swiperRef}
          slidesPerView={SLIDES_PER_VIEW}
          onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
          modules={[Navigation]}
        >
          {historyItems
            .sort((a, b) =>
              moment
                .utc(a.created_at)
                .tz(moment.tz.guess())
                .diff(moment.utc(b.created_at).tz(moment.tz.guess()))
            )
            .map((item: IHistoryEvent, index: number) => {
              const eventContent = getTimelineEventContent(item);

              return (
                <SwiperSlide
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ItemContainer>
                    {index !== 0 && <LeftHorizontalLine />}
                    <ItemWrapper>
                      <ContentColumnContainer>
                        <Label>
                          {t(eventContent?.title)}{' '}
                          {isEventInvoiceRelated(item.event.name) &&
                          item.sales_invoice
                            ? item.sales_invoice.invoice_number
                            : item.entityNumber}
                        </Label>
                        <Column>
                          {item?.user && (
                            <UserTimeLabel>{`${item?.user?.name} ${item?.user?.last_name}`}</UserTimeLabel>
                          )}
                          <UserTimeLabel>
                            {moment
                              .utc(item.created_at)
                              .utcOffset(moment().utcOffset())
                              .format('DD-MM-YYYY HH:mm')}
                          </UserTimeLabel>
                        </Column>
                        <MessageLabel>
                          {`${t(eventContent?.message)} `}
                          {item?.event?.name === MANUAL_CHANGE &&
                            item?.to_status &&
                            `${t('Status')}: ${t(
                              item?.to_status.name
                            ).toLowerCase()}`}
                        </MessageLabel>
                        {item?.event?.name === MANUAL_CHANGE &&
                          item?.to_sub_status && (
                            <MessageLabel>
                              {`${t('Substatus')}: ${t(
                                item?.to_sub_status.name
                              ).toLowerCase()}`}
                            </MessageLabel>
                          )}
                      </ContentColumnContainer>
                    </ItemWrapper>
                    {index !== historyItems.length - 1 && (
                      <RightHorizontalLine />
                    )}
                  </ItemContainer>
                </SwiperSlide>
              );
            })}
        </Swiper>

        <PreviousSlideChevronWrapper
          onClick={handlePrev}
          disabled={activeSlide === 0}
        >
          <Icon
            svg={CaretLeft}
            color={COLORS.BLACK}
            size={20}
            weight="regular"
          />
        </PreviousSlideChevronWrapper>

        <NextSlideChevronWrapper
          onClick={handleNext}
          disabled={
            historyItems.length <= SLIDES_PER_VIEW ||
            activeSlide === historyItems.length - SLIDES_PER_VIEW
          }
        >
          <Icon
            svg={CaretRight}
            color={COLORS.BLACK}
            size={20}
            weight="regular"
          />
        </NextSlideChevronWrapper>
      </Wrapper>
    </Container>
  );
};

export default CustomSwiper;
