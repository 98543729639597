import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  customerConfirmation,
  getCustomerConfirmation,
} from 'services/Appointment/AppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { toast } from 'utils/toast';

export const useGetCustomerConfirmation = (token: string, companyId: string) =>
  useQuery({
    queryKey: ReactQueryKeys.GET_SIGNATURE_INFO,
    queryFn: () => {
      return getCustomerConfirmation(token, companyId);
    },
  });

export const useCustomerConfirmation = (
  token: string,
  companyId: string,
  onConfirmSuccess: () => void
) => {
  const { t } = useTranslation();
  return useMutation(() => customerConfirmation(token, companyId), {
    onSuccess: () => {
      toast.success(t('Appointment confirmed'), {
        className: ReactMutationKeys.CUSTOMER_CONFIRM_APPOINTMENT,
      });
      onConfirmSuccess();
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.CUSTOMER_CONFIRM_APPOINTMENT,
      });
    },
    mutationKey: ReactMutationKeys.CUSTOMER_CONFIRM_APPOINTMENT,
  });
};
