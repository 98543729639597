import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding-top: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1000rem;
`;

export const AddNewProductLabel = styled.label`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  width: 770rem;
  text-align: left;
  margin-bottom: 30rem;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100rem;
  z-index: 5;
`;
