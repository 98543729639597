import { COLORS, H3 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import {
  Container,
  TitleContainer,
  BottomContainer,
  Row,
  Column,
  Name,
  Description,
  CustomersSuppliersLabel,
  TitleWrapper,
  NoPermissionMessage,
  LoaderWrapper,
} from './WorkersAndResourcesCard.styled';
import Icon from 'components/Icon/Icon';
import { Truck } from '@phosphor-icons/react';
import { useGetResourcesInfo, useGetWorkersInfo } from '../hooks';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Spinner from 'components/Spinner/Spinner';

export const WorkersAndResourcesCard = () => {
  const { t } = useTranslation();

  const { data: infoWorkers, isLoading: isLoadingWorkers } =
    useGetWorkersInfo();
  const { data: infoResources, isLoading: isLoadingResources } =
    useGetResourcesInfo();

  const canReadWorkersAndResources = useCan(Actions.READ_APPOINTMENT);

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={Truck} color={COLORS.PRIMARY} size={30} />
          <H3>{t('Workers & Resources')}</H3>
        </TitleWrapper>
      </TitleContainer>

      {canReadWorkersAndResources ? (
        <>
          {isLoadingWorkers || isLoadingResources ? (
            <LoaderWrapper>
              <Spinner />
            </LoaderWrapper>
          ) : (
            <BottomContainer>
              <Column>
                <CustomersSuppliersLabel>
                  {t('Workers')}
                </CustomersSuppliersLabel>

                <Row>
                  <Name>{t('Busy')}</Name>
                  <Description>{infoWorkers?.total_busy}</Description>
                </Row>
                <Row>
                  <Name>{t('Available')}</Name>
                  <Description>{infoWorkers?.total_available}</Description>
                </Row>
              </Column>
              <Column>
                <CustomersSuppliersLabel>
                  {t('Resources')}
                </CustomersSuppliersLabel>

                <Row>
                  <Name>{t('Busy')}</Name>
                  <Description>{infoResources?.total_busy}</Description>
                </Row>
                <Row>
                  <Name>{t('Available')}</Name>
                  <Description>{infoResources?.total_busy}</Description>
                </Row>
              </Column>
            </BottomContainer>
          )}
        </>
      ) : (
        <NoPermissionMessage>{t('No permission')}</NoPermissionMessage>
      )}
    </Container>
  );
};
