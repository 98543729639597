import { H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import FakeInput from 'components/FakeInput/FakeInput';
import {
  ICreateStockItemDetails,
  useGetUnitOfMeasureOptions,
} from '../ItemTab/constants';
import { ICreateStockItemSupplierDetails } from '../SupplierTab/constants';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SupplierDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  RowMarginTop,
  SectionTitle,
  SubmitRow,
  ItemsWrapper,
  FieldWrapper,
} from './OverviewTab.styled';
import { useGetTypeOptions } from '../ItemTab/hooks';
import { StockItemType } from 'pages/Manager/SingleStockItemPage/constants';

interface IOverviewTab {
  onForward: () => void;
  onBack: () => void;
  supplierDetails: ICreateStockItemSupplierDetails;
  item: ICreateStockItemDetails;
  status: string;
}

const OverviewTab = ({
  onForward,
  onBack,
  supplierDetails,
  item,
  status,
}: IOverviewTab) => {
  const { t } = useTranslation();
  const typeOptions = useGetTypeOptions();
  const unitOfMeasureOptions = useGetUnitOfMeasureOptions();

  return (
    <Container>
      <FirstRow>
        <H3>{t('Overview')}</H3>
      </FirstRow>
      <SupplierDetailsWrapper>
        <SectionTitle>{t('Supplier Details')}</SectionTitle>
        <RowMarginTop>
          <FakeInput
            label={supplierDetails.contact_person}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Contact person')}
          />
          <FakeInput
            label={supplierDetails.phone}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Phone number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.company_name}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Company name')}
          />
          <FakeInput
            label={supplierDetails.vat_number}
            isDropdown={false}
            width={'50%'}
            placeholder={t('VAT number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.email}
            isDropdown={false}
            width={'50%'}
            placeholder={t('E-mail')}
          />
          <FakeInput
            label={supplierDetails.coc_number}
            isDropdown={false}
            width={'50%'}
            placeholder={t('COC number')}
          />
        </RowMarginTop>

        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.code}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Code')}
          />
          <FakeInput
            label={supplierDetails.city}
            isDropdown={false}
            width={'50%'}
            placeholder={t('City')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.street}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Street')}
          />
          <FakeInput
            label={supplierDetails.house_number}
            isDropdown={false}
            width={'50%'}
            placeholder={t('House number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FieldWrapper>
            <FakeInput
              label={supplierDetails.country}
              isDropdown={true}
              width={'100%'}
              placeholder={t('Country')}
            />
          </FieldWrapper>
        </RowMarginTop>
        <Line />
      </SupplierDetailsWrapper>
      <ItemsWrapper>
        <SectionTitle>{t('Item details')}</SectionTitle>
        <RowMarginTop>
          <FakeInput
            label={item?.name}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Name')}
          />
          <FakeInput
            label={
              unitOfMeasureOptions.find(
                (option) => option.value === item.unit_of_measure
              )?.label || item.unit_of_measure
            }
            isDropdown={true}
            width={'50%'}
            placeholder={t('Unit of measure')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={item?.description}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Description')}
          />
          <FakeInput
            label={
              typeOptions.find((option) => option.value === item.type_id)
                ?.label || item.type_id
            }
            isDropdown={true}
            width={'50%'}
            placeholder={t('Type')}
          />
        </RowMarginTop>

        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={item?.purchase_price}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Purchase price exc. VAT')}
          />
          <FakeInput
            label={item.sales_price}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Sales Price')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          {item.type !== StockItemType.SERVICE && (
            <FakeInput
              label={item.in_stock}
              isDropdown={false}
              width={'50%'}
              placeholder={t('In stock')}
            />
          )}
          <FakeInput
            label={item?.number_supplier || ''}
            isDropdown={false}
            width={'50%'}
            placeholder={t('Item supplier number')}
          />
        </RowMarginTop>
      </ItemsWrapper>
      <SubmitRow>
        <Button
          width={'200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            width={'200rem'}
            onClick={() => onForward()}
            label={t('Finish')}
            primary
            disabled={status === 'loading'}
          />
        </Margin>
      </SubmitRow>
    </Container>
  );
};

export default OverviewTab;
