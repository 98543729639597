import { H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { formatCustomersTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useNavigate } from 'react-router-dom';
import { ICustomer, ICustomerTableDTO } from 'types/Customer.types';
import { CUSTOMERS_PER_PAGE, tableConfig } from './constants';
import {
  useGetCustomers,
  useGetCustomersPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import { useTranslation } from 'react-i18next';
import {
  AdvancedFilterWrapper,
  Container,
  FlexAlignCenter,
  Row,
  RowRightPart,
  SelectInputWrapper,
  TitleContainer,
} from './CustomersPage.styled';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { getStatusValue } from './helpers';
import { useDebounce } from 'utils/hooks/useDebounce';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useState } from 'react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useGetCustomersAdvancedFilters } from './advancedFilters';
import { RoutesConfig } from 'navigation/routes';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const CustomersPage = () => {
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [advancedFilters, setAdvancedFilters] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canCreateCustomer = useCan(Actions.EDIT_SALES_ORDER);
  const currencySymbol = useGetCurrencySymbol();

  const { customersPageFilters, statusOptions } = useGetCustomersPageFilters();

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  } = useManageAndSaveFilters(customersPageFilters);

  const debouncedSearchBy = useDebounce(searchBy);

  const { isLoading, data } = useGetCustomers(
    page,
    CUSTOMERS_PER_PAGE,
    debouncedSearchBy,
    getStatusValue(selectedStatusOption.value),
    sortBy,
    sortDirection,
    advancedFilters
  );

  let tableData: ICustomerTableDTO[] = [];
  if (data) {
    tableData = formatCustomersTableData(
      data.customers
        .map((customer: ICustomer[]) => {
          return customer;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: ICustomerTableDTO) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetCustomersAdvancedFilters();

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <Button
          onClick={() => navigate(RoutesConfig.NewCustomer.fullPath)}
          label={t('Create customer')}
          width="200rem"
          height="41rem"
          primary
          disabled={!canCreateCustomer}
        />
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={'400rem'}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
        </FlexAlignCenter>
        <RowRightPart>
          <SelectInputWrapper>
            <Select
              defaultValue={
                customersPageFilters.selectedStatusOption
                  ? customersPageFilters.selectedStatusOption
                  : statusOptions[0]
              }
              name="status"
              placeholder={t('Status')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedStatusOption(e)}
              options={statusOptions}
            />
          </SelectInputWrapper>
        </RowRightPart>
      </Row>
      <AdvancedFilterWrapper>
        <Button
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </AdvancedFilterWrapper>
      <Table
        page={page}
        perPage={CUSTOMERS_PER_PAGE}
        isLoading={isLoading}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />
      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />
    </Container>
  );
};

export default CustomersPage;
