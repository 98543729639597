import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { addNewFwAppointmentComment } from 'services/Appointment/FwAppointmentService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

interface IUseNewFwAppointmentCommentParams {
  headline: string;
  description: string;
  isInternal: boolean;
  id: string;
}
export const useNewFwAppointmentComment = () =>
  useMutation(
    (params: IUseNewFwAppointmentCommentParams) =>
      addNewFwAppointmentComment(
        params.headline,
        params.description,
        params.isInternal,
        params.id
      ),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_FW_APPOINTMENT_COMMENT,
        });
      },
      onSettled: () => {
        // onSettled
      },
      mutationKey: ReactMutationKeys.ADD_FW_APPOINTMENT_COMMENT,
    }
  );
