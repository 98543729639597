import { Route, Routes } from 'react-router-dom';
import { SalesCreditInvoiceRoutes } from './SingleSalesCreditInvoice.routes';
import SingleSalesCreditInvoiceGeneralPage from 'pages/Manager/SingleInvoice/SingleSalesCreditInvoice/SingleSalesCreditInvoiceGeneralPage/SingleSalesCreditInvoiceGeneralPage';
import SingleSalesCreditInvoicePaymentsPage from 'pages/Manager/SingleInvoice/SingleSalesCreditInvoice/SingleSalesCreditInvoicePaymentsPage/SingleSalesCreditInvoicePaymentsPage';
import SingleSalesCreditInvoiceFilesPage from 'pages/Manager/SingleInvoice/SingleSalesCreditInvoice/SingleSalesCreditInvoiceFilesPage/SingleSalesCreditInvoiceFilesPage';
import SingleSalesCreditInvoiceSalesInvoicePage from 'pages/Manager/SingleInvoice/SingleSalesCreditInvoice/SingleSalesCreditInvoiceSalesInvoicePage/SingleSalesCreditInvoiceSalesInvoicePage';

const SingleSalesCreditInvoiceRouter = () => {
  return (
    <Routes>
      <Route
        path={SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceGeneral.path}
        element={<SingleSalesCreditInvoiceGeneralPage />}
      />
      <Route
        path={
          SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceSalesInvoice.path
        }
        element={<SingleSalesCreditInvoiceSalesInvoicePage />}
      />
      <Route
        path={SalesCreditInvoiceRoutes.SingleSalesCreditInvoicePayments.path}
        element={<SingleSalesCreditInvoicePaymentsPage />}
      />
      <Route
        path={SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceFiles.path}
        element={<SingleSalesCreditInvoiceFilesPage />}
      />
    </Routes>
  );
};

export default SingleSalesCreditInvoiceRouter;
