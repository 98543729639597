import { AppointmentType } from 'types/Appointment.types';

export interface IReportPageErrors {
  situationDescription: string;
  fullName: string;
  signature: string | null;
  followUpActionDescription: string;
}

export const validateSituationDescription = (
  situationDescription: string,
  t: (string: string) => string
): string => {
  return situationDescription ? '' : t('Situation description is required');
};

export const validateFullName = (
  fullName: string,
  t: (string: string) => string
): string => {
  return fullName ? '' : t('Full name is required');
};

export const validateSignature = (
  signature: string,
  t: (string: string) => string
): string => {
  return signature ? '' : t('Signature is required');
};

export const validateFollowUpActionDescription = (
  followUpActionDescription: string,
  t: (string: string) => string,
  isFollowUpActionRequired: boolean
): string => {
  if (!isFollowUpActionRequired) {
    return '';
  } else {
    return followUpActionDescription
      ? ''
      : t('Follow up action description is required');
  }
};

export const validateAll = (
  situationDescription: string,
  fullName: string,
  signature: string,
  followUpActionDescription: string,
  t: (string: string) => string,
  isFollowUpActionRequired: boolean,
  isMeasuring: boolean
): IReportPageErrors => {
  return {
    situationDescription: validateSituationDescription(situationDescription, t),
    fullName: validateFullName(fullName, t),
    signature: isMeasuring ? '' : validateSignature(signature, t),
    followUpActionDescription: validateFollowUpActionDescription(
      followUpActionDescription,
      t,
      isFollowUpActionRequired
    ),
  };
};

export const isFormValid = (errors: IReportPageErrors) => {
  return Object.values(errors).every((error) => error === '');
};
