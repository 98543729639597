import { toast as toastLib } from 'react-toastify';
import { DEFAULT_ERROR_MESSAGE } from '../services/api/errors';

// Apply custom toast middleware based on toast message
// Used to hide multiple identical toasts that show when token expires or there is a version mismatch

const customToast = {
  success: (message: string, options: object = {}) => {
    toastLib.success(message, options);
  },
  error: (message: string, options: object = {}) => {
    if (message === 'Version mismatch') {
      toastLib.error(message, {
        ...options,
        toastId: 'VERSION_MISMATCH_TOAST_ID',
      });
      return;
    }
    if (message === 'Session expired') {
      toastLib.error(message, {
        ...options,
        toastId: 'SESSION_EXPIRED_TOAST_ID',
      });
      return;
    }
    if (message === 'Token expired') {
      toastLib.error(message, {
        ...options,
        toastId: 'TOKEN_EXPIRED_TOAST_ID',
      });
      return;
    }

    if (message === 'Request failed with status code 400') {
      toastLib.error(message, {
        ...options,
        toastId: 'REQUEST_FAILED_STATUS_CODE_400_TOAST_ID',
      });
      return;
    }

    if (message === DEFAULT_ERROR_MESSAGE) {
      toastLib.error(message, {
        ...options,
        toastId: 'DEFAULT_ERROR_TOAST_ID',
      });
      return;
    }

    if (
      message === 'Token is invalid' ||
      message === 'Old refresh token' ||
      message === 'Missing token' ||
      message === 'Request failed with status code 401' ||
      message === 'Request blocked; Version mismatch'
    ) {
      // User does not need to see this message
      return;
    }
    toastLib.error(message, options);
  },
  info: (message: string, options: object = {}) => {
    toastLib.info(message, options);
  },
  warn: (message: string, options: object = {}) => {
    toastLib.warn(message, options);
  },
  default: (message: string, options: object = {}) => {
    toastLib(message, options);
  },
};

export { customToast as toast };
