import { ISalesOrder, ISalesOrderLine } from 'types/SalesOrders.types';
import { IPurchaseOrderFormDTO, IPurchaseOrderLineFormDTO } from './constants';
import { isAnyProductSelected } from './Overview/helpers';

export const getInitialData = (
  prepopulateFormData: ISalesOrder
): IPurchaseOrderFormDTO[] => {
  const initialData: IPurchaseOrderFormDTO[] = [];

  const alreadyAddedUniqueSupplierIds: number[] = [];

  const getIsSelected = (salesOrderLine: ISalesOrderLine) => {
    let isSelected;
    if (salesOrderLine.stock_item_line) {
      // isSelected => add to purchase order
      isSelected =
        Number(salesOrderLine.quantity) > Number(salesOrderLine.in_stock);
    } else {
      isSelected =
        salesOrderLine.is_linked_purchase || salesOrderLine.already_purchased
          ? false
          : true;
    }
    return isSelected;
  };

  prepopulateFormData?.sales_order_lines.forEach((salesOrderLine) => {
    if (!salesOrderLine.supplier_id) {
      // Ignore working hours line
      return;
    }
    if (salesOrderLine?.stock_item?.type?.name === 'SERVICE') {
      // Ignore service stock item lines
      return;
    }
    if (
      !alreadyAddedUniqueSupplierIds.includes(
        Number(salesOrderLine.supplier_id)
      )
    ) {
      alreadyAddedUniqueSupplierIds.push(Number(salesOrderLine.supplier_id));

      const purchaseOrderFormDTO: IPurchaseOrderFormDTO = {
        supplier: salesOrderLine.supplier,
        purchaseOrderLines: [
          {
            product: {
              ...salesOrderLine.product!,
              purchase_price: salesOrderLine?.product?.purchase_price
                ? salesOrderLine.product.purchase_price
                : '0',
            },
            quantity: salesOrderLine.quantity,
            isSelected: getIsSelected(salesOrderLine),
            errorMessage: '',
            sales_order_line_id: salesOrderLine.id,
            isStockItem: salesOrderLine.stock_item_line,
            in_stock: salesOrderLine.in_stock!,
            placement: salesOrderLine.placement,
            status: salesOrderLine.sub_status?.name,
            supplier: salesOrderLine.supplier.company_name,
            salesPrice: salesOrderLine?.product_sales_price,
            totalDiscount: salesOrderLine?.discount_amount,
            totalPurchaseCost: salesOrderLine?.product_purchase_price,
          },
        ],
      };
      initialData.push(purchaseOrderFormDTO);
    } else {
      const existingPurchaseOrderFormDTO = initialData.find(
        (purchaseOrderFormDTO: IPurchaseOrderFormDTO) =>
          Number(purchaseOrderFormDTO.supplier.id) ===
          Number(salesOrderLine.supplier_id)
      );
      const purchaseOrderLineDTO: IPurchaseOrderLineFormDTO = {
        product: {
          ...salesOrderLine.product!,
          purchase_price: salesOrderLine?.product?.purchase_price
            ? salesOrderLine.product.purchase_price
            : '0',
        },
        quantity: salesOrderLine.quantity,
        isSelected: getIsSelected(salesOrderLine),
        errorMessage: '',
        sales_order_line_id: salesOrderLine.id,
        isStockItem: salesOrderLine.stock_item_line,
        in_stock: salesOrderLine.in_stock!,
        placement: salesOrderLine.placement,
        status: salesOrderLine.sub_status?.name,
        supplier: salesOrderLine.supplier.company_name,
        salesPrice: salesOrderLine?.product_sales_price,
        totalDiscount: salesOrderLine?.discount_amount,
        totalPurchaseCost: salesOrderLine?.product_purchase_price,
      };
      existingPurchaseOrderFormDTO?.purchaseOrderLines.push(
        purchaseOrderLineDTO
      );
    }
  });

  return initialData;
};

export const isFormValid = (purchaseOrderForms: IPurchaseOrderFormDTO[]) => {
  for (const purchaseOrderForm of purchaseOrderForms) {
    for (const purchaseOrderLine of purchaseOrderForm.purchaseOrderLines) {
      if (purchaseOrderLine.isSelected) {
        if (
          purchaseOrderLine.quantity === 0 ||
          purchaseOrderLine.quantity === '' ||
          purchaseOrderLine.quantity === undefined ||
          (purchaseOrderLine.quantity &&
            Number(purchaseOrderLine.quantity) < 0) ||
          purchaseOrderLine.product.purchase_price === '' ||
          +purchaseOrderLine.product.purchase_price === undefined ||
          (purchaseOrderLine.product.purchase_price &&
            +purchaseOrderLine.product.purchase_price < 0)
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

export const isAnyProductSelectedTotal = (
  purchaseOrderFormDTOs: IPurchaseOrderFormDTO[]
) => {
  for (const purchaseOrderFormDTO of purchaseOrderFormDTOs) {
    if (isAnyProductSelected(purchaseOrderFormDTO)) {
      return true;
    }
  }
  return false;
};
