import {
  Container,
  ProfileCard,
  H3,
  HeadingWrapper,
  ProfileCardImg,
  ProfileImg,
  IconWrapper,
  ProfileImgWrapper,
} from './ProfilePage.styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IRootReducerState } from 'store/store';
import { FileUploadModal } from 'components/Modal/FileUploadModal/FileUploadModal';
import { useEffect, useState } from 'react';
import { useUploadProfilePicture } from './hooks';
import ProfileCardRow from './ProfileCardRow/ProfileCardRow';
import { EditableProfileUserDataType } from './constants';
import SubmitFeedback from './SubmitFeedback/SubmitFeedback';
import Icon from 'components/Icon/Icon';
import { PencilSimple, User } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

const ProfilePage = () => {
  const { t } = useTranslation();
  const {
    name: firstName,
    last_name: lastName,
    email,
    image_url: imageUrl,
  } = useSelector((state: IRootReducerState) => state.userInfo);
  const companyName = useSelector(
    (state: IRootReducerState) => state.companyInfo.name
  );
  const [isUploadPictureModalOpen, setIsUploadPictureModalOpen] =
    useState<boolean>(false);

  const handleUploadProfilePicture = () => {
    setIsUploadPictureModalOpen(true);
  };

  const { mutate: uploadProfilePicture, isSuccess } = useUploadProfilePicture();

  const handleSubmitUploadProfilePicture = async (files: any) => {
    const blob = await fetch(files).then((r) => r.blob());
    uploadProfilePicture({ files: blob });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsUploadPictureModalOpen(false);
    }
  }, [isSuccess]);

  return (
    <Container>
      <HeadingWrapper>
        <H3>{t('Profile')}</H3>
      </HeadingWrapper>
      <ProfileCard>
        <ProfileCardImg>
          <ProfileImgWrapper>
            {imageUrl ? (
              <ProfileImg alt="" src={imageUrl} />
            ) : (
              <Icon svg={User} color={COLORS.PRIMARY} size={120} />
            )}
          </ProfileImgWrapper>
          <IconWrapper
            data-testid="change-image"
            onClick={() => handleUploadProfilePicture()}
          >
            <Icon svg={PencilSimple} size={20} color={COLORS.STRICT_WHITE} />
          </IconWrapper>
        </ProfileCardImg>
        <ProfileCardRow labelName={'Company'} labelValue={companyName} />
        <ProfileCardRow
          labelName={'Name'}
          labelValue={firstName}
          editableFieldType={EditableProfileUserDataType.NAME}
        />
        <ProfileCardRow
          labelName={'Last name'}
          labelValue={lastName}
          editableFieldType={EditableProfileUserDataType.LAST_NAME}
        />
        {/* <ProfileCardRow labelName={'Role'} labelValue={'TODO'} /> */}
        <ProfileCardRow
          labelName={'E-mail'}
          labelValue={email}
          editableFieldType={EditableProfileUserDataType.EMAIL}
          disableChange
        />
        <ProfileCardRow
          labelName={'Password'}
          labelValue={'********'}
          editableFieldType={EditableProfileUserDataType.PASSWORD}
        />
        <SubmitFeedback />
      </ProfileCard>
      <FileUploadModal
        isUploadProfilePicture={true}
        isOpen={isUploadPictureModalOpen}
        setIsOpen={setIsUploadPictureModalOpen}
        onSubmit={(files: any) => handleSubmitUploadProfilePicture(files)}
        multiple={false}
      />
    </Container>
  );
};

export default ProfilePage;
