import { COLORS, H3 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  LeftSide,
  RightSide,
  TitleContainer,
  ImgWrapper,
  Wrapper,
  HeaderWrapper,
  ButtonContainer,
  ButtonWrapper,
  RouterWrapper,
} from './SingleSupplierPage.styled';
import Button from 'components/Button/Button';
import { subMenuItems } from './constants';
import {
  useGetSingleSupplier,
  useGetSingleSupplierComments,
  useGetSingleSupplierInfo,
} from './hooks';
import { useEffect, useState } from 'react';
import { useActivateSupplier, useDeleteSupplier } from './hooks';
import { RoutesConfig } from 'navigation/routes';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { CaretLeft, ChatCircle, WarningCircle } from '@phosphor-icons/react';
import SubSideMenu from 'components/Layout/SubSideMenu/SubSideMenu';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import SingleSupplierRouter from 'navigation/SingleSupplier/SingleSupplierRouter';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { CommentsModal } from './CommentsModal/CommentsModal';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import { mapSubSideMenuItemsInfo } from 'components/Layout/SubSideMenu/helpers';

const COMMENTS_PER_PAGE = 3;
const FIRST_PAGE = 1;

const SingleSupplierPage = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const canEditSupplier = useCan(Actions.EDIT_PURCHASE_ORDER);
  const canCreatePurchaseOrder = useCan(Actions.CREATE_PURCHASE_ORDER);
  const canCreateItem = useCan(Actions.CREATE_STOCK_ITEM);

  const {
    data: supplierData,
    isLoading,
    isError: isErrorSupplier,
  } = useGetSingleSupplier(id || '');

  const { data: supplierComments } = useGetSingleSupplierComments(
    id || '',
    true,
    FIRST_PAGE,
    COMMENTS_PER_PAGE
  );

  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const {
    mutate: deleteSupplier,
    isLoading: deleteSupplierIsLoading,
    isSuccess: deleteSupplierIsSuccess,
  } = useDeleteSupplier();

  const {
    mutate: activateSupplier,
    isLoading: activateSupplierIsLoading,
    isSuccess: activateSupplierIsSuccess,
  } = useActivateSupplier();

  const { data: infoData } = useGetSingleSupplierInfo(id!);

  useEffect(() => {
    if (deleteSupplierIsSuccess) {
      setIsDeleteModalOpen(false);
    }
  }, [deleteSupplierIsSuccess]);

  useEffect(() => {
    if (activateSupplierIsSuccess) {
      setIsActivateModalOpen(false);
    }
  }, [activateSupplierIsSuccess]);

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet } = useBreakpointFlag();
  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  return !isErrorSupplier ? (
    <Container>
      <SubSideMenu
        subMenuItems={subMenuItems}
        subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.supplier_info)}
      />
      <Wrapper>
        <HeaderWrapper
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                svg={CaretLeft}
                marginRight="15rem"
                size={15}
                color={COLORS.BLACK}
                weight="regular"
                onClick={() => navigate(RoutesConfig.Suppliers.fullPath)}
              />
              {isLoading ? (
                <Spinner size={15} />
              ) : (
                <H3>{supplierData?.supplier?.company_name}</H3>
              )}
            </LeftSide>
            <RightSide>
              <ImgWrapper>
                <Tooltip content={t('Comments')} key={'commentKey'}>
                  <Icon
                    svg={ChatCircle}
                    color={COLORS.PRIMARY}
                    size={25}
                    onClick={() => setIsCommentModalOpen(true)}
                  />
                </Tooltip>
                {!!supplierComments?.total && (
                  <Icon svg={WarningCircle} size={25} color={COLORS.PRIMARY} />
                )}
              </ImgWrapper>
            </RightSide>
          </TitleContainer>
          <ButtonContainer>
            <ButtonWrapper>
              {!supplierData?.supplier?.deleted_at ? (
                <Button
                  width={'200rem'}
                  onClick={() => setIsDeleteModalOpen(true)}
                  label={t('Delete Supplier')}
                  disabled={!canEditSupplier}
                  redOutline
                />
              ) : (
                <Button
                  width={'200rem'}
                  onClick={() => setIsActivateModalOpen(true)}
                  secondary
                  label={t('Activate Supplier')}
                  disabled={!canEditSupplier}
                />
              )}
              <Button
                width={'200rem'}
                onClick={() =>
                  navigate(RoutesConfig.NewStockItem.fullPath, {
                    state: { prepopulatePurchaseOrderData: supplierData },
                  })
                }
                label={t('Create stock item')}
                secondary
                disabled={!canCreateItem}
              />
              <Button
                data-testid="create-purchase-order-button"
                width={'200rem'}
                onClick={() =>
                  navigate(
                    `${SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath}`.replace(
                      ':id',
                      id || ''
                    ),
                    {
                      state: {
                        prepopulatePurchaseOrderData: {
                          supplierData: supplierData,
                        },
                      },
                    }
                  )
                }
                label={t('Create purchase order')}
                primary
                disabled={!canCreatePurchaseOrder}
              />
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderWrapper>

        <RouterWrapper routerWithoutPaddingBottom={routerWithoutPaddingBottom}>
          <SingleSupplierRouter />
        </RouterWrapper>
      </Wrapper>

      <YesOrNoModal
        pwId="yes-or-no-activate-supplier-modal"
        disabled={activateSupplierIsLoading}
        isOpen={isActivateModalOpen}
        setIsOpen={setIsActivateModalOpen}
        onYes={() => {
          activateSupplier(id || '');
        }}
        onNo={() => setIsActivateModalOpen(false)}
        title={t('Activate Supplier')}
        description={`${t(`Are you sure you want to activate this Supplier`)}?`}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteSupplier(id || '');
            },
            text: t('Delete'),
            disabled: deleteSupplierIsLoading,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Delete Supplier')}
          description={
            t(
              `Are you sure you want to set this Supplier's status to Deleted`
            ) + '?'
          }
        />
      </Modal>
      <CommentsModal
        isOpen={isCommentModalOpen}
        setIsOpen={setIsCommentModalOpen}
        supplierId={id || ''}
        onCancel={() => setIsCommentModalOpen(false)}
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleSupplierPage;
