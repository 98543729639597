import { useEffect } from 'react';

// We disable scrolling here because the value of the number input fields that are both focused and hovered value will increment/decrement based on the scroll direction and intensity
const usePreventScrollOnFocusedNumberInput = () => {
  useEffect(() => {
    const preventScrollOnFocusedNumberInput = (event: any) => {
      if (
        event.target.type === 'number' &&
        event.target === document.activeElement
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', preventScrollOnFocusedNumberInput, {
      passive: false,
    });

    return () => {
      document.removeEventListener('wheel', preventScrollOnFocusedNumberInput);
    };
  }, []);
};

export default usePreventScrollOnFocusedNumberInput;
