import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  width: 557rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.label`
  padding-bottom: 28rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  text-align: center;
`;

export const ExpiredLink = styled(Heading)`
  padding-bottom: 0;
`;

export const Message = styled.p`
  font-size: 16rem;
  color: ${COLORS.BLACK};
  text-align: center;
  line-height: 30rem;
  margin-bottom: 20rem;
`;

export const ConfirmedMessage = styled.p`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  text-align: center;
  line-height: 30rem;
  margin-top: 20rem;
`;
