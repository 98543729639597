import { UpdateAvailableModal } from 'components/Modal/UpdateAvailableModal/UpdateAvailableModal';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface IUpdateAvailableModalProviderProps {
  children: React.ReactNode;
}

const UpdateAvailableModalProvider = ({
  children,
}: IUpdateAvailableModalProviderProps) => {
  const isUpdateAvailable = useSelector(
    (state: IRootReducerState) => state.commonInfo.isUpdateAvailable
  );
  const handleOnSubmit = () => {
    window.location.reload();
  };
  return (
    <>
      <UpdateAvailableModal
        onSubmit={handleOnSubmit}
        isOpen={isUpdateAvailable}
      />
      {children}
    </>
  );
};

export default UpdateAvailableModalProvider;
