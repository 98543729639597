import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
} from './Danger.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';

interface IDangerProps {
  submit: { onClick: () => void; text: string; disabled: boolean };
  cancel?: { onClick: () => void; text?: string };
  description?: string;
  title: string;
}

export const Danger = ({
  submit,
  cancel,
  description,
  title,
}: IDangerProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <TopContainer>
        <CloseContainer>
          <Close onClick={cancel?.onClick} />
        </CloseContainer>
        <TitleContainer>
          <Title>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description>{description}</Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            onClick={cancel?.onClick}
            secondary
            width="200rem"
            label={cancel?.text ? cancel.text : t('Cancel')}
          />
        </Margin>
        <Button
          red
          disabled={submit.disabled}
          width="200rem"
          label={submit?.text ? submit.text : t('Delete')}
          onClick={() => submit.onClick()}
        />
      </ButtonContainer>
    </div>
  );
};
