import {
  FormContainer,
  FormikContainer,
  ProductAttributeTypesWrapper,
  ProductTypeLabel,
  SpaceBetween,
} from './ProductFieldAttributeForm.styled';
import { Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { IProductAttribute, ProductAttributeType } from 'types/Product.types';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { validateField } from 'utils/validation';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { COLORS } from 'assets/styled';

interface IProductFieldAttributeFormProps {
  isEditMode: boolean;
  isValidForAttribute: boolean;
  setIsValidForAttribute: (attributeIndex: number, isValid: boolean) => void;
  areAllFieldsTouched: boolean;
  attributeIndex: number;
  attribute: IProductAttribute;
  handleSetAttribute: (
    attributeIndex: number,
    values: IProductAttribute | undefined
  ) => void;
}

export const ProductFieldAttributeForm = ({
  isEditMode,
  isValidForAttribute,
  setIsValidForAttribute,
  areAllFieldsTouched,
  attributeIndex,
  attribute,
  handleSetAttribute,
}: IProductFieldAttributeFormProps) => {
  const { t } = useTranslation();
  return (
    <FormContainer>
      <Formik
        enableReinitialize
        initialValues={attribute}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors }) => {
          if (isValid !== isValidForAttribute) {
            setIsValidForAttribute(attributeIndex, isValid);
          }
          return (
            <FormikContainer>
              <SpaceBetween>
                <ProductAttributeTypesWrapper>
                  <ProductTypeLabel
                    data-testid={`field-label-${attributeIndex}`}
                    onClick={() => {
                      handleSetAttribute(attributeIndex, {
                        ...values,
                        type: ProductAttributeType.FIELD,
                      });
                    }}
                    isSelected={values.type === ProductAttributeType.FIELD}
                  >
                    {t(ProductAttributeType.FIELD)}
                  </ProductTypeLabel>
                  <ProductTypeLabel
                    data-testid={`dropdown-label-${attributeIndex}`}
                    onClick={() => {
                      handleSetAttribute(attributeIndex, {
                        ...values,
                        type: ProductAttributeType.DROPDOWN,
                      });
                    }}
                    isSelected={values.type === ProductAttributeType.DROPDOWN}
                  >
                    {t(ProductAttributeType.DROPDOWN)}
                  </ProductTypeLabel>
                </ProductAttributeTypesWrapper>
                {isEditMode ? null : (
                  <Icon
                    svg={Trash}
                    color={COLORS.RED_200}
                    weight="regular"
                    size={20}
                    onClick={() =>
                      handleSetAttribute(attributeIndex, undefined)
                    }
                  />
                )}
              </SpaceBetween>
              <Input
                pwId={`fieldname-field-${attributeIndex}`}
                errorMessage={areAllFieldsTouched ? errors['fieldName'] : ''}
                height={'41rem'}
                name="fieldName"
                placeholder={t('Field name')}
                validate={(value: string) => validateField(value, 'fieldName')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('fieldName', value);
                  handleBlur(e);
                  handleSetAttribute(attributeIndex, {
                    ...values,
                    fieldName: value,
                  });
                }}
                wrapperStyles={{
                  width: '300rem',
                }}
              />
              <Checkbox
                isChecked={values.hasDefaultValue}
                label={t('Has default value')}
                onChange={() => {
                  setFieldValue('hasDefaultValue', !values.hasDefaultValue);
                  setFieldValue('defaultValue', '');
                  handleSetAttribute(attributeIndex, {
                    ...values,
                    hasDefaultValue: !values.hasDefaultValue,
                    defaultValue: '',
                  });
                }}
              />
              {values.hasDefaultValue && (
                <Input
                  pwId={`default-value-field-${attributeIndex}`}
                  height={'41rem'}
                  name="defaultValue"
                  placeholder={t('Default value')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('defaultValue', value);
                    handleBlur(e);
                    handleSetAttribute(attributeIndex, {
                      ...values,
                      defaultValue: value,
                    });
                  }}
                  wrapperStyles={{
                    width: '300rem',
                    marginTop: '10rem',
                  }}
                />
              )}
            </FormikContainer>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
