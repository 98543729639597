import { COLORS, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  margin: 10rem 0;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  margin-top: 20rem;
  align-items: center;
  justify-content: center;
  gap: 30rem;
`;
