export const validateSalesOrderLineIdsAndSalesOrderFields = (
  salesOrderId: string | number | undefined,
  salesOrderLinesIds: string[] | number[],
  setFieldError: any,
  t: (string: string) => string
) => {
  if (!salesOrderId) {
    setFieldError('sales_order_id', t('Sales order is required'));
    return false;
  }

  if (!salesOrderLinesIds.length) {
    setFieldError(
      'sales_order_lines_ids',
      t('Please choose at least one product')
    );
    return false;
  }

  setFieldError('sales_order_id', undefined);
  setFieldError('sales_order_lines_ids', undefined);
  return true;
};
