import { IFilterGroup } from 'components/Modal/AdvancedFilterModal/types';
import { Option } from 'components/Select/type';
import moment, { Moment } from 'moment';

export enum DateInterval {
  LAST_7_DAYS = 'last7days',
  CURRENT_MONTH = 'currentmonth',
  LAST_MONTH = 'lastmonth',
  CURRENT_QUARTER = 'currentquarter',
  LAST_QUARTER = 'lastquarter',
  CURRENT_YEAR = 'currentyear',
  LAST_YEAR = 'lastyear',
  ALL_TIME = '',
}

export const useGetDateIntervalOptions = () => {
  const dateIntervalOptions: Option[] = [
    { value: DateInterval.LAST_7_DAYS, label: 'Last 7 days', key: 'last7days' },
    {
      value: DateInterval.CURRENT_MONTH,
      label: 'Current month',
      key: 'currentmonth',
    },
    { value: DateInterval.LAST_MONTH, label: 'Last Month', key: 'lastmonth' },
    {
      value: DateInterval.CURRENT_QUARTER,
      label: 'Current quarter',
      key: 'currentquarter',
    },
    {
      value: DateInterval.LAST_QUARTER,
      label: 'Last quarter',
      key: 'lastquarter',
    },
    {
      value: DateInterval.CURRENT_YEAR,
      label: 'Current year',
      key: 'currentyear',
    },
    { value: DateInterval.LAST_YEAR, label: 'Last year', key: 'lastyear' },
    { value: DateInterval.ALL_TIME, label: 'All-time', key: 'all' },
  ];
  return dateIntervalOptions;
};

const _formatDate = (date: Moment) => moment(date).format('YYYY-MM-DD');

const _getDateIntervalValues = (interval: DateInterval) => {
  const today = moment();
  const tomorrow = today.clone().add(1, 'day');
  switch (interval) {
    case DateInterval.LAST_7_DAYS: {
      return {
        start: _formatDate(today.clone().subtract(7, 'days')),
        end: _formatDate(tomorrow),
      };
    }
    case DateInterval.CURRENT_MONTH:
      return {
        start: _formatDate(today.clone().startOf('month')),
        end: _formatDate(tomorrow),
      };
    case DateInterval.LAST_MONTH: {
      const lastMonth = today.clone().subtract(1, 'month');
      return {
        start: _formatDate(lastMonth.clone().startOf('month')),
        end: _formatDate(lastMonth.clone().endOf('month')),
      };
    }
    case DateInterval.CURRENT_QUARTER:
      return {
        start: _formatDate(today.clone().startOf('quarter')),
        end: _formatDate(today.clone().endOf('quarter')),
      };
    case DateInterval.LAST_QUARTER: {
      const lastQuarter = today.clone().subtract(1, 'quarter');
      return {
        start: _formatDate(lastQuarter.clone().startOf('quarter')),
        end: _formatDate(lastQuarter.clone().endOf('quarter')),
      };
    }
    case DateInterval.CURRENT_YEAR:
      return {
        start: _formatDate(today.clone().startOf('year')),
        end: _formatDate(tomorrow),
      };
    case DateInterval.LAST_YEAR: {
      const lastYear = today.clone().subtract(1, 'year');
      return {
        start: _formatDate(lastYear.clone().startOf('year')),
        end: _formatDate(lastYear.clone().endOf('year')),
      };
    }
    case DateInterval.ALL_TIME:
      return { start: '', end: '' }; // All-time
    default:
      return { start: '', end: '' };
  }
};

export const getInitialFilterGroupsFromDateInterval = (
  interval: DateInterval
): IFilterGroup[] => {
  const dateValues = _getDateIntervalValues(interval);
  return [
    {
      id: `group_${interval}_1`,
      filters: [
        {
          id: `filter_${interval}_start`,
          type: 'date',
          condition: 'gt',
          value: dateValues.start,
        },
      ],
      subFilters: [],
    },
    {
      id: `group_${interval}_2`,
      filters: [
        {
          id: `filter_${interval}_end`,
          type: 'date',
          precondition: 'and',
          condition: 'lt',
          value: dateValues.end,
        },
      ],
      subFilters: [],
    },
  ];
};
