import Button from 'components/Button/Button';
import {
  Container,
  LocationLabel,
  TopRow,
} from './FwSingleAppointmentLocationPage.styled';
import { useTranslation } from 'react-i18next';
import FwMapComponent from './FwMapComponent/FwMapComponent';
import { useParams } from 'react-router-dom';
import { useGetSingleFwAppointment } from '../hooks';
import { getLocationLabelFromGoogleMapsURL } from './helpers';
import Spinner from 'components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const FwSingleAppointmentLocationPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  const { data: appointmentData, isLoading: isLoadingAppointment } =
    useGetSingleFwAppointment(id!);

  const handleStartNavigation = () => {
    if (appointmentData?.google_maps_url) {
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.OPEN_GOOGLE_MAPS,
          payload: appointmentData.google_maps_url,
        };
        postMobileMessage(mobileMessage);
      } else {
        window.open(appointmentData.google_maps_url, '_blank');
      }
    }
  };

  const isLocationValid =
    !!appointmentData?.coords?.lat && !!appointmentData?.coords?.lng;

  const { steps } = useRunTour(GuidePages.FIELD_WORKER_LOCATION);

  return (
    <Container className="fw-location-step-1">
      <TopRow>
        <LocationLabel>
          {isLoadingAppointment ? (
            <Spinner size={20} />
          ) : !isLocationValid ? (
            t('Could not determine location')
          ) : (
            getLocationLabelFromGoogleMapsURL(appointmentData?.google_maps_url)
          )}
        </LocationLabel>
        {isLocationValid ? (
          <Button
            label={t('Start navigation')}
            onClick={() => handleStartNavigation()}
            width="200rem"
            secondary
            disabled={isLoadingAppointment}
          />
        ) : null}
      </TopRow>
      {isLocationValid && appointmentData?.coords ? (
        <FwMapComponent
          lat={appointmentData.coords.lat}
          lng={appointmentData.coords.lng}
        />
      ) : null}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default FwSingleAppointmentLocationPage;
