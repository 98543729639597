import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import {
  getCompany,
  getCompanyLogo,
  getCompanySettings,
} from 'services/Company/CompanyService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ERPError, getToastErrorMessage } from 'services/api/errors';

export const useGetCompany = (userCompanyId: string) =>
  useMutation(() => getCompany(userCompanyId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_COMPANY,
      });
    },
    mutationKey: ReactMutationKeys.GET_COMPANY,
  });

export const useGetCompanySettings = () =>
  useMutation(getCompanySettings, {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_COMPANY_SETTINGS,
      });
    },
    mutationKey: ReactMutationKeys.GET_COMPANY_SETTINGS,
  });

export const useGetCompanyLogo = (userCompanyId: string) =>
  useMutation(() => getCompanyLogo(userCompanyId), {
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.GET_COMPANY_LOGO,
      });
    },
    mutationKey: ReactMutationKeys.GET_COMPANY_LOGO,
  });
