import { queryClient } from 'index';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deletePurchaseInvoiceFile,
  editPurchaseInvoiceFile,
  getPurchaseInvoiceFiles,
  uploadPurchaseInvoiceFile,
} from 'services/Invoice/PurchaseInvoiceService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';

export const useGetPurchaseInvoiceFiles = (
  perPage: number,
  purchaseInvoiceId: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_PURCHASE_INVOICE_FILES, purchaseInvoiceId],
    queryFn: ({ pageParam = 1 }) =>
      getPurchaseInvoiceFiles(purchaseInvoiceId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
  });

interface IUseDeletePurchaseInvoiceFile {
  purchaseInvoiceId: string;
  purchaseInvoiceFileId: string;
}
export const useDeletePurchaseInvoiceFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeletePurchaseInvoiceFile) =>
      deletePurchaseInvoiceFile(
        params.purchaseInvoiceId,
        params.purchaseInvoiceFileId
      ),
    {
      onSuccess: () => {
        toast.success(t('File successfully deleted'));
        invalidateFileQueries();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_PURCHASE_INVOICE_FILE,
        });
      },
    }
  );
};

interface IUseUploadPurchaseInvoiceFileParams {
  files: any;
  purchaseInvoiceId: string;
}
export const useUploadPurchaseInvoiceFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadPurchaseInvoiceFileParams) => {
      const fileFormData = new FormData();

      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      return uploadPurchaseInvoiceFile(params.purchaseInvoiceId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_PURCHASE_INVOICE_FILE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE_INFO
        );
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_PURCHASE_INVOICE_FILES
        );
      },
    }
  );
};

export const useEditPurchaseInvoiceFileName = (
  purchaseInvoiceId: string,
  fileId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (newFileName: string) =>
      editPurchaseInvoiceFile(purchaseInvoiceId, fileId, newFileName),
    {
      onSuccess: () => {
        toast.success(t('File name updated'), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_INVOICE_FILE_NAME,
        });
        const shouldInvalidateInfoQueries = false;
        invalidateFileQueries(shouldInvalidateInfoQueries);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_INVOICE_FILE_NAME,
        });
      },
      mutationKey: ReactMutationKeys.EDIT_PURCHASE_INVOICE_FILE_NAME,
    }
  );
};
