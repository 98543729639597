import i18n from 'providers/i18n/i18n';
import { useEffect, useState } from 'react';

const languageMap = {
  rs: 'sr',
  no: 'nb',
};

export const useLocale = () => {
  const [locale, setLocale] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setLocale(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const momentLocale = languageMap[locale] || locale;

  return { locale: momentLocale };
};
