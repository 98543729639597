import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IBulkBookTableDTO, IPurchaseOrder } from 'types/PurchaseOrders.types';

export const formatBulkBookTableData = (data: IPurchaseOrder[]) => {
  const formatDeliveryDate = (date: string | undefined): string => {
    return date ? moment(date).format('DD-MM-YYYY') : '';
  };
  const { t } = useTranslation();
  return data?.map((purchaseOrder: IPurchaseOrder) => {
    const bulkBookTableDTO: IBulkBookTableDTO = {
      number: purchaseOrder?.number,
      reference: purchaseOrder?.reference,
      salesOrder: purchaseOrder?.sales_order_number,
      status: t(purchaseOrder?.status.name),
      deliveryDate: formatDeliveryDate(purchaseOrder?.delivery_date),
      entityId: purchaseOrder?.id,
    };
    return bulkBookTableDTO;
  });
};
