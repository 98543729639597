export interface ERPError {
  error: {
    message: string;
  };
  response: ERPErrorResponse;
}

interface ERPErrorResponse {
  data: {
    code: number;
    message: string;
    data: Record<string, unknown>;
    errors: string[];
  };
  status: number;
}

export const DEFAULT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please refresh the page and try again.';

export const getToastErrorMessage = (error: any) => {
  if (error.response?.data?.message === 'Unprocessable entity') {
    return handleUnprocessableEntityErrorMessage(error);
  } else {
    return handleGeneralErrorMessage(error);
  }
};

// This handles errors of type flask marshmallow 422 ValidationErrors
// Pure UnprocessableEntity error responses will need to be handled differently
const handleUnprocessableEntityErrorMessage = (error: any) => {
  if (
    !error.response ||
    !error.response.data ||
    !error.response.data.errors ||
    error.response.data.errors.length === 0
  ) {
    return DEFAULT_ERROR_MESSAGE;
  }

  const errorMessages: string[] = [];

  error.response.data.errors.forEach((errorString: string) => {
    try {
      // Convert single-quoted JSON to valid JSON by replacing single quotes and parsing it
      const correctedJsonString = errorString.replace(/'/g, '"');
      const errorsJson = JSON.parse(correctedJsonString);

      // Extract messages from each key in the parsed object
      Object.keys(errorsJson).forEach((errorsKey: string) => {
        const messages = errorsJson[errorsKey];
        messages.forEach((message: string) => {
          errorMessages.push(`${errorsKey}: ${message}; \n`);
        });
      });
    } catch (e) {
      // Error parsing JSON
      return DEFAULT_ERROR_MESSAGE;
    }
  });

  const errorMessage = errorMessages.join(', ');

  return errorMessage || DEFAULT_ERROR_MESSAGE;
};

const handleGeneralErrorMessage = (error: any) => {
  const errorMessages = [];
  if (!error.response?.data || !error.response.data.errors) {
    return DEFAULT_ERROR_MESSAGE;
  }

  // Process each error message in the array
  if (error.response.data.errors) {
    for (const msg of error.response.data.errors) {
      // Split the message by colon
      const msgParts = msg.split(':');
      if (msgParts.length > 1) {
        // Exclude the first part and trim the remaining parts before joining them back
        const meaningfulPart = msgParts.slice(1).join(':').trim();
        errorMessages.push(meaningfulPart);
      } else {
        // If no colon is found, use the whole message
        errorMessages.push(msg.trim());
      }
    }
  }

  // Join all processed messages with a comma separator
  const errorMessage = errorMessages.join(', ');
  return errorMessage;
};
