import axios from 'axios';
import { config } from 'config';
import { APP_SESSION_TYPE, WEB_SESSION_TYPE } from './constants';
import { store } from 'store/store';

const API_URL = config.api.baseUrl;

const openApiClient = axios.create({
  baseURL: API_URL,
});

openApiClient.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['Session-Type'] = store.getState().commonInfo.isMobileApp
      ? APP_SESSION_TYPE
      : WEB_SESSION_TYPE;
  }
  return config;
});

openApiClient.interceptors.response.use(
  (response) => response,
  (error: any) => {
    return Promise.reject(error);
  }
);

export default openApiClient;
