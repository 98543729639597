import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import {
  AlignRow,
  CloseContainer,
  FormikContainer,
  Margin,
  Row,
  SubmitRow,
  Title,
  TitleContainer,
} from './EditStockItemDetails.styled';
import { Input } from 'components/Input/InputFormik';
import { createStockItemDetailsTabSchema } from './validation';
import { marginMd, marginXs } from 'assets/styled';
import Button from 'components/Button/Button';
import { IStockItem } from 'types/StockItem.types';
import { useParams } from 'react-router-dom';
import { useEditStock } from './hooks';

interface IEditStockItemDetailsModal {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  stockItemDetails: IStockItem;
}
export const EditStockItemDetailsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  stockItemDetails,
}: IEditStockItemDetailsModal) => {
  const { id } = useParams();
  const { mutate: editStockItem } = useEditStock(id!);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit stock item')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          initialValues={stockItemDetails}
          validationSchema={createStockItemDetailsTabSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
          validateOnMount={true}
        >
          {({
            handleBlur,
            setFieldValue,
            submitForm,
            isValid,
            values,
            errors,
            touched,
          }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    pwId="name-field"
                    errorMessage={touched['name'] ? errors['name'] : ''}
                    height={'41rem'}
                    name="name"
                    placeholder={t('Name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('Name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '300rem',
                    }}
                  />
                  <Input
                    pwId="number-supplier-field"
                    errorMessage={
                      touched['number_supplier']
                        ? errors['number_supplier']
                        : ''
                    }
                    height={'41rem'}
                    name="number_supplier"
                    placeholder={t('Item supplier number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('number_supplier', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      marginLeft: marginMd,
                      width: '300rem',
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    pwId="price-field"
                    type="number"
                    errorMessage={touched['price'] ? errors['price'] : ''}
                    height={'41rem'}
                    name="price"
                    placeholder={t('Sales price')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('price', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '300rem',
                      marginTop: marginXs,
                    }}
                  />
                  <Input
                    pwId="purchase-price-field"
                    type="number"
                    errorMessage={
                      touched['purchase_price'] ? errors['purchase_price'] : ''
                    }
                    height={'41rem'}
                    name="purchase_price"
                    placeholder={t('Purchase Price exc. VAT')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('purchase_price', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '300rem',
                      marginLeft: marginMd,
                      marginTop: marginXs,
                    }}
                  />
                </Row>

                <Row>
                  <Input
                    pwId="description-field"
                    isTextArea
                    errorMessage={
                      touched['description'] ? errors['description'] : ''
                    }
                    height={'100rem'}
                    name="description"
                    placeholder={t('Description')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('description', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '600rem',
                    }}
                  />
                </Row>

                <AlignRow>
                  <SubmitRow>
                    <Margin>
                      <Button
                        width={'200rem'}
                        onClick={onCancel}
                        label={t('Cancel')}
                        secondary
                      />
                    </Margin>
                    <Button
                      disabled={
                        !values.name || !values.price || !values.purchase_price
                      }
                      width={'200rem'}
                      onClick={() => {
                        if (isValid) {
                          editStockItem({
                            name: values.name,
                            price: values.price,
                            purchase_price: values.purchase_price,
                            description: values.description,
                            number_supplier: values.number_supplier,
                          });
                          setIsOpen(false);
                        }
                      }}
                      label={t('Edit')}
                      primary
                    />
                  </SubmitRow>
                </AlignRow>
              </FormikContainer>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
