import { AxiosError } from 'axios';
import { FwSingleAppointmentRoutes } from 'navigation/FwSingleAppointment/FwSingleAppointment.routes';
import { RoutesConfig } from 'navigation/routes';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Placement, Step } from 'react-joyride';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  addIntroGuideCompletedPage,
  changeIntroGuideCompletedStatus,
} from 'services/User/UserService';
import { setRunTour } from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import { GuidePages } from './constants';
import { isCurrentLocationMatchingRoute } from './helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { toast } from 'utils/toast';

export const useGetGuideSteps = (page: string, id?: string): Step[] => {
  const { t } = useTranslation();
  const { isTablet } = useBreakpointFlag();

  switch (page) {
    case GuidePages.HOME:
      return [
        {
          title: t('Home Page'),
          target: '.home-step-1',
          content: t(
            'Your Home Page is where you have an overview of all things Blueprint related. You can see the most pertinent information about your Blueprint account and access shortcuts to those pages if need be.'
          ),
          placement: 'center' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
        {
          title: t('Profile'),
          target: '.home-step-2',
          content: t(
            'Here you can access Profile Settings, and User Guides, switch to Dark Mode or Field Worker view, or Log Out.'
          ),
          placement: isTablet
            ? ('bottom-end' as Placement)
            : ('bottom' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
        {
          title: t('Languages'),
          target: '.home-step-3',
          content: t(
            'Blueprint currently supports 6 different languages. You can choose which one suits you the best.'
          ),
          placement: isTablet
            ? ('bottom-end' as Placement)
            : ('bottom' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
        {
          title: t('Notifications'),
          target: '.home-step-4',
          content: t(
            'In Notifications, you will get alerts of new actions happening on your Blueprint account.'
          ),
          placement: isTablet
            ? ('bottom-end' as Placement)
            : ('bottom' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
        {
          title: t('Settings'),
          target: '.home-step-5',
          content: t(
            'Settings lets you edit multiple in-app features of your Blueprint account.'
          ),
          placement: isTablet
            ? ('bottom-end' as Placement)
            : ('bottom' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
        {
          title: t('Navigation Bar'),
          target: '.home-step-6',
          content: t(
            'The Navigation Bar is where all the pages of the Blueprint app are located. You can use it at any time to easily navigate through the app.'
          ),
          placement: 'right' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Home.fullPath,
            page: GuidePages.HOME,
          },
        },
      ];
    case GuidePages.QUOTATIONS:
      return [
        {
          title: t('Quotation Overview'),
          target: '.quotations-step-1',
          content: t(
            'The Quotations page is where a list of all the Quotations you have on Blueprint are located.'
          ),
          placement: 'center' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Quotations.fullPath,
            page: GuidePages.QUOTATIONS,
          },
        },
        {
          title: t('Filtering'),
          target: '.quotations-step-2',
          content: t(
            'You can see important data about the individual Quotations, search for the ones you are looking for, and filter based on Date, Activity, Status and Type.'
          ),
          placement: isTablet
            ? ('bottom-start' as Placement)
            : ('bottom-end' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Quotations.fullPath,
            page: GuidePages.QUOTATIONS,
          },
        },
        {
          title: t('Create Quotation'),
          target: '.quotations-step-3',
          content: t(
            'From here, you can also create a New Quotation when need be.'
          ),
          placement: isTablet ? ('left' as Placement) : ('bottom' as Placement),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.Quotations.fullPath,
            page: GuidePages.QUOTATIONS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION:
      return [
        {
          title: t('Quotation'),
          target: '.single-quotation-step-1',
          content: t(
            'On the single Quotation page, further details about the Quotation in question can be found, as well as ways to alter and adjust that Quotation if need be.'
          ),
          placement: 'center' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleQuotation.fullPath,
            page: GuidePages.SINGLE_QUOTATION,
          },
        },
        {
          title: t('Status Change'),
          target: '.single-quotation-step-2',
          content: t(
            'You can manually change the status of a Quotation at any time.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleQuotation.fullPath,
            page: GuidePages.SINGLE_QUOTATION,
          },
        },
        {
          title: t('Quick Actions'),
          target: '.single-quotation-step-3',
          content: t(
            'The quick actions are a great part of the flow, and from the you can add the customer signature, open the PDF of the Quotation and send and e-mail to the customer.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleQuotation.fullPath,
            page: GuidePages.SINGLE_QUOTATION,
          },
        },
        {
          title: t('Actions'),
          target: '.single-quotation-step-4',
          content: t(
            'The Action buttons are the main part of the Quotation’s flow. From them you can create a new Measurement, Edit the Quotation, or create a Sales Order from the Quotation.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleQuotation.fullPath,
            page: GuidePages.SINGLE_QUOTATION,
          },
        },
        {
          title: t('General'),
          target: '.single-quotation-step-5',
          content: t(
            'On the General subpage, you can find the Order and Customer details, with the ability to edit some of them. There is also a handy Timeline of all the events linked to the Quotation.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationProducts.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_PRODUCTS,
            id: id,
            fullPath: RoutesConfig.SingleQuotation.fullPath,
            page: GuidePages.SINGLE_QUOTATION,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_PRODUCTS:
      return [
        {
          title: t('Product & Services'),
          target: '.single-quotation-step-6',
          content: t(
            'This is the subpage where the products and services are located. You can edit and delete them, as well as rearrange them with drag and drop if need be.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationComments.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_COMMENTS,
            id: id,
            fullPath: SingleQuotationRoutes.SingleQuotationProducts.fullPath,
            page: GuidePages.SINGLE_QUOTATION_PRODUCTS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_COMMENTS:
      return [
        {
          title: t('Comments'),
          target: '.single-quotation-step-7',
          content: t(
            'The comments subpage is meant for internal and external correspondence about the Quotation.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationQuotations.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_QUOTATIONS,
            id: id,
            fullPath: SingleQuotationRoutes.SingleQuotationComments.fullPath,
            page: GuidePages.SINGLE_QUOTATION_COMMENTS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_QUOTATIONS:
      return [
        {
          title: t('Quotations'),
          target: '.single-quotation-step-8',
          content: t(
            'This subpage shows all past linked Quotations, if there are any, which were created before this one.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationSales.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_SALES_ORDERS,
            id: id,
            fullPath: SingleQuotationRoutes.SingleQuotationQuotations.fullPath,
            page: GuidePages.SINGLE_QUOTATION_QUOTATIONS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_SALES_ORDERS:
      return [
        {
          title: t('Sales Order'),
          target: '.single-quotation-step-9',
          content: t(
            'If the Quotation is already linked to a Sales Order, details about it will be shown on this subpage.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationAppointments.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_APPOINTMENTS,
            id: id,
            fullPath: SingleQuotationRoutes.SingleQuotationSales.fullPath,
            page: GuidePages.SINGLE_QUOTATION_SALES_ORDERS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_APPOINTMENTS:
      return [
        {
          title: t('Appointments'),
          target: '.single-quotation-step-10',
          content: t(
            'Every Appointment which is linked to the Quotation will be displayed here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationReports.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_REPORTS,
            id: id,
            fullPath:
              SingleQuotationRoutes.SingleQuotationAppointments.fullPath,
            page: GuidePages.SINGLE_QUOTATION_APPOINTMENTS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_REPORTS:
      return [
        {
          title: t('Reports'),
          target: '.single-quotation-step-11',
          content: t(
            'If the Quotation is already in the phase of being dealt with on the field, the reports are shown on this subpage.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleQuotationRoutes.SingleQuotationFiles.fullPath,
            nextPage: GuidePages.SINGLE_QUOTATION_FILES,
            id: id,
            fullPath: SingleQuotationRoutes.SingleQuotationReports.fullPath,
            page: GuidePages.SINGLE_QUOTATION_REPORTS,
          },
        },
      ];
    case GuidePages.SINGLE_QUOTATION_FILES:
      return [
        {
          title: t('Files'),
          target: '.single-quotation-step-12',
          content: t(
            'Any Files which might be important to the Quotation can be added here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath: SingleQuotationRoutes.SingleQuotationFiles.fullPath,
            page: GuidePages.SINGLE_QUOTATION_FILES,
          },
        },
      ];
    case GuidePages.SALES_ORDERS:
      return [
        {
          title: t('Sales Order Overview'),
          target: '.sales-orders-step-1',
          content: t(
            'The Sales orders page is where a list of all the Sales orders you have on Blueprint are located.'
          ),
          placement: 'center' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SalesOrders.fullPath,
            page: GuidePages.SALES_ORDERS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER:
      return [
        {
          title: t('Sales Order'),
          target: '.single-sales-order-step-1',
          content: t(
            'On the single Sales Order page, further details about the Sales Order in question can be found, as well as ways to alter and adjust that Sales Order if need be.'
          ),
          disableBeacon: true,
          placement: 'center' as Placement,
          data: {
            fullPath: RoutesConfig.SingleSalesOrder.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER,
          },
        },
        {
          title: t('Status Change'),
          target: '.single-sales-order-step-2',
          content: t(
            'You can manually change the status of a Sales Order at any time.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleSalesOrder.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER,
          },
        },
        {
          title: t('Quick Actions'),
          target: '.single-sales-order-step-3',
          content: t(
            'The quick actions are a great part of the flow, and from the you can add the customer signature, open the PDF of the Sales Order and send and e-mail to the customer.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleSalesOrder.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER,
          },
        },
        {
          title: t('Actions'),
          target: '.single-sales-order-step-4',
          content: t(
            "The Action buttons are the main part of the Sales Order's flow. From them you can create a new Invoice, create a new Appointment and create a new Purchase Order."
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleSalesOrder.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER,
          },
        },
        {
          title: t('General'),
          target: '.single-sales-order-step-5',
          content: t(
            'On the General subpage, you can find the Order and Customer details, with the ability to edit some of them. There is also a handy Timeline of all the events linked to the Sales Order.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderProducts.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_PRODUCTS,
            id: id,
            fullPath: RoutesConfig.SingleSalesOrder.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_PRODUCTS:
      return [
        {
          title: t('Product & Services'),
          target: '.single-sales-order-step-6',
          content: t(
            'This is the subpage where the products and services are located. You can edit and delete them, as well as rearrange them with drag and drop if need be.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderComments.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_COMMENTS,
            id: id,
            fullPath: SingleSalesOrderRoutes.SingleSalesOrderProducts.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_PRODUCTS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_COMMENTS:
      return [
        {
          title: t('Comments'),
          target: '.single-sales-order-step-7',
          content: t(
            'The comments subpage is meant for internal and external correspondence about the Sales Order.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderQuotations.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_QUOTATIONS,
            id: id,
            fullPath: SingleSalesOrderRoutes.SingleSalesOrderComments.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_COMMENTS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_QUOTATIONS:
      return [
        {
          title: t('Quotations'),
          target: '.single-sales-order-step-8',
          content: t(
            'This subpage shows all past linked Quotations which were created and linked to the Sales Order.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderInvoices.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_INVOICES,
            id: id,
            fullPath:
              SingleSalesOrderRoutes.SingleSalesOrderQuotations.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_QUOTATIONS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_INVOICES:
      return [
        {
          title: t('Invoices'),
          target: '.single-sales-order-step-9',
          content: t(
            'This subpage shows all of the Invoices which have been linked to the Sales Order.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderPurchaseOrders
              .fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_PURCHASE_ORDERS,
            id: id,
            fullPath: SingleSalesOrderRoutes.SingleSalesOrderInvoices.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_INVOICES,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_PURCHASE_ORDERS:
      return [
        {
          title: t('Purchase Orders'),
          target: '.single-sales-order-step-10',
          content: t(
            'This subpage shows all of the Purchase Orders which have been made from the Sales Order.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderAppointments.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_APPOINTMENTS,
            id: id,
            fullPath:
              SingleSalesOrderRoutes.SingleSalesOrderPurchaseOrders.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_PURCHASE_ORDERS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_APPOINTMENTS:
      return [
        {
          title: t('Appointments'),
          target: '.single-sales-order-step-11',
          content: t(
            'Every Appointment which is linked to the Sales Order will be displayed here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderReports.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_REPORTS,
            id: id,
            fullPath:
              SingleSalesOrderRoutes.SingleSalesOrderAppointments.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_APPOINTMENTS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_REPORTS:
      return [
        {
          title: t('Reports'),
          target: '.single-sales-order-step-12',
          content: t(
            'If the Sales Order is already in the phase of being dealt with on the field, the reports are shown on this subpage.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesOrderRoutes.SingleSalesOrderFiles.fullPath,
            nextPage: GuidePages.SINGLE_SALES_ORDER_FILES,
            id: id,
            fullPath: SingleSalesOrderRoutes.SingleSalesOrderReports.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_REPORTS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_ORDER_FILES:
      return [
        {
          title: t('Files'),
          target: '.single-sales-order-step-13',
          content: t(
            'Any Files which might be important to the Sales Order can be added here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath: SingleSalesOrderRoutes.SingleSalesOrderFiles.fullPath,
            page: GuidePages.SINGLE_SALES_ORDER_FILES,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_INVOICE:
      return [
        {
          title: t('Invoice'),
          target: '.single-sales-invoice-step-1',
          content: t(
            'On the single Invoice page, further details about the Invoice in question can be found, as well as ways to alter and adjust that Invoice if need be.'
          ),
          disableBeacon: true,
          placement: 'center' as Placement,
          data: {
            fullPath: RoutesConfig.SingleSalesInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE,
          },
        },
        {
          title: t('Status Change'),
          target: '.single-sales-invoice-step-2',
          content: t(
            'You can manually change the status of a Invoice at any time.'
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleSalesInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE,
          },
        },
        {
          title: t('Quick Actions'),
          target: '.single-sales-invoice-step-3',
          content: t(
            'The quick actions are a great part of the flow, and from the you can open the PDF of the Invoice and send and e-mail to the customer.'
          ),
          disableBeacon: true,
          placement: isTablet ? ('left' as Placement) : ('auto' as Placement),
          data: {
            fullPath: RoutesConfig.SingleSalesInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE,
          },
        },
        {
          title: t('Actions'),
          target: '.single-sales-invoice-step-4',
          content: t(
            "The Action buttons are the main part of the Invoice's flow. From them you can add new Payments which were made and create a Credit Invoice if need be."
          ),
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.SingleSalesInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE,
          },
        },
        {
          title: t('General'),
          target: '.single-sales-invoice-step-5',
          content: t(
            'On the General subpage, you can find important details about the Invoice like the creation and valid-to dates, the external reference and if it is linked to accounting. You will also find a table with all the products and services linked to it, which can be rearranged via drag and drop.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesInvoiceRoutes.SingleSalesInvoiceSalesOrders
              .fullPath,
            nextPage: GuidePages.SINGLE_SALES_INVOICE_SALES_ORDERS,
            id: id,
            fullPath: RoutesConfig.SingleSalesInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_INVOICE_SALES_ORDERS:
      return [
        {
          title: t('Sales Order'),
          target: '.single-sales-invoice-step-6',
          content: t(
            'All details about the Sales Order to which the Invoice is linked to can be found on this subpage.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesInvoiceRoutes.SingleSalesInvoiceCreditInvoice
              .fullPath,
            nextPage: GuidePages.SINGLE_SALES_INVOICE_CREDIT_INVOICES,
            id: id,
            fullPath:
              SingleSalesInvoiceRoutes.SingleSalesInvoiceSalesOrders.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE_SALES_ORDERS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_INVOICE_CREDIT_INVOICES:
      return [
        {
          title: t('Credit Invoices'),
          target: '.single-sales-invoice-step-7',
          content: t(
            'If there are any, information about Credit Invoices linked to this Invoice are found here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.fullPath,
            nextPage: GuidePages.SINGLE_SALES_INVOICE_PAYMENTS,
            id: id,
            fullPath:
              SingleSalesInvoiceRoutes.SingleSalesInvoiceCreditInvoice.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE_CREDIT_INVOICES,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_INVOICE_PAYMENTS:
      return [
        {
          title: t('Payments'),
          target: '.single-sales-invoice-step-8',
          content: t(
            'Details about all Payments which were made can be found, as well as rearranged via drag and drop.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            next: SingleSalesInvoiceRoutes.SingleSalesInvoiceFiles.fullPath,
            nextPage: GuidePages.SINGLE_SALES_INVOICE_FILES,
            id: id,
            fullPath:
              SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE_PAYMENTS,
          },
        },
      ];
    case GuidePages.SINGLE_SALES_INVOICE_FILES:
      return [
        {
          title: t('Files'),
          target: '.single-sales-invoice-step-9',
          content: t(
            'Any Files which might be important to the Invoice can be added here.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath: SingleSalesInvoiceRoutes.SingleSalesInvoiceFiles.fullPath,
            page: GuidePages.SINGLE_SALES_INVOICE_FILES,
          },
        },
      ];
    case GuidePages.PLANNING:
      return [
        {
          title: t('Planning'),
          target: '.planning-step-1',
          content: t(
            'The Planning page is meant for creating, tracking and changing all appointments linked to your Blueprint account.'
          ),
          disableBeacon: true,
          placement: 'center' as Placement,
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
        {
          title: t('Tabs'),
          target: '.planning-step-2',
          content: t(
            'To help you stay organized and find the appointments you are looking for, you can open multiple tabs, each allowing you to apply different filters.'
          ),
          disableBeacon: true,
          placement: isTablet ? ('left' as Placement) : ('auto' as Placement),
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
        {
          title: t('Filtering'),
          target: '.planning-step-3',
          content: t(
            'There are many ways in which you can filter the appointments on your planing page. You can filter by date or type of appointment and change calendar view.'
          ),
          disableBeacon: true,
          placement: isTablet ? ('left' as Placement) : ('auto' as Placement),
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
        {
          title: t('Filtering'),
          target: '.planning-step-4',
          content: t(
            'Or you can filter by the Users, Workers or Resources tied to the appointment. There is also an option of creating more tabs, each with their own active filters.'
          ),
          disableBeacon: true,
          placement: isTablet ? ('left' as Placement) : ('auto' as Placement),
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
        {
          title: t('Creating Appointments'),
          target: '.planning-step-5',
          content: t(
            'To create an Appointment you can click anywhere on the calendar or press the plus icon at the bottom of the screen.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
        {
          title: t('Field Worker View'),
          target: '.planning-step-6',
          content: t(
            'The Field Worker View is meant for workers who are handling the appointments in the field. It can be accessed through the Profile icon in the menu.'
          ),
          disableBeacon: true,
          placement: isTablet ? ('left' as Placement) : ('auto' as Placement),
          data: {
            fullPath: RoutesConfig.Planning.fullPath,
            page: GuidePages.PLANNING,
          },
        },
      ];
    case GuidePages.FIELD_WORKER:
      return [
        {
          title: t('Appointment'),
          target: '.fw-step-1',
          content: t(
            'The field worker chooses which appointment he wants to view. There, he will find subpages with all pertinent information, similar to those in the manger view.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath: RoutesConfig.FwAppointments.fullPath,
            page: GuidePages.FIELD_WORKER,
          },
        },
      ];
    case GuidePages.FIELD_WORKER_LOCATION:
      return [
        {
          title: t('Location'),
          target: '.fw-location-step-1',
          content: t(
            'This new subpage has the purpose of helping the field worker navigate to their desired location.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath:
              FwSingleAppointmentRoutes.FwSingleAppointmentLocation.fullPath,
            page: GuidePages.FIELD_WORKER_LOCATION,
          },
        },
      ];
    case GuidePages.FIELD_WORKER_TIMESHEET:
      return [
        {
          title: t('Timesheet'),
          target: '.fw-timesheet-step-1',
          content: t(
            'On the Timesheet subpage, the field worker can have an overlook of all the work that has been done on the appointment.'
          ),
          disableBeacon: true,
          placement: 'top-start' as Placement,
          data: {
            fullPath:
              FwSingleAppointmentRoutes.FwSingleAppointmentTimesheet.fullPath,
            page: GuidePages.FIELD_WORKER_TIMESHEET,
          },
        },
      ];
    case GuidePages.FIELD_WORKER_GENERAL:
      return [
        {
          title: t('Timer'),
          target: '.fw-general-step-1',
          content: t(
            'Once everything has been checked and the appointment has actually started, the field worker can press the Start Timer button. It will keep on measuring the length of the appointment until it is told otherwise. The field worker can Pause the Timer at any time, and when the appointment is finished press the Stop Timer button.'
          ),
          disableBeacon: true,
          placement: 'bottom' as Placement,
          data: {
            fullPath:
              FwSingleAppointmentRoutes.FwSingleAppointmentGeneral.fullPath,
            page: GuidePages.FIELD_WORKER_GENERAL,
          },
        },
      ];
    case GuidePages.FIELD_WORKER_REPORT:
      return [
        {
          title: t('Report'),
          target: '.fw-report-step-1',
          content: t(
            'The final step of the flow is filling out the Report. Together with the customer, the field worker fills in all important details that have to do with the recently finish appointment, and submits them for further review.'
          ),
          placement: 'top-start' as Placement,
          disableBeacon: true,
          data: {
            fullPath: RoutesConfig.FwReportPage.fullPath,
            page: GuidePages.FIELD_WORKER_REPORT,
          },
        },
      ];
    default:
      return [];
  }
};

export const useGetCompletedGuidePages = () => {
  return useSelector(
    (state: IRootReducerState) => state.userInfo.completed_guide_pages
  );
};

export const useGetIsIntroGuideCompleted = () => {
  return useSelector(
    (state: IRootReducerState) => state.userInfo.intro_guide_completed
  );
};

export const useRunTour = (
  page: GuidePages,
  id?: string,
  isFetched = true,
  checkRouteMatching = false
) => {
  const steps = useGetGuideSteps(page, id);
  const dispatch = useDispatch();
  const completedPages = useGetCompletedGuidePages();
  const routeIsMatching = checkRouteMatching
    ? isCurrentLocationMatchingRoute(page, location.pathname, id!)
    : true;
  const isGuideCompleted = useGetIsIntroGuideCompleted();

  useEffect(() => {
    if (
      !completedPages?.includes(page) &&
      !isGuideCompleted &&
      isFetched &&
      routeIsMatching
    ) {
      setTimeout(() => {
        dispatch(setRunTour(true));
        document.body.style.overflow = 'hidden';
      }, 100);
    }
  }, [isFetched]);

  return { steps, showIntroductionGuide: routeIsMatching };
};

export const useChangeIntroGuideCompletedStatus = () => {
  return useMutation(
    (value: boolean) => changeIntroGuideCompletedStatus(value),
    {
      onError: (error: AxiosError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.RESET_INTRO_GUIDE,
        });
      },
      mutationKey: ReactMutationKeys.RESET_INTRO_GUIDE,
    }
  );
};

export const useAddIntroGuideCompletedPage = () => {
  return useMutation((value: string) => addIntroGuideCompletedPage(value), {
    onError: (error: AxiosError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_COMPLETED_INTRO_GUIDE_PAGE,
      });
    },
    mutationKey: ReactMutationKeys.ADD_COMPLETED_INTRO_GUIDE_PAGE,
  });
};
