import moment from 'moment';
import { IAppointment, IAppointmentInterval } from 'types/Appointment.types';

export const formatSeconds = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');
  return `${duration.hours().toString().padStart(2, '0')}:${duration
    .minutes()
    .toString()
    .padStart(2, '0')},${duration.seconds().toString().padStart(2, '0')} h`;
};

export const checkIfLastIntervalIsStopped = (
  intervals: IAppointmentInterval[]
) => {
  return !!intervals[intervals.length - 1]?.end_date;
};

export const isAppointmentScheduledForToday = (
  appointment: undefined | IAppointment
) => {
  if (!appointment || !appointment.date_from) {
    return false;
  }
  // Parse the date_from into a moment object
  const appointmentDate = moment(appointment.date_from);
  // Get today's date as a moment object at the start of the day
  const today = moment().startOf('day');
  // Check if appointmentDate is the same as today
  return appointmentDate.isSame(today, 'day');
};

export const getAppointmentScheduledDifference = (
  appointment: undefined | IAppointment,
  t: (string: string) => string
) => {
  if (!appointment || !appointment.date_from) {
    return '';
  }

  const appointmentDate = moment(appointment.date_from);
  const now = moment();

  // Calculate the difference in days and hours
  const days = appointmentDate.diff(now, 'days');
  const hours = appointmentDate.subtract(days, 'days').diff(now, 'hours');

  // Generate the message
  let message = t('The appointment is scheduled to start in ');
  if (days > 0) {
    if (days === 1) {
      message += `${days} ${t('day')} `;
    } else {
      message += `${days} ${t('days')} `;
    }
  }

  if (hours > 0) {
    if (hours === 1) {
      message += `${hours} ${t('hour')}`;
    } else {
      message += `${hours} ${t('hours')}`;
    }
  }

  // In case the appointment is less than an hour away
  if (days === 0 && hours === 0) {
    message = '';
  }

  return message;
};
