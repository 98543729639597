import { Option } from 'components/Select/type';
import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';
import { IResourceWorkingHours } from './SituationDescriptionResourceSection';

export const removeAlreadySelectedResourcesFromFetchedResources = (
  fetchedResources: IWorkerAndResource[],
  resourceWorkingHours: IResourceWorkingHours[]
): Option[] => {
  if (!fetchedResources?.length) {
    return [];
  }
  return fetchedResources
    .filter((fetchedResource: IWorkerAndResource) => {
      if (resourceWorkingHours.length) {
        return !resourceWorkingHours.some(
          (resourceWorkingHour: IResourceWorkingHours) =>
            resourceWorkingHour.resource.id === fetchedResource.id
        );
      } else {
        // No resources selected, keep all fetched resources
        return true;
      }
    })
    .map((resource: IWorkerAndResource) => {
      return { value: resource?.id, label: resource?.name };
    });
};
