import { useEffect, useRef, useState } from 'react';
import {
  ICreateOutlookCalendarPreference,
  useCreateOutlookCalendarPreference,
} from '../hooks';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { createOutlookCalendarPreferencesSchema } from './validation';
import {
  ButtonsWrapper,
  FormContainer,
} from './OutlookCalendarPreferencesForm.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';

interface IOutlookCalendarPreferencesFormProps {
  onSuccess: () => void;
}

const OutlookCalendarPreferencesForm = ({
  onSuccess,
}: IOutlookCalendarPreferencesFormProps) => {
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const formikRef = useRef<any>(null);

  const {
    mutate: createOutlookCalendarPreference,
    isLoading,
    isSuccess,
    reset,
  } = useCreateOutlookCalendarPreference();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
      reset();
    }
  }, [isSuccess]);

  const handleCreateCalendarPreference = () => {
    const { values } = formikRef.current;
    const createOutlookCalendarPreferenceDTO: ICreateOutlookCalendarPreference =
      {
        name: values.name,
        custom_calendar_is_preferred: true,
      };
    createOutlookCalendarPreference(createOutlookCalendarPreferenceDTO);
  };

  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        validationSchema={createOutlookCalendarPreferencesSchema}
        initialValues={{
          name: '',
        }}
      >
        {(formikProps: any) => {
          const { values, errors, setFieldValue } = formikProps;

          useEffect(() => {
            formikRef.current = formikProps;
          }, [formikProps]);

          return (
            <FormContainer>
              <FormikInput
                placeholder={t('Name')}
                pwId="name-field"
                errorMessage={areAllFieldsTouched && errors['name']}
                height={'43rem'}
                name="name"
                wrapperStyles={{
                  width: '400rem',
                  marginBottom: '4rem',
                  marginTop: '20rem',
                }}
              />
            </FormContainer>
          );
        }}
      </Formik>
      <ButtonsWrapper>
        <Button
          disabled={isLoading}
          label={t('Skip')}
          secondary
          width="200rem"
          onClick={() => {
            const createGoogleCalendarPreferenceDTO: ICreateOutlookCalendarPreference =
              {
                custom_calendar_is_preferred: false,
              };
            createOutlookCalendarPreference(createGoogleCalendarPreferenceDTO);
          }}
        />
        <Button
          disabled={isLoading}
          label={t('Save')}
          primary
          width="200rem"
          onClick={() => {
            if (formikRef.current?.isValid) {
              handleCreateCalendarPreference();
            } else {
              if (!areAllFieldsTouched) {
                setAreAllFieldsTouched(true);
              }
            }
          }}
        />
      </ButtonsWrapper>
    </>
  );
};

export default OutlookCalendarPreferencesForm;
