import { formatSalesInvoiceTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISalesInvoice,
  ISecondarySalesInvoiceTableDTO,
} from 'types/Invoice.types';
import { SALES_INVOICES_PER_PAGE, tableConfig } from './constants';
import { useGetSalesOrderId, useGetSingleSalesOrderInvoices } from './hooks';
import {
  Container,
  LoaderWrapper,
  NoContentLabel,
} from './SingleSalesOrderInvoicesPage.styled';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SingleSalesOrderInvoicesPage = () => {
  const navigate = useNavigate();
  const salesOrderId = useGetSalesOrderId();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { isLoading, data, isFetched } = useGetSingleSalesOrderInvoices(
    typeof salesOrderId === 'string' ? salesOrderId : '',
    page,
    SALES_INVOICES_PER_PAGE
  );

  const handleViewButtonClick = (rowData: ISecondarySalesInvoiceTableDTO) => {
    rowData.isCreditInvoice === 'True'
      ? navigate(
          RoutesConfig.SingleSalesCreditInvoice.fullPath.replace(
            ':id/*',
            rowData.navigationID
          )
        )
      : navigate(
          RoutesConfig.SingleSalesInvoice.fullPath.replace(
            ':id/*',
            rowData.navigationID
          )
        );
  };

  let tableData: ISecondarySalesInvoiceTableDTO[] = [];

  if (data?.sales_invoices) {
    tableData = formatSalesInvoiceTableData(
      data.sales_invoices
        .map((sales_invoice: ISalesInvoice[]) => {
          return sales_invoice;
        })
        .flat(),
      currencySymbol
    );
  }

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER_INVOICES,
    salesOrderId,
    isFetched
  );

  return (
    <Container className="single-sales-order-step-9">
      {tableData.length ? (
        <Table
          isLoading={isLoading}
          page={page}
          perPage={SALES_INVOICES_PER_PAGE}
          total={data?.total}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={(newPage) => setPage(newPage)}
          onViewButtonClick={handleViewButtonClick}
        />
      ) : (
        <>
          {isLoading && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <NoContentLabel>
              {t('There are currently no Invoices on this Sales order')}
            </NoContentLabel>
          )}
        </>
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesOrderInvoicesPage;
