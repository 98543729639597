import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import { LayoutColumn, LayoutRow } from './FieldWorkerLayout.styled';

export function FieldWorkerLayout() {
  return (
    <LayoutRow>
      <LayoutColumn>
        <Header />
        <Outlet />
      </LayoutColumn>
    </LayoutRow>
  );
}

export default FieldWorkerLayout;
