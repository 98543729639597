import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

export const CUSTOMER_SALES_ORDERS_PER_PAGE = 10;

const rowConfigs: ITableRowConfig[] = [
  { header: 'Number', flexValue: 1, isBlue: false },
  { header: 'Payment', flexValue: 1, isBlue: true },
  { header: 'Status', flexValue: 1, isBlue: true },
  { header: 'Date', flexValue: 1, isBlue: false },
  { header: 'Reference', flexValue: 1, isBlue: false },
  { header: 'Customer', flexValue: 2, isBlue: true },
  { header: 'Amount', flexValue: 1, isBlue: true },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 1,
};
