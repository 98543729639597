import { useParams } from 'react-router-dom';
import { useGetSingleCustomer } from '../hooks';
import { useGetSingleMollieCustomerMandates } from './hooks';
import { MollieMandates } from './MollieMandates/MollieMandates';
import {
  Container,
  MollieMandatesWrapper,
} from './SingleCustomerMollieMandates.styled';

export const SingleCustomerMollieMandatesPage = () => {
  const { id } = useParams();

  const { data: customerData } = useGetSingleCustomer(id!);
  const { data: mandates } = useGetSingleMollieCustomerMandates(
    customerData?.customer?.mollie_customer?.id
  );
  return (
    <Container>
      {customerData?.customer?.mollie_customer && (
        <MollieMandatesWrapper>
          <MollieMandates
            mandates={mandates?.mandates}
            mollieCustomer={customerData?.customer?.mollie_customer}
          />
        </MollieMandatesWrapper>
      )}
    </Container>
  );
};
