import { queryClient } from 'index';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  deletePurchaseOrderFile,
  getPurchaseOrderByPurchaseOrderId,
  getPurchaseOrderComments,
  getPurchaseOrderFiles,
  newComment,
  uploadPurchaseOrderFile,
  changeStatus,
  exportPurchaseOrder,
  sendPurchaseOrderEmail,
  editPurchaseOrderDetails,
  getPurchaseOrderInfoById,
} from 'services/PurchaseOrder/PurchaseOrderService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import { IEditPurchaseOrderDetails } from 'types/PurchaseOrders.types';

export const useGetSinglePurchaseOrder = (orderId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER, orderId],
    queryFn: () => {
      return getPurchaseOrderByPurchaseOrderId(orderId);
    },
  });

export const useChangePurchaseStatus = () =>
  useMutation(
    (params: { id: string; status_id: string }) =>
      changeStatus(params.id, params.status_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_PURCHASE_ORDER_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER);
      },
      mutationKey: ReactMutationKeys.CHANGE_PURCHASE_ORDER_STATUS,
    }
  );

export const useNewPurchaseOrderComment = (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
) =>
  useMutation(() => newComment(headline, description, isInternal, id), {
    onSuccess: (data) => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.ADD_PURCHASE_ORDER_COMMENT,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_INFO
      );
      queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDER_COMMENTS);
    },
    mutationKey: ReactMutationKeys.ADD_PURCHASE_ORDER_COMMENT,
  });

export const useGetPurchaseOrderComments = (
  perPage: number,
  purchaseOrderId: string,
  dateFrom: string,
  dateTo: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_PURCHASE_ORDER_COMMENTS,
      purchaseOrderId,
      dateFrom,
      dateTo,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getPurchaseOrderComments(
        purchaseOrderId,
        pageParam,
        perPage,
        dateFrom,
        dateTo
      ),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

export const useGetPurchaseOrderFiles = (
  perPage: number,
  purchaseOrderId: string
) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_PURCHASE_ORDER_FILES, purchaseOrderId],
    queryFn: ({ pageParam = 1 }) =>
      getPurchaseOrderFiles(purchaseOrderId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
  });

interface IUseDeletePurchaseOrderFile {
  purchaseOrderId: string;
  purchaseOrderFileId: string;
}
export const useDeletePurchaseOrderFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeletePurchaseOrderFile) =>
      deletePurchaseOrderFile(
        params.purchaseOrderId,
        params.purchaseOrderFileId
      ),
    {
      onSuccess: (data) => {
        toast.success(t('File successfully deleted'));
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_INFO
        );
        queryClient.invalidateQueries([ReactQueryKeys.GET_QUOTATION_FILES]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_SALES_ORDER_FILES]);
        queryClient.invalidateQueries([ReactQueryKeys.GET_SALES_INVOICE_FILES]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_FW_APPOINTMENT_FILES,
        ]);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDER_FILES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_PURCHASE_ORDER_FILE,
        });
      },
      mutationKey: ReactMutationKeys.DELETE_PURCHASE_ORDER_FILE,
    }
  );
};

interface IUseUploadPurchaseOrderFileParams {
  files: any;
  purchaseOrderId: string;
}
export const useUploadPurchaseOrderFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadPurchaseOrderFileParams) => {
      const fileFormData = new FormData();

      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }
      return uploadPurchaseOrderFile(params.purchaseOrderId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_PURCHASE_ORDER_FILE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_INFO
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDER_FILES);
      },
      mutationKey: ReactMutationKeys.UPLOAD_PURCHASE_ORDER_FILE,
    }
  );
};

export const useExportPurchaseOrder = () =>
  useMutation(
    (purchaseOrderId: string) => exportPurchaseOrder(purchaseOrderId),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EXPORT_PURCHASE_ORDER,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EXPORT_PURCHASE_ORDER,
    }
  );

export const useSendPurchaseOrderEmail = (purchaseOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (purchaseOrderId: string) => sendPurchaseOrderEmail(purchaseOrderId),
    {
      onSuccess: () => {
        toast.success(t('E-mail sent'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER,
          purchaseOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SEND_PURCHASE_ORDER_EMAIL,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SEND_PURCHASE_ORDER_EMAIL,
    }
  );
};

export const useEditPurchaseOrderDetails = (purchaseOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (editData: IEditPurchaseOrderDetails) =>
      editPurchaseOrderDetails(purchaseOrderId, editData),
    {
      onSuccess: (data) => {
        toast.success(t('Purchase order edited'));
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER,
          purchaseOrderId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_PURCHASE_ORDER,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.EDIT_PURCHASE_ORDER,
    }
  );
};

export const useGetSinglePurchaseOrderInfo = (purchaseOrderId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER_INFO],
    queryFn: () => {
      return getPurchaseOrderInfoById(purchaseOrderId);
    },
  });
