import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginMd, paddingSm } from 'assets/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const DescriptionLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;
