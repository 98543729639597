import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';

const BASE_URL = '/v1/advanced-filters/';

export const getSavedFilters = async (page: string): Promise<any> => {
  const queryParamsObj = { page: page.toUpperCase() };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data.advanced_filters;
};

export const addSavedFilters = async (
  page: string,
  base64: string,
  name: string
): Promise<any> => {
  const url = BASE_URL;
  const { data } = await apiClient.post<any>(url, {
    page: page.toUpperCase(),
    base64_string: base64,
    name,
  });

  return data.data;
};

export const deleteSavedFilters = async (id: number | string): Promise<any> => {
  const url = BASE_URL + `${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};
