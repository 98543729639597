import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { Option } from 'components/Select/type';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  addPurchaseInvoicePayment,
  addSalesInvoicePayment,
} from 'services/Payment/PaymentService';

export const useGetPaymentOptions = (includeOffset = false) => {
  const paymentTypeOptions: Option[] = [
    { value: '1', label: 'Cash', key: 'cash' },
    { value: '2', label: 'Card', key: 'card' },
    { value: '4', label: 'Bank transfer', key: 'bank_transfer' },
  ];
  if (includeOffset) {
    paymentTypeOptions.push({
      value: '5',
      label: 'Offseting',
      key: 'offseting',
    });
  }
  return paymentTypeOptions;
};

export interface IAddSalesInvoicePaymentDTO {
  amount: number;
  paymentTypeId: number;
  invoiceId: number;
  date?: any;
  reference?: string;
}
export const useAddSalesInvoicePayment = () =>
  useMutation(
    (addPaymentDTO: IAddSalesInvoicePaymentDTO) =>
      addSalesInvoicePayment(addPaymentDTO),
    {
      onSuccess: () => {
        toast.success('Payment added', {
          toastId: ReactMutationKeys.ADD_SALES_INVOICE_PAYMENT,
          className: ReactMutationKeys.ADD_SALES_INVOICE_PAYMENT,
        });
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_INVOICE_PAYMENTS
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_SALES_INVOICE);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_ORDERS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_INVOICES);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_SALES_INVOICE_INFO
        );
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_SALES_INVOICE_PAYMENT,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_SALES_INVOICE,
    }
  );

export interface IAddPurchaseInvoicePaymentDTO {
  amount: number;
  paymentTypeId: number;
  invoiceId: number;
  date?: any;
  reference?: string;
}
export const useAddPurchaseInvoicePayment = () => {
  const { t } = useTranslation();
  return useMutation(
    (addPaymentDTO: IAddSalesInvoicePaymentDTO) =>
      addPurchaseInvoicePayment(addPaymentDTO),
    {
      onSuccess: () => {
        toast.success(t('Payment added'), {
          toastId: ReactMutationKeys.ADD_PURCHASE_INVOICE_PAYMENT,
          className: ReactMutationKeys.ADD_PURCHASE_INVOICE_PAYMENT,
        });
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE_PAYMENTS
        );
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE
        );
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_ORDERS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_PURCHASE_INVOICES);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_PURCHASE_INVOICE_PAYMENT,
        });
      },
      onSettled: () => {
        // On settled
      },
      mutationKey: ReactMutationKeys.CREATE_PURCHASE_INVOICE,
    }
  );
};
