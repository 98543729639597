import { ISubMenuItem } from 'components/Layout/SubSideMenu/type';
import { SinglePurchaseCreditInvoiceRoutes } from 'navigation/SingleInvoice/PurchaseCreditInvoice/SinglePurchaseCreditInvoice.routes';

export const PURCHASE_INVOICE_CREDIT_INVOICES_PER_PAGE = 10;

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route:
      SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoiceGeneral.path,
  },
  {
    text: 'Purchase Invoice',
    route:
      SinglePurchaseCreditInvoiceRoutes
        .SinglePurchaseCreditInvoicePurchaseInvoice.path,
  },
  {
    text: 'Payments',
    route:
      SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoicePayments
        .path,
  },
  {
    text: 'Files',
    route:
      SinglePurchaseCreditInvoiceRoutes.SinglePurchaseCreditInvoiceFiles.path,
  },
];
