import { FormikErrors, FormikTouched } from 'formik';

export const getFormikErrorMesssage = (
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
  fieldName: string
) => {
  if (touched[fieldName] && errors[fieldName]) {
    const res = errors[fieldName];
    return res?.toString();
  } else {
    return '';
  }
};
