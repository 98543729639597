import { COLORS, H3 } from 'assets/styled';
import {
  Container,
  TitleContainer,
  ButtonContainer,
  Line,
  NoContentLabel,
  TitleWrapper,
  LoaderWrapper,
} from './Notifications.styled';
import { useNavigate } from 'react-router';
import HomePageNotificationCard from './HomePageNotificationCard/HomePageNotificationCard';
import { useTranslation } from 'react-i18next';
import { useGetNotifications } from 'pages/Shared/NotificationsPage/hooks';
import { NOTIFICATIONS_PER_PAGE } from './constants';
import { INotification } from 'types/Notification.types';
import { RoutesConfig } from 'navigation/routes';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { Bell } from '@phosphor-icons/react';
import MoreButton from 'components/MoreButton/MoreButton';

const Notifications = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = useGetNotifications(NOTIFICATIONS_PER_PAGE, '');

  let notifications: INotification[] = [];
  if (data?.pages?.length) {
    notifications = data.pages.map((page) => page.notifications).flat();
  }

  return (
    <Container>
      <TitleContainer>
        <TitleWrapper>
          <Icon svg={Bell} pointer={false} size={30} color={COLORS.PRIMARY} />
          <H3>{t('Notifications')}</H3>
        </TitleWrapper>
        <ButtonContainer
          onClick={() => navigate(RoutesConfig.Notifications.fullPath)}
        >
          <MoreButton />
        </ButtonContainer>
      </TitleContainer>
      <Line />
      {notifications.length ? (
        notifications.map((notification: INotification) => {
          return (
            <HomePageNotificationCard
              key={notification.id}
              notification={notification}
            />
          );
        })
      ) : isLoading ? (
        <LoaderWrapper>
          <Spinner />
        </LoaderWrapper>
      ) : (
        <NoContentLabel>
          {t('There are currently no notifications')}
        </NoContentLabel>
      )}
    </Container>
  );
};

export default Notifications;
