import { ITableConfig, ITableRowConfig } from 'components/Table/Table';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Unit Name', flexValue: 1, isBlue: false },
  { header: 'Supplier Name', flexValue: 1, isBlue: false },
  { header: 'Quantity', flexValue: 1, isBlue: false },
  { header: 'VAT', flexValue: 1, isBlue: false },
  { header: 'Unit Price', flexValue: 1, isBlue: false },
  { header: 'Discount', flexValue: 1, isBlue: false },
  {
    header: 'Total incl. VAT',
    flexValue: 1,
    isBlue: false,
  },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 0.5,
};
