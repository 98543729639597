import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginLg, marginMd, marginSm, paddingMd } from 'assets/styled';
import { hoverLineIn } from 'assets/styled/commonStyles';

interface StyledProps {
  secure: boolean;
  clickable: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  margin-bottom: ${marginMd};
`;

export const EditLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineIn}
`;

export const BackgroundColorWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: ${paddingMd};
`;

export const Label = styled.label`
  margin-left: 30rem;
  margin-right: 30rem;
  font-size: 14rem;
  line-height: 21rem;
  font-weight: 600;
  white-space: nowrap;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Subtitle = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 16rem;
  padding-bottom: 20rem;
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
  ${({ clickable }: StyledProps) =>
    clickable &&
    css`
      cursor: pointer;
      text-decoration: underline;
    `}
`;

export const LinkValue = styled.a`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  text-decoration: underline;
  cursor: pointer;
  color: ${COLORS.BLACK};
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 12rem;
  height: 12rem;
  right: -20rem;
  top: 1rem;
`;

export const ValueWrapperRelative = styled.div`
  position: relative;
  margin-right: ${marginSm};
  height: min-content;
`;

export const ShowMoreLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  margin-top: ${marginSm};
  ${hoverLineIn}
`;

export const ShowMoreSubtitle = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 16rem;
  padding-bottom: 20rem;
  margin-top: ${marginLg};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EyeLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 70rem;
  justify-content: flex-start;
  align-items: center;
  gap: 5rem;
`;
