export const INVOICE_NEW = 'NEW';
export const INVOICE_SENT = 'SENT';
export const INVOICE_PARTLY_PAID = 'PARTLY_PAID';

export interface IEditSalesOrderDetailsDTO {
  reference: string;
  sales_person_id: number;
  downpayment: number;
}

export enum TimelineEvents {
  SALES_ORDER_CREATED = 'SALES_ORDER_CREATED',
  MANUAL_CHANGE = 'MANUAL_CHANGE',
  DOWN_PAYMENT_INVOICE_CREATED = 'DOWN_PAYMENT_INVOICE_CREATED',
  DOWN_PAYMENT_INVOICE_PAID = 'DOWN_PAYMENT_INVOICE_PAID',
  PURCHASE_ORDER_PARTLY_CREATED = 'PURCHASE_ORDER_PARTLY_CREATED',
  PURCHASE_ORDER_CREATED = 'PURCHASE_ORDER_CREATED',
  PURCHASE_ORDER_RECEIVED = 'PURCHASE_ORDER_RECEIVED',
  INSTALLATION_PARTLY_PLANNED = 'INSTALLATION_PARTLY_PLANNED',
  INSTALLATION_PLANNED = 'INSTALLATION_PLANNED',
  INSTALLATION_PARTLY_FINISHED = 'INSTALLATION_PARTLY_FINISHED',
  INSTALLATION_FINISHED = 'INSTALLATION_FINISHED',
  SALES_ORDER_FULLY_PAID = 'SALES_ORDER_FULLY_PAID',
  DOWN_PAYMENT_INVOICE_CANCELLED = 'DOWN_PAYMENT_INVOICE_CANCELLED',
  SALES_ORDER_PARLY_PAID = 'SALES_ORDER_PARLY_PAID',
  SALES_INVOICE_PARTLY_PAID = 'SALES_INVOICE_PARTLY_PAID',
  SALES_INVOICE_PAID = 'SALES_INVOICE_PAID',
  DOWN_PAYMENT_INVOICE_PARTLY_PAID = 'DOWN_PAYMENT_INVOICE_PARTLY_PAID',
  INVOICE_CREATED = 'INVOICE_CREATED',
  PURCHASE_ORDER_PARTLY_RECEIVED = 'PURCHASE_ORDER_PARTLY_RECEIVED',
  QUOTATION_CREATED = 'QUOTATION_CREATED',
  MEASURING_SCHEDULED = 'MEASURING_SCHEDULED',
  MEASURING_FINISHED = 'MEASURING_FINISHED',
  QUOTATION_COPIED_TO_NEW = 'QUOTATION_COPIED_TO_NEW',
  QUOTATION_ACCEPTED = 'QUOTATION_ACCEPTED',
  EMAIL_SENT = 'EMAIL_SENT',
  SALES_INVOICE_EMAIL_SENT = 'SALES_INVOICE_EMAIL_SENT',
  DOWN_PAYMENT_INVOICE_EMAIL_SENT = 'DOWN_PAYMENT_INVOICE_EMAIL_SENT',
  CREDIT_INVOICE_EMAIL_SENT = 'CREDIT_INVOICE_EMAIL_SENT',
  INVOICE_CANCELLED = 'INVOICE_CANCELLED',
  MEASURING_APPOINTMENT_PLANNED = 'MEASURING_APPOINTMENT_PLANNED',
  SERVICE_APPOINTMENT_PLANNED = 'SERVICE_APPOINTMENT_PLANNED',
  GENERAL_APPOINTMENT_PLANNED = 'GENERAL_APPOINTMENT_PLANNED',
  MEASURING_APPOINTMENT_DELETED = 'MEASURING_APPOINTMENT_DELETED',
  SERVICE_APPOINTMENT_DELETED = 'SERVICE_APPOINTMENT_DELETED',
  GENERAL_APPOINTMENT_DELETED = 'GENERAL_APPOINTMENT_DELETED',
  INSTALLATION_APPOINTMENT_DELETED = 'INSTALLATION_APPOINTMENT_DELETED',
  MEASURING_APPOINTMENT_EDITED = 'MEASURING_APPOINTMENT_EDITED',
  SERVICE_APPOINTMENT_EDITED = 'SERVICE_APPOINTMENT_EDITED',
  GENERAL_APPOINTMENT_EDITED = 'GENERAL_APPOINTMENT_EDITED',
  INSTALLATION_APPOINTMENT_EDITED = 'INSTALLATION_APPOINTMENT_EDITED',
  QUOTATION_IN_TREATMENT = 'QUOTATION_IN_TREATMENT',
  QUOTATION_FOLLOW_UP = 'QUOTATION_FOLLOW_UP',
  SIGNATURE_ADDED = 'SIGNATURE_ADDED',
}
