import {
  IIMollieMandatesTableRowConfig,
  IMollieMandatesTableConfig,
} from './MollieMandatesTable/MollieMandatesTable';

const rowConfigs: IIMollieMandatesTableRowConfig[] = [
  { header: 'Creation date', flexValue: 1 },
  { header: 'Method', flexValue: 1 },
];

export const tableConfig: IMollieMandatesTableConfig = {
  rowConfigs: rowConfigs,
};
