import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import {
  Title,
  ButtonWrapper,
  Container,
  Text,
} from './ConfirmLogyxSalesPriceUpdateModal.styled';

export interface IConfirmLogyxSalesPriceUpdateModalProps {
  isOpen: boolean;
  setIsOpen: any;
  onCancel: any;
  onConfirm: any;
}

export function ConfirmLogyxSalesPriceUpdateModal({
  isOpen,
  setIsOpen,
  onCancel,
  onConfirm,
}: IConfirmLogyxSalesPriceUpdateModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      level={'SECOND'}
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (boolean) {
          setIsOpen(true);
        }
      }}
      modalStyle={{ position: 'fixed', margin: 'auto', minWidth: '600rem' }} // Center positioning
    >
      <Container>
        <Title>{t('Update Price') + '?'}</Title>
        <Text>
          {t(
            'Should the Sales Price be updated to match price of the new updated configuration'
          ) + '?'}
        </Text>
        <ButtonWrapper>
          <Button
            label={t('Preserve')}
            secondary
            width="200rem"
            onClick={() => onCancel()}
          />
          <Button
            label={t('Update')}
            primary
            width="200rem"
            onClick={() => onConfirm()}
          />
        </ButtonWrapper>
      </Container>
    </Modal>
  );
}
